import React from "react";
import html2canvas from "html2canvas"
import {jsPDF} from 'jspdf'
import { Button} from 'reactstrap';
import styles from '../OutboundAssetForm/OutboundAssetForm.module.scss';

interface OwnProps {
    rootElementId: any;
    downloadFileName:any;
  }

  const DownloadPage: React.FC<OwnProps> = ({ rootElementId, downloadFileName }) => {
    const downloadFileDocument = async () => {
      const input = document.getElementById(rootElementId);

      if (input) {
        // Create a clone of the element to print
        const clone = input.cloneNode(true) as HTMLElement;
        clone.id = 'clone';
        clone.style.position = 'fixed';
        clone.style.right = '10000px'; 
        clone.style.display = 'none'; 
        // Recursively remove box shadows from the clone and its children
        const removeBoxShadow = (element: HTMLElement) => {
          element.style.boxShadow = 'none';
          Array.from(element.children).forEach((child) => 
            removeBoxShadow(child as HTMLElement)
          );
        };
        removeBoxShadow(clone);

        document.body.appendChild(clone);

        const clonedInput = document.getElementById('clone');

        if(clonedInput) {
          clonedInput.style.display = 'block';
          const canvas = await html2canvas(clonedInput, {
            scale: 2 / window.devicePixelRatio,  // Adjust the scale according to the device pixel ratio
            useCORS: true  // This might be needed if there are cross-origin resources
          });
          clonedInput.style.display = 'none';
          const imgData = canvas.toDataURL('image/png');

          const ratio = canvas.height / canvas.width;

          // Define the size of the A4 page
          const a4Width = 210;
          const a4Height = a4Width * ratio;

          const pdf = new jsPDF('p', 'mm', [a4Width, a4Height]);

          pdf.addImage(imgData, 'PNG', 0, 0, a4Width, a4Height);

          pdf.save(`${downloadFileName}.pdf`);
          // Remove the clone
          document.body.removeChild(clonedInput);
        }
      }
    };

    return(
        <div className={styles['button']}>
        <Button color="primary" size="lg" onClick={downloadFileDocument}>Export as PDF</Button>
      </div>
    );
}
export default DownloadPage;
