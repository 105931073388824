import { Dispatch } from 'redux';
import {
  IntegrationAssetIssuersActions,
  INTEGRATION_ASSET_ISSUERS_CLEAR_TOAST,
  INTEGRATION_ASSET_ISSUERS_LOADING,
  GET_INTEGRATION_ASSET_ISSUERS,
  INTEGRATION_ASSET_ISSUERS_FETCH_ERROR,
  ADD_INTEGRATION_ASSET_ISSUERS,
  DELETE_INTEGRATION_ASSET_ISSUERS,
  EDIT_INTEGRATION_ASSET_ISSUERS,
  INTEGRATION_ASSET_ISSUERS_EDIT_ERROR,
  INTEGRATION_ASSET_ISSUERS_ADD_ERROR,
  INTEGRATION_ASSET_ISSUERS_DELETE_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { IntegrationAssetIssuersResponseModel, IntegrationAssetIssuer } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating integration asset issuers...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Integration asset issuers updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating integration asset issuers...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Integration asset issuers created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;


export const getIntegrationAssetIssuers = () => (dispatch: Dispatch<IntegrationAssetIssuersActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_ISSUERS_LOADING,
  });

  new api<IntegrationAssetIssuersResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_ISSUERS_FETCH_ERROR,
  })
    .get(`/api/Integration/AssetIdIssuer`)
    .then((response) => {
      dispatch({
        type: GET_INTEGRATION_ASSET_ISSUERS,
        payload: response,
      });
    });
};

export const addIntegrationAssetIssuers = (integrationAssetIssuers: IntegrationAssetIssuer, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationAssetIssuersActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_ISSUERS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<IntegrationAssetIssuersResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_ISSUERS_ADD_ERROR,
  })
    .post(`/api/Integration/AssetIdIssuer`, integrationAssetIssuers)
    .then((response) => {
      dispatch({
        type: ADD_INTEGRATION_ASSET_ISSUERS,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteIntegrationAssetIssuers = (id: string) => (dispatch: Dispatch<IntegrationAssetIssuersActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_ISSUERS_LOADING,
  });

  const url = `/api/Integration/AssetIdIssuer/${id}`;
  new api<IntegrationAssetIssuersResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_ISSUERS_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_INTEGRATION_ASSET_ISSUERS,
        payload: response,
      });
    }).catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const editIntegrationAssetIssuers = (integrationAssetIssuers: IntegrationAssetIssuer, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationAssetIssuersActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_ISSUERS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (integrationAssetIssuers.id != undefined) {
    const url = `/api/Integration/AssetIdIssuer/${integrationAssetIssuers.id}`;

    new api<IntegrationAssetIssuersResponseModel>({
      dispatch,
      errorType: INTEGRATION_ASSET_ISSUERS_EDIT_ERROR,
    })
      .put(url, integrationAssetIssuers)
      .then((response) => {
        dispatch({
          type: EDIT_INTEGRATION_ASSET_ISSUERS,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const integrationAssetIssuersClearToast = () => (dispatch: Dispatch<IntegrationAssetIssuersActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_ISSUERS_CLEAR_TOAST,
  });
};
