import * as TYPE from './types';

import { ApplicationState } from '..';

const errorMessage = (errorCode: number, actionType: string) => {
    switch (errorCode) {
        case 403:
            switch (actionType) {
                case "read":
                    return 'Not authorized to do this action.';
                case "add":
                    return 'Not authorized to do this action.';
                case "edit":
                    return 'Not authorized to do this action.';
                case "delete":
                    return 'Not authorized to do this action (deleting another system admin)';
            }
        case 409:
            switch (actionType) {
                case "read":
                    return 'Conflict';
                case "add":
                    return 'Conflict';
                case "edit":
                    return 'Conflict';
                case "delete":
                    return 'User still have either FleetVessel/FleetPipeline or Chat history stored in database. Please remove this before continuing on deleting user';
            }
        default:
            return 'Something went wrong with User interaction. Please contact FuelBoss support for more details.';
    }
};

export const usersReducer = (
  state: ApplicationState['users'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.UserActions,
): ApplicationState['users'] => {
  switch (action.type) {
    case TYPE.USER_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.USERS_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.GET_USERS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.USERS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_USER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'User succesfully created!',
          type: 'success',
        },
      };

    case TYPE.USERS_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `User/email already exist in FuelBoss. Please check if the user/email is deactivated. If not please contact Fuelboss support`
            : `We could not create the new user. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_USER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'User edited',
          type: 'info',
        },
      };
    case TYPE.USERS_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            :action.payload.statusCode == 409
            ? `We could not edit the user due to user having active recurring nomination series. Please Check the recurring nomination series. If not please contact Fuelboss support for more details`
            : `We could not edit the user. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    case TYPE.DELETE_USER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'User succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.USERS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: "Delete User error. " + errorMessage(action.payload.statusCode, "delete"),
          type: 'error',
        },
      };

    default:
      return { ...state };
  }
};
