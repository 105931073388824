import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chat from './Components/Chat';
import styles from './BunkerEvents.module.scss';
import { ApplicationState } from '../../store';
import { getBunkerShips } from '../../store/BunkerShip/actions';
import { getBunkerPipelines } from '../../store/BunkerPipeline/actions';
import ChorusIntegration from './Components/ChorusIntegration/ChorusIntegration';
import { Loading } from '../../components/Loading';
import { getChorusCompanies } from '../../store/ChorusCompany/actions';
import NewNomination from './Components/ChorusIntegration/NewNomination';
import Nomination from '../../components/Nomination/Nomination';
import { useParams, useHistory } from 'react-router';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import {
  ICompany,
  IContract,
  ILocation,
  CachedLocationService,
  IShipWithId,
  IBunkerShip,
  IShipWithCompanyId,
  IPromptNomination,
} from '@teqplay/chorus-components';
import { getFleetVessels } from '../../store/CompanyFleet/actions';
import { clearArchivedNominations, getArchivedNominationsInfiniteScroll } from '../../store/ArchivedNomination/actions';
import { canCreateNominationOnBehalf, checkSupplier } from '../../helpers/roleChecks';
import { getContracts } from '../../store/Contract/actions';
import { getChorusContracts } from '../../store/ChorusContract/actions';
import { contractHasExpired } from '../../helpers/spotEnquiryHelper';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { getCompanies } from '../../store/Company/actions';
import { getFuelbossLocations } from '../../store/FuelbossLocations/actions';
import { Category } from '../../models/Category';
import { getCharteredFleetVessels } from '../../store/CompanyCharteredFleet/actions';
import { clearNominations, getNominationsInfiniteScroll } from '../../store/Nomination/actions';

interface OwnProps {
  openNewNomination?: boolean;
  serviceLayer: AuthenticationServiceLayer;
  locationService: CachedLocationService;
}

const BunkerEvents: React.FC<OwnProps> = ({ openNewNomination, serviceLayer, locationService }) => {
  const [nominationId, setNominationId] = React.useState('');
  const [newNomination, setNewNomination] = React.useState(false);

  const [bunkerEventsMinified, setBunkerEventsMinified] = React.useState(false);
  const [nominationDetailsMinified, setNominationDetailssMinified] = React.useState(false);
  const [chatMinified, setChatMinified] = React.useState(false);

  const [renderChat, setRenderChat] = React.useState(false);
  const [latestNomination, setLatestNomination] = React.useState<IPromptNomination | undefined>(undefined);

  const history = useHistory();

  const setNewNominationMethod = (_newNomination: boolean) => {
    setNewNomination(_newNomination);
    setNominationId('');
  };

  const setNominationIdMethod = (id: string, archived: boolean = false) => {
    // @ts-ignore
    setNominationId(id);
    setNewNomination(false);
    if (history) {
      history.push(`/bunkerevents/${archived ? 'archived' : 'bunker'}/${id}`);
    }
  };

  const {
    archivedNominationMetaData,
    archivedNominationIsLoaded,
    nominationMetaData,
    nominationIsLoaded,
    companies,
    companiesIsLoaded,
    chorusCompanyLoading,
    chorusCompany,
    fuelBossLocation,
    fuelBossLocationIsLoaded,
    fuelBossLocationLoading,
    chorusCompanyIsLoaded,
    chorusContracts,
    chorusContractsLoading,
    chorusContractsIsLoaded,
    contractsLoading,
    contractsDetailsError,
    contractsErrorDetails,
    contracts,
    contractsIsLoaded,
    bunkerShipLoading,
    bunkerShip,
    bunkerShipIsLoaded,
    bunkerPipelineLoading,
    bunkerPipelines,
    bunkerPipelineIsLoaded,
    companyFleetLoading,
    companyFleetError,
    companyFleetErrorDetails,
    companyFleet,
    companyFleetIsLoaded,
    companyCharteredFleetLoading,
    companyCharteredFleetError,
    companyCharteredFleetErrorDetails,
    companyCharteredFleet,
    companyCharteredFleetIsLoaded,
    auth,
  } = useSelector((state: ApplicationState) => ({
    archivedNominationMetaData: state.archived_nomination.metadata,
    archivedNominationIsLoaded: state.archived_nomination.dataIsLoaded,
    nominationMetaData: state.nomination.metadata,
    nominationIsLoaded: state.nomination.dataIsLoaded === true,
    fuelBossLocationLoading: state.fuelbosslocations.loading,
    fuelBossLocation: state.fuelbosslocations.data,
    fuelBossLocationIsLoaded: state.fuelbosslocations.dataIsLoaded === true,
    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompanyErrorDetails: state.choruscompany.errorDetails,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded === true,
    chorusContractsLoading: state.choruscontracts.loading,
    chorusContractsError: state.choruscontracts.error,
    chorusContractsErrorDetails: state.choruscontracts.errorDetails,
    chorusContracts: state.choruscontracts.data,
    chorusContractsIsLoaded: state.choruscontracts.dataIsLoaded === true,
    contractsLoading: state.contracts.loading,
    contractsDetailsError: state.contracts.error,
    contractsErrorDetails: state.contracts.errorDetails,
    contracts: state.contracts.data,
    contractsIsLoaded: state.contracts.dataIsLoaded,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    bunkerShipLoading: state.bunkership.loading,
    bunkerShipError: state.bunkership.error,
    bunkerShipErrorDetails: state.bunkership.errorDetails,
    bunkerShip: state.bunkership.data,
    bunkerShipIsLoaded: state.bunkership.dataIsLoaded === true,
    bunkerPipelineLoading: state.bunkerpipeline.loading,
    bunkerPipelineError: state.bunkerpipeline.error,
    bunkerPipelineErrorDetails: state.bunkerpipeline.errorDetails,
    bunkerPipelines: state.bunkerpipeline.data,
    bunkerPipelineIsLoaded: state.bunkerpipeline.dataIsLoaded === true,
    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,
    companyCharteredFleetLoading: state.companyCharteredFleet.loading,
    companyCharteredFleetError: state.companyCharteredFleet.error,
    companyCharteredFleetErrorDetails: state.companyCharteredFleet.errorDetails,
    companyCharteredFleet: state.companyCharteredFleet.data,
    companyCharteredFleetIsLoaded: state.companyCharteredFleet.dataIsLoaded,
    auth: state.auth.data,
  }));

  const { id, type } = useParams();

  const [yearMonth, setYearMonth] = React.useState('');
  const [vendorCompanies, setVendorCompanies] = React.useState<ICompany[]>([]);
  const [filteredContracts, setFilteredContracts] = React.useState<IContract[]>([]);
  const [chorusLocations, setChorusLocations] = React.useState<ILocation[]>([]);
  const [filteredBunkerShips, setFilteredBunkerShips] = React.useState<IShipWithCompanyId[]>([]);

  const getYearAndMonth = () => {
    const currentDate = new Date();
    let currentMonth = '';
    currentDate.getMonth() + 1 < 10
      ? (currentMonth = `0${currentDate.getMonth() + 1}`)
      : (currentMonth = currentMonth + 1);

    return `${currentDate.getFullYear()}-${currentMonth}`;
  };

  const dispatch = useDispatch();
  const getAllContractsCallback = React.useCallback(() => dispatch(getContracts()), []);

  const getBunkerShipCallback = React.useCallback(() => dispatch(getBunkerShips()), []);

  const getBunkerPipelineCallback = React.useCallback(() => dispatch(getBunkerPipelines()), []);

  const getLocationCallback = React.useCallback(() => dispatch(getFuelbossLocations()), []);

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const getChorusCompaniesCallback = React.useCallback(() => dispatch(getChorusCompanies()), []);

  const getChorusContractsCallback = React.useCallback(() => dispatch(getChorusContracts()), []);

  const getArchivedNominationCallback = React.useCallback(() => dispatch(getArchivedNominationsInfiniteScroll(0)), []);

  const getNominationCallback = React.useCallback(() => dispatch(getNominationsInfiniteScroll(0)), []);

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyFleetIsLoaded) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  const getCompanyCharteredFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyCharteredFleetIsLoaded) {
      dispatch(getCharteredFleetVessels(auth.company.companyId));
    }
  }, []);

  const clearNominationCallback = React.useCallback(() => dispatch(clearNominations()), []);
  const clearArchivedNominationCallback = React.useCallback(() => dispatch(clearArchivedNominations()), []);
  const [clearedNominarions, setClearedNominations] = React.useState(false);

  React.useEffect(() => {
    clearNominationCallback();
    clearArchivedNominationCallback();
    setClearedNominations(true);

    if (id && type) {
      setNominationId(id);
      setNewNomination(false);
    }
    if (openNewNomination) {
      setNewNominationMethod(true);
    }
    getNominationCallback();
    /*if (!archivedNominationIsLoaded || archivedNominationMetaData) {
      getArchivedNominationCallback();
    }*/
    if (!contractsIsLoaded) {
      getAllContractsCallback();
    }
    if (!chorusCompanyIsLoaded) {
      getChorusCompaniesCallback();
    }
    if (!bunkerPipelineIsLoaded) {
      getBunkerPipelineCallback();
    }

    if (!chorusContractsIsLoaded) {
      getChorusContractsCallback();
    }

    if (!bunkerShipIsLoaded) {
      getBunkerShipCallback();
    }

    if (!companiesIsLoaded) {
      getCompaniesCallback();
    }

    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }

    if (!companyCharteredFleetIsLoaded) {
      getCompanyCharteredFleetCallback();
    }

    setYearMonth(getYearAndMonth);
  }, []);

  const isUserShipOwner = () =>
    (auth.company && auth.company.category.categoryName == Category.Shipowner) ||
    (auth.company && auth.company.category.categoryName == Category.Charterer);

  React.useEffect(() => {
    const nonRejectedContracts: IContract[] = [];
    chorusContracts.forEach(function (choruscontract, index) {
      const contractMatch = contracts.find((x) => x.chorusId == choruscontract._id);
      if (contractMatch?.status != 'Rejected' && contractMatch !== undefined && !contractHasExpired(contractMatch!)) {
        nonRejectedContracts.push(choruscontract);
      }
    });
    setFilteredContracts(nonRejectedContracts);
  }, [chorusContracts, contracts]);

  React.useEffect(() => {
    const bunkerVessels: IShipWithCompanyId[] = [];
    bunkerShip.forEach(function (ship, index) {
      //search bunker vessels in own vessels
      const shipMatch = companyFleet.find((x) => x.chorusId == ship._id);
      if (shipMatch && shipMatch.vessel.vesselType.toLowerCase() === 'bunkervessel') {
        bunkerVessels.push(ship);
      }
      //search bunker vessels in chartered vessels if not found in own fleet
      if (!shipMatch) {
        const charteredshipMatch = companyCharteredFleet.find((x) => x.fleetVessel?.chorusId == ship._id);
        if (
          charteredshipMatch &&
          charteredshipMatch.fleetVessel &&
          charteredshipMatch.fleetVessel.vessel.vesselType.toLowerCase() === 'bunkervessel'
        ) {
          bunkerVessels.push(ship);
        }
      }
    });
    setFilteredBunkerShips(bunkerVessels);
  }, [bunkerShip, companyFleet, companyCharteredFleet]);

  React.useEffect(() => {
    const activeVendorCompanies: ICompany[] = [];
    chorusCompany.forEach(function (comp, index) {
      const compMatch = companies.find((x) => x.chorusId == comp._id);
      if (compMatch?.active && comp.companyType == 'VENDOR') {
        activeVendorCompanies.push(comp);
      }
    });
    setVendorCompanies(activeVendorCompanies);
  }, [chorusCompany, companies]);

  React.useEffect(() => {
    const data = window.sessionStorage.getItem('BUNKEREVENT_SHOWN_STATE');
    if (data !== null) setBunkerEventsMinified(JSON.parse(data));
  }, []);

  React.useEffect(() => {
    window.sessionStorage.setItem('BUNKEREVENT_SHOWN_STATE', JSON.stringify(bunkerEventsMinified));
  }, [bunkerEventsMinified]);

  React.useEffect(() => {
    const data = window.sessionStorage.getItem('CHATSIDEBAR_SHOWN_STATE');
    if (data !== null) setChatMinified(JSON.parse(data));
    if (!chatMinified) {
      setRenderChat(true);
    }
  }, []);

  React.useEffect(() => {
    window.sessionStorage.setItem('CHATSIDEBAR_SHOWN_STATE', JSON.stringify(chatMinified));
  }, [chatMinified]);

  React.useEffect(() => {
    const data = window.sessionStorage.getItem('NOMINATIONDETAILS_SHOWN_STATE');
    if (data !== null) setNominationDetailssMinified(JSON.parse(data));
  }, []);

  React.useEffect(() => {
    window.sessionStorage.setItem('NOMINATIONDETAILS_SHOWN_STATE', JSON.stringify(nominationDetailsMinified));
  }, [nominationDetailsMinified]);

  const loadingString = `${bunkerShipLoading ? 'bunkerships, ' : ''} ${chorusCompanyLoading ? 'companies, ' : ''} ${
    fuelBossLocationLoading ? 'locations, ' : ''
  }`;

  if (bunkerShipLoading || chorusCompanyLoading || !clearedNominarions) {
    return <Loading type={loadingString} />;
  }

  const chatComponent = (nominationId: string, isDelegateChat: boolean) => {
    if (renderChat) {
      return (
        !chatMinified && (
          <Chat
            className={styles['chat']}
            connectionId={nominationId}
            type="nomination"
            isDelegateChat={isDelegateChat}
            delegateSupplierId={latestNomination?.supplierId}
            delegationOriginEventId={isDelegateChat ? latestNomination?.delegationOriginEventId : undefined}
          />
        )
      );
    } else {
      return <p>Chat is not available</p>;
    }
  };

  getYearAndMonth();

  const isDelegateChat = latestNomination?.delegationOriginEventId ? true : false;
  
  return (
    <div className={styles['bunker-events']}>
      <div
        className={`${styles['nomination-wrapper']} ${
          bunkerEventsMinified ? styles['isMinified'] : styles['isNotMinified']
        }`}
      >
        <Nomination
          className={styles['nomination']}
          chooseNomination={setNominationIdMethod}
          setNewNomination={setNewNominationMethod}
          activeNominationId={nominationId}
          archivedAsInitialTab={type === 'archived' ? true : false}
          minified={bunkerEventsMinified}
          shipOwner={isUserShipOwner()}
        />
        <CollapseButton
          direction={bunkerEventsMinified ? 'right' : 'left'}
          onClick={() => {
            setBunkerEventsMinified(!bunkerEventsMinified);
          }}
          className={styles['nomination-collapse']}
        />
      </div>

      {newNomination ? (
        <NewNomination
          yearMonth={yearMonth}
          chooseNomination={setNominationIdMethod}
          className={`${styles['chorus']} chorus-wrapper ${styles['new-nomination']}`}
          bunkerShips={bunkerShip}
          bunkerPipelines={bunkerPipelines}
          companies={chorusCompany}
          locationService={locationService}
          contracts={filteredContracts}
          creatingNewNominationBool={newNomination}
          creatingNominationOnBehalfBool={canCreateNominationOnBehalf(auth)}
          vendorCompanies={vendorCompanies}
          serviceLayer={serviceLayer}
        />
      ) : (
        nominationId && (
          <div className={`${styles['chorus-wrapper']} ${nominationDetailsMinified ? styles['isMinified'] : ''}`}>
            {!nominationDetailsMinified && (
              <ChorusIntegration
                setRenderChat={setRenderChat}
                className={styles['chorus']}
                nominationId={nominationId}
                yearMonth={yearMonth}
                bunkerShips={checkSupplier(auth) ? filteredBunkerShips : bunkerShip}
                bunkerPipelines={bunkerPipelines}
                companies={chorusCompany}
                locationService={locationService}
                contracts={filteredContracts}
                vendorCompanies={vendorCompanies}
                serviceLayer={serviceLayer}
                setLatestNomination={setLatestNomination}
              />
            )}
            {nominationDetailsMinified && (
              <div className={styles['minified']}>
                <div className={styles['title']}>Nomination details</div>
              </div>
            )}

            {!chatMinified && (
              <CollapseButton
                direction={chatMinified ? 'left' : 'right'}
                onClick={() => {
                  if (nominationDetailsMinified) {
                    setNominationDetailssMinified(false);
                  } else {
                    setChatMinified(!chatMinified);
                  }
                }}
                className={`${styles['chorus-collapse']} ${nominationDetailsMinified ? styles['isMinified'] : ''}`}
              />
            )}
          </div>
        )
      )}
      {nominationId && latestNomination && (
        <div
          className={`${styles['chat-wrapper']} ${chatMinified ? styles['isMinified'] : ''} ${
            nominationDetailsMinified ? styles['nomIsMinified'] : ''
          }`}
        >
          {chatComponent(
            latestNomination?.delegationOriginEventId ? latestNomination.delegationOriginEventId : nominationId,
            isDelegateChat,
          )}
          {chatMinified && (
            <div className={styles['minified']}>
              <div className={styles['title']}>Chat</div>
            </div>
          )}
          {!nominationDetailsMinified && (
            <CollapseButton
              direction={'left'}
              onClick={() => {
                if (chatMinified) {
                  setChatMinified(false);
                } else {
                  setNominationDetailssMinified(true);
                  setBunkerEventsMinified(true);
                }
              }}
              className={`${styles['chat-collapse']} ${chatMinified ? styles['isMinified'] : ''}`}
            />
          )}
        </div>
      )}
    </div>
  );
};

//@ts-ignore
export default BunkerEvents;
