import { Report } from '../../../../store/Reports/models';
import { ReportType } from '../../../../store/ReportTypes/models';
import { Company } from '../../../../store/Company/models';
import { Category } from '../../../../store/Category/models';
import { Vessel } from '../../../../store/Vessels/models';
import { FleetVessel } from '../../../../store/CompanyFleet/models';
import { RoleType } from '../../../../models/RoleType';
import { Role } from '../../../../store/Roles/models';

const initialReportType: ReportType = {
  id: '',
  name: '',
  subset: 1,
};

const initialUserFormCategory: Category = {
  id: '',
  categoryName: '',
  chorusType: '',
};

const initialUserFormCompany: Company = {
  name: '',
  country: '',
  address: '',
  orgNumber: '',
  areaOfInterest: '',
  category: initialUserFormCategory,
  invoiceAddress: '',
  invoiceEmailAddress: '',
};

// @ts-ignore
const initialUserFormRoleType: RoleType = RoleType.Operator;
const initialUserFormRole: Role = {
  id: '',
  roleType: initialUserFormRoleType,
  chorusRole: '',
};

export const initialValues: Report = {
  name: '',
  description: '',
  url: '',
  companies: [],
  //role: initialUserFormRole,
  roles: [],
  openInNewTab: false,
  type: initialReportType,
  active: true,
  companyCategories:[],
  entityId: '',
  reportId: '',
  powerBiReportId: '',
  powerBiReportRoleType: '',
  reference: '',
  companiesIds: [],
  rolesIds: [],
  companyCategoriesIds: [],
};
