import { IPosition } from './types';
import background from './background.png';
import left from './left.png';

export const getColor = (iterator: number) => {
  const colors = [
    '#0F204B',
    '#009FDA',
    '#988F86',
    '#36842D',
    '#003591',
    '#E98300',
    '#FECB00',
    '#6E5091',
    '#988F86',
    '#d12710',
  ];
  //if all colors are used, start reusing them.
  if (iterator > colors.length - 1) {
    iterator = iterator - Math.floor(iterator / colors.length) * colors.length;
  }
  if (iterator == -1) {
    iterator = 1;
  }
  const returnColor = colors[iterator];
  return hexToRgb(returnColor);
};
export const getExtendedCharacterSet = () => {
  const charSet = [];
  for (let i = 32; i < 256; i += 1) {
    charSet.push(String.fromCharCode(i));
  }
  charSet.push('\u2019');
  return charSet;
};
const hexToRgb = (hex: any) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m: any, r: any, g: any, b: any) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x: any) => parseInt(x, 16));

export const getTime = (dateString: string) => {
  if (dateString == null) {
    return '--';
  }
  const d = new Date(dateString);
  //d.getTimezoneOffset()
  const options = { dateStyle: 'short', timeStyle: 'short', hour12: false };

  const endDate = new Date();
  const minutes = Math.round((endDate.getTime() - d.getTime()) / 1000 / 60);
  if (minutes < 60) {
    if (minutes == 1) {
      return `${minutes} minute ago`;
    }
    return `${minutes} minutes ago`;
  }
  return d.toLocaleString('en-US', options);
};

export const getEtaTime = (dateString: string) => {
  if (dateString == null) {
    return '--';
  }
  const d = new Date(dateString);
  const options = { dateStyle: 'short', timeStyle: 'short', hour12: false };
  return `${d.toLocaleString('en-US', options)} UTC`;
};

export const formatCommaDate = (date: Date) => {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return [y, m, d].toString();
};

export const formatTime = (date: any) => {
  const d = new Date(date);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  };
  return d.toLocaleString('en-US', options);
};

export const titleCase = (str: any) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const getImos: any = (myFleets: any) => {
  const list: number[] = [];
  for (const fleet of myFleets) {
    for (const vessel of fleet.myFleetVessel) {
      if (vessel.visible == true) {
        list.push(vessel.vessel.imo!);
      }
    }
  }
  return list;
};

export const getImoList = (vessels: IPosition[]) => {
  const list: number[] = [];
  for (const vessel of vessels) {
    list.push(vessel.imo!);
  }
  return list;
};

export const filterOnCategories = (latest_positions: any, activeFilters: string[], filters?: string[]) => {
  filters = filters || activeFilters;
  const allLatestPositions: any = latest_positions;
  let vesselsToBeViewed: Array<any> = [];
  for (const type of filters) {
    const currentFeatures = allLatestPositions.filter((vessel: IPosition) => vessel.vesselCategory == type);
    vesselsToBeViewed = vesselsToBeViewed.concat(currentFeatures);
  }
  return vesselsToBeViewed;
};

export const filterSearch = (items: any, searchFilterValue: any) => {
  const remainingVessels = items.filter(
    (v: any) => v.title.toLowerCase().indexOf(searchFilterValue.toLowerCase()) !== -1,
  );
  return remainingVessels;
};

export const filterOnMyFleet = (latest_positions: any, myFleets: any) => {
  const allLatestPositions: any = latest_positions;
  let vesselsToBeViewed: Array<any> = [];
  for (const imo of getImos(myFleets)) {
    const currentFeatures = allLatestPositions.filter((vessel: IPosition) => vessel.imo == imo);
    vesselsToBeViewed = vesselsToBeViewed.concat(currentFeatures);
  }
  return vesselsToBeViewed;
};

export const getIconBackground = (data: IPosition) => {
  const x = getTextWidth(data.title);
  let backgroundImage = background;
  const rectangleWidth = Math.round(x * 0.63) + 40;
  const sideWidth = 24;
  let imageWidth = rectangleWidth;
  let xAnchor = -75;

  if (data.imgOrient == 2) {
    //right side
    backgroundImage = left;
    imageWidth = sideWidth;
    xAnchor = sideWidth + rectangleWidth - 3 - xAnchor;
  }
  if (data.imgOrient == 1) {
    backgroundImage = left;
    imageWidth = sideWidth;
    xAnchor = xAnchor + sideWidth - 3;
  }

  return {
    url: backgroundImage,
    x: 0,
    y: 0,
    width: imageWidth,
    id: `${data.id}${data.imgOrient}`, // necessary to create unique icons with unique widths
    height: 84,
    anchorX: xAnchor,
    mask: true,
  };
};

const charMap: any = {
  '0': 57,
  '1': 40,
  '2': 54,
  '3': 54,
  '4': 61,
  '5': 54,
  '6': 53,
  '7': 52,
  '8': 53,
  '9': 53,
  ' ': 30,
  '!': 24,
  '"': 46,
  '#': 77,
  '%': 102,
  '&': 68,
  "'": 24,
  '(': 33,
  ')': 33,
  '*': 30,
  '+': 58,
  ',': 24,
  '-': 51,
  '.': 24,
  '/': 69,
  ':': 24,
  ';': 24,
  '<': 46,
  '=': 61,
  '>': 46,
  '?': 54,
  '@': 83,
  A: 82,
  B: 66,
  C: 73,
  D: 74,
  E: 69,
  F: 55,
  G: 82,
  H: 66,
  I: 40,
  J: 55,
  K: 65,
  L: 60,
  M: 82,
  N: 73,
  O: 82,
  P: 62,
  Q: 83,
  R: 66,
  S: 63,
  T: 62,
  U: 69,
  V: 72,
  W: 101,
  X: 73,
  Y: 73,
  Z: 62,
  '': 32,
  '\\': 68,
  ']': 32,
  '^': 56,
  _: 72,
  '`': 32,
  a: 58,
  b: 58,
  c: 47,
  d: 58,
  e: 57,
  f: 28,
  g: 57,
  h: 53,
  i: 26,
  j: 26,
  k: 50,
  l: 26,
  m: 76,
  n: 53,
  o: 57,
  p: 58,
  q: 58,
  r: 32,
  s: 43,
  t: 30,
  u: 53,
  v: 61,
  w: 81,
  x: 59,
  y: 61,
  z: 46,
  '{': 29,
  '|': 22,
  '}': 29,
  '~': 62,
};

export const getTextWidth = (text: any) => {
  let totalWidth: number = 0;
  for (let i: number = 0; i < text.length; i += 1) {
    let width: number = charMap[text[i]];
    if (width == undefined) {
      width = 59; //default width in case of special chars. E.g. ö,ø
    }
    totalWidth += width;
  }

  return Math.round(totalWidth * 0.82);
};

export const zoomLookup = (width: number) => {
  let value = 4;
  if (width > 0 && width <= 1200) {
    value = 1.4;
  } else if (width > 1200 && width <= 1500) {
    value = 1.7;
  } else if (width > 1500 && width <= 1900) {
    value = 1.9;
  } else if (width > 1900 && width <= 2000) {
    value = 2.0;
  } else if (width > 2000 && width < 2450) {
    value = 2.3;
  } else if (width > 2450 && width < 3000) {
    value = 2.6;
  } else if (width > 3000 && width < 4000) {
    value = 2.9;
  } else if (width > 4000 && width < 5000) {
    value = 3.6;
  } else if (width > 5000 && width < 6000) {
    value = 3.6;
  } else {
    value = 4;
  }
  return value!;
};

export const getLongDelta = (zoom: number) => {
  if (zoom > 0 && zoom < 2.0) {
    return 28; // 1.9
  }
  if (zoom > 2.0 && zoom < 3.0) {
    return 18; // 2.53
  }
  if (zoom > 2.0 && zoom < 3.0) {
    return 10; //3.17
  }
  if (zoom > 3.3 && zoom < 4) {
    return 5; //3.396
  }
  if (zoom > 4 && zoom < 4.7) {
    return 3.6; //4.49
  }
  if (zoom > 4.7 && zoom < 5.25) {
    return 2.2; //5.04
  }
  if (zoom > 5.25 && zoom < 5.7) {
    return 1.3; //5.588
  }
  if (zoom > 5.7 && zoom < 6.5) {
    return 1.2; //6.355
  }
  if (zoom > 6.5 && zoom < 8) {
    return 0.6;
  }
  if (zoom > 8 && zoom < 9.5) {
    return 0.37;
  }
  if (zoom > 9.5 && zoom < 10.5) {
    return 0.15;
  }
  if (zoom > 10.5 && zoom < 13.5) {
    return 0.1;
  }
  if (zoom > 11.5 && zoom < 13) {
    return 0.07;
  }
  if (zoom > 13 && zoom < 15) {
    return 0.06;
  }
  if (zoom > 15) {
    return 0.0;
  }
  return 13;
};

export const getLatDelta = (zoom: number) => {
  if (zoom > 0 && zoom < 2.0) {
    return 3.5; // 1.9
  }
  if (zoom > 2.0 && zoom < 3.0) {
    return 2.1; //2.53
  }
  if (zoom > 3.0 && zoom < 4.0) {
    return 1.0; //3.17
  }
  if (zoom > 4 && zoom < 4.7) {
    return 0.49; //4.49
  }
  if (zoom > 4.7 && zoom < 5.25) {
    return 0.4; //5.04
  }
  if (zoom > 5.25 && zoom < 5.7) {
    return 0.24; //5.588
  }
  if (zoom > 5.7 && zoom < 6.5) {
    return 0.16; //6.355
  }
  if (zoom > 6.5 && zoom < 8) {
    return 0.07;
  }
  if (zoom > 8 && zoom < 9.5) {
    return 0.02;
  }
  if (zoom > 9.5 && zoom < 10.5) {
    return 0.011;
  }
  if (zoom > 10.5 && zoom < 11.5) {
    return 0.003;
  }
  if (zoom > 11.5 && zoom < 13) {
    return 0.0005;
  }
  if (zoom > 13 && zoom < 15) {
    return 0.0003;
  }
  if (zoom > 15 && zoom < 16) {
    return 0.00015;
  }
  if (zoom > 16) {
    return 0.0;
  }
  return 1.6;
};
