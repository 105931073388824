import React from 'react';
import { ReportType } from '../../../../../store/ReportTypes/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import FuelBossTableContainer from '../../../../FuelBossTable';
import { TableOptions, Column, Accessor, UseTableOptions } from 'react-table/index';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
import styled from 'styled-components';
import BackNavigation from '../../../../../components/BackNavigation/BackNavigation';

const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const minusCircle = findIconDefinition({ prefix: 'fal', iconName: 'minus-circle' });
const checkCircle = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
const PlusCircle = findIconDefinition({  prefix: 'fal', iconName: 'plus-circle'  });

const addReportIcon = findIconDefinition({
  prefix: 'fal',
  iconName: 'user-plus',
});

const StyledReportTableWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

interface OwnProps {
  reporttypes: ReportType[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteReport: (id: string) => void;
  editReportType: (report: ReportType) => void;
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const ReportTypes: React.FC<OwnProps> = ({
  reporttypes,
  isLoading,
  hasError,
  errorDetails,
  deleteReport,
  editReportType,
  companyAdmin,
  company,
  navAdminType,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const headersNew: Array<Column<ReportType>> = React.useMemo(
    () => [
      {
        Header: 'Report name',
        accessor: 'name',
      },
      {
        Header: 'Subset',
        accessor: 'subset',
      },
      {
        Header: 'Show add vessel',
        accessor: (row) => {
          if (row.showAddVessel) return 'Yes';
          else return 'No';
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const data = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={data.id ? data.id : ''}
                deleteMethod={deleteReport}
                header="Delete report"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const report = props.cell.row.original;
          return (
            <div className="edit-cell">
              <EditById redirectUrl={`/${navAdminType}admin/reports/${report.id}/edittype`} />
            </div>
          );
        },
      },
    ],
    [],
  );

  const history = useHistory();
  const goBack = () => {
    history.push(`/${navAdminType}admin/reports`);
  }

  if (isLoading) {
    return <Loading />;
  } else if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledReportTableWrapper>
        {goBack && <BackNavigation onClick={goBack} />}
        <h3>Report types</h3>
        <div className="buttons-div">
          <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/reports/addtype`)}>
            <FontAwesomeIcon icon={PlusCircle} /> Add report type
          </Button>
        </div>
        {reporttypes && reporttypes.length ? (
          <FuelBossTableContainer
            headers={headersNew}
            tableRows={reporttypes}
            showPagination={true}
            defaultSelectedPage={defaultSelectedPage}
            setDefaultSelectedPage={setDefaultSelectedPage}
          />
        ) : null}
      </StyledReportTableWrapper>
    );
  }
};

export default React.memo(ReportTypes);
