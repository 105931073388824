import React from 'react';
import styles from './NoNominations.module.scss';
import classnames from 'classnames';

interface OwnProps {
  archived: boolean;
  className?: string;
}

const NoNominations: React.FC<OwnProps> = ({ archived, className }) => {
  if (!archived) {
    return <div className={classnames(styles['new-nomination-text'], className)}>No bunker events.</div>;
  } else {
    return <div className={classnames(styles['new-nomination-text'], className)}>No archived nominations.</div>;
  }
};

export default NoNominations;
