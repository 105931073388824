import { css } from 'styled-components';
import PropTypes from 'prop-types';
import myTheme from '../../theme/myTheme';
import { textBase } from './TextBase';

export const buttonBase = css`
  ${textBase};
  height: ${({ theme: { button } }) => button.height};
  min-width: ${({ theme: { button } }) => button.minWidth};
  border: ${({ theme: { button } }) => button.border};
  display: ${({ theme: { button } }) => button.display};
  align-items: ${({ theme: { button } }) => button.alignItems};
  justify-content: ${({ theme: { button } }) => button.justifyContent};
  font-weight: ${({ theme: { button } }) => button.fontWeight};
  border-radius: ${({ theme: { button } }) => button.borderRadius};
  padding: ${({ theme: { button } }) => button.padding};
  text-decoration: ${({ theme: { button } }) => button.textDecoration};
  text-align: ${({ theme: { button } }) => button.textAlign};
  transition: ${({ theme: { button } }) => button.transition};
`;

export const buttonProps = {
  theme: PropTypes.object,
};

export const buttonDefaults = {
  theme: {
    button: myTheme.button,
    color: myTheme.colors.primaryWhite,
    font: myTheme.fontSizes.small,
  },
};
