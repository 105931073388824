import * as TYPE from './types';

import { ApplicationState } from '..';

export const nominationReducer = (
  state: ApplicationState['nomination'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: undefined,
  },
  action: TYPE.NominationActions,
): ApplicationState['nomination'] => {
  switch (action.type) {
    case TYPE.NOMINATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.NOMINATION_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.NOMINATION_ARCHIVE_ERROR:
      return {
        ...state,
        error: true,
        errorDetails: action.payload,
        toastData: {
          content: 'The nomination could not be archived. Please contact FuelBoss support for more details',
          type: 'error',
        },
      };
    case TYPE.ARCHIVE_NOMINATION:
      return {
        ...state,
        data: state.data.filter((n) => n.eventId !== action.payload.eventId),
        toastData: {
          content: 'Nomination archived',
          type: 'info',
        },
      };
    case TYPE.GET_NOMINATION:
      if (action.payload.metaData) {
        return {
          ...state,
          data: action.payload.data,
          metadata: action.payload.metaData,
          loading: false,
          error: false,
          dataIsLoaded: true,
        };
      }
      var refDate = new Date();
      // reference day (current - 2days) used to consider active nominations as archieved
      refDate.setDate(refDate.getDate() - 7);
      return {
        ...state,
        data: action.payload.data.filter((x) => new Date(x.bst).getTime() >= refDate.getTime()),
        loading: false,
        error: false,
        dataIsLoaded: true,
      };

    case TYPE.GET_NOMINATION_INFINITE_SCROLL_APPEND:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        loading: false,
        error: false,
        dataIsLoaded: true,
      };

    case TYPE.GET_NOMINATION_INFINITE_SCROLL_REFRESH:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.CLEAR_NOMINATION:
      return {
        loading: false,
        error: false,
        dataIsLoaded: false,
        data: [],
        metadata: undefined,
      };
    default:
      return { ...state };
  }
};
