import * as React from 'react';
import styles from './Table.module.scss';
import { useState, useEffect } from 'react';
import Table from './Components/Table/Table';
import Pagination from './Components/Pagination/Pagination';

interface OwnProps {
  headers: string[];
  // rows: JSX.Element[];
  numberOfRowsPerPagination: number;
  children: React.ReactElement[];
  paginationOnBottom?: boolean;
  striped?: boolean;
  hover?: boolean;
  responsive?: boolean;
  centerPagination?: boolean;
  noPagination?: boolean;
  className?: string;
  headerStyling?: React.CSSProperties;
}

const TableContainer: React.FC<OwnProps> = ({
  headers,
  numberOfRowsPerPagination,
  children,
  paginationOnBottom = false,
  striped = true,
  hover = true,
  responsive = true,
  noPagination = false,
  centerPagination,
  className,
  headerStyling,
}) => {
  const FirstIndex = () => {
    const index = numberOfRowsPerPagination * (currentPage - 1);
    return index;
  };

  const LastIndex = () => {
    const index = numberOfRowsPerPagination * currentPage;
    return index;
  };

  // debugger;
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(children.length / numberOfRowsPerPagination);
  const [rowsToRender, setRowsToRender] = useState(children.slice(FirstIndex(), LastIndex()));

  useEffect(() => {
    setRowsToRender(children.slice(FirstIndex(), LastIndex()));
  }, [currentPage]);

  useEffect(() => {
    setRowsToRender(children.slice(FirstIndex(), LastIndex()));
  }, [children]);

  if (noPagination) {
    return (
      <div className={`${styles['center-content']} ${className}`}>
        <Table
          headerStyle={headerStyling}
          headers={headers}
          numberOfRowsPerPagination={999}
          striped={striped}
          hover={hover}
          responsive={responsive}
        >
          {rowsToRender}
        </Table>
      </div>
    );
  }

  if (paginationOnBottom) {
    return (
      <div className={`${styles['center-content']} ${className}`}>
        <Table
          headers={headers}
          numberOfRowsPerPagination={numberOfRowsPerPagination}
          striped={striped}
          hover={hover}
          responsive={responsive}
        >
          {rowsToRender}
        </Table>
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} maxPage={maxPage} />
      </div>
    );
  } else {
    return (
      <div className={className}>
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} maxPage={maxPage} />
        <Table
          headers={headers}
          numberOfRowsPerPagination={numberOfRowsPerPagination}
          striped={striped}
          hover={hover}
          responsive={responsive}
        >
          {rowsToRender}
        </Table>
      </div>
    );
  }
};

export default TableContainer;
