import {
  AuthenticationServiceLayer,
  ICompany,
  IContract,
  IFleetShip,
  IPipeline,
  IPromptNomination,
  isBunkerCaptain,
  isCustomer,
  IShipWithId,
  isReceivingCaptain,
  isScheduler,
  isSchedulerCaptain,
  IUserProfile,
  LocationService,
  IThirdPartyContact,
  isSupplier,
  useThirdPartyContactService,
  IShipWithCompanyId,
} from '@teqplay/chorus-components';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { RecurringNomination } from '../../../../../store/RecurringNomination/models';
import CustomerRecurringNominationForm from './CustomerRecurringNominationForm';
import SupplierRecurringNominationForm from './SupplierRecurringNominationForm';

interface IRecurringNominationFormProps {
  creatingNewNomination: boolean;
  values: RecurringNomination;
  errors?: any;
  currentUser: IUserProfile;
  prevValues?: RecurringNomination;
  authenticationService: AuthenticationServiceLayer;
  locationService: LocationService;
  bunkerShips: IShipWithCompanyId[];
  pipelines: IPipeline[];
  customerFleet: IFleetShip[];
  companies: ICompany[];
  //creatingNominationOnBehalf?: boolean
  //onBehalfCompany?: string
  showDeliveryModeSelection: boolean;
  contractsList: IContract[];
  vendorsWithContract: Set<string>;
  enableAlternativeFuelSelector: boolean;
  editType: 'EDIT' | 'EDIT_ON_BEHALF';
  customerCompanyName: string;
  onChangeFormValue: (field: string, value: string | boolean | number | object | undefined | null) => void;
  setAllValues: (values: RecurringNomination) => void;
  nominationEventToCompareWith?: RecurringNomination;
  disabled?: boolean;
}

const RecurringNominationForm: React.FunctionComponent<IRecurringNominationFormProps> = (props) => {
  // Hooks
  const [currentNomination, setCurrentNomination] = useState(props.values);

  const [companyContacts, setCompanyContacts] = useState<IThirdPartyContact[]>([]);
  const { allowedContacts, saveThirdPartyContact } = useThirdPartyContactService(
    props.authenticationService,
    props.currentUser.companyId,
    props.currentUser.roles,
  );

  useEffect(() => {
    setCompanyContacts(allowedContacts);
  }, [allowedContacts]);

  useEffect(() => {
    setCurrentNomination(props.values);
  }, [props.values]);

  // Get filtered 3rd party contacts according to the company type (Supplier | Customer)
  const [nominationContacts, setNominationContacts] = useState<IThirdPartyContact[]>([]);

  useEffect(() => {
    setNominationContacts(getNominationContacts());
  }, [companyContacts]);

  // Properties
  const { currentUser, errors, editType, disabled } = props;
  const previousNomination = props.prevValues;
  const userRoles = currentUser.roles;

  const vendorCompanies = props.companies.filter((company) => company.companyType === 'VENDOR');
  const selectedVendor = currentNomination.vendorCompanyId
    ? props.companies.find((company) => company._id === currentNomination.vendorCompanyId)
    : undefined;

  // filters the contracts to make sure it's only contracts associated with the nomination company
  const companyContracts = props.contractsList.filter(
    (contract) =>
      contract.companyId === currentNomination.companyId &&
      contract.vendorCompanyId === currentNomination.vendorCompanyId,
  );

  // filter the vendor companies so only the companies with whom you have a contract appear.
  const vendorCompaniesFilteredByContract = vendorCompanies?.filter((company) => {
    return props.vendorsWithContract.has(company._id);
  });

  // sort the companies alphabetically to look more pleasing in the selectbox
  const vendorCompaniesSorted = (vendorCompaniesFilteredByContract || []).sort((a, b) => {
    return sortAlphabetically(a, b, 'name');
  });

  return (
    <>
      {(isScheduler(userRoles) || isBunkerCaptain(userRoles)) && editType === 'EDIT' && (
        <SupplierRecurringNominationForm
          bunkerShips={props.bunkerShips}
          contracts={companyContracts}
          currentNomination={currentNomination}
          creatingNewNomination={props.creatingNewNomination}
          currentUser={currentUser}
          getPreviousValueLabel={getPreviousValueLabel}
          locationService={props.locationService}
          onChangeDateTimeValue={onChangeDateTimeValue}
          onChangeFormValue={props.onChangeFormValue}
          setAllValues={props.setAllValues}
          setMultipleValues={setMultipleValues}
          pipelines={props.pipelines}
          showDeliveryModeSelection={props.showDeliveryModeSelection}
          errors={errors}
          previousNomination={previousNomination}
          selectedVendor={selectedVendor}
          enableAlternativeFuelSelector={props.enableAlternativeFuelSelector}
          customerCompanyName={props.customerCompanyName}
          thirdPartyContacts={companyContacts}
          nominationContacts={nominationContacts}
          saveThirdPartyContact={saveThirdPartyContact}
          nominationEventToCompareWith={props.nominationEventToCompareWith}
          disabled={disabled}
          companies={props.companies}
        >
          {props.children}
        </SupplierRecurringNominationForm>
      )}

      {(isCustomer(userRoles) || isReceivingCaptain(userRoles)) && (
        <CustomerRecurringNominationForm
          creatingNewNomination={props.creatingNewNomination}
          currentNomination={currentNomination}
          currentUser={currentUser}
          getPreviousValueLabel={getPreviousValueLabel}
          locationService={props.locationService}
          onChangeDateTimeValue={onChangeDateTimeValue}
          onChangeFormValue={props.onChangeFormValue}
          showDeliveryModeSelection={props.showDeliveryModeSelection}
          errors={errors}
          previousNomination={previousNomination}
          selectedVendor={selectedVendor}
          customerFleet={props.customerFleet}
          setMultipleValues={setMultipleValues}
          vendorCompanies={vendorCompaniesSorted}
          enableAlternativeFuelSelector={props.enableAlternativeFuelSelector}
          customerCompanyName={props.customerCompanyName}
          thirdPartyContacts={companyContacts}
          nominationContacts={nominationContacts}
          saveThirdPartyContact={saveThirdPartyContact}
          nominationEventToCompareWith={props.nominationEventToCompareWith}
          disabled={disabled}
        >
          {props.children}
        </CustomerRecurringNominationForm>
      )}
    </>
  );

  function getPreviousValueLabel(
    field: keyof RecurringNomination,
    nominationValues?: RecurringNomination,
    prevNominationValues?: RecurringNomination,
  ) {
    if (nominationValues && prevNominationValues) {
      if (nominationValues[field] !== prevNominationValues[field]) {
        return prevNominationValues[field];
      }
    }
    return undefined;
  }

  function onChangeDateTimeValue(isoDate: string | null, inputValue: string, isValid: boolean, fieldName?: string) {
    if (fieldName && props.onChangeFormValue) {
      const valueToUpdate = isoDate || inputValue;
      props.onChangeFormValue(fieldName, valueToUpdate);
    }
  }

  async function setMultipleValues(fieldsToUpdate: { field: string; value: string | null | any[] }[]) {
    const newValues = cloneDeep(currentNomination);

    fieldsToUpdate.forEach((fieldUpdate) => {
      // check error here
      // @ts-ignore
      newValues[fieldUpdate.field] = fieldUpdate.value;
    });

    if (props.setAllValues) {
      props.setAllValues(newValues);
    }

    setCurrentNomination(newValues);
  }

  function getNominationContacts() {
    const contactType = isSupplier(props.currentUser.roles)
      ? 'vendorThirdPartyContactIds'
      : 'customerThirdPartyContactIds';

    const nominationContacts = companyContacts.filter((contact) => {
          return currentNomination[contactType]?.split(',').find((contactId) => contact._id === contactId);
    })

    return nominationContacts;
  }
};

export default RecurringNominationForm;

function sortAlphabetically(a: any, b: any, propertyToSort: string) {
  if (a[propertyToSort] < b[propertyToSort]) {
    return -1;
  }
  if (a[propertyToSort] > b[propertyToSort]) {
    return 1;
  }
  return 0;
}
