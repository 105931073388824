import * as TYPE from './types';
import { ApplicationState } from '..';
import { IntegrationOutboundApiTracker } from './models';

export const outboundIntegrationApiTrackerReducer = (
  state: ApplicationState['integration_outbound_api_tracker'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: {} as IntegrationOutboundApiTracker,
    metadata: {},
  },
  action: TYPE.OutboundIntegrationApiTrackerActions,
): ApplicationState['integration_outbound_api_tracker'] => {
  switch (action.type) {
    case TYPE.OUTBOUNDINTEGRATIONAPITRACKER_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.OUTBOUNDINTEGRATIONAPITRACKER_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
      case TYPE.GET_OUTBOUNDINTEGRATIONAPITRACKER:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
          dataIsLoaded: true,
        };
    case TYPE.OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_OUTBOUNDINTEGRATIONAPITRACKER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'ApiTracker Settings successfully created!',
          type: 'success',
        },
      };

    case TYPE.OUTBOUNDINTEGRATIONAPITRACKER_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not create the ApiTracker Settings. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_OUTBOUNDINTEGRATIONAPITRACKER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'ApiTracker Settings edited',
          type: 'info',
        },
      };
    case TYPE.OUTBOUNDINTEGRATIONAPITRACKER_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not edit the ApiTracker Settings. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    case TYPE.DELETE_OUTBOUNDINTEGRATIONAPITRACKER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'ApiTracker Settings successfully deleted!',
          type: 'success',
        },
      };
    case TYPE.OUTBOUNDINTEGRATIONAPITRACKER_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not delete the ApiTracker Settings. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
      case TYPE.OUTBOUNDINTEGRATIONASSET_ADD_ERROR:
        return {
          ...state,
          loading: false,
          toastData: {
            content: action.payload.message
              ? action.payload.message
              : `We could not add asset. Please contact FuelBoss support for more details`,
            type: 'error',
          },
        };
        case TYPE.ADD_OUTBOUNDINTEGRATIONASSET:
          return {
            ...state,
            loading: false,
            error: false,
            toastData: {
              content: 'Outbound Asset successfully created!',
              type: 'success',
            },
          };
    default:
      return { ...state };
  }
};
