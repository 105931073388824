export default {
  name: {
    label: 'Location name',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
  portAbbreviation: {
    label: 'Port abbreviation',
    show: true,
    placeholder: 'Please choose a port abbreviation',
  },
  areaOfOperation: {
    label: 'Area of operation',
    show: true,
    placeholder: '',
  },
  countryAbbreviation: {
    label: 'Country abbreviation',
    show: true,
    placeholder: '',
  },
  portType: {
    label: 'Port type',
    show: true,
    placeholder: '',
  },
  longitude: {
    label: 'Longitude',
    show: true,
    placeholder: '',
  },
  latitude: {
    label: 'Latitude',
    show: true,
    placeholder: '',
  },
  port: {
    label: 'Port',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
  country: {
    label: 'Country',
    show: true,
    placeholder: 'Please choose a country',
  },
  purpose: {
    label: 'Purpose',
    show: true,
    placeholder: 'Please choose a purpose',
    selections: [
      { id: 'ANCHORAGE', name: 'ANCHORAGE' },
      { id: 'CAR', name: 'CAR' },
      { id: 'CONTAINER', name: 'CONTAINER' },
      { id: 'CRUISE', name: 'CRUISE' },
      { id: 'DOLPHINS', name: 'DOLPHINS' },
      { id: 'GENERAL', name: 'GENERAL' },
      { id: 'LNG', name: 'LNG' },
      { id: 'OIL', name: 'OIL' },
    ],
  },
  type: {
    label: 'Type',
    show: true,
    placeholder: 'Please choose a type',
    selections: [
      { id: 'GENERAL', name: 'GENERAL' },
      { id: 'LOADING', name: 'LOADING' },
    ],
  },
  timezone: {
    label: 'Timezone',
    show: true,
    placeholder: 'Please choose a timezone',
  },
};
