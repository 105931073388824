import { string } from 'prop-types'
import { IntegrationAsset } from '../../../../../store/IntegrationAsset/models'

export const initialValues: IntegrationAsset = {
    id: '00000000-0000-0000-0000-000000000000',
    veracityAssetId: '',
    assetName: '',
    assetExternalName: '',
    assetDescription: '',
    assetLocation: '',
    assetType: '',
    assetTypeId: '',
    ownerCompanyId: '',
    timeStamp: new Date,
    assetIssuers: [],
    assetTopics: [],
    workspaceId: '',
}