import { IntegrationAssetIssuersWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_ISSUERS_CLEAR_TOAST = 'integrationAssetIssuers/ClearToast';
export type INTEGRATION_ASSET_ISSUERS_CLEAR_TOAST = typeof INTEGRATION_ASSET_ISSUERS_CLEAR_TOAST;

export const INTEGRATION_ASSET_ISSUERS_LOADING = 'integrationAssetIssuers/loading';
export type INTEGRATION_ASSET_ISSUERS_LOADING = typeof INTEGRATION_ASSET_ISSUERS_LOADING;

export const GET_INTEGRATION_ASSET_ISSUERS = 'integrationAssetIssuers/get';
export type GET_INTEGRATION_ASSET_ISSUERS = typeof GET_INTEGRATION_ASSET_ISSUERS;

export const INTEGRATION_ASSET_ISSUERS_FETCH_ERROR = 'integrationAssetIssuers/fetchError';
export type INTEGRATION_ASSET_ISSUERS_FETCH_ERROR = typeof INTEGRATION_ASSET_ISSUERS_FETCH_ERROR;

export const ADD_INTEGRATION_ASSET_ISSUERS = 'integrationAssetIssuers/add';
export type ADD_INTEGRATION_ASSET_ISSUERS = typeof ADD_INTEGRATION_ASSET_ISSUERS;

export const EDIT_INTEGRATION_ASSET_ISSUERS = 'integrationAssetIssuers/edit';
export type EDIT_INTEGRATION_ASSET_ISSUERS = typeof EDIT_INTEGRATION_ASSET_ISSUERS;

export const DELETE_INTEGRATION_ASSET_ISSUERS = 'integrationAssetIssuers/delete';
export type DELETE_INTEGRATION_ASSET_ISSUERS = typeof DELETE_INTEGRATION_ASSET_ISSUERS;

export const INTEGRATION_ASSET_ISSUERS_ADD_ERROR = 'integrationAssetIssuers/addError';
export type INTEGRATION_ASSET_ISSUERS_ADD_ERROR = typeof INTEGRATION_ASSET_ISSUERS_ADD_ERROR;

export const INTEGRATION_ASSET_ISSUERS_EDIT_ERROR = 'integrationAssetIssuers/editError';
export type INTEGRATION_ASSET_ISSUERS_EDIT_ERROR = typeof INTEGRATION_ASSET_ISSUERS_EDIT_ERROR;

export const INTEGRATION_ASSET_ISSUERS_DELETE_ERROR = 'integrationAssetIssuers/deleteError';
export type INTEGRATION_ASSET_ISSUERS_DELETE_ERROR = typeof INTEGRATION_ASSET_ISSUERS_DELETE_ERROR;

export type IntegrationAssetIssuersActions =
  | {
      type: INTEGRATION_ASSET_ISSUERS_LOADING;
    }
  | {
      type: GET_INTEGRATION_ASSET_ISSUERS;
      payload: IntegrationAssetIssuersWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_ASSET_ISSUERS;
      payload: IntegrationAssetIssuersWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_ASSET_ISSUERS;
      payload: IntegrationAssetIssuersWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_ISSUERS;
      payload: IntegrationAssetIssuersWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_ISSUERS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_ISSUERS_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_ISSUERS_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_ISSUERS_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_ISSUERS_CLEAR_TOAST;
    };
