import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import React from 'react';
import Vessels from './Vessels';
import { ApplicationState } from '../../../store';
import { getVessels, addVessel, deleteVessel, editVessel } from '../../../store/Vessels/actions';
import { AddToast } from '../../../helpers/toastService';

const VesselsContainer = () => {
  const { vesselsLoading, vesselsDetailsError, vesselsErrorDetails, vessels, vesselsIsLoaded, toastData } = useSelector(
    (state: ApplicationState) => ({
      vesselsLoading: state.vessels.loading,
      vesselsDetailsError: state.vessels.error,
      vesselsErrorDetails: state.vessels.errorDetails,
      vessels: state.vessels.data,
      vesselsIsLoaded: state.vessels.dataIsLoaded === true,
      toastData: state.vessels.toastData,
    }),
  );

  const dispatch = useDispatch();

  const getVesselsCallback = useCallback(() => dispatch(getVessels()), []);

  //Add vessel methods
  const addVesselCallback = useCallback(
    (name: string, type: string, imo: number | undefined) => dispatch(addVessel(imo, name, type)),
    [],
  );
  // @ts-ignore
  const addNewVessel = (name: string, type: string, imo: number | undefined) => {
    addVesselCallback(name, type, imo);
  };

  //Delete vessel methods
  const deleteVesselCallback = useCallback((id: string) => dispatch(deleteVessel(id)), []);

  //Edit vessel methods
  const editVesselCallback = useCallback(
    (id: string, imo: number | undefined, vesselName: string, vesselType: string) =>
      dispatch(editVessel(id, imo, vesselName, vesselType)),
    [],
  );

  useEffect(() => {
    if (!vesselsIsLoaded) {
      getVesselsCallback();
    }
  }, []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
    }
  }, [toastData]);

  return (
    <>
      {/* Commenting out add functionality for MVP */}
      {/* <AddVessel addNewVessel={addNewVessel} /> */}
      <Vessels
        vessels={vessels}
        isLoading={vesselsLoading}
        hasError={vesselsDetailsError}
        errorDetails={vesselsErrorDetails}
        deleteVessel={deleteVesselCallback}
        editVessel={editVesselCallback}
      />
    </>
  );
};

export default VesselsContainer;
