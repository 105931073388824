import React from 'react';
import { Button } from 'reactstrap';

interface OwnProps {
  color?: string;
  outline?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const FbButton: React.FC<OwnProps> = React.memo(({ color, outline, children, onClick }) => (
  <Button outline={outline === true} color={color} onClick={onClick}>
    {children}
  </Button>
));
