import React, { useEffect } from 'react';
import { BDNLocation, LocationGeneratedBy } from '../../../../store/BDNLocation/models';
import { ErrorActionPayload } from '../../../../models/ErrorAction';
import { Role } from '../../../../store/Roles/models';
import { ICompany, ILocation, LocationSearch, LocationService } from '@teqplay/chorus-components';
import { Company } from '../../../../store/Company/models';
import { Loading } from '../../../Loading';
import { Error } from '../../../Error';
import { Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Card, CardBody, Collapse, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { StyledTableWrapper } from '../../StyledComponents/StyledTableWrapper';
import FuelBossTableContainer from '../../../FuelBossTable';
import { TableOptions, Column, Accessor, UseTableOptions } from 'react-table/index';
import DeleteById from '../../../DeleteById/DeleteById';
import { getBDNLocations, deleteBDNLocations, addBDNLocation } from '../../../../store/BDNLocation/actions';
import styles from './BDNLocations.module.scss';
import { useState } from 'react';
import { ApplicationState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCompanies } from '../../../../store/Company/actions';
import DeliveryModeIcon from '../../../../components/Nomination/Components/DeliveryModeIcon/DeliveryModeIcon';

const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const syncIcon = findIconDefinition({ prefix: 'fal', iconName: 'sync' });
const addIcon = findIconDefinition({ prefix: 'fal', iconName: 'plus' });
const plusCircle = findIconDefinition({ prefix: 'fal', iconName: 'plus-circle' });
const faSave = findIconDefinition({ prefix: 'fal', iconName: 'save' });

const StyledReportTableWrapper = styled.div`
  position: relative;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

const StyledCellWrapper = styled.div`
  margin-left: 25px;
  margin-top: -10px;
`;

styled.select`
  border-radius: 0px;
  width: 300px;
  height: 50px;
`;

interface OwnProps {
  bdnlocations: BDNLocation[];
  isLoading: boolean;
  hasError: boolean;
  addBDNLocations: (bdnLocation: BDNLocation) => void;
  deleteBDNLocation: (id: string) => void;
  errorDetails: ErrorActionPayload | undefined;
  suppliers: Company[];
  companyAdmin?: boolean;
  systemAdmin?: boolean;
  company?: Company;
  navAdminType: string;
  defaultSelectedPage?: number;
  locationService: LocationService;
  setDefaultSelectedPage?: (number: number) => void;
}

const BDNLocations: React.FC<OwnProps> = ({
  bdnlocations,
  isLoading,
  hasError,
  errorDetails,
  addBDNLocations,
  deleteBDNLocation,
  systemAdmin,
  companyAdmin,
  company,
  suppliers,
  navAdminType,
  defaultSelectedPage,
  locationService,
  setDefaultSelectedPage,
}) => {
  const {
    bdnlocationLoading,
    bdnlocationDetailsError,
    bdnlocationErrorDetails,
    bdnlocation,
    bdnlocationIsLoaded,
    toastData,
    companyLoading,
    companyError,
    companies,
    companyIsLoaded,
    auth,
  } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    bdnlocationLoading: state.bdn_locations.loading,
    bdnlocationDetailsError: state.bdn_locations.error,
    bdnlocationErrorDetails: state.bdn_locations.errorDetails,
    bdnlocation: state.bdn_locations.data,
    bdnlocationIsLoaded: state.bdn_locations.dataIsLoaded,
    toastData: state.bdn_locations.toastData,
    companyLoading: state.companies.loading,
    companyError: state.companies.error,
    companies: state.companies.data,
    companyIsLoaded: state.companies.dataIsLoaded,
  }));
  const dispatch = useDispatch();
  const getBDNLocationsCallback = React.useCallback(() => dispatch(getBDNLocations()), []);

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  useEffect(() => {
    if (!bdnlocationIsLoaded) {
      getBDNLocationsCallback();
    }
    if (!companyIsLoaded) {
      getCompaniesCallback();
    }
  }, []);

  const [rerenderPlaceHolder, setRerenderPlaceHolder] = React.useState(false);

  const [isActive, setIsActive] = React.useState(true);

  const [singleBDNLocation, setSingleBDNLocation] = React.useState<BDNLocation>(({
    id: '00000000-0000-0000-0000-000000000000',
    company: { companyId: '00000000-0000-0000-0000-000000000000', name: '' } as Company,
    locationId: '00000000-0000-0000-0000-000000000000',
    location: {
      locationId: '00000000-0000-0000-0000-000000000000',
      chorusId: undefined,
      name: '',
      portAbbreviation: '',
      areaOfOperation: '',
      portType: '',
      port: '',
      countryAbbreviation: '',
      longitude: 0.0,
      latitude: 0.0,
      country: '',
      purpose: 'GENERAL',
      type: '',
      createdDated: undefined,
      modifiedDate: undefined,
    },
  } as unknown) as BDNLocation);

  const [addedBDNLocation, setAddedBDNLocation] = React.useState(false);

  const [jointBDNLocation, setJointBDNLocation] = React.useState<BDNLocation[]>([] as BDNLocation[]);

  useEffect(() => {
    if (addedBDNLocation == undefined) setAddedBDNLocation(false);
  });

  useState(() => {
    setJointBDNLocation([...bdnlocations]);
  });

  const addRow = () => {
    if (addedBDNLocation == false) {
      setJointBDNLocation([singleBDNLocation, ...bdnlocations]);
      setAddedBDNLocation(!addedBDNLocation);
      return;
    } else {
      setJointBDNLocation([...bdnlocations]);
      setAddedBDNLocation(!addedBDNLocation);
    }
  };

  const onBDNLocationSave = () => {
    if (singleBDNLocation.active == undefined) {
      singleBDNLocation.active = true;
    }

    if( singleBDNLocation.supplierId == '00000000-0000-0000-0000-000000000000' || singleBDNLocation.supplierId == undefined || singleBDNLocation == null) {
      if (suppliers) if (suppliers[0].companyId) singleBDNLocation.supplierId = suppliers[0].companyId;
    }
    if (singleBDNLocation.deliveryMode == undefined) {
      singleBDNLocation.deliveryMode = 'SHIP';
    }
    if (singleBDNLocation.location.chorusId == undefined || singleBDNLocation.location.chorusId == '') {
      return false;
    }
    addBDNLocations(singleBDNLocation);
  };

  const locationValidation = (singleBDNLocation: BDNLocation) => {
    if (
      singleBDNLocation.location.chorusId == undefined ||
      singleBDNLocation.location.chorusId == '' ||
      singleBDNLocation.location.chorusId == null
    ) {
      return false;
    }
    return true;
  };

  const updateBDNLocationCustomValues = (
    id: string,
    supplierId: string,
    locationId: string,
    deliveryMode: string,
    active: boolean,
  ) => {
    const oldBdnLocationCustomValues = singleBDNLocation;
    oldBdnLocationCustomValues.deliveryMode = deliveryMode;
    oldBdnLocationCustomValues.location.chorusId = locationId;
    suppliers.filter((x) => {
      if (x.companyId == supplierId) {
        oldBdnLocationCustomValues.supplierId = supplierId;
      }
    });
    oldBdnLocationCustomValues.active = active;
    setSingleBDNLocation(oldBdnLocationCustomValues);
    setRerenderPlaceHolder(!rerenderPlaceHolder);
  };

  const AddCellSupplier = ({ value: initialValue, row: { index }, column: { id }, updateMyData, cell }: any) => {
    const [value, setValue] = React.useState(initialValue);
    const bdnlocationId = cell.row.original.id;
    const onChange = (e: any) => {
      setValue(e.target.value);
      updateBDNLocationCustomValues(
        bdnlocationId,
        e.target.value,
        singleBDNLocation.location.chorusId ? singleBDNLocation.location.chorusId : '',
        singleBDNLocation.deliveryMode,
        singleBDNLocation.active,
      );
    };
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (bdnlocationId == '00000000-0000-0000-0000-000000000000' || bdnlocationId == null) {
      return (
        <Input
          type="select"
          placeholder="Select Company"
          name="company"
          id="company"
          value={singleBDNLocation.supplierId}
          onChange={onChange}
          className={styles['input-select']}
        >
          {suppliers.map((x) => (
            <option value={x.companyId}>{x.name}</option>
          ))}
        </Input>
      );
    } else {
      return (
        <span>{cell.row.original.company.name && cell.row.original.company ? cell.row.original.company.name : ''}</span>
      );
    }
  };

  const AddDeliveryMode = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance,
    cell,
  }: any) => {
    const [value, setValue] = useState(initialValue);
    const bdnlocationId = cell.row.original.id;
    const hasCustomValues = cell.row.original.hasCustomValues;

    const selectedBdnLocation = bdnlocation.find((x) => x.id == cell.row.original.id);
    const deliveryModes = ['SHIP', 'TRUCK', 'PIPE', 'CONTAINER'];

    const onChange = (e: any) => {
      setValue(e.target.value);
      updateBDNLocationCustomValues(
        bdnlocationId,
        singleBDNLocation.supplierId,
        singleBDNLocation.location.chorusId ? singleBDNLocation.location.chorusId : '',
        e.target.value,
        singleBDNLocation.active,
      );
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (bdnlocationId == '00000000-0000-0000-0000-000000000000' || bdnlocationId == null) {
      return (
        <>
          <div>
            <Input
              type="select"
              placeholder="Select Delivery Mode"
              name="deliveryMode"
              id="deliveryMode"
              onChange={onChange}
              value={singleBDNLocation.deliveryMode}
              className={styles['input-select']}
              defaultValue="SHIP"
            >
              {deliveryModes.map((x) => (
                <option>{x}</option>
              ))}
            </Input>
          </div>
        </>
      );
    } else {
      return (
        <DeliveryModeIcon
          deliveryMode={
            hasCustomValues && singleBDNLocation.deliveryMode
              ? singleBDNLocation.deliveryMode
              : selectedBdnLocation?.deliveryMode
          }
        />
      );
    }
  };

  const AddLocationCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance,
    cell,
  }: any) => {
    const [locationName, setlocationName] = useState(' ');
    const bdnlocationId = cell.row.original.id;
    const selectedBdnLocation = bdnlocation.find((x) => x.id == cell.row.original.id);
    const [value, setValue] = useState(initialValue);

    const onChange = (val: any) => {
      updateBDNLocationCustomValues(
        bdnlocationId,
        singleBDNLocation.supplierId,
        val,
        singleBDNLocation.deliveryMode,
        singleBDNLocation.active,
      );
    };
    if (bdnlocationId == '00000000-0000-0000-0000-000000000000') {
      const value = !singleBDNLocation.location.chorusId ? '' : singleBDNLocation.location.chorusId || '';
      const validation = locationValidation(singleBDNLocation);
      return (
        <div className={`${styles['input-select']}  ${!validation ? styles['invalid-input-select'] : ''}`}>
          <LocationSearch
            locationService={locationService}
            onChange={onChange}
            label=""
            placeholder={'Select Port...'}
            disabled={false}
            fieldName="locationId"
            error={undefined}
            value={!singleBDNLocation.location.chorusId ? '' : singleBDNLocation.location.chorusId || ''}
            prevValue={undefined}
          />
        </div>
      );
    } else {
      return cell.row.original.location.port;
    }
  };

  const AddCellActive = ({ value: initialValue, row: { index }, column: { id }, cell }: any) => {
    const [value, setValue] = React.useState<boolean>(true);
    const bdnlocationId = cell.row.original.id;
    const selectedBdnLocation = bdnlocation.find((x) => x.id == cell.row.original.id);
    const onChange = (e: any) => {
      setValue(!value);
      updateBDNLocationCustomValues(
        bdnlocationId,
        singleBDNLocation.supplierId,
        singleBDNLocation.location.chorusId ? singleBDNLocation.location.chorusId : '',
        singleBDNLocation.deliveryMode,
        !value,
      );
    };

    React.useEffect(() => {
      setValue(value);
    }, [value]);

    if (bdnlocationId == '00000000-0000-0000-0000-000000000000') {
      return (
        <StyledCellWrapper>
          <div>
            <Input type="checkbox" name="active" defaultChecked={value} id="active" onChange={onChange}>
              Set active
            </Input>
          </div>
        </StyledCellWrapper>
      );
    } else {
      if (cell.row.original.active) {
        return <span>Yes</span>;
      } else {
        return <span>No</span>;
      }
    }
  };

  const headersNew: Array<Column<BDNLocation>> = React.useMemo(
    () => [
      {
        Header: 'Supplier',
        id: 'supplierId',
        Cell: AddCellSupplier,
        accessor: (row) => {
          if (row.company) return row.company?.name;
          else return '-';
        },
      },
      {
        Header: 'Location',
        id: 'location',
        Cell: AddLocationCell,
        accessor: (row) => {
          if (row.location) return row.location.port;
          else return '-';
        },
      },
      {
        Header: 'Delivery mode',
        id: 'deliveryMode',
        Cell: AddDeliveryMode,
        accessor: (row) => {
          if (row.deliveryMode) return row.deliveryMode;
          else return '-';
        },
      },
      {
        Header: 'Active',
        id: 'active',
        Cell: AddCellActive,
        accessor: (row) => {
          if (row.active) return row.active;
          else return '-';
        },
      },
      {
        Header: 'Generated',
        id: 'generatedBy',
        accessor: (row) => {
          if (row.generatedBy == LocationGeneratedBy.System) return 'System';
          else if (row.generatedBy == LocationGeneratedBy.User) return 'Admin User';
          else return '-';
        },
      },
      ...(!addedBDNLocation
        ? [
            {
              Header: 'Delete',
              Cell: (props: any) => {
                const bdnlocation = props.cell.row.original;
                const generatedBy = bdnlocation.generatedBy;
                return (
                  <div className="delete-cell">
                    {generatedBy == LocationGeneratedBy.User && (
                      <DeleteById
                        id={bdnlocation.id ? bdnlocation.id : ''}
                        deleteMethod={deleteBDNLocation}
                        header="Delete BDN Location"
                        delBtnIcon={deleteIcon}
                      />
                    )}
                  </div>
                );
              },
            },
          ]
        : []),
      ...(addedBDNLocation
        ? [
            {
              Header: 'Add / Delete',
              Cell: (props: any) => {
                const bdnlocation = props.cell.row.original;
                {
                  if (bdnlocation.id == '00000000-0000-0000-0000-000000000000') {
                    return (
                      <div>
                        <Button color="primary" size="lg" onClick={onBDNLocationSave}>
                          <FontAwesomeIcon icon={faSave} />
                        </Button>
                      </div>
                    );
                  } else {
                    return (
                      <div className="delete-cell">
                        <DeleteById
                          id={bdnlocation.id ? bdnlocation.id : ''}
                          deleteMethod={deleteBDNLocation}
                          header="Delete BDN Location"
                          delBtnIcon={deleteIcon}
                        />
                      </div>
                    );
                  }
                }
              },
            },
          ]
        : []),
    ],
    [
      auth,
      singleBDNLocation.location,
      singleBDNLocation.location.chorusId,
      singleBDNLocation.deliveryMode,
      singleBDNLocation.supplierId,
      singleBDNLocation.active,
      addedBDNLocation,
    ],
  );

  if (isLoading) {
    return <Loading />;
  } else if (!suppliers) {
    return <Loading />;
  } else if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledReportTableWrapper>
        <div>
          <div className="buttons-div">
            <Button color="primary" size="lg" onClick={addRow}>
              <FontAwesomeIcon icon={plusCircle} /> Add BDN location
            </Button>
          </div>
          <StyledTableWrapper>
            <FuelBossTableContainer
              headers={headersNew}
              tableRows={jointBDNLocation}
              showPagination={true}
              defaultSelectedPage={defaultSelectedPage}
              setDefaultSelectedPage={setDefaultSelectedPage}
            />
          </StyledTableWrapper>
        </div>
      </StyledReportTableWrapper>
    );
  }
};

export default React.memo(BDNLocations);
