import React from 'react';
import { IntegrationAsset } from '../../../../../store/IntegrationAsset/models';
import { IntegrationAssetWorkspace } from '../../../../../store/IntegrationAssetWorkspaces/models';

import { Role } from '../../../../../store/Roles/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';
import { Formik } from 'formik';
import IntegrationAssetWorkspaceForm from '../IntegrationAssetForm/IntegrationAssetWorkspaceForm';
import integrationAssetWorkspaceFormValidation from '../FormValidations/integrationAssetWorkspaceFormValidation';
import { initialValues } from '../FormInitialValues/integrationAssetWorkspaceFormInitialValues';
import { IntegrationAssetCompany } from '../../../../../store/IntegrationAssetCompanies/models';

interface OwnProps {
  addNewIntegrationAssetWorkspace: (integrationAssetWorkspace: IntegrationAssetWorkspace) => void;
  navAdminType: string;
  integrationAssetWorkspace?: IntegrationAssetWorkspace;
  integrationAssetCompanyId: string;
  integrationAssetCompanies: IntegrationAssetCompany[];
}

const AddIntegrationAssetWorkspace: React.FunctionComponent<OwnProps> = ({ addNewIntegrationAssetWorkspace, navAdminType, integrationAssetWorkspace, integrationAssetCompanies, integrationAssetCompanyId }) => {
  const submitIntegrationAssetWorkspace = (integrationAssetWorkspace: IntegrationAssetWorkspace) => {
    addNewIntegrationAssetWorkspace(integrationAssetWorkspace);
  };

  const selectedIntegrationAssetCompany: IntegrationAssetCompany | undefined = integrationAssetCompanies.find((it) => it.veracityTenantId === integrationAssetCompanyId);

  return (
    <Formik
      initialValues={integrationAssetWorkspace ? integrationAssetWorkspace : initialValues}
      validationSchema={integrationAssetWorkspaceFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetWorkspaceForm
          integrationAssetWorkspace={integrationAssetWorkspace}
          integrationAssetCompany={selectedIntegrationAssetCompany}
          header={'Add integration asset workspace'}
          saveMethod={submitIntegrationAssetWorkspace}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationAssetWorkspace);
