import { IPromptNomination, IUserProfile } from '@teqplay/chorus-components';
import { isEqual } from 'lodash';
import { RecurringNomination } from '../../../../../store/RecurringNomination/models';
import { isAdmin, isCustomer, isScheduler } from '@teqplay/chorus-components';

export function isNominationBelongsToUsersCompany(
  nomination: RecurringNomination | IPromptNomination,
  user: IUserProfile,
) {
  return nomination.companyId === user.companyId || nomination.vendorCompanyId === user.companyId;
}

export function canUserEditContract(
  nomination: RecurringNomination | IPromptNomination,
  user: IUserProfile,
  creatingNewNomination: boolean,
) {
  return (
    (creatingNewNomination || isNominationBelongsToUsersCompany(nomination, user)) &&
    (isAdmin(user.roles) || isCustomer(user.roles) || isScheduler(user.roles, true))
  );
}
