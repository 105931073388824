import React, { useCallback } from 'react';
import { Company } from '../../../../store/Company/models';
import { updateCompany } from '../../../../store/Company/actions';
import { addCompanyLogo } from '../../../../store/CompanyLogo/actions';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import EditCompany from '../../../../components/AdminComponents/Companies/Components/EditCompany/EditCompany';
import { setCompanyFormEditMode } from '../../../../store/Company/actions';
import styles from './MyCompany.module.scss';
import styled from 'styled-components';

const StyledCompany = styled.div`
.headerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
  .companyHeader {
    width: 100%;
    font-weight: bold;
    font-size: 28px;
    line-height: 24px
  }
}

.bodyWrapper {
  height: 700px;
  font-size: 18px;
  .label {
    font-size: 18px;
  }
  .value {
    color: #24365c;
    font-weight: bold;
  }
 /* p {
    margin-top: 0;
    margin-bottom: 0 !important;

  }*/
}

.minified-company {
  font-size: 1.25rem;
  font-weight: bold;
  transform: rotate(-90deg);
  white-space: nowrap;
  margin-top: 122px;
  line-height: 53px;
  margin-bottom: 50px;
`;

const StyledLogoLabel = styled.span`
  color: rgb(187, 187, 187);
  font-size: 14px;
  font-family: Roboto;
  height: 23px;
  top: -8px;
  position: relative;
`;

const StyledLogoWrapper = styled.div`
  height: auto;
  position: relative;
  border-radius: 5px;
  text-align: left;

  img {
    width: auto;
    height: auto;
    position: relative;
    max-width: 312px;
    max-height: 150px;
  }
`;

const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });

interface OwnProps {
  setEditMode: (isEditMode: boolean) => void;
  isCompanyEditMode?: boolean;
  company: Company;
  companyLogo: Uint8Array[];
  minified?: boolean;
  companies: Company[];
  navAdminType: string;
  isCompanyAdmin: boolean;
}

const MyCompany: React.FC<OwnProps> = ({
  company,
  companies,
  navAdminType,
  isCompanyEditMode,
  setEditMode,
  minified,
  companyLogo,
  isCompanyAdmin,
}) => {
  const dispatch = useDispatch();

  const setFormEditModeCallback = useCallback((isEditMode) => dispatch(setCompanyFormEditMode(isEditMode)), []);
  const editCompanyCallback = useCallback(
    (company: Company) => dispatch(updateCompany(company, navAdminType, () => setFormEditModeCallback(false))),
    [],
  );

  const editCompanyMethod = (company: Company) => {
    editCompanyCallback(company);
  };

  const saveLogoCallback = useCallback(
    (companyId: string, logo: string, mimeType: string) =>
      dispatch(addCompanyLogo(companyId, logo, mimeType, () => console.log('logo added'))),
    [],
  );

  if (!minified) {
    const logoContent = `${'data:image/png;base64,'}${companyLogo}`;
    return (
      <>
        {!isCompanyEditMode ? (
          <>
            <StyledCompany>
              <div className="headerWrapper">
                <div className="companyHeader">
                  Company information
                  {isCompanyAdmin && (
                    <Button className={styles['btn-edit']} onClick={() => setEditMode(true)}>
                      <FontAwesomeIcon icon={edit} size="2x" />
                      Edit company information
                    </Button>
                  )}
                  <hr />
                </div>
              </div>
              <div className="bodyWrapper">
                <StyledLogoWrapper className="logo-div">
                  {companyLogo.length > 1 && <img src={logoContent}></img>}
                </StyledLogoWrapper>
                <div className={styles['labels-wrapper']}>
                  <div className={styles['labels']}>
                    <div className={styles['label']}>Company</div>

                    {/*<div className="label">Company logo</div>
                    <StyledLogoLabel>Appears on all operational documents</StyledLogoLabel>*/}
                    <div className={styles['label']}>Category</div>
                    <div className={styles['label']}>Country</div>
                    <div className={styles['label']}>Address</div>

                    <div className={styles['label']}>Unique VAT or business number</div>

                    <div className={styles['label']}>Invoice email</div>

                    <div className={styles['label']}>Invoice address</div>

                    <div className={styles['label']}>Company in brief</div>
                  </div>
                </div>
                <div className= {styles['value-wrapper']}>
                  <div className={styles['values']}>
                    <p className={styles['value']}>{company.name}</p>
                    <p className={styles['value']}>{company.category ? company.category.categoryName : 'unknown'}</p>
                    <p className={styles['value']}>{company.country}</p>
                    <p className={styles['value']}>{company.address}</p>
                    <p className={styles['value']}>{company.orgNumber}</p>
                    <p className={styles['value']}>{company.invoiceEmailAddress}</p>
                    <p className={styles['value']}>{company.invoiceAddress}</p>
                    <p className={styles['value']}>{company.companyInBrief}</p>
                  </div>
                </div>
              </div>
            </StyledCompany>
          </>
        ) : (
          <EditCompany
            editCompany={editCompanyMethod}
            company={company}
            companies={companies}
            companyId={company.companyId}
            navAdminType={navAdminType}
            saveLogoMethod={saveLogoCallback}
            companyLogo={companyLogo}
          />
        )}
      </>
    );
  } else {
    return (
      <StyledCompany>
        <p className="minified-company">Company profile</p>
      </StyledCompany>
    );
  }
};

export default React.memo(MyCompany);
