import { CompanyWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const COMPANY_LOADING = 'company/loading';
export type COMPANY_LOADING = typeof COMPANY_LOADING;

export const GET_COMPANIES = 'company/get';
export type GET_COMPANIES = typeof GET_COMPANIES;

export const COMPANIES_FETCH_ERROR = 'company/fetchError';
export type COMPANIES_FETCH_ERROR = typeof COMPANIES_FETCH_ERROR;

export const DELETE_COMPANY = 'company/delete';
export type DELETE_COMPANY = typeof DELETE_COMPANY;

export const DELETE_COMPANY_ERROR = 'company/deleteError';
export type DELETE_COMPANY_ERROR = typeof DELETE_COMPANY_ERROR;

export const COMPANY_EDIT_MODE = 'company/editMode';
export type COMPANY_EDIT_MODE = typeof COMPANY_EDIT_MODE;

export const UPDATE_COMPANY = 'company/update';
export type UPDATE_COMPANY = typeof UPDATE_COMPANY;

export const UPDATE_COMPANY_ERROR = 'company/updateError';
export type UPDATE_COMPANY_ERROR = typeof UPDATE_COMPANY_ERROR;

export const ADD_COMPANY = 'company/add';
export type ADD_COMPANY = typeof ADD_COMPANY;

export const ADD_COMPANY_ERROR = 'company/updateError';
export type ADD_COMPANY_ERROR = typeof ADD_COMPANY_ERROR;

export const COMPANY_CLEAR_TOAST = 'company/clearToast';
export type COMPANY_CLEAR_TOAST = typeof COMPANY_CLEAR_TOAST;

export const COMPANY_DELIVERY_MODE = 'company/deliveryMode';
export type COMPANY_DELIVERY_MODE = typeof COMPANY_DELIVERY_MODE;

export const COMPANY_DELIVERY_MODE_ERROR = 'company/deliveryModeError';
export type COMPANY_DELIVERY_MODE_ERROR = typeof COMPANY_DELIVERY_MODE_ERROR;

export const COMPANY_CLEAR_DELIVERY_MODE_ERROR = 'company/clearDeliveryModeError';
export type COMPANY_CLEAR_DELIVERY_MODE_ERROR = typeof COMPANY_CLEAR_DELIVERY_MODE_ERROR;

export const COMPANY_LOWCARBON_FUEL = 'company/lowCarbonFuel';
export type COMPANY_LOWCARBON_FUEL = typeof COMPANY_LOWCARBON_FUEL;

export const COMPANY_LOWCARBON_FUEL_ERROR = 'company/lowCarbonFuelError';
export type COMPANY_LOWCARBON_FUEL_ERROR = typeof COMPANY_LOWCARBON_FUEL_ERROR;

export const COMPANY_CLEAR_LOWCARBON_FUEL_ERROR = 'company/clearLowCarbonFuelError';
export type COMPANY_CLEAR_LOWCARBON_FUEL_ERROR = typeof COMPANY_CLEAR_LOWCARBON_FUEL_ERROR;

export type CompanyActions =
  | {
      type: GET_COMPANIES;
      payload: CompanyWrapperModel['data'];
    }
  | {
      type: COMPANY_LOADING;
    }
  | {
      type: COMPANIES_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_COMPANY;
      payload: CompanyWrapperModel['data'];
    }
  | {
      type: DELETE_COMPANY_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: UPDATE_COMPANY;
      payload: CompanyWrapperModel['data'];
    }
  | {
      type: UPDATE_COMPANY_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_COMPANY;
      payload: CompanyWrapperModel['data'];
    }
  | {
      type: ADD_COMPANY_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: COMPANY_EDIT_MODE;
      payload: boolean;
    }
  | {
      type: COMPANY_CLEAR_TOAST;
    }
  | {
      type: COMPANY_DELIVERY_MODE;
      payload: CompanyWrapperModel['data'];
    }
  | {
      type: COMPANY_DELIVERY_MODE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: COMPANY_CLEAR_DELIVERY_MODE_ERROR;
    }
  | {
      type: COMPANY_LOWCARBON_FUEL;
      payload: CompanyWrapperModel['data'];
    }
  | {
      type: COMPANY_LOWCARBON_FUEL_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: COMPANY_CLEAR_LOWCARBON_FUEL_ERROR;
    };
