import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import * as TYPE from './types';
import { api } from '../../helpers/api';
import { IntegrationAssetResponseModel, IntegrationAsset } from './models';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { getAuth } from '../Auth/actions';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating Integration Asset...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Integration Asset updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating Integration Asset...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Integration Asset created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;



export const getIntegrationAssets = () => (dispatch: Dispatch<TYPE.IntegrationAssetActions>) => {
  dispatch({
    type: TYPE.INTEGRATION_ASSET_LOADING,
  });

  new api<IntegrationAssetResponseModel>({
    dispatch,
    errorType: TYPE.INTEGRATION_ASSETS_FETCH_ERROR,
  })
    .get(`/api/Integration/Assets`)
    .then((response) => {
      dispatch({
        type: TYPE.GET_INTEGRATION_ASSETS,
        payload: response,
      });
    });
};

export const deleteIntegrationAssetAsync = (id: string) => (dispatch: Dispatch<TYPE.IntegrationAssetActions>) => {
  dispatch({
    type: TYPE.INTEGRATION_ASSET_LOADING,
  });

  const url = `/api/Integration/Assets/${id}`;
  new api<IntegrationAssetResponseModel>({
    dispatch,
    errorType: TYPE.DELETE_INTEGRATION_ASSET_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: TYPE.DELETE_INTEGRATION_ASSET,
        payload: response,
      });
    }).catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const updateIntegrationAsset = (integrationAsset: IntegrationAsset, navAdminType: string, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.IntegrationAssetActions>,
) => {
  dispatch({
    type: TYPE.INTEGRATION_ASSET_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/Integration/Assets/${integrationAsset.id}`;

  new api<IntegrationAssetResponseModel>({
    dispatch,
    errorType: TYPE.UPDATE_INTEGRATION_ASSET_ERROR,
  })
    .put(url, integrationAsset)
    .then((response) => {
      dispatch({
        type: TYPE.UPDATE_INTEGRATION_ASSET,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
      if (navAdminType === 'company') {
        // @ts-ignore
        getAuth()(dispatch);
      }
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const addIntegrationAsset = (integrationAsset: IntegrationAsset, onSuccess: () => void) => (dispatch: Dispatch<TYPE.IntegrationAssetActions>) => {
  dispatch({
    type: TYPE.INTEGRATION_ASSET_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<IntegrationAssetResponseModel>({
    dispatch,
    errorType: TYPE.ADD_INTEGRATION_ASSET_ERROR,
  })
    .post(`/api/Integration/Assets`, integrationAsset)
    .then((response) => {
      dispatch({
        type: TYPE.ADD_INTEGRATION_ASSET,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const setIntegrationAssetFormEditMode = (isEditMode: boolean) => (dispatch: Dispatch<TYPE.IntegrationAssetActions>) => {
  dispatch({
    type: TYPE.INTEGRATION_ASSET_EDIT_MODE,
    payload: isEditMode,
  });
};

export const integrationAssetClearToast = () => (dispatch: Dispatch<TYPE.IntegrationAssetActions>) => {
  dispatch({
    type: TYPE.INTEGRATION_ASSET_CLEAR_TOAST,
  });
};


