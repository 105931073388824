import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ReactComponent as GasPumpIcon } from './../../../../svgs/add-bunker-event.svg';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomInput } from 'reactstrap';
import DeliveryModeSelector from './Components/DeliveryModeSelector/DeliveryModeSelector';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { getVolumeStats } from '../../../../store/VolumeStats/actions';
import useInterval from '../../../../helpers/useInterval';
import { isQuantityInt } from '../OverviewNominationList/Utils/Utils';
import SearchBar from './Components/SearchBar/SearchBar'

const clockIcon = findIconDefinition({ prefix: 'fal', iconName: 'clock' });
const bellIcon = findIconDefinition({ prefix: 'fal', iconName: 'bell' });

type SortBy = 'time' | 'needsAction';
interface OwnProps {
  newSpotEnquiryAllowed: boolean;
  nominationCreateAllowed: boolean;
  nominationOnBehalf: boolean;
  search: string;
  setSearch: (value: string) => void;
  isExclude: boolean;
  setIsExclude: (value: boolean) => void;
  sortBy: SortBy;
  setSortBy: (value: SortBy) => void;
  deliveryModes: DeliveryModes[];
  setDeliveryModes: (value: DeliveryModes[]) => void;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
}

const Header: React.FC<OwnProps> = ({
  newSpotEnquiryAllowed,
  nominationCreateAllowed,
  nominationOnBehalf,
  search,
  setSearch,
  isExclude,
  setIsExclude,
  sortBy,
  setSortBy,
  deliveryModes,
  setDeliveryModes,
  isSearching,
  setIsSearching
}) => {
  const dispatch = useDispatch();
  const volumeStats = useSelector((state: ApplicationState) => state.volume_stats.data);
  const timeoutId = useRef<number | undefined>();
  const [inputValue, setInputValue] = useState("");

  const handleChange = useCallback((value) => {
    setInputValue(value);
    setIsSearching(true);

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      setSearch(value);
      if(!value){
        setIsSearching(false)
      }
    }, 500);

  }, []);

  useEffect(() => {
    dispatch(getVolumeStats());
  }, []);
  useInterval(() => {
    dispatch(getVolumeStats());
  }, 60000);
  const filteredVolumeStats = Object.entries(volumeStats ?? {}).map(([deliveryMode, value]) =>
    deliveryModes.includes(deliveryMode as DeliveryModes) ? value : undefined,
  );


  const confirmedVolume = filteredVolumeStats.reduce((acc, s) => (s ? acc + s.volume.confirmed : acc), 0);
  const confirmedWeight = filteredVolumeStats.reduce((acc, s) => (s ? acc + s.weight.confirmed : acc), 0);
  const unconfirmedVolume = filteredVolumeStats.reduce((acc, s) => (s ? acc + s.volume.unconfirmed : acc), 0);
  const unconfirmedWeight = filteredVolumeStats.reduce((acc, s) => (s ? acc + s.weight.unconfirmed : acc), 0);

  return (
    <div className={`${styles['root']} ${newSpotEnquiryAllowed && nominationCreateAllowed ? styles['shifted'] : ''}`}>
        <div className={styles['left']}>
          <h1>Overview</h1>
        </div>
      <div className={styles['middle']}>
        <div className={styles['hide-div']}>
          <p className={styles['label']}>FILTER DELIVERY MODES</p>
          <DeliveryModeSelector deliveryModes={deliveryModes} setDeliveryModes={setDeliveryModes} />
        </div>
        <div className={styles['hide-div']}>
          <p className={styles['label']}>CONFIRMED VOLUMES</p>
          <p className={styles['volume-number']}>
            {confirmedVolume ? (
              <>
                {isQuantityInt(confirmedVolume) ? confirmedVolume : confirmedVolume.toFixed(2)} m<sup>3</sup>
              </>
            ) : null}
            {confirmedVolume && confirmedWeight ? ' and ' : null}
            {confirmedWeight ? (
              <>{isQuantityInt(confirmedWeight) ? confirmedWeight : confirmedWeight.toFixed(2)} mt</>
            ) : null}
            {!confirmedVolume && !confirmedWeight ? '0 mt' : null}
          </p>
        </div>
        <div className={styles['hide-div']}>
          <p className={styles['label']}>UNCONFIRMED VOLUMES</p>
          <p className={styles['volume-number']}>
            {unconfirmedVolume ? (
              <>
                {isQuantityInt(unconfirmedVolume) ? unconfirmedVolume : unconfirmedVolume.toFixed(2)} m<sup>3</sup>
              </>
            ) : null}
            {unconfirmedVolume && unconfirmedWeight ? ' and ' : null}
            {unconfirmedWeight ? (
              <>{isQuantityInt(unconfirmedWeight) ? unconfirmedWeight : unconfirmedWeight.toFixed(2)} mt</>
            ) : null}
            {!unconfirmedVolume && !unconfirmedWeight ? '0 mt' : null}
          </p>
        </div>
        <div >
          <p className={styles['label']}>SORT BY</p>
          <div className={styles['sort-selector']}>
            <FontAwesomeIcon
              icon={clockIcon}
              className={sortBy === 'needsAction' ? styles['switch-icon-deselected'] : styles['switch-icon']}
            />
            <CustomInput
              id="sortBy"
              type="switch"
              onChange={(e) => setSortBy(e.target.checked ? 'needsAction' : 'time')}
              className={styles['switch']}
              checked={sortBy === 'needsAction'}
            />
            <FontAwesomeIcon
              icon={bellIcon}
              className={sortBy === 'needsAction' ? styles['switch-icon'] : styles['switch-icon-deselected']}
            />
          </div>
        </div>
      </div>
      <div className={styles['right']}>
        <SearchBar
          placeholder="Enter keyword"
          onChange={handleChange}
          value={inputValue}
          isExclude={isExclude}
          setIsExclude={(isExclude: boolean) => setIsExclude(isExclude)}
        />
        {nominationCreateAllowed && (
          <Link color="primary" className={`${styles['nomination-button']} btn btn-primary`} to={'bunkerevents/new'}>
            <GasPumpIcon />
            {nominationOnBehalf ? 'New nomination on behalf' : 'New nomination'}
          </Link>
        )}
        {newSpotEnquiryAllowed && (
          <Link
            className={`${styles['new-nomination-button']} btn btn-primary`}
            color="primary"
            to={'requestquotation/new'}
          >
            <GasPumpIcon />
            New quotation
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
