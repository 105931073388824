import { FleetChartererWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const FLEETCHAETERER_LOADING = 'fleetCharterer/loading';
export type FLEETCHAETERER_LOADING = typeof FLEETCHAETERER_LOADING;

export const GET_FLEETCHAETERER = 'fleetCharterer/get';
export type GET_FLEETCHAETERER = typeof GET_FLEETCHAETERER;

export const FLEETCHAETERER_FETCH_ERROR = 'fleetCharterer/fetchError';
export type FLEETCHAETERER_FETCH_ERROR = typeof FLEETCHAETERER_FETCH_ERROR;

export const ADD_FLEETCHAETERER = 'fleetCharterer/add';
export type ADD_FLEETCHAETERER = typeof ADD_FLEETCHAETERER;

export const FLEETCHAETERER_ADD_ERROR = 'fleetCharterer/addError';
export type FLEETCHAETERER_ADD_ERROR = typeof FLEETCHAETERER_ADD_ERROR;

export const FLEETCHAETERER_FETCH_SUCCESS = 'fleetCharterer/fetchSuccess';
export type FLEETCHAETERER_FETCH_SUCCESS = typeof FLEETCHAETERER_FETCH_SUCCESS;

export const DELETE_FLEETCHAETERER = 'fleetCharterer/delete';
export type DELETE_FLEETCHAETERER = typeof DELETE_FLEETCHAETERER;

export const FLEETCHAETERER_DELETE_ERROR = 'fleetCharterer/deleteError';
export type FLEETCHAETERER_DELETE_ERROR = typeof FLEETCHAETERER_DELETE_ERROR;

export const FLEETCHAETERER_CLEAR_TOAST = 'fleetCharterer/clearToast';
export type FLEETCHAETERER_CLEAR_TOAST = typeof FLEETCHAETERER_CLEAR_TOAST;

export type FleetChartererActions =
  | {
      type: GET_FLEETCHAETERER;
      payload: FleetChartererWrapperModel['data'];
    }
  | {
      type: FLEETCHAETERER_LOADING;
    }
  | {
      type: FLEETCHAETERER_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_FLEETCHAETERER;
      payload: FleetChartererWrapperModel['data'];
    }
  | {
      type: FLEETCHAETERER_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: FLEETCHAETERER_FETCH_SUCCESS;
    }
  | {
      type: DELETE_FLEETCHAETERER;
      payload: FleetChartererWrapperModel['data'];
    }
  | {
      type: FLEETCHAETERER_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: FLEETCHAETERER_CLEAR_TOAST;
    };
