import styled from 'styled-components';
const names = ['module', 'system'];

export default styled.div`
  display: block;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => (names.includes(props.name) || props.type === 'textarea' ? 'auto' : '49px')};
  margin-bottom: 1em;
  background-color: ${(props) => props.backgroundColor || 'inherit'};
  line-height: unset;
  padding-left: ${(props) => (props.type === 'text' || props.type === 'number' ? '5px' : '0')};
`;
