import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { api } from '../../helpers/api';
import {
  NominationSchedule,
  NominationScheduleResponseModel,
  PaginatedNominationScheduleResponseModel,
} from './models';
import {
  NominationScheduleActions,
  NOMINATIONSCHEDULE_LOADING,
  NOMINATIONSCHEDULE_FETCH_ERROR,
  GET_NOMINATIONSCHEDULE,
  NOMINATIONSCHEDULE_DELETE_ERROR,
  NOMINATIONSCHEDULE_CLEAR_TOAST,
  DELETE_NOMINATIONSCHEDULE,
  UPDATE_NOMINATIONSCHEDULE,
  UPDATE_NOMINATIONSCHEDULE_ERROR,
  //  ADD_NOMINATIONSCHEDULE_ERROR,
  //  ADD_NOMINATIONSCHEDULE,
  CREATENOMINATION_NOMINATIONSCHEDULE,
  CREATENOMINATION_NOMINATIONSCHEDULE_ERROR,
} from './types';

const updateLoaderModal: ReactElement = (
  <ModalResponse status="loading">Updating nomination schedule nomination...</ModalResponse>
);
const updateSuccessModal: ReactElement = (
  <ModalResponse status="success">Nominaiton schedule updated successfully</ModalResponse>
);

const createLoaderModal: ReactElement = (
  <ModalResponse status="loading">Creating nomination schedule nomination...</ModalResponse>
);
const createSuccessModal: ReactElement = (
  <ModalResponse status="success">Nominaiton schedule created successfully</ModalResponse>
);

const cancelLoaderModal: ReactElement = (
  <ModalResponse status="loading">Canceling nomination schedule nomination...</ModalResponse>
);
const cancelSuccessModal: ReactElement = (
  <ModalResponse status="success">Nominaiton schedule cancled successfully</ModalResponse>
);

const pushNominationLoaderModal: ReactElement = (
  <ModalResponse status="loading">Creating nomination for schedule nomination...</ModalResponse>
);
const pushNominationSuccessModal: ReactElement = (
  <ModalResponse status="success">Nominaiton created successfully from the schedule</ModalResponse>
);

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getNominationSchedules = () => (dispatch: Dispatch<NominationScheduleActions>) => {
  dispatch({
    type: NOMINATIONSCHEDULE_LOADING,
  });
  const url = `api/NominationSchedule/`;
  new api<NominationScheduleResponseModel>({
    dispatch,
    errorType: NOMINATIONSCHEDULE_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: NOMINATIONSCHEDULE_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_NOMINATIONSCHEDULE,
          payload: { data: response },
        });
      }
    });
};

export const getNominationSchedulesPaginated = (
  recurringNominationTemplateId: string,
  sortByNeedsAction: boolean,
  pageIndex: number,
  pageSize = 10,
) => (dispatch: Dispatch<NominationScheduleActions>) => {
  dispatch({
    type: NOMINATIONSCHEDULE_LOADING,
  });
  const url = `api/NominationSchedule/NominationSchedulesPaginated/${recurringNominationTemplateId}/recurring/?sortByNeedsAction=${sortByNeedsAction}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  new api<PaginatedNominationScheduleResponseModel>({
    dispatch,
    errorType: NOMINATIONSCHEDULE_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: NOMINATIONSCHEDULE_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_NOMINATIONSCHEDULE,
          payload: { data: response.page, metaData: { ...response } },
        });
      }
    });
};

export const updateNominationSchedule = (schedule: NominationSchedule, onSuccess: () => void) => (
  dispatch: Dispatch<NominationScheduleActions>,
) => {
  dispatch({
    type: NOMINATIONSCHEDULE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/NominationSchedule/${schedule.id}`;

  new api<PaginatedNominationScheduleResponseModel>({
    dispatch,
    errorType: UPDATE_NOMINATIONSCHEDULE_ERROR,
  })
    .put(url, schedule)
    .then((response) => {
      dispatch({
        type: UPDATE_NOMINATIONSCHEDULE,
        payload: { data: response.page, metaData: { ...response } },
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

/* export const addNominationSchedule = (nomination: NominationSchedule, onSuccess: () => void) => (
  dispatch: Dispatch<NominationScheduleActions>,
) => {
  dispatch({
    type: NOMINATIONSCHEDULE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<NominationScheduleResponseModel>({
    dispatch,
    errorType: ADD_NOMINATIONSCHEDULE_ERROR,
  })
    .post(`/api/NominationSchedule`, nomination)
    .then((response) => {
      dispatch({
        type: ADD_NOMINATIONSCHEDULE,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
}; */

export const cancelNominationSchedule = (id: string) => (dispatch: Dispatch<NominationScheduleActions>) => {
  dispatch({
    type: NOMINATIONSCHEDULE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: cancelLoaderModal });
  const url = `/api/NominationSchedule/cancel/${id}`;

  new api<PaginatedNominationScheduleResponseModel>({
    dispatch,
    errorType: NOMINATIONSCHEDULE_DELETE_ERROR,
  })
    .put(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_NOMINATIONSCHEDULE,
        payload: { data: response.page, metaData: { ...response } },
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: cancelSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const pushToNominationSchedule = (id: string) => (dispatch: Dispatch<NominationScheduleActions>) => {
  dispatch({
    type: NOMINATIONSCHEDULE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: pushNominationLoaderModal });
  const url = `/api/NominationSchedule/pushToNomination/${id}`;

  new api<PaginatedNominationScheduleResponseModel>({
    dispatch,
    errorType: CREATENOMINATION_NOMINATIONSCHEDULE_ERROR,
  })
    .put(url, id)
    .then((response) => {
      dispatch({
        type: CREATENOMINATION_NOMINATIONSCHEDULE,
        payload: { data: response.page, metaData: { ...response } },
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: pushNominationSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const nominationScheduleClearToast = () => (dispatch: Dispatch<NominationScheduleActions>) => {
  dispatch({
    type: NOMINATIONSCHEDULE_CLEAR_TOAST,
  });
};
