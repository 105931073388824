import React from 'react';
import FormikWrapper from '../Formik/FormikWrapper';

import { Contract } from '../../../../../store/Contract/models';
import { ICompany } from '@teqplay/chorus-components';
import { Company } from '../../../../../store/Company/models';

interface OwnProps {
  addNewContract: (contract: Contract) => void;
  systemAdmin?: boolean;
  customers: ICompany[];
  suppliers: ICompany[];
  company?: Company;
  navAdminType: string;
}

const AddContract: React.FC<OwnProps> = ({
  addNewContract,
  navAdminType,
  systemAdmin,
  customers,
  suppliers,
  company,
}) => {
  const submitContract = (contract: Contract) => {
    addNewContract(contract);
  };

  return (
    <FormikWrapper
      systemAdmin={systemAdmin}
      addContract={submitContract}
      header="Add contract"
      customers={customers}
      suppliers={suppliers}
      company={company}
      navAdminType={navAdminType}
    />
  );
};

export default React.memo(AddContract);
