import { FuelbossLocationWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const FUELBOSSLOCATION_LOADING = 'fuelbosslocations/loading';
export type FUELBOSSLOCATION_LOADING = typeof FUELBOSSLOCATION_LOADING;

export const GET_FUELBOSSLOCATIONS = 'fuelbosslocations/get';
export type GET_FUELBOSSLOCATIONS = typeof GET_FUELBOSSLOCATIONS;

export const FUELBOSSLOCATION_FETCH_ERROR = 'fuelbosslocations/fetchError';
export type FUELBOSSLOCATION_FETCH_ERROR = typeof FUELBOSSLOCATION_FETCH_ERROR;

export const ADD_FUELBOSSLOCATION = 'fuelbosslocations/add';
export type ADD_FUELBOSSLOCATION = typeof ADD_FUELBOSSLOCATION;

export const ADD_FUELBOSSLOCATION_ERROR = 'fuelbosslocations/addError';
export type ADD_FUELBOSSLOCATION_ERROR = typeof ADD_FUELBOSSLOCATION_ERROR;

export const DELETE_FUELBOSSLOCATION = 'fuelbosslocations/delete';
export type DELETE_FUELBOSSLOCATION = typeof DELETE_FUELBOSSLOCATION;

export const DELETE_FUELBOSSLOCATION_ERROR = 'fuelbosslocations/deleteError';
export type DELETE_FUELBOSSLOCATION_ERROR = typeof DELETE_FUELBOSSLOCATION_ERROR;

export const EDIT_FUELBOSSLOCATION = 'fuelbosslocations/edit';
export type EDIT_FUELBOSSLOCATION = typeof EDIT_FUELBOSSLOCATION;

export const EDIT_FUELBOSSLOCATION_ERROR = 'fuelbosslocations/editError';
export type EDIT_FUELBOSSLOCATION_ERROR = typeof EDIT_FUELBOSSLOCATION_ERROR;

export const FUELBOSSLOCATION_CLEAR_TOAST = 'fuelbosslocations/clearToast';
export type FUELBOSSLOCATION_CLEAR_TOAST = typeof FUELBOSSLOCATION_CLEAR_TOAST;

export type FuelbossLocationActions =
  | {
      type: GET_FUELBOSSLOCATIONS;
      payload: FuelbossLocationWrapperModel['data'];
    }
  | {
      type: FUELBOSSLOCATION_LOADING;
    }
  | {
      type: FUELBOSSLOCATION_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_FUELBOSSLOCATION;
      payload: FuelbossLocationWrapperModel['data'];
    }
  | {
      type: ADD_FUELBOSSLOCATION_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_FUELBOSSLOCATION;
      payload: FuelbossLocationWrapperModel['data'];
    }
  | {
      type: DELETE_FUELBOSSLOCATION_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: EDIT_FUELBOSSLOCATION;
      payload: FuelbossLocationWrapperModel['data'];
    }
  | {
      type: EDIT_FUELBOSSLOCATION_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: FUELBOSSLOCATION_CLEAR_TOAST;
    };
