import { Dispatch } from 'redux';
import { RoleActions, ROLE_LOADING, ROLES_FETCH_ERROR, GET_ROLES } from './types';
import { api } from '../../helpers/api';
import { RoleResponseModel } from './models';

export const getRoles = () => (dispatch: Dispatch<RoleActions>) => {
  dispatch({
    type: ROLE_LOADING,
  });

  new api<RoleResponseModel>({
    dispatch,
    errorType: ROLES_FETCH_ERROR,
  })
    .get(`/api/Roles`)
    .then((response) => {
      dispatch({
        type: GET_ROLES,
        payload: response,
      });
    });
};
