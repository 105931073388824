import React from 'react';
import { Report, ReportReference } from '../../../../../store/Reports/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import FuelBossTableContainer from '../../../../FuelBossTable';
import { TableOptions, Column, Accessor, UseTableOptions } from 'react-table/index';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
//import { Tooltip } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Category } from '../../../../../store/Category/models';
import ToggleVisibilityButton from './ToggleVisibilityButton/ToggleVisibilityButton';

const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const minusCircle = findIconDefinition({ prefix: 'fal', iconName: 'minus-circle' });
const checkCircle = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
const PlusCircle = findIconDefinition({ prefix: 'fal', iconName: 'plus-circle' });

const StyledReportTableWrapper = styled.div`
  position: relative;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

const StyledReportNameWrapper = styled.div`
  margin-right: 10px;
  div {
    font-family: Roboto;
    font-size: 18px;
    color: #0c202b;
  }
  span {
    font-family: Roboto;
    font-size: 14px;
    color: #4d4d4d;
  }
`;

const StyledCountWrapper = styled.span`
  font-size: 18px;
  color: #009fda;
`;

const StyledVisibilityButton = styled.button`
  color: #c4262e;
  background: #fff;
  border-color: #ccc;
  width: 38px;
  height: 33px;
  padding: 0.32rem;
  border-radius: 5px;
  stroke-width: 1;
`;

interface OwnProps {
  reports: Report[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteReport: (id: string) => void;
  editReport: (report: Report) => void;
  toggleVisibility: (id: string) => void;
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
  companies: Company[];
  categories: Category[];
  roles: Role[];
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const Reports: React.FC<OwnProps> = ({
  reports,
  isLoading,
  hasError,
  errorDetails,
  deleteReport,
  editReport,
  toggleVisibility,
  companyAdmin,
  company,
  navAdminType,
  companies,
  categories,
  roles,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const headersNew: Array<Column<Report>> = React.useMemo(
    () => [
      {
        Header: 'Report name',
        Cell: (props: any) => {
          const report: Report = props.cell.row.original;
          return (
            <StyledReportNameWrapper>
              <div>{report.name}</div>
              <span>{report.description}</span>
            </StyledReportNameWrapper>
          );
        },
      },
      {
        Header: 'Company type',
        Cell: (props: any) => {
          const report: Report = props.cell.row.original;
          if (!report.companyCategoriesIds) return <div>-</div>;
          if (report.companyCategoriesIds.length == 0) return <div>{'All Categories'}</div>;
          const companyCategoryObjectList = categories.filter((x) => report.companyCategoriesIds.includes(x.id));
          const categorylist = companyCategoryObjectList.map((y) => y.categoryName);
          var uniqueCategory = categorylist.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
          return (
            <div>
              {uniqueCategory.map((x) => (
                <div key={x}>
                  {x}
                  <br />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'Company',
        Cell: (props: any) => {
          const row: Report = props.cell.row.original;
          if (!row.companiesIds) return <div>-</div>;
          if (row.companiesIds.length == 0) return <div>{'All Companies'}</div>;
          //@ts-ignore
          const companyObjectList = companies.filter((x) => row.companiesIds.includes(x.companyId));
          const companyList = companyObjectList.map((x) => x.name);
          const companyTooltipList = companyList.slice(1, companyList.length);
          if (companyList.length == 0 || !companyList) return <div>-</div>;
          else if (companyList.length == 1) return <div>{companyList[0]}</div>;
          else if (companyList.length > 1)
            return (
              <div>
                {companyList[0]}
                <ReactTooltip place="bottom" type="info" effect="solid" multiline={true} />
                <StyledCountWrapper data-tip={companyTooltipList.join('<br/>')}>
                  {' '}
                  {' and ' + String(companyList.length - 1) + ' more'}
                </StyledCountWrapper>
              </div>
            );
        },
      },
      {
        Header: 'Roles',
        Cell: (props: any) => {
          const row: Report = props.cell.row.original;
          if (!row.rolesIds) return <div>-</div>;
          if (row.rolesIds.length == 0) return <div>{'All Roles'}</div>;
          const rolesObjectList = roles.filter((x) => row.rolesIds.includes(x.id));
          const rolesList = rolesObjectList.map((x) => x.roleType);
          const rolesTooltipList = rolesList.slice(1, rolesList.length);
          if (rolesList.length == 0 || !rolesList) return <div>-</div>;
          else if (rolesList.length == 1) return <div>{rolesList[0]}</div>;
          else if (rolesList.length > 1)
            return (
              <div>
                {rolesList[0]}
                <ReactTooltip place="bottom" type="info" effect="solid" multiline={true} />
                <StyledCountWrapper data-tip={rolesTooltipList.join('<br/>')}>
                  {' '}
                  {' and ' + String(rolesList.length - 1) + ' more'}
                </StyledCountWrapper>
              </div>
            );
        },
      },
      {
        Header: 'Report type',
        accessor: (row) => {
          if (row.type) return row.type.name;
          else return '-';
        },
      },
      {
        Header: 'Reference',
        accessor: (row) => {
          if (row.reference) return row.reference;
          else return '-';
        },
      },
      {
        Header: 'Active',
        Cell: (props: any) => {
          const report: Report = props.cell.row.original;
          return (
            <div>
              {report.active ? (
                <FontAwesomeIcon icon={checkCircle} size={'lg'} color="#46aa1e" />
              ) : (
                <FontAwesomeIcon icon={minusCircle} size={'lg'} color="grey" />
              )}
            </div>
          );
        },
      },
      {
        Header: 'Open in new tab',
        accessor: (row) => {
          if (row.openInNewTab) return 'Yes';
          else return 'No';
        },
      },
      {
        Header: 'Visible',
        Cell: (props: any) => {
          const data = props.cell.row.original;
          return (
            <div className="visibility-cell">
              <ToggleVisibilityButton
                id={data.id ? data.id : ''}
                method={toggleVisibility}
                header="Change report visibility"
                btnIcon={data.active ? minusCircle : checkCircle}
                isActive={data.active}
              />
            </div>
          );
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const data = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={data.id ? data.id : ''}
                deleteMethod={deleteReport}
                header="Delete report"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const report = props.cell.row.original;
          return (
            <div className="edit-cell">
              <EditById redirectUrl={`/${navAdminType}admin/reports/${report.id}/edit`} />
            </div>
          );
        },
      },
    ],
    [],
  );

  const history = useHistory();

  if (isLoading) {
    return <Loading />;
  } else if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledReportTableWrapper>
        <div className="buttons-div">
          <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/reports/add`)}>
            <FontAwesomeIcon icon={PlusCircle} /> Add report
          </Button>
          <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/reports/types`)}>
            <FontAwesomeIcon icon={PlusCircle} /> Manage report types
          </Button>
        </div>
        {reports && reports.length ? (
          <FuelBossTableContainer
            headers={headersNew}
            tableRows={reports}
            showPagination={true}
            defaultSelectedPage={defaultSelectedPage}
            setDefaultSelectedPage={setDefaultSelectedPage}
          />
        ) : null}
      </StyledReportTableWrapper>
    );
  }
};

export default React.memo(Reports);
