import * as React from 'react';
import { Column } from 'react-table';
import styles from './Table.module.scss';
import Table, { SortIcons } from './Table/Table';

interface OwnProps {
  headers: any;
  tableRows: object[];
  numberOfRowsPerPagination?: number;
  striped?: boolean;
  hover?: boolean;
  responsive?: boolean;
  className?: string;
  showPagination?: boolean;
  showFilterSearch?: boolean;
  renderRowSubComponent?: (row: any) => JSX.Element;
  hiddenColumns?: Array<string>;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
  showSortIcons?: boolean;
  filters?: { id: string; value: any }[];
  sortBy?: { id: string; desc: boolean }[];
  onRowClick?: (row: any) => void;
  rowStyle?: (row: any) => React.CSSProperties;
  globalFilter?: string; 
  pageSize?: number;
  sortIcons?: SortIcons;
  defaultCollumn?: Partial<Column<object>> | undefined;
  //defaultCollumn?: ({ value: initialValue, row: { index }, column: { id }, updateMyData }: any)  => JSX.Element;
  setDefaultPageSize?: (number: number) => void;
}

const ReactTableContainer: React.FC<OwnProps> = ({
  headers,
  numberOfRowsPerPagination,
  striped = true,
  hover = true,
  responsive = true,
  className,
  tableRows,
  showPagination = true,
  showFilterSearch = true,
  renderRowSubComponent,
  hiddenColumns,
  defaultSelectedPage,
  setDefaultSelectedPage,
  showSortIcons = true,
  filters,
  sortBy,
  onRowClick,
  rowStyle,
  globalFilter,
  pageSize,
  sortIcons,
  defaultCollumn,
  setDefaultPageSize,
}) => {
  return (
    <div className={`${styles['center-content']} ${className}`}>
      <Table
        headers={headers}
        numberOfRowsPerPagination={numberOfRowsPerPagination}
        striped={striped}
        hover={hover}
        responsive={responsive}
        tableRows={tableRows}
        showPagination={showPagination}
        showFilterSearch={showFilterSearch}
        renderRowSubComponent={renderRowSubComponent}
        hiddenColumns={hiddenColumns}
        defaultSelectedPage={defaultSelectedPage}
        setDefaultSelectedPage={setDefaultSelectedPage}
        showSortIcons={showSortIcons}
        filters={filters}
        sortBy={sortBy}
        onRowClick={onRowClick}
        rowStyle={rowStyle}
        globalFilter={globalFilter}
        pageSize={pageSize}
        sortIcons={sortIcons}
        defaultCollumn={defaultCollumn}
        setDefaultPageSize={setDefaultPageSize}
      ></Table>
    </div>
  );
};

export default ReactTableContainer;
