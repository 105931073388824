import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from './FilesUpload.module.scss';

const FileUpload = ({ id, file, onDelete, url }) => {
  return (
    <div className={styles['file-upload']}>
      <div className={styles['delete-icon-container']} onClick={() => onDelete(id)}>
        <FontAwesomeIcon style={{ color: '#fff' }} icon={faTrash} />
        <span className="tooltipTextShare">Delete</span>
      </div>
      <div>
        <a className={styles['file-upload-link']} href={url} target="_blank">
          {file.name}
        </a>
      </div>
    </div>
  );
};

export default FileUpload;
