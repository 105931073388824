import * as TYPE from './types';

import { ApplicationState } from '..';

const errorMessage = (errorCode: number) => {
    switch (errorCode) {
    case 403:
        return 'Not authorized to do this action.';
    case 409:
        return 'Contract reference with CustomerId/SupplierId already exists. Please use another Contract reference';
    default:
        return 'Something went wrong! We could not create the new Contract. Please contact FuelBoss support for more details.';
    }
};

export const contractReducer = (
  state: ApplicationState['contracts'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.ContractActions,
): ApplicationState['contracts'] => {
  switch (action.type) {
    case TYPE.CONTRACT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.CONTRACT_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.GET_CONTRACTS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.CONTRACTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.NOTIFY_CONTRACT:
      return {
        ...state,
        error: false,
      };
    case TYPE.NOTIFY_CONTRACT_ERROR:
      return {
        ...state,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.DELETE_CONTRACT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Contract succesfully deactivated!',
          type: 'success',
        },
      };
    case TYPE.DELETE_CONTRACT_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: action.payload.message ? action.payload.message : "Sorry, can't delete contract.",
          type: 'error',
        },
      };
    case TYPE.ADD_CONTRACT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Contract succesfully created!',
          type: 'success',
        },
      };
    case TYPE.ADD_CONTRACT_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: errorMessage(action.payload.statusCode),
          type: 'error',
        },
      };
    case TYPE.UPDATE_CONTRACT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Contract edited',
          type: 'info',
        },
      };
    case TYPE.UPDATE_CONTRACT_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: action.payload.message ? action.payload.message : "Sorry, can't update contract.",
          type: 'error',
        },
      };
    default:
      return { ...state };
  }
};
