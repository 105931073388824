import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getCompanies } from '../../../../../store/Company/actions';
import { Company } from '../../../../../store/Company/models';
import { getRoles } from '../../../../../store/Roles/actions';
import { Role } from '../../../../../store/Roles/models';
import { Report } from '../../../../../store/Reports/models';
import { ReportType } from '../../../../../store/ReportTypes/models';
import { getAuth } from '../../../../../store/Auth/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { RoleType } from '../../../../../models/RoleType';
import usePrevious from '../../../../../helpers/usePrevious';
import { getFleetVessels } from '../../../../../store/CompanyFleet/actions';
import { renderTextInput, renderComplexDropdown, renderSimpleDropdown, renderCheckbox } from '../../../../Form/FormInputs';
import reportConfigs from './reportConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';

const StyledInnerForm = styled.div`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
  margin-top: 1em;
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    flex: 1;
    justify-content: flex-end;
  }
  div[class^='input-'] {
    flex: 0 1 40%;
    margin-right: 35px;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
`;

interface OwnProps {
  reportType?: ReportType;
  header: string;
  saveMethod: (reportType: ReportType) => void;
  systemAdminPage?: boolean;
  companyAdminForm?: boolean;
  company?: Company;
  formikProps: FormikProps<ReportType>;
  navAdminType: string;
}

const ReportForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  reportType,
  systemAdminPage = false,
  companyAdminForm = false,
  company,
  navAdminType,
  formikProps,
}) => {
  const { authLoading, authError, authErrorDetails, authIsLoaded } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const onFormSave = () => {
    formikProps.submitForm();
    //const roleArray = getRolesArray();

    const reportTypeToSave: ReportType = {
      ...formikProps.values,
      //  active: true,
    };
    if (reportType) {
      reportTypeToSave.id = reportType.id;
    }
    if (formikProps.isValid) {
      saveMethod(reportTypeToSave);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: reportConfigs, errors, touched, setFieldValue, setFieldTouched };

  const subsetoptions = [
    { name: 1 },
    { name: 2 },
    { name: 3 },
    { name: 4 },
    { name: 5 },
    { name: 6 },
  ];
  if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save report type"
          cancelButtonText="Cancel"
          formUrl="reports/types"
        />
        <StyledInnerForm as={Form}>
          {renderTextInput('name', false, props)}
          {renderSimpleDropdown('subset', subsetoptions, false, 'name', props)}
          {renderCheckbox('showAddVessel', false, props)}
        </StyledInnerForm>
      </StyledForm>
    );
  }
};

export default React.memo(ReportForm);
