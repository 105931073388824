import { AISListColumnrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const AISLISTCOLUMN_LOADING = 'aisListColumn/loading';
export type AISLISTCOLUMN_LOADING = typeof AISLISTCOLUMN_LOADING;

export const GET_AISLISTCOLUMN = 'aisListColumn/get';
export type GET_AISLISTCOLUMN = typeof GET_AISLISTCOLUMN;

export const AISLISTCOLUMN_FETCH_ERROR = 'aisListColumn/fetchError';
export type AISLISTCOLUMN_FETCH_ERROR = typeof AISLISTCOLUMN_FETCH_ERROR;

export const ADD_AISLISTCOLUMN = 'aisListColumn/add';
export type ADD_AISLISTCOLUMN = typeof ADD_AISLISTCOLUMN;

export const AISLISTCOLUMN_ADD_ERROR = 'aisListColumn/addError';
export type AISLISTCOLUMN_ADD_ERROR = typeof AISLISTCOLUMN_ADD_ERROR;

export const AISLISTCOLUMN_EDIT_ERROR = 'aisListColumn/editError';
export type AISLISTCOLUMN_EDIT_ERROR = typeof AISLISTCOLUMN_EDIT_ERROR;

export const EDIT_AISLISTCOLUMN = 'aisListColumn/edit';
export type EDIT_AISLISTCOLUMN = typeof EDIT_AISLISTCOLUMN;

export const AISLISTCOLUMN_CLEAR_TOAST = 'aisListColumn/clearToast';
export type AISLISTCOLUMN_CLEAR_TOAST = typeof AISLISTCOLUMN_CLEAR_TOAST;

export type AISListColumnActions =
  | {
      type: GET_AISLISTCOLUMN;
      payload: AISListColumnrapperModel['data'];
    }
  | {
      type: AISLISTCOLUMN_LOADING;
    }
  | {
      type: AISLISTCOLUMN_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: AISLISTCOLUMN_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: AISLISTCOLUMN_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_AISLISTCOLUMN;
      payload: AISListColumnrapperModel['data'];
    }
  | {
      type: EDIT_AISLISTCOLUMN;
      payload: AISListColumnrapperModel['data'];
    }
  | {
      type: AISLISTCOLUMN_CLEAR_TOAST;
    };
