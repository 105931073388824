import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { BUNKERSHIP_LOADING, BUNKERSHIP_FETCH_ERROR, GET_BUNKERSHIP, BunkerShipActions } from './types';
import { BunkerShipResponseModel } from './models';

export const getBunkerShips = () => (dispatch: Dispatch<BunkerShipActions>) => {
  dispatch({
    type: BUNKERSHIP_LOADING,
  });

  new api<BunkerShipResponseModel>({
    dispatch,
    errorType: BUNKERSHIP_FETCH_ERROR,
  })
    .get(`api/chorus/GetBunkerShips`)
    .then((response) => {
      dispatch({
        type: GET_BUNKERSHIP,
        payload: response,
      });
    });
};
