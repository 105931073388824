import * as TYPE from './types';

import { ApplicationState } from '..';

export const bivesselsReducer = (
    state: ApplicationState['bivessels'] = {
        loading: true,
        error: false,
        dataIsLoaded: false,
        data: [],
        metadata: {},
    },
    action: TYPE.BIvesselsActions,
): ApplicationState['bivessels'] => {
    switch (action.type) {
        case TYPE.BIVESSELS_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case TYPE.BIVESSELS_FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorDetails: action.payload,
            };
        case TYPE.GET_BIVESSELS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                dataIsLoaded: true,
            };
        default:
            return { ...state };
    }
};
