import { EmailSettingsWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const EMAILSETTINGS_LOADING = 'emailSettings/loading';
export type EMAILSETTINGS_LOADING = typeof EMAILSETTINGS_LOADING;

export const GET_EMAILSETTINGS = 'emailSettings/get';
export type GET_EMAILSETTINGS = typeof GET_EMAILSETTINGS;

export const EMAILSETTINGS_FETCH_ERROR = 'emailSettings/fetchError';
export type EMAILSETTINGS_FETCH_ERROR = typeof EMAILSETTINGS_FETCH_ERROR;

export const ADD_EMAILSETTINGS = 'emailSettings/add';
export type ADD_EMAILSETTINGS = typeof ADD_EMAILSETTINGS;

export const EMAILSETTINGS_ADD_ERROR = 'emailSettings/addError';
export type EMAILSETTINGS_ADD_ERROR = typeof EMAILSETTINGS_ADD_ERROR;

export const EMAILSETTINGS_DELETE_ERROR = 'emailSettings/deleteError';
export type EMAILSETTINGS_DELETE_ERROR = typeof EMAILSETTINGS_DELETE_ERROR;

export const EMAILSETTINGS_EDIT_ERROR = 'emailSettings/editError';
export type EMAILSETTINGS_EDIT_ERROR = typeof EMAILSETTINGS_EDIT_ERROR;

export const DELETE_EMAILSETTINGS = 'emailSettings/delete';
export type DELETE_EMAILSETTINGS = typeof DELETE_EMAILSETTINGS;

export const EDIT_EMAILSETTINGS = 'emailSettings/edit';
export type EDIT_EMAILSETTINGS = typeof EDIT_EMAILSETTINGS;

export const EMAILSETTINGS_CLEAR_TOAST = 'emailSettings/ClearToast';
export type EMAILSETTINGS_CLEAR_TOAST = typeof EMAILSETTINGS_CLEAR_TOAST;


export type EmailSettingsActions =
    | {
        type: GET_EMAILSETTINGS;
        payload: EmailSettingsWrapperModel['data'];
    }
    | {
        type: EMAILSETTINGS_LOADING;
    }
    | {
        type: EMAILSETTINGS_FETCH_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: EMAILSETTINGS_ADD_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: EMAILSETTINGS_DELETE_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: EMAILSETTINGS_EDIT_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: ADD_EMAILSETTINGS;
        payload: EmailSettingsWrapperModel['data'];
    }
    | {
        type: DELETE_EMAILSETTINGS;
        payload: EmailSettingsWrapperModel['data'];
    }
    | {
        type: EDIT_EMAILSETTINGS;
        payload: EmailSettingsWrapperModel['data'];
    }
    | {
        type: EMAILSETTINGS_CLEAR_TOAST;
    };
