import React from 'react';
import { ReactComponent as ErrorIcon } from '../svgs/lock-alt.svg';
import styles from '../../Error.module.scss';

export const LoginMessage: React.FC = React.memo(() => (
  <div className={styles.errorComponent}>
    <div className={styles['icon-wrapper']}>
      <ErrorIcon className={styles.image} />
    </div>
    <h5 className={styles.header}>Hold on while we log you in...</h5>
  </div>
));
