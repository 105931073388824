export const addNameValuesForUserRoles = (data) => {
    const isChildValid = data && data instanceof Array && data.some((it) => it && (Object.keys(it) || []).length);
  
    if (!isChildValid) {
      return [];
    }
  
    const formatedData= (data || []).map(({ id: value, roleType: label }, idx) => ({ ...data[idx], label, value }));
    //console.log('addNameValuesForUserRoles formated data',formatedData);
    //const alloption= {label: 'All' , value: data};
    //formatedData.push(alloption);
    return formatedData;
  };
  