import React, { useEffect } from 'react';
import BackNavigation from '../BackNavigation/BackNavigation';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import stickybits from 'stickybits';

const StyledFormHeader = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 2px 0px 17px;
  margin-bottom: 1em;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(204, 204, 204);
  z-index: 1;
  white-space: nowrap;
`;

interface OwnProps {
  navAdminType?: string;
  header: string;
  formUrl: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onFormSave: () => void;
  onCancelButtonClick?: () => void;
  showSaveAndCancelButtons?: boolean;
}

export const FormHeader: React.FC<OwnProps> = ({
  navAdminType = 'system',
  header,
  onFormSave,
  onCancelButtonClick,
  confirmButtonText = 'Save',
  cancelButtonText = 'Cancel',
  formUrl,
  showSaveAndCancelButtons = true,
}) => {
  const history = useHistory();

  useEffect(() => {
    stickybits('#header', { useStickyClasses: true, stickyBitStickyOffset: -20 });
  }, []);

  useEffect(() => {
    const stickybitsInstancetoBeUpdated = stickybits('#header', { stickyBitStickyOffset: -20 });
    stickybitsInstancetoBeUpdated.update();
    return () => {
      const stickybitsInstancetoBeCleanedUp = stickybits('#header', { stickyBitStickyOffset: -20 });
      stickybitsInstancetoBeCleanedUp.cleanup();
    };
  });

  return (
    <StyledFormHeader id="header">
      <BackNavigation to={`/${navAdminType === 'company' ? 'companyadmin' : 'systemadmin'}/${formUrl}`} />
      <div className="formHeader">{header}</div>
      {showSaveAndCancelButtons && (
        <div className="buttonContainer">
          <Button type="submit" color="primary" onClick={onFormSave}>
            {confirmButtonText}
          </Button>
          <Button
            color="secondary"
            onClick={onCancelButtonClick ? onCancelButtonClick : () => history.push(`/${navAdminType}admin/${formUrl}`)}
          >
            {cancelButtonText}
          </Button>
        </div>
      )}
    </StyledFormHeader>
  );
};
