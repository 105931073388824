import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import { IntegrationAssetType} from '../../../../../store/IntegrationAssetType/models';

import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs'
import { Formik } from 'formik';
import IntegrationAssetTypeForm from '../IntegrationAssetForm/IntegrationAssetTypeForm'
import { Loading } from '../../../../Loading';
import integrationAssetTypeFormValidation from '../FormValidations/integrationAssetTypeFormValidation';
import { initialValues } from '../FormInitialValues/integrationAssetTypeFormInitialValues';

interface OwnProps {
  editIntegrationAssetType: (integrationAssetType: IntegrationAssetType) => void;
  integrationAssetTypeId: string;
  company?: Company;
  integrationAssetTypes: IntegrationAssetType[];
  navAdminType: string;
  companyAdmin?: boolean;
  isLoading?: boolean;
}

const EditIntegrationAssetType: React.FunctionComponent<OwnProps> = ({
  editIntegrationAssetType,
  companyAdmin,
  navAdminType,
  company,
  integrationAssetTypeId,
  integrationAssetTypes,
  isLoading,
}) => {
  const submitIntegrationAssetType = (integrationAssetType: IntegrationAssetType) => {
    editIntegrationAssetType(integrationAssetType);
  };

  const selectedIntegrationAssetType: IntegrationAssetType | undefined = integrationAssetTypes.find((it) => it.id === integrationAssetTypeId);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={selectedIntegrationAssetType ? selectedIntegrationAssetType : initialValues}
      validationSchema={integrationAssetTypeFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetTypeForm
          integrationAssetType={selectedIntegrationAssetType}
          header={'Edit integration asset type'}
          saveMethod={submitIntegrationAssetType}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(EditIntegrationAssetType);
