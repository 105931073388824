import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { Button } from 'reactstrap';
import { getAuth } from '../../store/Auth/actions';
import { RoleType } from '../../models/RoleType';
import { ReactComponent as EmailSettingsLogo } from '../../svgs/email-settings.svg';
import { useHistory } from 'react-router-dom';

const UserProfile = styled.div`
  min-width: 300px;
  padding: 1rem;
  .introWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .intro {
      font-size: 28px;
    }
  }
  .myUserInfo {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .label {
    font-size: 0.75rem;
    color: grey;
  }
  .value {
    border-bottom: 1px solid #666666;
    font-size: 18px;
    &:last-child {
      border-bottom: none;
    }
  }
  hr {
    margin: 0;
  }
  .email-notifications {
    display: flex;
    flex-direction: row;
  }
  .email-notifications:hover {
    cursor: pointer;
  }
  .email-logo {
    width: 38px;
    height: 35px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .email-logo-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #24365c;
    font-size: 16px;
    font-weight: 600;
  }
`;

interface OwnProps {
  toggleMyUser: () => void;
  tooltipOpen: boolean;
}

const MyUser: React.FC<OwnProps> = ({ toggleMyUser, tooltipOpen }) => {
  const { authLoading, authError, authErrorDetails, auth, authIsLoaded } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  //Callbacks to redux
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  //Methods for calling redux callbacks
  const signOut = () => {
    window.location.replace(`/account/SignOut`);
  };

  const node = useRef<HTMLDivElement>(null);

  const handleClick = (e: { target: any }) => {
    // @ts-ignore
    if ((node || {}).current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    if (tooltipOpen) {
      toggleMyUser();
    }

    return;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const filteredRole = auth.roles.filter((role) => role !== RoleType.SysAdmin && role !== RoleType.CompAdmin && role !== RoleType.CompAdminBuyer);

  const vesselType =
    auth.company != null && auth.company.category.chorusType.toLowerCase() === 'vendor' ? 'Bunker' : 'Receiving';

  //On mount load everything if not loaded
  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const onClickEmailSettings = () => {
    history.push(`/emailsettings`);
    toggleMyUser();
  };

  const onClickCookieConsent = () => {
    history.push(`/cookielist`);
    toggleMyUser();
  };

  if (authLoading) {
    return <Loading />;
  } else if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <UserProfile ref={node}>
        <div className="introWrapper">
          <div className="intro">About me</div>
          <Button color="primary" onClick={signOut}>
            Logout
          </Button>
        </div>
        <div className="myUserInfo">
          {/* Dont show email notifications settings for captains and terminal operators */}
          {!auth.vessel && !auth.pipeline && (
            <div className="email-notifications" onClick={onClickEmailSettings}>
              <EmailSettingsLogo className="email-logo" />
              <div className="email-logo-text">My e-mail notification settings</div>
            </div>
          )}
          <p className="value"></p>
          <div className="email-notifications" onClick={onClickCookieConsent}>
            <div className="email-logo-text">Cookie consent</div>
          </div>
          <p className="value"></p>
          <div className="label">First Name</div>
          <p className="value">{auth.name.split(', ')[1] || ''}</p>
          <div className="label">Last Name</div>
          <p className="value">{auth.name.split(', ')[0] || ''}</p>
          <div className="label">Email</div>
          <p className="value">{auth.email}</p>
          <div className="label">Company</div>
          <p className="value">{(auth.company || {}).name || ''}</p>
          <div className="label">Role</div>
          <p className="value">{filteredRole}</p>
          {auth.vessel && (
            <>
              <div className="label">{vesselType} Vessel</div>
              <p className="value">{auth.vessel.vesselName}</p>
            </>
          )}
          {auth.pipeline && (
            <>
              <div className="label">Terminal</div>
              <p className="value">{auth.pipeline.name}</p>
            </>
          )}
        </div>
        <hr />
      </UserProfile>
    );
  }
};

export default MyUser;
