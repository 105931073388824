import { string } from 'prop-types';
import { IntegrationAssetWorkspace } from '../../../../../store/IntegrationAssetWorkspaces/models';

export const initialValues: IntegrationAssetWorkspace = {
  workspace: '',
  id: '',
  name: '',
  timeStamp: new Date,
  integrationAssetCompanyCompanyId: '',
};
