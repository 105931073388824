import React from 'react';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import styles from './Eye.module.scss';
import { ReactComponent as EyeOpenIcon } from '../../../../../../../../svgs/eye-open.svg';
import { ReactComponent as EyeClosedIcon } from '../../../../../../../../svgs/eye-closed.svg';

interface OwnProps {
  visible: boolean;
}

const Eye: React.FC<OwnProps> = ({ visible }) => (
  <div className={styles['wrapper']}>
    <Button className={styles['eyeButton']}>
      {visible ? (
        <EyeOpenIcon className={classnames(styles.eyeClosed, styles.eye)} />
      ) : (
        <EyeClosedIcon className={classnames(styles.eyeOpen, styles.eye)} />
      )}
    </Button>
  </div>
);

export default React.memo(Eye);
