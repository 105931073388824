import { BDNLocationSettingsWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const BDN_LOCATIONS_STTTINGS_LOADING = 'bdnLocationsettings/loading';
export type BDN_LOCATIONS_STTTINGS_LOADING = typeof BDN_LOCATIONS_STTTINGS_LOADING;

export const GET_BDN_LOCATIONS_STTTINGS = 'bdnLocationsettings/get';
export type GET_BDN_LOCATIONS_STTTINGS = typeof GET_BDN_LOCATIONS_STTTINGS;

export const BDN_LOCATIONS_STTTINGS_FETCH_ERROR = 'bdnLocationsettings/fetchError';
export type BDN_LOCATIONS_STTTINGS_FETCH_ERROR = typeof BDN_LOCATIONS_STTTINGS_FETCH_ERROR;

export const UPDATE_BDN_LOCATIONS_STTTINGS = 'bdnLocationsettings/update';
export type UPDATE_BDN_LOCATIONS_STTTINGS = typeof UPDATE_BDN_LOCATIONS_STTTINGS;

export const UPDATE_BDN_LOCATIONS_STTTINGS_ERROR = 'bdnLocationsettings/updateError';
export type UPDATE_BDN_LOCATIONS_STTTINGS_ERROR = typeof UPDATE_BDN_LOCATIONS_STTTINGS_ERROR;

export type BDNLocationSettingsActions =
  | {
      type: GET_BDN_LOCATIONS_STTTINGS;
      payload: BDNLocationSettingsWrapperModel['data'];
    }
  | {
      type: BDN_LOCATIONS_STTTINGS_LOADING;
    }
  | {
      type: BDN_LOCATIONS_STTTINGS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: UPDATE_BDN_LOCATIONS_STTTINGS;
      payload: BDNLocationSettingsWrapperModel['data'];
    }
  | {
      type: UPDATE_BDN_LOCATIONS_STTTINGS_ERROR;
      payload: ErrorActionPayload;
    };
