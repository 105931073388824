import { Contract, ContractBunkerLocation } from '../../../../store/Contract/models';

export const initialValues: Contract = {
  name: '',
  supplierId: '',
  customerId: '',
  contractRef: '',
  // startDate: new Date(),
  startDate: null,
  endDate: null,
  endDateOption: null,
  signedDate: new Date(),
  //   signedDate: null,
  quantityMinimum: 0.0,
  quantityOption: 0.0,
  pricePerUnit: '',
  currency: '',
  unit: '',
  calorificBasis: '',
  notes: '',
  negotiateOnPrice: false,
  createdAt: new Date(),
  editedAt: new Date(),
  chorusId: '',
  hasBeenAccepted: false,
  customerNumber: '',
  invoiceAddress: '',
  invoiceEmailAddress: '',
  contractBunkerLocations: [],
  additionalFees: '',
  active: true,
};
