import { TopicDataType } from '../../store/IntegrationAssetTopic/models'

export enum Topic {
    Outbound
}
export enum EventType {
    Nomination,
    BDN,
    //BDNPDF,
    SupplierInternal
}

export const eventTypeToDataType = {
    [EventType[EventType.Nomination]]: TopicDataType.NominationOutbound,
    [EventType[EventType.BDN]]: TopicDataType.BunkerDeliveryNoteOutbound,
    //[EventType[EventType.BDNPDF]]: TopicDataType.BunkerDeliveryNoteDocumentOutbound,
    [EventType[EventType.SupplierInternal]]: TopicDataType.SupplierInternalOutbound,
};


export const eventTypeToTopics: { [key in EventType]: Topic[] } = {
    [EventType.Nomination]: [Topic.Outbound],
    [EventType.BDN]: [Topic.Outbound],
    //[EventType.BDNPDF]: [Topic.Outbound],
    [EventType.SupplierInternal]: [Topic.Outbound],
};

export const assetTypeOptions = [
    { name: "Terminal" },
    { name: "Bunker Vessel" },
    { name: "Recieving Vessel" },
    { name: "Generic Vessel" },
    { name: "Truck" },
    { name: "Container" },
    { name: "Outbound" },
];

export const fbAssetTypeOptions = [
    { name: 'Bunkership', label: 'Bunkership' },
    { name: 'Pipeline', label: 'Pipeline' },
    { name: 'Truck', label: 'Truck' },
    { name: 'Container', label: 'Container' },
    { name: 'Recievingship', label: 'Recievingship' },
    { name: 'Outbound', label: 'Outbound' },
  ];