import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';
import NavMenu from './Components/NavMenu/NavMenu';
import styles from './Layout.module.scss';
import { ReactComponent as DnvglLogo } from '../../svgs/dnvgl-logo.svg';
import SessionTimeout from './Components/NavMenu/SessionTimeout/SessionTimeout';
import MyUser from '../MyUser/MyUser';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { getNameColor } from '../../helpers/getNameColor';
import Initials from '../../helpers/Initials';
import TokenTimeout from './Components/NavMenu/TokenTimeout/TokenTimeout';

const StyledTooltip = styled(Tooltip)`
  .tooltip {
    opacity: 1;
    margin-top: 1em;
    z-index: 1200;
    .tooltip-inner {
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      color: inherit;
      max-width: 450px;
      text-align: start;
    }
  }
`;

interface OwnProps {
  setTimeoutBoxDisplayed: (val: boolean) => void;
}


const Layout: React.FC<OwnProps> = ({ setTimeoutBoxDisplayed, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const auth = useSelector((state: ApplicationState) => state.auth.data);
  const firstName: string = auth.name.split(', ')[1] || '';
  const lastName: string = auth.name.split(', ')[0] || '';

  return (
    <div className={styles['layout']}>
      <SessionTimeout setTimeoutBoxDisplayed={setTimeoutBoxDisplayed} />
      <TokenTimeout setTimeoutBoxDisplayed={setTimeoutBoxDisplayed} />
      <div className={styles['sidebar']}>
        <NavMenu />
      </div>
      <div className={styles['rightside']}>
        <div className={styles['header']}>
          <div className={styles['appLogo']}>FuelBoss</div>
          <div className={styles['user-profile-wrapper']}>
            <div
              className={styles['user-image']}
              onClick={toggle}
              id="userProfileTooltip"
              style={getNameColor(firstName) as React.CSSProperties}
            >
              {Initials(firstName, lastName, true)}
            </div>
            <p onClick={toggle} id="userProfileTooltip">
              Hello, <span>{firstName}</span>!
            </p>
            <StyledTooltip placement="auto-start" isOpen={tooltipOpen} hideArrow target="userProfileTooltip">
              <MyUser toggleMyUser={toggle} tooltipOpen={tooltipOpen} />
            </StyledTooltip>
          </div>
          <div className={styles.separator} />
          <span className={styles.dnvgl}>
            <p>Powered By</p>
            <DnvglLogo className={styles['dnvgl-logo']} />
          </span>
        </div>
        <div className={styles['content']}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
