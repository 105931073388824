import React from 'react';
import { ReactComponent as ServerIcon } from './server.svg';
import { ReactComponent as ExclamationIcon } from '../svgs/exclamation-square.svg';
import { ErrorActionPayload } from '../../../../models/ErrorAction';
import styles from '../../Error.module.scss';
import { ReactComponent as ErrorIcon } from '../svgs/error-generic-graphic.svg';

interface OwnProps {
  error: ErrorActionPayload;
}

export const ServerError: React.FC<OwnProps> = React.memo(({ error }) => (
  <div className={styles.errorComponent}>
    <div className={styles['icon-wrapper']}>
      <ErrorIcon className={styles.image} />
      <div className={styles['status-code']}>{error ? error.statusCode : 505}</div>
    </div>
    <h2>Error</h2>
    <h5 className={styles.header}>We are sorry, but there seems to be an error contacting our servers.</h5>
    <div className={styles['error-description']}>
      <p>Try refreshing the page in a while.</p>
      <p>
        If the problem persists, please <a href="mailto:fuelboss@dnv.com">contact us</a> and let us know.
      </p>
    </div>

    <a className={`btn btn-primary ${styles['continue-button']}`} href="/">
      Click here to continue
    </a>
    {error && error.message && <div>{error.message}</div>}
  </div>
));
