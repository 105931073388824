import { VesselsWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const VESSELS_LOADING = 'vessels/loading';
export type VESSELS_LOADING = typeof VESSELS_LOADING;

export const GET_VESSELS = 'vessels/get';
export type GET_VESSELS = typeof GET_VESSELS;

export const VESSELS_FETCH_ERROR = 'vessels/fetchError';
export type VESSELS_FETCH_ERROR = typeof VESSELS_FETCH_ERROR;

export const ADD_VESSEL = 'vessels/add';
export type ADD_VESSEL = typeof ADD_VESSEL;

export const ADD_VESSEL_ERROR = 'vessels/addVesselError';
export type ADD_VESSEL_ERROR = typeof ADD_VESSEL_ERROR;

export const DELETE_VESSEL = 'vessels/delete';
export type DELETE_VESSEL = typeof DELETE_VESSEL;

export const DELETE_VESSEL_ERROR = 'vessels/deleteVesselError';
export type DELETE_VESSEL_ERROR = typeof DELETE_VESSEL_ERROR;

export const EDIT_VESSEL = 'vessels/edit';
export type EDIT_VESSEL = typeof EDIT_VESSEL;

export const EDIT_VESSEL_ERROR = 'vessels/editVesselError';
export type EDIT_VESSEL_ERROR = typeof EDIT_VESSEL_ERROR;

export type VesselsActions =
  | {
      type: GET_VESSELS;
      payload: VesselsWrapperModel['data'];
    }
  | {
      type: VESSELS_LOADING;
    }
  | {
      type: VESSELS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_VESSEL;
      payload: VesselsWrapperModel['data'];
    }
  | {
      type: ADD_VESSEL_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_VESSEL;
      payload: VesselsWrapperModel['data'];
    }
  | {
      type: DELETE_VESSEL_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: EDIT_VESSEL;
      payload: VesselsWrapperModel['data'];
    }
  | {
      type: EDIT_VESSEL_ERROR;
      payload: ErrorActionPayload;
    };
