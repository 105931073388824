import { Dispatch } from 'redux';
import {
  IntegrationAssetApiTrackerActions,
  INTEGRATION_ASSET_APITRACKER_LOADING,
  INTEGRATION_ASSET_APITRACKERS_FETCH_ERROR,
  GET_INTEGRATION_ASSET_APITRACKERS,
  ADD_INTEGRATION_ASSET_APITRACKER,
  INTEGRATION_ASSET_APITRACKERS_ADD_ERROR,
  DELETE_INTEGRATION_ASSET_APITRACKER,
  EDIT_INTEGRATION_ASSET_APITRACKER,
  INTEGRATION_ASSET_APITRACKERS_DELETE_ERROR,
  INTEGRATION_ASSET_APITRACKERS_EDIT_ERROR,
  INTEGRATION_ASSET_APITRACKERS_CLEAR_TOAST,
} from './types';
import { api } from '../../helpers/api';
import { IntegrationAssetApiTrackerResponseModel, IntegrationAssetApiTracker } from './models';
import { Role } from '../Roles/models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { IntegrationAssetTopic } from '../IntegrationAssetTopic/models';
import { IntegrationAsset } from '../IntegrationAsset/models';

const updateLoaderModal: ReactElement = (
  <ModalResponse status="loading">Updating integrationAssetApiTracker...</ModalResponse>
);
const updateSuccessModal: ReactElement = (
  <ModalResponse status="success">IntegrationAssetApiTracker updated successfully</ModalResponse>
);

const createLoaderModal: ReactElement = (
  <ModalResponse status="loading">Creating integrationAssetApiTracker...</ModalResponse>
);
const createSuccessModal: ReactElement = (
  <ModalResponse status="success">IntegrationAssetApiTracker created successfully</ModalResponse>
);

const deleteLoaderModal: ReactElement = (
  <ModalResponse status="loading">Deleting IntegrationAssetApiTracker...</ModalResponse>
);

const deleteSuccessModal: ReactElement = (
  <ModalResponse status="success">IntegrationAsset ApiTracker deleted successfully</ModalResponse>
);

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getIntegrationAssetApiTrackers = () => (dispatch: Dispatch<IntegrationAssetApiTrackerActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_APITRACKER_LOADING,
  });

  new api<IntegrationAssetApiTrackerResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_APITRACKERS_FETCH_ERROR,
  })
    .get(`/api/Integration/AssetApiTracker`)
    .then((response) => {
      
      dispatch({
        type: GET_INTEGRATION_ASSET_APITRACKERS,
        payload: response,
      });
    });
};

export const addIntegrationAssetApiTracker = (
  //integrationAssetApiTracker: IntegrationAssetApiTracker,
  integrationAssetTopic: IntegrationAssetTopic,
  integrationAsset: IntegrationAsset,
  fbAssetType: string,
  fbAssetId: string,
  onSuccess: () => void,
) => (dispatch: Dispatch<IntegrationAssetApiTrackerActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_APITRACKER_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  const input = {
    integrationAssetTopic: integrationAssetTopic,
    integrationAsset: integrationAsset,
    fbAssetType: fbAssetType,
    fbAssetId: fbAssetId,
  };

  new api<IntegrationAssetApiTrackerResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_APITRACKERS_ADD_ERROR,
  })
    .post(`/api/Integration/AssetApiTracker`, input)
    .then((response) => {
      dispatch({
        type: ADD_INTEGRATION_ASSET_APITRACKER,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteIntegrationAssetApiTracker = (id: string) => (
  dispatch: Dispatch<IntegrationAssetApiTrackerActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_APITRACKER_LOADING,
  });

  const url = `/api/Integration/AssetApiTracker/${id}`;
  new api<IntegrationAssetApiTrackerResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_APITRACKERS_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_INTEGRATION_ASSET_APITRACKER,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: deleteSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const editIntegrationAssetApiTracker = (
  integrationAssetApiTracker: IntegrationAssetApiTracker,
  onSuccess: () => void,
) => (dispatch: Dispatch<IntegrationAssetApiTrackerActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_APITRACKER_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (integrationAssetApiTracker.id != undefined) {
    const url = `/api/Integration/AssetApiTracker/${integrationAssetApiTracker.id}`;

    new api<IntegrationAssetApiTrackerResponseModel>({
      dispatch,
      errorType: INTEGRATION_ASSET_APITRACKERS_EDIT_ERROR,
    })
      .put(url, integrationAssetApiTracker)
      .then((response) => {
        dispatch({
          type: EDIT_INTEGRATION_ASSET_APITRACKER,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const integrationAssetApiTrackersClearToast = () => (dispatch: Dispatch<IntegrationAssetApiTrackerActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_APITRACKERS_CLEAR_TOAST,
  });
};
