import { AISFilterBookmark, AISFilterBookmarkResponseModel, AISFilterBookmarkWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const AISFILTERBOOKMARKS_LOADING = 'aisFilterBookmark/loading';
export type AISFILTERBOOKMARKS_LOADING = typeof AISFILTERBOOKMARKS_LOADING;

export const GET_AISFILTERBOOKMARKS = 'aisFilterBookmark/get';
export type GET_AISFILTERBOOKMARKS = typeof GET_AISFILTERBOOKMARKS;

export const AISFILTERBOOKMARKS_FETCH_ERROR = 'aisFilterBookmark/fetchError';
export type AISFILTERBOOKMARKS_FETCH_ERROR = typeof AISFILTERBOOKMARKS_FETCH_ERROR;

export const ADD_AISFILTERBOOKMARK = 'aisFilterBookmark/add';
export type ADD_AISFILTERBOOKMARK = typeof ADD_AISFILTERBOOKMARK;

export const AISFILTERBOOKMARK_ADD_ERROR = 'aisFilterBookmark/addError';
export type AISFILTERBOOKMARK_ADD_ERROR = typeof AISFILTERBOOKMARK_ADD_ERROR;

export const AISFILTERBOOKMARK_DELETE_ERROR = 'aisFilterBookmark/deleteError';
export type AISFILTERBOOKMARK_DELETE_ERROR = typeof AISFILTERBOOKMARK_DELETE_ERROR;

export const AISFILTERBOOKMARK_EDIT_ERROR = 'aisFilterBookmark/editError';
export type AISFILTERBOOKMARK_EDIT_ERROR = typeof AISFILTERBOOKMARK_EDIT_ERROR;

export const DELETE_AISFILTERBOOKMARK = 'aisFilterBookmark/delete';
export type DELETE_AISFILTERBOOKMARK = typeof DELETE_AISFILTERBOOKMARK;

export const EDIT_AISFILTERBOOKMARK = 'aisFilterBookmark/edit';
export type EDIT_AISFILTERBOOKMARK = typeof EDIT_AISFILTERBOOKMARK;

export const AISFILTERBOOKMARK_CLEAR_TOAST = 'aisFilterBookmark/clearToast'
export type AISFILTERBOOKMARK_CLEAR_TOAST = typeof AISFILTERBOOKMARK_CLEAR_TOAST;

export type AISFilterBookmarkActions =
  | {
      type: GET_AISFILTERBOOKMARKS;
      payload: AISFilterBookmarkWrapperModel['data'];
    }
  | {
      type: AISFILTERBOOKMARKS_LOADING;
    }
  | {
      type: AISFILTERBOOKMARKS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: AISFILTERBOOKMARK_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: AISFILTERBOOKMARK_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: AISFILTERBOOKMARK_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_AISFILTERBOOKMARK;
      payload: AISFilterBookmark;
    }
  | {
      type: DELETE_AISFILTERBOOKMARK;
      payload: string;
    }
  | {
      type: EDIT_AISFILTERBOOKMARK;
      payload: AISFilterBookmark;
    } | {
      type: AISFILTERBOOKMARK_CLEAR_TOAST
    };
