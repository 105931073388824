export default {
  category: {
    label: 'Category',
    show: true,
    placeholder: 'Please choose a category',
  },
  name: {
    label: 'Company name',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
  country: {
    label: 'Country',
    show: true,
    placeholder: 'Please choose a country',
  },
  address: {
    label: 'Address',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
  orgNumber: {
    label: 'Unique VAT or business number',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
  companyInBrief: {
    label: 'Company in brief',
    show: true,
    placeholder: '',
  },
  logo: {
    label: 'Company logo',
    show: true,
  },
  invoiceEmailAddress: {
    label: 'Invoice email',
    show: true,
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
    placeholder: '',
  },
  invoiceAddress: {
    label: 'Invoice address',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
};
