import { Auth } from '../store/Auth/models';
import { RoleType } from '../models/RoleType';
import { Category } from '../models/Category';

export const checkSystemAdmin = (auth: Auth) => {
  let isAdmin = false;
  if (auth && auth.roles) {
    auth.roles.forEach((role) => {
      if (role === RoleType.SysAdmin) {
        isAdmin = true;
        return;
      }
    });
  }
  return isAdmin;
};

export const checkCompanyAdmin = (auth: Auth) => {
  let isAdmin = false;
  if (auth && auth.roles) {
    auth.roles.forEach((role) => {
      if (role === RoleType.CompAdmin || role === RoleType.CompAdminBuyer) {
        isAdmin = true;
        return;
      }
    });
  }
  return isAdmin;
};

export const checkBunkerVesselCrew = (auth: Auth) => {
  let isCaptain = false;
  if (auth && auth.roles) {
    auth.roles.forEach((role) => {
      if (role === RoleType.BunkerVesselCrew) {
        isCaptain = true;
        return;
      }
    });
  }
  return isCaptain;
};

export const checkReceivingVesselCrew = (auth: Auth) => {
  let isCaptain = false;
  if (auth && auth.roles) {
    isCaptain = auth.roles.includes(RoleType.RecVesselCrew);
  }
  return isCaptain;
};

export const checkTerminalOperator = (auth: Auth) => {
  let isTerminalOperator = false;
  if (auth && auth.roles) {
    auth.roles.forEach((role) => {
      if (role === RoleType.TerminalOperator) {
        isTerminalOperator = true;
        return;
      }
    });
  }
  return isTerminalOperator;
};

export const checkCaptains = (auth: Auth) => {
  let isCaptain = false;
  if (auth && auth.roles) {
    isCaptain =
      auth.roles.includes(RoleType.RecVesselCrew) ||
      auth.roles.includes(RoleType.BunkerVesselCrew) ||
      auth.roles.includes(RoleType.CaptainScheduler) ||
      auth.roles.includes(RoleType.ReceivingCaptainScheduler);
  }
  return isCaptain;
};

export const checkCaptainScheduler = (auth: Auth) => {
  let isSchedulerCaptain = false;
  if (auth && auth.roles) {
    auth.roles.forEach((role) => {
      if (role === RoleType.CaptainScheduler) {
        isSchedulerCaptain = true;
        return;
      }
    });
  }
  return isSchedulerCaptain;
};

export const checkReceivingCaptainScheduler = (auth: Auth) => {
  let isSchedulerCaptain = false;
  if (auth && auth.roles) {
    auth.roles.forEach((role) => {
      if (role === RoleType.ReceivingCaptainScheduler) {
        isSchedulerCaptain = true;
        return;
      }
    });
  }
  return isSchedulerCaptain;
};

export const checkShipOwnerAdministrative = (auth: Auth) => {
  let isAdministrative = false;
  if (auth && auth.roles) {
    isAdministrative = auth.roles.includes(RoleType.Administrative);
  }
  return isAdministrative;
};

export const checkCommercial = (auth: Auth) => {
  let isAdministrative = false;
  if (auth && auth.roles) {
    isAdministrative = auth.roles.includes(RoleType.Commercial);
  }
  return isAdministrative;
};

export const checkProcurement = (auth: Auth) => {
  let isProcurement = false;
  if (auth && auth.roles) {
    isProcurement = auth.roles.includes(RoleType.Procurement);
  }
  return isProcurement;
};

export const checkOperator = (auth: Auth) => {
  let isOperator = false;
  if (auth && auth.roles) {
    isOperator = auth.roles.includes(RoleType.Operator);
  }
  return isOperator;
};

export const checkVesselOperator = (auth: Auth) => {
  let isVesselOperator = false;
  if (auth && auth.roles) {
    auth.roles.forEach((role) => {
      if (role === RoleType.VesselOperator) {
        isVesselOperator = true;
        return;
      }
    });
  }
  return isVesselOperator;
};

export const canCreateNominationOnBehalf = (auth: Auth) => {
  let canCreateNomination = false;
  const isSupplier = auth.company?.category.categoryName == Category.Supplier;
  if (auth && auth.roles) {
    canCreateNomination =
      auth.roles.includes(RoleType.Operator) ||
      auth.roles.includes(RoleType.Commercial) ||
      auth.roles.includes(RoleType.CaptainScheduler) ||
      auth.roles.includes(RoleType.ReceivingCaptainScheduler);
  }
  return isSupplier && canCreateNomination;
};

export const checkShipOwner = (auth: Auth) => {
  return auth.company && auth.company.category.categoryName == Category.Shipowner;
};
export const checkSupplier = (auth: Auth) => {
  return auth.company && auth.company.category.categoryName == Category.Supplier;
};
export const checkCharterer = (auth: Auth) => {
  return auth.company && auth.company.category.categoryName == Category.Charterer;
};

export const canAccessRecurringNominations = (auth: Auth) => {
  let canAccess = false;
  if (auth && auth.roles) {
    canAccess =
      auth.roles.includes(RoleType.VesselOperator) ||
      auth.roles.includes(RoleType.Procurement) ||
      auth.roles.includes(RoleType.ReceivingCaptainScheduler) ||
      auth.roles.includes(RoleType.Operator) ||
      auth.roles.includes(RoleType.Commercial) ||
      auth.roles.includes(RoleType.CaptainScheduler);
  }
  return canAccess;
};

export const canCreateSpotEnquiry = (auth: Auth) => {
  if (auth && auth.roles) {
    return (
      (checkShipOwner(auth) || checkCharterer(auth)) &&
      !checkReceivingVesselCrew(auth) &&
      !checkReceivingCaptainScheduler(auth) &&
      !checkShipOwnerAdministrative(auth)
    );
  } else {
    return false;
  }
};
