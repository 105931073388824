import styled, { css } from 'styled-components';
import { buttonBase, buttonProps, buttonDefaults } from './ButtonBase';

const BaseButton = styled.button`
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
      background: ${({ theme: { colors } }) => colors.neutral20};
    `}
  ${buttonBase};
  &:hover {
    text-decoration: none;
  }
`;

BaseButton.propTypes = buttonProps;

BaseButton.defaultProps = buttonDefaults;

export default BaseButton;
