import * as TYPE from './types';

import { ApplicationState } from '..';

export const archived_nominationReducer = (
  state: ApplicationState['archived_nomination'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: undefined,
  },
  action: TYPE.ArchivedNominationActions,
): ApplicationState['archived_nomination'] => {
  switch (action.type) {
    case TYPE.ARCHIVED_NOMINATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.ARCHIVED_NOMINATION_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_ARCHIVED_NOMINATION:
      return {
        ...state,
        data: action.payload.data,
        metadata: action.payload.metadata,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_APPEND:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        loading: false,
        error: false,
        dataIsLoaded: true,
      };

    case TYPE.GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };

    case TYPE.CLEAR_ARCHIVED_NOMINATION:
      return {
        loading: false,
        error: false,
        dataIsLoaded: false,
        data: [],
        metadata: undefined,
      };
    default:
      return { ...state };
  }
};
