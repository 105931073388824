import { ErrorActionPayload } from '../../models/ErrorAction';
import { ChorusCompanyWrapperModel } from './models';

export const CHORUSCOMPANY_LOADING = 'choruscompany/loading';
export type CHORUSCOMPANY_LOADING = typeof CHORUSCOMPANY_LOADING;

export const GET_CHORUSCOMPANY = 'choruscompany/get';
export type GET_CHORUSCOMPANY = typeof GET_CHORUSCOMPANY;

export const CHORUSCOMPANY_FETCH_ERROR = 'choruscompany/fetchError';
export type CHORUSCOMPANY_FETCH_ERROR = typeof CHORUSCOMPANY_FETCH_ERROR;

export type ChorusCompanyActions =
  | {
      type: GET_CHORUSCOMPANY;
      payload: ChorusCompanyWrapperModel['data'];
    }
  | {
      type: CHORUSCOMPANY_LOADING;
    }
  | {
      type: CHORUSCOMPANY_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
