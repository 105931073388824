import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import styles from './CompanyAdmin.module.scss';
import classnames from 'classnames';
import { getBunkerShips } from '../../store/BunkerShip/actions';
import { getBunkerPipelines } from '../../store/BunkerPipeline/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { ApplicationState } from '../../store';
import MyCompany from './Components/MyCompany/MyCompany';
import Users from '../../components/AdminComponents/Users';
import {
  getFleetVessels,
  addFleetVessel,
  deleteFleetVessel,
  editFleetVessel,
  setCharterableFleetVessel,
} from '../../store/CompanyFleet/actions';
import { getAuth } from '../../store/Auth/actions';
import { setCompanyFormEditMode } from '../../store/Company/actions';
import { getCompanyLogo } from '../../store/CompanyLogo/actions';
import MyFleet from '../../components/AdminComponents/MyFleet/MyFleet';
import { Vessel } from '../../store/Vessels/models';
import { FleetVessel } from '../../store/CompanyFleet/models';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import Contracts from '../../components/AdminComponents/Contracts/';
import { Route } from 'react-router';
import { useEffect } from 'react';
import DeliveryMode from './Components/DeliveryModes';
import ServiceOfferings from './Components/ServiceOfferings/ServiceOfferings';
import Configurations from './Components/Configurations/Configurations';
import ThirdPartyConnections from './Components/ThirdPartyConnections/ThirdPartyConnections';
import LNGAssetData from './Components/LNGAssetData/LNGAssetData';
import { checkShipOwner, checkSupplier, checkCharterer, checkCompanyAdmin } from '../../helpers/roleChecks';
import { AuthenticationServiceLayer, IShipWithCompanyId, IPipeline, IShipWithId } from '@teqplay/chorus-components';
import { faInfinity } from '@fortawesome/pro-solid-svg-icons';
import { Company } from '../../store/Company/models';
import {
  addCharteredFleetVessel,
  deleteCharteredFleetVessel,
  getCharteredFleetVessels,
} from '../../store/CompanyCharteredFleet/actions';
import CompanyIntegrations from './Components/CompanyIntegrations/CompanyIntegrations';
//import OperationalAreas from './Components/ServiceOfferingAreas/OperationalAreas';
const LazyOperationalAreas = React.lazy(() => import('./Components/ServiceOfferingAreas/OperationalAreas'));
//import { faAddressBook, faBuilding, faFileSignature, faInfo, faShip, faUsers } from '@fortawesome/free-solid-svg-icons';

const faFileContract = findIconDefinition({ prefix: 'fal', iconName: 'file-contract' });
const faUsers = findIconDefinition({ prefix: 'fal', iconName: 'users' });
const faAddressBook = findIconDefinition({ prefix: 'fal', iconName: 'address-book' });
const faInfo = findIconDefinition({ prefix: 'fal', iconName: 'info' });
const faShip = findIconDefinition({ prefix: 'fal', iconName: 'ship' });
const faBuilding = findIconDefinition({ prefix: 'fal', iconName: 'building' });
const faPaste = findIconDefinition({ prefix: 'fal', iconName: 'paste' });
const faCogs = findIconDefinition({ prefix: 'fal', iconName: 'cogs' });
const faCompass = findIconDefinition({ prefix: 'fal', iconName: 'compass' });
const faMapMarkedAlt = findIconDefinition({ prefix: 'fal', iconName: 'map-marked-alt' });
const faFlaskPotion = findIconDefinition({ prefix: 'fal', iconName: 'flask-potion' });
const faLink = findIconDefinition({ prefix: 'fal', iconName: 'link' });

interface OwnProps {
  serviceLayer: AuthenticationServiceLayer;
}

const CompanyAdmin: React.FunctionComponent<OwnProps> = ({ serviceLayer }) => {
  const {
    auth,
    authIsLoaded,
    companyFleetLoading,
    companyFleetError,
    companyFleetErrorDetails,
    companyFleet,
    companyFleetIsLoaded,
    companyCharteredFleetLoading,
    companyCharteredFleetError,
    companyCharteredFleetErrorDetails,
    companyCharteredFleet,
    companyCharteredFleetIsLoaded,
    companies,
    isCompanyEditMode,
    companyLogo,
    companyLogoIsLoaded,
    bunkerShip,
    bunkerShipIsLoaded,
    bunkerShipLoading,
    bunkerShipError,
    bunkerShipErrorDetails,
    bunkerShipToastData,
    bunkerPipelines,
    bunkerPipelinesIsLoaded,
    bunkerPipelinesLoading,
    bunkerPipelinesError,
    bunkerPipelinesErrorDetails,
    bunkerPipelinesToastData
  } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,
    companyCharteredFleetLoading: state.companyCharteredFleet.loading,
    companyCharteredFleetError: state.companyCharteredFleet.error,
    companyCharteredFleetErrorDetails: state.companyCharteredFleet.errorDetails,
    companyCharteredFleet: state.companyCharteredFleet.data,
    companyCharteredFleetIsLoaded: state.companyCharteredFleet.dataIsLoaded,
    companies: state.companies.data,
    isCompanyEditMode: state.companies.isCompanyEditMode,
    companyLogo: state.company_logo.data,
    companyLogoIsLoaded: state.company_logo.dataIsLoaded,
    bunkerShip: state.bunkership.data,
    bunkerShipIsLoaded: state.bunkership.dataIsLoaded === true,
    bunkerShipLoading: state.bunkership.loading,
    bunkerShipError: state.bunkership.error,
    bunkerShipErrorDetails: state.bunkership.errorDetails,
    bunkerShipToastData: state.bunkership.toastData,
    bunkerPipelines: state.bunkerpipeline.data,
    bunkerPipelinesLoading: state.bunkerpipeline.loading,
    bunkerPipelinesIsLoaded: state.bunkerpipeline.dataIsLoaded === true,
    bunkerPipelinesError: state.bunkerpipeline.error,
    bunkerPipelinesErrorDetails: state.bunkerpipeline.errorDetails,
    bunkerPipelinesToastData: state.bunkerpipeline.toastData
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  const [filteredBunkerShips, setFilteredBunkerShips] = React.useState<IShipWithCompanyId[]>([]);

  const getBunkerShipCallback = React.useCallback(() => dispatch(getBunkerShips()), []);

  const getBunkerPipelineCallback = React.useCallback(() => dispatch(getBunkerPipelines()), []);

  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);
  const companyFormEditModeCallback = React.useCallback(
    (isEditMode) => dispatch(setCompanyFormEditMode(isEditMode)),
    [],
  );
  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyFleetIsLoaded) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  const getCompanyCharteredFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyCharteredFleetIsLoaded) {
      dispatch(getCharteredFleetVessels(auth.company.companyId));
    }
  }, []);

  const getCompanyLogoCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyLogoIsLoaded) {
      dispatch(getCompanyLogo(auth.company.companyId));
    }
  }, []);

  React.useEffect(() => {
    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }

    if (!authIsLoaded) {
      getAuthCallback();
    }

    if (!companyLogoIsLoaded) {
      getCompanyLogoCallback();
    }

    if (!companyCharteredFleetIsLoaded) {
      getCompanyCharteredFleetCallback();
    }

    if (!bunkerPipelinesIsLoaded) {
      getBunkerPipelineCallback();
    }

    if (!bunkerShipIsLoaded) {
      getBunkerShipCallback();
    }
  }, []);

  React.useEffect(() => {
    const bunkerVessels: IShipWithCompanyId[] = [];
    bunkerShip.forEach(function (ship, index) {
      //search bunker vessels in own vessels
      const shipMatch = companyFleet.find((x) => x.chorusId == ship._id);
      if (shipMatch && shipMatch.vessel.vesselType.toLowerCase() === 'bunkervessel') {
        bunkerVessels.push(ship);
      }
      //search bunker vessels in chartered vessels if not found in own fleet
      if (!shipMatch) {
        const charteredshipMatch = companyCharteredFleet.find((x) => x.fleetVessel?.chorusId == ship._id);
        if (
          charteredshipMatch &&
          charteredshipMatch.fleetVessel &&
          charteredshipMatch.fleetVessel.vessel.vesselType.toLowerCase() === 'bunkervessel'
        ) {
          bunkerVessels.push(ship);
        }
      }
    });
    setFilteredBunkerShips(bunkerVessels);
  }, [bunkerShip, companyFleet, companyCharteredFleet]);


  const deleteFleetVesselCallback = React.useCallback((id: string) => {
    if (auth.company && auth.company.companyId) dispatch(deleteFleetVessel(id, auth.company.companyId));
  }, []);

  const addFleetVesselCallback = React.useCallback((vessel: Vessel) => {
    if (auth.company && auth.company.companyId) dispatch(addFleetVessel(auth.company.companyId, vessel));
  }, []);

  const editFleetVesselCallback = React.useCallback((vessel: FleetVessel) => {
    if (auth.company && auth.company.companyId) dispatch(editFleetVessel(auth.company.companyId, vessel));
  }, []);

  const setCharterableFleetVesselCallback = React.useCallback((isCharterable: boolean, vessel: FleetVessel) => {
    if (auth.company && auth.company.companyId)
      dispatch(setCharterableFleetVessel(auth.company.companyId, isCharterable, vessel));
  }, []);

  const addCharteredFleetVesselCallback = React.useCallback((vessel: FleetVessel, allowShipOwnerToCreateNominations: boolean) => {
    if (auth.company && auth.company.companyId) dispatch(addCharteredFleetVessel(auth.company.companyId, allowShipOwnerToCreateNominations, vessel));
  }, []);

  const deleteCharteredFleetVesselCallback = React.useCallback((id: string) => {
    dispatch(deleteCharteredFleetVessel(id));
  }, []);

  //Methods for calling redux callbacks
  const deleteFleetVesselMethod = (id: string) => {
    deleteFleetVesselCallback(id);
  };

  const addFleetVesselMethod = (vessel: Vessel) => {
    addFleetVesselCallback(vessel);
  };

  const editFleetVesselMethod = (vessel: FleetVessel) => {
    editFleetVesselCallback(vessel);
  };

  const setCharterableFleetVesselMethod = (isCharterable: boolean, vessel: FleetVessel) => {
    setCharterableFleetVesselCallback(isCharterable, vessel);
  };

  const addCharteredFleetVesselMethod = (vessel: FleetVessel) => {
    if (!vessel.fleetCharterers) {
      vessel.fleetCharterers = [];
    }
    addCharteredFleetVesselCallback(vessel, false);
  };

  const deleteCharteredFleetVesselMethod = (id: string) => {
    deleteCharteredFleetVesselCallback(id);
  };

  const [companySliderMinified, setCompanySliderMinified] = React.useState(false);

  const routes = history.location.pathname.split('/');

  useEffect(() => {
    if (routes.length > 2) {
      return;
    }
    if (checkSupplier(auth)) history.push(`/companyadmin/delivery`);
    else history.push(`/companyadmin/fleet`);
  }, []);

  const [, , baseRoute] = routes;

  const [activeTab, setActiveTab] = React.useState(baseRoute);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    toggle(baseRoute);
  }, [routes]);

  const isRootRoute = (routes || []).length <= 3;

  const ActiveTab = {
    color: 'white',
    backgroundColor: '#262C42',
    filter: 'brightness(149%)',
    pointerEvents: 'none',
    opacity: '7',
  };
  const inActiveTab = {};
  return (
    <div className={styles['admin']}>
      <div className={styles['admin-content']}>
        <div className={styles['admin-header']}>
          <Nav className={styles['b']}>
            {checkCompanyAdmin(auth) && (
              <NavItem
                className={styles['b2']}
                onClick={() => {
                  history.push(`/companyadmin/contracts`);
                }}
                style={activeTab === 'contracts' ? ActiveTab : inActiveTab}
              >
                <NavLink className={classnames({ active: activeTab === 'contracts' })}>
                  <div className={styles['logo-area']}>
                    <FontAwesomeIcon
                      icon={faFileContract}
                      className={styles['logo-size']}
                      size={'lg'}
                      width={'15px'}
                      color="#009fda"
                    />
                  </div>
                  <div className={styles['logo-text-b']}>My contracts</div>
                </NavLink>
              </NavItem>
            )}
            {checkCompanyAdmin(auth) && (
              <NavItem
                className={styles['b2']}
                onClick={() => {
                  history.push(`/companyadmin/users`);
                }}
                style={activeTab === 'users' ? ActiveTab : inActiveTab}
              >
                <NavLink className={classnames({ active: activeTab === 'users' })}>
                  <div className={styles['logo-area']}>
                    {' '}
                    <FontAwesomeIcon
                      icon={faUsers}
                      className={styles['logo-size']}
                      size={'lg'}
                      width={'15px'}
                      color="#009fda"
                    />
                  </div>
                  <div className={styles['logo-text-b']}> My team</div>
                </NavLink>
              </NavItem>
            )}
            {(checkShipOwner(auth) || checkCharterer(auth)) && (
              <NavItem
                className={styles['b2']}
                onClick={() => {
                  history.push(`/companyadmin/fleet`);
                }}
                style={activeTab === 'fleet' ? ActiveTab : inActiveTab}
              >
                <NavLink className={classnames({ active: activeTab === 'fleet' })}>
                  <div className={styles['logo-area']}>
                    <FontAwesomeIcon
                      icon={faShip}
                      className={styles['logo-size']}
                      size={'lg'}
                      width={'15px'}
                      color="#009fda"
                    />
                  </div>
                  <div className={styles['logo-text-b']}>Company fleet</div>
                </NavLink>
              </NavItem>
            )}
            {checkSupplier(auth) && (
              <>
                <NavItem
                  className={styles['b1']}
                  onClick={() => {
                    history.push(`/companyadmin/delivery`);
                  }}
                  style={activeTab === 'delivery' ? ActiveTab : inActiveTab}
                >
                  <NavLink className={classnames({ active: activeTab === 'delivery' })}>
                    <div className={styles['logo-area']}>
                      <FontAwesomeIcon
                        icon={faCogs}
                        className={styles['logo-size']}
                        size={'lg'}
                        width={'15px'}
                        color="#009fda"
                      />{' '}
                    </div>
                    <div className={styles['logo-text']}>My service offerings</div>
                  </NavLink>
                </NavItem>
                {checkCompanyAdmin(auth) && <NavItem
                  className={styles['b1']}
                  onClick={() => {
                    history.push(`/companyadmin/service-areas`);
                  }}
                  style={activeTab === 'service-areas' ? ActiveTab : inActiveTab}
                >
                  <NavLink className={classnames({ active: activeTab === 'service-areas' })} >
                    <div className={styles['logo-area']}>
                      <FontAwesomeIcon
                        icon={faMapMarkedAlt}
                        className={styles['logo-size']}
                        size={'lg'}
                        width={'15px'}
                        color="#009fda"
                      />
                    </div>
                    <div className={styles['logo-text']}>Service offering areas</div>
                  </NavLink>
                </NavItem>}
              </>
            )}
            {checkSupplier(auth) && checkCompanyAdmin(auth) && (
              <NavItem
                className={styles['b1']}
                onClick={() => {
                  history.push(`/companyadmin/configuration`);
                }}
                style={activeTab === 'configuration' ? ActiveTab : inActiveTab}
              >
                <NavLink className={classnames({ active: activeTab === 'configuration' })}>
                  <div className={styles['logo-area']}>
                    {' '}
                    <FontAwesomeIcon
                      icon={faPaste}
                      className={styles['logo-size']}
                      size={'lg'}
                      width={'15px'}
                      color="#009fda"
                    />
                  </div>
                  <div className={styles['logo-text']}>Operational templates </div>
                </NavLink>
              </NavItem>
            )}
            <NavItem
              className={styles['b1']}
              onClick={() => {
                history.push(`/companyadmin/thirdparty`);
              }}
              style={activeTab === 'thirdparty' ? ActiveTab : inActiveTab}
            >
              <NavLink className={classnames({ active: activeTab === 'thirdparty' })}>
                <div className={styles['logo-area']}>
                  <FontAwesomeIcon
                    icon={faAddressBook}
                    className={styles['logo-size']}
                    size={'lg'}
                    width={'15px'}
                    color="#009fda"
                  />
                </div>
                <div className={styles['logo-text']}>Third party contacts </div>
              </NavLink>
            </NavItem>
            {checkSupplier(auth) && checkCompanyAdmin(auth) &&
              <NavItem
                className={styles['b1']}
                onClick={() => {
                  history.push(`/companyadmin/lngasset`)
                }}
                style={activeTab === 'lngasset' ? ActiveTab : inActiveTab}>
                <NavLink className={classnames({ active: activeTab === 'lngasset' })}>
                  <div className={styles['logo-area']}>
                    <FontAwesomeIcon
                      icon={faFlaskPotion}
                      className={styles['logo-size']}
                      size={'lg'}
                      width={'15px'}
                      color="#009fda"
                    />
                  </div>
                  <div className={styles['logo-text']}>Fuel data by asset</div>
                </NavLink>
              </NavItem>
            }
            {checkCompanyAdmin(auth) && (
              <NavItem
                className={styles['b2']}
                onClick={() => {
                  history.push(`/companyadmin/companyintegrations`);
                }}
                style={activeTab === 'companyintegrations' ? ActiveTab : inActiveTab}
              >
                <NavLink className={classnames({ active: activeTab === 'companyintegrations' })}>
                  <div className={styles['logo-area']}>
                    {' '}
                    <FontAwesomeIcon
                      icon={faLink}
                      className={styles['logo-size']}
                      size={'lg'}
                      width={'20px'}
                      color="#009fda"
                    />
                  </div>
                  <div className={styles['logo-text-b']}>Integrations </div>
                </NavLink>
              </NavItem>
            )}
            {checkCompanyAdmin(auth) && (
              <NavItem
                className={styles['b2']}
                onClick={() => {
                  history.push(`/companyadmin/companyprofile`);
                }}
                style={activeTab === 'companyprofile' ? ActiveTab : inActiveTab}
              >
                <NavLink className={classnames({ active: activeTab === 'companyprofile' })}>
                  <div className={styles['logo-area']}>
                    {' '}
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className={styles['logo-size']}
                      size={'lg'}
                      width={'20px'}
                      color="#009fda"
                    />
                  </div>
                  <div className={styles['logo-text-b']}>Company profile </div>
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </div>
        <div className={styles['admin-header-content']}>
          <TabContent activeTab={activeTab} style={{ marginLeft: '0.7em', padding: '0px 30px' }}>
            <TabPane tabId="contracts">
              {isRootRoute && <h3>My contracts</h3>}
              <Route
                path="/companyadmin/contracts"
                render={(props) => <Contracts {...props} navAdminType="company" />}
              />
            </TabPane>
            <TabPane tabId="users">
              {isRootRoute && <h3>My team</h3>}
              <Route
                path="/companyadmin/users"
                render={(props) => (
                  <Users {...props} companyAdmin={true} company={auth.company} navAdminType="company" />
                )}
              />
            </TabPane>
            <TabPane tabId="fleet">
              <h3>Company fleet</h3>
              <Route
                path="/companyadmin/fleet"
                exact
                render={(props) => (
                  <MyFleet
                    {...props}
                    chooseVesselMethod={addFleetVesselMethod}
                    companyFleet={companyFleet}
                    isLoading={companyFleetLoading}
                    hasError={companyFleetError}
                    errorDetails={companyFleetErrorDetails}
                    deleteVesselMethod={deleteFleetVesselMethod}
                    serviceLayer={serviceLayer}
                    editVesselMethod={editFleetVesselMethod}
                    setCharterableFleetVesselMethod={setCharterableFleetVesselMethod}
                    companyCharteredFleet={companyCharteredFleet}
                    addCharteredFleetVesselMethod={addCharteredFleetVesselMethod}
                    deleteCharteredFleetVesselMethod={deleteCharteredFleetVesselMethod}
                  />
                )}
              />
            </TabPane>
            <TabPane tabId="delivery">
              <h3>My service offerings</h3>
              <Route
                path="/companyadmin/delivery"
                render={(props) => <ServiceOfferings auth={auth} navAdminType="company" serviceLayer={serviceLayer} />}
              />
            </TabPane>
            <TabPane tabId="service-areas">
              <Route path="/companyadmin/service-areas">
                {checkCompanyAdmin(auth) && <LazyOperationalAreas />}
              </Route>
            </TabPane>
            <TabPane tabId="configuration">
              <h3>Operational templates</h3>
              <span>Manage your company's templates for different types of operational documents.</span>
              <Route
                path="/companyadmin/configuration"
                render={(props) => checkCompanyAdmin(auth) ? <Configurations auth={auth} navAdminType="company" serviceLayer={serviceLayer} /> : <></>}
              />
            </TabPane>
            <TabPane tabId="thirdparty">
              <h3>Third party contacts</h3>
              <span>
                Add third party companies and contacts to loop them into the communication cycles of your nomination.
              </span>
              <Route
                path="/companyadmin/thirdparty"
                render={(props) => (
                  <ThirdPartyConnections auth={auth} navAdminType="company" serviceLayer={serviceLayer} />
                )}
              />
            </TabPane>
            <TabPane tabId="lngasset">
              <h3>Fuel data by asset</h3>
              <Route
                path="/companyadmin/lngasset"
                render={(props) => (checkCompanyAdmin(auth) ?
                  <LNGAssetData auth={auth} navAdminType="company" serviceLayer={serviceLayer} bunkerShips={checkSupplier(auth) ? filteredBunkerShips : bunkerShip} bunkerPipelines={bunkerPipelines} /> : <></>
                )}
              />
            </TabPane>
            <TabPane tabId="companyprofile">
              <Route
                path="/companyadmin/companyprofile"
                exact
                render={(props) => (
                  <MyCompany
                    {...props}
                    company={auth.company ? auth.company : ({} as Company)}
                    companyLogo={companyLogo}
                    navAdminType="company"
                    companies={companies}
                    minified={companySliderMinified}
                    setEditMode={companyFormEditModeCallback}
                    isCompanyEditMode={isCompanyEditMode}
                    isCompanyAdmin={checkCompanyAdmin(auth)}
                  />
                )}
              />
            </TabPane>
            <TabPane tabId="companyintegrations">
              <h3>Integrations</h3>
              <Route
                path="/companyadmin/companyintegrations"
                render={(props) => checkCompanyAdmin(auth) ? <CompanyIntegrations {...props} auth={auth} navAdminType="company" /> : <></>}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default CompanyAdmin;
