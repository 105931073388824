export default {
    veracityAssetId: {
        label: 'Integration asset veracity id',
        show: true,
        placeholder: 'fb_t_risavika',
        regex: /^[0-9a-zA-Z]{2,}$/,
    },
    assetName: {
        label: 'Integration asset name',
        show: true,
        regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
        placeholder: '',
    },
    assetExternalName: {
        label: 'Integration asset external name',
        show: true,
        regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
        placeholder: '',
    },
    assetDescription: {
        label: 'Integration asset description',
        show: true,
        placeholder: '',
    },
    assetLocation: {
        label: 'Integration asset location',
        show: true,
        placeholder: '',
    },
    assettimeStamp : {
        label: 'Integration asset time stamp',
        show: true,
        placeholder: '',
    },
    ownerCompanyId : {
        label: 'Integration asset company',
        show: true,
        placeholder: 'Please select asset company',
    },
    company : {
        label: 'Integration asset company',
        show: true,
        placeholder: 'Please select asset company',
    },
    companyName : {
        label: 'Integration asset company name',
        show: true,
    },
    assetType: {
        label: 'Integration asset type',
        show: true,
        placeholder: 'Please select asset type',
    },
    type: {
        label: 'Integration asset type',
        show: true,
        placeholder: 'Please select asset type',
    },
    assetTypeTimeStamp: {
        label: 'Integration asset type time stamp',
        show: true,
        placeholder: "",
    },
    companyId: {
        label: 'Integration asset company owner id',
        show: true,
        placeholder: '',
    },
    company: {
        label: 'Integration asset company name',
        show: true,
        placeholder: "Please select asset company",
        regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    },
    veracityTenantId: {
        label: 'Veracity Tenant Id',
        show: true,
        placeholder: "00000000-0000-0000-0000-000000000000",
        regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    },
    assetCompanyTimeStamp: {
        label: "Integration asset company time stamp",
        show: true,
        placeholder: "",
    },
    eventType: {
        label: 'Integration event type',
        show: true,
        placeholder: "Please select event type",
    },
    eventTypeTimeStamp: {
        label: 'Integration event type time stamp',
        show: true,
        placeholder: "",
    },
    dataChannelId: {
        label: 'Integration asset data channel id',
        show: true,
        placeholder: "fb_t_risavika_mass",
        regex: /^[a-zA-Z]{2}_[a-zA-Z]{1,}_[0-9a-zA-Z]{2,}_[a-zA-Z]{2,}$/,
    },
    topic: {
        label: 'Integration asset topic',
        show: true,
        placeholder: "fb_t_risavika_mass",
        regex: /^[0-9a-zA-Z_]{2,}$/,
    },
    dataStructure: {
        label: 'Integration asset data structure',
        show: true,
        placeholder: "",
    },
    dataChannelIdTimeStamp: {
        label: 'Integration asset data channel id time stamp',
        show: true,
        placeholder: "",
    },
    dataEventType: {
        label: 'Data channel event type',
        show: true,
        placeholder: "Please select event type",
    },
    assetIdIssuer: {
        label: 'Integration asset issuer Enter IMO',
        show: true,
        placeholder: '',
    },
    assetIdSchema: {
        label: 'Integration asset schema id     Enter IMO',
        show: true,
        placeholder: '',
    },
    assetSchema: {
        label: 'Integration asset schema',
        show: true,
        placeholder: '',
    },
    assetSchematimeStamp : {
        label: 'Integration asset schema time stamp',
        show: true,
        placeholder: '',
    },
    ownerCompanyId: {
        label: 'Integration asset permissions owner',
        show: true,
        placeholder: "",
    },
    externalAccessCompanyId: {
        label: 'Integration asset external access',
        show: true,
        placeholder: "",
    },
    assetPermissionsTimeStamp: {
        label: 'Integration asset permissions time stamp',
        show: true,
        placeholder: "",
    },
    read: {
        label: "Can external company read",
        show: true,
        placeholder: "read",
    },
    write: {
        label: "Can external company write",
        show: true,
        placeholder: "write",
    },
    ownerCompany: {
        label: 'Integration asset permissions owner',
        show: true,
        placeholder: "Please select asset company",
    },
    externalCompany: {
        label: 'Integration asset external access',
        show: true,
        placeholder: "Please select asset company",
    },
    dataType: {
        label: 'Data type',
        show: true,
        placeholder: "Please select data type",
    },
    workspace: {
        label: 'Veracity Workspace Id',
        show: true,
        placeholder: "00000000-0000-0000-0000-000000000000",
        regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    },
    workspaceId: {
        label: 'Veracity Workspace',
        show: true,
        placeholder: "",
        regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    },
    name: {
        label: 'Integration asset workspace name',
        show: true,
        regex: /^[a-z0-9]*/,
        placeholder: '',
    },
    id : {
        label: 'Id',
        show: true,
    },
};