import { Dispatch } from 'redux';
import {
  BIcompanyFleetActions,
  BI_COMPANY_FLEET_LOADING,
  BI_COMPANY_FLEET_FETCH_ERROR,
  GET_BI_COMPANY_FLEET,
  BI_COMPANY_CLEAR_TOAST,
  ADD_BI_COMPANY_FLEET,
  DELETE_BI_COMPANY_FLEET,
  DELETE_BI_COMPANY_FLEET_ERROR,
  ADD_BI_COMPANY_FLEET_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { BIcompanyFleetResponseModel, BIVesselModel, LLoydsVessel } from './models';

export const biVesselClearToast = () => (dispatch: Dispatch<BIcompanyFleetActions>) => {
  dispatch({
    type: BI_COMPANY_CLEAR_TOAST,
  });
};

export const getBIcompanyFleet = () => (dispatch: Dispatch<BIcompanyFleetActions>) => {
  dispatch({
    type: BI_COMPANY_FLEET_LOADING,
  });
  new api<BIcompanyFleetResponseModel>({
    dispatch,
    errorType: BI_COMPANY_FLEET_FETCH_ERROR,
  })
    .get(`/api/BIvessels/getBIcompanyFleet`)
    .then((response) => {
      dispatch({
        type: GET_BI_COMPANY_FLEET,
        payload: response,
      });
    });
};
export const addBIfleetItem = (vessel: LLoydsVessel) => (dispatch: Dispatch<BIcompanyFleetActions>) => {
  dispatch({
    type: BI_COMPANY_FLEET_LOADING,
  });
  new api<BIcompanyFleetResponseModel>({
    dispatch,
    errorType: ADD_BI_COMPANY_FLEET_ERROR,
  })
    .post(`/api/BIvessels`, convertType(vessel))
    .then((response) => {
      dispatch({
        type: ADD_BI_COMPANY_FLEET,
        payload: response,
      });
    });
};

export const removeBIfleetItem = (vessel: LLoydsVessel) => (dispatch: Dispatch<BIcompanyFleetActions>) => {
  dispatch({
    type: BI_COMPANY_FLEET_LOADING,
  });
  new api<BIcompanyFleetResponseModel>({
    dispatch,
    errorType: DELETE_BI_COMPANY_FLEET_ERROR,
  })
    .delete(`/api/BIvessels/RemoveVessel`, convertType(vessel))
    .then((response) => {
      dispatch({
        type: DELETE_BI_COMPANY_FLEET,
        payload: response,
      });
    });
};

export const removeBIfleetItemById = (id: string) => (dispatch: Dispatch<BIcompanyFleetActions>) => {
  dispatch({
    type: BI_COMPANY_FLEET_LOADING,
  });
  new api<BIcompanyFleetResponseModel>({
    dispatch,
    errorType: BI_COMPANY_FLEET_FETCH_ERROR,
  })
    .delete(`/api/BIvessels`, id)
    .then((response) => {
      dispatch({
        type: GET_BI_COMPANY_FLEET,
        payload: response,
      });
    });
};

export const convertType = (vessel: LLoydsVessel) => {
  const biVesselModel: BIVesselModel = {
    pKvesseLloyds: vessel.pK_VESSELLLOYDS,
    imo: vessel.bK_IMONO,
    vessel_name: vessel.vesselname,
    vesselTypeL3: vessel.l3_LLOYDSTYPENAME,
    owner: vessel.registeredownerfullname,
    dateOfBuild: vessel.dateofbuild,
    grossTon: vessel.grosston,
  };
  return biVesselModel;
};
