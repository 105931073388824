import React from 'react';
import FormikWrapper from '../Formik/FormikWrapper';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';

interface OwnProps {
    addNewIntegrationAsset: (integrationAsset: IntegrationAsset) => void;
    systemAdmin?: boolean;
    navAdminType: string;
}

const AddIntegrationAsset: React.FC<OwnProps> =({
    addNewIntegrationAsset,
    systemAdmin,
    navAdminType
}) => {
    const submitIntegrationAsset = (integrationAsset: IntegrationAsset) => {
        addNewIntegrationAsset(integrationAsset);
    }

    return(
        <FormikWrapper
        systemAdmin={systemAdmin}
        header="Add integration asset"
        addIntegrationAsset={submitIntegrationAsset}
        navAdminType={navAdminType}
        />
    );
};

export default React.memo(AddIntegrationAsset);