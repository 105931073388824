import * as TYPE from './types';
import { ApplicationState } from '..';
export const myFleetsReducer = (
    state: ApplicationState['myFleets'] = {
        loading: true,
        error: false,
        dataIsLoaded: false,
        data: [],
        metadata: {},
    },
    action: TYPE.MyFleetsActions,
): ApplicationState['myFleets'] => {
    switch (action.type) {
        case TYPE.MYFLEETS_LOADING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case TYPE.GET_MYFLEETS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                dataIsLoaded: true,
            };
        case TYPE.MYFLEETS_FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorDetails: action.payload,
            };
        case TYPE.ADD_MYFLEETS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                dataIsLoaded: true,
            };
        case TYPE.DELETE_MYFLEETS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                dataIsLoaded: true,
            };
        case TYPE.EDIT_MYFLEETS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: false,
                dataIsLoaded: true,
            };
    }
    return { ...state };
};
