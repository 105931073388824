import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useState } from 'react';
import styles from './Overview.module.scss';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Category } from '../../models/Category';
import { RoleType } from '../../models/RoleType';
import {
  checkReceivingVesselCrew,
  checkBunkerVesselCrew,
  checkTerminalOperator,
  checkShipOwnerAdministrative,
  canCreateNominationOnBehalf,
  checkCharterer,
  checkCaptainScheduler,
  checkReceivingCaptainScheduler,
  canCreateSpotEnquiry,
} from '../../helpers/roleChecks';
import Header from './Components/Header/Header';
import { DeliveryModes } from '../../models/DeliveryMode';
import OverviewNominationList from './Components/OverviewNominationList/OverviewNominationList';
import classnames from 'classnames';
import OverviewSpotList from './Components/OverviewSpotList/OverviewSpotList';

const Overview: React.FC = () => {
  const auth = useSelector((state: ApplicationState) => state.auth.data);

  const [deliveryModes, setdeliveryModes] = useState([
    DeliveryModes.Container,
    DeliveryModes.Pipe,
    DeliveryModes.Ship,
    DeliveryModes.Truck,
  ]);
  const [sortBy, setSortBy] = useState<'time' | 'needsAction'>('time');
  const [search, setSearch] = useState('');
  const [isExclude, setIsExclude] = useState<boolean>(false);
  const [activeBunkerEventTab, setActiveBunkerEventTab] = useState('1');
  const [activeSpotEnquieryTab, setActiveSpotEnquieryTab] = useState('1');
  const [isSearching, setIsSearching] = useState(false);

  const isShipOwner = auth && auth.company?.category.categoryName === Category.Shipowner;
  const isSupplier = auth && auth.company?.category.categoryName === Category.Supplier;
  const isCaptain = auth && auth.roles.includes(RoleType.RecVesselCrew);
  const canCreateNomination = auth && auth.company?.category.categoryName !== Category.Supplier && !isCaptain;

  if (auth) {
    return (
      <div className={styles['overview-wrapper']}>
        <Header

          isSearching={isSearching}
          setIsSearching={setIsSearching}
          newSpotEnquiryAllowed={canCreateSpotEnquiry(auth) ?? false}
          nominationCreateAllowed={(canCreateNomination ?? false) || canCreateNominationOnBehalf(auth)}
          nominationOnBehalf={canCreateNominationOnBehalf(auth)}
          search = {search}
          setSearch ={(value) => setSearch(value)}
          isExclude={isExclude}
          setIsExclude={(value) => setIsExclude(value)}
          sortBy={sortBy}
          setSortBy={(value) => setSortBy(value)}
          deliveryModes={deliveryModes}
          setDeliveryModes={(value) => setdeliveryModes(value)}
        />
        <div className={styles['content-wrapper']}>
          <Nav tabs className={`${styles['nav']} sticky-top bg-white bunker-events-nav`}>
            <NavItem>
              <NavLink
                className={classnames({ active: activeBunkerEventTab === '1' })}
                onClick={() => setActiveBunkerEventTab('1')}
              >
                Bunker events
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeBunkerEventTab === '2' })}
                onClick={() => {
                  setActiveBunkerEventTab('2');
                }}
              >
                Archived
              </NavLink>
            </NavItem>
            <div className={styles['abbreviation-tips']}>
              <p>LBV = Bunker vessel</p>
              <p>RV = Receiving vessel</p>
              <p>TO = Terminal operator</p>
            </div>
          </Nav>
          <TabContent activeTab={activeBunkerEventTab}>
            <TabPane tabId="1">
              <div className={styles['tab-pane-content']}>
                <OverviewNominationList
                isSearching={isSearching}
                  deliveryModes={deliveryModes}
                  sortBy={sortBy}
                  search= {search}
                  isExclude={isExclude}
                  userCompanyId={auth.company?.chorusId}
                  isSupplier={isSupplier}
                  fetchData={activeBunkerEventTab === '1'}
                  
                />
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className={styles['tab-pane-content']}>
                <OverviewNominationList
                isSearching={isSearching}
                  deliveryModes={deliveryModes}
                  sortBy="time"
                  search= {search}
                  isExclude={isExclude}
                  archived
                  fetchData={activeBunkerEventTab === '2'}
                />
              </div>
            </TabPane>
          </TabContent>
          {!checkReceivingVesselCrew(auth) &&
            !checkBunkerVesselCrew(auth) &&
            !checkTerminalOperator(auth) &&
            !checkReceivingCaptainScheduler(auth) &&
            !checkCaptainScheduler(auth) && (
              <div>
                <Nav tabs className={`${styles['nav']} sticky-top bg-white bunker-events-nav`}>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeSpotEnquieryTab === '1' })}
                      onClick={() => setActiveSpotEnquieryTab('1')}
                    >
                      Requests for quotations
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeSpotEnquieryTab === '2' })}
                      onClick={() => {
                        setActiveSpotEnquieryTab('2');
                      }}
                    >
                      Archived
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeSpotEnquieryTab}>
                  <TabPane tabId="1">
                    <div className={styles['tab-pane-content']}>
                      <OverviewSpotList
                        deliveryModes={deliveryModes}
                        sortBy={sortBy}
                        userIsShipOwner={isShipOwner}
                        fetchData={activeSpotEnquieryTab === '1'}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className={styles['tab-pane-content']}>
                      <OverviewSpotList
                        deliveryModes={deliveryModes}
                        sortBy="time"
                        archived
                        userIsShipOwner={isShipOwner}
                        fetchData={activeSpotEnquieryTab === '2'}
                      />
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Overview;
