import { ErrorActionPayload } from '../../models/ErrorAction';
import { LocationWrapperModel, LocUploadWrapperModel } from './models';

export const LOCATION_LOADING = 'location/loading';
export type LOCATION_LOADING = typeof LOCATION_LOADING;

export const GET_LOCATION = 'location/get';
export type GET_LOCATION = typeof GET_LOCATION;

export const LOCATION_FETCH_ERROR = 'location/fetchError';
export type LOCATION_FETCH_ERROR = typeof LOCATION_FETCH_ERROR;

export const LOCUPLOAD_LOADING = 'location_upload/loading';
export type LOCUPLOAD_LOADING = typeof LOCUPLOAD_LOADING;

export const GET_LOCUPLOAD = 'location_upload/get';
export type GET_LOCUPLOAD = typeof GET_LOCUPLOAD;

export const LOCUPLOAD_FETCH_ERROR = 'location_upload/fetchError';
export type LOCUPLOAD_FETCH_ERROR = typeof LOCUPLOAD_FETCH_ERROR;

export const IMPORT_LOCATIONS = 'location_upload/import';
export type IMPORT_LOCATIONS = typeof IMPORT_LOCATIONS;

export const IMPORT_LOCATIONS_ERROR = 'location_upload/importError';
export type IMPORT_LOCATIONS_ERROR = typeof IMPORT_LOCATIONS_ERROR;

// export const IMPORT_LOCATIONS_CLEAR_TOAST = 'location_upload/clearToast';
// export type IMPORT_LOCATIONS_CLEAR_TOAST = typeof IMPORT_LOCATIONS_CLEAR_TOAST;

export type LocationActions =
  | {
      type: GET_LOCATION;
      payload: LocationWrapperModel['data'];
    }
  | {
      type: LOCATION_LOADING;
    }
  | {
      type: LOCATION_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_LOCUPLOAD;
      payload: LocUploadWrapperModel['data'];
    }
  | {
      type: LOCUPLOAD_LOADING;
    }
  | {
      type: LOCUPLOAD_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: IMPORT_LOCATIONS;
      payload: LocUploadWrapperModel['data'];
    }
  | {
      type: IMPORT_LOCATIONS_ERROR;
      payload: ErrorActionPayload;
    };
// | {
//     type: IMPORT_LOCATIONS_CLEAR_TOAST;
//   };
