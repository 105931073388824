import React from 'react';
import styles from './EmailSettings.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  addEmailSettings,
  deleteEmailSettings,
  editEmailSettings,
  getEmailSettings,
  emailSettingsClearToast,
} from '../../store/EmailSettings/actions';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import {
  EmailSettings as MyEmailSettings,
  EmailNotificationSetting,
  EmailNotificationRefIdType,
  EmailSettingUserType,
} from '../../store/EmailSettings/models';
import Logg from '../../helpers/consoleLogger';
import { getAuth } from '../../store/Auth/actions';
import { ReactComponent as EmailSettingsLogo } from '../../svgs/email-settings.svg';
import BackNavigation from '../../components/BackNavigation/BackNavigation';
import { useHistory } from 'react-router-dom';
import { CustomInput, Label, Input, Button } from 'reactstrap';
import { checkCharterer, checkShipOwner, checkSupplier } from '../../helpers/roleChecks';
import { CompanyDeliveryMode } from '../../models/CompanyDeliveryMode';
import { getFleetVessels } from '../../store/CompanyFleet/actions';
import { getContracts } from '../../store/Contract/actions';
import { getChorusCompanies } from '../../store/ChorusCompany/actions';
import { ReactComponent as TruckIcon } from '../../svgs/truck-to-ship.svg';
import { ReactComponent as PipeIcon } from '../../svgs/pipe-to-ship-blue.svg';
import { ReactComponent as ShipIcon } from '../../svgs/ship-to-ship.svg';
import { ReactComponent as ContainerIcon } from '../../svgs/container-to-ship.svg';
import { DeliveryModes } from '../../models/DeliveryMode';
import styled from 'styled-components';
import { FleetVessel } from '../../store/CompanyFleet/models';
import { ICompany } from '@teqplay/chorus-components';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { Prompt } from 'react-router';
import RouteLeavingGuard from '../../helpers/RouteLeavingGuard';
import _ from 'lodash';
import { Category } from '../../models/Category';

enum NotificationType {
  Bunker,
  Chat,
}

const StyledCustomSwitch = styled.div`
  .custom-switch .custom-control-label:after {
    background-color: #b4b4b4;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border: 1px solid #24365c;
  }
`;

export const EmailSettings: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  const getChorusCompaniesCallback = React.useCallback(() => dispatch(getChorusCompanies()), []);

  const getAllContractsCallback = React.useCallback(() => dispatch(getContracts()), []);

  const getEmailSettingsCallback = React.useCallback(() => dispatch(getEmailSettings()), []);

  const addEmailSettingsCallback = React.useCallback(
    (settingss: MyEmailSettings) => dispatch(addEmailSettings(settingss, () => {})),
    [],
  );

  const editEmailSettingsCallback = React.useCallback(
    (settingss: MyEmailSettings) => dispatch(editEmailSettings(settingss, () => {})),
    [],
  );

  const [vesselList, setVesselList] = React.useState<FleetVessel[]>();
  const [vesselListIsLoaded, setVesselListIsLoaded] = React.useState(false);
  const [contractCompaniesIds, setContractCompaniesIds] = React.useState<string[]>();
  const [contractCompaniesList, setContractCompaniesList] = React.useState<ICompany[]>();
  const [contractCompaniesIsLoaded, setContractCompaniesIsLoaded] = React.useState(false);
  const [deliveryModesList, setDeliveryModesList] = React.useState<CompanyDeliveryMode[]>();

  const [isSaveEnabled, setIsSaveEnabled] = React.useState(false);
  const [myEmailSettings, setMyEmailSettings] = React.useState<MyEmailSettings>();
  const [rerenderPlaceHolder, setRerenderPlaceHolder] = React.useState(false);

  const {
    auth,
    authIsLoaded,

    emailsettingsLoading,
    emailsettingsError,
    emailsettingsErrorDetails,
    emailsettings,
    emailsettingsIsLoaded,

    companyFleetLoading,
    companyFleetError,
    companyFleetErrorDetails,
    companyFleet,
    companyFleetIsLoaded,

    contractsLoading,
    contractsError,
    contractsErrorDetails,
    contracts,
    contractsIsLoaded,

    chorusCompanyLoading,
    chorusCompanyError,
    chorusCompanyErrorDetails,
    chorusCompany,
    chorusCompanyIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    emailsettingsLoading: state.emailsettings.loading,
    emailsettingsError: state.emailsettings.error,
    emailsettingsErrorDetails: state.emailsettings.errorDetails,
    emailsettings: state.emailsettings.data,
    emailsettingsIsLoaded: state.emailsettings.dataIsLoaded === true,

    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,

    contractsLoading: state.contracts.loading,
    contractsError: state.contracts.error,
    contractsErrorDetails: state.contracts.errorDetails,
    contracts: state.contracts.data,
    contractsIsLoaded: state.contracts.dataIsLoaded,

    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompanyErrorDetails: state.choruscompany.errorDetails,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded === true,
  }));

  React.useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }
    if (!chorusCompanyIsLoaded) {
      getChorusCompaniesCallback();
    }
    //if (!emailsettingsIsLoaded) {
    getEmailSettingsCallback();
    //}
    if (!contractsIsLoaded) {
      getAllContractsCallback();
    }
  }, []);

  const onButtonClick = () => {};

  const createNewEmailSettings = () => {   
    let es = emailsettings != undefined ? emailsettings : ({} as MyEmailSettings);
    
    let ems = [] as EmailNotificationSetting[];
    if (checkSupplier(auth)) {
      if (deliveryModesList) {
        let emsDeliveryMode = deliveryModesList.map((deliveryMode) => {
          const deliveryModeName = deliveryMode.deliveryMode.mode;
          const id = deliveryMode.deliveryMode.id;
          return {
            id: '00000000-0000-0000-0000-000000000000',
            name: deliveryModeName,
            bunkerNotification: true,
            chatNotification: true,
            refId: id,
            referenceIdType: EmailNotificationRefIdType.DeliveryMode,
          } as EmailNotificationSetting;
        });
        ems = ems.concat(emsDeliveryMode);
        //ems=[ ...ems, ...emsDeliveryMode];
      }

      let emsSpot = {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Spot',
        bunkerNotification: true,
        chatNotification: true,
        refId: '',
        referenceIdType: EmailNotificationRefIdType.Spot,
      } as EmailNotificationSetting;
      ems = ems.concat(emsSpot);
      if (contractCompaniesList) {
        let emsContractCompany = contractCompaniesList.map((contractCompany) => {
          const companyName = contractCompany.name;
          const id = contractCompany._id;
          return {
            id: '00000000-0000-0000-0000-000000000000',
            name: companyName,
            bunkerNotification: true,
            chatNotification: true,
            refId: id,
            referenceIdType: EmailNotificationRefIdType.Company,
          } as EmailNotificationSetting;
        });
        ems = ems.concat(emsContractCompany);
      }
    } else if (checkShipOwner(auth) || checkCharterer(auth)) {
      if (vesselList) {
        ems = vesselList.map((vessel) => {
          const vesselName = vessel.vessel.vesselName;
          const id = vessel.chorusId;
          return {
            id: '00000000-0000-0000-0000-000000000000',
            name: vesselName,
            bunkerNotification: true,
            chatNotification: true,
            refId: id,
            referenceIdType: EmailNotificationRefIdType.Vessel,
          } as EmailNotificationSetting;
        });
      }
    }
    if (auth.company && auth.company.companyId) {
      es.companyId = auth.company.companyId;
      const category = auth.company.category.categoryName;
      if (category == Category.Supplier) {
        es.userType = EmailSettingUserType.Supplier;
      } else {
        es.userType = EmailSettingUserType.Shipowner;
      }
    }
    es.veracityId = auth.veracityId;
    es.disableAll = false;
    es.disableAllNominations = false;
    es.emailNotificationSettings = ems;
    return es;
  };

  const LoadEmailSettingsSupplier = (loadedSettings: MyEmailSettings) => {
    let mySettings = loadedSettings;
    let loadedEmailNotificationSettings: EmailNotificationSetting[] = [];
    //load delivery mode settings
    if (deliveryModesList) {
      deliveryModesList.map((deliveryMode) => {
        const name = deliveryMode.deliveryMode.mode;
        const refId = deliveryMode.deliveryMode.id;
        let objIndex = loadedSettings.emailNotificationSettings
          ? loadedSettings.emailNotificationSettings.findIndex((obj) => obj.refId == refId)
          : -1;
        if (objIndex == -1) {
          //any new deliverymode added which is not in the old settings
          const newSettingObj = {
            id: '00000000-0000-0000-0000-000000000000',
            name: name,
            bunkerNotification: loadedSettings.disableAll ? false : true,
            chatNotification: loadedSettings.disableAll ? false : true,
            refId: refId,
            referenceIdType: EmailNotificationRefIdType.DeliveryMode,
            emailSettingId: loadedSettings.id,
          };
          loadedEmailNotificationSettings.push(newSettingObj);
        } else {
          const settingObj = loadedSettings.emailNotificationSettings[objIndex];
          loadedEmailNotificationSettings.push(settingObj);
        }
      });
      //mySettings.emailNotificationSettings = loadedEmailNotificationSettings;
    }

    //load spot settings
    let spotObjIndex = loadedSettings.emailNotificationSettings
      ? loadedSettings.emailNotificationSettings.findIndex(
          (obj) => obj.referenceIdType == EmailNotificationRefIdType.Spot,
        )
      : -1;
    if (spotObjIndex == -1) {
      //any new contract company added which is not in the old settings
      const newSettingObj = {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Spot',
        bunkerNotification: loadedSettings.disableAll ? false : true,
        chatNotification: loadedSettings.disableAll ? false : true,
        refId: '',
        referenceIdType: EmailNotificationRefIdType.Spot,
        emailSettingId: loadedSettings.id,
      };
      loadedEmailNotificationSettings.push(newSettingObj);
    } else {
      const settingObj = loadedSettings.emailNotificationSettings[spotObjIndex];
      loadedEmailNotificationSettings.push(settingObj);
    }

    //load company settings
    if (contractCompaniesList) {
      contractCompaniesList.map((contractCompany) => {
        const name = contractCompany.name;
        const refId = contractCompany._id;
        let objIndex = loadedSettings.emailNotificationSettings
          ? loadedSettings.emailNotificationSettings.findIndex((obj) => obj.refId == refId)
          : -1;
        if (objIndex == -1) {
          //any new contract company added which is not in the old settings
          const newSettingObj = {
            id: '00000000-0000-0000-0000-000000000000',
            name: name,
            bunkerNotification: loadedSettings.disableAll || loadedSettings.disableAllNominations ? false : true,
            chatNotification: loadedSettings.disableAll || loadedSettings.disableAllNominations ? false : true,
            refId: refId,
            referenceIdType: EmailNotificationRefIdType.Company,
            emailSettingId: loadedSettings.id,
          };
          loadedEmailNotificationSettings.push(newSettingObj);
        } else {
          const settingObj = loadedSettings.emailNotificationSettings[objIndex];
          loadedEmailNotificationSettings.push(settingObj);
        }
      });
      //mySettings.emailNotificationSettings = loadedEmailNotificationSettings;
    }

    mySettings.emailNotificationSettings = loadedEmailNotificationSettings;
    return mySettings;
  };

  const LoadEmailSettingsShipowner = (loadedSettings: MyEmailSettings) => {
    let mySettings = loadedSettings;
    let loadedEmailNotificationSettings: EmailNotificationSetting[] = [];
    if (vesselList) {
      vesselList.map((vessel) => {
        const vesselName = vessel.vessel.vesselName;
        const refId = vessel.chorusId;
        let objIndex = loadedSettings.emailNotificationSettings
          ? loadedSettings.emailNotificationSettings.findIndex((obj) => obj.refId == refId)
          : -1;
        if (objIndex == -1) {
          //any new vessel added which is not in the old settings
          const newSettingObj = {
            id: '00000000-0000-0000-0000-000000000000',
            name: vesselName,
            bunkerNotification: loadedSettings.disableAll ? false : true,
            chatNotification: loadedSettings.disableAll ? false : true,
            refId: refId,
            referenceIdType: EmailNotificationRefIdType.Vessel,
            emailSettingId: loadedSettings.id,
          };
          loadedEmailNotificationSettings.push(newSettingObj);
        } else {
          const settingObj = loadedSettings.emailNotificationSettings[objIndex];
          loadedEmailNotificationSettings.push(settingObj);
        }
      });
      mySettings.emailNotificationSettings = loadedEmailNotificationSettings;
    }
    return mySettings;
  };

  React.useEffect(() => {
    //create or load email setting for supplier after every dependency has been loaded
    if (checkSupplier(auth)) {
      if (
        contractsIsLoaded &&
        chorusCompanyIsLoaded &&
        authIsLoaded &&
        emailsettingsIsLoaded &&
        contractCompaniesIsLoaded
      ) {
        //wait while everthing is loaded
        if (!emailSettingsExist()) {
          setIsSaveEnabled(true);
          const newEmailSettings = createNewEmailSettings();
          setMyEmailSettings(newEmailSettings);
        } else {
          const loadedSettings = LoadEmailSettingsSupplier(emailsettings);
          setMyEmailSettings(loadedSettings);
          setIsSaveEnabled(false);
        }
      }
    }
  }, [contracts, chorusCompany, emailsettings, contractCompaniesIsLoaded]);

  React.useEffect(() => {
    //create or load email setting for shipowner after every dependency has been loaded
    if (checkShipOwner(auth) || checkCharterer(auth)) {
      if (companyFleetIsLoaded && emailsettingsIsLoaded && vesselListIsLoaded) {
        //wait while everthing is loaded
        if (!emailSettingsExist()) {
          setIsSaveEnabled(true);
          const newEmailSettings = createNewEmailSettings();
          setMyEmailSettings(newEmailSettings);
        } else {
          const loadedSettings = LoadEmailSettingsShipowner(emailsettings);
          setMyEmailSettings(loadedSettings);
          setIsSaveEnabled(false);
        }
      }
    }
  }, [companyFleet, emailsettings, vesselListIsLoaded]);

  React.useEffect(() => {
    if (companyFleetIsLoaded) {
      const vessels = companyFleet;
      setVesselList(vessels);
      setVesselListIsLoaded(true);
    }
  }, [companyFleet]);

  React.useEffect(() => {
    if (auth.company && auth.company.companyDeliveryModes) {
      setDeliveryModesList(auth.company?.companyDeliveryModes);
    }
  }, [auth]);

  React.useEffect(() => {
    if (contractsIsLoaded && chorusCompanyIsLoaded) {
      const myContracts = contracts.filter((x) => x.supplierId == auth.company?.chorusId);
      const myContractCompanyIds = myContracts.map((x) => x.customerId);
      var uniqueIds = myContractCompanyIds.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      setContractCompaniesIds(uniqueIds);
      const myContractCompanies = chorusCompany.filter((y) => uniqueIds.includes(y._id));
      setContractCompaniesList(myContractCompanies);
      setContractCompaniesIsLoaded(true);
    }
  }, [contracts, chorusCompany]);

  const getCompanyDeliveryModes = () => {
    const ret: string[] = [];

    if (auth.company && auth.company.companyDeliveryModes) {
      auth.company.companyDeliveryModes.forEach((element: CompanyDeliveryMode) => {
        ret.push(element.deliveryMode.mode);
      });
    }
    return ret;
  };

  const getDeliveryModeIcon = (deliveryMode: string) => {
    if (deliveryMode == DeliveryModes.Truck) return <TruckIcon className={styles[deliveryMode]} />;
    else if (deliveryMode == DeliveryModes.Ship) return <ShipIcon className={styles[deliveryMode]} />;
    else if (deliveryMode == DeliveryModes.Container) return <ContainerIcon className={styles[deliveryMode]} />;
    else if (deliveryMode == DeliveryModes.Pipe) return <PipeIcon className={styles[deliveryMode]} />;
    else return <div></div>;
  };

  const emailSettingsExist = () => {
    if (
      _.isEmpty(emailsettings) ||
      (emailsettings.id == '00000000-0000-0000-0000-000000000000' && emailsettings.chorusId == null)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const goBack = () => {
    history.push(`/overview`);
  };

  const DisplaySettingOption = (
    id: string,
    name: string,
    refrenceType: EmailNotificationRefIdType,
    bunkerNotification: boolean,
    chatNotification: boolean,
  ) => {
    return (
      <>
        <div className={styles['options-div']}>
          <div className={styles['option-name']}>{name}</div>
          <StyledCustomSwitch>
            <CustomInput
              type="switch"
              id={id}
              name={name}
              label=""
              onChange={(e) => onChangeSwitch(e, refrenceType)}
              checked={bunkerNotification}
            />
          </StyledCustomSwitch>
        </div>
        <div className={styles['checkbox-div']}>
          <Label check>
            <Input
              type="checkbox"
              id={id}
              name={name}
              onChange={(e) => onChangeCheckBox(e, refrenceType)}
              checked={chatNotification}
            />{' '}
            <div className={styles['checkbox-label']}>Receive notifications for new chat messages</div>
          </Label>
        </div>
        <hr className={styles['options-line-break']} />
      </>
    );
  };

  const DisplayDeliverModeSettingOption = (
    id: string,
    name: string,
    refrenceType: EmailNotificationRefIdType,
    bunkerNotification: boolean,
    chatNotification: boolean,
  ) => {
    const displayName = name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase() + ' to ship';
    return (
      <>
        <div className={styles['deliverymode-option']}>
          <div className={`${styles['delivery-mode-icon']}`}>{getDeliveryModeIcon(name)}</div>
          <div className={styles['deliverymode-setting']}>
            <div className={styles['options-div']}>
              <div className={styles['option-name']}>{displayName}</div>
              <StyledCustomSwitch>
                <CustomInput
                  type="switch"
                  id={id}
                  name={name}
                  label=""
                  onChange={(e) => onChangeSwitch(e, refrenceType)}
                  checked={bunkerNotification}
                />
              </StyledCustomSwitch>
            </div>
            <div className={styles['checkbox-div']}>
              <Label check>
                <Input
                  type="checkbox"
                  id={id}
                  name={name}
                  onChange={(e) => onChangeCheckBox(e, refrenceType)}
                  checked={chatNotification}
                />{' '}
                <div className={styles['checkbox-label']}>Receive notifications for new chat messages</div>
              </Label>
            </div>
          </div>
        </div>
        <hr className={styles['options-line-break']} />
      </>
    );
  };

  const DisplayShipownerSettings = () => {
    if (myEmailSettings?.emailNotificationSettings) {
      const list = myEmailSettings?.emailNotificationSettings.filter(
        (x) => x.referenceIdType == EmailNotificationRefIdType.Vessel,
      );
      return (
        <>
          {list &&
            list.map((item) => {
              return DisplaySettingOption(
                item.refId,
                item.name,
                EmailNotificationRefIdType.Vessel,
                item.bunkerNotification,
                item.chatNotification,
              );
            })}
        </>
      );
    }
  };

  const DisplayContractCompanySettings = () => {
    if (myEmailSettings?.emailNotificationSettings) {
      const list = myEmailSettings?.emailNotificationSettings.filter(
        (x) => x.referenceIdType == EmailNotificationRefIdType.Company,
      );
      return (
        <>
          {list &&
            list.map((item) => {
              return DisplaySettingOption(
                item.refId,
                item.name,
                EmailNotificationRefIdType.Company,
                item.bunkerNotification,
                item.chatNotification,
              );
            })}
        </>
      );
    }
  };

  const DisplayDeliveryModesSettings = () => {
    if (myEmailSettings?.emailNotificationSettings) {
      const list = myEmailSettings?.emailNotificationSettings.filter(
        (x) => x.referenceIdType == EmailNotificationRefIdType.DeliveryMode,
      );
      return (
        <>
          {list &&
            list.map((item) => {
              return DisplayDeliverModeSettingOption(
                item.refId,
                item.name,
                EmailNotificationRefIdType.DeliveryMode,
                item.bunkerNotification,
                item.chatNotification,
              );
            })}
        </>
      );
    }
  };

  const onFormSave = () => {
    let emailSettingsToSave = emailsettings;
    
    emailSettingsToSave.emailNotificationSettings = emailsettings.emailNotificationSettings.filter(
      (x) => x.bunkerNotification != true || x.chatNotification != true,
    );
    if (emailsettings.disableAll) {
      // disableAll then send an empty emailNotificationSettings
      emailSettingsToSave.emailNotificationSettings = [];
    }   
    if (!emailSettingsExist()) {
      //create new email setting
      addEmailSettingsCallback(emailSettingsToSave);
    } else {
      //update existing email setting
      editEmailSettingsCallback(emailSettingsToSave);
    }
  };

  const onCancelButtonClick = () => {
    history.push(`/overview`);
  };

  const onChangeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    refrenceType: EmailNotificationRefIdType | undefined,
  ) => {
    const id = event.target.id;
    const name = event.target.name;
    const checked = event.target.checked;
    updateEmailNotificationSettings(id, name, checked, refrenceType, NotificationType.Chat);
  };

  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    refrenceType: EmailNotificationRefIdType | undefined,
  ) => {
    const id = event.target.id;
    const name = event.target.name;
    const checked = event.target.checked;
    updateEmailNotificationSettings(id, name, checked, refrenceType, NotificationType.Bunker);
  };

  const updateEmailNotificationSettings = (
    id: string,
    name: string,
    checked: boolean,
    refrenceType: EmailNotificationRefIdType | undefined,
    type: NotificationType,
  ) => {
    setIsSaveEnabled(true);
    let settings = myEmailSettings;
    let notificationSettings = myEmailSettings
      ? myEmailSettings.emailNotificationSettings
      : ({} as EmailNotificationSetting[]);
    //data.set(id, checked);
    if (refrenceType != undefined) {
      if (settings && notificationSettings) {
        let objIndex = notificationSettings.findIndex((obj) => obj.refId == id && obj.referenceIdType == refrenceType);
        if (refrenceType == EmailNotificationRefIdType.Spot) {
          // as for spot setting refId is not available
          objIndex = notificationSettings.findIndex((obj) => obj.referenceIdType == refrenceType);
        }
        if (objIndex == -1) {
          //add to the list
          const newNotificationSetting: EmailNotificationSetting = {
            id: '',
            name: name,
            bunkerNotification: type == NotificationType.Bunker ? checked : true,
            chatNotification: type == NotificationType.Chat ? checked : true,
            refId: id,
            referenceIdType: refrenceType,
            emailSettingId: '',
          };
          notificationSettings.push(newNotificationSetting);
        } else {
          if (type == NotificationType.Bunker) {
            notificationSettings[objIndex].bunkerNotification = checked;
          } else if (type == NotificationType.Chat) {
            notificationSettings[objIndex].chatNotification = checked;
          }
        }
        //reset disableAll and disableAllNominations if any switch or checkbox is enabled
        if (checked && settings.disableAll) {
          settings.disableAll = false;
        }
        if (checked && settings.disableAllNominations && (refrenceType == EmailNotificationRefIdType.Company || refrenceType == EmailNotificationRefIdType.Vessel)) {
          settings.disableAllNominations = false;
        }
        settings.emailNotificationSettings = notificationSettings;
      }
    } else {
      //selectAll switch and selectAllNominations Switch
      if (settings && notificationSettings) {
        if (id == 'selectAllSwitch') {
          notificationSettings.forEach(function (item, index) {
            if (checked) {
              item.bunkerNotification = true;
              item.chatNotification = true;
            } else {
              item.bunkerNotification = false;
              item.chatNotification = false;
            }
            // @ts-ignore
            settings.disableAll = !checked;
            // @ts-ignore
            settings.disableAllNominations = !checked;
          });
        }
        if (id == 'selectAllNominationsSwitch') {
          const companySettings = settings.emailNotificationSettings.filter(
            (x) => x.referenceIdType == EmailNotificationRefIdType.Company,
          );
          companySettings.forEach(function (item, index) {
            if (checked) {
              item.bunkerNotification = true;
              item.chatNotification = true;
            } else {
              item.bunkerNotification = false;
              item.chatNotification = false;
            }
            // @ts-ignore
            settings.disableAllNominations = !checked;
          });
        }
        settings.emailNotificationSettings = notificationSettings;
      }
    }

    setMyEmailSettings(settings);
    //force rerender the checkboxes and switches
    setRerenderPlaceHolder(!rerenderPlaceHolder);
  };

  const isSpotSwitchChecked = (type: NotificationType) => {
    if (myEmailSettings && myEmailSettings.emailNotificationSettings) {
      const spotSetting = myEmailSettings.emailNotificationSettings.filter(
        (x) => x.referenceIdType == EmailNotificationRefIdType.Spot,
      );
      if (spotSetting && spotSetting.length > 0) {
        return type == NotificationType.Bunker ? spotSetting[0].bunkerNotification : spotSetting[0].chatNotification;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const isSelectAllSwitchChecked = () => {
    const isChecked = myEmailSettings ? !myEmailSettings.disableAll : true;

    if (myEmailSettings?.emailNotificationSettings) {
      //if any option is disabled toggle off select all switch
      const disabledOptions = myEmailSettings?.emailNotificationSettings.filter(
        (x) => x.chatNotification == false || x.bunkerNotification == false,
      );
      if (disabledOptions && disabledOptions?.length > 0) {
        return false;
      }
    }
    return isChecked;
  };

  const loadingString = `${emailsettingsLoading ? 'email settings, ' : ''} ${chorusCompanyLoading ? 'companies, ' : ''} 
   ${companyFleetLoading ? 'company fleet, ' : ''}`;

  if (emailsettingsLoading || chorusCompanyLoading || companyFleetLoading || contractsLoading) {
    return <Loading type={loadingString} />;
  } else if (emailsettingsError && emailsettingsErrorDetails) {
    return <Error error={emailsettingsErrorDetails} />;
  } else if (chorusCompanyError && chorusCompanyErrorDetails) {
    return <Error error={chorusCompanyErrorDetails} />;
  } else if (companyFleetError && companyFleetErrorDetails) {
    return <Error error={companyFleetErrorDetails} />;
  } else if (contractsError && contractsErrorDetails) {
    return <Error error={contractsErrorDetails} />;
  } else {
    return (
      <React.Fragment>
        <RouteLeavingGuard
          when={isSaveEnabled}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={(location) => {
            //block on every location
            return true;
          }}
        />
        <div className={styles.emailSettingsWrapper}>
          <div className={styles['header']}>
            <div className={styles['email-notifications']}>
              {goBack && <BackNavigation onClick={goBack} />}
              <EmailSettingsLogo className={styles['email-logo']} />
              <div className={styles['email-logo-text']}>Email notification settings</div>
              <div className={styles['form-buttons-container']}>
                <Button type="submit" color="primary" disabled={!isSaveEnabled} onClick={onFormSave}>
                  Save
                </Button>
                <Button color="secondary" onClick={onCancelButtonClick}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          <div className={styles['content-wrapper']}>
            <div className={styles['settings-wrapper']}>
              <div className={styles['settings-header']} style={{ textAlign: 'center' }}>
                I would like to recieve email notifications for...
              </div>
              <div className={styles['select-all-div']}>
                <div className={styles['select-all-label']}>Select all</div>
                <StyledCustomSwitch>
                  <CustomInput
                    type="switch"
                    id="selectAllSwitch"
                    name="customSwitch"
                    label=""
                    onChange={(e) => onChangeSwitch(e, undefined)}
                    className={styles['custom-switch']}
                    checked={isSelectAllSwitchChecked()}
                  />
                </StyledCustomSwitch>
              </div>
              {checkSupplier(auth) && (
                <div>
                  <div className={styles['settings-header']}>Delivery modes</div>
                  <hr className={styles['line-break']} />
                  {DisplayDeliveryModesSettings()}
                  <div className={styles['options-div']}>
                    <div className={styles['settings-header']}>Spot quotations</div>
                    <StyledCustomSwitch>
                      <CustomInput
                        type="switch"
                        id="selectSpotSwitch"
                        name="customSwitch"
                        label=""
                        onChange={(e) => onChangeSwitch(e, EmailNotificationRefIdType.Spot)}
                        checked={isSpotSwitchChecked(NotificationType.Bunker)}
                      />
                    </StyledCustomSwitch>
                  </div>
                  <div className={styles['checkbox-div']}>
                    <Label check>
                      <Input
                        type="checkbox"
                        id="selectSpotCheckbox"
                        onChange={(e) => onChangeCheckBox(e, EmailNotificationRefIdType.Spot)}
                        checked={isSpotSwitchChecked(NotificationType.Chat)}
                      />{' '}
                      <div className={styles['checkbox-label']}>Receive notifications for new chat messages</div>
                    </Label>
                  </div>
                  <hr className={styles['line-break']} />
                  <div className={styles['options-div']}>
                    <div className={styles['settings-header']}>Nominations</div>
                    <StyledCustomSwitch>
                      <CustomInput
                        type="switch"
                        id="selectAllNominationsSwitch"
                        name="customSwitch"
                        label=""
                        onChange={(e) => onChangeSwitch(e, undefined)}
                        checked={myEmailSettings ? !myEmailSettings.disableAllNominations : true}
                      />
                    </StyledCustomSwitch>
                  </div>
                  <hr className={styles['line-break']} />
                  {DisplayContractCompanySettings()}
                </div>
              )}
              {(checkShipOwner(auth) || checkCharterer(auth)) && (
                <div>
                  <div className={styles['settings-header']}>Bunker events & spot quotations</div>
                  <hr className={styles['line-break']} />
                  {DisplayShipownerSettings()}
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};
