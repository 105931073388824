import React from 'react';
import { Report } from '../../../../../store/Reports/models';
import { ReportType } from '../../../../../store/ReportTypes/models';

import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import * as Yup from 'yup';
import reportConfigs from '../ReportForm/reportConfigs';
import { Formik } from 'formik';
import ReportTypeForm from '../ReportForm/ReportTypeForm';
import { Loading } from '../../../../Loading';

interface OwnProps {
  editReportType: (report: ReportType) => void;
  reportTypeId: string;
  company?: Company;
  reportTypes: ReportType[];
  navAdminType: string;
  companyAdmin?: boolean;
  isLoading?: boolean;
}

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

const EditReportType: React.FunctionComponent<OwnProps> = ({
  editReportType,
  companyAdmin,
  navAdminType,
  company,
  reportTypeId,
  reportTypes,
  isLoading,
}) => {
  const submitReportType = (report: ReportType) => {
    editReportType(report);
  };

  const initialValues: ReportType = {
    name: '',
    subset: 1,
    showAddVessel: false,
  };
  const selectedreportType: ReportType | undefined = reportTypes.find((it) => it.id === reportTypeId);

  const validationShape = {
    name: Yup.string().matches(reportConfigs.name.regex, validationMessage).required('Type required'),
    showAddVessel: Yup.boolean().optional(),
    subset: Yup.number().min(1, 'Not valid').optional(),
  };

  const reportFormValidation = Yup.object().shape(validationShape);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={selectedreportType ? selectedreportType : initialValues}
      validationSchema={reportFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <ReportTypeForm
          reportType={selectedreportType}
          header={'Edit report type'}
          saveMethod={submitReportType}
          systemAdminPage={true}
          companyAdminForm={companyAdmin}
          company={company}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(EditReportType);
