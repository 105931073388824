/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/display-name */
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, useAsyncDebounce } from 'react-table';
import { Button, Input } from 'reactstrap';
import { formatTime, titleCase } from '../../scenes/Map/Utils/Utils';
import { ApplicationState } from '../../store';
import {
  getBIcompanyFleet,
  addBIfleetItem,
  removeBIfleetItemById,
  removeBIfleetItem,
  biVesselClearToast,
} from '../../store/BIcompanyFleet/actions';
import { BIFleetItem, LLoydsVessel } from '../../store/BIcompanyFleet/models';
import { getBIvessels } from '../../store/BIvessels/actions';
import DeleteById from '../DeleteById/DeleteById';
import FuelBossTableContainer from './../FuelBossTable';
import styles from './BIaddVessels.module.scss';
import classnames from 'classnames';
import { AddToast } from '../../helpers/toastService';

interface OwnProps {
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const addIcon = findIconDefinition({ prefix: 'fal', iconName: 'plus' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const checkedIcon = findIconDefinition({ prefix: 'fal', iconName: 'check' });
const arrowLeft = findIconDefinition({
  prefix: 'fal',
  iconName: 'arrow-left',
});

const BIaddVessels: React.FC<OwnProps> = ({ defaultSelectedPage, setDefaultSelectedPage }) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [searchActive, setSearchActive] = React.useState(false);
  const [searchFieldFocused, setSearchFieldFocused] = useState(false);

  const onChange = useAsyncDebounce((searchText) => {
    if (typeof searchText !== 'undefined' && searchText.length > 2) {
      setSearchValue(searchText || undefined);
      getBIvesselsCallback(searchText);
    }
  }, 150);
  const { bivessels, bicompanyfleet, bicompanyfleetIsLoaded, toastData } = useSelector((state: ApplicationState) => ({
    bivessels: state.bivessels.data,
    bicompanyfleet: state.bicompanyfleet.data,
    bicompanyfleetIsLoaded: state.bicompanyfleet.dataIsLoaded,
    toastData: state.bicompanyfleet.toastData,
  }));

  const dispatch = useDispatch();
  const getBIvesselsCallback = useCallback((searchText: string) => dispatch(getBIvessels(searchText)), []);
  const getBIcompanyFleetCallback = useCallback(() => dispatch(getBIcompanyFleet()), []);
  const addBIitemCallback = useCallback((vessel: LLoydsVessel) => dispatch(addBIfleetItem(vessel)), []);
  const removeBIitemByIdCallback = useCallback((id: string) => dispatch(removeBIfleetItemById(id)), []);
  const removeBIitemCallback = useCallback((vessel: LLoydsVessel) => dispatch(removeBIfleetItem(vessel)), []);
  const clearToastCallback = useCallback(() => dispatch(biVesselClearToast()), []);

  useEffect(() => {
    if (!bicompanyfleetIsLoaded) {
      getBIcompanyFleetCallback();
    }
  }, []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  const getSearchResult = () => {
    const BIVesselSearchResult: LLoydsVessel[] = bivessels;
    BIVesselSearchResult.forEach((vessel) => {
      const matchfind = bicompanyfleet.find((fleetItem: BIFleetItem) => fleetItem.biVesselModel.imo == vessel.bK_IMONO);
      vessel.searchMatch = matchfind ? true : false;
    });
    return BIVesselSearchResult;
  };

  const removeVesselParse = (vessel: string) => {
    removeBIitemCallback(JSON.parse(vessel));
  };

  const removeVessel = (id: string) => {
    removeBIitemByIdCallback(id);
  };

  const toggleSearch = () => {
    setSearchActive(!searchActive);
    setSearchValue('');
  };

  const toggleFocus = (val: string) => {
    setSearchFieldFocused(!searchFieldFocused);
  };

  const searchHeaders: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: 'IMO',
        accessor: 'bK_IMONO',
      },
      {
        Header: 'Name',
        accessor: (row: LLoydsVessel) => {
          if (row.vesselname != undefined) {
            return titleCase(row.vesselname);
          }
        },
      },
      {
        Header: 'Owner',
        accessor: 'registeredownerfullname',
      },
      {
        Header: 'Ship type',
        accessor: 'l3_LLOYDSTYPENAME',
      },
      {
        Header: 'Date of build',
        accessor: (row: LLoydsVessel) => new Date(row.dateofbuild!).getTime(),
        Cell: (props: any) => {
          const vessel: LLoydsVessel = props.cell.row.original;
          return formatTime(vessel.dateofbuild);
        },
      },
      {
        Header: 'Gross tonnage',
        accessor: 'grosston',
      },
      {
        Header: 'Added',
        accessor: (row: LLoydsVessel) => row.searchMatch.toString(),
        Cell: (props: any) => {
          const vessel: LLoydsVessel = props.cell.row.original;
          return (
            <div>
              {vessel.searchMatch ? (
                <AddRemoveBIvessel vessel={vessel} removeVesselMethod={removeVesselParse} />
              ) : (
                <div className={styles['add-vessel']}>
                  <Button onClick={() => addBIitemCallback(vessel)}>
                    <FontAwesomeIcon icon={addIcon} size={'lg'} color="rgb(36, 54, 92)" />
                  </Button>
                  <div className={styles['right-margin']}> Add vessel</div>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  const vesselHeaders: Array<Column<any>> = React.useMemo(
    () => [
      {
        Header: 'Analysis ready',
        accessor: (row: BIFleetItem) => new Date(row.createdAt!).getTime(),
        Cell: (props: any) => {
          const fleetItem: BIFleetItem = props.cell.row.original;
          const oneDay = 60 * 60 * 1000 * 24;
          const oneDayAgo = Date.now() - oneDay;
          const date = new Date(fleetItem.createdAt!).getTime();
          if (oneDayAgo > date) return <div className={styles['analysis-green']}>●</div>;
          else return <div className={styles['analysis-red']}>●</div>;
        },
      },
      {
        Header: 'IMO',
        accessor: 'biVesselModel.imo',
      },
      {
        Header: 'Name',
        accessor: (row: BIFleetItem) => {
          if (row.biVesselModel.vessel_name != undefined) {
            return titleCase(row.biVesselModel.vessel_name);
          }
        },
      },
      {
        Header: 'Owner',
        accessor: 'biVesselModel.owner',
      },
      {
        Header: 'Ship type',
        accessor: 'biVesselModel.vesselTypeL3',
      },
      {
        Header: 'Date of build',
        accessor: (row: BIFleetItem) => {
          const value = new Date(row.biVesselModel.dateOfBuild!).getTime();
          // console.log(row.biVesselModel.dateofbuild, value);
          return value;
        },
        Cell: (props: any) => {
          const fleetItem: BIFleetItem = props.cell.row.original;
          return formatTime(fleetItem.biVesselModel.dateOfBuild);
        },
      },
      {
        Header: 'Gross tonnage',
        accessor: 'biVesselModel.grossTon',
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const fleetItem: BIFleetItem = props.cell.row.original;
          return (
            <div>
              <DeleteById
                id={fleetItem.id}
                deleteMethod={removeVessel}
                header="Remove vessel"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <h3>Analyse non-LNG-fuelled vessels</h3>
      <b> All LNG-fuelled vessels are already available for analysis.</b> <br /> <br />
      To analyse non-LNG-fuelled vessels please add them below. <br />
      Once added, the vessel data will be processed and made available for analysis in the reports within 24 hours.
      <br />
      <br />
      {searchActive ? (
        <div
          style={
            searchFieldFocused ? { backgroundColor: '#e5f6fb', boxShadow: '0px 5px 15px 0px rgba(204,204,204,1)' } : {}
          }
          className={classnames(styles['form-parent'], searchActive ? styles['form-parent-active'] : null)}
        >
          <div className={styles['search-parent']}>
            <Input
              value={searchValue || ''}
              className={styles['search-input']}
              onChange={(e) => {
                setSearchValue(e.target.value);
                onChange(e.target.value);
              }}
              onFocus={(e) => toggleFocus(e.target.value)}
              onBlur={(e) => toggleFocus(e.target.value)}
              placeholder={`Search any field ...`}
            />

            <Button color="secondary" outline className={styles['button-cancel']} onClick={toggleSearch}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Button color="primary" size="lg" onClick={toggleSearch} className={styles['addButton']}>
          <FontAwesomeIcon icon={addIcon} style={{ marginRight: '10px' }} />
          Add vessel
        </Button>
      )}
      <br />
      <br />
      {bivessels.length > 0 && searchActive ? (
        <>
          <Button className={styles['btn-open']} onClick={toggleSearch}>
            <FontAwesomeIcon icon={arrowLeft} />
          </Button>
          <div className={styles['vessels-found']}>
            {bivessels.length}
            {bivessels.length > 99 ? '+ vessels found' : ' vessels found'}
          </div>
        </>
      ) : (
        ''
      )}
      {bivessels.length > 0 && searchActive && (
        <FuelBossTableContainer
          className={styles['search-table']}
          showFilterSearch={false}
          headers={searchHeaders}
          tableRows={getSearchResult()}
          showPagination={true}
          defaultSelectedPage={defaultSelectedPage}
          setDefaultSelectedPage={setDefaultSelectedPage}
        />
      )}
      {bicompanyfleet.length > 0 && !searchActive && (
        <FuelBossTableContainer
          className={styles['company-vessel-table']}
          showFilterSearch={false}
          headers={vesselHeaders}
          tableRows={bicompanyfleet}
          showPagination={true}
          defaultSelectedPage={defaultSelectedPage}
          setDefaultSelectedPage={setDefaultSelectedPage}
        />
      )}
    </div>
  );
};

export default BIaddVessels;

interface AddRemoveBIvessel {
  removeVesselMethod: (vessel: string) => void;
  vessel: LLoydsVessel;
}

const AddRemoveBIvessel: React.FC<AddRemoveBIvessel> = (props) => {
  const { removeVesselMethod, vessel } = props;
  const [hovered, setHovered] = useState(false);

  return (
    <div className={styles['add-remove-vessel']}>
      <div className={hovered ? styles['added-vessel'] : styles['add-vessel']}>
        <div
          role="button"
          onKeyDown={() => null}
          onMouseOut={() => {
            setHovered(false);
          }}
          onMouseOver={() => {
            setHovered(true);
          }}
        >
          <DeleteById
            className={styles['delete-style']}
            id={JSON.stringify(vessel)}
            deleteMethod={removeVesselMethod}
            header="Remove vessel"
            delBtnIcon={checkedIcon}
          />
        </div>
      </div>
      {hovered ? (
        <div className={styles['hover-remove']}>Remove</div>
      ) : (
        <div className={styles['right-margin']}>Added</div>
      )}
    </div>
  );
};
