export default {
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
  colors: {
    primaryWhite: '#fff',
    primaryBlack: '#000',
    cyan: '#5eaeb8',
    darkBlue: '#0f204b',
    landGreen: '#36842d',
    landGreenDarkest: '#276121',
    seaBlue: '#003591',
    digitalBlue: '#0067c5',
    redLightest: '#ffc5c7',
    redLight: '#cc4148',
    red: '#c4262e',
    redDark: '#ad2128',
    redDarkest: '#941c22',
    skyBlueLight: '#ccecf8',
    skyBlue: '#99d9f0',
    cyanLightest: '#66c5e9',
    cyanLight: '#33b2e1',
    cyanDark: '#003853',
    neutral05: '#f2f2f2',
    neutral10: '#e5e5e5',
    neutral20: '#ccc',
    neutral40: '#999',
    neutral60: '#666',
    neutral80: '#333',
    blue57: '#1e3957',
    golda1: '#eca104',
  },
  button: {
    height: '37px',
    minWidth: '100px',
    padding: '6px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    border: 'none',
    borderRadius: '0',
    textDecoration: 'none',
    textAlign: 'center',
    transition: '0.2s ease-in-out all',
  },
};
