import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import React from 'react';
import Users from './Components/Users/Users';
import AddUser from './Components/AddUser/AddUser';
import { Company } from '../../../store/Company/models';
import { ApplicationState } from '../../../store';
import { getUsers, addUser, deleteUser, editUser } from '../../../store/Users/actions';
import { User } from '../../../store/Users/models';
import { Role } from '../../../store/Roles/models';
import { AddToast } from '../../../helpers/toastService';
import { usersClearToast } from '../../../store/Users/actions';
import { Switch, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import EditUser from './Components/EditForm/EditUser';

interface OwnProps {
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
}

const UsersContainer: React.FC<OwnProps> = ({ companyAdmin, navAdminType, company }) => {
  const { usersLoading, usersDetailsError, usersErrorDetails, users, usersIsLoaded, toastData, auth } = useSelector(
    (state: ApplicationState) => ({
      auth: state.auth.data,
      usersLoading: state.users.loading,
      usersDetailsError: state.users.error,
      usersErrorDetails: state.users.errorDetails,
      users: state.users.data,
      usersIsLoaded: state.users.dataIsLoaded,
      toastData: state.users.toastData,
    }),
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const getUsersCallback = useCallback(() => dispatch(getUsers()), []);

  //Add user methods
  const addUserCallback = useCallback(
    (user: User, roles: Role[]) => dispatch(addUser(user, roles, () => history.push(`/${navAdminType}admin/users`))),
    [],
  );

  const deleteUserCallback = useCallback((id: string) => dispatch(deleteUser(id)), []);

  const editUserCallback = useCallback(
    (user: User, roles: Role[]) => dispatch(editUser(user, roles, () => history.push(`/${navAdminType}admin/users`))),
    [],
  );
  const clearToastCallback = useCallback(() => dispatch(usersClearToast()), []);

  useEffect(() => {
    if (!usersIsLoaded) {
      getUsersCallback();
    }
  }, []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }

  return (
    <Switch>
      <Route
        exact
        path={`/${navAdminType}admin/users`}
        render={(props) => (
          <Users
            {...props}
            users={users}
            isLoading={usersLoading}
            hasError={usersDetailsError}
            errorDetails={usersErrorDetails}
            deleteUser={deleteUserCallback}
            editUser={editUserCallback}
            companyAdmin={companyAdmin}
            company={company}
            navAdminType={navAdminType}
            defaultSelectedPage={selectedPage}
            setDefaultSelectedPage={handleDefaultChange}
          />
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/users/add`}
        render={(props) => (
          <AddUser
            {...props}
            addNewUser={addUserCallback}
            companyAdmin={companyAdmin}
            company={company}
            navAdminType={navAdminType}
          />
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/users/:userId/edit`}
        render={(props) => (
          <EditUser
            {...props}
            isLoading={usersLoading}
            editUser={editUserCallback}
            companyAdmin={companyAdmin}
            users={users}
            userId={props.match.params.userId}
            company={company}
            navAdminType={navAdminType}
          />
        )}
      />
    </Switch>
  );
};

export default UsersContainer;
