import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './DeliveryModeIcon.module.scss';
import { ReactComponent as TruckIcon } from '../../../../svgs/truck-to-ship.svg';
import { ReactComponent as PipeIcon } from '../../../../svgs/pipe-to-ship.svg';
import { ReactComponent as ShipIcon } from '../../../../svgs/ship-to-ship.svg';
import { ReactComponent as ContainerIcon } from '../../../../svgs/container-to-ship.svg';
import { DeliveryModes } from '../../../../models/DeliveryMode';

interface OwnProps {
  deliveryMode: string | undefined;
}

const DeliveryModeIcon: React.FC<OwnProps> = ({ deliveryMode }) => (
  <div className={`${styles['delivery-mode-icon']}`}>
    {deliveryMode == DeliveryModes.Truck ? (
      <TruckIcon className={styles[deliveryMode]} />
    ) : deliveryMode == DeliveryModes.Ship ? (
      <ShipIcon className={styles[deliveryMode]} />
    ) : deliveryMode == DeliveryModes.Container ? (
      <ContainerIcon className={styles[deliveryMode]} />
    ) : deliveryMode == DeliveryModes.Pipe ? (
      <PipeIcon className={styles[deliveryMode]} />
    ) : (
      <div></div>
    )}
  </div>
);

export default DeliveryModeIcon;
