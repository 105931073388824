import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';

const validationMessage = {message: 'Not valid', exculdeEmptyString: true};

export default Yup.object().shape({
        //externalAccessCompanyId: Yup.string().required("External Access companies required"),
        externalCompany: Yup.string().required("External Access company required"),
        read: Yup.boolean().optional(),
        write: Yup.boolean().optional(),
        timeStamp: Yup.date().optional(),
})