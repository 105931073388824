import React from 'react';
import { Nomination } from '../../../../store/Nomination/models';
import { IShipWithId, ILocation } from '@teqplay/chorus-components';
import NominationTable from './NominationTable';
import styles from './NominationTable.module.scss';
import NoNominations from '../NoNominations/NoNominations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
const rightArrow = findIconDefinition({
  prefix: 'far',
  iconName: 'arrow-right',
});

interface OwnProps {
  nominations: Nomination[];
  archived: boolean;
  sortByNeedsAction: boolean;
}

const NominationTableContainer: React.FC<OwnProps> = ({ nominations, archived, sortByNeedsAction }) => {
  const sortNominationsByTime = (a: Nomination, b: Nomination) => {
    const dateA = new Date(a.bst);
    const dateB = new Date(b.bst);
    if (dateA > dateB) return 1;
    return -1;
  };

  const sortNominationsByNeedsAction = (a: Nomination, b: Nomination) => {
    let number = 0;
    if (a.attributes.needsAction) {
      number -= 1;
    }
    if (b.attributes.needsAction) {
      number += 1;
    }
    return number;
  };

  const sortedNominations = [...nominations].sort(sortNominationsByTime);
  const sortedNominationsByNeedsAction = [...sortedNominations].sort(sortNominationsByNeedsAction);

  if (nominations.length < 1) {
    return <NoNominations className={styles['message-wrapper']} archived={archived} />;
  } else {
    return (
      <div>
        <>
          {sortByNeedsAction && <NominationTable nominations={sortedNominationsByNeedsAction} archived={archived} />}
          {!sortByNeedsAction && <NominationTable nominations={sortedNominations} archived={archived} />}
        </>
        <Link to={'bunkerevents'}>
          <Button color="link" className={styles['view-all-button']}>
            View all <FontAwesomeIcon icon={rightArrow} />
          </Button>
        </Link>
      </div>
    );
  }
};

export default NominationTableContainer;
