import { UserVesselWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const USERVESSEL_LOADING = 'userVessel/loading';
export type USERVESSEL_LOADING = typeof USERVESSEL_LOADING;

export const GET_USERVESSEL = 'userVessel/get';
export type GET_USERVESSEL = typeof GET_USERVESSEL;

export const USERVESSEL_FETCH_ERROR = 'userVessel/fetchError';
export type USERVESSEL_FETCH_ERROR = typeof USERVESSEL_FETCH_ERROR;

export const ADD_USERVESSEL = 'userVessel/add';
export type ADD_USERVESSEL = typeof ADD_USERVESSEL;

export const USERVESSEL_FETCH_SUCCESS = 'userVessel/fetchSuccess';
export type USERVESSEL_FETCH_SUCCESS = typeof USERVESSEL_FETCH_SUCCESS;

export const DELETE_USERVESSEL = 'userVessel/delete';
export type DELETE_USERVESSEL = typeof DELETE_USERVESSEL;

export const EDIT_USERVESSEL = 'userVessel/edit';
export type EDIT_USERVESSEL = typeof EDIT_USERVESSEL;

export type UserVesselActions =
  | {
      type: GET_USERVESSEL;
      payload: UserVesselWrapperModel['data'];
    }
  | {
      type: USERVESSEL_LOADING;
    }
  | {
      type: USERVESSEL_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_USERVESSEL;
      payload: UserVesselWrapperModel['data'];
    }
  | {
      type: USERVESSEL_FETCH_SUCCESS;
    }
  | {
      type: DELETE_USERVESSEL;
      payload: UserVesselWrapperModel['data'];
    }
  | {
      type: EDIT_USERVESSEL;
      payload: UserVesselWrapperModel['data'];
    };
