import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import {IntegrationAssetPermissions} from '../../../../../store/IntegrationAssetPermissions/models';

import { Role } from '../../../../../store/Roles/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';
import { Formik } from 'formik';
import IntegrationAssetPermissionsForm from '../IntegrationAssetForm/IntegrationAssetPermissionsForm'; 
import integrationAssetPermissionsFormValidation from '../FormValidations/integrationAssetPermissionsFormValidation';


interface OwnProps {
  veracityAssetId: string;
  integrationAssetId: string;
  integrationAssetCompany: string;
  addNewIntegrationAssetPermissions: (integrationAssetPermissions: IntegrationAssetPermissions) => void;
  navAdminType: string;
  integrationAssetPermissions?: IntegrationAssetPermissions;
}

const AddIntegrationAssetPermissions: React.FunctionComponent<OwnProps> = ({ addNewIntegrationAssetPermissions, navAdminType, integrationAssetPermissions, veracityAssetId, integrationAssetId, integrationAssetCompany }) => {
  const submitIntegrationAssetPermissions = (integrationAssetPermissions: IntegrationAssetPermissions) => {
    addNewIntegrationAssetPermissions(integrationAssetPermissions);
  };

  const initialValues: IntegrationAssetPermissions = {
    veracityAssetId: veracityAssetId,
    assetId: integrationAssetId,
    ownerCompanyId: integrationAssetCompany,
    externalAccessCompanyId: '',
    read: false,
    write: false,
  };

  return (
    <Formik
      initialValues={ integrationAssetPermissions ? integrationAssetPermissions : initialValues}
      validationSchema={integrationAssetPermissionsFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetPermissionsForm
          integrationAssetPermissions={integrationAssetPermissions}
          header={'Add integration asset permissions'}
          saveMethod={submitIntegrationAssetPermissions}
          systemAdminPage={true}
          integrationAssetId={integrationAssetId}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationAssetPermissions);
