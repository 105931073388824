import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from './../../../../../store/index';
import styles from './SessionTimeout.module.scss';
import { SessionTimeOutError } from '../../../../../components/Error/components/SessionTimeOutErrror';
import { getAuth } from './../../../../../store/Auth/actions';
import { Button } from 'reactstrap';
import { ReactComponent as FuelBossLogout } from '../logout_illustration.svg';
import logo from '../FuelBoss_icon.png';

interface OwnProps {
  setTimeoutBoxDisplayed: (val: boolean) => void;
}

const SessionTimeout: React.FC<OwnProps> = ({ setTimeoutBoxDisplayed }) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const [currentTime, setCurrentTime] = React.useState<Date>(new Date());
  const [timeoutInMinutes, setTimeoutInMinutes] = React.useState<number>(20);
  const [displayTimeoutBox, setDisplayTimeoutBox] = React.useState<boolean>(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (auth && auth.sessionTimeOut) {
      const localTimeoutInMinutes = Math.floor(
        (new Date(auth.sessionTimeOut).getTime() - currentTime.getTime()) / 60000,
      );
      setTimeoutInMinutes(localTimeoutInMinutes);
      if (
        (localTimeoutInMinutes === 5 ||
          localTimeoutInMinutes === 4 ||
          localTimeoutInMinutes === 3 ||
          localTimeoutInMinutes === 2 ||
          localTimeoutInMinutes === 1) &&
        timeoutInMinutes != localTimeoutInMinutes
      ) {
        setDisplayTimeoutBox(true);
        setTimeoutBoxDisplayed(true);
      }
    }
  }, [currentTime]);

  const getSignoutLogo = () => {
    return <FuelBossLogout className={styles['status-logo']} />;
  };

  if (!auth.sessionTimeOut) {
    return null;
  }

  if (displayTimeoutBox) {
    return (
      <div className={styles['session-timeout-wrapper']}>
        <div className={styles['session-wrapper']}>
          <div className={styles['circle']}></div>
          <div className={styles['content']}>
            <div className={styles['content-left']}>
              <div className={styles['fb-logo']}>
                <div className={styles['fb-logo-img']}>
                  <img src={logo} alt="Logo" className={styles.logo} />
                </div>
                <div className={styles['fb-logo-text']}>FuelBoss</div>
              </div>
              {timeoutInMinutes <= 0 && <div className={styles['session-div']}>You have been signed out.</div>}
              {timeoutInMinutes > 0 && (
                <div className={styles['session-div']}>
                  <div className={styles['error-header']}>Your session is about to timeout</div>
                  <div className={styles['error-description']}>Timing out in {timeoutInMinutes} minutes</div>
                </div>
              )}
              <div className={styles['nom-button']}>
                <Button
                  className={styles['refresh-button']}
                  onClick={() => {
                    setTimeoutBoxDisplayed(false);
                    window.location.href = '/account/signin';
                  }}
                >
                  Login again
                </Button>
              </div>
            </div>
            <div className={styles['content-right']}>{getSignoutLogo()}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default SessionTimeout;
