import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import {
  CHORUSCONTRACTS_LOADING,
  CHORUSCONTRACTS_FETCH_ERROR,
  GET_CHORUSCONTRACTS,
  ChorusContractsActions,
} from './types';
import { ChorusContractsResponseModel } from './models';

export const getChorusContracts = () => (dispatch: Dispatch<ChorusContractsActions>) => {
  dispatch({
    type: CHORUSCONTRACTS_LOADING,
  });
  new api<ChorusContractsResponseModel>({
    dispatch,
    errorType: CHORUSCONTRACTS_FETCH_ERROR,
  })
    .get(`api/chorus/GetContracts/`)
    .then((response) => {
      dispatch({
        type: GET_CHORUSCONTRACTS,
        payload: response,
      });
    });
};
