import React, { useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button } from 'reactstrap';
import styles from './ToggleVisibilityButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import classnames from 'classnames';
import { ReactComponent as EyeOpenIcon } from '../../../../../../svgs/eye-open.svg';
import { ReactComponent as EyeClosedIcon } from '../../../../../../svgs/eye-closed.svg';
interface OwnProps {
  id: string;
  method: (id: string) => void;
  header: string;
  btnIcon: IconDefinition;
  className?: string;
  isActive: boolean;
}

const ToggleVisibilityButton: React.FC<OwnProps> = ({ id, method, header, btnIcon, className, isActive }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Button className={`${styles['btn-visible']}  ${className}`} onClick={toggle}>
        {isActive ? (
          <EyeOpenIcon className={classnames(styles.eyeClosed, styles.eye)} />
        ) : (
          <EyeClosedIcon className={classnames(styles.eyeOpen, styles.eye)} />
        )}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody>
          <h3>Are you sure you want to {isActive ? 'deactivate report' : 'activate report'}?</h3>
          <Button
            color="danger"
            onClick={() => {
              method(id);
              toggle();
            }}
          >
            Confirm
          </Button>
          <Button color="primary" onClick={toggle}>
            Cancel
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default React.memo(ToggleVisibilityButton);
