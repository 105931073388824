/* eslint-disable react/display-name */
import { findIconDefinition } from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Column, Row } from 'react-table';
import { Button, Card, CardBody, Collapse, Input, Tooltip, UncontrolledTooltip } from 'reactstrap';
import FuelBossTable from '../../../../components/FuelBossTable';
import { Loading } from '../../../../components/Loading';
import DeliveryModeIcon from '../../../../components/Nomination/Components/DeliveryModeIcon/DeliveryModeIcon';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { ApplicationState } from '../../../../store';
import { archiveNomination, getNominationsPaginated } from '../../../../store/Nomination/actions';
import { getRecurringNominationsPaginated } from '../../../../store/RecurringNomination/actions';
import {
  cancelNominationSchedule,
  getNominationSchedulesPaginated,
  nominationScheduleClearToast,
  pushToNominationSchedule,
  updateNominationSchedule,
} from '../../../../store/NominationSchedule/actions';
import { DocumentStatusValue, Nomination } from '../../../../store/Nomination/models';
import styles from './OverviewRecurringList.module.scss';
import editbuttonstyles from '../../../../components/EditById/EditById.module.scss';
import { Error } from '../../../../components/Error';
import { getArchivedNominationsPaginated } from '../../../../store/ArchivedNomination/actions';
import useInterval from '../../../../helpers/useInterval';
import ReactTooltip from 'react-tooltip';
import { StandardWrapper } from '../../../../models/Responses';
import CountDownCell from '../../../Overview/Components/OverviewNominationList/Components/CountDownCell/CountDownCell';
import {
  ScheduleRecurrenceType,
  RecurringNomination,
  WeeklyScheduleModel,
  DailyScheduleModel,
} from '../../../../store/RecurringNomination/models';
import { DateTime } from 'luxon';
import { AddToast } from '../../../../helpers/toastService';
import {
  ILocation,
  LocationSearch,
  LocationService,
  QUANTITY_UNITS,
  useLocationTimeZone,
} from '@teqplay/chorus-components';
import { NominationSchedule } from '../../../../store/NominationSchedule/models';
import DeleteById from '../../../../components/DeleteById/DeleteById';
import { ButtonGroup, datesAreEqual, DateTimePicker, Dialog, isAfter, isBefore } from '@teqplay/teqplay-ui';
import { checkShipOwner } from '../../../../helpers/roleChecks';
import { ReactComponent as IIcon } from '../../../../svgs/info-circle.svg';

const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const archiveIcon = findIconDefinition({ prefix: 'fal', iconName: 'archive' });
const save = findIconDefinition({ prefix: 'fal', iconName: 'save' });
const downArrowIcon = findIconDefinition({ prefix: 'fal', iconName: 'arrow-down' });
const chevronRight = findIconDefinition({ prefix: 'fal', iconName: 'chevron-right' });
const chevronDown = findIconDefinition({ prefix: 'fal', iconName: 'chevron-down' });
const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const banIcon = findIconDefinition({ prefix: 'fal', iconName: 'ban' });
const gas = findIconDefinition({ prefix: 'fal', iconName: 'gas-pump' });
const warningTriangle = findIconDefinition({ prefix: 'fal', iconName: 'exclamation-triangle' });

interface OwnProps {
  deliveryModes: DeliveryModes[];
  sortBy: 'time' | 'needsAction';
  //archived?: boolean;
  isSupplier?: boolean;
  userCompanyId?: string;
  refetchInterval?: number;
  fetchData?: boolean;
  locationService: LocationService;
}

interface ScheduleCustomValues {
  amount: number;
  bst: string;
  eta: string;
  etd: string;
  deliveryMode: string;
  locationId: string;
  bstValidation: string;
  etaValidation: string;
  etdValidation: string;
}

interface CustomeValuesDictionary {
  [id: string]: ScheduleCustomValues;
}

const OverviewRecurringList: React.FC<OwnProps> = ({
  deliveryModes,
  sortBy,
  // archived = false,
  isSupplier = true,
  userCompanyId,
  refetchInterval = 60000,
  fetchData = true,
  locationService,
}) => {
  const dispatch = useDispatch();

  const { auth } = useSelector((state: ApplicationState) => ({ auth: state.auth.data }));

  const selector = (state: ApplicationState) => state.recurring_nomination;
  const scheduleselector = (state: ApplicationState) => state.nomination_schedule;
  const recurringNomination = useSelector((state: ApplicationState) => selector(state).data);
  const recurringNominationLoading = useSelector((state: ApplicationState) => selector(state).loading);
  const recurringNominationMetadata = useSelector((state: ApplicationState) => selector(state).metadata);
  const recurringNominationError = useSelector((state: ApplicationState) => selector(state).error);
  const recurringNominationErrorDetails = useSelector((state: ApplicationState) => selector(state).errorDetails);
  const recurringNominationLoaded = useSelector((state: ApplicationState) => selector(state).dataIsLoaded);
  const [pageSize, setPageSize] = useState(10);

  const nominationSchedule = useSelector((state: ApplicationState) => scheduleselector(state).data);
  const nominationScheduleLoading = useSelector((state: ApplicationState) => scheduleselector(state).loading);
  const nominationScheduleMetadata = useSelector((state: ApplicationState) => scheduleselector(state).metadata);
  const nominationScheduleError = useSelector((state: ApplicationState) => scheduleselector(state).error);
  const nominationScheduleErrorDetails = useSelector((state: ApplicationState) => scheduleselector(state).errorDetails);
  const nominationScheduleLoaded = useSelector((state: ApplicationState) => scheduleselector(state).dataIsLoaded);
  const toastData = useSelector((state: ApplicationState) => scheduleselector(state).toastData);
  const [pageSizeNominationSchedule, setPageSizeNominationSchedule] = useState(10);

  const vesselselector = (state: ApplicationState) => state.bunkership;
  const bunkerShip = useSelector((state: ApplicationState) => vesselselector(state).data);
  const bunkerShipLoading = useSelector((state: ApplicationState) => vesselselector(state).loading);
  const bunkerShipError = useSelector((state: ApplicationState) => vesselselector(state).error);
  const bunkerShipErrorDetails = useSelector((state: ApplicationState) => vesselselector(state).errorDetails);
  const bunkerShipLoaded = useSelector((state: ApplicationState) => vesselselector(state).dataIsLoaded);

  const clearToastCallback = useCallback(() => dispatch(nominationScheduleClearToast()), []);
  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  const [activeDialog, setActiveDialog] = useState<'CANCEL' | 'PUSHTONOMINATION' | 'SAVE' | undefined>(undefined);
  const [rerenderPlaceHolder, setRerenderPlaceHolder] = React.useState(false);

  //a dictionary of schedules with custom values to keep track of it
  const [nominationScheduleCustomValuesDictionary, setNominationScheduleCustomValuesDictionary] = React.useState<
    CustomeValuesDictionary
  >({});
  const [selectedNominationSchedule, setSelectedNominationSchedule] = React.useState<NominationSchedule>();
  const [editNominationScheduleId, setEditNominationScheduleId] = React.useState<string>('');
  const [expandedRecurringNominationId, setExpandedRecurringNominationId] = React.useState<string>('');
  useEffect(() => {
    if (expandedRecurringNominationId) {
      dispatch(
        getNominationSchedulesPaginated(
          expandedRecurringNominationId,
          sortBy === 'needsAction',
          0,
          pageSizeNominationSchedule,
        ),
      );
    }
  }, [expandedRecurringNominationId, pageSizeNominationSchedule]);

  const cancelNominationScheduleCallback = useCallback((id: string) => dispatch(cancelNominationSchedule(id)), []);

  const pushToNominationScheduleCallback = useCallback((id: string) => dispatch(pushToNominationSchedule(id)), []);

  const updateRecurringNominationCallback = React.useCallback(
    (schedule: NominationSchedule) =>
      dispatch(
        updateNominationSchedule(schedule, () => {
          history.push(`/recurringnominations`);
        }),
      ),
    [],
  );

  const getRecurringNominations = () => {
    if (!fetchData) return;
    dispatch(getRecurringNominationsPaginated(sortBy === 'needsAction', 0, pageSize));
  };

  useEffect(getRecurringNominations, [pageSize, sortBy, fetchData]);
  useInterval(getRecurringNominations, refetchInterval);

  const deliveryModeFilter = useCallback(
    (rows: Row<RecurringNomination>[], _, value) => rows.filter((r) => value.includes(r.original.deliveryMode)),
    [],
  );

  const getReccuranceDaysForDailySchedule = (schedule: DailyScheduleModel) => {
    if (!schedule) return '';
    if (schedule.occursEveryNDays == 1) return '1st';
    else if (schedule.occursEveryNDays == 2) return '2nd';
    else if (schedule.occursEveryNDays == 3) return '3rd';
    else return schedule.occursEveryNDays + 'th';
  };

  const getReccuranceDaysForWeeklySchedule = (schedule: WeeklyScheduleModel) => {
    if (!schedule) return '';
    let days = '';
    if (schedule.monday) days = days + 'MO,';
    if (schedule.tuesday) days = days + 'TU,';
    if (schedule.wednesday) days = days + 'WE,';
    if (schedule.thursday) days = days + 'TH,';
    if (schedule.friday) days = days + 'FR,';
    if (schedule.saturday) days = days + 'SA,';
    if (schedule.sunday) days = days + 'SU,';
    if (days.length > 1) return days.slice(0, -1);
    return days;
  };
  const getRecurranceString = (
    num: ScheduleRecurrenceType,
    dailySchedule: DailyScheduleModel,
    weeklySchedule: WeeklyScheduleModel,
  ) => {
    if (num == ScheduleRecurrenceType.Weekly)
      return ScheduleRecurrenceType[num] + ': ' + getReccuranceDaysForWeeklySchedule(weeklySchedule);
    else if (num == ScheduleRecurrenceType.Daily)
      return 'Every ' + getReccuranceDaysForDailySchedule(dailySchedule) + ' day';
    else return ScheduleRecurrenceType[num];
  };

  const getLocationText = async (choursLocationId: string) => {
    const selectedLocation: ILocation | undefined = await locationService.findLocationById(choursLocationId);
    if (selectedLocation) {
      return `${selectedLocation.port}, ${selectedLocation.country}`;
    } else {
      return choursLocationId;
    }
  };

  const LocationCell = ({ cell }: any) => {
    const [locationName, setlocationName] = useState(' ');

    const getlocationName = async (id: string) => {
      const location_name = await getLocationText(id);
      setlocationName(location_name);
    };

    useEffect(() => {
      getlocationName(cell.row.original.locationId);
    }, []);

    return <>{locationName}</>;
  };

  const VesselCell = ({ cell }: any) => {
    const [vesselName, setvesselName] = useState(' ');

    const getvesselName = async (id: string) => {
      //const vesselname = await getvesselText(id);
      //setvesselName(locationname);
    };

    useEffect(() => {
      getvesselName(cell.row.original.locationId);
    }, []);

    return vesselName;
  };

  /*   const getBunkerAsset = (deliveryMode: string | undefined, nomination: RecurringNomination) => {
    if (deliveryMode == 'SHIP') {
      const bunkerShip = nomination.attributes.bunkerShipName;
      if (bunkerShip) return bunkerShip;
      else return 'Bunker vessel not assigned';
    }
    if (deliveryMode == 'PIPE') {
      const bunkerPipeId = nomination.pipelineId;
      if (bunkerPipeId) return nomination.attributes.pipelineName;
      else return 'Pipeline not assigned';
    } else {
      return '';
    }
  }; */

  function getDialogTitleIcon(action: 'CANCEL' | 'PUSHTONOMINATION' | 'SAVE') {
    switch (action) {
      case 'CANCEL':
        return <FontAwesomeIcon icon={banIcon} />;
      case 'PUSHTONOMINATION':
        return <FontAwesomeIcon icon={gas} />;
      case 'SAVE':
        return <FontAwesomeIcon icon={edit} />;
      default:
        return null;
    }
  }

  const columns: Array<Column<RecurringNomination>> = React.useMemo(
    () => [
      /* {
        id: 'fbStatus',
        Header: '',
        maxWidth: 10,
        accessor: (row) => row.fbStatus,
        Cell: (props: any) => <div className={`${styles['status']} ${styles[props.cell.row.original.fbStatus]}`}></div>,
        //sortType: statusSort,
        disableSortBy: true,
      }, */
      /* {
        id: 'daysleft',
        Header: 'Days left',
        accessor: (row) => row.bst,
        Cell: (props: any) => (
          <CountDownCell bst={props.cell.row.original.bst} fbStatus={props.cell.row.original.fbStatus} />
        ),
        disableSortBy: true,
      }, */
      {
        Header: 'Vessel',
        maxWidth: 100,
        accessor: (row) => (row.attributes ? row.attributes.receivingShipName : row.receivingShipId),
        disableSortBy: true,
      },

      {
        Header: 'Port',
        Cell: LocationCell,
        disableSortBy: true,
      },
      {
        Header: 'Quantity',
        accessor: (row) => row.amount,
        Cell: (props: any) => (
          <p>
            {props.cell.row.original.amount}{' '}
            {props.cell.row.original.quantityUnit === 'TONNES' ? (
              'mt'
            ) : (
              <>
                m<sup>3</sup>
              </>
            )}
          </p>
        ),
        disableSortBy: true,
      },
      {
        id: 'deliveryMode',
        Header: 'Delivery mode',
        accessor: (row) => row.deliveryMode,
        Cell: (props: any) => <DeliveryModeIcon deliveryMode={props.cell.row.original.deliveryMode} />,
        filter: deliveryModeFilter,
        disableSortBy: true,
      },
      {
        Header: 'Recurrence',
        Cell: (props: any) =>
          getRecurranceString(
            props.cell.row.original.scheduleRecurrenceType,
            props.cell.row.original.dailySchedule,
            props.cell.row.original.weeklySchedule,
          ),
        disableSortBy: true,
      },
      {
        id: 'bst',
        Header: 'BST',
        accessor: (row) => row.bst,
        Cell: (props: any) => <Moment date={props.cell.row.original.bst} format="DD MMM, HH:mm" />,
        disableSortBy: true,
      },
      {
        id: 'startDate',
        Header: 'Start date',
        accessor: (row) => row.startDate,
        Cell: (props: any) => <Moment date={props.cell.row.original.startDate} format="DD MMM YYYY" />,
        disableSortBy: true,
      },
      {
        id: 'endDate',
        Header: 'End date',
        accessor: (row) => row.endDate,
        Cell: (props: any) => <Moment date={props.cell.row.original.endDate} format="DD MMM YYYY" />,
        disableSortBy: true,
      },
      {
        Header: 'Upcoming events',
        Cell: (props: any) => {
          const { row } = props.cell;
          const recurrinNomination = row.original;
          return (
            <div>
              {recurrinNomination.numberOfEvents}
              {recurrinNomination.failedScheduledEventsCount > 0 ? (
                <span className={styles['tooltip-span']}>
                  <FontAwesomeIcon id="pathTooltip" data-tip="mydata-datatip" icon={warningTriangle} color="#c4262e" />
                  <ReactTooltip place="top" type="light" effect="solid" className={styles['tooltip-class']}>
                    An error occured while processing an event to nominations
                  </ReactTooltip>
                </span>
              ) : (
                <></>
              )}
            </div>
          );
        },
        //accessor: (row) => row.numberOfEvents,
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const { row } = props.cell;
          const recurrinNomination = row.original;
          return (
            <div
              className="expand-cell"
              onClick={(e) => {
                recurrinNomination.id != expandedRecurringNominationId
                  ? setExpandedRecurringNominationId(recurrinNomination.id)
                  : setExpandedRecurringNominationId('');
                setEditNominationScheduleId('');
                e.stopPropagation();
              }}
            >
              <span className={styles['btn-expand']}>
                {row.isExpanded ? (
                  <FontAwesomeIcon size="lg" color="#24365c" icon={chevronDown} />
                ) : (
                  <FontAwesomeIcon size="lg" color="#24365c" icon={chevronRight} />
                )}
              </span>
            </div>
          );
        },
      },
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Cell: (props: any) => {
          const { row } = props.cell;
          const toggleRowExpanded = props.toggleRowExpanded;
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          if (row.original.id == expandedRecurringNominationId) {
            row.canExpand = true;
            row.isExpanded = true;
          } else {
            row.canExpand = false;
            row.isExpanded = false;
          }

          if (row.canExpand) {
            return (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                  },
                })}
              >
                {/* {row.isExpanded ? (
                  <Button className={styles['btn-open']}>
                    <FontAwesomeIcon color="#24365c" icon={chevronDown} />
                  </Button>
                ) : (
                  <Button className={styles['btn-open']}>
                    <FontAwesomeIcon color="#24365c" icon={chevronRight} />
                  </Button>
                )} */}
              </span>
            );
          } else return null;
        },
      },
      /* {
        id: 'bunkerAsset',
        Header: 'Bunker asset',
        Cell: (props: any) => <p>{getBunkerAsset(props.cell.row.original.deliveryMode, props.cell.row.original)}</p>,
        disableSortBy: true,
      }, */
    ],
    [recurringNomination, nominationSchedule, expandedRecurringNominationId],
  );

  const validateETA = (eta: string, etd: string) => {
    const isValidDate = eta && DateTime.fromISO(eta).isValid ? true : false;
    if (!isValidDate) {
      return 'Invalid date';
    }
    if (isAfter(DateTime.fromISO(eta), DateTime.fromISO(etd))) {
      return 'ETA must be before ETD';
    }
    if (datesAreEqual(DateTime.fromISO(eta), DateTime.fromISO(etd))) {
      return 'ETA cant be same as ETD';
    }

    //valid case empty error message
    return '';
  };

  const validateETD = (eta: string, etd: string) => {
    const isValidDate = etd && DateTime.fromISO(etd).isValid ? true : false;
    if (!isValidDate) {
      return 'Invalid date';
    }
    if (isBefore(DateTime.fromISO(etd), DateTime.fromISO(eta))) {
      return 'ETD must be after ETA';
    }
    if (datesAreEqual(DateTime.fromISO(eta), DateTime.fromISO(etd))) {
      return 'ETD cant be same as ETA';
    }
    if (isBefore(DateTime.fromISO(etd), DateTime.local())) {
      return 'ETD must be in future';
    }

    //valid case empty error message
    return '';
  };

  const validateBST = (eta: string, etd: string, bst: string) => {
    const isValidDate = bst && DateTime.fromISO(bst).isValid ? true : false;
    if (!isValidDate) {
      return 'Invalid date';
    }

    if (isBefore(DateTime.fromISO(bst), DateTime.local())) {
      return 'BST must be in future';
    }
    if (eta && isBefore(DateTime.fromISO(bst), DateTime.fromISO(eta))) {
      return 'BST must be after ETA';
    }
    if (etd && isAfter(DateTime.fromISO(bst), DateTime.fromISO(etd))) {
      return 'BST must be before ETD';
    }

    //valid case empty error message
    return '';
  };

  const updateNominationScheduleCustomValues = (
    id: string,
    amount: number,
    deliveryMode: string,
    bst: string,
    eta: string,
    etd: string,
    locationId: string,
  ) => {
    const oldScheduleCustomValues = nominationScheduleCustomValuesDictionary;
    //validate eta
    const etaValidation = validateETA(eta, etd);
    //validate etd
    const etdValidation = validateETD(eta, etd);
    //validate bst
    const bstValidation = validateBST(eta, etd, bst);
    oldScheduleCustomValues[id] = {
      amount: amount,
      deliveryMode: deliveryMode,
      bst: bst,
      eta: eta,
      etd: etd,
      bstValidation: bstValidation,
      etdValidation: etdValidation,
      etaValidation: etaValidation,
      locationId: locationId,
    };
    setNominationScheduleCustomValuesDictionary(oldScheduleCustomValues);
    setRerenderPlaceHolder(!rerenderPlaceHolder);
  };

  const EditableDeliveryMode = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance,
    cell,
  }: any) => {
    const [value, setValue] = React.useState(initialValue);
    const scheduleId = cell.row.original.id;
    const hasCustomValues = cell.row.original.hasCustomValues;

    const customValues = JSON.parse(cell.row.original.customValues) as ScheduleCustomValues;
    const selectedRecEvent = recurringNomination.find((x) => x.id == cell.row.original.recurringNominationTemplateId);
    const deliveryModes = ['SHIP', 'TRUCK', 'PIPE', 'CONTAINER'];
    const onChange = (e: any) => {
      setValue(e.target.value);
      updateNominationScheduleCustomValues(
        scheduleId,
        nominationScheduleCustomValuesDictionary[scheduleId].amount,
        e.target.value,
        nominationScheduleCustomValuesDictionary[scheduleId].bst,
        nominationScheduleCustomValuesDictionary[scheduleId].eta,
        nominationScheduleCustomValuesDictionary[scheduleId].etd,
        nominationScheduleCustomValuesDictionary[scheduleId].locationId,
      );
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (editNominationScheduleId != scheduleId) {
      //if there are custom values load those else load value on recurring event
      return (
        <DeliveryModeIcon
          deliveryMode={
            hasCustomValues && customValues.deliveryMode ? customValues.deliveryMode : selectedRecEvent?.deliveryMode
          }
        />
      );
    } else if (nominationScheduleCustomValuesDictionary.hasOwnProperty(scheduleId)) {
      const values = nominationScheduleCustomValuesDictionary[scheduleId];
      return (
        <Input type="select" name="deliveryMode" id="deliveryMode" onChange={onChange} value={values.deliveryMode}>
          {deliveryModes.map((x) => (
            <option>{x}</option>
          ))}
        </Input>
      );
    }
    return (
      <Input type="select" name="deliveryMode" id="deliveryMode" onChange={onChange} value={value}>
        {deliveryModes.map((x) => (
          <option>{x}</option>
        ))}
      </Input>
    );
  };

  const EditableLocationCell = ({ cell }: any) => {
    const [locationName, setlocationName] = useState(' ');
    const scheduleId = cell.row.original.id;
    const selectedRecEvent = recurringNomination.find((x) => x.id == cell.row.original.recurringNominationTemplateId);

    const getlocationName = async (id: string) => {
      const location_name = await getLocationText(id);
      setlocationName(location_name);
    };

    useEffect(() => {
      if (selectedRecEvent) getlocationName(selectedRecEvent.locationId);
    }, []);

    const onChange = (e: any) => {
      if (e == null) return;
      updateNominationScheduleCustomValues(
        scheduleId,
        nominationScheduleCustomValuesDictionary[scheduleId].amount,
        nominationScheduleCustomValuesDictionary[scheduleId].deliveryMode,
        nominationScheduleCustomValuesDictionary[scheduleId].bst,
        nominationScheduleCustomValuesDictionary[scheduleId].eta,
        nominationScheduleCustomValuesDictionary[scheduleId].etd,
        e.target.value,
      );
    };

    if (editNominationScheduleId != scheduleId) {
      return locationName;
    } else if (nominationScheduleCustomValuesDictionary.hasOwnProperty(scheduleId)) {
      const values = nominationScheduleCustomValuesDictionary[scheduleId];
      return (
        <LocationSearch
          locationService={locationService}
          onChange={onChange}
          label=""
          placeholder={'Select Port...'}
          disabled={false}
          fieldName="locationId"
          error={undefined}
          value={!values?.locationId ? '-' : values?.locationId}
          prevValue={undefined}
        />
      );
    } else {
      return <></>;
    }
  };

  const EditableCellQuantity = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance,
    cell,
  }: any) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const scheduleId = cell.row.original.id;
    const hasCustomValues = cell.row.original.hasCustomValues;

    const customValues = JSON.parse(cell.row.original.customValues) as ScheduleCustomValues;
    const selectedRecEvent = recurringNomination.find((x) => x.id == cell.row.original.recurringNominationTemplateId);

    const onChange = (e: any) => {
      setValue(e.target.value);
      updateNominationScheduleCustomValues(
        scheduleId,
        e.target.value,
        nominationScheduleCustomValuesDictionary[scheduleId].deliveryMode,
        nominationScheduleCustomValuesDictionary[scheduleId].bst,
        nominationScheduleCustomValuesDictionary[scheduleId].eta,
        nominationScheduleCustomValuesDictionary[scheduleId].etd,
        nominationScheduleCustomValuesDictionary[scheduleId].locationId,
      );
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (editNominationScheduleId != scheduleId) {
      //if there are custom values load those else load value on recurring event
      if (hasCustomValues && customValues.amount) {
        return <span>{customValues.amount}</span>;
      } else return <span>{selectedRecEvent?.amount}</span>;
    } else if (nominationScheduleCustomValuesDictionary.hasOwnProperty(scheduleId)) {
      const values = nominationScheduleCustomValuesDictionary[scheduleId];
      return (
        <Input
          type="number"
          name="amount"
          className={styles['quantity-input']}
          onChange={onChange}
          value={values.amount}
          disabled={false}
        />
      );
    }
    return (
      <Input
        type="number"
        name="amount"
        className={styles['quantity-input']}
        onChange={onChange}
        value={value}
        disabled={false}
      />
    );
  };

  const EditableCellBST = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance,
    cell,
  }: any) => {
    const [value, setValue] = React.useState(initialValue);
    const scheduleId = cell.row.original.id;
    const hasCustomValues = cell.row.original.hasCustomValues;

    const customValues = JSON.parse(cell.row.original.customValues) as ScheduleCustomValues;
    const selectedRecEvent = recurringNomination.find((x) => x.id == cell.row.original.recurringNominationTemplateId);

    // todo handle change in timezone here if location is changed in schedule event
    const timezone = useLocationTimeZone(selectedRecEvent ? selectedRecEvent.locationId : undefined, locationService);

    const onChange = (val: any) => {
      setValue(val);
      updateNominationScheduleCustomValues(
        scheduleId,
        nominationScheduleCustomValuesDictionary[scheduleId].amount,
        nominationScheduleCustomValuesDictionary[scheduleId].deliveryMode,
        val,
        nominationScheduleCustomValuesDictionary[scheduleId].eta,
        nominationScheduleCustomValuesDictionary[scheduleId].etd,
        nominationScheduleCustomValuesDictionary[scheduleId].locationId,
      );
    };

    const onChangeDateTimeValue = (
      isoDate: string | null,
      inputValue: string,
      isValid: boolean,
      fieldName?: string,
    ) => {
      const valueToUpdate = isoDate || inputValue;
      const isValidDate = valueToUpdate && DateTime.fromISO(valueToUpdate).isValid ? true : false;

      onChange(valueToUpdate);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (editNominationScheduleId != scheduleId) {
      //if there are custom values load those else load value on recurring event
      return <Moment date={cell.row.original.bst} format="DD MMM YYYY, HH:mm" />;
    } else if (nominationScheduleCustomValuesDictionary.hasOwnProperty(scheduleId)) {
      const values = nominationScheduleCustomValuesDictionary[scheduleId];
      const validation = values.bstValidation;
      return (
        <div className={styles['date-cell']}>
          <DateTimePicker
            key={`${scheduleId} bst`}
            name={'bst'}
            className={`${styles['date-input']} ${validation ? styles['invalid-date-input'] : ''}`}
            onDateTimeChange={(
              isoDate: string | null,
              inputValue: string,
              isValid: boolean,
              fieldName?: string | undefined,
            ) => {
              onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
            }}
            disabled={false}
            icon={<i className="icon-calendar-1" />}
            placeholder="dd/mm/yyyy hh:mm"
            value={!values.bst ? '-' : values.bst || ''}
            //using timezone undefined for local time if port time needed use timezone
            //If the timezone is not provided undefined it will fallback to date.toLocal(). it uses luxon DateTime.toLocal for this
            timeZone={undefined}
            hideDialogHeader
            useInitialStart={'hour'}
          />
          <span className={styles['date-cell-validation']}>{validation}</span>
        </div>
      );
    }
    return <></>;
  };

  const EditableCellETA = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance,
    cell,
  }: any) => {
    const [value, setValue] = React.useState(initialValue);
    const scheduleId = cell.row.original.id;
    const hasCustomValues = cell.row.original.hasCustomValues;

    const selectedRecEvent = recurringNomination.find((x) => x.id == cell.row.original.recurringNominationTemplateId);

    // todo handle change in timezone here if location is changed in schedule event
    const timezone = useLocationTimeZone(selectedRecEvent ? selectedRecEvent.locationId : undefined, locationService);

    const onChange = (val: any) => {
      setValue(val);
      updateNominationScheduleCustomValues(
        scheduleId,
        nominationScheduleCustomValuesDictionary[scheduleId].amount,
        nominationScheduleCustomValuesDictionary[scheduleId].deliveryMode,
        nominationScheduleCustomValuesDictionary[scheduleId].bst,
        val,
        nominationScheduleCustomValuesDictionary[scheduleId].etd,
        nominationScheduleCustomValuesDictionary[scheduleId].locationId,
      );
    };

    const onChangeDateTimeValue = (
      isoDate: string | null,
      inputValue: string,
      isValid: boolean,
      fieldName?: string,
    ) => {
      const valueToUpdate = isoDate || inputValue;
      const isValidDate = valueToUpdate && DateTime.fromISO(valueToUpdate).isValid ? true : false;

      onChange(valueToUpdate);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (editNominationScheduleId != scheduleId) {
      //if there are custom values load those else load value on recurring event
      return <Moment date={cell.row.original.eta} format="DD MMM YYYY, HH:mm" />;
    } else if (nominationScheduleCustomValuesDictionary.hasOwnProperty(scheduleId)) {
      const values = nominationScheduleCustomValuesDictionary[scheduleId];
      const validation = values.etaValidation;
      return (
        <div className={styles['date-cell']}>
          <DateTimePicker
            key={`${scheduleId} eta`}
            name={'eta'}
            className={`${styles['date-input']} ${validation ? styles['invalid-date-input'] : ''}`}
            onDateTimeChange={(
              isoDate: string | null,
              inputValue: string,
              isValid: boolean,
              fieldName?: string | undefined,
            ) => {
              onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
            }}
            disabled={false}
            icon={<i className="icon-calendar-1" />}
            placeholder="dd/mm/yyyy hh:mm"
            value={!values.eta ? '-' : values.eta || ''}
            timeZone={undefined}
            hideDialogHeader
            useInitialStart={'hour'}
          />
          <span className={styles['date-cell-validation']}>{validation}</span>
        </div>
      );
    }
    return <></>;
  };

  const EditableCellETD = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance,
    cell,
  }: any) => {
    const [value, setValue] = React.useState(initialValue);
    const scheduleId = cell.row.original.id;
    const hasCustomValues = cell.row.original.hasCustomValues;

    const selectedRecEvent = recurringNomination.find((x) => x.id == cell.row.original.recurringNominationTemplateId);

    // todo handle change in timezone here if location is changed in schedule event
    const timezone = useLocationTimeZone(selectedRecEvent ? selectedRecEvent.locationId : undefined, locationService);

    const onChange = (val: any) => {
      setValue(val);
      updateNominationScheduleCustomValues(
        scheduleId,
        nominationScheduleCustomValuesDictionary[scheduleId].amount,
        nominationScheduleCustomValuesDictionary[scheduleId].deliveryMode,
        nominationScheduleCustomValuesDictionary[scheduleId].bst,
        nominationScheduleCustomValuesDictionary[scheduleId].eta,
        val,
        nominationScheduleCustomValuesDictionary[scheduleId].locationId,
      );
    };

    const onChangeDateTimeValue = (
      isoDate: string | null,
      inputValue: string,
      isValid: boolean,
      fieldName?: string,
    ) => {
      const valueToUpdate = isoDate || inputValue;
      const isValidDate = valueToUpdate && DateTime.fromISO(valueToUpdate).isValid ? true : false;

      onChange(valueToUpdate);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (editNominationScheduleId != scheduleId) {
      //if there are custom values load those else load value on recurring event
      return <Moment date={cell.row.original.etd} format="DD MMM YYYY, HH:mm" />;
    } else if (nominationScheduleCustomValuesDictionary.hasOwnProperty(scheduleId)) {
      const values = nominationScheduleCustomValuesDictionary[scheduleId];
      const validation = values.etdValidation;
      return (
        <div className={styles['date-cell']}>
          <DateTimePicker
            key={`${scheduleId} etd`}
            name={'etd'}
            className={`${styles['date-input']} ${validation ? styles['invalid-date-input'] : ''}`}
            onDateTimeChange={(
              isoDate: string | null,
              inputValue: string,
              isValid: boolean,
              fieldName?: string | undefined,
            ) => {
              onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
            }}
            disabled={false}
            icon={<i className="icon-calendar-1" />}
            placeholder="dd/mm/yyyy hh:mm"
            value={!values.etd ? '-' : values.etd || ''}
            timeZone={undefined}
            hideDialogHeader
            useInitialStart={'hour'}
          />
          <span className={styles['date-cell-validation']}>{validation}</span>
        </div>
      );
    }
    return <></>;
  };

  const onEditNominationSchedule = (
    scheduleId: string,
    schedule: NominationSchedule,
    customValues: ScheduleCustomValues,
  ) => {
    const selectedRecEvent = recurringNomination.find((x) => x.id == schedule.recurringNominationTemplateId);
    const updatedBST = customValues.bst ? customValues.bst : schedule.bst;
    const updatedETA = customValues.eta ? customValues.eta : schedule.eta;
    const updatedETD = customValues.etd ? customValues.etd : schedule.etd;
    const updatedAmount = customValues.amount ? customValues.amount : selectedRecEvent?.amount;

    //filter values you want to update in customValues at the backend
    const filteredCustomValues = {
      //keep the key cases the same as used in the recurring engine call towards chorus in scheduled jobs
      amount: customValues.amount,
      bst: updatedBST,
      eta: updatedETA,
      etd: updatedETD,
    };
    const jsonString = JSON.stringify(filteredCustomValues, (key, value) => {
      //ignore empty values
      if (value !== null && value !== '') return value;
    });

    const updatedSchedule: NominationSchedule = {
      ...schedule,
      customValues: jsonString ? jsonString : null,
      bst: updatedBST,
      eta: updatedETA,
      etd: updatedETD,
    };
    updateRecurringNominationCallback(updatedSchedule);
  };

  const scheduleColumns: Array<Column<NominationSchedule>> = React.useMemo(
    () => [
      {
        id: 'status',
        Header: '',
        maxWidth: 20,
        Cell: (props: any) => {
          const schedule = props.cell.row.original;
          if (schedule.canceled)
            return (
              <div className={`${styles['schedule-status-icon']}`}>
                <FontAwesomeIcon icon={banIcon} />
              </div>
            );
          if (schedule.hasCustomValues) {
            return (
              <div className={`${styles['schedule-status-icon']}`}>
                <FontAwesomeIcon icon={edit} />
              </div>
            );
          }
          return <></>;
        },
        disableSortBy: true,
      },
      /* {
        Header: 'Port',
        id: 'port',
        Cell: EditableLocationCell,
        disableSortBy: true,
      }, */
      {
        Header: 'Quantity',
        id: 'quantity',
        Cell: EditableCellQuantity,
        disableSortBy: true,
      },
      {
        Header: 'Unit',
        id: 'unit',
        Cell: (props: any) => {
          const selectedRecEvent = recurringNomination.find(
            (x) => x.id == props.cell.row.original.recurringNominationTemplateId,
          );
          return <div>{QUANTITY_UNITS.find((x) => x.value == selectedRecEvent?.quantityUnit)?.text}</div>;
        },
        disableSortBy: true,
      },
      /*       {
        id: 'deliveryMode',
        Header: 'Delivery mode',
        Cell: EditableDeliveryMode,
        disableSortBy: true,
      }, */
      {
        id: 'eta',
        Header: 'ETA',
        Cell: EditableCellETA,
        disableSortBy: true,
      },
      {
        id: 'etd',
        Header: 'ETD',
        Cell: EditableCellETD,
        disableSortBy: true,
      },
      {
        id: 'bst',
        Header: 'BST',
        Cell: EditableCellBST,
        disableSortBy: true,
      },
      {
        id: 'autoCreateDateTime',
        Header: 'Send to nominations',
        accessor: (row) => row.autoCreateDatetime,
        Cell: (props: any) => {
          return (
            <div>
              <Moment date={props.cell.row.original.autoCreateDatetime} format="DD MMM, HH:mm" />
            </div>
          );
        },
        disableSortBy: true,
      },
      /* {
        Header: 'Active',
        accessor: (row) => {
          if (row.canceled) return 'No';
          else return 'Yes';
        },
      }, */
      //only show this collumn if the user is system admin
      ...(auth && checkShipOwner(auth)
        ? [
            {
              Header: 'delete',
              Cell: (props: any) => {
                const schedule = props.cell.row.original;
                if (schedule.canceled || !schedule.failedAutoCreate) {
                  return <></>;
                }
                return (
                  <div className={styles['failed-cell']}>
                    <FontAwesomeIcon
                      id="pathTooltip"
                      data-tip="tooltip-datatip"
                      icon={warningTriangle}
                      color="#c4262e"
                    />
                    <ReactTooltip place="top" type="light" effect="solid" className={styles['tooltip-class']}>
                      An error occured while processing an event to nominations
                    </ReactTooltip>
                  </div>
                );
              },
            },
            {
              Header: 'Delete',
              Cell: (props: any) => {
                const schedule = props.cell.row.original;
                if (schedule.canceled) {
                  return <></>;
                }
                return (
                  <div className={styles['delete-cell']}>
                    <Button
                      className={styles['btn-delete']}
                      onClick={() => {
                        setActiveDialog('CANCEL');
                        setSelectedNominationSchedule(schedule);
                      }}
                    >
                      <FontAwesomeIcon icon={banIcon} />
                    </Button>
                  </div>
                );
              },
            },
            {
              Header: 'Edit',
              Cell: (props: any) => {
                const schedule = props.cell.row.original;
                if (schedule.canceled) {
                  return <></>;
                }
                if (editNominationScheduleId != schedule.id) {
                  return (
                    <div className="edit-cell">
                      <Button
                        className={editbuttonstyles['btn-edit']}
                        onClick={() => {
                          setEditNominationScheduleId(schedule.id);
                          //add entry to the dictionary
                          const selectedRecEvent = recurringNomination.find(
                            (x) => x.id == props.cell.row.original.recurringNominationTemplateId,
                          );
                          if (!nominationScheduleCustomValuesDictionary.hasOwnProperty(schedule.id)) {
                            nominationScheduleCustomValuesDictionary[schedule.id] = {
                              amount: selectedRecEvent && selectedRecEvent?.amount ? selectedRecEvent.amount : 0,
                              bst: schedule?.bst ? schedule.bst : '',
                              eta: schedule?.eta ? schedule.eta : '',
                              etd: schedule?.etd ? schedule.etd : '',
                              bstValidation: '',
                              etaValidation: '',
                              etdValidation: '',
                              deliveryMode:
                                selectedRecEvent && selectedRecEvent?.deliveryMode ? selectedRecEvent.deliveryMode : '',
                              locationId:
                                selectedRecEvent && selectedRecEvent?.locationId ? selectedRecEvent.locationId : '',
                            };
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={edit} />
                      </Button>
                    </div>
                  );
                } else {
                  return (
                    <div className="save-cell">
                      <Button
                        className={styles['btn-save']}
                        disabled={false}
                        onClick={() => {
                          const valuess = nominationScheduleCustomValuesDictionary[schedule.id];
                          const saveDisabled =
                            valuess.bstValidation !== '' ||
                            valuess.etaValidation !== '' ||
                            valuess.etdValidation !== '';
                          if (!saveDisabled) {
                            setActiveDialog('SAVE');
                            setSelectedNominationSchedule(schedule);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={save} />
                      </Button>
                    </div>
                  );
                }
              },
            },
            {
              Header: 'edit',
              Cell: (props: any) => {
                const schedule = props.cell.row.original;
                if (schedule.canceled) {
                  return <></>;
                }
                return (
                  <div className={styles['complete-cell']}>
                    <Button
                      className={styles['btn-complete']}
                      onClick={() => {
                        setActiveDialog('PUSHTONOMINATION');
                        setSelectedNominationSchedule(schedule);
                      }}
                    >
                      <FontAwesomeIcon icon={gas} />
                    </Button>
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [recurringNomination, nominationSchedule, editNominationScheduleId],
  );
  const history = useHistory();
  const [isCollapseableInfoOpen, setCollapseableInfoOpen] = React.useState(false);

  if (recurringNominationError)
    return recurringNominationErrorDetails ? <Error error={recurringNominationErrorDetails} /> : null;
  if (!recurringNominationLoaded) return <Loading />;

  const renderCollapseableInfo = () => {
    return (
      <div className={styles['collapse-div']}>
        {
          <div
            onClick={() => setCollapseableInfoOpen(!isCollapseableInfoOpen)}
            className={isCollapseableInfoOpen ? styles['collapse-div-expanded'] : styles['collapse-div-not-expanded']}
          >
            <span className={styles['expander-icon']}>
              {!isCollapseableInfoOpen && <IIcon />}
              {isCollapseableInfoOpen && <FontAwesomeIcon icon={deleteIcon} size={'lg'} />}
            </span>
          </div>
        }
        <Collapse isOpen={isCollapseableInfoOpen}>
          <Card>
            <CardBody>
              <div className={styles['expander-div']}>
                <div className={styles['expander-left']}>
                  <span className={styles['expander-left-icon']}>
                    <IIcon />
                  </span>
                </div>
                <div className={styles['expander-right']}>
                  <div className={styles['expander-top']}>
                    The recurring nominations generator is designed to automatically issue nominations for any recurring
                    bunkerings. A queue of single events are created based on the input in the form. The buyer specifies
                    at which time before bunkering start time the event is to automatically create a pre-filled
                    nomination. Any changes to the form will change the queue of future single events from which the
                    nominations are created.
                  </div>
                  <div className={styles['expander-bottom']}>
                    IMPORTANT! Please note that actual nominations will be sent to the supplier. Great care must be
                    taken to capture and communicate any deviations.
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  };

  return (
    <div className={styles['root']}>
      {renderCollapseableInfo()}
      {activeDialog && (
        <Dialog
          type="medium"
          titleIcon={getDialogTitleIcon(activeDialog)}
          className={styles['dialog-styles']}
          title={
            activeDialog === 'CANCEL'
              ? 'You are about to cancel this single event in the recurring series..'
              : activeDialog === 'PUSHTONOMINATION'
              ? 'You are about to create a nomination from this single event. Please be aware that this will create an actual nomination and the supplier will be notified. It also deletes the single event form the recurring nomination series.'
              : activeDialog === 'SAVE'
              ? 'You are about to change this single event in the recurring series.'
              : `${activeDialog}`
          }
          onCloseDialog={() => {
            setActiveDialog(undefined);
          }}
          showDialog={activeDialog !== undefined}
        >
          {activeDialog === 'CANCEL' && (
            <div>
              <p>{`Please be aware that changes done in the recurring series will delete all current and create new events which might include already canceled events.`}</p>
              <div className={styles['dialog-buttons-group']}>
                <Button
                  className={styles['dialog-button']}
                  primary
                  preventDoubleClick
                  onClick={() => {
                    if (selectedNominationSchedule) cancelNominationScheduleCallback(selectedNominationSchedule.id);
                    setActiveDialog(undefined);
                  }}
                >
                  {`Yes, cancel`}
                </Button>
                <div
                  className={styles['dialog-button-cancel']}
                  onClick={() => {
                    setActiveDialog(undefined);
                    setSelectedNominationSchedule(undefined);
                  }}
                >
                  No, don't cancel
                </div>
              </div>
            </div>
          )}

          {activeDialog === 'PUSHTONOMINATION' && (
            <div>
              <div className={styles['dialog-buttons-group']}>
                <Button
                  className={styles['dialog-button']}
                  primary
                  preventDoubleClick
                  onClick={() => {
                    if (selectedNominationSchedule) pushToNominationScheduleCallback(selectedNominationSchedule.id);
                    setActiveDialog(undefined);
                  }}
                >
                  {`Push to nomination`}
                </Button>
                <div
                  className={styles['dialog-button-cancel']}
                  onClick={() => {
                    setActiveDialog(undefined);
                    setSelectedNominationSchedule(undefined);
                  }}
                >
                  Cancel
                </div>
              </div>
            </div>
          )}

          {activeDialog === 'SAVE' && (
            <div>
              <div className={styles['dialog-buttons-group']}>
                <Button
                  className={styles['dialog-button']}
                  primary
                  preventDoubleClick
                  onClick={() => {
                    if (selectedNominationSchedule) {
                      onEditNominationSchedule(
                        selectedNominationSchedule.id,
                        selectedNominationSchedule,
                        nominationScheduleCustomValuesDictionary.hasOwnProperty(selectedNominationSchedule.id)
                          ? nominationScheduleCustomValuesDictionary[selectedNominationSchedule.id]
                          : ({} as ScheduleCustomValues),
                      );
                      setEditNominationScheduleId('');
                      setActiveDialog(undefined);
                    }
                  }}
                >
                  {`Save changes`}
                </Button>
                <div
                  className={styles['dialog-button-cancel']}
                  onClick={() => {
                    setActiveDialog(undefined);
                    setSelectedNominationSchedule(undefined);
                  }}
                >
                  Discard changes
                </div>
              </div>
            </div>
          )}
        </Dialog>
      )}
      <FuelBossTable
        showSortIcons={false}
        showFilterSearch={false}
        headers={columns}
        showPagination={false}
        renderRowSubComponent={renderRowSubComponent}
        tableRows={recurringNomination}
        filters={[{ id: 'deliveryMode', value: deliveryModes }]}
        sortBy={[{ id: sortBy === 'time' ? 'bst' : 'fbStatus', desc: true }]}
        onRowClick={(row: RecurringNomination) => {
          history.push(`/recurringevents/${false ? 'archived' : 'recurring'}/${row.id}`);
        }}
        rowStyle={(row: RecurringNomination) => ({
          //row highlighting selected
          //backgroundColor: row.id == expandedRecurringNominationId? '#e5f5fb' : 'white',
        })}
      />
      {recurringNominationLoading && <Loading small />}
      {recurringNominationMetadata?.hasNextPage && (
        <Button className={styles['show-more-button']} onClick={() => setPageSize(pageSize + 5)}>
          Show more recurring events <FontAwesomeIcon icon={downArrowIcon} />
        </Button>
      )}
    </div>
  );

  //  a function that will render our row sub components for contracts
  function renderRowSubComponent(row: any = null): JSX.Element {
    if (row == null || row == undefined) return <div></div>;
    else if (nominationScheduleLoading) {
      return <Loading small type="nomination schedules" />;
    } else if (!nominationSchedule || nominationSchedule.length == 0) {
      return <div className={styles['recurringnomination-noevents']}>No scheduled events found</div>;
    } else {
      return (
        <div className={styles['recurringnomination-subcomponent']}>
          <FuelBossTable
            showSortIcons={false}
            showFilterSearch={false}
            headers={scheduleColumns}
            showPagination={false}
            tableRows={nominationSchedule}
            //filters={[{ id: 'deliveryMode', value: deliveryModes }]}
            //sortBy={[{ id: sortBy === 'time' ? 'bst' : 'fbStatus', desc: true }]}
            onRowClick={(row: NominationSchedule) => {}}
            rowStyle={(row: NominationSchedule) => ({
              backgroundColor: 'white',
              textDecoration: row.canceled ? 'line-through' : 'initial',
              color: row.canceled ? '#999999' : '#0c202b',
              cursor: 'default',
            })}
          />
          {nominationScheduleLoading && <Loading small />}
          {nominationScheduleMetadata?.hasNextPage && (
            <Button
              className={styles['show-more-button']}
              onClick={() => setPageSizeNominationSchedule(pageSizeNominationSchedule + 5)}
            >
              Show more schedule events <FontAwesomeIcon icon={downArrowIcon} />
            </Button>
          )}
        </div>
      );
    }
  }
};

export default OverviewRecurringList;
