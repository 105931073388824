import React, { useEffect } from 'react';
import { PowerBIEmbed, } from 'powerbi-client-react';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import styles from './EmbeddedReport.module.scss';
import { Loading } from '../../../components/Loading';

interface OwnProps {
  reportId: string;
}

const EmbeddedReport: React.FC<OwnProps> = ({ reportId }) => {

    const [reportLoading, setReportLoading] = React.useState(false);
    const [reportLoadingMessage, setReportLoadingMessage] = React.useState("VAP report");

    // API end-point url to get embed config for a report
    const getReportConfigUrl = '/api/VAPReport/reportconfig/';   

    // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
    const [reportConfig, setReportConfig] = React.useState<models.IReportEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: undefined,
    });

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        ['loaded', function () {
            //console.log('Report has loaded');
        }],
        ['rendered', function () {
            //console.log('Report has rendered');
        }],
        ['error', async function (event?: service.ICustomEvent<any>) {
            if (event) {
                console.log('Report has error');
                console.error(event.detail);
                //const error = event.detail;
                // if the error is TokenExpired refresh the token
                // else, show error dialog
                if(event.detail.message === models.CommonErrorCodes.TokenExpired) {
                    // refresh token
                    const config = await fetchReportConfig();
                    if(config.accessToken){
                        console.log('Report setting new accesstoken');
	                    var newAccessToken = config.accessToken;
	                    // Set new access token to the report
                        //@ts-ignore
	                    window.report.setAccessToken(newAccessToken);
                    }
                }
            }
        }]
    ]);


    const fetchReportConfig = async () => {
        if(reportConfig.embedUrl == undefined){
            setReportLoading(true);
        }
        const reportConfigResponse = await fetch(getReportConfigUrl + reportId);
        //console.log("Change report - load new config");
        if (!reportConfigResponse.ok) {
            var errorMessage = `Failed to load report. Status: `;
            if (reportConfigResponse.status === 401) {
                errorMessage = errorMessage + "Not authorized to see this report";
            } else if (reportConfigResponse.status === 404) {
                errorMessage = errorMessage + "Report not found";
            } else {
                errorMessage = errorMessage + `${reportConfigResponse.status} ${reportConfigResponse.statusText}`;
            }
            console.error(errorMessage);
            setReportLoadingMessage(errorMessage);
            setTimeout(() => {
                setReportConfig({
                    ...reportConfig,
                    embedUrl: undefined,
                    accessToken: undefined
                });
                setReportLoading(false);
                setReportLoadingMessage("VAP report");
            }, 3000);
            return;
        }
        const _reportConfig = await reportConfigResponse.json();

        // Set the fetched embedUrl and embedToken in the report config
        setReportConfig({
            ...reportConfig,
            embedUrl: _reportConfig.embeddedUrl,
            accessToken: _reportConfig.accessToken
        });

        setReportLoading(false);
        return _reportConfig;
    };

    //Component did mount
    useEffect(() => {
        //console.log("REPORT-ID Changed: " + reportId);
        fetchReportConfig();
    }, [reportId]);

    if (reportLoading) {
        return <Loading type={reportLoadingMessage}/>;
    } else {
        return (
            <div className={styles['embeddedreport-wrapper']}>
                <PowerBIEmbed
                    embedConfig={reportConfig}
                    eventHandlers={eventHandlersMap}
                    cssClassName={styles['embeddedreport']}
                    getEmbeddedComponent={
                        (embeddedReport: Embed) => {
                            // @ts-ignore
                            window.report = embeddedReport;
                        }
                    }
                />
            </div>
        );
    }
};

export default EmbeddedReport;
