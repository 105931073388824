import { PaginationMeta } from '../../helpers/PaginationMeta';
import { ErrorActionPayload } from '../../models/ErrorAction';
import { SpotEnquiry } from './models';

export const ARCHIVED_SPOT_LOADING = 'spot/archived/loading';
export type ARCHIVED_SPOT_LOADING = typeof ARCHIVED_SPOT_LOADING;

export const GET_ARCHIVED_SPOT = 'spot/archived/get';
export type GET_ARCHIVED_SPOT = typeof GET_ARCHIVED_SPOT;

export const ARCHIVED_SPOT_FETCH_ERROR = 'spot/archived/fetchError';
export type ARCHIVED_SPOT_FETCH_ERROR = typeof ARCHIVED_SPOT_FETCH_ERROR;

export type ArchivedSpotActions =
  | {
      type: GET_ARCHIVED_SPOT;
      payload: { data: SpotEnquiry[]; metadata?: PaginationMeta };
    }
  | {
      type: ARCHIVED_SPOT_LOADING;
    }
  | {
      type: ARCHIVED_SPOT_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
