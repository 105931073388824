import React from 'react';
import styles from './RejectModal.module.scss';
import { Contract } from '../../../../../store/Contract/models';
import { Button, Form, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Field, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { initialValues } from '../contractsFormInitialValues';
const minusCircle = findIconDefinition({ prefix: 'fal', iconName: 'minus-circle' });

interface OwnProps {
  toggle: () => void;
  showModal: boolean;
  contract: Contract;
  updateContract: (contract: Contract) => void;
}

const RejectModal: React.FC<OwnProps> = ({ toggle, showModal, updateContract, contract }) => {
  const onFormSave = (formikProps: FormikProps<Contract>) => {
    formikProps.submitForm();
    if (contract) {
      contract.rejectReason = formikProps.values.rejectReason;
      contract.status = 'Rejected';
    }
    if (formikProps.isValid) {
      updateContract(contract);
      toggle();
    }
  };

  return (
    <Modal isOpen={showModal} toggle={toggle} className={styles['position']}>
      <div className={styles['wrapper']}>
        <ModalBody className={styles['body']}>
          <div className={styles['icon']}>
            <FontAwesomeIcon icon={minusCircle} size={'3x'} color="#c4262e" />
          </div>

          <div className={styles['content-wrapper']}>
            <div className={styles['header']}>Reject contract</div>
            <div className={styles['content']}>Why are you rejecting the contract?</div>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                rejectReason: Yup.string().min(0, 'Enter reject reason').required('Required'),
              })}
              onSubmit={(values, actions: any) => {
                actions.validateForm();
                actions.setSubmitting(false);
              }}
            >
              {(formikProps: FormikProps<Contract>) => (
                <div>
                  <Form>
                    <div className="contractFormMain">
                      <Field
                        className={styles['textarea']}
                        // as={Field}
                        type="textarea"
                        component="textarea"
                        id={'rejectReason'}
                        name={'rejectReason'}
                        placeholder={''}
                        readOnly={false}
                        rows="5"
                        cols="44"
                        maxLength={500}
                      />
                    </div>
                  </Form>

                  <Button className={styles['reject-button']} color="primary" onClick={() => onFormSave(formikProps)}>
                    Reject
                  </Button>
                  <Button color="primary" className={styles['cancel-button']} onClick={toggle}>
                    Cancel
                  </Button>
                </div>
              )}
            </Formik>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default React.memo(RejectModal);
