import {
  DeliveryMode,
  EnergyUnit,
  getAllowedThirdPartyContactRoles,
  getCompanyDetails,
  getNominationNegotiationFieldPermissions,
  ICompany,
  IContract,
  IPipeline,
  // IPromptNomination,
  IShipWithId,
  isScheduler,
  isSchedulerCaptain,
  IThirdPartyContact,
  IThirdPartyOptions,
  IUserProfile,
  LocationService,
  PriceUnit,
  QuantityUnit,
  useLocationTimeZone,
  isAdmin,
  isCustomer,
  IShipWithCompanyId,
} from '@teqplay/chorus-components';
import {
  Button,
  Checkbox,
  DateTimePicker,
  formatDateTime,
  FormElement,
  FormErrorMessage,
  FormFieldWrapper,
  FormLabel,
  FormWrapper,
  MultiSelectionBox,
  MultiSelectOption,
  NumberInput,
  SelectionBox,
  TextArea,
  TextField,
} from '@teqplay/teqplay-ui';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ENERGY_UNITS,
  PRICE_UNITS,
  QUANTITY_UNITS,
  comparingNominationFields,
  AlternativeFuelSelector,
  LocationSearch,
  ValidationWarning,
  DeliveryModeSelection,
  AddThirdPartyContactDialog,
} from '@teqplay/chorus-components';
import SummaryRow from '../NominationSummary/SummaryRow';
import {
  RecurringNomination,
  SCHEDULERECURRANCE_TYPE,
  ScheduleRecurrenceType,
} from '../../../../../store/RecurringNomination/models';
import { comparingRecurringNominationFields } from '../Utils/compareRecurringNominations';
import { validateNominationBST } from '../Validators/recurringNominationValidation';
import { canUserEditContract } from '../Utils/nominationPermissions';
import { FieldPermissionsRecurring } from '../../../../../store/FieldPermissionsRecurringForm/models';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { getFieldPermissionRecurring } from '../../../../../store/FieldPermissionsRecurringForm/actions';
import { Loading } from '../../../../../components/Loading';

interface CustomerNominationFormProps {
  currentNomination: RecurringNomination;
  previousNomination?: RecurringNomination;
  creatingNewNomination: boolean;
  showDeliveryModeSelection: boolean;
  errors?: any;
  currentUser: IUserProfile;
  locationService: LocationService;
  selectedVendor?: ICompany;
  contracts: IContract[];
  bunkerShips: IShipWithCompanyId[];
  pipelines: IPipeline[];
  enableAlternativeFuelSelector: boolean;
  customerCompanyName: string;
  companies: ICompany[];
  thirdPartyContacts: IThirdPartyContact[];
  nominationContacts: IThirdPartyContact[];
  saveThirdPartyContact: (data: IThirdPartyContact) => void;
  getPreviousValueLabel: (
    field: keyof RecurringNomination,
    nominationValues?: RecurringNomination,
    previousNominationValues?: RecurringNomination,
  ) => any;
  onChangeFormValue: (field: string, value: string | boolean | number | object | undefined | null) => void;
  setAllValues: (nomination: RecurringNomination) => void;
  onChangeDateTimeValue: (isoDate: string | null, inputValue: string, isValid: boolean, fieldName?: string) => void;
  setMultipleValues: (fieldsToUpdate: { field: string; value: string | null | any[] }[]) => void;
  nominationEventToCompareWith?: RecurringNomination;
  disabled?: boolean;
}

const SupplierNominationForm: React.FunctionComponent<CustomerNominationFormProps> = (props) => {
  const {
    getPreviousValueLabel,
    currentNomination,
    onChangeDateTimeValue,
    onChangeFormValue,
    currentUser,
    previousNomination,
    errors,
    selectedVendor,
    contracts,
    enableAlternativeFuelSelector,
    nominationEventToCompareWith,
    disabled,
  } = props;

  const [isActiveAddContactFormDialog, setIsActiveAddContactFormDialog] = useState<boolean>(false);

  const [thirdPartyOptions, setThirdPartyOptions] = useState<IThirdPartyOptions[]>([]);
  const [multiSelectItems, setMultiSelectitems] = useState<IThirdPartyOptions[]>();

  const [scheduleRecurrance, setScheduleRecurrance] = useState<ScheduleRecurrenceType | undefined>(undefined);

  useEffect(() => {
    !!multiSelectItems &&
      props.onChangeFormValue(
        'vendorThirdPartyContactIds',
        Object.values(multiSelectItems)
          .map((e) => e.value)
          .join(','),
      );
  }, [multiSelectItems]);

  useEffect(() => {
    setScheduleRecurrance(currentNomination.scheduleRecurrenceType);
    if (!fieldPermissionRecurringIsLoaded) {
      getFieldPermissionRecurringCallback();
    }
  }, []);

  const [newContactFormValues, setNewContactFormValues] = useState<IThirdPartyContact>({
    _id: '',
    companyId: currentUser.companyId,
    companyName: '',
    email: '',
    name: '',
    role: '',
  });

  useEffect(() => {
    setThirdPartyOptions(
      props.thirdPartyContacts.map((contact) => ({
        text: `${contact.name} <${contact.email}>`,
        value: contact._id,
      })),
    );
  }, [props.thirdPartyContacts]);

  useEffect(() => {
    if (props.nominationContacts.length > 0) {
      setMultiSelectitems(
        props.nominationContacts.map((contact) => ({
          text: `${contact.name} <${contact.email}>`,
          value: contact._id,
        })),
      );
    }
  }, [props.nominationContacts]);

  const timezone = useLocationTimeZone(currentNomination.locationId, props.locationService);

  const {
    fieldPermissionRecurringLoading,
    fieldPermissionRecurringDetailsError,
    fieldPermissionRecurringErrorDetails,
    fieldPermissionRecurring,
    fieldPermissionRecurringIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    fieldPermissionRecurringLoading: state.fieldPermissionRecurringForm.loading,
    fieldPermissionRecurringDetailsError: state.fieldPermissionRecurringForm.error,
    fieldPermissionRecurringErrorDetails: state.fieldPermissionRecurringForm.errorDetails,
    fieldPermissionRecurring: state.fieldPermissionRecurringForm.data,
    fieldPermissionRecurringIsLoaded: state.fieldPermissionRecurringForm.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getFieldPermissionRecurringCallback = React.useCallback(
    () => dispatch(getFieldPermissionRecurring('RecurringNomination')),
    [],
  );

  const fieldPermissions = fieldPermissionRecurring;

  const previousBunkerShip = props.bunkerShips.find(
    (bunkerShip) => bunkerShip._id === getPreviousValueLabel('bunkerShipId', currentNomination, previousNomination),
  );

  const previousPipeline = props.pipelines.find(
    (pipe) => pipe._id === getPreviousValueLabel('pipelineId', currentNomination, previousNomination),
  );

  const previousContract = props.contracts.find(
    (contract) => contract._id === getPreviousValueLabel('contractId', currentNomination, previousNomination),
  );

  const supplierOffersAltFuels = !isEmpty(selectedVendor ? selectedVendor.altFuelTypes : []);

  const allowedThirdPartyRoleOptions = getAllowedThirdPartyContactRoles(currentUser.roles);

  function togglePriceEnabled() {
    if (currentNomination.priceEnabled) {
      props.setAllValues({
        ...currentNomination,
        priceEnabled: false,
        price: '',
        priceUnit: '',
        //energyUnit: undefined
      });
    } else {
      props.onChangeFormValue('priceEnabled', true);
    }
  }

  function toggleAddContactDialog(isDialogActive: boolean) {
    setNewContactFormValues({
      _id: '',
      companyId: currentUser.companyId,
      companyName: '',
      email: '',
      name: '',
      role: '',
    });
    setIsActiveAddContactFormDialog(isDialogActive);
  }

  const isContractHolder = currentUser.companyId === currentNomination.companyId;

  const counterCompanyName =
    true && isScheduler(props.currentUser.roles)
      ? currentNomination.companyId
        ? getCompanyDetails(props.companies, currentNomination.companyId).companyName
        : '-'
      : currentNomination.vendorCompanyId
      ? getCompanyDetails(props.companies, currentNomination.vendorCompanyId).companyName
      : '-';

  const contactIdFieldName: 'vendorThirdPartyContactIds' | 'customerThirdPartyContactIds' =
    false && isContractHolder ? 'customerThirdPartyContactIds' : 'vendorThirdPartyContactIds';

  const contractTermsFieldName: 'supplierTerms' | 'buyerTerms' =
    false && isContractHolder ? 'buyerTerms' : 'supplierTerms';

  const fieldsThatAreDifferent = comparingRecurringNominationFields(currentNomination, nominationEventToCompareWith);

  if (!fieldPermissionRecurringIsLoaded) {
    return <Loading type="Permissions" />;
  }
  return (
    <div className={'prompt-form nomination-form supplier-form'}>
      {/* Renders the onbehalf selection if passed through the children */}
      {props.children}

      <section className="form-section">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">1</div>
            <h2 className="">General information</h2>
          </div>
        </div>

        <section className="summary-section">
          <div className="section-content">
            <div className="single-row">
              <div className="rows">
                <SummaryRow
                  label={isScheduler(props.currentUser.roles) ? 'Buyer' : 'Supplier'}
                  hideNewIndicator
                  currentValue={counterCompanyName}
                  emptyValueMessage={'-'}
                  isInitialValue={false}
                />
              </div>
            </div>
          </div>
        </section>

        <FormWrapper>
          <FormElement style={{ display: 'flex' }}>
            <LocationSearch
              locationService={props.locationService}
              onChange={(value) => {
                props.onChangeFormValue('locationId', value);
              }}
              label="Delivery point *"
              placeholder={'Select port...'}
              disabled={disabled || !fieldPermissions.locationId.write}
              fieldName="locationId"
              error={errors ? get(errors, 'locationId') : undefined}
              value={
                !fieldPermissions.location && !currentNomination.locationId ? '-' : currentNomination.locationId || ''
              }
              prevValue={previousNomination ? previousNomination.locationId : undefined}
              comparingNominationIsDifferent={fieldsThatAreDifferent.includes('locationId')}
            />
          </FormElement>

          <FormElement className={fieldsThatAreDifferent.includes('location') ? 'delegated-is-different' : ''}>
            <FormLabel fieldName="location">Location</FormLabel>
            <TextField
              fieldName="location"
              disabled={disabled || !fieldPermissions.location.write}
              placeholder={'Detailed description, e.g. berth, terminal, anchorage etc.'}
              value={
                !fieldPermissions.location && !currentNomination.location
                  ? '-'
                  : currentNomination.location
                  ? currentNomination.location
                  : ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.onChangeFormValue('location', event.target.value);
              }}
            ></TextField>
            <div className="form-field-message">
              <FormLabel fieldName="prevlocation" strikeThrough>
                {getPreviousValueLabel('location', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'location') : undefined} />
            </div>
          </FormElement>

          <FormElement>
            <FormLabel fieldName="agent">Agent</FormLabel>
            <TextField
              fieldName="agent"
              disabled={disabled || !fieldPermissions.agent.write}
              value={
                !fieldPermissions.agent && !currentNomination.agent
                  ? '-'
                  : currentNomination.agent
                  ? currentNomination.agent
                  : ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.onChangeFormValue('agent', event.target.value);
              }}
            ></TextField>
            <div className="form-field-message">
              <FormLabel fieldName="prevAgent" strikeThrough>
                {getPreviousValueLabel('agent', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'agent') : undefined} />
            </div>
          </FormElement>

          {false && isContractHolder && (
            <FormElement>
              <FormLabel fieldName="buyerRef">Buyer reference</FormLabel>
              <TextField
                fieldName="buyerRef"
                disabled={disabled || !fieldPermissions.buyerRef.write}
                placeholder={'E.g. PO no. or similar'}
                value={
                  !fieldPermissions.buyerRef && !currentNomination.buyerRef
                    ? '-'
                    : currentNomination.buyerRef
                    ? currentNomination.buyerRef
                    : ''
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChangeFormValue('buyerRef', event.target.value);
                }}
              ></TextField>
              <div className="form-field-message">
                <FormLabel fieldName="prevBuyer" strikeThrough>
                  {getPreviousValueLabel('buyerRef', currentNomination, previousNomination)}
                </FormLabel>
                <FormErrorMessage errorMessage={errors ? get(errors, 'buyerRef') : undefined} />
              </div>
            </FormElement>
          )}
        </FormWrapper>
      </section>

      <section className="form-section">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">2</div>
            <h2 className="">Quantity and delivery</h2>
          </div>
        </div>

        <FormWrapper>
          <FormElement className={fieldsThatAreDifferent.includes('amount') ? 'delegated-is-different' : ''}>
            <FormLabel fieldName="amount">Total quantity *</FormLabel>
            <div className="flex amount-and-quantityUnit-container">
              <FormFieldWrapper>
                <NumberInput
                  name={'amount'}
                  maxDecimals={3}
                  value={
                    !fieldPermissions.amount && !currentNomination.amount
                      ? '-'
                      : currentNomination.amount
                      ? currentNomination.amount
                      : ''
                  }
                  disabled={disabled || !fieldPermissions.amount.write}
                  onChangeFormValue={props.onChangeFormValue}
                />
              </FormFieldWrapper>
              <SelectionBox<{ value: QuantityUnit; text: string }>
                fieldName={'quantityUnit'}
                placeholder={'Select quantity'}
                value={currentNomination?.quantityUnit}
                renderFieldValue={(selectedItem) => {
                  return `${selectedItem.text}`;
                }}
                valueKey="value"
                displayKey="text"
                disabled={disabled || !fieldPermissions.quantityUnit.write}
                onChangeValue={(unit) => {
                  props.onChangeFormValue('quantityUnit', unit?.value);
                }}
                options={QUANTITY_UNITS.sort((a, b) => (a.text > b.text ? -1 : 1))}
                renderOption={(option) => {
                  return (
                    <div className="shipsearch-select-option">
                      <b>{option.text}</b>
                    </div>
                  );
                }}
              ></SelectionBox>
            </div>

            <div className="form-field-message">
              <FormLabel fieldName="prevAmount" strikeThrough>
                {getPreviousValueLabel('amount', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'amount') : undefined} />
            </div>
          </FormElement>

          <FormElement className={fieldsThatAreDifferent.includes('tolerance') ? 'delegated-is-different' : ''}>
            <FormLabel fieldName="tolerance">
              Tolerance<div className="label-unit">(%)</div>
            </FormLabel>
            <NumberInput
              name="tolerance"
              maxDecimals={3}
              disabled={disabled || !fieldPermissions.tolerance.write}
              value={
                !fieldPermissions.tolerance && !currentNomination?.tolerance
                  ? '-'
                  : currentNomination?.tolerance
                  ? currentNomination.tolerance
                  : ''
              }
              onChangeFormValue={props.onChangeFormValue}
            ></NumberInput>
            <div className="form-field-message">
              <FormLabel fieldName="prevTolerance" strikeThrough>
                {getPreviousValueLabel('tolerance', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'tolerance') : undefined} />
            </div>
          </FormElement>

          {supplierOffersAltFuels && enableAlternativeFuelSelector && (
            <>
              {/* <FormElement>
                <FormLabel fieldName="altFuelTolerance ">
                  Alternative Fuel Tolerance <div className="label-unit">(%)</div>
                </FormLabel>
                <TextField
                  fieldName="altFuelTolerance "
                  disabled={!fieldPermissions.altFuelTolerance.write}
                  value={
                    !fieldPermissions.altFuelTolerance && !currentNomination?.altFuelTolerance
                      ? '-'
                      : currentNomination?.altFuelTolerance
                      ? currentNomination.altFuelTolerance
                      : ''
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeFormValue('altFuelTolerance', event.target.value)
                  }}
                ></TextField>
                <div className="form-field-message">
                  <FormLabel fieldName="prevaltFuelTolerance" strikeThrough>
                    {getPreviousValueLabel('altFuelTolerance', currentNomination, previousNomination)}
                  </FormLabel>
                  <FormErrorMessage errorMessage={errors ? get(errors, 'altFuelTolerance') : undefined} />
                </div>
              </FormElement> */}
              <AlternativeFuelSelector
                totalAmount={currentNomination.amount}
                quantityUnit={currentNomination.quantityUnit}
                onChangeFormValue={props.onChangeFormValue}
                percentage={currentNomination.altFuelPercentage}
                previousPercentage={
                  getPreviousValueLabel('altFuelPercentage', currentNomination, previousNomination) as
                    | number
                    | undefined
                }
                readOnly={disabled || !fieldPermissions.altFuelPercentage.write}
                type={'FORMFIELDS'}
              />
            </>
          )}

          {props.showDeliveryModeSelection && (
            <FormElement>
              <FormLabel fieldName="deliveryMode">Delivery by *</FormLabel>
              <DeliveryModeSelection
                fieldName="deliveryMode"
                availableDeliveryModes={selectedVendor?.deliveryModes}
                selectedDeliveryModes={currentNomination.deliveryMode as DeliveryMode}
                readOnly={disabled || !fieldPermissions.deliveryMode}
                changeDeliveryMode={(deliveryMode) => {
                  if (props.onChangeFormValue) {
                    props.onChangeFormValue('deliveryMode', deliveryMode);
                  }
                }}
              />
              <div className="form-field-message">
                <FormLabel fieldName="prevDeliveryMode" strikeThrough>
                  {getPreviousValueLabel('deliveryMode', currentNomination, previousNomination)}
                </FormLabel>

                <FormErrorMessage errorMessage={errors ? get(errors, 'deliveryMode') : undefined} />
              </div>
            </FormElement>
          )}

          {currentNomination.deliveryMode === 'SHIP' && (
            <FormElement>
              <FormLabel fieldName="bunkerShipId">Bunker vessel</FormLabel>
              <SelectionBox<IShipWithCompanyId>
                fieldName={'bunkerShipId'}
                value={
                  !fieldPermissions.bunkerShipId && !currentNomination.bunkerShipId
                    ? '-'
                    : currentNomination.bunkerShipId || ''
                }
                valueKey="_id"
                displayKey="name"
                sortOptions
                disabled={disabled || !fieldPermissions.bunkerShipId.write}
                onChangeValue={(option) => {
                  props.onChangeFormValue('bunkerShipId', option?._id || null);
                }}
                options={props.bunkerShips.concat(
                  // Add the attribute bunkerShipName, in case the bunker ship is not in the options
                  // Only for read only, cause it should not be selectable
                  (disabled || !fieldPermissions.bunkerShipId) &&
                    currentNomination.bunkerShipId &&
                    currentNomination.attributes?.bunkerShipName
                    ? [
                        {
                          _id: currentNomination.bunkerShipId,
                          imoNumber: '',
                          name: currentNomination.attributes.bunkerShipName,
                          companyId: '',
                        },
                      ]
                    : [],
                )}
                renderOption={(option) => {
                  return (
                    <div className="shipsearch-select-option">
                      <div>
                        <b>{option.name}</b>
                        {option.charterers &&
                          option.charterers.length > 0 &&
                          currentUser.companyId !== option.companyId && (
                            <span className="select-chartered-tag"> Chartered</span>
                          )}
                      </div>
                    </div>
                  );
                }}
              />
              <div className="form-field-message">
                <FormLabel fieldName="bunkerShipId">
                  {(previousBunkerShip ? previousBunkerShip.name : undefined) ||
                    previousNomination?.attributes?.bunkerShipName}
                </FormLabel>
                <FormErrorMessage errorMessage={errors ? get(errors, 'bunkerShipId') : undefined}></FormErrorMessage>
              </div>
            </FormElement>
          )}

          {currentNomination.deliveryMode === 'PIPE' && (
            <FormElement>
              <FormLabel fieldName="pipelineId">Pipeline</FormLabel>
              <SelectionBox<IPipeline>
                fieldName={'pipelineId'}
                value={
                  !fieldPermissions.pipelineId && !currentNomination.pipelineId
                    ? '-'
                    : currentNomination.pipelineId || ''
                }
                valueKey="_id"
                displayKey="name"
                sortOptions
                disabled={disabled || !fieldPermissions.pipelineId.write}
                onChangeValue={(option) => {
                  props.onChangeFormValue('pipelineId', option?._id || null);
                }}
                options={props.pipelines.concat(
                  // Add the attribute pipelineName, in case pipeline is not in the options
                  // Only for read only, cause it should not be selectable
                  (disabled || !fieldPermissions.pipelineId) &&
                    currentNomination.pipelineId &&
                    currentNomination.attributes?.pipelineName
                    ? [getEmptyPipeline(currentNomination.pipelineId, currentNomination.attributes.pipelineName)]
                    : [],
                )}
                renderOption={(option) => {
                  return (
                    <div className="shipsearch-select-option">
                      <div>
                        <b>{option.name}</b>
                      </div>
                    </div>
                  );
                }}
              />
              <div className="form-field-message">
                <FormLabel fieldName="pipelineId">{previousPipeline ? previousPipeline.name : undefined}</FormLabel>

                <FormErrorMessage errorMessage={errors ? get(errors, 'pipelineId') : undefined}></FormErrorMessage>
              </div>
            </FormElement>
          )}
        </FormWrapper>
      </section>

      <section className="form-section form-section__third-party-communication">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">3</div>
            <h2>Communication</h2>
          </div>
        </div>

        <h5 className="third_party-title">
          Third party contacts added here will receive notifications about updates to this nomination
        </h5>
        <FormWrapper>
          <FormElement>
            <FormLabel fieldName={contactIdFieldName}>Ports, agents, etc.</FormLabel>
            <MultiSelectionBox
              fieldName={contactIdFieldName}
              values={multiSelectItems}
              placeholder={'Ports, agents, etc.'}
              valueKey="value"
              searchKey="text"
              searchKeys={['text', 'value']}
              sortOptions
              showClearButton
              renderFieldValue={(selectedItem, removeItem) => {
                return (
                  <MultiSelectOption
                    optionText={selectedItem.text}
                    removeItem={() => {
                      removeItem(selectedItem);
                    }}
                  ></MultiSelectOption>
                );
              }}
              onChangeValue={(selectedItems) => {
                setMultiSelectitems(selectedItems);
              }}
              options={thirdPartyOptions}
              renderOption={(option) => {
                return (
                  <div>
                    <div>{option.text}</div>
                  </div>
                );
              }}
              disabled={disabled}
            />
          </FormElement>
          <FormElement className="justify-center">
            <Button
              disabled={disabled}
              preventDoubleClick
              primary
              onClick={(e) => {
                setIsActiveAddContactFormDialog(true);
              }}
            >
              Add a <strong>new</strong> contact to the list
            </Button>
          </FormElement>
        </FormWrapper>
        {isActiveAddContactFormDialog && (
          <AddThirdPartyContactDialog
            onCloseDialog={() => toggleAddContactDialog(false)}
            showDialog={isActiveAddContactFormDialog}
            title="Add contact person"
            formValues={newContactFormValues}
            allowedThirdPartyRoleOptions={allowedThirdPartyRoleOptions}
            onInputChange={setNewContactFormValues}
            saveThirdPartyContact={props.saveThirdPartyContact}
            callback={toggleAddContactDialog}
          />
        )}
      </section>

      <section className="form-section">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">4</div>
            <h2 className="">Schedule</h2>
          </div>
        </div>

        <h5 className="timezone-title">Timezone: {timezone.text}</h5>

        <section className="summary-section">
          <div className="section-content">
            <div className="single-row">
              <h5 className="summary-section-sub-title">Receiving vessel</h5>
              <div className="rows">
                <SummaryRow
                  className="rv-row"
                  label={'ETA'}
                  formatAsDate
                  currentValue={currentNomination?.eta}
                  formatTimezone={timezone.value}
                  hideNewIndicator
                  emptyValueMessage={'entered by customer'}
                  isInitialValue={false}
                />
                <SummaryRow
                  className="rv-row"
                  label={'ETD'}
                  formatAsDate
                  currentValue={currentNomination?.etd}
                  formatTimezone={timezone.value}
                  hideNewIndicator
                  emptyValueMessage={'entered by customer'}
                  isInitialValue={false}
                />
              </div>
            </div>
          </div>
        </section>

        <FormWrapper>
          <h5 className="summary-section-sub-title center-align">Bunker asset</h5>
          {/* {currentNomination.deliveryMode === 'SHIP' && (
            <FormElement>
              <FormLabel fieldName="bunkershipEta">ETA</FormLabel>
              <FormFieldWrapper>
                <DateTimePicker
                  key={`${currentNomination?.id} bunkershipEta`}
                  name={'bunkershipEta'}
                  onDateTimeChange={onChangeDateTimeValue}
                  disabled={disabled || !fieldPermissions.bunkershipEta.write}
                  icon={<i className="icon-calendar-1" />}
                  placeholder="dd/mm/yyyy hh:mm"
                  value={
                    !fieldPermissions.bunkershipEta && !currentNomination.bunkershipEta
                      ? '-'
                      : currentNomination.bunkershipEta || ''
                  }
                  timeZone={timezone.value}
                  hideDialogHeader
                  useInitialStart={'hour'}
                />
              </FormFieldWrapper>

              <div className="form-field-message">
                <FormLabel fieldName={`prevbunkershipEta`} strikeThrough>
                  {formatDateTime(
                    getPreviousValueLabel(
                      'bunkershipEta',
                      currentNomination,
                      previousNomination
                    ) as string,
                    timezone.value
                  )}
                </FormLabel>
                <FormErrorMessage
                  errorMessage={errors ? get(errors, 'bunkershipEta') : undefined}
                />
              </div>
            </FormElement>
          )} */}

          <FormElement className={fieldsThatAreDifferent.includes('bst') ? 'delegated-is-different' : ''}>
            <FormLabel fieldName={'bst'}>Bunkering Start Time (BST) *</FormLabel>
            <FormFieldWrapper>
              <DateTimePicker
                key={`${currentNomination?.id} bst`}
                name={'bst'}
                onDateTimeChange={onChangeDateTimeValue}
                disabled={disabled || !fieldPermissions.bst.write}
                icon={<i className="icon-calendar-1" />}
                placeholder="dd/mm/yyyy hh:mm"
                value={!fieldPermissions.bst && !currentNomination.bst ? '-' : currentNomination.bst || ''}
                timeZone={timezone.value}
                hideDialogHeader
                useInitialStart={'hour'}
              />
            </FormFieldWrapper>
            <div className="form-field-message">
              <FormLabel fieldName={`prevBst`} strikeThrough>
                {formatDateTime(
                  getPreviousValueLabel('bst', currentNomination, previousNomination) as string,
                  timezone.value,
                )}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'bst') : undefined} />
              <ValidationWarning
                warning={validateNominationBST(currentNomination, props.currentUser.roles)}
                className="form-field-error-message warning"
              />
            </div>
          </FormElement>

          <FormElement>
            <FormLabel fieldName="allowedBunkeringTime">Allowed bunkering time (hours) *</FormLabel>
            <FormFieldWrapper>
              <div className="label-unit in-form-field">(hours)</div>
              <NumberInput
                key={`${currentNomination?.id} allowedBunkeringTime`}
                name={'allowedBunkeringTime'}
                maxDecimals={3}
                value={
                  !fieldPermissions.allowedBunkeringTime && !currentNomination?.allowedBunkeringTime
                    ? '-'
                    : currentNomination?.allowedBunkeringTime
                    ? currentNomination.allowedBunkeringTime
                    : ''
                }
                disabled={disabled || !fieldPermissions.allowedBunkeringTime?.write}
                onChangeFormValue={props.onChangeFormValue}
              />
            </FormFieldWrapper>
            <div className="form-field-message">
              <FormLabel fieldName="prevAllowedBunkeringTime" strikeThrough>
                {getPreviousValueLabel('allowedBunkeringTime', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'allowedBunkeringTime') : undefined} />
            </div>
          </FormElement>

          {/* {currentNomination.deliveryMode === 'SHIP' && (
            <FormElement>
              <FormLabel fieldName={'bunkershipEtd'}>ETD</FormLabel>
              <FormFieldWrapper>
                <DateTimePicker
                  key={`${currentNomination?.id} bunkershipEtd`}
                  name={'bunkershipEtd'}
                  onDateTimeChange={onChangeDateTimeValue}
                  disabled={disabled || !fieldPermissions.bunkershipEtd.write}
                  icon={<i className="icon-calendar-1" />}
                  placeholder="dd/mm/yyyy hh:mm"
                  value={
                    !fieldPermissions.bunkershipEtd && !currentNomination.bunkershipEtd
                      ? '-'
                      : currentNomination.bunkershipEtd || ''
                  }
                  timeZone={timezone.value}
                  hideDialogHeader
                  useInitialStart={'hour'}
                />
              </FormFieldWrapper>
              <div className="form-field-message">
                <FormLabel fieldName={`prevbunkershipEtd`} strikeThrough>
                  {formatDateTime(
                    getPreviousValueLabel(
                      'bunkershipEtd',
                      currentNomination,
                      previousNomination
                    ) as string,
                    timezone.value
                  )}
                </FormLabel>
                <FormErrorMessage
                  errorMessage={errors ? get(errors, 'bunkershipEtd') : undefined}
                />
              </div>
            </FormElement>
          )} */}
        </FormWrapper>

        {/* New fields */}
        <FormWrapper>
          <FormElement>
            <FormLabel fieldName="scheduleRecurrenceType">Bunkering occurs *</FormLabel>

            <SelectionBox<{ value: ScheduleRecurrenceType; text: string }>
              fieldName={'scheduleRecurrenceType'}
              placeholder={'Select schedule...'}
              // @ts-ignore
              value={currentNomination ? currentNomination.scheduleRecurrenceType : ''}
              renderFieldValue={(selectedItem) => {
                return `${selectedItem.text}`;
              }}
              valueKey="value"
              displayKey="text"
              sortOptions
              disabled={disabled || !fieldPermissions.scheduleRecurrenceType.write}
              onChangeValue={(unit) => {
                onChangeFormValue('scheduleRecurrenceType', unit?.value);
                setScheduleRecurrance(unit?.value);
                if (unit?.value == ScheduleRecurrenceType.Daily) {
                } else if (unit?.value == ScheduleRecurrenceType.Weekly) {
                }
              }}
              options={SCHEDULERECURRANCE_TYPE}
              renderOption={(option) => {
                return (
                  <div className="shipsearch-select-option">
                    <b>{option.text}</b>
                  </div>
                );
              }}
            ></SelectionBox>
            <div className="form-field-message">
              <FormLabel fieldName={`prevScheduleRecurrenceType`} strikeThrough>
                {formatDateTime(
                  getPreviousValueLabel('scheduleRecurrenceType', currentNomination, previousNomination) as string,
                  timezone.value,
                )}
              </FormLabel>

              <FormErrorMessage errorMessage={errors ? get(errors, 'scheduleRecurrenceType') : undefined} />
            </div>
          </FormElement>

          {scheduleRecurrance == ScheduleRecurrenceType.Daily && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <FormElement>
                  <div className="form-field-wrapper recurring-section-header">
                    Occurs every {'  '}
                    <NumberInput
                      name="dailySchedule.occursEveryNDays"
                      disabled={disabled || !fieldPermissions.dailySchedule.write}
                      placeholder={'hours or days'}
                      value={
                        !fieldPermissions.dailySchedule && !currentNomination.dailySchedule
                          ? '-'
                          : currentNomination.dailySchedule && currentNomination.dailySchedule.occursEveryNDays
                          ? currentNomination.dailySchedule.occursEveryNDays
                          : ''
                      }
                      onChangeFormValue={(
                        field: string,
                        value: string | number | boolean | object | null | undefined,
                      ) => {
                        onChangeFormValue('dailySchedule.occursEveryNDays', Number(value));
                      }}
                    ></NumberInput>
                    day
                  </div>
                  <div className="form-field-message">
                    <FormErrorMessage
                      errorMessage={errors ? get(errors, 'dailySchedule.occursEveryNDays') : undefined}
                    />
                  </div>
                </FormElement>
              </div>
            </div>
          )}
          {scheduleRecurrance == ScheduleRecurrenceType.Weekly && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <FormElement>
                  <div className="form-field-wrapper recurring-section-header">Select recurring weekday(s)</div>
                </FormElement>
                <FormElement>
                  <FormFieldWrapper>
                    <Button
                      className={`recurring-duration-button-hours ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.monday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        onChangeFormValue(
                          'weeklySchedule.monday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.monday : false,
                        );
                      }}
                    >
                      MON
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.tuesday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        onChangeFormValue(
                          'weeklySchedule.tuesday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.tuesday : false,
                        );
                      }}
                    >
                      TUE
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.wednesday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        onChangeFormValue(
                          'weeklySchedule.wednesday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.wednesday : false,
                        );
                      }}
                    >
                      WED
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.thursday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        onChangeFormValue(
                          'weeklySchedule.thursday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.thursday : false,
                        );
                      }}
                    >
                      THR
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.friday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        onChangeFormValue(
                          'weeklySchedule.friday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.friday : false,
                        );
                      }}
                    >
                      FRI
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.saturday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        onChangeFormValue(
                          'weeklySchedule.saturday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.saturday : false,
                        );
                      }}
                    >
                      SAT
                    </Button>
                    <Button
                      className={`recurring-duration-button-days ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.sunday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        onChangeFormValue(
                          'weeklySchedule.sunday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.sunday : false,
                        );
                      }}
                    >
                      SUN
                    </Button>
                  </FormFieldWrapper>
                </FormElement>
              </div>
            </div>
          )}
          {scheduleRecurrance != undefined && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <div className="times-field-wrapper">
                  <FormElement>
                    <FormLabel fieldName={'startDate'}>Start date for recurrence *</FormLabel>
                    <FormFieldWrapper>
                      <DateTimePicker
                        key={`${currentNomination?.id} startDate`}
                        name={'startDate'}
                        onDateTimeChange={onChangeDateTimeValue}
                        disabled={disabled || !fieldPermissions.startDate.write}
                        icon={<i className="icon-calendar-1" />}
                        placeholder="dd/mm/yyyy hh:mm"
                        //@ts-ignore
                        value={
                          !fieldPermissions.startDate && !currentNomination.startDate
                            ? '-'
                            : currentNomination.startDate || ''
                        }
                        timeZone={timezone.value}
                        hideDialogHeader
                        useInitialStart={'hour'}
                      />
                    </FormFieldWrapper>

                    <div className="form-field-message">
                      <FormLabel fieldName={`prevStartDate`} strikeThrough>
                        {formatDateTime(
                          getPreviousValueLabel('startDate', currentNomination, previousNomination) as string,
                          timezone.value,
                        )}
                      </FormLabel>

                      <FormErrorMessage errorMessage={errors ? get(errors, 'startDate') : undefined} />
                    </div>
                  </FormElement>
                </div>

                <div className="times-field-wrapper">
                  <FormElement>
                    <FormLabel fieldName={'endDate'}>End date for recurrence *</FormLabel>
                    <FormFieldWrapper>
                      <DateTimePicker
                        key={`${currentNomination?.id} endDate`}
                        name={'endDate'}
                        onDateTimeChange={onChangeDateTimeValue}
                        disabled={disabled || !fieldPermissions.endDate.write}
                        icon={<i className="icon-calendar-1" />}
                        placeholder="dd/mm/yyyy hh:mm"
                        //@ts-ignore
                        value={
                          !fieldPermissions.endDate && !currentNomination.endDate
                            ? '-'
                            : currentNomination.endDate || ''
                        }
                        timeZone={timezone.value}
                        hideDialogHeader
                        useInitialStart={'hour'}
                      />
                    </FormFieldWrapper>

                    <div className="form-field-message">
                      <FormLabel fieldName={`prevEndDate`} strikeThrough>
                        {formatDateTime(
                          getPreviousValueLabel('endDate', currentNomination, previousNomination) as string,
                          timezone.value,
                        )}
                      </FormLabel>

                      <FormErrorMessage errorMessage={errors ? get(errors, 'endDate') : undefined} />
                    </div>
                  </FormElement>
                </div>
              </div>
            </div>
          )}
        </FormWrapper>
      </section>

      {/* <section className="form-section">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">4</div>
            <h2 className="">Additional Services</h2>
          </div>
        </div>
        <FormWrapper>
          <FormElement>
            <Checkbox
              onChange={(event: any) => {
                onChangeFormValue('gasUp', event.target.checked)
              }}
              disabled={!fieldPermissions.gasUp.write}
              field={'gasUp'}
              label={'Gas Up'}
              value={currentNomination && currentNomination.gasUp !== undefined ? currentNomination.gasUp : false}
            />
          </FormElement>

          <FormElement>
            <Checkbox
              onChange={(event: any) => {
                onChangeFormValue('coolDown', event.target.checked)
              }}
              disabled={!fieldPermissions.coolDown.write}
              field={'coolDown'}
              label={'Cool Down'}
              value={currentNomination && currentNomination.coolDown !== undefined ? currentNomination.coolDown : false}
            />
          </FormElement>
        </FormWrapper>
      </section> */}
      {canUserEditContract(currentNomination, props.currentUser, props.creatingNewNomination) && (
        <section className="form-section">
          <div className="form-header">
            <div className="step-header">
              <div className="step-indicator">5</div>
              <h2 className="">Contract</h2>
            </div>
          </div>
          <FormWrapper>
            <FormElement>
              <FormLabel fieldName="vendorReference">Supplier reference</FormLabel>
              <TextField
                fieldName="vendorReference"
                disabled={disabled || !fieldPermissions.vendorReference.write}
                placeholder={'E.g. customer no., voyage no. or similar'}
                value={
                  !fieldPermissions.vendorReference && !currentNomination.vendorReference
                    ? '-'
                    : currentNomination.vendorReference
                    ? currentNomination.vendorReference
                    : ''
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChangeFormValue('vendorReference', event.target.value);
                }}
              ></TextField>
              <div className="form-field-message">
                <FormLabel fieldName="prevVendorReference" strikeThrough>
                  {getPreviousValueLabel('vendorReference', currentNomination, previousNomination)}
                </FormLabel>
                <FormErrorMessage errorMessage={errors ? get(errors, 'vendorReference') : undefined} />
              </div>
            </FormElement>

            {
              <FormElement>
                <FormLabel fieldName="contractId">Contract *</FormLabel>
                <SelectionBox<{ value: string; text: string }>
                  fieldName={'contractId'}
                  className="hodor"
                  value={
                    !fieldPermissions.contractId && !currentNomination.contractId
                      ? '-'
                      : currentNomination.contractId || ''
                  }
                  options={contracts.map((contract) => ({
                    value: contract._id,
                    text: contract.name,
                  }))}
                  disabled={disabled || !fieldPermissions.contractId.write}
                  valueKey="value"
                  displayKey="text"
                  renderFieldValue={(selectedItem) => {
                    return `${selectedItem.text}`;
                  }}
                  onChangeValue={(selectedItem) => {
                    if (!currentNomination.vendorReference) {
                      props.setMultipleValues([
                        { field: 'contractId', value: selectedItem?.value || null },
                        { field: 'vendorReference', value: selectedItem?.text || null },
                      ]);
                    } else {
                      props.onChangeFormValue('contractId', selectedItem?.value || null);
                    }
                  }}
                  renderOption={(option) => {
                    return (
                      <div>
                        <span>{option.text}</span>
                      </div>
                    );
                  }}
                />
                <div className="form-field-message">
                  <FormLabel fieldName="prevContractId" strikeThrough>
                    {previousContract?.name}
                  </FormLabel>
                  <FormErrorMessage errorMessage={errors?.contractId}></FormErrorMessage>
                </div>
              </FormElement>
            }
            {!isSchedulerCaptain(props.currentUser.roles) && (
              <FormElement>
                <FormLabel fieldName="priceEnabled">
                  Specify price <br />
                  <span className="sub-label">(Will show on the order confirmation)</span>
                </FormLabel>
                <Checkbox
                  field={'priceEnabled'}
                  value={currentNomination.priceEnabled || false}
                  disabled={disabled || !fieldPermissions.priceEnabled.write}
                  className="price-checkbox"
                  onChange={togglePriceEnabled}
                />
              </FormElement>
            )}
            {currentNomination.priceEnabled && !isSchedulerCaptain(props.currentUser.roles) && (
              <>
                <FormElement>
                  <FormLabel fieldName="price">Price</FormLabel>

                  <FormFieldWrapper>
                    <TextField
                      fieldName="price"
                      disabled={disabled || !fieldPermissions.price.write}
                      value={currentNomination.price || ''}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props.onChangeFormValue('price', event.target.value);
                      }}
                    />
                  </FormFieldWrapper>

                  <div className="form-field-message">
                    <FormLabel strikeThrough fieldName="prevPrice">
                      {getPreviousValueLabel('price', currentNomination, previousNomination)}
                    </FormLabel>

                    <FormErrorMessage errorMessage={errors ? get(errors, 'price') : undefined} />
                  </div>
                </FormElement>
                <FormElement>
                  <FormLabel>Unit</FormLabel>
                  <div className="form-field-wrapper">
                    <SelectionBox<{ value: PriceUnit; text: string }>
                      disableSearch
                      fieldName="priceUnit"
                      value={currentNomination.priceUnit}
                      valueKey="value"
                      displayKey="text"
                      disabled={disabled || !fieldPermissions.priceUnit.write}
                      className="price-unit-selection-box"
                      renderFieldValue={(selectedItem) => {
                        return `${selectedItem.text}`;
                      }}
                      onChangeValue={(selectedItem) => {
                        props.onChangeFormValue('priceUnit', selectedItem?.value);
                      }}
                      options={PRICE_UNITS}
                      renderOption={(option) => {
                        return (
                          <div>
                            <span>{option.text}</span>
                          </div>
                        );
                      }}
                    />
                    {/* <SelectionBox<{ value: EnergyUnit; text: string }>
                      disableSearch
                      fieldName="energyUnit"
                      value={currentNomination.energyUnit}
                      valueKey="value"
                      displayKey="text"
                      disabled={disabled || !fieldPermissions.energyUnit.write}
                      renderFieldValue={selectedItem => {
                        return `${selectedItem.text}`
                      }}
                      onChangeValue={selectedItem => {
                        props.onChangeFormValue('energyUnit', selectedItem?.value)
                      }}
                      options={ENERGY_UNITS}
                      renderOption={option => {
                        return (
                          <div>
                            <span>{option.text}</span>
                          </div>
                        )
                      }}
                    /> */}
                  </div>
                  <div className="form-field-message">
                    <FormLabel fieldName="prevPriceUnit" strikeThrough>
                      {getPreviousValueLabel('priceUnit', currentNomination, previousNomination)}
                    </FormLabel>
                    {/* <FormLabel fieldName="prevEnergyUnit" strikeThrough>
                      {getPreviousValueLabel('energyUnit', currentNomination, previousNomination)}
                    </FormLabel> */}
                    {/* <FormErrorMessage errorMessage={get(errors, 'energyUnit')} /> */}
                    <FormErrorMessage errorMessage={get(errors, 'priceUnit')} />
                  </div>
                </FormElement>
              </>
            )}
            {!isSchedulerCaptain(props.currentUser.roles) && (
              <FormElement>
                <FormLabel fieldName={contractTermsFieldName}>
                  {"Supplier's"}
                  <br /> Additional terms
                </FormLabel>
                <TextArea
                  disabled={disabled || !fieldPermissions.supplierTerms.write}
                  fieldName={contractTermsFieldName}
                  placeholder={'E.g. legal requirements or similar. These will appear on the order confirmation'}
                  value={currentNomination[contractTermsFieldName] || ''}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    props.onChangeFormValue(e.target.name, e.target.value)
                  }
                ></TextArea>
              </FormElement>
            )}
          </FormWrapper>
        </section>
      )}
    </div>
  );
};

export default SupplierNominationForm;

function getEmptyPipeline(_id: string, name: string): IPipeline {
  return {
    _id,
    name,
    companyId: '',
    order: 1,
    active: true,
    deleted: false,
    lngComposition: null,
    lngProperties: null,
  };
}
