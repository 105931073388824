import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { AuthActions, AUTH_LOADING, AUTH_FETCH_ERROR, GET_AUTH } from './types';
import { AuthResponseModel } from './models';

export const getAuth = () => (dispatch: Dispatch<AuthActions>) => {
  dispatch({
    type: AUTH_LOADING,
  });

  new api<AuthResponseModel>({
    dispatch,
    errorType: AUTH_FETCH_ERROR,
  })
    .get(`/account/GetProfileData`)
    .then((response) => {
      dispatch({
        type: GET_AUTH,
        payload: response,
      });
    });
};
