import React from 'react'
import Button, { Dialog, ButtonGroup } from '@teqplay/teqplay-ui'
import { getNominationStatusIcon, IPromptNomination, INominationEnquiryEvent } from '@teqplay/chorus-components'
import { RecurringNomination } from '../../../../../store/RecurringNomination/models'

interface CounterProposalDialogProps {
  counterProposalCandidate?: IPromptNomination | INominationEnquiryEvent | RecurringNomination
  clearCounterProposalCandidate: () => void
  ignoringApproveRequest?: boolean
  amendOrCounterNegotiation: (
    action: 'AMEND' | 'COUNTER',
    counterCandidate: IPromptNomination | INominationEnquiryEvent | RecurringNomination
  ) => void
}

const CounterProposalDialog: React.FunctionComponent<CounterProposalDialogProps> = (props) => {
  return (
    <Dialog
      showDialog={props.counterProposalCandidate ? true : false}
      onCloseDialog={() => {
        props.clearCounterProposalCandidate()
      }}
      type="small"
      title="Counter Proposal Required"
      titleIcon={getNominationStatusIcon('PROPOSED')}
    >
      <p>
        The changes to the event require a new proposal to be sent.
      </p>

      {
        (props.ignoringApproveRequest === true) && <p>This counter proposal will not be approved.</p>
      }

      <p>
        Do you wish to continue?
      </p>

      <ButtonGroup>
        <Button
          primary
          preventDoubleClick
          onClick={() => {
            if (props.counterProposalCandidate) {
              props.amendOrCounterNegotiation('COUNTER', props.counterProposalCandidate)
            }
            props.clearCounterProposalCandidate()
          }}
        >
          Send new proposal
        </Button>
        <Button
          primary
          preventDoubleClick
          outline
          onClick={() => {
            props.clearCounterProposalCandidate()
          }}
        >
          Cancel
        </Button>
      </ButtonGroup>
    </Dialog>
  )
}

export default CounterProposalDialog
