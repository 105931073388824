import { StandardWrapper } from '../../models/Responses';

export type OutboundIntegrationApiTrackerWrapperModel = StandardWrapper<IntegrationOutboundApiTracker, {}>;
export type OutboundIntegrationApiTrackerResponseModel = IntegrationOutboundApiTracker;

export interface IntegrationOutboundApiTracker {
  Id: string;
  CompanyId: string;
  TrackAll: boolean;
  TimeStamp?: Date;
  IntegrationStatus: IntegrationStatus;
  Nomination: boolean;
  BDN: boolean;
  SupplierInternal?: boolean;
  BDNPDF?: boolean;
}

export enum IntegrationStatus {
    NewRequest, 
    Pending, 
    Completed
} 

