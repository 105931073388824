import * as TYPE from './types';

import { ApplicationState } from '..';

export const integrationAssetCompanyReducer = (
  state: ApplicationState['integration_assets_companies'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.IntegrationAssetCompanyActions,
): ApplicationState['integration_assets_companies'] => {
  switch (action.type) {
    case TYPE.INTEGRATION_ASSET_COMPANY_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.INTEGRATION_ASSET_COMPANY_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.GET_INTEGRATION_ASSET_COMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.INTEGRATION_ASSET_COMPANY_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_INTEGRATION_ASSET_COMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Integration Asset Company succesfully created!',
          type: 'success',
        },
      };

    case TYPE.INTEGRATION_ASSET_COMPANY_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `Integration Asset Company with this client Id already exist in FuelBoss. If not please contact Fuelboss support`
            : `We could not create the new Integration Asset Company. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_INTEGRATION_ASSET_COMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Integration Asset Company edited',
          type: 'info',
        },
      };
    case TYPE.INTEGRATION_ASSET_COMPANY_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not edit the Integration Asset Company. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    case TYPE.DELETE_INTEGRATION_ASSET_COMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Integration Asset Company succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.INTEGRATION_ASSET_COMPANY_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not delete the Integration Asset Company as there might be Integration Asset linked to this company. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    default:
      return { ...state };
  }
};
