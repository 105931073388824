import { CategoryWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const CATEGORY_LOADING = 'category/loading';
export type CATEGORY_LOADING = typeof CATEGORY_LOADING;

export const GET_CATEGORIES = 'category/get';
export type GET_CATEGORIES = typeof GET_CATEGORIES;

export const CATEGORIES_FETCH_ERROR = 'category/fetchError';
export type CATEGORIES_FETCH_ERROR = typeof CATEGORIES_FETCH_ERROR;

export const ADD_CATEGORY = 'category/add';
export type ADD_CATEGORY = typeof ADD_CATEGORY;

export const CATEGORIES_FETCH_SUCCESS = 'category/fetchSuccess';
export type CATEGORIES_FETCH_SUCCESS = typeof CATEGORIES_FETCH_SUCCESS;

export const DELETE_CATEGORY = 'category/delete';
export type DELETE_CATEGORY = typeof DELETE_CATEGORY;

export const EDIT_CATEGORY = 'category/edit';
export type EDIT_CATEGORY = typeof EDIT_CATEGORY;

export type CategoryActions =
  | {
      type: GET_CATEGORIES;
      payload: CategoryWrapperModel['data'];
    }
  | {
      type: CATEGORY_LOADING;
    }
  | {
      type: CATEGORIES_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_CATEGORY;
      payload: CategoryWrapperModel['data'];
    }
  | {
      type: CATEGORIES_FETCH_SUCCESS;
    }
  | {
      type: DELETE_CATEGORY;
      payload: CategoryWrapperModel['data'];
    }
  | {
      type: EDIT_CATEGORY;
      payload: CategoryWrapperModel['data'];
    };
