import { isoStringToFormat } from '@teqplay/teqplay-ui'
import React from 'react'
import { ValidationWarning } from '@teqplay/chorus-components'

interface SummaryRowProps {
  label: string
  currentValue?: string | null | number | React.ReactChild
  previousValue?: string | null | number | React.ReactChild
  formatAsDate?: boolean
  formatTimezone?: string
  className?: string
  hideNewIndicator?: boolean
  emptyValueMessage: string
  isInitialValue: boolean
  warning?: string

  shouldCompare?: boolean
  rowToCompareWith?: string | null | number | React.ReactChild
}

const SummaryRow: React.FunctionComponent<SummaryRowProps> = props => {
  const {
    currentValue,
    previousValue,
    label,
    formatAsDate,
    formatTimezone,
    hideNewIndicator,
    isInitialValue,
    warning
  } = props
  const isNewValue = currentValue && previousValue === undefined
  const showNewIndicator = hideNewIndicator !== true && isNewValue
  const valueIsDifferentThenComparedNomination =
    props.shouldCompare && props.currentValue !== props.rowToCompareWith

  return (
    <div
      className={`summary-row ${props.className || ''} ${
        valueIsDifferentThenComparedNomination ? 'delegated-is-different' : ''
      }`}
    >
      <div className="summary-label">
        <span className="summary-label-text">{label}</span>
      </div>
      <div className="summary-value">
        {currentValue !== undefined && currentValue !== null && currentValue !== '' && (
          <div className="current-value">
            {formatAsDate
              ? isoStringToFormat(currentValue as string, 'dd MMM yyyy | HH:mm', formatTimezone)
              : currentValue}
          </div>
        )}

        {(currentValue === undefined || currentValue === null || currentValue === '') && (
          <div className="default-value">{props.emptyValueMessage}</div>
        )}

        {!isInitialValue && (
          <>
            {previousValue !== undefined &&
              previousValue !== null &&
              previousValue !== currentValue &&
              previousValue !== 0 &&
              previousValue !== '' && (
                <div className="previous-value">
                  {formatAsDate
                    ? isoStringToFormat(
                        previousValue as string,
                        'dd MMM yyyy | HH:mm',
                        formatTimezone
                      )
                    : previousValue}
                </div>
              )}

            {showNewIndicator ? <div className="new-value">NEW</div> : null}
          </>
        )}

        {warning && <ValidationWarning warning={warning} />}
      </div>
    </div>
  )
}

export default SummaryRow
