import * as React from 'react';
import { useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, useExpanded, usePagination, Column } from 'react-table';
import styles from '../Table.module.scss';
import { Row } from 'react-table/index';
import GlobalFilter from './GlobalFilter';
import Pagination from '../Pagination/Pagination';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getHiddenColumns } from '../../../scenes/AISList/Utils/Utils';
import { ReactElement } from 'react';
import { IconProp } from '@fortawesome/fontawesome';
import styled from 'styled-components';

export interface SortIcons {
  default: IconProp;
  asc: IconProp;
  desc: IconProp;
}
interface OwnProps {
  headers: any;
  // rows: JSX.Element[];
  numberOfRowsPerPagination?: number;
  // children: React.ReactElement[];
  striped?: boolean;
  hover?: boolean;
  responsive?: boolean;
  tableRows: object[];
  showPagination: boolean;
  showFilterSearch: boolean;
  renderRowSubComponent?: (row: any) => JSX.Element;
  hiddenColumns?: Array<string>;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
  showSortIcons?: boolean;
  filters?: { id: string; value: any }[];
  sortBy?: { id: string; desc: boolean }[];
  onRowClick?: (row: any) => void;
  rowStyle?: (row: any) => React.CSSProperties;
  globalFilter?: string;
  pageSize?: number;
  sortIcons?: SortIcons;
  defaultCollumn?: Partial<Column<object>> | undefined;
  setDefaultPageSize?: (number: number) => void;
}

const sortAsc = findIconDefinition({
  prefix: 'fas',
  iconName: 'sort-up',
});
const sortDesc = findIconDefinition({
  prefix: 'fas',
  iconName: 'sort-down',
});
const sort = findIconDefinition({
  prefix: 'fal',
  iconName: 'sort',
});

const StyledHeader = styled.th<{ minWidthInRem: number }>`
  min-width: ${(props) => props.minWidthInRem}rem;
`;
const Table: React.FC<OwnProps> = ({
  headers,
  numberOfRowsPerPagination = 10,
  striped = true,
  hover = true,
  responsive = true,
  tableRows,
  showPagination = true,
  showFilterSearch = true,
  renderRowSubComponent,
  hiddenColumns,
  defaultSelectedPage,
  setDefaultSelectedPage,
  showSortIcons = true,
  filters,
  sortBy,
  onRowClick,
  rowStyle,
  globalFilter,
  pageSize,
  sortIcons = { default: sort, asc: sortAsc, desc: sortDesc },
  defaultCollumn,
  setDefaultPageSize,
}) => {
  const filterTypes = {
    text: (rows: Row<object>[], id: any, filterValue: any) => {
      const filteredRows = rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
          : true;
      });
      return filteredRows;
    },
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { expanded, pageIndex },
    state,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    setAllFilters,
    setSortBy,
    setHiddenColumns,
    rows,
  } = useTable(
    {
      columns: headers,
      data: tableRows,
      defaultColumn: defaultCollumn? defaultCollumn : undefined,
      initialState: {
        pageIndex: defaultSelectedPage && (numberOfRowsPerPagination < tableRows.length) ? defaultSelectedPage : 0,
        pageSize: numberOfRowsPerPagination,
        hiddenColumns: hiddenColumns ? hiddenColumns : ['hidePlaceholder'],
        filters: filters ? filters : [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const isHeaderHidden = (header: string = 'header') => {
    if (header.toLowerCase() == 'edit' || header.toLowerCase() == 'delete' || header.toLowerCase() == 'hidecountry' ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!showPagination) {
      setPageSize(tableRows.length);
    } else {
      setPageSize(numberOfRowsPerPagination);
    }
  }, []);

  useEffect(() => {
    if (hiddenColumns === undefined) return;
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns]);

  useEffect(() => {
    if (globalFilter === undefined) return;
    setGlobalFilter(globalFilter);
  }, [globalFilter]);

  useEffect(() => {
    if (filters === undefined) return;
    setAllFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (sortBy === undefined) return;
    setSortBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    if (pageSize === undefined) return;
    setPageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    // capture currently selected page to auto navigate in future
    if (setDefaultSelectedPage && pageIndex != 0) {
      setDefaultSelectedPage(pageIndex);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (state.globalFilter != undefined && setDefaultSelectedPage) {
      setDefaultSelectedPage(0);
    }
  }, [state.globalFilter]);

  useEffect(() => {
    if (state.pageSize != undefined && setDefaultPageSize) {
      setDefaultPageSize(state.pageSize);
    }
  }, [state.pageSize]);

  return (
    <div className={styles['table']}>
      {showFilterSearch && <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />}
      {/* 
      // @ts-ignore */}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            // @ts-ignore
            <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headersIndex) => (
                // @ts-ignore
                <th key={headersIndex} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {!isHeaderHidden(column.Header?.toString()) && (
                    <div
                      className={styles['th-div']}
                      style={
                        column.maxWidth && column.maxWidth !== Number.MAX_SAFE_INTEGER
                          ? { width: `${column.maxWidth}px` }
                          : {}
                      }
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {showSortIcons &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={sortIcons.desc} />
                            ) : (
                              <FontAwesomeIcon icon={sortIcons.asc} />
                            )
                          ) : column.Header == 'Edit' || column.Header == 'Delete' ? (
                            ' '
                          ) : (
                            <FontAwesomeIcon icon={sortIcons.default} />
                          ))}
                      </span>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/*
        // @ts-ignore */}
        <tbody {...getTableBodyProps()}>
          {(showPagination ? page : rows).map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                {/* @ts-ignore */}
                <tr
                  /* @ts-ignore */
                  key={rowIndex}
                  {...row.getRowProps()}
                  className={`${onRowClick ? styles['clickable'] : ''}`}
                  onClick={onRowClick ? () => onRowClick(row.original) : undefined}
                  /* @ts-ignore */

                  style={rowStyle ? rowStyle(row.original) : undefined}
                >
                  {row.cells.map((cell, cellIndex) => (
                    // @ts-ignore
                    <td key={cellIndex} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                          Inside it, call our renderRowSubComponent function to display expanded row
                        */}
                      {renderRowSubComponent ? renderRowSubComponent(row) : ''}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      {showPagination && (
        <div className={styles['pagination-wrapper']}>
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            maxPage={pageOptions.length}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageSize={state.pageSize}
            setPageSize={setPageSize}
            totalRecords={globalFilteredRows.length}
          />
        </div>
      )}
    </div>
  );
};
export default Table;
