import * as React from 'react';
import { FormGroup, Input } from 'reactstrap';
import TimezoneList from '../CommonLists/TimezoneList';

const ReactstrapSelectInput = ({ field, disabled = false, accessor, ...props }) => {
  // sort options by name
  const options = (props.options || []).sort((a, b) => {
    return a[accessor].localeCompare(b[accessor]);
  });

  return (
    <FormGroup>
      <Input id={props.id} {...field} {...props} type="select" disabled={disabled}>
        <option hidden>{props.placeholder}</option>
        {field.name === 'timezone' ? (
          <TimezoneList />
        ) : (
          options.map((option, idx) => (
            <option value={option[accessor]} key={idx}>
              {option.name}
            </option>
          ))
        )}
      </Input>
    </FormGroup>
  );
};
export default ReactstrapSelectInput;
