import styled from 'styled-components';

export default styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 35px;
  min-height: 25px;

  &:focus ~ .checkmark {
    border-color: #99d6f0;
  }
  &:hover input:not(:disabled) ~ .checkmark {
    border-color: ${({ theme: { colors } }) => colors.cyanDark};
  }
  &:hover input:not(:checked):not(.disabled) ~ .checkmark:after {
    display: block;
    opacity: 0.5;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      border-color: ${({ theme: { colors } }) => colors.cyanDark};

      &:after {
        display: block;
      }
    }
  }

  input:disabled {
    &:checked ~ .checkmark {
      border-color: ${({ theme: { colors } }) => colors.cyanDark};

      &:after {
        border-color: grey !important;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: ${({ theme: { colors } }) => `1px solid ${colors.neutral40}`};

    &:after {
      content: ' ';
      display: none;
      width: 0.5em; // the short bar of the mark is half as long as the long bar
      height: 1em;
      border: ${({ theme: { colors } }) => `solid ${colors.cyanLight}`};
      border-width: 0 0.2em 0.2em 0; // creates the inverted "L" shape
      position: absolute;
      left: 0.5em;
      top: 20%;
      margin-top: -0.2em;
      -webkit-transform: rotate(45deg); // tilts the shape
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &.error {
      border-color: color(red);
    }
  }

  .text-label {
    line-height: 25px;
    &.error {
      color: color(red);
    }
    &.checked {
      color: #02c39a;
    }
  }
`;
