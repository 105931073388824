import { StandardWrapper } from '../../models/Responses';
import { Company } from '../Company/models';
import { UserRole } from '../../models/UserRole';
import { Role } from '../Roles/models';
import { Category } from '../Category/models';

export type EmailSettingsWrapperModel = StandardWrapper<EmailSettings, {}>;
export type EmailSettingsResponseModel = EmailSettings;

export interface EmailSettings {
  id: string;
  veracityId: string;
  chorusId: string;
  companyId: string;
  userType: EmailSettingUserType;
  emailNotificationSettings: EmailNotificationSetting[];
  disableAll: boolean;
  disableAllNominations: boolean;
  timeStamp?: Date;
}

export interface EmailNotificationSetting {
    id: string;
    referenceIdType: EmailNotificationRefIdType;
    refId: string;
    name: string;
    bunkerNotification: boolean;
    chatNotification: boolean;
    emailSettingId: string;
}

export enum EmailNotificationRefIdType {
    Vessel, // RefId will be ChorusId for the Vessel
    DeliveryMode, // RefId will be DeliveryID from FuelBoss, but use Name for checking the deliveryMode (PIPE, CONTAINER etc)
    Company, // RefId will be ChorusId for the Company (or contract?)
    Spot
}

export enum EmailSettingUserType {
    Shipowner, // depending on UserType, you get different email settings lists to use
    Supplier //
}