import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import * as TYPE from './types';
import { api } from '../../helpers/api';
import { ContractDocumentsResponseModel, ContractDocument } from './models';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Uploading contract document...</ModalResponse>;
const updateSuccessModal: ReactElement = (
  <ModalResponse status="success">Contract document uploaded successfully</ModalResponse>
);

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

const errorAddDocument = (
  <ModalResponse status="error">There has been an error updating the contract document</ModalResponse>
);

export const getContractDocuments = (contractId: string) => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.CONTRACT_DOCUMENT_LOADING,
  });

  new api<ContractDocumentsResponseModel>({
    dispatch,
    errorType: TYPE.CONTRACT_DOCUMENT_FETCH_ERROR,
  })
    .get(`/api/Contract/getContractDocument/${contractId}`)
    .then((response) => {
      dispatch({
        type: TYPE.GET_CONTRACT_DOCUMENT,
        payload: response,
      });
    });
};

export const deleteContractDocuments = (documentId: string, fileName: string) => (
  dispatch: Dispatch<TYPE.CompanyActions>,
) => {
  dispatch({
    type: TYPE.CONTRACT_DOCUMENT_LOADING,
  });

  const url = `/api/Contract/deleteContractDocument/${documentId}/${fileName}`;

  new api<ContractDocumentsResponseModel>({
    dispatch,
    errorType: TYPE.DELETE_CONTRACT_DOCUMENT_ERROR,
  })
    .delete(url, documentId)
    .then((response) => {
      dispatch({
        type: TYPE.DELETE_CONTRACT_DOCUMENT,
        payload: response,
      });
    });
};

export const addContractDocuments = (contractId: string, doc: ContractDocument, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.CompanyActions>,
) => {
  dispatch({
    type: TYPE.CONTRACT_DOCUMENT_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/Contract/addContractDocument/${contractId}`;

  new api<ContractDocumentsResponseModel>({
    dispatch,
    errorType: TYPE.ADD_CONTRACT_DOCUMENT_ERROR,
  })
    .put(url, doc)
    .then((response) => {
      dispatch({
        type: TYPE.ADD_CONTRACT_DOCUMENT,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorAddDocument });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 500);
    });
};
