import * as TYPE from './types';

import { ApplicationState } from '..';

export const chorusDocumentReducer = (
  state: ApplicationState['chorusdocument'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.ChorusDocumentActions,
): ApplicationState['chorusdocument'] => {
  switch (action.type) {
    case TYPE.CHORUSDOCUMENTS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.CHORUSDOCUMENTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_CHORUSDOCUMENTS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
      case TYPE.CHORUSDOCUMENTSDOWNLOADPREVIEW_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.CHORUSDOCUMENTSDOWNLOADPREVIEW_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_CHORUSDOCUMENTSDOWNLOADPREVIEW:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
      case TYPE.CHORUSDOCUMENTSDOWNLOADLIST_LOADING:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case TYPE.CHORUSDOCUMENTSDOWNLOADLIST_FETCH_ERROR:
        return {
          ...state,
          loading: false,
          error: true,
          errorDetails: action.payload,
        };
      case TYPE.GET_CHORUSDOCUMENTSDOWNLOADLIST:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
          dataIsLoaded: true,
        };
      case TYPE.CHORUSDOCUMENTSDOWNLOADPREVIEW_CLEAR_TOAST:
        return {
          ...state,
          toastData: undefined,
        };
    default:
      return { ...state };
      
  }
};