import { IntegrationAssetTopicWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_TOPIC_CLEAR_TOAST = 'integrationAssetTopic/ClearToast';
export type INTEGRATION_ASSET_TOPIC_CLEAR_TOAST = typeof INTEGRATION_ASSET_TOPIC_CLEAR_TOAST;

export const INTEGRATION_ASSET_TOPIC_LOADING = 'integrationAssetTopic/loading';
export type INTEGRATION_ASSET_TOPIC_LOADING = typeof INTEGRATION_ASSET_TOPIC_LOADING;

export const GET_INTEGRATION_ASSET_TOPIC = 'integrationAssetTopic/get';
export type GET_INTEGRATION_ASSET_TOPIC = typeof GET_INTEGRATION_ASSET_TOPIC;

export const INTEGRATION_ASSET_TOPIC_FETCH_ERROR = 'integrationAssetTopic/fetchError';
export type INTEGRATION_ASSET_TOPIC_FETCH_ERROR = typeof INTEGRATION_ASSET_TOPIC_FETCH_ERROR;

export const ADD_INTEGRATION_ASSET_TOPIC = 'integrationAssetTopic/add';
export type ADD_INTEGRATION_ASSET_TOPIC = typeof ADD_INTEGRATION_ASSET_TOPIC;

export const EDIT_INTEGRATION_ASSET_TOPIC = 'integrationAssetTopic/edit';
export type EDIT_INTEGRATION_ASSET_TOPIC = typeof EDIT_INTEGRATION_ASSET_TOPIC;

export const DELETE_INTEGRATION_ASSET_TOPIC = 'integrationAssetTopice/delete';
export type DELETE_INTEGRATION_ASSET_TOPIC = typeof DELETE_INTEGRATION_ASSET_TOPIC;

export const INTEGRATION_ASSET_TOPIC_ADD_ERROR = 'integrationAssetTopic/addError';
export type INTEGRATION_ASSET_TOPIC_ADD_ERROR = typeof INTEGRATION_ASSET_TOPIC_ADD_ERROR;

export const INTEGRATION_ASSET_TOPIC_EDIT_ERROR = 'integrationAssetTopic/editError';
export type INTEGRATION_ASSET_TOPIC_EDIT_ERROR = typeof INTEGRATION_ASSET_TOPIC_EDIT_ERROR;

export const INTEGRATION_ASSET_TOPIC_DELETE_ERROR = 'integrationAssetTopic/deleteError';
export type INTEGRATION_ASSET_TOPIC_DELETE_ERROR = typeof INTEGRATION_ASSET_TOPIC_DELETE_ERROR;

export type IntegrationAssetTopicActions =
  | {
      type: INTEGRATION_ASSET_TOPIC_LOADING;
    }
  | {
      type: GET_INTEGRATION_ASSET_TOPIC;
      payload: IntegrationAssetTopicWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_ASSET_TOPIC;
      payload: IntegrationAssetTopicWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_ASSET_TOPIC;
      payload: IntegrationAssetTopicWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_TOPIC;
      payload: IntegrationAssetTopicWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_TOPIC_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TOPIC_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TOPIC_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TOPIC_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TOPIC_CLEAR_TOAST;
    };
