import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAuth } from '../../../../../store/Auth/actions';
import { Company } from '../../../../../store/Company/models';
import { getCompanies } from '../../../../../store/Company/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { renderTextInput, renderComplexDropdown, renderSimpleDropdown, renderTextAreaInput } from '../../../../Form/FormInputs';
import integrationAssetConfigs from './integrationAssetConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import { IntegrationAssetWorkspace } from '../../../../../store/IntegrationAssetWorkspaces/models';
import { IntegrationAssetCompany } from '../../../../../store/IntegrationAssetCompanies/models';

const StyledInnerForm = styled.div`
  width: 70%;
  @media (max-width: 1920px) {
    width: 90%;
  }
  display: block;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
margin-top: 1em;
.formHeader {
  flex: 3 1 0%;
  font-weight: 600;
  font-size: 1.75em;
  padding-left: 5px;
}
.buttonContainer {
  display: flex;
  flex: 0 1 0%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: right;
}
.buttonContainer button {
  justify-self: flex-end;
  margin-left: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 18px;
  font-size: 18px;
}
div[class^='input-'] {
  flex: 0 1 40%;
  margin-right: 35px;
  width: 35%;
}
button {
  justify-self: flex-end;
  margin-right: 0.5em;
}
.form-check {
  margin-right: 5px;
}
`;


interface OwnProps {
  integrationAssetWorkspace?: IntegrationAssetWorkspace;
  integrationAssetCompany: IntegrationAssetCompany | undefined;
  header: string;
  saveMethod: (integrationAssetWorkspace: IntegrationAssetWorkspace) => void;
  companyAdminForm?: boolean
  systemAdminPage?: boolean;
  formikProps: FormikProps<IntegrationAssetWorkspace>;
  navAdminType: string;
}

const IntegrationAssetWorkspaceForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  integrationAssetWorkspace,
  integrationAssetCompany,
  systemAdminPage = false,
  companyAdminForm = false,
  navAdminType,
  formikProps,
}) => {
  const {
    authLoading,
    authError,
    authErrorDetails,
    authIsLoaded,

    companies,
    companiesIsLoaded,
    companiesLoading,
    companiesError,
    companiesErrorDetails,

  } = useSelector((state: ApplicationState) => ({
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    companiesLoading: state.companies.loading,
    companiesError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,

    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  const getAllCompaniesCallback = useCallback(() => dispatch(getCompanies()), []);
  const [isEditMode, setIsEditMode] = useState<boolean>();

  useEffect(() => {
    if (integrationAssetWorkspace?.id) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [integrationAssetWorkspace]);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getAllCompaniesCallback();
    }
  }, [companiesIsLoaded]);

  /*useEffect(() => {
      if(formikProps.values.company){
        if(formikProps.values.company.chorusId)
      formikProps.values.companyId = formikProps.values.company.chorusId;
      formikProps.values.companyName = formikProps.values.company.name;
      }
  }, [formikProps.values.company])*/

  const onFormSave = () => {

    formikProps.submitForm();
    //const roleArray = getRolesArray();

    const integrationAssetWorkspaceToSave: IntegrationAssetWorkspace = {
      ...formikProps.values,
      //  active: true,
    };
    if (integrationAssetWorkspace) {
      //edit case
      //integrationAssetWorkspaceToSave.workspace = integrationAssetWorkspace.workspace;
      integrationAssetWorkspaceToSave.id = integrationAssetWorkspace.id;
      //integrationAssetWorkspaceToSave.name = integrationAssetWorkspace.name;
    } else {
      //create case
      integrationAssetWorkspaceToSave.id = '00000000-0000-0000-0000-000000000000';
      if (integrationAssetCompany) integrationAssetWorkspaceToSave.integrationAssetCompanyCompanyId = integrationAssetCompany.companyId;
    }
    if (formikProps.isValid) {
      saveMethod(integrationAssetWorkspaceToSave);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetConfigs, errors, touched, setFieldValue, setFieldTouched };

  if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save integration asset workspace"
          cancelButtonText="Cancel"
          formUrl="integrationassets/companies"
        />
        <StyledInnerForm as={Form}>
          {integrationAssetWorkspace && renderTextInput('id', isEditMode, props)}
          {/* {!companyAdminForm && renderComplexDropdown('company', companies, false, 'name', props)} */}
          {renderTextInput('workspace', false, props)}
          {renderTextInput('name', false, props)}

        </StyledInnerForm>
      </StyledForm>
    );
  }
};

export default React.memo(IntegrationAssetWorkspaceForm);
