import React, { useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Button } from 'reactstrap';
import styles from './DeleteById.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';

//const trash = findIconDefinition({ prefix: 'fal', iconName: 'trash' });

interface OwnProps {
  id: string;
  deleteMethod: (id: string) => void;
  header: string;
  delBtnIcon: IconDefinition;
  className?: string;
  message?: string;
}

const DeleteById: React.FC<OwnProps> = ({ id, deleteMethod, header, delBtnIcon, className, message }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <Button className={`${styles['btn-del']}  ${className}`} onClick={toggle}>
        <FontAwesomeIcon icon={delBtnIcon} />
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody>
          <h3>{message ? message : 'Are you sure you want to delete?'}</h3>
          <Button
            color="danger"
            onClick={() => {
              deleteMethod(id);
              toggle();
            }}
          >
            Confirm
          </Button>
          <Button color="primary" onClick={toggle}>
            Cancel
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default React.memo(DeleteById);
