import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import React from 'react';
import Reports from './Components/Reports/Reports';
import ReportTypes from './Components/ReportTypes/ReportTypes';
import AddReport from './Components/AddReport/AddReport';
import AddReportType from './Components/AddReportType/AddReportType';
import { Company } from '../../../store/Company/models';
import { ApplicationState } from '../../../store';
import { getReports, addReport, deleteReport, editReport, toggleReportVisibility } from '../../../store/Reports/actions';
import { Report } from '../../../store/Reports/models';
import { addReportType, getReportTypes, deleteReportType, editReportType } from '../../../store/ReportTypes/actions';
import { ReportType } from '../../../store/ReportTypes/models';
import { Role } from '../../../store/Roles/models';
import { AddToast } from '../../../helpers/toastService';
import { reportsClearToast } from '../../../store/Reports/actions';
import { reportTypesClearToast } from '../../../store/ReportTypes/actions';
import { Switch, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import EditReport from './Components/EditForm/EditReport';
import EditReportType from './Components/EditReportType/EditReportType';
import { getCategories } from '../../../store/Category/actions';
import { getCompanies } from '../../../store/Company/actions';
import { getRoles } from '../../../store/Roles/actions';

interface OwnProps {
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
}

const ReportsContainer: React.FC<OwnProps> = ({ companyAdmin, navAdminType, company }) => {
  const {
    reportsLoading,
    reportsDetailsError,
    reportsErrorDetails,
    reports,
    reportsIsLoaded,
    toastData,
    reportTypeLoading,
    reportTypeError,
    reportTypeErrorDetails,
    reportType,
    reportTypeIsLoaded,
    toastDataReportTypes,
    companiesLoading,
    companiesDetailsError,
    companiesErrorDetails,
    companies,
    companiesIsLoaded,
    categoriesLoading,
    categoriesError,
    categoriesErrorDetails,
    categories,
    categoriesIsLoaded,
    roles,
    rolesIsLoaded,
    rolesLoading,
    rolesError,
    rolesErrorDetails,
  } = useSelector((state: ApplicationState) => ({
    reportsLoading: state.reports.loading,
    reportsDetailsError: state.reports.error,
    reportsErrorDetails: state.reports.errorDetails,
    reports: state.reports.data,
    reportsIsLoaded: state.reports.dataIsLoaded,
    toastData: state.reports.toastData,

    reportTypeLoading: state.report_types.loading,
    reportTypeError: state.report_types.error,
    reportTypeErrorDetails: state.report_types.errorDetails,
    reportType: state.report_types.data,
    reportTypeIsLoaded: state.report_types.dataIsLoaded,
    toastDataReportTypes: state.report_types.toastData,

    companiesLoading: state.companies.loading,
    companiesDetailsError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,

    categoriesLoading: state.categories.loading,
    categoriesError: state.categories.error,
    categoriesErrorDetails: state.categories.errorDetails,
    categories: state.categories.data,
    categoriesIsLoaded: state.categories.dataIsLoaded,

    roles: state.roles.data,
    rolesIsLoaded: state.roles.dataIsLoaded,
    rolesLoading: state.roles.loading,
    rolesError: state.roles.error,
    rolesErrorDetails: state.roles.errorDetails,
    
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const getReportsCallback = useCallback(() => dispatch(getReports()), []);

  const getReportTypesCallback = useCallback(() => dispatch(getReportTypes()), []);

  //Add report methods
  const addReportCallback = useCallback(
    (report: Report) =>
      dispatch(addReport(report, () => history.push(`/${navAdminType}admin/reports`))),
    [],
  );

  const addReportTypeCallback = useCallback(
    (reportType: ReportType) =>
      dispatch(addReportType(reportType, () => history.push(`/${navAdminType}admin/reports/types`))),
    [],
  );

  const getCategoriesCallback = React.useCallback(() => dispatch(getCategories()), []);

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const getAllRolesCallback = useCallback(() => dispatch(getRoles()), []);

  const deleteReportCallback = useCallback((id: string) => dispatch(deleteReport(id)), []);

  const deleteReportTypeCallback = useCallback((id: string) => dispatch(deleteReportType(id)), []);

  const toggleReportVisibilityCallback = useCallback((id: string) => dispatch(toggleReportVisibility(id)), []);

  const editReportCallback = useCallback(
    (report: Report) =>
      dispatch(editReport(report, () => history.push(`/${navAdminType}admin/reports`))),
    [],
  );

  const editReportTypeCallback = useCallback(
    (reportType: ReportType) =>
      dispatch(editReportType(reportType, () => history.push(`/${navAdminType}admin/reports/types`))),
    [],
  );
  const clearToastCallback = useCallback(() => dispatch(reportsClearToast()), []);

  const clearToastReportTypesCallback = useCallback(() => dispatch(reportTypesClearToast()), []);

  useEffect(() => {
    if (!reportsIsLoaded) {
      getReportsCallback();
    }
    if (!reportTypeIsLoaded) {
      getReportTypesCallback();
    }
    if (!categoriesIsLoaded) {
      getCategoriesCallback();
    }
    if (!companiesIsLoaded) {
      getCompaniesCallback();
    }
    if (!rolesIsLoaded) {
      getAllRolesCallback();
    }
  }, []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  useEffect(() => {
    if (toastDataReportTypes != null || toastDataReportTypes != undefined) {
      AddToast(toastDataReportTypes);
      clearToastReportTypesCallback();
    }
  }, [toastDataReportTypes]);

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }

  return (
    <Switch>
      <Route
        exact
        path={`/${navAdminType}admin/reports`}
        render={(props) => (
          <>
            {companiesIsLoaded && categoriesIsLoaded && rolesIsLoaded && <Reports
              {...props}
              reports={reports}
              isLoading={reportsLoading}
              hasError={reportsDetailsError}
              errorDetails={reportsErrorDetails}
              deleteReport={deleteReportCallback}
              editReport={editReportCallback}
              toggleVisibility={toggleReportVisibilityCallback}
              companyAdmin={companyAdmin}
              company={company}
              navAdminType={navAdminType}
              companies={companies}
              categories={categories}
              roles={roles}
              defaultSelectedPage={selectedPage}
              setDefaultSelectedPage={handleDefaultChange}
            />}
          </>
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/reports/add`}
        render={(props) => (
          <AddReport
            {...props}
            addNewReport={addReportCallback}
            companyAdmin={companyAdmin}
            company={company}
            navAdminType={navAdminType}
          />
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/reports/:reportId/edit`}
        render={(props) => (
          <>
          {companiesIsLoaded && categoriesIsLoaded && rolesIsLoaded && <EditReport
            {...props}
            isLoading={reportsLoading}
            editReport={editReportCallback}
            companyAdmin={companyAdmin}
            reports={reports}
            reportId={props.match.params.reportId}
            company={company}
            navAdminType={navAdminType}
          />}
          </>
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/reports/types`}
        render={(props) => (
          <>
            <ReportTypes
              {...props}
              reporttypes={reportType}
              isLoading={reportTypeLoading}
              hasError={reportTypeError}
              errorDetails={reportTypeErrorDetails}
              deleteReport={deleteReportTypeCallback}
              editReportType={editReportTypeCallback}
              companyAdmin={companyAdmin}
              company={company}
              navAdminType={navAdminType}
            />
          </>
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/reports/addtype`}
        render={(props) => (
          <AddReportType
            {...props}
            addNewReportType={addReportTypeCallback}
            companyAdmin={companyAdmin}
            company={company}
            navAdminType={navAdminType}
          />
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/reports/:reportTypeId/edittype`}
        render={(props) => (
          <EditReportType
            {...props}
            isLoading={reportsLoading}
            editReportType={editReportTypeCallback}
            companyAdmin={companyAdmin}
            reportTypes={reportType}
            reportTypeId={props.match.params.reportTypeId}
            company={company}
            navAdminType={navAdminType}
          />
        )}
      />
    </Switch>
  );
};

export default ReportsContainer;
