import React from 'react';
import {
  useTable,
  useGlobalFilter,
  useGroupBy,
  useAsyncDebounce,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import { Input } from 'reactstrap';
import styles from '../Table.module.scss';

interface OwnProps {
  globalFilter: any;
  setGlobalFilter: any;
}

const GlobalFilter: React.FC<OwnProps> = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  return (
    <div className={`${styles['search-wrapper']}`}>
      <label className={`${styles['search-label']}`}>Search: </label>
      <Input
        value={value || ''}
        className="form-control"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search any field ...`}
        style={{
          height: 'auto',
          width: '400px',
          borderRadius: '0',
        }}
      />
    </div>
  );
};

export default GlobalFilter;
