import * as React from 'react';
import styles from './DeliveryModeContainer.module.scss';
import { useState, useEffect } from 'react';
import { Auth } from '../../../../store/Auth/models';
import MyFleet from '../../../../components/AdminComponents/MyFleet/MyFleet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getFleetVessels, deleteFleetVessel, addFleetVessel } from '../../../../store/CompanyFleet/actions';
import { getPipeline } from '../../../../store/Pipelines/actions';
import { companyClearDeliverModeError } from '../../../../store/Company/actions';
import { ApplicationState } from '../../../../store';
import { Vessel } from '../../../../store/Vessels/models';
import ButtonContainer from './Components/ButtonContainer/ButtonContainer';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { toggleDeliveryMode } from '../../../../store/Company/actions';
import { CompanyDeliveryMode } from '../../../../models/CompanyDeliveryMode';
import { getAuth } from '../../../../store/Auth/actions';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface OwnProps {
  auth: Auth;
  addAsset: (event: any, deliveryMode: DeliveryModes) => void;
}

const DeliveryModeContainer: React.FC<OwnProps> = ({ auth, addAsset }) => {
  const {
    companyFleet,
    companyFleetIsLoaded,
    companyPipelines,
    companyPipelineIsLoaded,
    toggleDeliveryModeFailed,
  } = useSelector((state: ApplicationState) => ({
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,
    companyPipelines: state.pipelines.data,
    companyPipelineIsLoaded: state.pipelines.dataIsLoaded,
    toggleDeliveryModeFailed: state.companies.toggleDeliveryModeFailed,
  }));

  const [rejectionModal, setRejectionModal] = React.useState(false);
  const toggleRejectionModal = () => setRejectionModal(!rejectionModal);
  const [selectedDeliveryMode, setSelectedDeliveryMode] = React.useState('');

  React.useEffect(() => {
    if (toggleDeliveryModeFailed == true) {
      toggleRejectionModal();
      clearDeliverModeErrorsCallback();
    }
  }, [toggleDeliveryModeFailed]);

  const dispatch = useDispatch();
  const history = useHistory();

  const getAssetCount = (deliveryMode: DeliveryModes) => {
    switch (deliveryMode) {
      case DeliveryModes.Ship:
        return companyFleet.length;
        break;
      case DeliveryModes.Truck:
        return 0;
        break;
      case DeliveryModes.Pipe:
        return companyPipelines.length;
        break;
      case DeliveryModes.Container:
        return 0;
        break;
      default:
        return 0;
        break;
    }
  };

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyFleetIsLoaded) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  const getCompanyPipelineCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyPipelineIsLoaded) {
      dispatch(getPipeline(auth.company.companyId));
    }
  }, []);

  const clearDeliverModeErrorsCallback = React.useCallback(() => dispatch(companyClearDeliverModeError()), []);
  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  React.useEffect(() => {
    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }
    if (!companyPipelineIsLoaded) {
      getCompanyPipelineCallback();
    }
  }, []);

  const toggleDeliveryModeCallback = React.useCallback((deliveryMode: DeliveryModes, onSuccess: () => void) => {
    if (auth.company && auth.company.companyId) {
      setSelectedDeliveryMode(deliveryMode);
      dispatch(toggleDeliveryMode(auth.company.companyId, deliveryMode, onSuccess));
    }
  }, []);

  //Check delivery modes
  const calcultateDeliveryModesArray = () => {
    const ret: string[] = [];

    if (auth.company && auth.company.companyDeliveryModes) {
      auth.company.companyDeliveryModes.forEach((element: CompanyDeliveryMode) => {
        ret.push(element.deliveryMode.mode);
      });
    }
    return ret;
  };

  const [deliveryModesArray, setDeliveryModesArray] = useState(calcultateDeliveryModesArray());

  const deliveryModeSuccessCallabck = (deliveryMode: DeliveryModes) => {
    //reload auth to get updated delivery modes
    getAuthCallback();
  };

  const toggle = (deliveryMode: DeliveryModes) => {
    switch (deliveryMode) {
      case DeliveryModes.Ship:
        toggleDeliveryModeCallback(DeliveryModes.Ship, () => deliveryModeSuccessCallabck(DeliveryModes.Ship));
        break;
      case DeliveryModes.Truck:
        toggleDeliveryModeCallback(DeliveryModes.Truck, () => deliveryModeSuccessCallabck(DeliveryModes.Truck));
        break;
      case DeliveryModes.Pipe:
        toggleDeliveryModeCallback(DeliveryModes.Pipe, () => deliveryModeSuccessCallabck(DeliveryModes.Pipe));
        break;
      case DeliveryModes.Container:
        toggleDeliveryModeCallback(DeliveryModes.Container, () => deliveryModeSuccessCallabck(DeliveryModes.Container));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <ButtonContainer
        toggle={toggle}
        initialDeliveryModes={deliveryModesArray}
        addAsset={addAsset}
        getAssetCount={getAssetCount}
      />
      <Modal isOpen={rejectionModal} toggle={toggleRejectionModal} className={styles['deliverymode-modal-rejection']}>
        <ModalHeader toggle={toggleRejectionModal} className={styles['deliverymode-modal-header']}>
          There are active nominations or spot quotations for{' '}
          {' ' + selectedDeliveryMode.toLowerCase() + '-to-ship '} bunkerings
        </ModalHeader>
        <ModalBody className={styles['deliverymode-modal-body']}>
          Please complete or cancel those nominations or spot quotations to disable this delivery mode..
        </ModalBody>
        <ModalFooter className={styles['deliverymode-modal-footer']}>
          <Button color="primary" onClick={toggleRejectionModal}>
            Ok
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeliveryModeContainer;
