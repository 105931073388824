import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './SpotChorusIntegration.module.scss';
import {
  AuthenticationServiceLayer,
  IPromptNomination,
  ICompany,
  ILocation,
  IShipWithId,
  INominationEnquiryEvent,
  INominationEnquiry,
  IPipeline,
  IContract,
  IShipWithCompanyId,
} from '@teqplay/chorus-components';

import './../../../BunkerEvents/Components/ChorusIntegration/ChorusStyleOverrides.scss';

import { DocumentHandler, useEnquiryService, EnquiryNegotiation, useLocationService, CachedLocationService } from '@teqplay/chorus-components';
import { useState } from 'react';
import { ApplicationState } from '../../../../store';
import { Loading } from '../../../../components/Loading/index';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import { ErrorWrapper } from './../../../../models/ErrorAction';
import { Error } from './../../../../components/Error/index';
import { getArchivedSpots } from './../../../../store/ArchivedSpot/actions';
import { getSpotEnquiries } from '../../../../store/Spot/actions';
import { RoleType } from '../../../../models/RoleType';
import { toast } from 'react-toastify';
import useInterval from '../../../../helpers/useInterval';
import { getNominationsInfiniteScroll } from '../../../../store/Nomination/actions';

interface OwnProps {
  spotId: string;
  className?: string;
  bunkerShips: IShipWithCompanyId[];
  bunkerPipelines: IPipeline[];
  companies: ICompany[];
  locationService: CachedLocationService;
  contracts: IContract[];
  yearMonth: string;
  statusUpdateCallback?: (spotId: string, status: string) => void;
  setRenderChat: (b: boolean) => void;
  serviceLayer: AuthenticationServiceLayer;
}

const SpotChorusIntegration: React.FC<OwnProps> = ({
  spotId,
  className,
  bunkerShips,
  bunkerPipelines,
  companies,
  locationService,
  contracts,
  yearMonth,
  statusUpdateCallback = undefined,
  setRenderChat,
  serviceLayer,
}) => {
  const { auth, spots } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    spots: state.spot.data,
  }));

  const errorWrapperObject: ErrorWrapper = {};

  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('1');
  const [newVersionToastShowing, setNewVersionToastShowing] = useState(false);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dispatch = useDispatch();

  const getArchivedSpotCallback = React.useCallback(() => dispatch(getArchivedSpots()), []);

  const getSpotCallback = React.useCallback(() => dispatch(getSpotEnquiries()), []);

  const getNominationCallback = React.useCallback(() => dispatch(getNominationsInfiniteScroll(0)), []);

  const [latestFbStatus, setLatestFbStatus] = useState('');

  const successCallback = (
    action: 'AMEND' | 'COUNTER' | 'CREATE' | 'CONTRACT_ADDED_AND_FINALISED' | 'STATE_UPDATE',
    updatedEnquiry: IPromptNomination | INominationEnquiryEvent | INominationEnquiry | undefined,
  ) => {
    if (updatedEnquiry && statusUpdateCallback != undefined) {
      const updated = updatedEnquiry as IPromptNomination;

      const updatedStatus: string = updated.attributes?.fbStatus ? updated.attributes?.fbStatus : '';
      const updatedEventId = updated.eventId ? updated.eventId : '';
      statusUpdateCallback(updatedEventId, updatedStatus);
    } else if (updatedEnquiry == null || updatedEnquiry == undefined) {
    }
    if (action == 'CONTRACT_ADDED_AND_FINALISED') {
      //get the newly created nomination event
      getNominationCallback();
    }
    getSpotCallback();
    getArchivedSpotCallback();
  };

  React.useEffect(() => {
    if (newVersionToastShowing) toast.dismiss();
    setNewVersionToastShowing(false);
  }, [spotId]);

  const onNewerVersionAvailable = (refetchEnquiry: () => void, setNewerVersion: (bool: boolean) => void) => {
    if (!newVersionToastShowing) {
      setNewVersionToastShowing(true);
      toast(
        <div className={styles['toast']}>
          <p>
            There is a newer version of the quotation available.
            <br />
            If you load the latest version you will lose your changes.
          </p>
          <Button
            color="primary"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              refetchEnquiry();
              getSpotCallback();
              setNewerVersion(false);
            }}
          >
            Load latest quotation
          </Button>
        </div>,
        {
          autoClose: false,
          position: 'bottom-right',
          className: 'warning-notification',
          onClose: () => {
            setNewerVersion(false);
            setNewVersionToastShowing(false);
          },
          closeButton: false,
        },
      );
    }
  };

  const {
    enquirySelection,
    latestEnquiry,
    enquiryHistory,
    updateEnquiryState,
    updateSpotEnquiry,
    fetchEnquiryList,
    addContractAndFinaliseEnquiry,
    refetchEnquiryData,
    checkIfNewerEnquiryAvailable,
    attachFileToEnquiry,
  } = useEnquiryService(spotId, serviceLayer, setLoading, onNewerVersionAvailable, successCallback);
  
  React.useEffect(() => {
    setRenderChat(true);
    const fbStatus = latestEnquiry?.attributes?.fbStatus;
    setLatestFbStatus(fbStatus ? fbStatus : '');
  }, [latestEnquiry]);

  //continually checks if a new spot version is availible
  useInterval(() => {
    checkIfNewerEnquiryAvailable();
  }, 60000);

  if (errorWrapperObject.payload) {
    return <Error error={errorWrapperObject.payload} />;
  } else if (spotId && spotId != '' && latestEnquiry) {
    return (
      <div className={className}>
        <Nav tabs className={`sticky-top bg-white ${styles['navbar']} bunker-events-nav`}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              Quotation details 
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              Documents
            </NavLink>
          </NavItem>
        </Nav>
        {loading && (
          <Loading small={true} type="nomination data" className={styles['loading']}>
            loading
          </Loading>
        )}
        <TabContent activeTab={activeTab} className={'chorus-wrapper'}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <EnquiryNegotiation
                  key={latestEnquiry._id}
                  authenticationService={serviceLayer}
                  user={auth.chorusUserProfile}
                  locationService={locationService}
                  readOnly={false}
                  enquiryHistory={enquiryHistory}
                  enquirySelection={enquirySelection}
                  latestEnquiry={latestEnquiry}
                  updateEnquiryState={updateEnquiryState}
                  updateSpotEnquiry={updateSpotEnquiry}
                  addContractAndFinaliseEnquiry={addContractAndFinaliseEnquiry}
                  showFuelbossStates={true}
                  showDeliveryModeSelection={true}
                  companies={companies}
                  bunkerShips={bunkerShips}
                  pipelines={bunkerPipelines}
                  contractsList={contracts}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <DocumentHandler
                  authenticationService={serviceLayer}
                  eventWithDocuments={latestEnquiry}
                  uploadActive={true}
                  userProfile={auth.chorusUserProfile}
                  uploadFile={attachFileToEnquiry}
                  allowedDocumentTypes={['OTHER']}
                  uploadId={'SpotPage-Documents-Upload'}
                  uploadFileType={'OTHER'}
                  readOnly={false}
                  refetchEvent={() => {
                    refetchEnquiryData();
                  }}
                  updateItem={async (updatedEnquiry) => {
                    await updateSpotEnquiry('AMEND', updatedEnquiry as INominationEnquiryEvent, true);
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  } else if (loading) {
    return <Loading type="Chorus API" />;
  } else {
    setRenderChat(false);
    return null;
  }
};

export default SpotChorusIntegration;
