import * as TYPE from './types';
import { ApplicationState } from '..';

export const integrationAssetReducer = (
  state: ApplicationState['integration_assets'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  
  },
  action: TYPE.IntegrationAssetActions,
): ApplicationState['integration_assets'] => {
  switch (action.type) {
    case TYPE.INTEGRATION_ASSET_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_INTEGRATION_ASSETS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.INTEGRATION_ASSETS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.UPDATE_INTEGRATION_ASSET:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Integration Asset updated',
          type: 'info',
        },
      };
    case TYPE.UPDATE_INTEGRATION_ASSET_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not update the Integraion. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.ADD_INTEGRATION_ASSET:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Integration succesfully added!',
          type: 'success',
        },
      };
    case TYPE.ADD_INTEGRATION_ASSET_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Oops! Something went wrong. We were unable to create the new Integration Asset as an Asset with the same ID already exists. Please contact FuelBoss support for more information.',
          type: 'error',
        },
      };
    case TYPE.DELETE_INTEGRATION_ASSET:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Integration Asset succesfully deleted',
          type: 'success',
        },
      };
    case TYPE.DELETE_INTEGRATION_ASSET_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not delete Integration Asset. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.INTEGRATION_ASSET_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    default:
      return { ...state };
  }
};
