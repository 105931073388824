import * as TYPE from './types';

import { ApplicationState } from '..';

export const recurring_nominationReducer = (
  state: ApplicationState['recurring_nomination'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: undefined,
  },
  action: TYPE.RecurringNominationActions,
): ApplicationState['recurring_nomination'] => {
  switch (action.type) {
    case TYPE.RECURRINGNOMINATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.RECURRINGNOMINATION_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.RECURRINGNOMINATION_DELETE_ERROR:
      return {
        ...state,
        error: true,
        errorDetails: action.payload,
        toastData: {
          content: 'The recurring nomination could not be deleted. Please contact FuelBoss support for more details',
          type: 'error',
        },
      };
    case TYPE.DELETE_RECURRINGNOMINATION:
      return {
        ...state,
        data: action.payload, //state.data.filter((n) => n.id !== action.payload.id),
        loading: false,
        error: false,
        toastData: {
          content: 'Recurring nomination deleted',
          type: 'info',
        },
      };
    case TYPE.UPDATE_RECURRINGNOMINATION:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Recurring nomination updated',
          type: 'info',
        },
      };
    case TYPE.UPDATE_RECURRINGNOMINATION_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not update the recurring nomination. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.ADD_RECURRINGNOMINATION:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Recurring nomination succesfully added!',
          type: 'success',
        },
      };
    case TYPE.ADD_RECURRINGNOMINATION_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `Number of scheduled events 0. If not please contact Fuelboss support`
            : `Something went wrong! We could not create the new recurring nomination. Please contact FuelBoss support for more details.`,
          type: 'error',
        },
      };
    case TYPE.GET_RECURRINGNOMINATION:
      if (action.payload.metaData) {
        return {
          ...state,
          //append Z at the end of dates to consider them UTC date times at the frontend
          data: action.payload.data.map((el) =>
            true
              ? {
                  ...el,
                  startDate: el.startDate + 'Z',
                  endDate: el.endDate + 'Z',
                  eta: el.eta + 'Z',
                  etd: el.etd + 'Z',
                  bst: el.bst + 'Z',
                  timeStamp: el.timeStamp + 'Z',
                }
              : el,
          ),
          metadata: action.payload.metaData,
          loading: false,
          error: false,
          dataIsLoaded: true,
        };
      }
      return {
        ...state,
        //append Z at the end of dates to consider them UTC date times at the frontend
        data: action.payload.data.map((el) =>
          true
            ? {
                ...el,
                startDate: el.startDate + 'Z',
                endDate: el.endDate + 'Z',
                eta: el.eta + 'Z',
                etd: el.etd + 'Z',
                bst: el.bst + 'Z',
                timeStamp: el.timeStamp + 'Z',
              }
            : el,
        ),
        loading: false,
        error: false,
        dataIsLoaded: true,
      };

    default:
      return { ...state };
  }
};
