import React from 'react';
import styles from './SanityContentPage.module.scss';
import BlockContent from '@sanity/block-content-to-react';
import { Table } from 'reactstrap';
import imageUrlBuilder from '@sanity/image-url';
import { Node } from '../../store/Sanity/models';

const SanityTable: React.FC<{
  rows: any;
}> = ({ rows }) => (
  <Table className={styles.table} data-pdf-break="true">
    <tbody>
      {rows.map((row: any, rowIndex: any) =>
        rowIndex == 0 ? (
          <tr className={styles.tr} key={rowIndex}>
            {row.cells.map((cell: any, headerIndex: any) => (
              <th key={headerIndex} className={styles.th}>
                {cell}
              </th>
            ))}
          </tr>
        ) : (
          <tr className={styles.tr} key={rowIndex}>
            {row.cells.map((cell: any, dataIndex: any) => (
              <td key={dataIndex} className={styles.td}>
                {cell}
              </td>
            ))}
          </tr>
        ),
      )}
    </tbody>
  </Table>
);



interface OwnProps {
  sanityContentPage: any;
  sanityProjectId: string;
  santiyDataSet: string;
}

export const SanityContentPage: React.FC<OwnProps> = ({ sanityContentPage, sanityProjectId, santiyDataSet }) => {
  if (!sanityContentPage) {
    return <div></div>;
  }
  const builder = imageUrlBuilder({
    projectId: sanityProjectId,
    dataset: santiyDataSet
  });

  function urlFor(source: any) {
    return builder.image(source).url();
  }

  const serializers = {
    types: {
      mainImage: ({ node }: { node: Node }) => {
        const imageUrl = urlFor(node.asset);    
        return <img data-pdf-break="true" className={styles['image']} src={imageUrl || ''} alt={node.alt || ''}  />
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.pagewrapper}>
        <div className={styles.title}>{sanityContentPage.title}</div>
        {sanityContentPage.contentSections.map((contentsection: any, index: any) => (
          <div key={index} className={styles.contentSection}>
            <h4 className={styles.contentTitle}>{contentsection.contentSectionTitle}</h4>
            <div className={styles['subtitle']}>{contentsection.contentSectionSubTitle}</div>
            <div className={styles.content}>
              {contentsection._type == 'contentSection' &&
                contentsection.paragraph.map((para: any, blockIndex: any) => (
                  <BlockContent
                    blocks={para}
                    projectId={sanityProjectId}
                    dataset={santiyDataSet}
                    serializers={serializers}
                    key={blockIndex}
                  />
                ))}
              {contentsection._type == 'table' && <SanityTable rows={contentsection.rows}></SanityTable>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

