/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Column, Row } from 'react-table';
import FuelBossTable from '../../../../components/FuelBossTable';
import { Loading } from '../../../../components/Loading';
import DeliveryModeIcon from '../../../../components/Nomination/Components/DeliveryModeIcon/DeliveryModeIcon';
import { getSpotEventIds } from '../../../../helpers/spotEnquiryHelper';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { ApplicationState } from '../../../../store';
import { getArchivedSpotsPaginated } from '../../../../store/ArchivedSpot/actions';
import { Nomination } from '../../../../store/Nomination/models';
import { getSpotEnquiriesPaginated } from '../../../../store/Spot/actions';
import { SpotEnquiry } from '../../../../store/Spot/models';
import styles from './OverviewSpotList.module.scss';
import { Error } from '../../../../components/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome';
import CountDownCell from '../OverviewNominationList/Components/CountDownCell/CountDownCell';
import useInterval from '../../../../helpers/useInterval';
import { isQuantityInt } from '../OverviewNominationList/Utils/Utils';
import NoNominations from '../../../../components/Nomination/Components/NoNominations/NoNominations';

const downArrowIcon = findIconDefinition({ prefix: 'fal', iconName: 'arrow-down' });

interface OwnProps {
  deliveryModes: DeliveryModes[];
  sortBy: 'time' | 'needsAction';
  userIsShipOwner: boolean;
  archived?: boolean;
  refetchInterval?: number;
  fetchData?: boolean;
}

const OverviewSpotList: React.FC<OwnProps> = ({
  deliveryModes,
  sortBy,
  userIsShipOwner,
  archived = false,
  refetchInterval = 60000,
  fetchData = true,
}) => {
  const dispatch = useDispatch();
  const selector = archived
    ? (state: ApplicationState) => state.archived_spot
    : (state: ApplicationState) => state.spot;
  const spots = useSelector((state: ApplicationState) => selector(state).data);
  const spotsLoading = useSelector((state: ApplicationState) => selector(state).loading);
  const spotsMetadata = useSelector((state: ApplicationState) => selector(state).metadata);
  const spotsError = useSelector((state: ApplicationState) => selector(state).error);
  const spotsErrorDetails = useSelector((state: ApplicationState) => selector(state).errorDetails);
  const spotsLoaded = useSelector((state: ApplicationState) => selector(state).dataIsLoaded);
  const [pageSize, setPageSize] = useState(20);

  const getSpots = () => {
    if (!fetchData) return;
    dispatch(
      archived
        ? getArchivedSpotsPaginated(0, pageSize)
        : getSpotEnquiriesPaginated(sortBy === 'needsAction', 0, pageSize),
    );
  };

  useEffect(getSpots, [pageSize, sortBy, fetchData]);
  useInterval(getSpots, refetchInterval);

  const statusSort = useCallback((a, b, id, desc) => {
    if (a.original.attributes?.needsAction) return 1;
    if (b.original.attributes?.needsAction) return -1;
    return 0;
  }, []);

  const deliveryModeFilter = useCallback(
    (rows: Row<SpotEnquiry>[], _, value) =>
      rows.filter((r) => r.original.deliveryModes.some((mode) => value.includes(mode))),
    [],
  );

  const columns: Array<Column<SpotEnquiry>> = React.useMemo(
    () => [
      {
        id: 'fbStatus',
        Header: '',
        accessor: (row) => row.attributes?.fbStatus,
        Cell: (props: any) => (
          <div className={`${styles['status']} ${styles[props.cell.row.original.attributes.fbStatus]}`}></div>
        ),
        sortType: statusSort,
        disableSortBy: true,
      },
      {
        id: 'bst',
        Header: 'Days left',
        accessor: (row) => row.bst,
        Cell: (props: any) => (
          <CountDownCell bst={props.cell.row.original.bst} fbStatus={props.cell.row.original.attributes.fbStatus} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Vessel',
        accessor: (row) => row.attributes?.receivingShipName,
        disableSortBy: true,
      },

      {
        Header: 'Port',
        accessor: (row) => `${row.attributes?.port}, ${row.attributes?.country}`,
        disableSortBy: true,
      },
      {
        Header: 'Quantity',
        accessor: (row) => row.amount,
        Cell: (props: any) => (
          <p>
            {isQuantityInt(props.cell.row.original.amount)
              ? props.cell.row.original.amount
              : props.cell.row.original.amount.toFixed(2)}{' '}
            {props.cell.row.original.quantityUnit === 'TONNES' ? (
              'mt'
            ) : (
              <>
                m<sup>3</sup>
              </>
            )}
          </p>
        ),
        disableSortBy: true,
      },
      {
        id: 'deliveryModes',
        Header: 'Delivery mode',
        accessor: (row) => row.deliveryModes,
        Cell: (props: any) => (
          <div className={styles['delivery-modes-wrapper']}>
            {props.cell.row.original.deliveryModes.map((deliveryMode: DeliveryModes) => (
              <DeliveryModeIcon key={deliveryMode} deliveryMode={deliveryMode} />
            ))}
          </div>
        ),
        filter: deliveryModeFilter,
        disableSortBy: true,
      },
      {
        id: 'eta',
        Header: 'Date',
        accessor: (row) => row.bst,
        Cell: (props: any) => (
          <div>
            <Moment date={props.cell.row.original.bst} format="DD.MM.YYYY" />
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const history = useHistory();

  if (spotsError) return spotsErrorDetails ? <Error error={spotsErrorDetails} /> : null;
  if (!spotsLoaded) return <Loading />;
  if (spots.length == 0) {
    return <NoNominations className={styles['message-wrapper']} archived={archived} />;
  }
  return (
    <div className={styles['root']}>
      <FuelBossTable
        showSortIcons={false}
        showFilterSearch={false}
        showPagination={false}
        headers={columns}
        tableRows={spots}
        filters={[{ id: 'deliveryModes', value: deliveryModes }]}
        sortBy={sortBy === 'needsAction' ? [{ id: 'fbStatus', desc: true }] : [{ id: 'bst', desc: true }]}
        onRowClick={(row: SpotEnquiry) =>
          history.push(
            userIsShipOwner
              ? {
                  pathname: `/requestquotation/${archived ? 'archived' : 'spots'}/${getSpotEventIds(row).join(';')}`,
                  state: { redirectedSpotObject: row },
                }
              : {
                  pathname: `/requestquotation/${archived ? 'archived' : 'spots'}/${(row as Nomination).eventId}`,
                  state: { redirectedSpotObject: row },
                },
          )
        }
        rowStyle={(row: SpotEnquiry) => ({
          backgroundColor: row.attributes?.fbStatus === 'action-required' ? '#fff2f3' : 'white',
        })}
      />
      {spotsLoading && <Loading small />}
      {spotsMetadata?.hasNextPage && (
        <Button className={styles['show-more-button']} onClick={() => setPageSize(pageSize + 10)}>
          Show more quotations <FontAwesomeIcon icon={downArrowIcon} />
        </Button>
      )}
    </div>
  );
};

export default OverviewSpotList;
