import * as Yup from 'yup';
import moment from 'moment';
import contractConfigs from './ContractForm/contractConfigs';

const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
const formatDate = (date) => new Date(date).toLocaleDateString('en-GB', options);
const validationMessage = { message: 'Not valid', excludeEmptyString: true };
const today = new Date();
today.setHours(0, 0, 0, 0);

export default Yup.object().shape({
  name: Yup.string().matches(contractConfigs.name.regex, validationMessage).required('Name required'),
  supplierId: Yup.string().required('Supplier ID required'),
  customerId: Yup.string().required('Customer ID required'),
  contractRef: Yup.string()
    .matches(contractConfigs.contractRef.regex, validationMessage)
    .required('Contract reference required'),
  startDate: Yup.date()
    .nullable()
    .required('Start date required'),
  endDate: Yup.date()
    .required('End date required')
    .nullable()
    .min(Yup.ref('startDate'), ({ min }) => `Date needs to be after ${formatDate(min)}`),
  endDateOption: Yup.date()
    // .required('End date option required')
    .nullable()
    .min(Yup.ref('startDate'), ({ min }) => `Date needs to be after ${formatDate(min)}`),
  signedDate: Yup.date()
    .required('Signed date required')
    .max(moment().endOf('day').toDate(), 'Signed date cannot be in the future'),
  quantityMinimum: Yup.number().min(0, 'Not valid').optional(),
  quantityOption: Yup.number().min(0, 'Not valid').optional(),
  pricePerUnit: Yup.string().optional(),
  currency: Yup.string().optional(),
  unit: Yup.string().optional(),
  calorificBasis: Yup.string().optional(),
  notes: Yup.string().optional(),
  negotiateOnPrice: Yup.boolean().optional(),
  invoiceEmailAddress: Yup.string().nullable().matches(contractConfigs.invoiceEmailAddress.regex, validationMessage),
});
