import { User } from '../../../../store/Users/models';
import { Company } from '../../../../store/Company/models';
import { Category } from '../../../../store/Category/models';
import { Vessel } from '../../../../store/Vessels/models';
import { FleetVessel } from '../../../../store/CompanyFleet/models';
import { RoleType } from '../../../../models/RoleType';
import { Role } from '../../../../store/Roles/models';

const initialUserFormCategory: Category = {
  id: '',
  categoryName: '',
  chorusType: '',
};

const initialUserFormCompany: Company = {
  name: '',
  country: '',
  address: '',
  orgNumber: '',
  areaOfInterest: '',
  category: initialUserFormCategory,
  invoiceAddress: '',
  invoiceEmailAddress: '',
};

const initialUserFormVessel: Vessel = {
  vesselName: '',
  vesselType: '',
  owner: '',
  id: '',
  fleetVessel: [],
  lngBunkerParticulars: [],
  shipParticulars: [],
};

const initialUserFormFleetVessel: FleetVessel = {
  id: '',
  vessel: initialUserFormVessel,
  company: initialUserFormCompany,
  createdAt: new Date(),
  companyId: '',
  chorusId: '',
  vesselId: '',
  ownerCompany: '',
  charterable: false,
  fleetCharterers: [],
};

// @ts-ignore
const initialUserFormRoleType: RoleType = RoleType.Operator;
const initialUserFormRole: Role = {
  id: '',
  roleType: initialUserFormRoleType,
  chorusRole: '',
};

export const initialValues: User = {
  firstName: '',
  lastName: '',
  email: '',
  company: initialUserFormCompany,
  role: '',
  userRoles: [],
  isAdmin: false,
  veracityVerified: false,
  veracitySubscription: false,
  createdAt: new Date(),
};
