import React, { useState } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAuth } from '../../../../../store/Auth/actions';
import { Company } from '../../../../../store/Company/models';
import { getCompanies } from '../../../../../store/Company/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { renderTextInput, renderComplexDropdown, renderSimpleDropdown, renderTextAreaInput } from '../../../../Form/FormInputs';
import integrationAssetConfigs from './integrationAssetConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import { IntegrationAssetCompany } from '../../../../../store/IntegrationAssetCompanies/models';
import { deleteIntegrationAssetWorkspace, getIntegrationAssetWorkspaces } from '../../../../../store/IntegrationAssetWorkspaces/actions';
import { IntegrationAssetWorkspace } from '../../../../../store/IntegrationAssetWorkspaces/models';
import { Column } from 'react-table';
import Moment from 'react-moment';
import DeleteById from '../../../../DeleteById/DeleteById';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import FuelBossTableContainer from '../../../../FuelBossTable';
import EditById from '../../../../EditById/EditById';


const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const PlusCircle = findIconDefinition({ prefix: 'fal', iconName: 'plus-circle' });

const StyledInnerForm = styled.div`
  width: 70%;
  @media (max-width: 1920px) {
    width: 90%;
  }
  display: block;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
margin-top: 1em;
.formHeader {
  flex: 3 1 0%;
  font-weight: 600;
  font-size: 1.75em;
  padding-left: 5px;
}
.buttonContainer {
  display: flex;
  flex: 0 1 0%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: right;
}
.buttonContainer button {
  justify-self: flex-end;
  margin-left: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 18px;
  font-size: 18px;
}
div[class^='input-'] {
  flex: 0 1 40%;
  margin-right: 35px;
  width: 35%;
}
button {
  justify-self: flex-end;
  margin-right: 0.5em;
}
.form-check {
  margin-right: 5px;
}
`;

const StyledFormSectionHeader = styled.div`
  color: rgb(0, 0, 0);
  line-height: 24px;
  font-weight: bold;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: left;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  font-size: 24px;
  margin: 15px 0px 10px;
  :before {
    content: '';
    border-top: 1px solid rgb(204, 204, 204);
    margin: 0px 10px 0px 0px;
    flex: 0 0 43px;
  }
  :after {
    content: '';
    border-top: 1px solid rgb(204, 204, 204);
    margin: 0px 20px 0px 10px;
    flex: 1 0 20px;
  }
`;

const StyledIntegrationAssetTypeTableWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

interface OwnProps {
  integrationAssetCompany?: IntegrationAssetCompany;
  header: string;
  saveMethod: (integrationAssetCompany: IntegrationAssetCompany) => void;
  companyAdminForm?: boolean
  systemAdminPage?: boolean;
  formikProps: FormikProps<IntegrationAssetCompany>;
  navAdminType: string;
}

const IntegrationAssetCompanyForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  integrationAssetCompany,
  systemAdminPage = false,
  companyAdminForm = false,
  navAdminType,
  formikProps,
}) => {
  const {
    authLoading,
    authError,
    authErrorDetails,
    authIsLoaded,

    companies,
    companiesIsLoaded,
    companiesLoading,
    companiesError,
    companiesErrorDetails,

    integrationAssetWorkspaces,
    integrationAssetWorkspacesLoading,
    integrationAssetWorkspacesDetailsError,
    integrationAssetWorkspacesErrorDetails,
    integrationAssetWorkspacesIsLoaded,
    toastDataIntegrationAssetWorkspaces,

  } = useSelector((state: ApplicationState) => ({
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    companiesLoading: state.companies.loading,
    companiesError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,

    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    integrationAssetWorkspaces: state.integration_assets_workspaces.data,
    integrationAssetWorkspacesLoading: state.integration_assets_workspaces.loading,
    integrationAssetWorkspacesDetailsError: state.integration_assets_workspaces.error,
    integrationAssetWorkspacesErrorDetails: state.integration_assets_workspaces.errorDetails,
    integrationAssetWorkspacesIsLoaded: state.integration_assets_workspaces.dataIsLoaded,
    toastDataIntegrationAssetWorkspaces: state.integration_assets_workspaces.toastData,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);
  const [isEditMode, setIsEditMode] = useState<boolean>();
  const history = useHistory();

  const getAllCompaniesCallback = useCallback(() => dispatch(getCompanies()), []);
  const getAllWorkspacesCallback = useCallback(() => dispatch(getIntegrationAssetWorkspaces()), []);
  const deleteIntegrationAssetWorkspaceCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetWorkspace(id)),
    [],
  );
  const [selectedPage, setDefaultSelectedPage] = React.useState(0);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
    if (!integrationAssetWorkspacesIsLoaded) {
      getAllWorkspacesCallback();
    }
  }, []);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getAllCompaniesCallback();
    }
  }, [companiesIsLoaded]);

  useEffect(() => {
    if (formikProps.values.company) {
      if (formikProps.values.company.chorusId)
        formikProps.values.companyId = formikProps.values.company.chorusId;
      formikProps.values.companyName = formikProps.values.company.name;
    }
  }, [formikProps.values.company])

  useEffect(() => {
    if (integrationAssetCompany) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [integrationAssetCompany])


  const onFormSave = () => {

    formikProps.submitForm();
    //const roleArray = getRolesArray();

    const integrationAssetCompanyToSave: IntegrationAssetCompany = {
      ...formikProps.values,
      //  active: true,
    };
    if (integrationAssetCompany) {
      integrationAssetCompanyToSave.veracityTenantId = integrationAssetCompany.veracityTenantId;
    }
    if (formikProps.isValid) {
      saveMethod(integrationAssetCompanyToSave);
    }
  };

  const headersWorkspaces: Array<Column<IntegrationAssetWorkspace>> = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Veracity Workspace Id',
        accessor: 'workspace',
      },
      {
        Header: 'Name',
        accessor: (row) => {
          return row.name;
        },
      },
      {
        Header: 'Time stamp',
        accessor: 'timeStamp',
        Cell: (props: any) => {
          const timeStamp = props.cell.row.original.timeStamp;
          return <Moment date={timeStamp} format={timeStamp ? 'DD MMM, YYYY HH:mm' : ''} />;
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const integrationAssetWorkspace = props.cell.row.original;
          return (
            <div className="edit-cell">
              {integrationAssetCompany && <EditById redirectUrl={`/${navAdminType}admin/integrationassets/${integrationAssetCompany.companyId}/editcompany/${integrationAssetWorkspace.id}/editworkspace`} />}
            </div>
          );
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const integrationAssetWorkspace = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={integrationAssetWorkspace.id ? integrationAssetWorkspace.id : ''}
                deleteMethod={deleteIntegrationAssetWorkspaceCallback}
                header="Delete integration asset topic"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  let workspacesList = integrationAssetCompany?.companyId ? integrationAssetWorkspaces.filter((x) => x.integrationAssetCompanyCompanyId.includes(integrationAssetCompany?.companyId)) : [];

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetConfigs, errors, touched, setFieldValue, setFieldTouched };

  if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save integration asset company"
          cancelButtonText="Cancel"
          formUrl="integrationassets/companies"
        />
        <StyledInnerForm as={Form}>
          {isEditMode && renderTextInput('companyName', isEditMode, props)}
          {!companyAdminForm && !isEditMode && renderComplexDropdown('company', companies, false, 'name', props)}
          {formikProps.values.companyName && renderTextInput('veracityTenantId', isEditMode, props)}
        </StyledInnerForm>
        {isEditMode && <StyledFormSectionHeader>Workspaces</StyledFormSectionHeader>}

        {isEditMode && (
          <div style={{ width: '100%' }}>
            {workspacesList.length > 0 && (<StyledIntegrationAssetTypeTableWrapper>
              <FuelBossTableContainer
                headers={headersWorkspaces}
                tableRows={workspacesList}
                showFilterSearch={true}
                showPagination={true}
                defaultSelectedPage={selectedPage}
                setDefaultSelectedPage={setDefaultSelectedPage}
              />
            </StyledIntegrationAssetTypeTableWrapper>)}
            <br />{' '}
            <div className="buttons-div">
              <Button
                color="primary"
                size="lg"
                onClick={() => {
                  history.push(`/${navAdminType}admin/integrationassets/${integrationAssetCompany?.veracityTenantId}/editcompany/addworkspace`)
                }}
              >
                <FontAwesomeIcon icon={PlusCircle} />
                Add workspace
              </Button>
            </div>
            <br />
          </div>
        )}
      </StyledForm>
    );
  }
};

export default React.memo(IntegrationAssetCompanyForm);
