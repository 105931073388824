import {
  SanityConfigWrapperModel,
} from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';


export const SANITY_CONFIG_LOADING = 'sanityconfig/loading';
export type SANITY_CONFIG_LOADING = typeof SANITY_CONFIG_LOADING;

export const GET_SANITY_CONFIG = 'sanityconfig/get';
export type GET_SANITY_CONFIG = typeof GET_SANITY_CONFIG;

export const SANITY_CONFIG_FETCH_ERROR = 'sanityconfig/fetchError';
export type SANITY_CONFIG_FETCH_ERROR = typeof SANITY_CONFIG_FETCH_ERROR;

export type MapActions =
  | {
      type: GET_SANITY_CONFIG;
      payload: SanityConfigWrapperModel['data'];
    }
  | {
      type: SANITY_CONFIG_LOADING;
    }
  | {
      type: SANITY_CONFIG_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
