import {
  getNominationStatusIcon,
  instanceOfNominationTimelineItem,
  isCustomer,
  isOneOf,
  isScheduler,
  isSchedulerCaptain,
  IPromptNomination,
  IUserProfile,
  INominationEnquiryEvent,
  ICompany,
  Icon,
  NegotiationState
} from '@teqplay/chorus-components'
import React from 'react'
//import Tag from '../../sharedComponents/tag/Tag'
//import SandBoxWarningMessage from '../sandboxWarningMessage/SandboxWarningMessage'
import ScheduleInformation from '../ScheduleInformation/ScheduleInformation'
import NegotiationStatusSummary from './NegotiationStatusSummary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faShare } from '@fortawesome/pro-light-svg-icons'
import { Tag } from '@teqplay/chorus-components'
import { RecurringNomination } from '../../../../../store/RecurringNomination/models'
import { instanceOfRecurringNomination } from '../Validators/recurringNominationValidation'

interface NegotiationStatusHeaderProps {
  negotiationItem?: IPromptNomination | INominationEnquiryEvent | RecurringNomination
  receivingVesselName?: string
  isNominationInSandbox: boolean
  userProfile: IUserProfile
  showFuelbossStates?: boolean
  companies: ICompany[]
  isDelegatedNomination?: boolean
}

const shareIcon = findIconDefinition({ prefix: 'fal', iconName: 'share' });
const repeat = findIconDefinition({ prefix: 'fal', iconName: 'repeat-alt' });

const NegotiationStatusHeader: React.FunctionComponent<NegotiationStatusHeaderProps> = props => {
  const { negotiationItem, receivingVesselName, isNominationInSandbox } = props

  const stateText = negotiationItem ? getStateText(negotiationItem) : undefined
  const takeAction = getTakeAction(negotiationItem)

  const hideStateIcon = !isOneOf(negotiationItem?.state, ['CANCELLED', 'COMPLETED', 'FINALISED'])

  const getRecurringNominationStatusIcon =(state?: NegotiationState | undefined | 'RECURRING EVENT', takeAction?: boolean | undefined) =>{
    return (
      <>
      { state == 'RECURRING EVENT' &&
        <Icon>
          <FontAwesomeIcon icon={repeat} />
        </Icon>
      }
      </>
    );
  }

  const isDelegatedNomination: boolean = (negotiationItem as IPromptNomination)
    ?.delegationOriginEventId
    ? true
    : false

  if (!negotiationItem) {
    return null
  }

  return (
    <div className="nomination-status-header">
      {!instanceOfRecurringNomination(negotiationItem) && <NegotiationStatusSummary negotiationItem={negotiationItem} />}
      <div className="nomination-delivery-info-box">
        <Tag
          tagText={stateText}
          className={`nomination-status ${negotiationItem?.state} ${
            takeAction ? 'TAKE_ACTION' : ''
          }`}
        >
          <div
            className={`status-icon nomination-status-color ${negotiationItem?.state} ${
              takeAction ? 'TAKE_ACTION' : ''
            }`}
          >
            {/* {hideStateIcon && instanceOfRecurringNomination(negotiationItem) && getRecurringNominationStatusIcon(negotiationItem?.state, takeAction)} */}
            {hideStateIcon && !instanceOfRecurringNomination(negotiationItem) && getNominationStatusIcon(negotiationItem?.state, takeAction)}
          </div>
        </Tag>

        {negotiationItem &&
          instanceOfNominationTimelineItem(negotiationItem) &&
          negotiationItem.hasPendingProposal &&
          negotiationItem.state !== 'PENDING' && (
            <Tag tagText={'AWAITING PENDING PROPOSAL'} className={`nomination-status PENDING`} />
          )}

        <h2 className="nomination-header">
          {isDelegatedNomination && (
            <Icon>
              <FontAwesomeIcon icon={shareIcon} />
            </Icon>
          )}
          {receivingVesselName
            ? receivingVesselName
            : instanceOfNominationTimelineItem(props.negotiationItem)
            ? 'Bunker Nomination'
            : 'Bunker Enquiry'}
        </h2>

        {/* {instanceOfNominationTimelineItem(negotiationItem) && (
          <SandBoxWarningMessage
            isNominationInSandbox={isNominationInSandbox}
            initialNomination={negotiationItem}
          />
        )} */}

        <ScheduleInformation selectedNomination={negotiationItem} companies={props.companies} />
      </div>
    </div>
  )

  function getStateText(nomination: IPromptNomination | INominationEnquiryEvent | RecurringNomination) {
    // if no state is present, the form is being edited and the state should be editing
    // Otherweise if the take action flag is active, the state should display a custom state message
    // Otherwise we can just use the state to display.
    if (!nomination.state) {
      return 'EDITING'
    } 
    //special case for recurring event
    //@ts-ignore
    else if (nomination.state === 'RECURRING EVENT') {
      return 'RECURRING EVENT';
    }
    else {
      if (nomination.state === 'PROPOSED') {
        if (
          isCustomer(props.userProfile.roles) ||
          isScheduler(props.userProfile.roles) ||
          isSchedulerCaptain(props.userProfile.roles)
        ) {
          const isContractHolder = props.userProfile.companyId === nomination.companyId
          const opposingParty = isCustomer(props.userProfile.roles)
            ? 'SUPPLIER'
            : props.isDelegatedNomination === true
            ? isContractHolder
              ? 'EXECUTING SUPPLIER'
              : 'CONTRACT-HOLDING SUPPLIER'
            : 'CUSTOMER'
          if (nomination.attributes?.needsAction === true) {
            return `NEW PROPOSAL FROM ${opposingParty}`
          } else {
            return `PROPOSAL SENT TO ${opposingParty}`
          }
        } else {
          return showNegotiationState(nomination, props.showFuelbossStates)
        }
      } else {
        return showNegotiationState(nomination, props.showFuelbossStates)
      }
    }
  }

  function showNegotiationState(
    negotiationEvent: NegotiationStatusHeaderProps['negotiationItem'],
    showFuelBossStates?: boolean
  ) {
    const negotiationState = showFuelBossStates
      ? negotiationEvent?.attributes?.fbStatus
      : negotiationEvent?.state
    return negotiationState ? negotiationState : ''
  }
}

export default React.memo(NegotiationStatusHeader)

function getTakeAction(negotiationItem?: IPromptNomination | INominationEnquiryEvent | RecurringNomination) {
  return negotiationItem
    ? negotiationItem.attributes?.needsAction
      ? negotiationItem.attributes?.needsAction
      : false
    : false
}
