import React from 'react';
import { Company } from '../../../../../store/Company/models';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import FormikWrapper from '../Formik/FormikWrapper';
import { Loading } from '../../../../Loading';

interface OwnProps {
  editIntegrationAsset: (integrationAsset: IntegrationAsset) => void;
  integrationAssets: IntegrationAsset[];
  integrationAssetId?: string;
  navAdminType: string;
  systemAdmin?: boolean;
  integrationAsset?: IntegrationAsset;
  isLoading?: boolean;
  veracityClientId?: string | undefined;
  setVeracityClientId: (value: string) => void;
  assetId?: string | undefined;
  setAssetId: (value: string) => void;
  assetCompany?: string | undefined;
  setAssetCompany: (value: string) => void;
}

const EditIntegrationAsset: React.FC<OwnProps> = ({
    editIntegrationAsset,
    integrationAssets,
    integrationAssetId,
    navAdminType,
    systemAdmin,
    integrationAsset,
    isLoading,
    veracityClientId,
    setVeracityClientId,
    assetId,
    setAssetId,
    assetCompany,
    setAssetCompany,
}) => {
  const submitIntegrationAsset = (integrationAsset: IntegrationAsset) => {
    editIntegrationAsset(integrationAsset);
  };

  if (!integrationAsset && isLoading) {
    return <Loading />;
  }

  if(!integrationAsset){
    const integrationasset = integrationAssets.find((it) => it.id === integrationAssetId);
    if(integrationasset?.veracityAssetId){
    setVeracityClientId(integrationasset.veracityAssetId);
    setAssetId(integrationasset.id);
    if(integrationasset.ownerCompanyId)
    setAssetCompany(integrationasset.ownerCompanyId);
    }
  
  }


  return (
    <FormikWrapper
      header="Edit integration asset"
      integrationAsset={integrationAsset ? integrationAsset : integrationAssets.find((it) => it.id === integrationAssetId)}
      addIntegrationAsset={submitIntegrationAsset}
      navAdminType={navAdminType}
      systemAdmin={systemAdmin}
    />
  );
};

export default React.memo(EditIntegrationAsset);
