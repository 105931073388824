import { Dispatch } from 'redux';
import {
  IntegrationAssetTopicActions,
  INTEGRATION_ASSET_TOPIC_CLEAR_TOAST,
  INTEGRATION_ASSET_TOPIC_LOADING,
  GET_INTEGRATION_ASSET_TOPIC,
  INTEGRATION_ASSET_TOPIC_FETCH_ERROR,
  ADD_INTEGRATION_ASSET_TOPIC,
  DELETE_INTEGRATION_ASSET_TOPIC,
  EDIT_INTEGRATION_ASSET_TOPIC,
  INTEGRATION_ASSET_TOPIC_EDIT_ERROR,
  INTEGRATION_ASSET_TOPIC_ADD_ERROR,
  INTEGRATION_ASSET_TOPIC_DELETE_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { IntegrationAssetTopicResponseModel, IntegrationAssetTopic } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = (
  <ModalResponse status="loading">Updating integration asset topic...</ModalResponse>
);
const updateSuccessModal: ReactElement = (
  <ModalResponse status="success">Integration asset topic updated successfully</ModalResponse>
);

const createLoaderModal: ReactElement = (
  <ModalResponse status="loading">Creating integration asset topic...</ModalResponse>
);
const createSuccessModal: ReactElement = (
  <ModalResponse status="success">Integration asset topic created successfully</ModalResponse>
);

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getIntegrationAssetTopic = (assetId?: string) => (
  dispatch: Dispatch<IntegrationAssetTopicActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_TOPIC_LOADING,
  });
  const url = assetId ? `/api/Integration/Topic/${assetId}` : `/api/Integration/Topic`;
  new api<IntegrationAssetTopicResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_TOPIC_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_INTEGRATION_ASSET_TOPIC,
        payload: response,
      });
    });
};

export const addIntegrationAssetTopic = (
  integrationAssetTopic: IntegrationAssetTopic,
  onSuccess: () => void,
) => (dispatch: Dispatch<IntegrationAssetTopicActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_TOPIC_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<IntegrationAssetTopicResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_TOPIC_ADD_ERROR,
  })
    .post(`api/Integration/Topic`, integrationAssetTopic)
    .then((response) => {
      dispatch({
        type: ADD_INTEGRATION_ASSET_TOPIC,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteIntegrationAssetTopic = (id: string) => (
  dispatch: Dispatch<IntegrationAssetTopicActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_TOPIC_LOADING,
  });

  const url = `api/Integration/Topic/${id}`;
  new api<IntegrationAssetTopicResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_TOPIC_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_INTEGRATION_ASSET_TOPIC,
        payload: response,
      });
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const editIntegrationAssetTopic = (
  integrationAssetTopic: IntegrationAssetTopic,
  onSuccess: () => void,
) => (dispatch: Dispatch<IntegrationAssetTopicActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_TOPIC_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (integrationAssetTopic.id != undefined) {
    const url = `api/Integration/Topic/${integrationAssetTopic.id}`;

    new api<IntegrationAssetTopicResponseModel>({
      dispatch,
      errorType: INTEGRATION_ASSET_TOPIC_EDIT_ERROR,
    })
      .put(url, integrationAssetTopic)
      .then((response) => {
        dispatch({
          type: EDIT_INTEGRATION_ASSET_TOPIC,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const integrationAssetTopicClearToast = () => (
  dispatch: Dispatch<IntegrationAssetTopicActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_TOPIC_CLEAR_TOAST,
  });
};
