import * as TYPE from './types';
import { ApplicationState } from '..';

export const userVesselReducer = (
  state: ApplicationState['myFleet'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.UserVesselActions,
): ApplicationState['myFleet'] => {
  switch (action.type) {
    case TYPE.USERVESSEL_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_USERVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.USERVESSEL_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_USERVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.DELETE_USERVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.EDIT_USERVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
  }
  return { ...state };
};
