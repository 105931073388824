import * as React from 'react';
import { Vessel } from '../../../../store/Vessels/models';
import styles from '../../SearchVessel.module.scss';
import classnames from 'classnames';

interface OwnProps {
  vessel: Vessel;
  chooseVessel: (vessel: Vessel, index: number) => void;
  active: boolean;
  index: number;
}

const SearchVesselResult: React.FunctionComponent<OwnProps> = ({ vessel, chooseVessel, active, index }) => (
  <>
    <li
      onClick={() => chooseVessel(vessel, index)}
      className={classnames(active ? styles.active : '', styles.suggestion)}
    >
      <div className={styles.name}>Name: {vessel.vesselName}</div>
      <div className={styles.imo}>IMO: {vessel.imo}</div>
    </li>
  </>
);

export default SearchVesselResult;
