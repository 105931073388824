import React, { useEffect, useCallback } from 'react';
import { Company } from '../../../../../store/Company/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { getCategories } from '../../../../../store/Category/actions';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import styled from 'styled-components';
import { Column } from 'react-table';
import FuelBossTableContainer from '../../../../FuelBossTable';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
import { IntegrationAssetApiTracker } from '../../../../../store/IntegrationAssetApiTracker/models';
import { getIntegrationAssetApiTrackers, integrationAssetApiTrackersClearToast } from '../../../../../store/IntegrationAssetApiTracker/actions';
import { authReducer } from '../../../../../store/Auth/reducers';
import Moment from 'react-moment';
import { getIntegrationAssetTopic } from '../../../../../store/IntegrationAssetTopic/actions';
import { IntegrationAssetTopic } from '../../../../../store/IntegrationAssetTopic/models';
import { getPipeline } from '../../../../../store/Pipelines/actions';
import { getFleetVessels } from '../../../../../store/CompanyFleet/actions';
import { AddToast } from '../../../../../helpers/toastService';
const PlusCircle = findIconDefinition({
  prefix: 'fal',
  iconName: 'plus-circle',
});
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

const StyledAssetTableWrapper = styled.div`
  position: relative;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

interface OwnProps {
  integrationAssetApiTrackers: IntegrationAssetApiTracker[];
  integrationAssetTopics: IntegrationAssetTopic[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteIntegrationAssetApiTracker: (id: string) => void;
  editIntegrationAssetApiTracker: (integrationAsset: IntegrationAssetApiTracker) => void;
  navAdminType: string;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const IntegrationAssetApiTrackers: React.FC<OwnProps> = ({
  integrationAssetApiTrackers,
  integrationAssetTopics,
  isLoading,
  hasError,
  errorDetails,
  deleteIntegrationAssetApiTracker,
  editIntegrationAssetApiTracker,
  navAdminType,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const { integrationassetApiTrackersIsLoaded, integrationAssets, toastData, auth } = useSelector((state: ApplicationState) => ({
    integrationassetApiTrackers: state.integration_assets_apitracker.data,
    integrationassetApiTrackersIsLoaded: state.integration_assets_apitracker.dataIsLoaded,
    toastData: state.integration_assets_apitracker.toastData,

    integrationAssetsLoading: state.integration_assets.loading,
    integrationAssetsDetailsError: state.integration_assets.error,
    integrationAssetsErrorDetails: state.integration_assets.errorDetails,
    integrationAssets: state.integration_assets.data,
    integrationAssetsIsLoaded: state.integration_assets.dataIsLoaded,

    auth: state.auth.data,
  }));

  const {
    companyFleetLoading,
    companyFleetError,
    companyFleetErrorDetails,
    companyFleet,
    companyFleetIsLoaded,
    companyPipelineLoading,
    companyPipelineError,
    companyPipelineErrorDetails,
    companyPipelines,
    companyPipelineIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,
    companyPipelineLoading: state.pipelines.loading,
    companyPipelineError: state.pipelines.error,
    companyPipelineErrorDetails: state.pipelines.errorDetails,
    companyPipelines: state.pipelines.data,
    companyPipelineIsLoaded: state.pipelines.dataIsLoaded,
  }));

  const headersNew: Array<Column<IntegrationAssetApiTracker>> = React.useMemo(
    () => [
      {
        Header: 'AssetId',
        accessor: (row) => row.veracityAssetId,
      },
      {
        Header: 'Topic',
        Cell: (props: any) => {
          const apiTracker: IntegrationAssetApiTracker = props.cell.row.original;
          const integrationAssetTopicId = integrationAssetTopics.find((x) => x.id == apiTracker.assetTopic);
          return <div>{integrationAssetTopicId ? integrationAssetTopicId.topic : ''}</div>;
        },

      },
      {
        Header: 'FuelBoss AssetType',
        accessor: (row) => row.fbAssetType,
      },
      {
        Header: 'FuelBoss AssetId',
        accessor: (row) => row.fbAssetId,
      },
      {
        Header: 'FuelBoss AssetName',
        Cell: (props: any) => {
          const apiTracker: IntegrationAssetApiTracker = props.cell.row.original;
          const integrationAsset = integrationAssets.find((x) => x.veracityAssetId == apiTracker.veracityAssetId);
          if (apiTracker.fbAssetType == 'Pipeline') {
            const fleetPipeline = companyPipelines.find((x) => x.chorusId == apiTracker.fbAssetId);
            return <div>{fleetPipeline ? fleetPipeline.name : ''}</div>;
          } else {
            const fleetVessel = companyFleet.find((x) => x.chorusId == apiTracker.fbAssetId);
            return <div>{fleetVessel ? fleetVessel.vessel.vesselName : ''}</div>;

          }
        },
      },
      /* {
        Header: 'Asset Type',
        accessor: (row) => row.assetType,
      }, */
      {
        Header: 'OwnerID',
        accessor: (row) => auth.company?.name,
      },
      {
        Header: 'Timestamp',
        //accessor: (row) => row.timeStamp,
        Cell: (props: any) => {
          const timeStamp = props.cell.row.original.timeStamp;
          return (
            <Moment date={timeStamp} format={timeStamp ? "DD MMM, YYYY HH:mm" : ""} />
          )
        }
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const integrationAssetApiTracker = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={integrationAssetApiTracker.id ? integrationAssetApiTracker.id : ''}
                deleteMethod={deleteIntegrationAssetApiTracker}
                header="Delete Integrated Asset"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      /* {
        Header: 'Edit',
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original;
          return (
            <div className="edit-cell">
              <EditById
                redirectUrl={`/${navAdminType}admin/companyintegrations/inboundconnection/${integrationAsset.integrationAssetId}/edit`}
              />
            </div>
          );
        },
      }, */
    ],
    [integrationAssets, integrationAssetTopics, companyFleet, companyPipelines],
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const getCompanyPipelineCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyPipelineIsLoaded) {
      dispatch(getPipeline(auth.company.companyId));
    }
  }, []);

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyFleetIsLoaded) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  useEffect(() => {
    if (!companyPipelineIsLoaded) {
      getCompanyPipelineCallback();
    }
    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }
  }, []);

  const clearToastCallback = useCallback(() => dispatch(integrationAssetApiTrackersClearToast()), []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  const getIntegratedAssetApiTrackersCallback = useCallback(() => {
    dispatch(getIntegrationAssetApiTrackers());
  }, []);

  useEffect(() => {
    if (!integrationassetApiTrackersIsLoaded) {
      getIntegratedAssetApiTrackersCallback();
    }
  }, [integrationassetApiTrackersIsLoaded]);

  const getAllIntegrationAssetTopic = useCallback(() => {
    dispatch(getIntegrationAssetTopic());
  }, []);

  useEffect(() => {
    getAllIntegrationAssetTopic();
  }, []);


  if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledAssetTableWrapper>
        <div>Data integrations to and from FuelBoss are based on using the Veracity data API. In order to create and track data records automatically from Veracity, please add a data connection in FuelBoss. This will link your data from the Veracity data API to the correct FuelBoss asset and data records</div>
        <div className="buttons-div">
          <Button
            color="primary"
            size="lg"
            onClick={() => history.push(`/${navAdminType}admin/companyintegrations/inboundconnection/add`)}
          >
            Add data connection
          </Button>
        </div>
        {integrationAssetApiTrackers instanceof Array && <FuelBossTableContainer
          headers={headersNew}
          tableRows={integrationAssetApiTrackers}
          showPagination={true}
          defaultSelectedPage={defaultSelectedPage}
          setDefaultSelectedPage={setDefaultSelectedPage}
        />}
      </StyledAssetTableWrapper>
    );
  }
};

export default React.memo(IntegrationAssetApiTrackers);
