import { ErrorActionPayload } from '../../models/ErrorAction';
import { BIvesselsWrapperModel } from './models';

export const BIVESSELS_LOADING = 'bivessels/loading';
export type BIVESSELS_LOADING = typeof BIVESSELS_LOADING;

export const GET_BIVESSELS = 'bivessels/get';
export type GET_BIVESSELS = typeof GET_BIVESSELS;

export const BIVESSELS_FETCH_ERROR = 'bivessels/fetchError';
export type BIVESSELS_FETCH_ERROR = typeof BIVESSELS_FETCH_ERROR;

export type BIvesselsActions =
    | {
        type: GET_BIVESSELS;
        payload: BIvesselsWrapperModel['data'];
    }
    | {
        type: BIVESSELS_LOADING;
    }
    | {
        type: BIVESSELS_FETCH_ERROR;
        payload: ErrorActionPayload;
    };
