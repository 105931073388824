import { Dispatch } from 'redux';
import {
    OutboundIntegrationApiTrackerActions,
    OUTBOUNDINTEGRATIONAPITRACKER_LOADING,
    OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR,
    GET_OUTBOUNDINTEGRATIONAPITRACKER,
    ADD_OUTBOUNDINTEGRATIONAPITRACKER,
    OUTBOUNDINTEGRATIONAPITRACKER_ADD_ERROR,
    DELETE_OUTBOUNDINTEGRATIONAPITRACKER,
    EDIT_OUTBOUNDINTEGRATIONAPITRACKER,
    OUTBOUNDINTEGRATIONAPITRACKER_DELETE_ERROR,
    OUTBOUNDINTEGRATIONAPITRACKER_EDIT_ERROR,
    OUTBOUNDINTEGRATIONAPITRACKER_CLEAR_TOAST,
    OUTBOUNDINTEGRATIONASSET_ADD_ERROR,
    ADD_OUTBOUNDINTEGRATIONASSET
} from './types';
import { api } from '../../helpers/api';
import { OutboundIntegrationApiTrackerResponseModel, IntegrationOutboundApiTracker } from './models';
import { Role } from '../Roles/models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { IntegrationAsset } from '../IntegrationAsset/models';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating Outbound Api Tracker...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Outbound Api Tracker updated successfully</ModalResponse>;
const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating Outbound Api Tracker...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Outbound Api Tracker created successfully</ModalResponse>;


const createAssetLoaderModal: ReactElement = <ModalResponse status="loading">Creating Outbound Assets...</ModalResponse>;
const createAssetSuccessModal: ReactElement = <ModalResponse status="success">Outbound Assets created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getIntegrationOutboundApiTracker = (isInSetting: boolean) => (dispatch: Dispatch<OutboundIntegrationApiTrackerActions>) => {
  dispatch({
    type: OUTBOUNDINTEGRATIONAPITRACKER_LOADING,
  });
  const url = `/api/Integration/outboundapitracker?isInSetting=${isInSetting}`;
  new api<OutboundIntegrationApiTrackerResponseModel>({
    dispatch,
    errorType: OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {            
      
      dispatch({
        type: GET_OUTBOUNDINTEGRATIONAPITRACKER,
        payload: response,
      });
    });
};

export const getIntegrationOutboundApiTrackerById = (id: string) => (dispatch: Dispatch<OutboundIntegrationApiTrackerActions>) => {
  dispatch({
    type: OUTBOUNDINTEGRATIONAPITRACKER_LOADING,
  });
  const url = `/api/Integration/outboundapitrackerbyid?id=${id}`;
  new api<OutboundIntegrationApiTrackerResponseModel>({
    dispatch,
    errorType: OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {            
      
      dispatch({
        type: GET_OUTBOUNDINTEGRATIONAPITRACKER,
        payload: response,
      });
    });
};


export const addIntegrationOutboundApiTracker = (outboundApiTracker: IntegrationOutboundApiTracker, onSuccess: () => void) => (
  dispatch: Dispatch<OutboundIntegrationApiTrackerActions>,
) => { 

  dispatch({
    type: OUTBOUNDINTEGRATIONAPITRACKER_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  
  new api<OutboundIntegrationApiTrackerResponseModel>({
    dispatch,
    errorType: OUTBOUNDINTEGRATIONAPITRACKER_ADD_ERROR,
  })
    .post(`/api/Integration/outboundapitracker`, outboundApiTracker)
    .then((response) => {
      dispatch({
        type: ADD_OUTBOUNDINTEGRATIONAPITRACKER,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};


export const addOutboundAsset = (assets:IntegrationAsset[], companyName:string, tenantId:string, onSuccess: () => void) => (
  dispatch: Dispatch<OutboundIntegrationApiTrackerActions>,
) => { 

  dispatch({
    type: OUTBOUNDINTEGRATIONAPITRACKER_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createAssetLoaderModal });

  new api<OutboundIntegrationApiTrackerResponseModel>({
    dispatch,
    errorType: OUTBOUNDINTEGRATIONASSET_ADD_ERROR,
  })
  .post(`/api/Integration/outboundasset?companyName=${companyName}&tenantId=${tenantId}`, assets)
  .then((response) => {
    dispatch({
      type: ADD_OUTBOUNDINTEGRATIONASSET,
      payload: response,
    });
    // @ts-ignore
    dispatch({ type: SET_MODAL, payload: createAssetSuccessModal });
    setTimeout(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: null });
    }, 1000);
    onSuccess();
  })
  .catch(() => {
    // @ts-ignore
    dispatch({ type: SET_MODAL, payload: errorModal });
    setTimeout(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: null });
    }, 1000);
  });
};


export const deleteIntegrationOutboundApiTracker = (id: string) => (dispatch: Dispatch<OutboundIntegrationApiTrackerActions>) => {
  dispatch({
    type: OUTBOUNDINTEGRATIONAPITRACKER_LOADING,
  });

  const url = `/api/Integration/outboundapitracker/${id}`;
  new api<OutboundIntegrationApiTrackerResponseModel>({
    dispatch,
    errorType: OUTBOUNDINTEGRATIONAPITRACKER_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_OUTBOUNDINTEGRATIONAPITRACKER,
        payload: response,
      });
    });
};


export const editIntegrationOutboundApiTracker = (outboundApiTracker: IntegrationOutboundApiTracker, isInSetting: boolean, onSuccess: () => void) => (
  dispatch: Dispatch<OutboundIntegrationApiTrackerActions>,
) => {

  dispatch({
    type: OUTBOUNDINTEGRATIONAPITRACKER_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/Integration/outboundapitracker/${outboundApiTracker.Id}?isInSetting=${isInSetting}`;
    new api<OutboundIntegrationApiTrackerResponseModel>({
      dispatch,
      errorType: OUTBOUNDINTEGRATIONAPITRACKER_EDIT_ERROR,
    })
      .put(url, outboundApiTracker)
      .then((response) => {
        dispatch({
          type: EDIT_OUTBOUNDINTEGRATIONAPITRACKER,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
};

export const emailSettingsClearToast = () => (dispatch: Dispatch<OutboundIntegrationApiTrackerActions>) => {
  dispatch({
    type: OUTBOUNDINTEGRATIONAPITRACKER_CLEAR_TOAST,
  });
};
