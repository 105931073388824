import * as TYPE from './types';

import { ApplicationState } from '..';

export const sanity_configReducer = (
  state: ApplicationState['sanity_config'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: { dataset: '', projectId: '', token: '' },
    metadata: {},
  },
  action: TYPE.MapActions,
): ApplicationState['sanity_config'] => {
  switch (action.type) {
    case TYPE.SANITY_CONFIG_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.SANITY_CONFIG_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_SANITY_CONFIG:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
