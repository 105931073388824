import * as TYPE from './types';

import { ApplicationState } from '..';

export const archived_spotReducer = (
  state: ApplicationState['archived_spot'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: undefined,
  },
  action: TYPE.ArchivedSpotActions,
): ApplicationState['archived_spot'] => {
  switch (action.type) {
    case TYPE.ARCHIVED_SPOT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.ARCHIVED_SPOT_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_ARCHIVED_SPOT:
      return {
        ...state,
        data: action.payload.data,
        metadata: action.payload.metadata,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
