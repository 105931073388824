import * as TYPE from './types';

import { ApplicationState } from '..';

export const integrationAssetPermissionsReducer = (
  state: ApplicationState['integration_assets_permissions'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.IntegrationAssetPermissionsActions,
): ApplicationState['integration_assets_permissions'] => {
  switch (action.type) {
    case TYPE.INTEGRATION_ASSET_PERMISSIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.INTEGRATION_ASSET_PERMISSIONS_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.GET_INTEGRATION_ASSET_PERMISSIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.INTEGRATION_ASSET_PERMISSIONS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_INTEGRATION_ASSET_PERMISSIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Integration asset permissions succesfully created!',
          type: 'success',
        },
      };

    case TYPE.INTEGRATION_ASSET_PERMISSIONS_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `Integration asset permissions with this name already exist in FuelBoss. If not please contact Fuelboss support`
            : `We could not create the new Integration asset permissions. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_INTEGRATION_ASSET_PERMISSIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Integration asset permissions edited',
          type: 'info',
        },
      };
    case TYPE.INTEGRATION_ASSET_PERMISSIONS_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not edit the Integration asset permissions. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    case TYPE.DELETE_INTEGRATION_ASSET_PERMISSIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Integration asset permissions succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.INTEGRATION_ASSET_PERMISSIONS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not delete the Integration asset permissions. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    default:
      return { ...state };
  }
};
 