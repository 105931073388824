import React, { useState, useCallback, useEffect } from 'react';
import { ErrorActionPayload } from '../../../models/ErrorAction';
import { Loading } from '../../Loading';
import { Error } from '../../Error';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '../../../store/Auth/actions';
import { getChorusCompanies } from '../../../store/ChorusCompany/actions';
import { ApplicationState } from '../../../store';
import { AddToast } from '../../../helpers/toastService';
import FuelBossTableContainer from '../../FuelBossTable';
import { Column } from 'react-table/index';
import DeleteById from '../../DeleteById/DeleteById';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledTableWrapper } from '../StyledComponents/StyledTableWrapper';
import { Button } from 'reactstrap';
import editButtonStyles from '../../../components/EditById/EditById.module.scss';
import { EditPipeline } from '../../EditPipeline/EditPipeline';
import { pipelineClearToast } from '../../../store/Pipelines/actions';
import { Pipeline } from '../../../store/Pipelines/models';
import AddPipeline from '../../AddPipeline/AddPipeline';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { CustomError } from '../../Error/components/CustomError';
import { Label, Input, Table } from 'reactstrap';
import { User } from '../../../store/Users/models';
import { getPipeline } from '../../../store/Pipelines/actions';
import SearchPipeline from '../../SearchPipeline/SearchPipeline';

const penIcon = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

interface OwnProps {
  companyPipelines?: any[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deletePipelineMethod: (id: string) => void;
  addPipelineMethod: (pipeline: Pipeline) => void;
  noPagination?: boolean;
  numberOfRowsPerPagination?: number;
  className?: string;
  displayAddPipeline: boolean;
  setDisplayAddPipeline: (display: boolean) => void;
  isAddPipelineActive: boolean;
  setAddPipelineActive: (isActive: boolean) => void;
  serviceLayer: AuthenticationServiceLayer;
}

const Pipelines: React.FC<OwnProps> = ({
  companyPipelines,
  isLoading,
  hasError,
  errorDetails,
  deletePipelineMethod,
  addPipelineMethod,
  numberOfRowsPerPagination = 8,
  noPagination = false,
  className,
  displayAddPipeline,
  setDisplayAddPipeline,
  isAddPipelineActive,
  setAddPipelineActive,
  serviceLayer,
}) => {
  const {
    authLoading,
    authError,
    authErrorDetails,
    auth,
    authIsLoaded,
    chorusCompanyLoading,
    chorusCompanyError,
    chorusCompanyErrorDetails,
    chorusCompany,
    chorusCompanyIsLoaded,
    toastData,
  } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompanyErrorDetails: state.choruscompany.errorDetails,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded,
    toastData: state.pipelines.toastData,
  }));

  const [selectedPipelineId, displayEditLngSpecsFor] = useState<string>('');
  const [fleetName, setFleetName] = useState('');

  const addPipeline = (pipeline: Pipeline) => {
    addPipelineMethod(pipeline);
  };

  const dispatch = useDispatch();

  //Callbacks to redux
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);
  const getCompaniesCallback = useCallback(() => dispatch(getChorusCompanies()), []);
  const clearToastCallback = useCallback(() => dispatch(pipelineClearToast()), []);

  const getCompanyPipelineCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId) {
      dispatch(getPipeline(auth.company.companyId));
    }
  }, []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
    if (!chorusCompanyIsLoaded) {
      getCompaniesCallback();
    }
  }, []);

  useEffect(() => {
    if ((toastData != null || toastData != undefined) && !errorDetails) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  const headersNew: Array<Column<Pipeline>> = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => {
          if (row.name) return row.name;
          else return '';
        },
      },
      // not used after seperate option to specify quality
      /* {
        Header: 'LNG quality specification',
        Cell: (props: any) => {
          const pipeline = props.cell.row.original;
          return (
            <div className="delete-cell">
              <Button
                className={editButtonStyles['btn-edit']}
                onClick={() => {
                  displayEditLngSpecsFor(pipeline.chorusId);
                }}
              >
                <FontAwesomeIcon icon={penIcon} />
              </Button>
            </div>
          );
        },
      }, */
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const pipeline = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={pipeline.id}
                deleteMethod={deletePipelineMethod}
                header="Delete pipeline"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
    ],
    [],
  );
  if (isLoading || authLoading || chorusCompanyLoading) {
    return <Loading />;
  } else if (errorDetails?.serverFault && errorDetails) {
    //@ts-ignore
    const usersWithBunkerPipeline: User[] = companyPipelines!;
    if (errorDetails.statusCode == 400) {
      const users = usersWithBunkerPipeline.map((subitem: User, i: any) => (
        <tr key={i}>
          <td scope="row">{subitem.firstName}</td>
          <td>{subitem.lastName}</td>
          <td>{subitem.email}</td>
        </tr>
      ));
      //reload data after some time
      setTimeout(() => {
        getCompanyPipelineCallback();
      }, 15000);
      return (
        <CustomError
          errorTitle={'Not allowed to delete pipeline'}
          errorMessage={'There are users in your company that are associated with this pipeline:'}
          displayContact={false}
        >
          <Table style={{ display: 'initial' }}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>{users}</tbody>
            <h6 style={{ marginTop: '20px' }}> Refresh page in order to continue</h6>
          </Table>
        </CustomError>
      );
    }
    return <Error error={errorDetails} />;
  } else if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else if (chorusCompanyError && chorusCompanyErrorDetails) {
    return <Error error={chorusCompanyErrorDetails} />;
  } else if (selectedPipelineId != '') {
    return (
      <EditPipeline
        auth={auth}
        selectedPipelineId={selectedPipelineId}
        goBack={() => {
          displayEditLngSpecsFor('');
        }}
        serviceLayer={serviceLayer}
      />
    );
  } else {
    return (
      <>
        {/* {displayAddPipeline && (
          <AddPipeline
            addPipelineMethod={addPipeline}
            setDisplayAddPipeline={setDisplayAddPipeline}
            isSearchActive={isAddPipelineActive}
            setIsSearchActive={setAddPipelineActive}
          />
        )} */}
        {displayAddPipeline && <SearchPipeline addPipelineMethod={addPipeline} />}
        {companyPipelines && companyPipelines.length ? (
          <StyledTableWrapper>
            <FuelBossTableContainer
              className={className}
              headers={headersNew}
              tableRows={companyPipelines}
              showFilterSearch={false}
            />
          </StyledTableWrapper>
        ) : null}
      </>
    );
  }
};

export default React.memo(Pipelines);
