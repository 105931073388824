import React from 'react';
import FormikWrapper from '../Formik/FormikWrapper';

import { Company } from '../../../../../store/Company/models';
import { Report } from '../../../../../store/Reports/models';
import { Role } from '../../../../../store/Roles/models';
import { Loading } from '../../../../Loading';

interface OwnProps {
  editReport: (report: Report) => void;
  reportId: string;
  company?: Company;
  reports: Report[];
  navAdminType: string;
  companyAdmin?: boolean;
  isLoading?: boolean;
}

const EditReport: React.FC<OwnProps> = ({
  editReport,
  reportId,
  companyAdmin,
  navAdminType,
  isLoading,
  reports,
  company,
}) => {
  const submitReport = (report: Report) => {
    editReport(report);
  };

  const report: Report | undefined = reports.find((it) => it.id === reportId);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormikWrapper
      addReport={submitReport}
      companyAdmin={companyAdmin}
      report={report}
      header="Edit report"
      company={company}
      navAdminType={navAdminType}
    />
  );
};

export default React.memo(EditReport);
