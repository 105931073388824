import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getCompanies } from '../../../../../store/Company/actions';
import { Company } from '../../../../../store/Company/models';
import { getRoles } from '../../../../../store/Roles/actions';
import { Role } from '../../../../../store/Roles/models';
import { User } from '../../../../../store/Users/models';
import { getAuth } from '../../../../../store/Auth/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { RoleType } from '../../../../../models/RoleType';
import usePrevious from '../../../../../helpers/usePrevious';
import { getFleetVessels } from '../../../../../store/CompanyFleet/actions';
import { getPipeline } from '../../../../../store/Pipelines/actions';
import { renderTextInput, renderComplexDropdown } from '../../../../Form/FormInputs';
import userConfigs from './userConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { checkShipOwner, checkSupplier } from '../../../../../helpers/roleChecks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { AddToast } from '../../../../../helpers/toastService';
import { UserRole } from '../../../../../models/UserRole';

const iconUp = findIconDefinition({ prefix: 'fal', iconName: 'chevron-up' });
const iconDown = findIconDefinition({ prefix: 'fal', iconName: 'chevron-down' });

const UserRoleInfoWrapper = styled.div`
  margin-bottom: 6px;
  .expanded {
    height: 40px;
    border-radius: 5px;
    background: rgb(204, 236, 248);
    stroke-width: 1;
    display: flex;
    user-select: none;
    z-index: 2;
    position: relative;
    :hover {
      cursor: pointer;
    }
  }
  .not-expanded {
    height: 40px;
    border-radius: 5px;
    background: #f5f5f5;
    stroke-width: 1;
    display: flex;
    user-select: none;
    :hover {
      cursor: pointer;
      background: rgb(233, 233, 233);
    }
  }
  .user-role-name {
    flex: 10 1 0%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 18px;
    color: rgb(36, 54, 92);
    line-height: 24px;
    margin-left: 10px;
    font-weight: 600;
  }
  .expander-icon {
    flex: 1 1 0%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-right: 10px;
    svg {
      background: white;
      border-radius: 50%;
      padding: 3px;
    }
  }
  .card {
    position: relative !important;
    display: flex;
    flex-direction: column !important;
    min-width: 0;
    word-wrap: break-word !important;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(17, 17, 17, 0.125);
    border-radius: 0 0 7px 7px;
    top: -3px;
    z-index: 1;
    position: relative;
  }
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem 0.3rem 0;
  }
  ul li {
    margin-bottom: 7px;
  }
`;

const StyledInnerForm = styled.div`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
  margin-top: 1em;
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
  }
  .buttonContainer button {
    justify-self: flex-end;
    margin-left: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    flex: 0 1 40%;
    margin-right: 35px;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
  .form-check {
    margin-right: 5px;
  }
`;

interface OwnProps {
  user?: User;
  header: string;
  saveMethod: (user: User, roles: Role[]) => void;
  systemAdminPage?: boolean;
  companyAdminForm?: boolean;
  company?: Company;
  formikProps: FormikProps<User>;
  navAdminType: string;
  setIsRoleCaptain: any;
  setIsRoleTerminalOperator: any;
  addTeamMember?: boolean;
}

const UserForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  user,
  systemAdminPage = false,
  companyAdminForm = false,
  company,
  navAdminType,
  setIsRoleCaptain,
  setIsRoleTerminalOperator,
  formikProps,
  addTeamMember
}) => {
  const {
    companies,
    companiesIsLoaded,
    companiesLoading,
    companiesError,
    companiesErrorDetails,

    roles,
    rolesIsLoaded,
    rolesLoading,
    rolesError,
    rolesErrorDetails,

    authLoading,
    authError,
    authErrorDetails,
    auth,
    authIsLoaded,

    companyFleet,
    companyFleetLoading,
    companyFleetIsLoaded,

    companyPipelineLoading,
    companyPipelines,
    companyPipelineIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    companiesLoading: state.companies.loading,
    companiesError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,

    roles: state.roles.data,
    rolesIsLoaded: state.roles.dataIsLoaded,
    rolesLoading: state.roles.loading,
    rolesError: state.roles.error,
    rolesErrorDetails: state.roles.errorDetails,

    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,

    companyPipelineLoading: state.pipelines.loading,
    companyPipelineError: state.pipelines.error,
    companyPipelineErrorDetails: state.pipelines.errorDetails,
    companyPipelines: state.pipelines.data,
    companyPipelineIsLoaded: state.pipelines.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  const getCompanyFleetCallback = React.useCallback((company: Company | undefined) => {
    if (company && company.companyId) dispatch(getFleetVessels(company.companyId));
  }, []);

  const getCompanyPipelineCallback = React.useCallback((company: Company | undefined) => {
    if (company && company.companyId) {
      dispatch(getPipeline(company.companyId));
    }
  }, []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const getAllCompaniesCallback = useCallback(() => dispatch(getCompanies()), []);

  const getAllRolesCallback = useCallback(() => dispatch(getRoles()), []);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getAllCompaniesCallback();
    }
  }, [companiesIsLoaded]);

  useEffect(() => {
    if (!rolesIsLoaded) {
      getAllRolesCallback();
    }
  }, [rolesIsLoaded]);

  //States for storing form answers
  const newCompany = formikProps.values.company;
  const newFleetVessel = formikProps.values.fleetVessel;
  const newFleetPipeline = formikProps.values.fleetPipeline;

  //State and methods for roles
  const { role } = formikProps.values;
  const [roleArray, setRoleArray] = React.useState<Role[]>();
  const prevRoleArray = usePrevious(roleArray);
  const [systemAdmin, setSystemAdmin] = React.useState<Role>();
  const [companyAdmin, setCompanyAdmin] = React.useState<Role>();
  const [isCaptain, setIsCaptain] = React.useState(false);
  const [isTerminalOperator, setIsTerminalOperator] = React.useState(false);
  const [companyChangePipeline, setCompanyChangePipeline] = React.useState(false);
  const [companyChangeVessel, setCompanyChangeVessel] = React.useState(false);

  // Save admin roles easily availible
  const systemAdminRole: Role | undefined = roles.find((role) => role.roleType === RoleType.SysAdmin);
  const companyAdminRoleSupplier: Role | undefined = roles.find((role) => role.roleType === RoleType.CompAdmin);
  const companyAdminRoleShipowner: Role | undefined = roles.find(
    (role) => role.roleType === RoleType.CompAdminBuyer,
  );

  const checkCompanyAdmin = (userRole: UserRole[] | undefined) => {
    if (!userRole) return false;
    return userRole.some((role) => role.role?.roleType === RoleType.CompAdmin || role.role?.roleType === RoleType.CompAdminBuyer);
  }

  //Init roles if edit
  useEffect(() => {
    if (user) {
      user.userRoles.forEach((ur) => {
        if (ur.role) {
          if (ur.role.roleType === RoleType.SysAdmin) setSystemAdmin(ur.role);
          else if (ur.role.roleType === RoleType.CompAdmin) {
            setCompanyAdmin(ur.role);
          } else if (ur.role.roleType === RoleType.CompAdminBuyer) {
            setCompanyAdmin(ur.role);
          } else {
            if (isCaptainRole(ur.role) && user.fleetVessel) {
              setIsCaptain(true);
              setIsRoleCaptain(true);
              formikProps.setFieldValue('fleetVessel', user.fleetVessel);
            }
            if (isTerminalOperatorRole(ur.role) && user.fleetPipeline) {
              setIsTerminalOperator(true);
              setIsRoleTerminalOperator(true);
              formikProps.setFieldValue('fleetPipeline', user.fleetPipeline);
            }
            formikProps.setFieldValue('role', ur.role);
          }
        }
      });
      formikProps.setFieldValue('company', user.company);
    } else if (company) {
      formikProps.setFieldValue('company', company);
    }
  }, []);

  //const prevCompany = usePrevious(newCompany);
  useEffect(() => {
    setRoleArray(getRolesForCompany());

    if (
      !companyFleetIsLoaded ||
      (companyFleet && companyFleet.length < 1) ||
      (companyFleet && companyFleet.length > 0 && newCompany && companyFleet[0].companyId != newCompany.companyId)
    ) {
      getCompanyFleetCallback(newCompany);
      //Reset newFleetVessel if company differs from the fleetVessel's
      if (newCompany && newCompany.companyId && newFleetVessel) {
        if (newCompany.companyId !== newFleetVessel.companyId) {
          setCompanyChangeVessel(true);

        }
      }
    }

    if (
      !companyPipelineIsLoaded ||
      (companyPipelines && companyPipelines.length < 1) ||
      (companyPipelines && companyPipelines.length > 0 && newCompany && companyPipelines[0].companyId != newCompany.companyId)
    ) {
      getCompanyPipelineCallback(newCompany);
      //Reset newFleetPipeline if company differs from the fleetPipeline's
      if (newCompany && newCompany.companyId && newFleetPipeline) {
        if (newCompany.companyId !== newFleetPipeline.companyId) {
          setCompanyChangePipeline(true);
        }
      }
    }
  }, [newCompany]);

  useEffect(() => {

    //load companyadmin type
    const currentCategoryName = newCompany.category.categoryName;
    if (currentCategoryName === 'Supplier') {
      if (checkCompanyAdmin(user?.userRoles)) {
        setCompanyAdmin(companyAdminRoleSupplier);
      }
    } else {
      if (checkCompanyAdmin(user?.userRoles)) {
        setCompanyAdmin(companyAdminRoleShipowner);
      }
    }
  }, [newCompany, companyAdminRoleSupplier, companyAdminRoleShipowner]);


  useEffect(() => {
    if (companyChangePipeline) {
      setTimeout(() => {
        formikProps.setFieldValue('fleetPipeline', null);
        setCompanyChangePipeline(false);
      }, 500);
    }
  }, [companyChangePipeline]);


  useEffect(() => {
    if (companyChangeVessel) {
      setTimeout(() => {
        formikProps.setFieldValue('fleetVessel', null);
        setCompanyChangeVessel(false);
      }, 500);
    }
  }, [companyChangeVessel]);

  useEffect(() => {
    setRoleArray(getRolesForCompany());
  }, [roles]);

  const prevRole = usePrevious(role);
  useEffect(() => {
    if (user) {
      formikProps.setFieldTouched('role');
    }
    // @ts-ignore
    if (isCaptainRole(role)) {
      setIsCaptain(true);
      setIsRoleCaptain(true);
    } else {
      setIsCaptain(false);
      setIsRoleCaptain(false);
      if (prevRole) {
        formikProps.setFieldValue('fleetVessel', undefined);

      }
    }
    // @ts-ignore
    if (isTerminalOperatorRole(role)) {
      setIsTerminalOperator(true);
      setIsRoleTerminalOperator(true);
    } else {
      setIsTerminalOperator(false);
      setIsRoleTerminalOperator(false);
      if (prevRole) {
        formikProps.setFieldValue('fleetPipeline', undefined);
      }
    }
  }, [role]);

  useEffect(() => {
    //Null checks
    if (!!prevRoleArray && prevRoleArray.length > 0 && !!roleArray && !!roleArray[0]) {
      //If the new role array differs from the old, change the role
      if (prevRoleArray[0].roleType !== roleArray[0].roleType) {
        formikProps.setFieldValue('role', roleArray[0]);
      }
    }
  }, [roleArray]);

  //Getting roles for saving
  const getRolesArray = () => {
    const arr: Role[] = [];
    if (systemAdmin) arr.push(systemAdmin);
    if (companyAdmin) arr.push(companyAdmin);
    if (role) {
      // @ts-ignore
      arr.push(role);
    }

    return arr;
  };

  const isCaptainRole = (roleToCheck: Role | undefined) => {
    if (roleToCheck) {
      return roleToCheck.roleType === RoleType.BunkerVesselCrew || roleToCheck.roleType === RoleType.RecVesselCrew || roleToCheck.roleType === RoleType.CaptainScheduler || roleToCheck.roleType === RoleType.ReceivingCaptainScheduler;
    } else {
      return false;
    }
  };

  const isTerminalOperatorRole = (roleToCheck: Role | undefined) => {
    if (roleToCheck) {
      return roleToCheck.roleType === RoleType.TerminalOperator;
    } else {
      return false;
    }
  };

  const isTruckOperatorRole = (roleToCheck: Role | undefined) => {
    if (roleToCheck) {
      return roleToCheck.roleType === RoleType.TruckOperator;
    } else {
      return false;
    }
  };

  const isSupplierRole = (roleToCheck: Role) =>
    roleToCheck.roleType === RoleType.Operator ||
    roleToCheck.roleType === RoleType.TerminalOperator ||
    roleToCheck.roleType === RoleType.TruckOperator ||
    roleToCheck.roleType === RoleType.Commercial ||
    roleToCheck.roleType === RoleType.BackOffice ||
    roleToCheck.roleType === RoleType.CaptainScheduler ||
    roleToCheck.roleType === RoleType.BunkerVesselCrew;

  const isShipownerOrChartererRole = (roleToCheck: Role) =>
    roleToCheck.roleType === RoleType.VesselOperator ||
    roleToCheck.roleType === RoleType.Procurement ||
    roleToCheck.roleType === RoleType.Administrative ||
    roleToCheck.roleType === RoleType.ReceivingCaptainScheduler ||
    roleToCheck.roleType === RoleType.RecVesselCrew;

  //Get roles for dropdown
  const getRolesForCompany = () => {
    //Check if company admin rest is null checks, might need to break them out
    if (newCompany) {
      const currentCategoryName = newCompany.category.categoryName;

      //SUPPLIER
      if (currentCategoryName === 'Supplier') {
        return roles.filter((role) => isSupplierRole(role));
      }
      //SHIPOWNER/CHARTERE
      else if (currentCategoryName === 'Shipowner' || currentCategoryName === 'Charterer') {
        return roles.filter((role) => isShipownerOrChartererRole(role));
      } else {
        //Backup return shipowner or charterer
        return roles.filter((role) => isShipownerOrChartererRole(role));
      }
    } else {
      //Backup return empty array
      return [];
    }
  };

  const onFormSave = () => {
    formikProps.submitForm();
    const roleArray = getRolesArray();

    const userToSave: User = {
      ...formikProps.values,
    };
    
    if (user) {
      userToSave.id = user.id;
    }
    if (formikProps.isValid) {
      saveMethod(userToSave, roleArray);
    } else {
      formikProps.setFieldTouched("fleetVessel", true)
      formikProps.setFieldTouched("fleetPipeline", true)

      if(addTeamMember){      
        AddToast({
          content: 'Unable to add team member, missing requirements',
          type: 'error',
          toastId: 'editTeamMember',
        });
      }else{
        AddToast({
          content: 'Unable to edit team member, missing requirements',
          type: 'error',
          toastId: 'editTeamMember',
        });
      }
    }
  };

  // Toggle methods for admins
  const toggleActive = () => {
    formikProps.setFieldValue('active', !formikProps.values.active);
  };

  // Toggle methods for admins
  const toggleCompanyAdmin = () => {
    if (companyAdmin) {
      setCompanyAdmin(undefined);
    }
    else {
      const currentCompanyCategory = formikProps.values.company.category.categoryName;
      if (currentCompanyCategory === 'Supplier') setCompanyAdmin(companyAdminRoleSupplier);
      else setCompanyAdmin(companyAdminRoleShipowner);
    }
  };

  const toggleSystemAdmin = () => {
    if (systemAdmin) setSystemAdmin(undefined);
    else setSystemAdmin(systemAdminRole);
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: userConfigs, errors, touched, setFieldValue, setFieldTouched };
  const [rerenderPlaceHolder, setRerenderPlaceHolder] = React.useState(false);
  const [isUserRoleInfoOpen, setIsUserRoleInfoOpen] = React.useState(new Map());
  const toggle = (role: string) => {
    const map = isUserRoleInfoOpen;
    map.set(role, !getIsUserRoleInfoExpanded(role));
    setIsUserRoleInfoOpen(map);
    setRerenderPlaceHolder(!rerenderPlaceHolder);
  };

  const getIsUserRoleInfoExpanded = (role: string): boolean => {
    if (isUserRoleInfoOpen.get(role)) {
      const isOpenBool = isUserRoleInfoOpen.get(role);
      return isOpenBool;
    } else {
      return false;
    }
  };

  const renderUserRoleInfo = (role: string, info: string[]) => {
    const roleName = role.replace(/\s/g, '');
    const isExpanded = getIsUserRoleInfoExpanded(roleName);
    return (
      <UserRoleInfoWrapper>
        {
          <div onClick={() => toggle(roleName)} className={isExpanded ? 'expanded' : 'not-expanded'}>
            <span className="user-role-name">{role}</span>
            <span className="expander-icon">
              {!isExpanded && <FontAwesomeIcon icon={iconUp} size={'lg'} />}
              {isExpanded && <FontAwesomeIcon icon={iconDown} size={'lg'} />}
            </span>
          </div>
        }
        <Collapse isOpen={isExpanded}>
          <Card>
            <CardBody>
              <ul>
                {info.map((userInfo, index) => (
                  <li key={index}>{userInfo}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Collapse>
      </UserRoleInfoWrapper>
    );
  };

  const isSuppler = checkSupplier(auth);
  if (companiesLoading || authLoading || rolesLoading) {
    return <Loading type="user form" />;
  } else if (companiesError && companiesErrorDetails) {
    return <Error error={companiesErrorDetails} />;
  } else if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else if (rolesError && rolesErrorDetails) {
    return <Error error={rolesErrorDetails} />;
  } else {
    return (
      <div>
        <StyledForm>
          <FormHeader
            navAdminType={navAdminType}
            header={header}
            onFormSave={onFormSave}
            confirmButtonText="Save team member"
            cancelButtonText="Cancel"
            formUrl="users"
          />
          <StyledInnerForm as={Form}>
            {renderTextInput('firstName', false, props)}
            {renderTextInput('lastName', false, props)}
            {renderTextInput('email', !!user, props)}
            {!companyAdminForm && renderComplexDropdown('company', companies, false, 'name', props)}
            {renderComplexDropdown('role', roleArray, false, 'roleType', props)}
            {isCaptain && renderComplexDropdown('fleetVessel', companyFleet, companyFleetLoading, 'vesselName', props)}
            {isTerminalOperator && renderComplexDropdown('fleetPipeline', companyPipelines, companyPipelineLoading, 'name', props)}

            <div className="input-checkbox">
              <FormGroup check>
                <Label checkfor="IsAdmin">
                  <Input type="checkbox" checked={!!companyAdmin} onChange={toggleCompanyAdmin} />
                  Set as company admin
                </Label>
              </FormGroup>
              {systemAdminPage && !companyAdminForm && (
                <span>
                  <FormGroup check>
                    <Label checkfor="IsAdmin">
                      <Input type="checkbox" checked={!!systemAdmin} onChange={toggleSystemAdmin} />
                      Set as system admin
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label checkfor="IsActive">
                      <Input type="checkbox" checked={formikProps.values.active} onChange={toggleActive} />
                      Set active
                    </Label>
                  </FormGroup>
                </span>

              )}
            </div>

          </StyledInnerForm>
        </StyledForm>
        {/*Render user role information */}
        <hr />
        <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>About user roles</div>
        {!isSuppler &&
          renderUserRoleInfo('Administrative', [
            'Read only'
          ])}
        {!isSuppler &&
          renderUserRoleInfo('Receiving vessel crew', [
            'See details of future and past bunker events, for own ship only',
            'Communicate with supplier’s representatives through the chat',
            'Fill out and sign electronic documents and checklists during bunkering operations'
          ])}
        {!isSuppler &&
          renderUserRoleInfo('Procurement', [
            'Initiate, review and/or modify LNG bunker nominations and spot quotations',
            'Communicate with seller’s representatives through the chat',
            'Fill out and sign electronic documents and checklists during bunkering operations'
          ])}
        {!isSuppler &&
          renderUserRoleInfo('Vessel operator', [
            'Initiate, review and/or modify LNG bunker nominations and spot quotations',
            'Communicate with seller’s representatives through the chat',
            'Fill out and sign electronic documents and checklists during bunkering operations'
          ])}
        {/*Supplier role information */}
        {isSuppler &&
          renderUserRoleInfo('Operator', [
            'Initiate, review and/or modify LNG bunker nominations, and respond to spot quotations',
            'Communicate with buyer’s representatives through the chat',
            'Fill out and sign electronic documents and checklists during bunkering operations'
          ])}
        {isSuppler &&
          renderUserRoleInfo('Commercial', [
            'Initiate, review and/or modify LNG bunker nominations, and respond to spot quotations',
            'Communicate with buyer’s representatives through the chat',
            'Create and edit Bunker Delivery Notes'
          ])}
        {isSuppler &&
          renderUserRoleInfo('Bunker vessel crew', [
            'See details of future and past bunker events, for own ship only',
            'Communicate with seller’s representatives through the chat',
            'Create, fill out and sign safety checklist, statement of facts and Bunker Delivery Note during bunkering operations'
          ])}
        {isSuppler && renderUserRoleInfo('Back office', ['Read only'])}
        {renderUserRoleInfo('Company admin ', [
          'Manage company users and access rights',
          'View details of, create and edit contracts',
          'Manage company service offerings: delivery modes, alternative fuels, operational templates (e.g. BDNs), bunker assets (e.g. bunker vessels)',
          'Upload company logo, used for e.g. operational documents',
        ])}
      </div>
    );
  }
};

export default React.memo(UserForm);
