import React, { useState, useCallback, useEffect } from 'react';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { getVessels } from '../../store/Vessels/actions';
import { FormGroup, Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Vessel } from '../../store/Vessels/models';
import SearchVesselResult from './Components/SearchVesselResult/SearchVesselResult';
import styles from './SearchVessel.module.scss';
import ShipsLogo from '../../svgs/add-ship.svg';
import classnames from 'classnames';
import { FleetVessel } from '../../store/CompanyFleet/models';
import { FleetCharterer } from '../../store/CompanyCharteredFleet/models';

interface OwnProps {
  chooseVesselMethod?: (vessel: FleetVessel) => void;
  hidden?: boolean;
  buttonStyle?: string;
  isCompanyAdmin: boolean;
  companyFleet?: any[];
  companyCharteredFleet: FleetCharterer[] | undefined;
}

const SearchCharterVessel: React.FC<OwnProps> = ({
  chooseVesselMethod,
  hidden = false,
  buttonStyle,
  isCompanyAdmin,
  companyFleet,
  companyCharteredFleet,
}) => {
  const [vesselsIsLoaded, setVesselsIsLoaded] = useState(false);
  const [vessels, setVessels] = useState([] as FleetVessel[]);

  useEffect(() => {
    async function loadCharterableVessels() {
      const baseUrl = '/api/FleetVessels/allcharterable/';
      const response = await fetch(baseUrl);
      if (response.ok) {
        const jsonValue = await response.json();
        setVesselsIsLoaded(true);
        setVessels(jsonValue as FleetVessel[]);
      }
    }
    loadCharterableVessels();
  }, []);

  const dispatch = useDispatch();
  const getVesselsCallback = useCallback(() => dispatch(getVessels()), []);

  const [suggestions, setSuggestions] = useState<FleetVessel[]>([]);
  const [activeVessel, setActiveVessel] = useState(0);
  const [chosenVessel, setChosenVessel] = useState('');
  const [vesselObj, setVesselObj] = useState<FleetVessel>();
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const checkVesselInFleet = () => {
    if (companyFleet && vesselObj) {
      const existsInFleet = companyFleet.find((x) => x.vessel.imo == vesselObj?.vessel.imo);
      if (existsInFleet) {
        toggle();
        return;
      }
    }
    addVesselClick();
  };
  let StyledButton: React.CSSProperties = {
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    height: '50px',
    padding: '0 20px 3px 15px',
  } as React.CSSProperties;
  let StyledInput: React.CSSProperties = { height: '50px', width: '400px', borderRadius: '0' } as React.CSSProperties;
  let StyledInputParent: React.CSSProperties = {
    flex: '1',
    display: 'flex',
    height: '46px',
    whiteSpace: 'nowrap',
  } as React.CSSProperties;
  let StyledImage: React.CSSProperties = {
    marginRight: '10px',
    width: '30px',
    height: '25px',
  } as React.CSSProperties;
  let StyledFormGroup: React.CSSProperties = {
    position: 'relative',
    marginTop: '20px',
    marginBottom: '10px',
  } as React.CSSProperties;
  let StyledOwnedVesselText: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    paddingRight: '5px',
  } as React.CSSProperties;
  let StyledSuggestion: React.CSSProperties = {
    zIndex: 2,
  } as React.CSSProperties;

  useEffect(() => {
    if (!vesselsIsLoaded) {
      getVesselsCallback();
    }
  }, []);

  const onChange = (val: string) => {
    setActiveVessel(0);
    setChosenVessel(val);
    setVesselObj(undefined);
    setSuggestions(vessels.filter((vessel) => checkMatch(vessel, val)));
  };

  const checkMatch = (fleetVessel: FleetVessel, val: string) => {
    if (val.length < 1) return false;
    let match = false;

    //Compares input value with imo
    if (fleetVessel.vessel.imo !== null && fleetVessel.vessel.imo !== undefined) {
      if (fleetVessel.vessel.imo.toString().indexOf(val) > -1) {
        match = true;
      }
    }

    //Compares input value with vessel name
    if (fleetVessel.vessel.vesselName !== undefined && fleetVessel.vessel.vesselName !== null) {
      if (fleetVessel.vessel.vesselName.toLowerCase().indexOf(val.toLowerCase()) > -1) {
        match = true;
      }
    }

    //Compares input value with company name
    if (fleetVessel.company.name !== undefined && fleetVessel.company.name !== null) {
      if (fleetVessel.company.name.toLowerCase().indexOf(val.toLowerCase()) > -1) {
        match = true;
      }
    }

    //if already in charted fleet dont match
    if (companyCharteredFleet && fleetVessel) {
      const exists = companyCharteredFleet.find(x => x.fleetVessel?.companyId == fleetVessel.companyId && x.fleetVessel?.vessel.id == fleetVessel.vessel.id);
      if (exists) {
        match = false;
      }
    }

    //Place for more cases if needed in the future
    return match;
  };

  const chooseVessel = (vessel: FleetVessel, index: number) => {
    setChosenVessel(vessel.vessel.vesselName);
    setVesselObj(vessel);
    setSuggestions([]);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      //Enter key
      chooseVessel(suggestions[activeVessel], activeVessel);
    } else if (e.keyCode === 38) {
      //Up key
      if (activeVessel > 0) {
        setActiveVessel(activeVessel - 1);
      }
    } else if (e.keyCode === 40) {
      //Down key
      if (activeVessel + 1 < suggestions.length) {
        setActiveVessel(activeVessel + 1);
      }
    }
  };

  const addVesselClick = () => {
    if (vesselObj !== undefined && chooseVesselMethod) {
      chooseVesselMethod(vesselObj);
      setChosenVessel('');
    }
  };

  return (
    // @ts-ignore
    <FormGroup hidden={hidden} style={StyledFormGroup} autoComplete="off">
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
        <ModalBody>
          <>
            The vessel {vesselObj?.vessel.vesselName} already exists in your fleet as an owned vessel. Are you sure you
            want to add it as a chartered vessel?
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              addVesselClick();
              toggle();
            }}
          >
            Confirm
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div style={StyledInputParent}>
        <h3 style={StyledOwnedVesselText}>Chartered vessels</h3>
        {!isSearchInputVisible && isCompanyAdmin && (
          <Button
            className={buttonStyle}
            color="primary"
            size="lg"
            disabled={false}
            onClick={(e) => setIsSearchInputVisible(true)}
            // @ts-ignore
            style={StyledButton}
          >
            <img src={ShipsLogo} alt="" width="25px" height="20px" style={StyledImage} />
            Add vessel
          </Button>
        )}
        {isSearchInputVisible && (
          <Input
            // @ts-ignore
            style={StyledInput}
            type="text"
            name="vessel"
            id="vessel"
            onChange={(e) => onChange(e.target.value)}
            value={chosenVessel}
            onKeyDown={onKeyDown}
            autoComplete="off"
            placeholder="Type vessel name ..."
          />
        )}
        {isSearchInputVisible && (
          <Button
            className={buttonStyle}
            color="primary"
            size="lg"
            disabled={!vesselObj}
            onClick={checkVesselInFleet}
            // @ts-ignore
            style={StyledButton}
          >
            <>
              <img src={ShipsLogo} alt="" width="25px" height="20px" style={StyledImage} />
              Add vessel
            </>
          </Button>
        )}
      </div>

      <ul style={StyledSuggestion} className={styles.suggestions}>
        {suggestions.map((fleetVessel: FleetVessel, index: any) => (
          <div key={fleetVessel.id}>
            <li
              onClick={() => chooseVessel(fleetVessel, index)}
              className={classnames(activeVessel === index ? styles.active : '', styles.suggestion)}
            >
              <div className={styles.name}>Name: {fleetVessel.vessel.vesselName}</div>
              <div className={styles.imo}>
                IMO: {fleetVessel.vessel.imo}, <>{fleetVessel.company.name}</>
              </div>
            </li>
          </div>
        ))}
      </ul>
    </FormGroup>
  );
};

export default React.memo(SearchCharterVessel);
