import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import { IntegrationEventType} from '../../../../../store/IntegrationEventTypes/models';

import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs'
import { Formik } from 'formik';
import IntegrationEventTypeForm from '../IntegrationAssetForm/IntegrationEventTypeForm'
import { Loading } from '../../../../Loading';
import integrationEventTypeFormValidation from '../FormValidations/integrationEventTypeFormValidation';
import { initialValues } from '../FormInitialValues/integrationEventTypeFormInitialValues';

interface OwnProps {
  editIntegrationEventType: (integrationEventType: IntegrationEventType) => void;
  integrationEventTypeId: string;
  company?: Company;
  integrationEventTypes: IntegrationEventType[];
  navAdminType: string;
  companyAdmin?: boolean;
  isLoading?: boolean;
}

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

const EditIntegrationEventType: React.FunctionComponent<OwnProps> = ({
  editIntegrationEventType,
  companyAdmin,
  navAdminType,
  company,
  integrationEventTypeId,
  integrationEventTypes,
  isLoading,
}) => {
  const submitIntegrationEventType = (integrationEventType: IntegrationEventType) => {
    editIntegrationEventType(integrationEventType);
  };

  const selectedIntegrationEventType: IntegrationEventType | undefined = integrationEventTypes.find((it) => it.id === integrationEventTypeId);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={selectedIntegrationEventType ? selectedIntegrationEventType : initialValues}
      validationSchema={integrationEventTypeFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationEventTypeForm
          integrationEventType={selectedIntegrationEventType}
          header={'Edit integration event type'}
          saveMethod={submitIntegrationEventType}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(EditIntegrationEventType);
