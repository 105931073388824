import { Chance } from 'chance';
import { getLightDarkColor } from './getLightDarkColor';

export const getNameColor = (nameColor?: string) => {
  const nameColors: string[] = [
    '#52feee',
    '#44dff1',
    '#e3544e',
    '#4997ab',
    '#e77c5a',
    '#46d2a5',
    '#e3544e',
    '#438fe5',
    '#175d45',
    '#14557f',
    '#53b5e4',
    '#1f3835',
    '#f1cb44',
    '#75bdb9',
    '#74f1d1',
    '#3c5760',
    '#06202d',
    '#264653',
  ];

  if (nameColor === '' || nameColor === undefined || nameColor === null) {
    return { fontColor: 'black', bgcolor: 'white' };
  }

  nameColor = nameColor.toLowerCase();

  const chance: Chance.Chance = new Chance(nameColor);
  const colorNumb = chance.integer({
    min: 0,
    max: nameColors.length - 1,
  });

  const fontColor = getLightDarkColor(nameColors[colorNumb]);
  const bgcolor = nameColors[colorNumb];

  const styling: React.CSSProperties = {
    color: fontColor,
    backgroundColor: bgcolor,
  };
  return styling;
};
