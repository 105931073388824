import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AuthenticationServiceLayer,
  ICompany,
  ILocation,
  IShipWithId,
  IPromptNomination,
  IPipeline,
  IContract,
  IShipWithCompanyId,
} from '@teqplay/chorus-components';
import { ErrorWrapper } from './../../../../models/ErrorAction';
import { useNominationService, useLocationService, CachedLocationService } from '@teqplay/chorus-components';

import { useState } from 'react';
import { ApplicationState } from '../../../../store';
import { Loading } from '../../../../components/Loading/index';
import { Error } from './../../../../components/Error/index';
import './../../../BunkerEvents/Components/ChorusIntegration/ChorusStyleOverrides.scss';

import { canCreateNominationOnBehalf } from '../../../../helpers/roleChecks';
import { getVolumeStats } from '../../../../store/VolumeStats/actions';
import CreateRecurringNomination from './CreateRecurringNomination';
import {
  addRecurringNomination,
  getRecurringNominations,
  updateRecurringNomination,
} from '../../../../store/RecurringNomination/actions';
import { RecurringNomination } from '../../../../store/RecurringNomination/models';
import { useHistory } from 'react-router';

interface OwnProps {
  chooseNomination: (nominationId: string) => void;
  bunkerShips: IShipWithCompanyId[];
  bunkerPipelines: IPipeline[];
  companies: ICompany[];
  locationService: CachedLocationService;
  contracts: IContract[];
  creatingNewNominationBool?: boolean;
  className?: string;
  yearMonth: string;
  vendorCompanies?: ICompany[];
  serviceLayer: AuthenticationServiceLayer;
}

const NewRecurringNomination: React.FC<OwnProps> = ({
  chooseNomination,
  bunkerShips,
  bunkerPipelines,
  companies,
  locationService,
  contracts,
  creatingNewNominationBool = false,
  className,
  yearMonth,
  vendorCompanies,
  serviceLayer,
}) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));
  const dispatch = useDispatch();
  const errorWrapperObject: ErrorWrapper = {};
  const history = useHistory();

  const getRecurringNominationCallback = React.useCallback(() => dispatch(getRecurringNominations()), []);

  const addRecurringNominationCallback = React.useCallback(
    (nomination: RecurringNomination) =>
      dispatch(
        addRecurringNomination(nomination, () => {
          history.push(`/recurringnominations`);
        }),
      ),
    [],
  );

  const editRecurringNominationCallback = React.useCallback(
    (nomination: RecurringNomination) =>
      dispatch(
        updateRecurringNomination(nomination, () => {
          history.push(`/recurringnominations`);
        }),
      ),
    [],
  );

  const successCallback = (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE',
    updatedNomination: RecurringNomination,
  ) => {
    getRecurringNominationCallback();
    if (action === 'CREATE') {
      chooseNomination(`${updatedNomination.id}`);
    }
  };
  const [loading, setLoading] = useState(false);

  const updateRecurringNominationMethod = (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE',
    updatedNomination: RecurringNomination,
  ) => {
    if (action == 'CREATE') {
      addRecurringNominationCallback(updatedNomination);
    }
    if (action == 'AMEND') {
      editRecurringNominationCallback(updatedNomination);
    }
    if (action == 'COUNTER') {
      editRecurringNominationCallback(updatedNomination);
    }

    const promise = new Promise<void>(() => {});
    return promise;
  };

  if (errorWrapperObject.payload) {
    return <Error error={errorWrapperObject.payload} />;
  } else if (creatingNewNominationBool) {
    return (
      <div className={className}>
        <div className={`sticky-top prompt-negotiation-wrapper`}>
          <div className="prompt-form">
            <div className="form-section create-nomination-header">
              <h2 className="form-header">Create new recurring nomination{''}</h2>
            </div>
          </div>
        </div>
        <CreateRecurringNomination
          userProfile={auth.chorusUserProfile}
          updateRecurringNomination={updateRecurringNominationMethod}
          authenticationService={serviceLayer}
          companies={companies}
          //using location service instead of locationlist
          locationService={locationService}
          bunkerShips={bunkerShips}
          pipelines={bunkerPipelines}
          //vendorCompanies={vendorCompanies}
          showFuelbossStates={true}
          showDeliveryModeSelection={true}
          contractsList={contracts}
          enableAlternativeFuelSelector={true}
        />
      </div>
    );
  } else if (loading) {
    return <Loading type="Chorus API" />;
  } else {
    return null;
  }
};

export default NewRecurringNomination;
