import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ChorusIntegration.module.scss';
import {
  AuthenticationServiceLayer,
  IPromptNomination,
  ICompany,
  ILocation,
  IShipWithId,
  IPipeline,
  IContract,
  BunkerPlannerDocumentType,
  nominationRefreshHandler,
  IShipWithCompanyId,
  NoticeOfReadiness,
  MastersRequisition,
  useGlobalDataService,
} from '@teqplay/chorus-components';

import {
  DocumentHandler,
  useNominationService,
  useNominationCollectionService,
  useLocationService,
  NominationNegotiation,
  DelegationContainer,
  SOFOverview,
  SafetyChecklistForms,
  BDNOverview,
  CachedLocationService,
  NominationServiceProps,
} from '@teqplay/chorus-components';

import './ChorusStyleOverrides.scss';

import { useState } from 'react';
import { ApplicationState } from '../../../../store';
import { Loading } from '../../../../components/Loading/index';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import { ErrorWrapper } from './../../../../models/ErrorAction';
import { Error } from './../../../../components/Error/index';
import { getArchivedNominationsInfiniteScroll } from './../../../../store/ArchivedNomination/actions';
import { RoleType } from '../../../../models/RoleType';
import { toast } from 'react-toastify';
import useInterval from '../../../../helpers/useInterval';
import { getVolumeStats } from '../../../../store/VolumeStats/actions';
import { checkShipOwner, checkSupplier } from '../../../../helpers/roleChecks';
import { getFleetVessels } from '../../../../store/CompanyFleet/actions';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { getNominationsInfiniteScroll } from '../../../../store/Nomination/actions';
import { checkListDocumentTypesGothenburg, checkListDocumentTypesIAPH, checkListDocumentTypesIAPHV2, checkListDocumentTypesTR56 } from './SafteyChecklistsDocumentTypes';

interface OwnProps {
  nominationId: string;
  className?: string;
  bunkerShips: IShipWithCompanyId[];
  bunkerPipelines: IPipeline[];
  companies: ICompany[];
  locationService: CachedLocationService;
  contracts: IContract[];
  yearMonth: string;
  vendorCompanies?: ICompany[];
  setRenderChat: (b: boolean) => void;
  serviceLayer: AuthenticationServiceLayer;
  setLatestNomination: (nomination: IPromptNomination | undefined) => void;
}

const ChorusIntegration: React.FC<OwnProps> = ({
  nominationId,
  className,
  bunkerShips,
  bunkerPipelines,
  companies,
  contracts,
  locationService,
  yearMonth,
  vendorCompanies,
  setRenderChat,
  serviceLayer,
  setLatestNomination,
}) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));

  const vesselList = useSelector((state: ApplicationState) => state.vessels.data);
  const companyFleetList = useSelector((state: ApplicationState) => state.companyFleet.data);
  const errorWrapperObject: ErrorWrapper = {};

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [newVersionToastShowing, setNewVersionToastShowing] = useState(false);
  const [vesselNameChangeToastShowing, setVesselNameChangeToastShowing] = useState(false);
  const [bdnWriteable, setBdnWritable] = useState(false);
  const [receivingShipImo, setReceivingShipImo] = useState(null);
  const [receivingShipMmsi, setReceivingShipMmsi] = useState('');
  const { userFleet: customerFleet, pipelines } = useGlobalDataService(serviceLayer, auth.chorusUserProfile);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dispatch = useDispatch();

  const getNominationCallback = React.useCallback(() => dispatch(getNominationsInfiniteScroll(0)), []);

  const getArchivedNominationCallback = React.useCallback(() => dispatch(getArchivedNominationsInfiniteScroll(0)), []);


  const successCallback = (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE',
    updatedNomination: IPromptNomination,
  ) => {
    getNominationCallback();
    getArchivedNominationCallback();
    dispatch(getVolumeStats());
  };

  const isCustomerOperator = auth.roles.includes(RoleType.VesselOperator);
  const isProcurement = auth.roles.includes(RoleType.Procurement);

  const notReceivingCaptain = () => {
    let ret = true;
    auth.roles.forEach((role) => {
      if (role === RoleType.RecVesselCrew) ret = false;
    });
    return ret;
  };

  const notBunkerVesselCrew = () => {
    let ret = true;
    auth.roles.forEach((role) => {
      if (role === RoleType.BunkerVesselCrew) ret = false;
    });
    return ret;
  };

  const notReceivingVesselCaptainScheduler = () => {
    let ret = true;
    auth.roles.forEach((role) => {
      if (role === RoleType.ReceivingCaptainScheduler) ret = false;
    });
    return ret;
  };

  const creatingNominationOnBehalf = () => {
    auth.roles.forEach((role) => {
      if (role === RoleType.Operator) return true;
      if (role === RoleType.Commercial) return true;
    });
    return false;
  };

  React.useEffect(() => {

    setVesselNameChangeToastShowing(false);
    toast.dismiss();
  }, [nominationId]);


  React.useEffect(() => {
    if (newVersionToastShowing) toast.dismiss();
  }, []);


  const onNewerVersionAvailable = (refetchNomination: () => void, setNewerVersion: (bool: boolean) => void) => {
    if (!newVersionToastShowing) {
      setNewVersionToastShowing(true);
      toast(
        <div className={styles['toast']}>
          <p>
            There is a newer version of the nomination available.
            <br />
            If you load the latest nomination you will lose your changes.
          </p>
          <Button
            color="primary"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              refetchNomination();
              getNominationCallback();
              setNewerVersion(false);
            }}
          >
            Load latest nomination
          </Button>
        </div>,
        {
          autoClose: false,
          position: 'bottom-right',
          className: 'warning-notification',

          onClose: () => {
            setNewerVersion(false);
            setNewVersionToastShowing(false);
          },
          closeButton: false,
        },
      );
    }
  };

  const fetchReceivingShip = async (latestNomination: any, matchingReceivingShip: any) => {
    const header = new Headers();
    if (matchingReceivingShip) {
      try {
        const response = await fetch(`api/chorus/GetReceivingShip?companyId=${latestNomination?.companyId}&id=${matchingReceivingShip?.chorusId}`, {
          method: 'GET',
          headers: header,
        });

        if (response.ok) {
          const data = await response.json();
          const result = {
            imo: data.imoNumber, // access the imo variable returned by the API
            mmsi: data.mmsi, // access the mmsi variable returned by the API
          }
          return result;
        } else {
          throw Error;
        }
      } catch (error) {
        throw Error;
      }
    }
  }

  const syncReceivingSVessels = async () => {

    const header = new Headers();
    const matchingReceivingShip = companyFleetList.find((v) => v.chorusId === latestNomination?.receivingShipId);
    const fetchedReceivingShip = await fetchReceivingShip(latestNomination, matchingReceivingShip);
    const hasImoChanged = fetchedReceivingShip?.imo !== matchingReceivingShip?.vessel.imo?.toString();

    const hasMmsiChanged = String(fetchedReceivingShip?.mmsi )!== String(matchingReceivingShip?.vessel.mmsi);


    if (matchingReceivingShip) {
      if (hasImoChanged || hasMmsiChanged)
        try {
          const response = await fetch(`api/chorus/UpdateReceivingShip?chorusId=${matchingReceivingShip.chorusId}&vesselName=${latestNomination?.attributes?.receivingShipName}&mmsi=${matchingReceivingShip.vessel.mmsi}&imoNumber=${matchingReceivingShip.vessel.imo}`, {
            method: 'POST',
            headers: header,
          });

          if (response.ok) {
            const data = await response.json();
            return data;
          } else {
            throw Error;
          }
        } catch (error) {
          throw Error;
        }
    }
  }

  const syncbunkerVessels = async () => {
    const header = new Headers();
    const matchingBunkerShip = companyFleetList.find((v) => v.chorusId === latestNomination?.bunkerShipId);
    const findMatchingBuker = bunkerShips.find((v) => v._id === matchingBunkerShip?.chorusId);
    const hasMmsiChanged = String(findMatchingBuker?.mmsi) !== String(matchingBunkerShip?.vessel.mmsi)
    const hasImoChanged = findMatchingBuker?.imoNumber !== matchingBunkerShip?.vessel.imo?.toString()

    if (matchingBunkerShip ) {
      if (hasImoChanged || hasMmsiChanged) {
        try {
          const response = await fetch(`api/chorus/UpdateBunkerShip?chorusId=${matchingBunkerShip?.chorusId}&vesselName=${latestNomination?.attributes?.bunkerShipName}&mmsi=${matchingBunkerShip.vessel.mmsi}&imoNumber=${matchingBunkerShip?.vessel.imo}`, {
            method: 'POST',
            headers: header,
          });

          if (response.ok) {
            const data = await response.json();
            return data;
          } else {
            throw Error;
          }
        } catch (error) {
          throw Error;
        }
      }
    }
  }

  const syncVesselName = async () => {

    const header = new Headers();
    header.append('content-type', 'application/json');
    if (!vesselNameChangeToastShowing) {
      setVesselNameChangeToastShowing(true);
      if (checkShipOwner(auth)) {
        const matchingReceivingShip = companyFleetList.find((v) => v.chorusId === latestNomination?.receivingShipId);
        const hasNameChanged = latestNomination?.attributes?.receivingShipName !== matchingReceivingShip?.vessel.vesselName;

        if (matchingReceivingShip && hasNameChanged) {
          toast(
            <div className={styles['toast']}>
              <p>
                We have detected a vessel change:
              </p>
              {hasNameChanged && (
                <p>
                  Ship name has been changed from <strong>{latestNomination?.attributes?.receivingShipName}</strong> to <strong>{matchingReceivingShip?.vessel.vesselName}</strong>.
                </p>
              )}
              <p>Would you like to update the nomination with the new vessel changes?</p>
              <Button
                color="primary"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  fetch(`api/chorus/UpdateReceivingShip?chorusId=${matchingReceivingShip.chorusId}&vesselName=${matchingReceivingShip.vessel.vesselName}&mmsi=${matchingReceivingShip.vessel.mmsi}&imoNumber=${matchingReceivingShip.vessel.imo}`, {
                    method: 'POST',
                    headers: header,
                  })
                    .then((response) => {
                      if (response.ok) {
                        return response.json();
                      } else {
                        throw Error
                      }
                    })
                    .then((data) => {
                      refetchNominationData();
                      window.location.reload();
                      setVesselNameChangeToastShowing(false);

                    })
                    .catch((error) => {
                      throw Error;
                    });
                }
                }
              >
                Load changes and refresh page
              </Button>
            </div>,
            {
              autoClose: false,
              position: 'bottom-right',
              className: 'warning-notification',
              onClose: () => {
                setVesselNameChangeToastShowing(false);
                setNewVersionToastShowing(false);
              },
              closeButton: false,
            },
          );
        }
      } else if (checkSupplier(auth)) {
        const matchingBunkerShip = companyFleetList.find((v) => v.chorusId === latestNomination?.bunkerShipId);
        const hasNameChanged = latestNomination?.attributes?.bunkerShipName !== matchingBunkerShip?.vessel.vesselName;

        if (matchingBunkerShip && hasNameChanged) {
          toast(
            <div className={styles['toast']}>
              <p>
                We have detected a vessel change:
              </p>
              {hasNameChanged && (
                <p>
                  Ship name has been changed from <strong>{latestNomination?.attributes?.bunkerShipName}</strong> to <strong>{matchingBunkerShip?.vessel.vesselName}</strong>.
                </p>
              )}

              <p>Would you like to update the nomination with the new vessel changes?</p>
              <Button
                color="primary"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  fetch(`api/chorus/UpdateBunkerShip?chorusId=${matchingBunkerShip?.chorusId}&vesselName=${matchingBunkerShip?.vessel.vesselName}&mmsi=${matchingBunkerShip.vessel.mmsi}&imoNumber=${matchingBunkerShip?.vessel.imo}`, {
                    method: 'POST',
                    headers: header,
                  })
                    .then((response) => {
                      if (response.ok) {
                        return response.json();
                      } else {
                        throw Error
                      }
                    })
                    .then((data) => {
                      refetchNominationData();
                      window.location.reload();
                      setVesselNameChangeToastShowing(false);

                    })
                    .catch((error) => {
                      throw Error
                    });
                }
                }
              >
                Load changes and refresh page
              </Button>
            </div>,
            {
              autoClose: false,
              position: 'bottom-right',
              className: 'warning-notification',
              onClose: () => {
                setVesselNameChangeToastShowing(false);
                setNewVersionToastShowing(false);
              },
              closeButton: false,
            },
          );
        }
      }
    }
  }


  const onRefreshNotification = () => {
    toast.info('Loaded newest nomination data', {
      autoClose: 2000,
      closeOnClick: true,
      position: 'bottom-right',
    });
  };

  const callbackOnOrderPDFConfirmed = () => {
    toggle('2');
    refetchNominationData();

  };

  const nominationProps: NominationServiceProps = {
    nominationId: nominationId,
    authenticationService: serviceLayer,
    userProfile: auth.chorusUserProfile,
    setLoading: setLoading,
    onNewerVersionAvailable: (refresh, setNewNomination) => {
      nominationRefreshHandler(
        !nominationInEditingState && !delegationInEditingState,
        refresh,
        setNewNomination,
        onNewerVersionAvailable, //onNewerVersionAvailablePopup also exported by Teqplay but using our own,
        onRefreshNotification,
      );
    },

    sandboxId: undefined,
    vendorCompanyId: auth.chorusUserProfile.companyId ? auth.chorusUserProfile.companyId : '',
    successCallback: successCallback,
  };

  const [nominationInEditingState, setNominationInEditingState] = useState<boolean>(false);
  const [delegationInEditingState, setDelegationInEditingState] = useState<boolean>(false);
  const { delegatedNomination, originalNomination } = useNominationCollectionService(nominationProps);

  const showEbdnTab = notReceivingCaptain() && notReceivingVesselCaptainScheduler() && !isCustomerOperator && !isProcurement;
  const {
    latestNomination,
    nominationHistory,
    nominationSelection,
    updatePromptNomination,
    updatePromptNominationState,
    updateSandboxPromptNomination,
    attachFileToNomination,
    refetchNominationData,
    createBDNDocument,
    attachEBDNToNomination,
    checkIfNewerNominationAvailable,
    generateAndSendOrderConfirmation,
  } = originalNomination;

  const showSofTab = notReceivingCaptain() && notReceivingVesselCaptainScheduler() && !isCustomerOperator && !isProcurement;
  const showChecklistTab = true;
  const showMasterRequisitionTab = latestNomination && latestNomination.deliveryMode != DeliveryModes.Container;
  const showNoticeOfReadinessTab = latestNomination && latestNomination.deliveryMode != DeliveryModes.Container;

  //continually checks if a new nomination is availible
  useInterval(() => {
    checkIfNewerNominationAvailable();
  }, 60000);

  React.useEffect(() => {
    setRenderChat(true);
    if (latestNomination) {
      syncVesselName();
    }
    setLatestNomination(latestNomination);
  }, [latestNomination]);

  React.useEffect(() => {
    if (latestNomination) {
      syncReceivingSVessels();
    }
  }, [latestNomination]);

  React.useEffect(() => {
    if (latestNomination) {
      syncbunkerVessels();
    }
  }, [latestNomination]);

  React.useEffect(() => {
    if (auth && auth.roles) {
      auth.roles.forEach((role) => {
        if (role === RoleType.Operator || role === RoleType.Commercial || role === RoleType.BunkerVesselCrew) {
          setBdnWritable(true);
          return;
        }
      });
    }
  }, [auth]);


  if (errorWrapperObject.payload) {
    return <Error error={errorWrapperObject.payload} />;
  } else if (nominationId && nominationId != '' && latestNomination) {
    return (
      <div className={className}>
        <Nav tabs className={`sticky-top bg-white ${styles['navbar']} bunker-events-nav`}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              Nomination details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              Documents
            </NavLink>
          </NavItem>
          {showChecklistTab && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                }}
              >
                Safety checklist
              </NavLink>
            </NavItem>
          )}
          {showMasterRequisitionTab && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggle('4');
                }}
              >
                MRF
              </NavLink>
            </NavItem>
          )}
          {showNoticeOfReadinessTab && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '5' })}
                onClick={() => {
                  toggle('5');
                }}
              >
                NOR
              </NavLink>
            </NavItem>
          )}
          {showSofTab && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '6' })}
                onClick={() => {
                  toggle('6');
                }}
              >
                Statement of facts
              </NavLink>
            </NavItem>
          )}
          {showEbdnTab && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '7' })}
                onClick={() => {
                  toggle('7');
                }}
              >
                eBDN
              </NavLink>
            </NavItem>
          )}
        </Nav>
        {loading && (
          <Loading small={true} type="nomination data" className={styles['loading']}>
            loading
          </Loading>
        )}
        <TabContent activeTab={activeTab} className={'chorus-wrapper'}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <DelegationContainer
                  key={latestNomination._id}
                  //readOnly={false}
                  //yearMonth={yearMonth}
                  user={auth.chorusUserProfile}
                  //userProfile={auth.chorusUserProfile}
                  //initialNomination={latestNomination}
                  originalNomination={originalNomination}
                  delegatedNomination={delegatedNomination}
                  //nominationHistory={nominationHistory}
                  //nominationSelection={nominationSelection}
                  //isNominationInSandbox={false}
                  //updatePromptNomination={updatePromptNomination}
                  //updateSandboxPromptNomination={updateSandboxPromptNomination}
                  //updatePromptNominationState={updatePromptNominationState}
                  authenticationService={serviceLayer}
                  companies={companies}
                  //using location service instead of locationlist
                  locationService={locationService}
                  bunkerShips={bunkerShips}
                  pipelines={bunkerPipelines}
                  //showProposalInformation={true}
                  //showFuelbossStates={true}
                  //showDeliveryModeSelection={true}
                  contractsList={contracts}
                  //enableAlternativeFuelSelector={true}
                  //generateAndSendOrderConfirmation={generateAndSendOrderConfirmation}
                  onOrderPDFConfirmed={callbackOnOrderPDFConfirmed}
                  /* reloadNominationData={() => {
                    refetchNominationData();
                  }} */
                  setEditingModeLHS={setNominationInEditingState}
                  setEditingModeRHS={setDelegationInEditingState}
                  showContractCheckIntegration={false}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <DocumentHandler
                  authenticationService={serviceLayer}
                  eventWithDocuments={latestNomination}
                  uploadActive={true}
                  userProfile={auth.chorusUserProfile}
                  uploadFile={attachFileToNomination}
                  allowedDocumentTypes={
                    notReceivingCaptain() && notBunkerVesselCrew()
                      ? ([
                          'EBDN',
                          'OTHER',
                          ...checkListDocumentTypesIAPH,
                          ...checkListDocumentTypesGothenburg,
                          ...checkListDocumentTypesTR56,
                          ...checkListDocumentTypesIAPHV2,
                          'STATEMENT_OF_FACTS',
                          'ORDER_CONFIRMATION',
                          'MASTERS_REQUISITION_FORM',
                          'NOR_BUNKERSHIP',
                          'NOR_RECEIVINGSHIP',
                          //  'EBDN_SPREADSHEET',
                          //  'VOYAGE_ORDERS',
                          //  'DELIVERY_NOTE',
                          //  'IMAGE',
                          //  'INVOICE',
                        ] as BunkerPlannerDocumentType[])
                      : ([
                          'EBDN',
                          'OTHER',
                          ...checkListDocumentTypesIAPH,
                          ...checkListDocumentTypesGothenburg,
                          ...checkListDocumentTypesTR56,
                          ...checkListDocumentTypesIAPHV2,
                          'STATEMENT_OF_FACTS',
                          'MASTERS_REQUISITION_FORM',
                          'NOR_BUNKERSHIP',
                          'NOR_RECEIVINGSHIP',
                        ] as BunkerPlannerDocumentType[])
                  }
                  uploadId={'NominationPage-Documents-Upload'}
                  uploadFileType={'OTHER'}
                  readOnly={false}
                  hideEmptyMessage
                  refetchEvent={() => {
                    refetchNominationData();
                  }}
                  updateItem={async (updatedNomination) => {
                    await updatePromptNomination('AMEND', updatedNomination as IPromptNomination);
                  }}
                />
              </Col>
            </Row>
          </TabPane>
          {/* show Checklist for all delivery modes SHIP/PIPE/TRUCK/CONTAINER*/}
          {showChecklistTab && (
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <SafetyChecklistForms
                    userProfile={auth.chorusUserProfile}
                    uploadFile={attachFileToNomination}
                    nomination={latestNomination}
                    refreshIntervalMs={10000}
                    authenticationService={serviceLayer}
                    refetchEvent={() => {
                      refetchNominationData();
                    }}
                    //readOnly={isCustomerOperator || isProcurement}
                    updateEvent={async (updatedNomination) => {
                      await updatePromptNomination('AMEND', updatedNomination as IPromptNomination, true);
                    }}
                  ></SafetyChecklistForms>
                </Col>
              </Row>
            </TabPane>
          )}
          {showMasterRequisitionTab && (
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <MastersRequisition
                    authenticationService={serviceLayer}
                    refetchEvent={() => {
                      refetchNominationData();
                    }}
                    userProfile={auth.chorusUserProfile}
                    event={latestNomination}
                    bunkerVessels={bunkerShips}
                    companies={companies}
                    customerFleet={customerFleet}
                  ></MastersRequisition>
                </Col>
              </Row>
            </TabPane>
          )}
          {showNoticeOfReadinessTab && (
            <TabPane tabId="5">
              <Row>
                <Col sm="12">
                  <NoticeOfReadiness
                    authenticationService={serviceLayer}
                    refetchNominationData={() => {
                      refetchNominationData();
                    }}
                    userProfile={auth.chorusUserProfile}
                    event={latestNomination}
                    updateItem={async (updatedNomination) => {
                      await updatePromptNomination('AMEND', updatedNomination as IPromptNomination);
                    }}
                  ></NoticeOfReadiness>
                </Col>
              </Row>
            </TabPane>
          )}
          {notReceivingCaptain() && (
            <TabPane tabId="6">
              <Row>
                <Col sm="12">
                  {/* <div className={styles['sofDocument']}>
                    <DocumentHandler
                      authenticationService={serviceLayer}
                      eventWithDocuments={latestNomination}
                      uploadActive={false}
                      userProfile={auth.chorusUserProfile}
                      uploadFile={attachFileToNomination}
                      allowedDocumentTypes={['STATEMENT_OF_FACTS']}
                      uploadId={'NominationPage-Voyage-SOF-Upload'}
                      uploadFileType={'OTHER'}
                      readOnly={false}
                      hideEmptyMessage
                      refetchEvent={() => {
                        refetchNominationData();
                      }}
                      updateItem={async (updatedNomination) => {
                        await updatePromptNomination('AMEND', updatedNomination as IPromptNomination);
                      }}
                    />
                  </div> */}

                  <SOFOverview
                    authenticationService={serviceLayer}
                    event={latestNomination}
                    userProfile={auth.chorusUserProfile}
                    //using location service instead of locationlist
                    locationService={locationService}
                    vendorCompanyId={auth.chorusUserProfile.companyId || ''}
                    onGenerateDocument={() => {
                      refetchNominationData();
                    }}
                    refetchEvent={() => {
                      refetchNominationData();
                    }}
                    updateItem={async (updatedNomination) => {
                      await updatePromptNomination('AMEND', updatedNomination as IPromptNomination);
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {showEbdnTab && (
            <TabPane tabId="7">
              <Row>
                <Col sm="12">
                  <BDNOverview
                    nomination={latestNomination}
                    authenticationService={serviceLayer}
                    createBDNDocument={createBDNDocument}
                    userProfile={auth.chorusUserProfile}
                    refetchEvent={() => {
                      refetchNominationData();
                    }}
                    updateNomination={async (updatedNomination: any) => {
                      await updatePromptNomination('AMEND', updatedNomination as IPromptNomination);
                      refetchNominationData();
                    }}
                    attachEBDNToNomination={attachEBDNToNomination}
                    //readOnly={!bdnWriteable}
                    vendorCompanyId={auth.chorusUserProfile.companyId || ''}
                    calculationsEnabled={true}
                  ></BDNOverview>
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  } else if (loading) {
    return <Loading type="Chorus API" />;
  } else {
    setRenderChat(false);
    return null;
  }
};

export default ChorusIntegration;
