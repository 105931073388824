import React from 'react';
import { DeliveryModes } from '../../../../../../models/DeliveryMode';

import styles from './DeliveryModeSelector.module.scss';
import DeliveryModeIcon from '../../../../../../components/Nomination/Components/DeliveryModeIcon/DeliveryModeIcon';

interface OwnProps {
  deliveryModes: DeliveryModes[];
  setDeliveryModes: (value: DeliveryModes[]) => void;
}

const DeliveryModeSelector: React.FC<OwnProps> = ({ deliveryModes, setDeliveryModes }) => {
  const DeliveryModeButton: React.FC<{ deliveryMode: DeliveryModes }> = ({ deliveryMode }) => (
    <div
      className={`${styles['button']} ${styles[deliveryModes.includes(deliveryMode) ? 'active' : 'inactive']}`}
      onClick={() => {
        if (deliveryModes.includes(deliveryMode)) setDeliveryModes(deliveryModes.filter((v) => v !== deliveryMode));
        else setDeliveryModes([...deliveryModes, deliveryMode]);
      }}
    >
      <DeliveryModeIcon deliveryMode={deliveryMode} />
    </div>
  );

  return (
    <div className={styles['root']}>
      <DeliveryModeButton deliveryMode={DeliveryModes.Ship} />
      <DeliveryModeButton deliveryMode={DeliveryModes.Truck} />
      <DeliveryModeButton deliveryMode={DeliveryModes.Container} />
      <DeliveryModeButton deliveryMode={DeliveryModes.Pipe} />
    </div>
  );
};

export default DeliveryModeSelector;
