import * as TYPE from './types';
import { ApplicationState } from '..';

export const globalReducer = (
  state: ApplicationState['global'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.GlobalActions,
): ApplicationState['global'] => {
  switch (action.type) {
    case TYPE.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };

    default:
      return { ...state };
  }
};
