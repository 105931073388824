import { Dispatch } from 'redux';
import {
  IntegrationAssetWorkspaceActions,
  INTEGRATION_ASSET_WORKSPACE_CLEAR_TOAST,
  INTEGRATION_ASSET_WORKSPACE_LOADING,
  GET_INTEGRATION_ASSET_WORKSPACE,
  INTEGRATION_ASSET_WORKSPACE_FETCH_ERROR,
  ADD_INTEGRATION_ASSET_WORKSPACE,
  DELETE_INTEGRATION_ASSET_WORKSPACE,
  EDIT_INTEGRATION_ASSET_WORKSPACE,
  INTEGRATION_ASSET_WORKSPACE_EDIT_ERROR,
  INTEGRATION_ASSET_WORKSPACE_ADD_ERROR,
  INTEGRATION_ASSET_WORKSPACE_DELETE_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { IntegrationAssetWorkspaceResponseModel, IntegrationAssetWorkspace } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating integration asset workspace...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Integration asset workspace updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating integration asset workspace...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Integration Asset workspace created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;


export const getIntegrationAssetWorkspaces = () => (dispatch: Dispatch<IntegrationAssetWorkspaceActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_WORKSPACE_LOADING,
  });

  new api<IntegrationAssetWorkspaceResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_WORKSPACE_FETCH_ERROR,
  })
    .get(`/api/Integration/AssetWorkspace`)
    .then((response) => {
      dispatch({
        type: GET_INTEGRATION_ASSET_WORKSPACE,
        payload: response,
      });
    });
};

export const addIntegrationAssetWorkspace = (integrationAssetWorkspace: IntegrationAssetWorkspace, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationAssetWorkspaceActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_WORKSPACE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<IntegrationAssetWorkspaceResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_WORKSPACE_ADD_ERROR,
  })
    .post(`/api/Integration/AssetWorkspace`, integrationAssetWorkspace)
    .then((response) => {
      dispatch({
        type: ADD_INTEGRATION_ASSET_WORKSPACE,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteIntegrationAssetWorkspace = (id: string) => (dispatch: Dispatch<IntegrationAssetWorkspaceActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_WORKSPACE_LOADING,
  });

  const url = `/api/Integration/AssetWorkspace/${id}`;
  new api<IntegrationAssetWorkspaceResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_WORKSPACE_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_INTEGRATION_ASSET_WORKSPACE,
        payload: response,
      });
    }).catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const editIntegrationAssetWorkspace = (integrationAssetWorkspace: IntegrationAssetWorkspace, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationAssetWorkspaceActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_WORKSPACE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (integrationAssetWorkspace.workspace != undefined) {
    const url = `/api/Integration/AssetWorkspace/${integrationAssetWorkspace.id}`;

    new api<IntegrationAssetWorkspaceResponseModel>({
      dispatch,
      errorType: INTEGRATION_ASSET_WORKSPACE_EDIT_ERROR,
    })
      .put(url, integrationAssetWorkspace)
      .then((response) => {
        dispatch({
          type: EDIT_INTEGRATION_ASSET_WORKSPACE,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const integrationAssetWorkspacesClearToast = () => (dispatch: Dispatch<IntegrationAssetWorkspaceActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_WORKSPACE_CLEAR_TOAST,
  });
};
