import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import {IntegrationEventType} from '../../../../../store/IntegrationEventTypes/models';

import { Role } from '../../../../../store/Roles/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';
import { Formik } from 'formik';
import IntegrationEventTypeForm from '../IntegrationAssetForm/IntegrationEventTypeForm'; 
import integrationEventTypeFormValidation from '../FormValidations/integrationEventTypeFormValidation';
import { initialValues } from '../FormInitialValues/integrationEventTypeFormInitialValues';


interface OwnProps {
  addNewIntegrationEventType: (integrationEventType: IntegrationEventType) => void;
  navAdminType: string;
  integrationEventType?: IntegrationEventType;
}

const AddIntegrationEventType: React.FunctionComponent<OwnProps> = ({ addNewIntegrationEventType, navAdminType, integrationEventType }) => {
  const submitIntegrationEventType = (integrationEventType: IntegrationEventType) => {
    addNewIntegrationEventType(integrationEventType);
  };

  return (
    <Formik
      initialValues={ integrationEventType? integrationEventType : initialValues}
      validationSchema={integrationEventTypeFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationEventTypeForm
          integrationEventType={integrationEventType}
          header={'Add integration event type'}
          saveMethod={submitIntegrationEventType}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationEventType);
