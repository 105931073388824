import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { api } from '../../helpers/api';
import {
  RecurringNomination,
  RecurringNominationResponseModel,
  PaginatedRecurringNominationResponseModel,
} from './models';
import {
  RecurringNominationActions,
  RECURRINGNOMINATION_LOADING,
  RECURRINGNOMINATION_FETCH_ERROR,
  GET_RECURRINGNOMINATION,
  RECURRINGNOMINATION_DELETE_ERROR,
  RECURRINGNOMINATION_CLEAR_TOAST,
  DELETE_RECURRINGNOMINATION,
  UPDATE_RECURRINGNOMINATION,
  UPDATE_RECURRINGNOMINATION_ERROR,
  ADD_RECURRINGNOMINATION_ERROR,
  ADD_RECURRINGNOMINATION,
} from './types';

const updateLoaderModal: ReactElement = (
  <ModalResponse status="loading">Updating recurring nomination...</ModalResponse>
);
const updateSuccessModal: ReactElement = (
  <ModalResponse status="success">Recurring nomination updated successfully</ModalResponse>
);

const createLoaderModal: ReactElement = (
  <ModalResponse status="loading">Creating recurring nomination...</ModalResponse>
);
const createSuccessModal: ReactElement = (
  <ModalResponse status="success">Recurring nomination created successfully</ModalResponse>
);

const deleteLoaderModal: ReactElement = (
  <ModalResponse status="loading">Deleting recurring nomination...</ModalResponse>
);
const deleteSuccessModal: ReactElement = (
  <ModalResponse status="success">Recurring nomination deleted successfully</ModalResponse>
);

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getRecurringNominations = () => (dispatch: Dispatch<RecurringNominationActions>) => {
  dispatch({
    type: RECURRINGNOMINATION_LOADING,
  });
  const url = `api/RecurringNomination/`; // + '2020-01-01T00:00:00' + `/` + '2040-01-01T00:00:00' + `/bunker/`;
  new api<RecurringNominationResponseModel>({
    dispatch,
    errorType: RECURRINGNOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: RECURRINGNOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_RECURRINGNOMINATION,
          payload: { data: response },
        });
      }
    });
};

export const getRecurringNominationsPaginated = (sortByNeedsAction: boolean, pageIndex: number, pageSize = 10) => (
  dispatch: Dispatch<RecurringNominationActions>,
) => {
  dispatch({
    type: RECURRINGNOMINATION_LOADING,
  });
  const url = `api/RecurringNomination/RecurringNominationsPaginated/recurring/?sortByNeedsAction=${sortByNeedsAction}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  new api<PaginatedRecurringNominationResponseModel>({
    dispatch,
    errorType: RECURRINGNOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: RECURRINGNOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_RECURRINGNOMINATION,
          payload: { data: response.page, metaData: { ...response } },
        });
      }
    });
};

export const updateRecurringNomination = (nomination: RecurringNomination, onSuccess: () => void) => (
  dispatch: Dispatch<RecurringNominationActions>,
) => {
  dispatch({
    type: RECURRINGNOMINATION_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/RecurringNomination/${nomination.id}`;

  new api<RecurringNominationResponseModel>({
    dispatch,
    errorType: UPDATE_RECURRINGNOMINATION_ERROR,
  })
    .put(url, nomination)
    .then((response) => {
      dispatch({
        type: UPDATE_RECURRINGNOMINATION,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const addRecurringNomination = (nomination: RecurringNomination, onSuccess: () => void) => (
  dispatch: Dispatch<RecurringNominationActions>,
) => {
  dispatch({
    type: RECURRINGNOMINATION_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<RecurringNominationResponseModel>({
    dispatch,
    errorType: ADD_RECURRINGNOMINATION_ERROR,
  })
    .post(`/api/RecurringNomination`, nomination)
    .then((response) => {
      dispatch({
        type: ADD_RECURRINGNOMINATION,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteRecurringNomination = (id: string) => (dispatch: Dispatch<RecurringNominationActions>) => {
  dispatch({
    type: RECURRINGNOMINATION_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: deleteLoaderModal });
  const url = `/api/RecurringNomination/${id}`;

  new api<RecurringNominationResponseModel>({
    dispatch,
    errorType: RECURRINGNOMINATION_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_RECURRINGNOMINATION,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: deleteSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const recurringNominationClearToast = () => (dispatch: Dispatch<RecurringNominationActions>) => {
  dispatch({
    type: RECURRINGNOMINATION_CLEAR_TOAST,
  });
};
