import { CompanyOperationalLocationsWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const COMPANY_OPERATIONAL_LOCATIONS_LOADING = 'companyOperationaLocations/loading';
export type COMPANY_OPERATIONAL_LOCATIONS_LOADING = typeof COMPANY_OPERATIONAL_LOCATIONS_LOADING;

export const GET_COMPANY_OPERATIONAL_LOCATIONS = 'companyOperationaLocations/get';
export type GET_COMPANY_OPERATIONAL_LOCATIONS = typeof GET_COMPANY_OPERATIONAL_LOCATIONS;

export const COMPANY_OPERATIONAL_LOCATIONS_FETCH_ERROR = 'companyOperationaLocations/fetchError';
export type COMPANY_OPERATIONAL_LOCATIONS_FETCH_ERROR = typeof COMPANY_OPERATIONAL_LOCATIONS_FETCH_ERROR;

export const DELETE_COMPANY_OPERATIONAL_LOCATIONS = 'companyOperationaLocations/delete';
export type DELETE_COMPANY_OPERATIONAL_LOCATIONS = typeof DELETE_COMPANY_OPERATIONAL_LOCATIONS;

export const DELETE_COMPANY_OPERATIONAL_LOCATIONS_ERROR = 'companyOperationaLocations/deleteError';
export type DELETE_COMPANY_OPERATIONAL_LOCATIONS_ERROR = typeof DELETE_COMPANY_OPERATIONAL_LOCATIONS_ERROR;

export const UPDATE_COMPANY_OPERATIONAL_LOCATIONS = 'companyOperationaLocations/update';
export type UPDATE_COMPANY_OPERATIONAL_LOCATIONS = typeof UPDATE_COMPANY_OPERATIONAL_LOCATIONS;

export const UPDATE_COMPANY_OPERATIONAL_LOCATIONS_ERROR = 'companyOperationaLocations/updateError';
export type UPDATE_COMPANY_OPERATIONAL_LOCATIONS_ERROR = typeof UPDATE_COMPANY_OPERATIONAL_LOCATIONS_ERROR;

export const ADD_COMPANY_OPERATIONAL_LOCATIONS = 'companyOperationaLocations/add';
export type ADD_COMPANY_OPERATIONAL_LOCATIONS = typeof ADD_COMPANY_OPERATIONAL_LOCATIONS;

export const ADD_COMPANY_OPERATIONAL_LOCATIONS_ERROR = 'companyOperationaLocations/addError';
export type ADD_COMPANY_OPERATIONAL_LOCATIONS_ERROR = typeof ADD_COMPANY_OPERATIONAL_LOCATIONS_ERROR;

export const COMPANY_OPERATIONAL_LOCATIONS_CLEAR_TOAST = 'companyOperationaLocations/clearToast';
export type COMPANY_OPERATIONAL_LOCATIONS_CLEAR_TOAST = typeof COMPANY_OPERATIONAL_LOCATIONS_CLEAR_TOAST;

export type CompanyOperationaLocationsActions =
  | {
      type: GET_COMPANY_OPERATIONAL_LOCATIONS;
      payload: CompanyOperationalLocationsWrapperModel['data'];
    }
  | {
      type: COMPANY_OPERATIONAL_LOCATIONS_LOADING;
    }
  | {
      type: COMPANY_OPERATIONAL_LOCATIONS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_COMPANY_OPERATIONAL_LOCATIONS;
      payload: CompanyOperationalLocationsWrapperModel['data'];
    }
  | {
      type: DELETE_COMPANY_OPERATIONAL_LOCATIONS_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: UPDATE_COMPANY_OPERATIONAL_LOCATIONS;
      payload: CompanyOperationalLocationsWrapperModel['data'];
    }
  | {
      type: UPDATE_COMPANY_OPERATIONAL_LOCATIONS_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_COMPANY_OPERATIONAL_LOCATIONS;
      payload: CompanyOperationalLocationsWrapperModel['data'];
    }
  | {
      type: ADD_COMPANY_OPERATIONAL_LOCATIONS_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: COMPANY_OPERATIONAL_LOCATIONS_CLEAR_TOAST;
    };
