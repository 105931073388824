import React, { useEffect } from 'react';
import NominationFilterButton from '../NominationFilterButton/NominationFilterButton';
import { FuelbossStatus } from '../../store/Nomination/models';

interface OwnProps {
  setFilteredStatuses: (statuses: FuelbossStatus[]) => void;
  filteredStates: FuelbossStatus[];
  displayNormalStatuses: boolean;
  displayArchivedStatuses: boolean;
  displayRecurringStatuses?: boolean;
  className?: string;
}

const StatusFilter: React.FC<OwnProps> = ({
  setFilteredStatuses,
  filteredStates,
  displayNormalStatuses,
  displayArchivedStatuses,
  displayRecurringStatuses = false,
  className,
}) => {
  const statusButtonPressed = (status: FuelbossStatus) => {
    const statusIndex = filteredStates.indexOf(status);
    const localFilteredStates = [...filteredStates];

    if (statusIndex < 0) {
      localFilteredStates.push(status);
    } else {
      localFilteredStates.splice(statusIndex, 1);
    }
    setFilteredStatuses(localFilteredStates);
  };

  useEffect(() => {
    if (filteredStates.length === 0) {
      setFilteredStatuses(['completed', 'cancelled', 'action-required', 'pending', 'confirmed', 'recurring']);
    }
  }, []);

  return (
    <div className={className}>
      {displayArchivedStatuses && (
        <>
          <NominationFilterButton
            status={'action-required'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
          <NominationFilterButton status={'pending'} filteredStatuses={filteredStates} onClick={statusButtonPressed} />
          <NominationFilterButton
            status={'confirmed'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
          <NominationFilterButton
            status={'completed'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
          <NominationFilterButton
            status={'cancelled'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
        </>
      )}
      {displayNormalStatuses && (
        <>
          <NominationFilterButton
            status={'action-required'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
          <NominationFilterButton status={'pending'} filteredStatuses={filteredStates} onClick={statusButtonPressed} />
          <NominationFilterButton
            status={'confirmed'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
        </>
      )}
      {displayRecurringStatuses && (
        <>
          <NominationFilterButton
            status={'recurring'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
        </>
      )}
    </div>
  );
};

export default StatusFilter;
