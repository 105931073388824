import { PaginationMeta } from '../../helpers/PaginationMeta';
import { ErrorActionPayload } from '../../models/ErrorAction';
import { Nomination } from './models';

export const NOMINATION_LOADING = 'nomination/loading';
export type NOMINATION_LOADING = typeof NOMINATION_LOADING;

export const GET_NOMINATION = 'nomination/get';
export type GET_NOMINATION = typeof GET_NOMINATION;

export const ARCHIVE_NOMINATION = 'nomination/archive';
export type ARCHIVE_NOMINATION = typeof ARCHIVE_NOMINATION;

export const NOMINATION_FETCH_ERROR = 'nomination/fetchError';
export type NOMINATION_FETCH_ERROR = typeof NOMINATION_FETCH_ERROR;

export const NOMINATION_ARCHIVE_ERROR = 'nomination/archiveError';
export type NOMINATION_ARCHIVE_ERROR = typeof NOMINATION_ARCHIVE_ERROR;

export const NOMINATION_CLEAR_TOAST = 'nomination/clearToast';
export type NOMINATION_CLEAR_TOAST = typeof NOMINATION_CLEAR_TOAST;

export const GET_NOMINATION_INFINITE_SCROLL_APPEND = 'nominationScrollAppend/get';
export type GET_NOMINATION_INFINITE_SCROLL_APPEND = typeof GET_NOMINATION_INFINITE_SCROLL_APPEND;

export const GET_NOMINATION_INFINITE_SCROLL_REFRESH = 'nominationScrollRefresh/get';
export type GET_NOMINATION_INFINITE_SCROLL_REFRESH = typeof GET_NOMINATION_INFINITE_SCROLL_REFRESH;

export const CLEAR_NOMINATION = 'nomination/clear';
export type CLEAR_NOMINATION = typeof CLEAR_NOMINATION;

export type NominationActions =
  | {
      type: GET_NOMINATION;
      payload: { data: Nomination[]; metaData?: PaginationMeta };
    }
  | {
      type: ARCHIVE_NOMINATION;
      payload: Nomination;
    }
  | {
      type: NOMINATION_LOADING;
    }
  | {
      type: NOMINATION_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: NOMINATION_CLEAR_TOAST;
    }
  | {
      type: NOMINATION_ARCHIVE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_NOMINATION_INFINITE_SCROLL_APPEND;
      payload: { data: Nomination[] };
    }
  | {
      type: GET_NOMINATION_INFINITE_SCROLL_REFRESH;
      payload: { data: Nomination[] };
    }
  | {
      type: CLEAR_NOMINATION;
    };
