import React, { useState, useCallback, useEffect } from 'react';
import { SpotEnquiry } from '../../../../store/Spot/models';
import styles from './SpotList.module.scss';
import { Button } from 'reactstrap';
import SpotList from './SpotList';
import { ReactComponent as GasPumpIcon } from './../../../../svgs/add-bunker-event.svg';
import MinifiedSpotList from './Components/MinifiedSpotList/MinifiedSpotList';
import SpotStatusFilter from '../../../SpotStatusFilter/SpotStatusFilter';
import NoNominations from '../NoNominations/NoNominations';
import { useSelector, useDispatch } from 'react-redux';
import { getAuth } from '../../../../store/Auth/actions';
import { Loading } from '../../../Loading';
import { Error } from '../../../Error';
import { Category } from '../../../../models/Category';
import { ApplicationState } from '../../../../store';
import { checkReceivingVesselCrew, checkShipOwnerAdministrative, checkSupplier } from '../../../../helpers/roleChecks';
import { FuelbossStatus } from '../../../../store/Nomination/models';

interface OwnProps {
  spots: SpotEnquiry[];
  className?: string;
  setNewSpot: (newSpot: boolean) => void;
  chooseSpot: (spotId: string[], spotObject: SpotEnquiry) => void;
  displayingArchivedSpots?: boolean;
  activeSpotId: string;
  minified: boolean;
  activeTab: string;
}

const SpotListContainer: React.FC<OwnProps> = ({
  spots,
  className,
  setNewSpot,
  chooseSpot,
  displayingArchivedSpots,
  activeSpotId,
  minified,
  activeTab,
}) => {
  const [filteredStates, setFilteredStates] = useState<FuelbossStatus[]>([]);

  const filteredSpots = spots.filter((spot) =>
    filteredStates.includes(spot.attributes?.fbStatus ? spot.attributes.fbStatus : 'pending'),
  );

  const { authLoading, authError, authErrorDetails, auth, authIsLoaded } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const isUserShipOwner = () => {
    return auth.company && auth.company.category.categoryName == Category.Shipowner;
  };

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const createSpotButton = () => {
    if (authLoading) {
      return <Loading type="auth" />;
    } else if (authError && authErrorDetails) {
      return <Error error={authErrorDetails} />;
    } else {
      if (auth.company && !checkSupplier(auth) && !checkShipOwnerAdministrative(auth) && !checkReceivingVesselCrew(auth) && activeTab == '1') {
        return (
          <Button className={styles['new-nomination-button']} color="primary" onClick={() => setNewSpot(true)}>
            <GasPumpIcon />
          </Button>
        );
      }
    }
  };

  return (
    <div className={className}>
      {!minified && (
        <div className={styles['nomination-header']}>
          <SpotStatusFilter
            setFilteredStatuses={setFilteredStates}
            filteredStates={filteredStates}
            displayNormalStatuses={!displayingArchivedSpots}
            displayArchivedStatuses={displayingArchivedSpots === true}
            className={styles['nomination-filters']}
            isShipOwner={isUserShipOwner()}
          />
          {createSpotButton()}
        </div>
      )}
      {spots.length < 1 && <NoNominations archived={displayingArchivedSpots === true} />}
      {spots.length < 1 && !minified && displayingArchivedSpots && (
        <NoNominations archived={displayingArchivedSpots === true} />
      )}
      {spots.length > 0 && !minified && (
        <SpotList
          spots={filteredSpots} //filteredSpots
          chooseSpot={chooseSpot}
          activeSpotId={activeSpotId}
          displayingArchivedSpots={displayingArchivedSpots ? displayingArchivedSpots : false}
        />
      )}
      {spots.length > 0 && minified && (
        <MinifiedSpotList spots={filteredSpots} activeSpotId={activeSpotId} /> //spots={filteredSpots}
      )}
    </div>
  );
};

export default SpotListContainer;
