import { CompanyLogoWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const COMPANY_LOGO_LOADING = 'companylogo/loading';
export type COMPANY_LOGO_LOADING = typeof COMPANY_LOGO_LOADING;

export const GET_COMPANY_LOGO = 'companylogo/get';
export type GET_COMPANY_LOGO = typeof GET_COMPANY_LOGO;

export const COMPANY_LOGO_FETCH_ERROR = 'companylogo/fetchError';
export type COMPANY_LOGO_FETCH_ERROR = typeof COMPANY_LOGO_FETCH_ERROR;

export const DELETE_COMPANY_LOGO = 'companylogo/delete';
export type DELETE_COMPANY_LOGO = typeof DELETE_COMPANY_LOGO;

export const DELETE_COMPANY_LOGO_ERROR = 'companylogo/deleteError';
export type DELETE_COMPANY_LOGO_ERROR = typeof DELETE_COMPANY_LOGO_ERROR;

export const ADD_COMPANY_LOGO = 'companylogo/add';
export type ADD_COMPANY_LOGO = typeof ADD_COMPANY_LOGO;

export const ADD_COMPANY_LOGO_ERROR = 'companylogo/updateError';
export type ADD_COMPANY_LOGO_ERROR = typeof ADD_COMPANY_LOGO_ERROR;

export const COMPANY_LOGO_CLEAR_TOAST = 'companylogo/clearToast';
export type COMPANY_LOGO_CLEAR_TOAST = typeof COMPANY_LOGO_CLEAR_TOAST;

export type CompanyActions =
  | {
      type: GET_COMPANY_LOGO;
      payload: CompanyLogoWrapperModel['data'];
    }
  | {
      type: COMPANY_LOGO_LOADING;
    }
  | {
      type: COMPANY_LOGO_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_COMPANY_LOGO;
      payload: CompanyLogoWrapperModel['data'];
    }
  | {
      type: DELETE_COMPANY_LOGO_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_COMPANY_LOGO;
      payload: CompanyLogoWrapperModel['data'];
    }
  | {
      type: ADD_COMPANY_LOGO_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: COMPANY_LOGO_CLEAR_TOAST;
    };
