import React from 'react';
import { ErrorActionPayload } from '../../../../models/ErrorAction';
import { ReactComponent as ErrorIcon } from '../svgs/lock-alt.svg';
import styles from '../../Error.module.scss';

interface OwnProps {
  error: ErrorActionPayload;
}

export const ForbiddenError: React.FC<OwnProps> = React.memo(({ error }) => (
  <div className={styles.errorComponent}>
    <div className={styles['icon-wrapper']}>
      <ErrorIcon className={styles.image} />
      <div className={styles['status-code-forbidden']}>{error.statusCode}</div>
    </div>
    <h5 className={styles.header}>We are sorry, you seem to not have access to this content.</h5>
    <p className={styles['error-description']}>
      If you believe this is wrong, please <a href="mailto:fuelboss@dnv.com">contact us</a> and let us know.
    </p>
  </div>
));
