import { RoleWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const ROLE_LOADING = 'role/loading';
export type ROLE_LOADING = typeof ROLE_LOADING;

export const GET_ROLES = 'role/get';
export type GET_ROLES = typeof GET_ROLES;

export const ROLES_FETCH_ERROR = 'role/fetchError';
export type ROLES_FETCH_ERROR = typeof ROLES_FETCH_ERROR;

export const ADD_ROLE = 'role/add';
export type ADD_ROLE = typeof ADD_ROLE;

export const ROLES_FETCH_SUCCESS = 'role/fetchSuccess';
export type ROLES_FETCH_SUCCESS = typeof ROLES_FETCH_SUCCESS;

export const DELETE_ROLE = 'role/delete';
export type DELETE_ROLE = typeof DELETE_ROLE;

export const EDIT_ROLE = 'role/edit';
export type EDIT_ROLE = typeof EDIT_ROLE;

export type RoleActions =
  | {
      type: GET_ROLES;
      payload: RoleWrapperModel['data'];
    }
  | {
      type: ROLE_LOADING;
    }
  | {
      type: ROLES_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_ROLE;
      payload: RoleWrapperModel['data'];
    }
  | {
      type: ROLES_FETCH_SUCCESS;
    }
  | {
      type: DELETE_ROLE;
      payload: RoleWrapperModel['data'];
    }
  | {
      type: EDIT_ROLE;
      payload: RoleWrapperModel['data'];
    };
