import { Dispatch } from 'redux';
import { CategoryActions, CATEGORY_LOADING, CATEGORIES_FETCH_ERROR, GET_CATEGORIES } from './types';
import { api } from '../../helpers/api';
import { CategoryResponseModel } from './models';

export const getCategories = () => (dispatch: Dispatch<CategoryActions>) => {
  dispatch({
    type: CATEGORY_LOADING,
  });

  new api<CategoryResponseModel>({
    dispatch,
    errorType: CATEGORIES_FETCH_ERROR,
  })
    .get(`/api/Categories`)
    .then((response) => {
      dispatch({
        type: GET_CATEGORIES,
        payload: response,
      });
    });
};
