import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Configurations.module.scss';
import {
  useBdnConfigurationService,
  useSofConfigurationService,
  SofConfiguration,
  BdnConfiguration,
} from '@teqplay/chorus-components';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { ApplicationState } from '../../../../store';
import { Auth } from '../../../../store/Auth/models';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import BDNConfigurationTable from './Components/BDNConfigurationTable';
import SOFConfigurationTable from './Components/SOFConfigurationTable';
import { Route } from 'react-router';

interface OwnProps {
  auth: Auth;
  navAdminType: string;
  serviceLayer: AuthenticationServiceLayer;
}

const ConfigurationsContainer: React.FunctionComponent<OwnProps> = ({ auth, navAdminType, serviceLayer }) => {
  const vendorCompanyId = auth.chorusUserProfile.companyId ? auth.chorusUserProfile.companyId : '';

  const history = useHistory();

  const routes = history.location.pathname.split('/');

  const [, , baseRoute] = routes;

  const [activeTab, setActiveTab] = React.useState(baseRoute);
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  React.useEffect(() => {
    toggle('BDN');
    history.push(`/companyadmin/configuration/bdn`);
  }, []);

  const {
    sofConfigurationsList,
    getSofConfigurationsList,
    createOrUpdateSofConfig,
    selectedSofConfiguration,
    setSelectedSofConfiguration,
    removeSofConfiguration,
  } = useSofConfigurationService(serviceLayer, vendorCompanyId);
  const {
    bdnConfigurationsList,
    createOrUpdateBdnConfiguration,
    getBdnConfigurations,
    selectedBdnConfiguration,
    setSelectedBdnConfiguration,
    removeBdnConfiguration,
  } = useBdnConfigurationService(serviceLayer, vendorCompanyId);

  const refectchBDNCongfigurations = () => {
    getBdnConfigurations();
  };

  const refectchSOFCongfigurations = () => {
    getSofConfigurationsList();
  };
  const sofConfigurationsListSorted = sofConfigurationsList.sort((a, b) => a.name.localeCompare(b.name));
  const bdnConfigurationsListSorted = bdnConfigurationsList.sort((a, b) => a.name.localeCompare(b.name));
  
  return (
    <div className={styles['confiurations-wrapper']} style={{ height: '95vh' }}>
      <Nav tabs className={`bg-white ${styles['navbar']}`}>
        <NavItem className={classnames({ active: activeTab == 'BDN' })}>
          <NavLink
            className={classnames({ active: activeTab == 'BDN' })}
            onClick={() => {
              toggle('BDN');
              history.push(`/companyadmin/configuration/bdn`);
            }}
          >
            BDN templates
          </NavLink>
        </NavItem>
        <NavItem className={classnames({ active: activeTab == 'SOF' })}>
          <NavLink
            className={classnames({ active: activeTab == 'SOF' })}
            onClick={() => {
              toggle('SOF');
              history.push(`/companyadmin/configuration/sof`);
            }}
          >
            Statement of facts templates
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} style={{ marginLeft: '0.7em' }}>
        <TabPane tabId="BDN">
          <Route
            path="/companyadmin/configuration/bdn"
            render={(props) => (
              <div className="bdn-configurations-wrapper">
                <BDNConfigurationTable
                  bdnConfigurations={bdnConfigurationsListSorted}
                  deleteConfiguration={removeBdnConfiguration}
                  createOrUpdateBdnConfiguration={createOrUpdateBdnConfiguration}
                  refectchBDNCongfigurations={refectchBDNCongfigurations}
                  navAdminType={navAdminType}
                  auth={auth}
                  serviceLayer={serviceLayer}
                />
              </div>
            )}
          />
        </TabPane>
        <TabPane tabId="SOF">
          <Route
            path="/companyadmin/configuration/sof"
            render={(props) => (
              <div className="sof-configurations-wrapper">
                <SOFConfigurationTable
                  sofConfigurations={sofConfigurationsListSorted}
                  deleteConfiguration={removeSofConfiguration}
                  createOrUpdateSofConfiguration={createOrUpdateSofConfig}
                  refectchSOFCongfigurations={refectchSOFCongfigurations}
                  navAdminType={navAdminType}
                  auth={auth}
                  serviceLayer={serviceLayer}
                />
              </div>
            )}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};
export default ConfigurationsContainer;
