import React from 'react';
import { Report } from '../../../../../store/Reports/models';
import { ReportType } from '../../../../../store/ReportTypes/models';

import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import * as Yup from 'yup';
import reportConfigs from '../ReportForm/reportConfigs';
import { Formik } from 'formik';
import ReportTypeForm from '../ReportForm/ReportTypeForm';


interface OwnProps {
  addNewReportType: (reportType: ReportType) => void;
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
  reportType?: ReportType;
}

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

const AddReportType: React.FunctionComponent<OwnProps> = ({ addNewReportType, companyAdmin, navAdminType, company, reportType }) => {
  const submitReportType = (report: ReportType) => {
    addNewReportType(report);
  };

  const initialValues: ReportType = {
    name: '',
    subset: 1,
    showAddVessel: false,
  };

  const validationShape = {
    name: Yup.string().matches(reportConfigs.name.regex, validationMessage).required('Type required'),
    showAddVessel: Yup.boolean().optional(),
    subset: Yup.number().min(1, 'Not valid').optional(),
  };
  const reportFormValidation = Yup.object().shape(validationShape);
  return (
    <Formik
      initialValues={reportType ? reportType : initialValues}
      validationSchema={reportFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <ReportTypeForm
          reportType={reportType}
          header={'Add report type'}
          saveMethod={submitReportType}
          systemAdminPage={true}
          companyAdminForm={companyAdmin}
          company={company}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddReportType);
