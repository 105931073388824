import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAuth } from '../../../../../store/Auth/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import {
  renderTextInput,
  renderComplexDropdown,
  renderSimpleDropdown,
  renderTextAreaInput,
} from '../../../../Form/FormInputs';
import integrationAssetConfigs from './integrationAssetConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import {
  TopicDataType,
  IntegrationAssetTopic,
} from '../../../../../store/IntegrationAssetTopic/models';
import { getIntegrationEventTypes } from '../../../../../store/IntegrationEventTypes/actions';
import { Console } from 'console';

const StyledInnerForm = styled.div`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: block;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
  margin-top: 1em;
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
  }
  .buttonContainer button {
    justify-self: flex-end;
    margin-left: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    flex: 0 1 40%;
    margin-right: 35px;
    width: 35%;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
  .form-check {
    margin-right: 5px;
  }
`;

interface OwnProps {
  integrationAssetTopic?: IntegrationAssetTopic;
  header: string;
  saveMethod: (integrationAssetTopic: IntegrationAssetTopic) => void;
  systemAdminPage?: boolean;
  formikProps: FormikProps<IntegrationAssetTopic>;
  navAdminType: string;
  integrationAssetId: string;
}

const IntegrationAssetTopicForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  integrationAssetTopic,
  systemAdminPage = false,
  navAdminType,
  formikProps,
  integrationAssetId,
}) => {
  const {
    authLoading,
    authError,
    authErrorDetails,
    authIsLoaded,
    integrationEventTypes,
    integrationEventTypesDetailsError,
    integrationEventTypesErrorDetails,
    integrationEventTypesIsLoaded,
    integrationEventTypesLoading,
    toastDataIntegrationEventTypes,
  } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    integrationEventTypes: state.integration_events_types.data,
    integrationEventTypesLoading: state.integration_events_types.loading,
    integrationEventTypesDetailsError: state.integration_events_types.error,
    integrationEventTypesErrorDetails: state.integration_events_types.errorDetails,
    integrationEventTypesIsLoaded: state.integration_events_types.dataIsLoaded,
    toastDataIntegrationEventTypes: state.integration_events_types.toastData,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);
  const [dataTypes, setDataTypes] = useState([]);

  const getAllIntegrationEventTypes = useCallback(() => dispatch(getIntegrationEventTypes()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }

    //set datatypes state
    const map: { id: number; name: string }[] = [];
    for (var n in TopicDataType) {
      if (typeof TopicDataType[n] === 'number') {
        //@ts-ignore
        map.push({ id: TopicDataType[n], name: n });
      }
    }
    //@ts-ignore
    setDataTypes(map);
  }, []);

  useEffect(() => {
    if (!integrationEventTypesIsLoaded) {
      getAllIntegrationEventTypes();
    }
  }, [integrationEventTypesIsLoaded]);

  const onFormSave = () => {
    formikProps.submitForm();

    const integrationAssetTopicToSave: IntegrationAssetTopic = {
      ...formikProps.values,
      //  active: true,
    };
    integrationAssetTopicToSave.dataType = integrationAssetTopicToSave.dataType as TopicDataType;
    if (formikProps.values.dataEventType?.id)
      integrationAssetTopicToSave.eventTypeId = formikProps.values.dataEventType?.id;

    if (integrationAssetTopic) {
      integrationAssetTopicToSave.topic = integrationAssetTopic.topic;
    }
    if (formikProps.isValid) {
      saveMethod(integrationAssetTopicToSave);
    }
  };
  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetConfigs, errors, touched, setFieldValue, setFieldTouched };

  const url = `integrationassets/${integrationAssetId}/edit`;

  if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save integration asset topic"
          cancelButtonText="Cancel"
          formUrl={url}
        />
        <StyledInnerForm as={Form}>
          {renderTextInput('topic', false, props)}
          {renderTextAreaInput('dataStructure', false, props)}
          {renderComplexDropdown(
            'dataEventType',
            integrationEventTypes,
            integrationEventTypesLoading,
            'eventType',
            props,
          )}
          {renderSimpleDropdown('dataType', dataTypes, false, 'name', props)}
        </StyledInnerForm>
      </StyledForm>
    );
  }
};

export default React.memo(IntegrationAssetTopicForm);
