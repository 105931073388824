import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { ArchivedNominationActions, ARCHIVED_NOMINATION_FETCH_ERROR, ARCHIVED_NOMINATION_LOADING, GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH, GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_APPEND, CLEAR_ARCHIVED_NOMINATION} from './types';
import { ArchivedNominationResponseModel, PaginatedArchivedNominationResponseModel } from './models';
import { GET_ARCHIVED_NOMINATION } from './types';

/*export const getArchivedNominations = () => (dispatch: Dispatch<ArchivedNominationActions>) => {
  dispatch({
    type: ARCHIVED_NOMINATION_LOADING,
  });
  const url = `api/chorus/GetNominations/` + '2020-01-01T00:00:00' + `/` + '2040-01-01T00:00:00' + `/archived/`;
  new api<ArchivedNominationResponseModel>({
    dispatch,
    errorType: ARCHIVED_NOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: ARCHIVED_NOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_ARCHIVED_NOMINATION,
          payload: {data: response},
        });
      }
    });
};*/



export const getArchivedNominationsPaginated = (pageIndex: number, pageSize = 10) => (
  dispatch: Dispatch<ArchivedNominationActions>,
) => {
  dispatch({
    type: ARCHIVED_NOMINATION_LOADING,
  });
  const url = `api/chorus/GetNominationsPaginated/archived/?pageIndex=${pageIndex}&pageSize=${pageSize}`;

  new api<PaginatedArchivedNominationResponseModel>({
    dispatch,
    errorType: ARCHIVED_NOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: ARCHIVED_NOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_ARCHIVED_NOMINATION,
          payload: {data: response.page, metadata: {...response}},
        });
      }
    });
};

export const getArchivedNominationsInfiniteScroll = (page: number) => (dispatch: Dispatch<ArchivedNominationActions>) => {
  const pageSize = 20;
  return new Promise<ArchivedNominationResponseModel>((resolve, reject) => {
    dispatch({
      type: ARCHIVED_NOMINATION_LOADING,
    });
    const url = `api/chorus/GetNominationsInfiniteScroll/archived/?sortByNeedsAction=${false}&pageIndex=${
      page * pageSize
    }&pageSize=${pageSize}`;
    new api<ArchivedNominationResponseModel>({
      dispatch,
      errorType: ARCHIVED_NOMINATION_FETCH_ERROR,
    })
      .get(url)
      .then((response) => {
        //@ts-ignore
        if (response.status) {
          dispatch({
            type: ARCHIVED_NOMINATION_FETCH_ERROR,
            //@ts-ignore
            payload: {
              clientFault: true,
              serverFault: false,
              //@ts-ignore
              statusCode: response.status,
              //@ts-ignore
              message: response.message,
            },
          });
          reject(new Error('Failed to fetch archieved nominations'));
        } else {
          dispatch({
            type: page > 0 ? GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_APPEND : GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH,
            payload: { data: response as ArchivedNominationResponseModel },
          });
          resolve((response as unknown) as ArchivedNominationResponseModel);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getArchivedNominationsInfiniteScrollRefresh = (page: number) => (dispatch: Dispatch<ArchivedNominationActions>) => {
  const pageSize = 20;
  const dataSize = (page + 1) * pageSize;
  return new Promise<ArchivedNominationResponseModel>((resolve, reject) => {
    dispatch({
      type: ARCHIVED_NOMINATION_LOADING,
    });
    const url = `api/chorus/GetNominationsInfiniteScroll/archived/?sortByNeedsAction=${false}&pageIndex=${0}&pageSize=${dataSize}`;
    new api<ArchivedNominationResponseModel>({
      dispatch,
      errorType: ARCHIVED_NOMINATION_FETCH_ERROR,
    })
      .get(url)
      .then((response) => {
        //@ts-ignore
        if (response.status) {
          dispatch({
            type: ARCHIVED_NOMINATION_FETCH_ERROR,
            //@ts-ignore
            payload: {
              clientFault: true,
              serverFault: false,
              //@ts-ignore
              statusCode: response.status,
              //@ts-ignore
              message: response.message,
            },
          });
          reject(new Error('Failed to fetch archieved nominations'));
        } else {
          dispatch({
            type: GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH,
            payload: { data: response as ArchivedNominationResponseModel},
          });
          resolve((response as unknown) as ArchivedNominationResponseModel);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getArchivedNominationsBySearchBar = (sortByNeedsAction: boolean,search: string, isExclude: boolean) => (
  dispatch: Dispatch<ArchivedNominationActions>,
) => {
  dispatch({
    type: ARCHIVED_NOMINATION_LOADING,
  });
  const url = `api/chorus/GetNominationsBySearchBar/archived/?sortByNeedsAction=${sortByNeedsAction}&search=${search}&isExclude=${isExclude}`;

  new api<ArchivedNominationResponseModel>({
    dispatch,
    errorType: ARCHIVED_NOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: ARCHIVED_NOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_ARCHIVED_NOMINATION,
          payload: {data: response},
        });
      }
    });
};

export const clearArchivedNominations = () => (dispatch: Dispatch<ArchivedNominationActions>) => {
  dispatch({
    type: CLEAR_ARCHIVED_NOMINATION,
  });
};