import * as TYPE from './types';

import { ApplicationState } from '..';

export const connectivityReducer = (
  state: ApplicationState['connectivity_check'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: false,
    metadata: {},

    //Fuelboss checks
    loading_fuelboss_check: true,
    data_fuelboss_check: {},
    dataIsLoaded_fuelboss_check: false,
    error_fuelboss_check: '',

    //Chorus checks
    loading_chorus_check: true,
    data_chorus_check: {},
    dataIsLoaded_chorus_check: false,
    error_chorus_check: '',

    //SignalR checks
    loading_signalr_check: true,
    data_signalr_check: {},
    dataIsLoaded_signalr_check: false,
    error_signalr_check: '',

    //Mapbox checks
    loading_mapbox_check: true,
    data_mapbox_check: {},
    dataIsLoaded_mapbox_check: false,
    error_mapbox_check: '',

    //Tqplay icons checks
    loading_tqplayicon_check: true,
    data_tqplayicon_check: {},
    dataIsLoaded_tqplayicon_check: false,
    error_tqplayicon_check: '',

    //veracity checks
    loading_veracity_check: true,
    data_veracity_check: {},
    dataIsLoaded_veracity_check: false,
    error_veracity_check: '',
  },
  action: TYPE.ConnectivityCheckActions,
): ApplicationState['connectivity_check'] => {
  switch (action.type) {
    case TYPE.FUELBOSS_CONNECTIVITY_CHECK_LOADING:
      return {
        ...state,
        loading_fuelboss_check: true,
        error: false,
      };

    case TYPE.GET_FUELBOSS_CONNECTIVITY_CHECKS:
      return {
        ...state,
        data_fuelboss_check: action.payload,
        loading_fuelboss_check: false,
        error: false,
        dataIsLoaded_fuelboss_check: true,
      };
    case TYPE.FUELBOSS_CONNECTIVITY_CHECK_ERROR:
      return {
        ...state,
        loading_fuelboss_check: false,
        error: true,
        data_fuelboss_check: false,
        dataIsLoaded_fuelboss_check: true,
        error_fuelboss_check: String(action.payload.statusCode) + ' ' + (action.payload.message ? action.payload.message : ''),
      };

    case TYPE.CHORUS_CONNECTIVITY_CHECK_LOADING:
      return {
        ...state,
        loading_chorus_check: true,
        error: false,
      };

    case TYPE.GET_CHORUS_CONNECTIVITY_CHECKS:
      return {
        ...state,
        data_chorus_check: action.payload,
        loading_chorus_check: false,
        error: false,
        dataIsLoaded_chorus_check: true,
      };
    case TYPE.CHORUS_CONNECTIVITY_CHECK_ERROR:
      return {
        ...state,
        loading_chorus_check: false,
        error: true,
        data_chorus_check: false,
        dataIsLoaded_chorus_check: true,
        error_chorus_check: String(action.payload.statusCode) + ' ' + (action.payload.message ? action.payload.message : ''),
      };

    case TYPE.MAPBOX_CONNECTIVITY_CHECK_LOADING:
      return {
        ...state,
        loading_mapbox_check: true,
        error: false,
      };

    case TYPE.GET_MAPBOX_CONNECTIVITY_CHECKS:
      return {
        ...state,
        data_mapbox_check: action.payload,
        loading_mapbox_check: false,
        error: false,
        dataIsLoaded_mapbox_check: true,
      };
    case TYPE.MAPBOX_CONNECTIVITY_CHECK_ERROR:
      return {
        ...state,
        loading_mapbox_check: false,
        error: true,
        data_mapbox_check: false,
        dataIsLoaded_mapbox_check: true,
        error_mapbox_check: String(action.payload.statusCode) + ' ' + (action.payload.message ? action.payload.message : ''),
      };

    case TYPE.SIGNALR_CONNECTIVITY_CHECK_LOADING:
      return {
        ...state,
        loading_signalr_check: true,
        error: false,
      };

    case TYPE.GET_SIGNALR_CONNECTIVITY_CHECKS:
      return {
        ...state,
        data_signalr_check: action.payload,
        loading_signalr_check: false,
        error: false,
        dataIsLoaded_signalr_check: true,
      };
    case TYPE.SIGNALR_CONNECTIVITY_CHECK_ERROR:
      return {
        ...state,
        loading_signalr_check: false,
        error: true,
        data_signalr_check: false,
        error_signalr_check: String(action.payload.statusCode) + ' ' + (action.payload.message ? action.payload.message : ''),
      };
    case TYPE.VERACITY_CONNECTIVITY_CHECK_LOADING:
      return {
        ...state,
        loading_veracity_check: true,
        error: false,
      };

    case TYPE.GET_VERACITY_CONNECTIVITY_CHECKS:
      return {
        ...state,
        data_veracity_check: action.payload,
        loading_veracity_check: false,
        error: false,
        dataIsLoaded_veracity_check: true,
      };
    case TYPE.VERACITY_CONNECTIVITY_CHECK_ERROR:
      return {
        ...state,
        loading_veracity_check: false,
        error: true,
        data_veracity_check: false,
        dataIsLoaded_veracity_check: true,
        error_veracity_check: String(action.payload.statusCode) + ' ' + (action.payload.message ? action.payload.message : ''),
      };

    case TYPE.TQPLAYICONS_CONNECTIVITY_CHECK_LOADING:
      return {
        ...state,
        loading_tqplayicon_check: true,
        error: false,
      };

    case TYPE.GET_TQPLAYICONS_CONNECTIVITY_CHECKS:
      return {
        ...state,
        data_tqplayicon_check: action.payload,
        loading_tqplayicon_check: false,
        error: false,
        dataIsLoaded_tqplayicon_check: true,
      };
    case TYPE.TQPLAYICONS_CONNECTIVITY_CHECK_ERROR:
      return {
        ...state,
        loading_tqplayicon_check: false,
        error: true,
        data_tqplayicon_check: false,
        dataIsLoaded_tqplayicon_check: true,
        error_tqplayicon_check: String(action.payload.statusCode) + ' ' + (action.payload.message ? action.payload.message : ''),
      };

    case TYPE.CLEAR_CONNECTIVITY_CHECKS:
      return {
        ...state,
        loading_fuelboss_check: true,
        data_fuelboss_check: {},
        dataIsLoaded_fuelboss_check: false,
        error_fuelboss_check: '',


        //Chorus checks
        loading_chorus_check: true,
        data_chorus_check: {},
        dataIsLoaded_chorus_check: false,
        error_chorus_check: '',

        //SignalR checks
        loading_signalr_check: true,
        data_signalr_check: {},
        dataIsLoaded_signalr_check: false,
        error_signalr_check: '',

        //Mapbox checks
        loading_mapbox_check: true,
        data_mapbox_check: {},
        dataIsLoaded_mapbox_check: false,
        error_mapbox_check: '',

        //Tqplay icons checks
        loading_tqplayicon_check: true,
        data_tqplayicon_check: {},
        dataIsLoaded_tqplayicon_check: false,
        error_tqplayicon_check: '',

        //veracity checks
        loading_veracity_check: true,
        data_veracity_check: {},
        dataIsLoaded_veracity_check: false,
        error_veracity_check: '',
      };

    case TYPE.ADD_CONNECTIVITYCHECK_RESULT:
      return { ...state };

    case TYPE.ADD_CONNECTIVITYCHECK_RESULT_ERROR:
      return { ...state };

    default:
      return { ...state };
  }
};
