import styled from 'styled-components';

export default styled.div`
  margin-bottom: 3em;
  .input-field {
    margin-left: 2.2em;
  }
  fieldset {
    display: flex;
    align-items: center;
    margin-bottom: 0.3em;
    justify-self: flex-start;
    label[for='checkbox-asset_type_subsea'] {
      pointer-events: none;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex-direction: column;
      margin-right: 94%;
    }
  }
  .assetTypeDescription {
    font-style: italic;
  }
  .assetTypeRectangle {
    height: 26px;
    width: 209px;
    border: 1px solid #9ad8f0;
    border-radius: 13px;
    margin-top: 1em;
    text-align: center;
  }
`;
