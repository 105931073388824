import { FuelBossLocation } from '../../../../store/FuelbossLocations/models';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const momentTimezone = require('moment-timezone');

export const initialValues: FuelBossLocation = {
  name: '',
  portAbbreviation: '',
  areaOfOperation: '',
  portType: '',
  port: '',
  countryAbbreviation: '',
  longitude: 0.0,
  latitude: 0.0,
  country: '',
  purpose: 'GENERAL',
  type: '',
  timezone: momentTimezone.tz.guess(),
};
