import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const momentTimezone = require('moment-timezone');

const TimezoneList: React.FC = () => {
  const timezones = momentTimezone.tz.names();

  //need to implement moment-timezones to get a predefined list and map it in an option list.
  return (
    <>
      {timezones &&
        timezones.map((zone: string) => (
          <option key={zone} value={zone}>
            {zone}
          </option>
        ))}
    </>
  );
};

export default TimezoneList;
