import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import { ApplicationState } from '../../../store';
import { IntegrationAsset } from '../../../store/IntegrationAsset/models';
import { AddToast } from '../../../helpers/toastService';
import {
  addIntegrationAssetApiTracker,
  deleteIntegrationAssetApiTracker,
  editIntegrationAssetApiTracker,
  getIntegrationAssetApiTrackers,
  integrationAssetApiTrackersClearToast,
} from '../../../store/IntegrationAssetApiTracker/actions';
import IntegrationAssetApiTrackers from './Components/IntegrationAssetApiTracker/IntegrationAssetApiTrackers';
import { IntegrationAssetApiTracker } from '../../../store/IntegrationAssetApiTracker/models';
import AddIntegrationAssetApiTracker from './Components/AddIntegrationAssetApiTracker/AddIntegrationAssetApiTracker';
import { IntegrationAssetTopic } from '../../../store/IntegrationAssetTopic/models';
import { getIntegrationAssetTopic } from '../../../store/IntegrationAssetTopic/actions';
import { Loading } from '../../Loading';
import { getIntegrationOutboundApiTracker } from '../../../store/IntegrationOutboundApiTracker/actions';
import IntegrationOutboundApiTrackers from './Components/OutboundIntegrationCompanyView/OutboundIntegrationSettings';

interface OwnProps {
  systemAdmin?: boolean;
  navAdminType: string;
}

const CompanyIntegrationAssetContainer: React.FC<OwnProps> = ({ systemAdmin = false, navAdminType }) => {
  const {
    integrationAssetApiTrackersLoading,
    integrationAssetApiTrackersDetailsError,
    integrationAssetApiTrackersErrorDetails,
    integrationAssetApiTrackers,
    integrationAssetApiTrackersIsLoaded,
    toastData,
    integrationAssetTopic,
    integrationAssetTopicDetailsError,
    integrationAssetTopicErrorDetails,
    integrationAssetTopicIsLoaded,
    integrationAssetTopicLoading,
    auth,
    integrationoutboundApiTrackerLoading,
    integrationoutboundApiTrackerError,
    integrationoutboundApiTrackerDetails,
    integrationoutboundApiTracker,
    integrationoutboundApiTrackerIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    integrationAssetApiTrackersLoading: state.integration_assets_apitracker.loading,
    integrationAssetApiTrackersDetailsError: state.integration_assets_apitracker.error,
    integrationAssetApiTrackersErrorDetails: state.integration_assets_apitracker.errorDetails,
    integrationAssetApiTrackers: state.integration_assets_apitracker.data,
    integrationAssetApiTrackersIsLoaded: state.integration_assets_apitracker.dataIsLoaded,
    toastData: state.integration_assets_apitracker.toastData,

    integrationAssetTopicLoading: state.integration_assets_topic.loading,
    integrationAssetTopicDetailsError: state.integration_assets_topic.error,
    integrationAssetTopicErrorDetails: state.integration_assets_topic.errorDetails,
    integrationAssetTopic: state.integration_assets_topic.data,
    integrationAssetTopicIsLoaded: state.integration_assets_topic.dataIsLoaded,
    auth: state.auth.data,

    integrationoutboundApiTrackerLoading: state.integration_outbound_api_tracker.loading,
    integrationoutboundApiTrackerError: state.integration_outbound_api_tracker.error,
    integrationoutboundApiTrackerDetails: state.integration_outbound_api_tracker.errorDetails,
    integrationoutboundApiTracker: state.integration_outbound_api_tracker.data,
    integrationoutboundApiTrackerIsLoaded: state.integration_outbound_api_tracker.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const getIntegrationAssetApiTrackersCallback = useCallback(() => {
    dispatch(getIntegrationAssetApiTrackers());
  }, []);

  const getAllIntegrationAssetTopic = useCallback(() => {
    dispatch(getIntegrationAssetTopic());
  }, []);

  const addIntegrationAssetApiTrackersCallback = useCallback(
    (
      integrationAssetTopic: IntegrationAssetTopic,
      integrationAsset: IntegrationAsset,
      fbAssetType: string,
      fbAssetId: string,
    ) =>
      dispatch(
        addIntegrationAssetApiTracker(integrationAssetTopic, integrationAsset, fbAssetType, fbAssetId, () =>
          history.push(`/${navAdminType}admin/companyintegrations/inboundconnection`),
        ),
      ),
    [],
  );

  const deleteIntegrationAssetApiTrackersCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetApiTracker(id)),
    [],
  );

  const editIntegrationAssetApiTrackerCallback = useCallback(
    (integrationAssetApiTracker: IntegrationAssetApiTracker) =>
      dispatch(
        editIntegrationAssetApiTracker(integrationAssetApiTracker, () =>
          history.push(`/${navAdminType}admin/companyintegrations/inboundconnection`),
        ),
      ),
    [],
  );

  const clearToastCallback = useCallback(() => dispatch(integrationAssetApiTrackersClearToast()), []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  useEffect(() => {
    if (!integrationAssetApiTrackersIsLoaded) {
      getIntegrationAssetApiTrackersCallback();
    }
    if (!integrationAssetTopicIsLoaded) {
      getAllIntegrationAssetTopic();
    }
  }, []);


  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }
	if (!integrationAssetApiTrackersIsLoaded || !integrationAssetTopicIsLoaded ) {
    return <Loading />;
  } else if (integrationAssetApiTrackers && integrationAssetTopic ) {   
    return (
      <Switch>
        <Route
          exact
          path={`/${navAdminType}admin/companyintegrations/inboundconnection/`}
          render={(props) => (
            <IntegrationAssetApiTrackers
            integrationAssetApiTrackers={integrationAssetApiTrackers}
            integrationAssetTopics={integrationAssetTopic}
            isLoading={integrationAssetApiTrackersLoading}
            hasError={integrationAssetApiTrackersDetailsError}
            errorDetails={integrationAssetApiTrackersErrorDetails}
            deleteIntegrationAssetApiTracker={deleteIntegrationAssetApiTrackersCallback}
            editIntegrationAssetApiTracker={editIntegrationAssetApiTrackerCallback}
            navAdminType={navAdminType}
            defaultSelectedPage={selectedPage}
            setDefaultSelectedPage={handleDefaultChange}
            {...{ props }}
            />

          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/companyintegrations/inboundconnection/add`}
          render={(props) => (
            <AddIntegrationAssetApiTracker
              {...{ props }}
              addNewIntegrationAsset={addIntegrationAssetApiTrackersCallback}
              systemAdmin={systemAdmin}
              navAdminType={navAdminType}
              companyId={auth.company?.chorusId}
            />
          )}
        />
        <Route 
          exact
          path={`/${navAdminType}admin/companyintegrations/outboundconnection/`}
          render={(props) => (
            <IntegrationOutboundApiTrackers
              {...{ props }}
            />
          )}
        />
        {/* <Route
        exact
        path={`/${navAdminType}admin/companyintegrations/inboundconnection/:assetIntegrationId/edit`}
        render={(props) => (
          <EditIntegrationAssetApiTracker
            {...props}
            isLoading={integrationAssetApiTrackersLoading}
            editIntegrationAsset={editIntegrationAssetApiTrackerCallback}
            integrationAssetApiTrackers={integrationAssetApiTrackers}
            integrationAssetId={props.match.params.assetIntegrationId}
            navAdminType={navAdminType}
            systemAdmin={systemAdmin}
            companyId={auth.company?.chorusId}
          />
        )}
      /> */}
      </Switch>
    );
  } else {
    return null;
  }
};

export default CompanyIntegrationAssetContainer;
