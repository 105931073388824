import { ErrorActionPayload } from '../../models/ErrorAction';
import { ChatWrapperModel, ChatNotificationWrapperModel } from './models';

export const CHAT_LOADING = 'chat/loading';
export type CHAT_LOADING = typeof CHAT_LOADING;

export const GET_CHAT = 'chat/get';
export type GET_CHAT = typeof GET_CHAT;

export const NOTIFY_CHAT = 'chat/notify';
export type NOTIFY_CHAT = typeof NOTIFY_CHAT;

export const NOTIFY_CHAT_ERROR = 'chat/notifyError';
export type NOTIFY_CHAT_ERROR = typeof NOTIFY_CHAT_ERROR;

export const CHAT_FETCH_ERROR = 'chat/fetchError';
export type CHAT_FETCH_ERROR = typeof CHAT_FETCH_ERROR;

export type ChatActions =
  | {
      type: GET_CHAT;
      payload: ChatWrapperModel['data'];
    }
  | {
      type: NOTIFY_CHAT;
      payload: ChatNotificationWrapperModel['data'];
    }
  | {
      type: NOTIFY_CHAT_ERROR;
      payload: ErrorActionPayload;
  }
  | {
      type: CHAT_LOADING;
    }
  | {
      type: CHAT_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
