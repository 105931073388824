import React from 'react';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { getAuth } from '../../store/Auth/actions';
import { getSanityConfiguration } from '../../store/Sanity/actions';
import sanityClient from '../../helpers/sanityClient';
import styles from './CookieList.module.scss';
import { SanityContentPage } from '../../components/SanityContentPage/SanityContentPage';

export const CookieList: React.FC = () => {
  const [sanityContentPage, setSanityContentPage] = React.useState<any>(undefined);

  const dispatch = useDispatch();

  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const getSanityConfigurationCallback = React.useCallback(() => dispatch(getSanityConfiguration()), []);

  const { auth, authIsLoaded, sanityConfig, sanityConfigIsLoaded } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    sanityConfig: state.sanity_config.data,
    sanityConfigIsLoaded: state.sanity_config.dataIsLoaded,
    sanityConfigLoading: state.sanity_config.loading,
    sanityConfigError: state.sanity_config.error,
    sanityConfigErrorDetails: state.sanity_config.errorDetails,
  }));

  React.useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
    if (!sanityConfigIsLoaded) {
      getSanityConfigurationCallback();
    }
  }, []);

  React.useEffect(() => {
    if (sanityConfigIsLoaded) {
      const client = sanityClient.getSanityClient(sanityConfig.projectId, sanityConfig.dataset, sanityConfig.token);
      if (client) {
        //fetch cokkie page
        const query = `*[directory=='/cookieconsent']`;
        client.fetch(query).then((page) => {
          setSanityContentPage(page[0]);
        });
      }
    }
  }, [sanityConfigIsLoaded]);

  return (
    <SanityContentPage
      sanityContentPage={sanityContentPage}
      sanityProjectId={sanityConfig.projectId}
      santiyDataSet={sanityConfig.dataset}
    ></SanityContentPage>
  );
};
