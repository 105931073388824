import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import {TopicDataType, IntegrationAssetTopic} from '../../../../../store/IntegrationAssetTopic/models';

import { Role } from '../../../../../store/Roles/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';
import { Formik } from 'formik';
import IntegrationAssetTopicForm from '../IntegrationAssetForm/IntegrationAssetTopicForm'; 
import integrationAssetTopicFormValidation from '../FormValidations/integrationAssetTopicFormValidation';


interface OwnProps {
  veracityAssetId: string;
  integrationAssetId: string;
  addNewIntegrationAssetTopic: (integrationAssetTopic: IntegrationAssetTopic) => void;
  navAdminType: string;
  integrationAssetTopic?: IntegrationAssetTopic;
}

const AddIntegrationAssetTopic: React.FunctionComponent<OwnProps> = ({ addNewIntegrationAssetTopic, navAdminType, integrationAssetTopic, veracityAssetId, integrationAssetId }) => {
  const submitIntegrationAssetTopic = (integrationAssetTopic: IntegrationAssetTopic) => {
    addNewIntegrationAssetTopic(integrationAssetTopic);
  };

  const initialValues: IntegrationAssetTopic = {
    dataStructure: '',
    veracityAssetId: veracityAssetId,
    topic: '',
    eventTypeId: '',
    integrationAssetId: integrationAssetId,
    dataType: TopicDataType.CompositionInbound
  };

  return (
    <Formik
      initialValues={ integrationAssetTopic? integrationAssetTopic : initialValues}
      validationSchema={integrationAssetTopicFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetTopicForm
          integrationAssetTopic={integrationAssetTopic}
          integrationAssetId={integrationAssetId}
          header={'Add integration asset topic'}
          saveMethod={submitIntegrationAssetTopic}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationAssetTopic);
