import { Dispatch } from 'redux';
import {
  VesselsActions,
  VESSELS_LOADING,
  VESSELS_FETCH_ERROR,
  GET_VESSELS,
  ADD_VESSEL_ERROR,
  ADD_VESSEL,
  DELETE_VESSEL_ERROR,
  DELETE_VESSEL,
  EDIT_VESSEL_ERROR,
  EDIT_VESSEL,
} from './types';
import { api } from '../../helpers/api';
import { VesselsResponseModel } from './models';

export const getVessels = () => (dispatch: Dispatch<VesselsActions>) => {
  dispatch({
    type: VESSELS_LOADING,
  });

  new api<VesselsResponseModel>({
    dispatch,
    errorType: VESSELS_FETCH_ERROR,
  })
    .get(`/api/vessel/VesselAsset`)
    .then((response) => {
      dispatch({
        type: GET_VESSELS,
        payload: response,
      });
    });
};

export const addVessel = (imo: number | undefined, name: string, type: string) => (
  dispatch: Dispatch<VesselsActions>,
) => {
  dispatch({
    type: VESSELS_LOADING,
  });

  const vessel = {
    imo: imo,
    vesselName: name,
    vesselType: type,
  };

  new api<VesselsResponseModel>({
    dispatch,
    errorType: ADD_VESSEL_ERROR,
  })
    .post('api/vessel/VesselAsset', vessel)
    .then((response) => {
      dispatch({
        type: ADD_VESSEL,
        payload: response,
      });
    });
};

export const deleteVessel = (id: string) => (dispatch: Dispatch<VesselsActions>) => {
  dispatch({
    type: VESSELS_LOADING,
  });

  const url = `api/vessel/VesselAsset/${id}`;
  new api<VesselsResponseModel>({
    dispatch,
    errorType: DELETE_VESSEL_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_VESSEL,
        payload: response,
      });
    });
};

export const editVessel = (id: string, imo: number | undefined, name: string, type: string) => (
  dispatch: Dispatch<VesselsActions>,
) => {
  dispatch({
    type: VESSELS_LOADING,
  });

  const vessel = {
    imo: imo,
    vesselName: name,
    vesselType: type,
  };

  const url = `api/vessel/VesselAsset/${id}`;
  new api<VesselsResponseModel>({
    dispatch,
    errorType: EDIT_VESSEL_ERROR,
  })
    .put(url, vessel)
    .then((response) => {
      dispatch({
        type: EDIT_VESSEL,
        payload: response,
      });
    });
};


