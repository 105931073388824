import { UserWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const USER_LOADING = 'user/loading';
export type USER_LOADING = typeof USER_LOADING;

export const GET_USERS = 'user/get';
export type GET_USERS = typeof GET_USERS;

export const USERS_FETCH_ERROR = 'user/fetchError';
export type USERS_FETCH_ERROR = typeof USERS_FETCH_ERROR;

export const ADD_USER = 'user/add';
export type ADD_USER = typeof ADD_USER;

export const USERS_ADD_ERROR = 'user/addError';
export type USERS_ADD_ERROR = typeof USERS_ADD_ERROR;

export const USERS_DELETE_ERROR = 'user/deleteError';
export type USERS_DELETE_ERROR = typeof USERS_DELETE_ERROR;

export const USERS_EDIT_ERROR = 'user/editError';
export type USERS_EDIT_ERROR = typeof USERS_EDIT_ERROR;

export const DELETE_USER = 'user/delete';
export type DELETE_USER = typeof DELETE_USER;

export const EDIT_USER = 'user/edit';
export type EDIT_USER = typeof EDIT_USER;

export const USERS_CLEAR_TOAST = 'Users/ClearToast';
export type USERS_CLEAR_TOAST = typeof USERS_CLEAR_TOAST;

export type UserActions =
  | {
      type: GET_USERS;
      payload: UserWrapperModel['data'];
    }
  | {
      type: USER_LOADING;
    }
  | {
      type: USERS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: USERS_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: USERS_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: USERS_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_USER;
      payload: UserWrapperModel['data'];
    }
  | {
      type: DELETE_USER;
      payload: UserWrapperModel['data'];
    }
  | {
      type: EDIT_USER;
      payload: UserWrapperModel['data'];
    }
  | {
      type: USERS_CLEAR_TOAST;
    };
