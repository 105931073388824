import React from 'react';
import { Formik } from 'formik';
import LocationForm from '../LocationsForm/LocationForm';
import locationsFormValidation from '../locationsFormValidation';
import { initialValues } from '../locationsFormInitialValues';
import { FuelBossLocation } from '../../../../../store/FuelbossLocations/models';

interface OwnProps {
  addLocation: (location: FuelBossLocation) => void;
  header: string;
  location?: FuelBossLocation;
}

const FormikWrapper: React.FC<OwnProps> = ({ addLocation, header, location }) => {
  const submitLocation = (location: FuelBossLocation) => {
    addLocation(location);
  };

  return (
    <Formik
      initialValues={location ? location : initialValues}
      validationSchema={locationsFormValidation}
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <LocationForm header={header} saveMethod={submitLocation} location={location} formikProps={formikProps} />
      )}
    </Formik>
  );
};

export default React.memo(FormikWrapper);
