import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './CompanyIntegrations.module.scss';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { ApplicationState } from '../../../../store';
import { Auth } from '../../../../store/Auth/models';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Route, useHistory } from 'react-router-dom';
import { Label, Button } from 'reactstrap';
import { getIntegrationAssetTopic } from '../../../../store/IntegrationAssetTopic/actions';
import { getIntegrationAssets } from '../../../../store/IntegrationAsset/actions';
import CompanyIntegrationAssetContainer from '../../../../components/AdminComponents/IntegrationAssets/CompanyIntegrationAssets';
import { getIntegrationOutboundApiTracker } from '../../../../store/IntegrationOutboundApiTracker/actions';

interface OwnProps {
  auth: Auth;
  navAdminType: string;
}

const CompanyIntegrations: React.FunctionComponent<OwnProps> = ({ auth, navAdminType }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const routes = history.location.pathname.split('/');

  const [, , baseRoute] = routes;

  const {
    integrationAssetLoading,
    integrationAssetError,
    integrationAssetErrorDetails,
    integrationAsset,
    integrationAssetIsLoaded,
    topicLoading,
    topicError,
    topicErrorDetails,
    topic,
    topicIsLoaded,
    outboundApiTrackerLoading,
    outboundApiTrackerError,
    outboundApiTrackerDetails,
    outboundApiTracker,
    outboundApiTrackerIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    integrationAssetLoading: state.integration_assets.loading,
    integrationAssetError: state.integration_assets.error,
    integrationAssetErrorDetails: state.integration_assets.errorDetails,
    integrationAsset: state.integration_assets.data,
    integrationAssetIsLoaded: state.integration_assets.dataIsLoaded,

    topicLoading: state.integration_assets_topic.loading,
    topicError: state.integration_assets_topic.error,
    topicErrorDetails: state.integration_assets_topic.errorDetails,
    topic: state.integration_assets_topic.data,
    topicIsLoaded: state.integration_assets_topic.dataIsLoaded,

    outboundApiTrackerLoading: state.integration_outbound_api_tracker.loading,
    outboundApiTrackerError: state.integration_outbound_api_tracker.error,
    outboundApiTrackerDetails: state.integration_outbound_api_tracker.errorDetails,
    outboundApiTracker: state.integration_outbound_api_tracker.data,
    outboundApiTrackerIsLoaded: state.integration_outbound_api_tracker.dataIsLoaded,
  }));

  const [activeTab, setActiveTab] = React.useState(baseRoute);
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getIntegrationAssetsCallback = React.useCallback(() => {
    dispatch(getIntegrationAssets());
  }, []);

  const getOutboundIntegrationCallback = React.useCallback(() => {
    dispatch(getIntegrationOutboundApiTracker(true));
  }, []);


  React.useEffect(() => {
    if (!integrationAssetIsLoaded) {
      getIntegrationAssetsCallback();      
    }
    toggle('apitracker');
    history.push(`/${navAdminType}admin/companyintegrations/inboundconnection`)

  }, []);

  
  React.useEffect(() => {

    if (!outboundApiTrackerLoading) {
      getOutboundIntegrationCallback();
    }

    toggle('outboundapitracker');
    history.push(`/${navAdminType}admin/companyintegrations/outboundconnection`);
  }, []);

  return (
    <div className={styles['confiurations-wrapper']} style={{ height: '95vh' }}>
      <Nav tabs className={`bg-white ${styles['navbar']}`}>
        <NavItem className={classnames({ active: activeTab == 'apitracker' })}>
          <NavLink
            className={classnames({ active: activeTab == 'apitracker' })}
            onClick={() => {
              toggle('apitracker');
              history.push(`/${navAdminType}admin/companyintegrations/inboundconnection`);
            }}
          >
             Data to FuelBoss
          </NavLink>
        </NavItem>
        <NavItem className={classnames({ active: activeTab == 'outboundapitracker' })}>
          <NavLink
            className={classnames({ active: activeTab == 'outboundapitracker' })}
            onClick={() => {
              toggle('outboundapitracker');
              history.push(`/${navAdminType}admin/companyintegrations/outboundconnection`)
            }}
          >
            Data from FuelBoss
          </NavLink>
        </NavItem>
      </Nav>
          <div className="apitracker-wrapper">
            <Label style={{ display: 'block', marginTop: '20px' }}>
            </Label>
            <Route
              path="/companyadmin/companyintegrations"
              render={(props) => <CompanyIntegrationAssetContainer {...props} navAdminType="company"/>}
            />
          </div>
        {/* <TabPane tabId="ManageAssets">
          <div className="low-carbon-wrapper">
            <Label style={{ display: 'block', marginTop: '20px' }}>
              Activate low carbon options to enable your customers to order these in the nomination form.
            </Label>
          </div>
        </TabPane> */}
    </div>
  );
};
export default CompanyIntegrations;
