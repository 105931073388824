import * as React from 'react';
import * as reactstrap from 'reactstrap';
import styles from '../../Table.module.scss';

interface OwnProps {
  headers: string[];
  // rows: JSX.Element[];
  numberOfRowsPerPagination: number;
  children: React.ReactElement[];
  striped?: boolean;
  hover?: boolean;
  responsive?: boolean;
  headerStyle?: React.CSSProperties;
}

const Table: React.FC<OwnProps> = ({
  headers,
  numberOfRowsPerPagination,
  children,
  striped = true,
  hover = true,
  responsive = true,
  headerStyle,
}) => (
  <div className={styles['table']}>
    <reactstrap.Table striped={striped} hover={hover} responsive={responsive}>
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th style={headerStyle ? headerStyle : undefined} key={i}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </reactstrap.Table>
  </div>
);

export default Table;
