import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './RecurringEvents.module.scss';
import { ApplicationState } from '../../store';
import { getBunkerShips } from '../../store/BunkerShip/actions';
import { getBunkerPipelines } from '../../store/BunkerPipeline/actions';
import RecurringChorusIntegration from './Components/RecurringChorusIntegration/RecurringChorusIntegration';
import { Loading } from '../../components/Loading';
import { getChorusCompanies } from '../../store/ChorusCompany/actions';
import NewRecurringNomination from './Components/RecurringChorusIntegration/NewRecurringNomination';
import RecurringNominations from '../../components/Nomination/RecurringNominations';
import { useParams, useHistory } from 'react-router';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import {
  ICompany,
  IContract,
  ILocation,
  CachedLocationService,
  IShipWithId,
  IBunkerShip,
  IShipWithCompanyId,
} from '@teqplay/chorus-components';
import { getFleetVessels } from '../../store/CompanyFleet/actions';
import { getRecurringNominations } from '../../store/RecurringNomination/actions';
import { canCreateNominationOnBehalf, checkSupplier } from '../../helpers/roleChecks';
import { getContracts } from '../../store/Contract/actions';
import { getChorusContracts } from '../../store/ChorusContract/actions';
import { contractHasExpired } from '../../helpers/spotEnquiryHelper';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { getCompanies } from '../../store/Company/actions';
import { getFuelbossLocations } from '../../store/FuelbossLocations/actions';
import { Category } from '../../models/Category';
import { getCharteredFleetVessels } from '../../store/CompanyCharteredFleet/actions';

interface OwnProps {
  openNewNomination?: boolean;
  serviceLayer: AuthenticationServiceLayer;
  locationService: CachedLocationService;
}

const RecurringEvents: React.FC<OwnProps> = ({ openNewNomination, serviceLayer, locationService }) => {
  const [nominationId, setNominationId] = React.useState('');
  const [newNomination, setNewNomination] = React.useState(false);

  const [bunkerEventsMinified, setBunkerEventsMinified] = React.useState(false);
  const [nominationDetailsMinified, setNominationDetailssMinified] = React.useState(false);

  const [renderChat, setRenderChat] = React.useState(false);

  const history = useHistory();

  const setNewNominationMethod = (_newNomination: boolean) => {
    setNewNomination(_newNomination);
    setNominationId('');
  };

  const setNominationIdMethod = (id: string, archived: boolean = false) => {
    // @ts-ignore
    setNominationId(id);
    setNewNomination(false);
    if (history) {
      history.push(`/recurringevents/${archived ? 'archived' : 'recurring'}/${id}`);
    }
  };

  const {
    recurringNominationMetaData,
    recurringNominationIsLoaded,
    allRecurringNomination,
    companies,
    companiesIsLoaded,
    chorusCompanyLoading,
    chorusCompany,
    fuelBossLocation,
    fuelBossLocationIsLoaded,
    fuelBossLocationLoading,
    chorusCompanyIsLoaded,
    chorusContracts,
    chorusContractsLoading,
    chorusContractsIsLoaded,
    contractsLoading,
    contractsDetailsError,
    contractsErrorDetails,
    contracts,
    contractsIsLoaded,
    bunkerShipLoading,
    bunkerShip,
    bunkerShipIsLoaded,
    bunkerPipelineLoading,
    bunkerPipelines,
    bunkerPipelineIsLoaded,
    companyFleetLoading,
    companyFleetError,
    companyFleetErrorDetails,
    companyFleet,
    companyFleetIsLoaded,
    companyCharteredFleetLoading,
    companyCharteredFleetError,
    companyCharteredFleetErrorDetails,
    companyCharteredFleet,
    companyCharteredFleetIsLoaded,
    auth,
  } = useSelector((state: ApplicationState) => ({
    recurringNominationLoading: state.recurring_nomination.loading,
    recurringNominationError: state.recurring_nomination.error,
    recurringNominationErrorDetails: state.recurring_nomination.errorDetails,
    allRecurringNomination: state.recurring_nomination.data,
    recurringNominationIsLoaded: state.recurring_nomination.dataIsLoaded,
    recurringNominationMetaData: state.recurring_nomination.metadata,
    fuelBossLocationLoading: state.fuelbosslocations.loading,
    fuelBossLocation: state.fuelbosslocations.data,
    fuelBossLocationIsLoaded: state.fuelbosslocations.dataIsLoaded === true,
    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompanyErrorDetails: state.choruscompany.errorDetails,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded === true,
    chorusContractsLoading: state.choruscontracts.loading,
    chorusContractsError: state.choruscontracts.error,
    chorusContractsErrorDetails: state.choruscontracts.errorDetails,
    chorusContracts: state.choruscontracts.data,
    chorusContractsIsLoaded: state.choruscontracts.dataIsLoaded === true,
    contractsLoading: state.contracts.loading,
    contractsDetailsError: state.contracts.error,
    contractsErrorDetails: state.contracts.errorDetails,
    contracts: state.contracts.data,
    contractsIsLoaded: state.contracts.dataIsLoaded,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    bunkerShipLoading: state.bunkership.loading,
    bunkerShipError: state.bunkership.error,
    bunkerShipErrorDetails: state.bunkership.errorDetails,
    bunkerShip: state.bunkership.data,
    bunkerShipIsLoaded: state.bunkership.dataIsLoaded === true,
    bunkerPipelineLoading: state.bunkerpipeline.loading,
    bunkerPipelineError: state.bunkerpipeline.error,
    bunkerPipelineErrorDetails: state.bunkerpipeline.errorDetails,
    bunkerPipelines: state.bunkerpipeline.data,
    bunkerPipelineIsLoaded: state.bunkerpipeline.dataIsLoaded === true,
    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,
    companyCharteredFleetLoading: state.companyCharteredFleet.loading,
    companyCharteredFleetError: state.companyCharteredFleet.error,
    companyCharteredFleetErrorDetails: state.companyCharteredFleet.errorDetails,
    companyCharteredFleet: state.companyCharteredFleet.data,
    companyCharteredFleetIsLoaded: state.companyCharteredFleet.dataIsLoaded,
    auth: state.auth.data,
  }));

  const { id, type } = useParams();

  const [yearMonth, setYearMonth] = React.useState('');
  const [vendorCompanies, setVendorCompanies] = React.useState<ICompany[]>([]);
  const [filteredContracts, setFilteredContracts] = React.useState<IContract[]>([]);
  const [chorusLocations, setChorusLocations] = React.useState<ILocation[]>([]);
  const [filteredBunkerShips, setFilteredBunkerShips] = React.useState<IShipWithCompanyId[]>([]);

  const getYearAndMonth = () => {
    const currentDate = new Date();
    let currentMonth = '';
    currentDate.getMonth() + 1 < 10
      ? (currentMonth = `0${currentDate.getMonth() + 1}`)
      : (currentMonth = currentMonth + 1);

    return `${currentDate.getFullYear()}-${currentMonth}`;
  };

  const dispatch = useDispatch();
  const getAllContractsCallback = React.useCallback(() => dispatch(getContracts()), []);

  const getBunkerShipCallback = React.useCallback(() => dispatch(getBunkerShips()), []);

  const getBunkerPipelineCallback = React.useCallback(() => dispatch(getBunkerPipelines()), []);

  //const getLocationCallback = React.useCallback(() => dispatch(getFuelbossLocations()), []);

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const getChorusCompaniesCallback = React.useCallback(() => dispatch(getChorusCompanies()), []);

  const getChorusContractsCallback = React.useCallback(() => dispatch(getChorusContracts()), []);

  const getRecurringNominationCallback = React.useCallback(() => dispatch(getRecurringNominations()), []);

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyFleetIsLoaded) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  const getCompanyCharteredFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyCharteredFleetIsLoaded) {
      dispatch(getCharteredFleetVessels(auth.company.companyId));
    }
  }, []);

  React.useEffect(() => {
    if (id && type) {
      setNominationId(id);
      setNewNomination(false);
    }
    if (openNewNomination) {
      setNewNominationMethod(true);
    }
    if (!recurringNominationIsLoaded || recurringNominationMetaData) {
      getRecurringNominationCallback();
    }
    /* if (!archivedNominationIsLoaded || archivedNominationMetaData) {
      getArchivedNominationCallback();
    } */
    if (!contractsIsLoaded) {
      getAllContractsCallback();
    }
    if (!chorusCompanyIsLoaded) {
      getChorusCompaniesCallback();
    }
    if (!bunkerPipelineIsLoaded) {
      getBunkerPipelineCallback();
    }

    if (!chorusContractsIsLoaded) {
      getChorusContractsCallback();
    }

    if (!bunkerShipIsLoaded) {
      getBunkerShipCallback();
    }

    if (!companiesIsLoaded) {
      getCompaniesCallback();
    }

    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }

    if (!companyCharteredFleetIsLoaded) {
      getCompanyCharteredFleetCallback();
    }

    setYearMonth(getYearAndMonth);

    const interval = setInterval(() => {
      getRecurringNominationCallback();
      //getArchivedNominationCallback();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const isUserShipOwner = () =>
    (auth.company && auth.company.category.categoryName == Category.Shipowner) ||
    (auth.company && auth.company.category.categoryName == Category.Charterer);

  React.useEffect(() => {
    const nonRejectedContracts: IContract[] = [];
    chorusContracts.forEach(function (choruscontract, index) {
      const contractMatch = contracts.find((x) => x.chorusId == choruscontract._id);
      if (contractMatch?.status != 'Rejected' && contractMatch !== undefined && !contractHasExpired(contractMatch!)) {
        nonRejectedContracts.push(choruscontract);
      }
    });
    setFilteredContracts(nonRejectedContracts);
  }, [chorusContracts, contracts]);

  React.useEffect(() => {
    const bunkerVessels: IShipWithCompanyId[] = [];
    bunkerShip.forEach(function (ship, index) {
      const shipMatch = companyFleet.find((x) => x.chorusId == ship._id);
      if (shipMatch && shipMatch.vessel.vesselType.toLowerCase() === 'bunkervessel') {
        bunkerVessels.push(ship);
      }
      //search bunker vessels in chartered vessels if not found in own fleet
      if (!shipMatch) {
        const charteredshipMatch = companyCharteredFleet.find((x) => x.fleetVessel?.chorusId == ship._id);
        if (
          charteredshipMatch &&
          charteredshipMatch.fleetVessel &&
          charteredshipMatch.fleetVessel.vessel.vesselType.toLowerCase() === 'bunkervessel'
        ) {
          bunkerVessels.push(ship);
        }
      }
    });
    setFilteredBunkerShips(bunkerVessels);
  }, [bunkerShip, companyFleet, companyCharteredFleet]);

  React.useEffect(() => {
    const activeVendorCompanies: ICompany[] = [];
    chorusCompany.forEach(function (comp, index) {
      const compMatch = companies.find((x) => x.chorusId == comp._id);
      if (compMatch?.active && comp.companyType == 'VENDOR') {
        activeVendorCompanies.push(comp);
      }
    });
    setVendorCompanies(activeVendorCompanies);
  }, [chorusCompany, companies]);

  const loadingString = `${bunkerShipLoading ? 'bunkerships, ' : ''} ${chorusCompanyLoading ? 'companies, ' : ''} ${
    fuelBossLocationLoading ? 'locations, ' : ''
  }`;

  if (bunkerShipLoading || chorusCompanyLoading) {
    return <Loading type={loadingString} />;
  }

  getYearAndMonth();
  return (
    <div className={styles['bunker-events']}>
      <div
        className={`${styles['nomination-wrapper']} ${
          bunkerEventsMinified ? styles['isMinified'] : styles['isNotMinified']
        }`}
      >
        <RecurringNominations
          className={styles['nomination']}
          chooseNomination={setNominationIdMethod}
          setNewNomination={setNewNominationMethod}
          activeNominationId={nominationId}
          archivedAsInitialTab={false}
          minified={bunkerEventsMinified}
          shipOwner={isUserShipOwner()}
          locationService={locationService}
        />
        <CollapseButton
          direction={bunkerEventsMinified ? 'right' : 'left'}
          onClick={() => {
            setBunkerEventsMinified(!bunkerEventsMinified);
          }}
          className={styles['nomination-collapse']}
        />
      </div>

      {newNomination ? (
        <NewRecurringNomination
          yearMonth={yearMonth}
          chooseNomination={setNominationIdMethod}
          className={`${styles['chorus']} chorus-wrapper ${styles['new-nomination']}`}
          bunkerShips={bunkerShip}
          bunkerPipelines={bunkerPipelines}
          companies={chorusCompany}
          locationService={locationService}
          contracts={filteredContracts}
          creatingNewNominationBool={newNomination}
          vendorCompanies={vendorCompanies}
          serviceLayer={serviceLayer}
        />
      ) : (
        nominationId && (
          <div className={`${styles['chorus-wrapper']} ${nominationDetailsMinified ? styles['isMinified'] : ''}`}>
            {!nominationDetailsMinified && (
              <RecurringChorusIntegration
                setRenderChat={setRenderChat}
                className={styles['chorus']}
                nominationId={nominationId}
                yearMonth={yearMonth}
                bunkerShips={checkSupplier(auth) ? filteredBunkerShips : bunkerShip}
                bunkerPipelines={bunkerPipelines}
                companies={chorusCompany}
                locationService={locationService}
                contracts={filteredContracts}
                vendorCompanies={vendorCompanies}
                serviceLayer={serviceLayer}
                selectedRecurringNomination={allRecurringNomination.find((x) => x.id == nominationId)}
              />
            )}
            {nominationDetailsMinified && (
              <div className={styles['minified']}>
                <div className={styles['title']}>Nomination details</div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

//@ts-ignore
export default RecurringEvents;
