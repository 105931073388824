export default {
  firstName: {
    label: 'First name',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
    required: true,
  },
  lastName: {
    label: 'Last name',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
    required: true,
  },
  email: {
    label: 'Email',
    show: true,
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
    placeholder: '',
    required: true,
  },
  company: {
    label: 'Company',
    show: true,
    placeholder: 'Please select a company',
  },
  role: {
    label: 'Role',
    show: true,
    placeholder: 'Please select a role(see descriptions below)',
    required: true,
  },
  fleetVessel: {
    label: 'Vessel',
    show: true,
    placeholder: 'Please select a vessel',
    required: true,
  },
  fleetPipeline: {
    label: 'Terminal',
    show: true,
    placeholder: 'Please select a terminal',
    required: true,
  },
};
