import * as React from 'react';
import styles from './LowCarbonOptionsContainer.module.scss';
import { useState, useEffect } from 'react';
import { Auth } from '../../../../store/Auth/models';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { companyClearLowCarbonFuelError } from '../../../../store/Company/actions';
import { ApplicationState } from '../../../../store';
import LowCarbonOptionsButtons from './Components/ButtonContainer/LowCarbonOptionsButtons';
import { LowCarbonFuels } from '../../../../models/LowCarbonFuel';
import { toggleLowCarbonOption } from '../../../../store/Company/actions';
import { CompanyLowCarbonFuel } from '../../../../models/CompanyLowCarbonFuel';
import { getAuth } from '../../../../store/Auth/actions';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface OwnProps {
  auth: Auth;
}

const LowCarbonOptionsContainer: React.FC<OwnProps> = ({ auth }) => {
  const {
    toggleLowCarbonFuelFailed,
  } = useSelector((state: ApplicationState) => ({
    toggleLowCarbonFuelFailed: state.companies.toggleLowCarbonFuelFailed,
  }));

  const [rejectionModal, setRejectionModal] = React.useState(false);
  const toggleRejectionModal = () => setRejectionModal(!rejectionModal);
  const [selectedLowCarbonFuel, setSelectedLowCarbonFuel] = React.useState('');

  React.useEffect(() => {
    if (toggleLowCarbonFuelFailed == true) {
      toggleRejectionModal();
      clearLowCarbonFuelErrorsCallback();
    }
  }, [toggleLowCarbonFuelFailed]);

  const dispatch = useDispatch();
  const history = useHistory();

  const clearLowCarbonFuelErrorsCallback = React.useCallback(() => dispatch(companyClearLowCarbonFuelError()), []);
  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const toggleLowCarbonFuelCallback = React.useCallback((lowCarbonFuel: LowCarbonFuels, onSuccess: () => void) => {
    if (auth.company && auth.company.companyId) {
      setSelectedLowCarbonFuel(lowCarbonFuel);
      dispatch(toggleLowCarbonOption(auth.company.companyId, lowCarbonFuel, onSuccess));
    }
  }, []);

  //Check lowCarbon fuels
  const calcultateLowCarbonFuelsArray = () => {
    const ret: string[] = [];

    if (auth.company && auth.company.companyLowCarbonFuels) {
      auth.company.companyLowCarbonFuels.forEach((element: CompanyLowCarbonFuel) => {
        ret.push(element.lowCarbonFuel.fuelName);
      });
    }
    return ret;
  };

  const [lowCarbonFuelsArray, setLowCarbonFuelsArray] = useState(calcultateLowCarbonFuelsArray());

  const lowCarbonFuelSuccessCallaback = (fuel: LowCarbonFuels) => {
    //reload auth to get updated 
    getAuthCallback();
  };

  const toggle = (fuel: LowCarbonFuels) => {
    switch (fuel) {
      case LowCarbonFuels.LBG:
        toggleLowCarbonFuelCallback(LowCarbonFuels.LBG, () => lowCarbonFuelSuccessCallaback(LowCarbonFuels.LBG));
        break;
      case LowCarbonFuels.LSM:
        toggleLowCarbonFuelCallback(LowCarbonFuels.LSM, () => lowCarbonFuelSuccessCallaback(LowCarbonFuels.LSM));
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <LowCarbonOptionsButtons
        toggle={toggle}
        initialLowCarbonFuels={lowCarbonFuelsArray}
      />
      <Modal isOpen={rejectionModal} toggle={toggleRejectionModal} className={styles['lowcarbonoption-modal-rejection']}>
        <ModalHeader toggle={toggleRejectionModal} className={styles['lowcarbonoption-modal-header']}>
          There are active nominations where{' '}
          {' ' + selectedLowCarbonFuel.toUpperCase()} has been chosen.
        </ModalHeader>
        <ModalBody className={styles['lowcarbonoption-modal-body']}>
          In order to disable LBG as a low carbon option, please complete or cancel active nominations with LBG.
        </ModalBody>
        <ModalFooter className={styles['lowcarbonoption-modal-footer']}>
          <Button color="primary" onClick={toggleRejectionModal}>
            Ok
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LowCarbonOptionsContainer;
