import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SystemAdmin.module.scss';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Users from '../../components/AdminComponents/Users';
import Vessels from '../../components/AdminComponents/Vessels';
import Companies from '../../components/AdminComponents/Companies/';
import Locations from '../../components/AdminComponents/Locations';
import Contracts from '../../components/AdminComponents/Contracts/';
import Reports from '../../components/AdminComponents/Reports';
import BDNLocations from '../../components/AdminComponents/BDNLocations/BDNLocationsContainer/BDNLocationsContainer';
import { Route } from 'react-router';
import { useEffect } from 'react';
import BIaddVessels from '../../components/BIaddVessels/BIaddVessels';
import { CachedLocationService, LocationService } from '@teqplay/chorus-components';
import IntegrationAsset from '../../components/AdminComponents/IntegrationAssets/';

/*
const StyledSystemAdmin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem;
  .admin-header {
    margin-bottom: 1rem;
  }

  &.active {
    background-color: var(--highlighted-element-color);
  }

  a:hover {
    background-color: #f0f0f0;
  }
`;*/

interface OwnProps {
  locationService: CachedLocationService;
}



const faFileContract = findIconDefinition({ prefix: 'fal', iconName: 'file-contract' });
const faUsers = findIconDefinition({ prefix: 'fal', iconName: 'users' });
const faShip = findIconDefinition({ prefix: 'fal', iconName: 'ship' });
const faAnalytics = findIconDefinition({ prefix: 'fal', iconName: 'analytics' });
const faBuilding = findIconDefinition({ prefix: 'fal', iconName: 'building' });
const faMapMarkedAlt = findIconDefinition({ prefix: 'fal', iconName: 'map-marked-alt' });
const faLink = findIconDefinition({ prefix: 'fal', iconName: 'link' });

const SystemAdmin: React.FC<OwnProps> = ({ locationService }) => {
  const history = useHistory();

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (routes.length > 2) {
      return;
    }

    history.push(`/systemadmin/users`);
  }, []);

  const routes = history.location.pathname.split('/');
  const [, , baseRoute] = routes;
  const [activeTab, setActiveTab] = React.useState(baseRoute);

  useEffect(() => {
    toggle(baseRoute);
  }, [routes]);

  const rootRoute = (routes || []).length <= 3;

  const ActiveTab = {
    color: "white",
    filter: "brightness(149%)",
    opacity: "7",
    pointerEvents: "none",
    backgroundColor: "#262C42"
  }

  const inActiveTab = {

  }

  return (
    <div className={styles['admin']}>
      <div className={styles['admin-content']}>
        <div className={styles['admin-header']}>
          <Nav className={styles['b']}>
            <NavItem
              className={styles['b2']}
              onClick={() => {
                history.push(`/systemadmin/users`);
              }}
              style={ activeTab === "users" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'users' })} >
                <div className={styles['logo-area']}>
                  {' '}
                  <FontAwesomeIcon icon={faUsers} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text']}>Users</div>
              </NavLink>
            </NavItem>
            <NavItem
              className={styles['b2']}
              onClick={() => {
                history.push(`/systemadmin/vessels`);
              }}
              style={ activeTab === "vessels" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'vessels' })} >
                <div className={styles['logo-area']}>
                  <FontAwesomeIcon icon={faShip} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text']}>Vessels</div>
              </NavLink>
            </NavItem>
            <NavItem
              className={styles['b2']}
              onClick={() => {
                history.push(`/systemadmin/contracts`);
              }}
              style={ activeTab === "contracts" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'contracts' })} >
                <div className={styles['logo-area']}>
                  <FontAwesomeIcon icon={faFileContract} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text']}>Contracts</div>
              </NavLink>
            </NavItem>
            <NavItem
              className={styles['b2']}
              onClick={() => {
                history.push(`/systemadmin/companies`);
              }}
              style={ activeTab ==="companies" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'companies' })} >
                <div className={styles['logo-area']}>
                  {' '}
                  <FontAwesomeIcon icon={faBuilding} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text']}>Companies</div>
              </NavLink>
            </NavItem>
            <NavItem
              className={styles['b2']}
              onClick={() => {
                history.push(`/systemadmin/locations`);
              }}
              style={ activeTab ==="locations" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'locations' })} >
                <div className={styles['logo-area']}>
                  {' '}
                  <FontAwesomeIcon icon={faMapMarkedAlt} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text']}>Locations</div>
              </NavLink>
            </NavItem>
            <NavItem
              className={styles['b1']}
              onClick={() => {
                history.push(`/systemadmin/reports`);
              }}
              style={ activeTab === "reports" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'reports' })} >
                <div className={styles['logo-area']}>
                  {' '}
                  <FontAwesomeIcon icon={faAnalytics} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text-b']}>Analytics & insights</div>
              </NavLink>
            </NavItem>
             <NavItem
              className={styles['b2']}
              onClick={() => {
                history.push(`/systemadmin/bdnlocations`);
              }}
              style={ activeTab === "bdnlocations" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'bdnlocations' })} >
                <div className={styles['logo-area']}>
                  {' '}
                  <FontAwesomeIcon icon={faAnalytics} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text']}>BDN locations</div>
              </NavLink>
            </NavItem>
            <NavItem
              className={styles['b1']}
              onClick={() => {
                history.push(`/systemadmin/integrationassets`);
              }}
              style={ activeTab === "integrationassets" ? ActiveTab  : inActiveTab }
            >
              <NavLink className={classnames({ active: activeTab === 'integrationassets' })} >
                <div className={styles['logo-area']}>
                  {' '}
                  <FontAwesomeIcon icon={faLink} className={styles['logo-size']} size={'lg'} width={'15px'} color="#009fda" />
                </div>
                <div className={styles['logo-text-b']}>Integration Assets</div>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className={styles['admin-header-content']}>
          <TabContent activeTab={activeTab} style={{ marginLeft: '0.7em', padding: '0px 30px' }}>
            <TabPane tabId="users">
              {rootRoute && <h3>Users</h3>}
              <Route path="/systemadmin/users" render={(props) => <Users {...props} navAdminType="system" />} />
            </TabPane>
            <TabPane tabId="vessels">
              <h3>Vessels</h3>
              <Route path="/systemadmin/vessels" component={Vessels} />
            </TabPane>
            <TabPane tabId="contracts">
              {rootRoute && <h3>Contracts</h3>}
              <Route
                path="/systemadmin/contracts"
                render={(props) => <Contracts {...props} systemAdmin={true} navAdminType="system" />}
              />
            </TabPane>
            <TabPane tabId="companies">
              {rootRoute && <h3>Companies</h3>}
              <Route
                path="/systemadmin/companies"
                render={(props) => <Companies {...props} systemAdmin={true} navAdminType="system" />}
              />
            </TabPane>
            <TabPane tabId="locations">
              {rootRoute && <h3>Locations</h3>}
              <Route path="/systemadmin/locations" component={Locations} />
            </TabPane>
            <TabPane tabId="reports">
              {rootRoute && <h3>Reports</h3>}
              <Route path="/systemadmin/reports" render={(props) => <Reports {...props} navAdminType="system" />} />
            </TabPane>
            <TabPane tabId="bdnlocations">
              {rootRoute && <h3>BDN locations</h3>}
              <Route  path="/systemadmin/bdnlocations" render={(props) => <BDNLocations {...props} navAdminType="system" locationService={locationService}/>} />
            </TabPane>
            <TabPane tabId="integrationassets">
              {rootRoute && <h3>Integration assets</h3>}
              <Route
                path="/systemadmin/integrationassets"
                render={(props) => <IntegrationAsset {...props} systemAdmin={true} navAdminType="system" />} 
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default SystemAdmin;
