import {
  AuthenticationServiceLayer,
  doesUserHavePermission,
  ICompany,
  IContract,
  IPipeline,
  IShipWithCompanyId,
  IShipWithId,
  isScheduler,
  isSchedulerCaptain,
  IUserProfile,
  LocationService,
  useContractService,
  useLocationTimeZone,
  UserPermissionContext,
  UserRole,
} from '@teqplay/chorus-components';
import useForm from '@teqplay/react-useform';
import { Button, FormElement, FormErrorMessage, FormLabel, FormWrapper, SelectionBox } from '@teqplay/teqplay-ui';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { PermissionsWarning, useGlobalDataService } from '@teqplay/chorus-components';
import RecurringNominationForm from './RecurringNominationForm/RecurringNominationForm';
import {
  DailyScheduleModel,
  RecurringNomination,
  TimeBeforeNominationCreationType,
  WeeklyScheduleModel,
} from '../../../../store/RecurringNomination/models';
import {
  parseRecurringNominationValues,
  validateRecurringNominationForm,
} from './Validators/recurringNominationValidation';
import { QuantityUnit } from '@teqplay/chorus-components';

declare var Raven: any;

interface NominationNegotiationProps {
  userProfile: IUserProfile;
  // Creation Props
  // Auth Service handling all the api calls
  authenticationService: AuthenticationServiceLayer;
  // List properties from the global state
  companies: ICompany[];
  locationService: LocationService;
  bunkerShips: IShipWithCompanyId[];
  pipelines: IPipeline[];
  // presentation props
  showFuelbossStates: boolean;
  className?: string;
  enableAlternativeFuelSelector: boolean;
  // update / api functions
  updateRecurringNomination: (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE',
    updatedNomination: RecurringNomination,
  ) => Promise<void>;

  showDeliveryModeSelection: boolean;
  contractsList: IContract[];
}

const CreateRecurringNomination: React.FunctionComponent<NominationNegotiationProps> = (props) => {
  const { userProfile } = props;
  const { userPermissions } = useContext(UserPermissionContext);
  //const [onBehalfCompany, setOnBehalfCompany] = useState<string | undefined>(undefined);
  const [errorFillCompany, setErrorFillCompany] = useState<string | undefined>(undefined);
  const [creatingNomination, setCreatingNomination] = useState(false);
  const [initialNominationValues] = useState<RecurringNomination>({
    weeklySchedule: {} as WeeklyScheduleModel,
    dailySchedule: {} as DailyScheduleModel,
    tbncType: TimeBeforeNominationCreationType.Hours,
    timeBeforeNominationCreation: 6,
    quantityUnit: QuantityUnit.TONNES,
  } as RecurringNomination);

  const { vendorsWithContract, customersWithContract } = useContractService(props.authenticationService);

  const userRoles = userProfile ? userProfile.roles : undefined;

  const customerCompanyName = 'your customer';

  const debouncedSubmit = debounce(submitRecurringNomination, 500, { leading: true, trailing: false });

  const { handleSubmit, values, errors, onChangeValue, isDifferentFromInitial, setAllValues } = useForm<
    RecurringNomination
  >({
    initialValues: initialNominationValues,
    validate: validateRecurringNomination,
    onValidSubmit: debouncedSubmit,
  });

  const timezone = useLocationTimeZone(values?.locationId, props.locationService);

  const customerFleetCompanyId = props.userProfile.companyId;
  //const customerFleet = useCompanyFleetService(props.authenticationService, customerFleetCompanyId);
  const { userFleet: customerFleet, pipelines } = useGlobalDataService(props.authenticationService, props.userProfile);
  const errorMessage = 'Please fill in all fields correctly.';

  if (!doesUserHavePermission(userPermissions, 'NOMINATION_CREATE')) {
    return <PermissionsWarning>You are not allowed to Create a new nomination'</PermissionsWarning>;
  }

  const customerCompaniesFilteredByContract = props.companies
    .filter((company) => company.companyType === 'TRAMP')
    .filter((company) => {
      return customersWithContract.has(company._id);
    });

  return (
    <div className={`prompt-negotiation-wrapper ${props.className || ''}`}>
      <div>
        <form noValidate className="form-wrapper-prompt">
          {values && (
            <>
              <RecurringNominationForm
                currentUser={userProfile}
                creatingNewNomination={true}
                values={values}
                authenticationService={props.authenticationService}
                locationService={props.locationService}
                errors={errors}
                bunkerShips={props.bunkerShips}
                customerFleet={customerFleet}
                onChangeFormValue={onChangeValue}
                companies={props.companies}
                //creatingNominationOnBehalf={false}
                //onBehalfCompany={onBehalfCompany}
                showDeliveryModeSelection={props.showDeliveryModeSelection}
                vendorsWithContract={vendorsWithContract}
                contractsList={props.contractsList}
                setAllValues={setAllValues}
                pipelines={props.pipelines}
                enableAlternativeFuelSelector={props.enableAlternativeFuelSelector}
                editType={'EDIT'}
                customerCompanyName={customerCompanyName}
              ></RecurringNominationForm>

              {errors && !isEmpty(errors) && <div className="error block">{errorMessage}</div>}

              <div className="form-action-bar centered">
                <p className="create-message">
                  To share additional information, please click “Create nomination“ and use the chat
                </p>
                <div className="action-bar-buttons">
                  {(isScheduler(userRoles) || isSchedulerCaptain(userRoles)) &&
                  doesUserHavePermission(userPermissions, 'ACT_ON_BEHALF')
                    ? submitButton(true)
                    : submitButton()}
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );

  function submitButton(onBehalf: boolean = false) {
    return (
      <Button
        preventDoubleClick
        primary
        title={isDifferentFromInitial ? '' : 'No changes detected'}
        onClick={(e) => {
          handleSubmit(e, onBehalf);
        }}
      >
        {'Create new Recurring Nomination'}
      </Button>
    );
  }

  function validateRecurringNomination(nominationToValidate: RecurringNomination) {
    const roles: UserRole[] = props.userProfile.roles;
    // check error here
    const errors: { [index: string]: any } = validateRecurringNominationForm(
      nominationToValidate,
      props.userProfile,
      roles,
      props.showDeliveryModeSelection,
      timezone.value,
    );

    if (timezone.loading && !errors['locationId']) {
      errors['locationId'] = 'Location is still loading. Submit again once loaded';
    }

    return errors;
  }

  async function submitRecurringNomination(promptNomination: RecurringNomination) {
    const parsedNomination = parseRecurringNominationValues(promptNomination);

    const nomination = cloneDeep(parsedNomination);

    // set correct onbehalf
    //nomination.onBehalf = onBehalfOf !== undefined ? onBehalfOf : false;

    await createNewNomination(nomination);
  }

  async function createNewNomination(nominationToCreate: RecurringNomination) {
    if (creatingNomination) {
      return;
    }

    // means we are creating a nomination ( not on behalf )
    if (props.userProfile.companyId) {
      nominationToCreate.companyId = props.userProfile.companyId;
    } else {
      Raven.captureMessage('Submitting nomination without companyID', {
        userProfile: props.userProfile,
      });
    }

    setCreatingNomination(true);
    await props.updateRecurringNomination('CREATE', nominationToCreate);
    //setOnBehalfCompany(undefined); // reset the onBehalfCompany to make sure the nomination doesn't stay in 'onbehalf' mode after it's been created
    setCreatingNomination(true);
  }
};

export default React.memo(CreateRecurringNomination);
