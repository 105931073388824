import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { ApplicationState } from '../../store';
import { getSpotEnquiries } from '../../store/Spot/actions';

import { Loading } from '../Loading/index';
import { Error } from '../Error/index';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { getArchivedSpots } from '../../store/ArchivedSpot/actions';
import SpotListContainer from './Components/SpotList/SpotListContainer';
import SpotTableContainer from './Components/SpotTable/SpotTableContainer';
import { SpotEnquiry } from '../../store/Spot/models';
import { getSpotCompanyIds } from '../../helpers/spotEnquiryHelper';
import { getCompanies } from '../../store/Company/actions';

interface OwnProps {
  chooseSpot: (spotId: string[], spotObject: SpotEnquiry) => void;
  className?: string;
  setNewSpot: (newSpot: boolean) => void;
  displayAsTable?: boolean;
  activeSpotId?: string;
  archivedAsInitialTab?: boolean;
  sortByNeedsAction?: boolean;
  minified?: boolean;
  shipOwner: boolean | undefined;
}

const Enquiries: React.FC<OwnProps> = ({
  chooseSpot,
  className,
  setNewSpot,
  displayAsTable,
  activeSpotId,
  archivedAsInitialTab = false,
  sortByNeedsAction,
  minified,
  shipOwner,
}) => {
  const {
    spotLoading,
    spotError,
    spotErrorDetails,
    spot,
    spotMetadata,
    spotIsLoaded,
    companies,
    companiesIsLoaded,
    archivedSpotLoading,
    archivedSpotError,
    archivedSpotErrorDetails,
    archivedSpot,
    archivedSpotIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    spotLoading: state.spot.loading,
    spotError: state.spot.error,
    spotErrorDetails: state.spot.errorDetails,
    spot: state.spot.data,
    spotMetadata: state.spot.metadata,
    spotIsLoaded: state.spot.dataIsLoaded,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    archivedSpotLoading: state.archived_spot.loading,
    archivedSpotError: state.archived_spot.error,
    archivedSpotErrorDetails: state.archived_spot.errorDetails,
    archivedSpot: state.archived_spot.data,
    archivedSpotIsLoaded: state.archived_spot.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getSpotCallback = useCallback(() => dispatch(getSpotEnquiries()), []);

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const getArchivedSpotCallback = useCallback(() => dispatch(getArchivedSpots()), []);

  useEffect(() => {
    if (!spotIsLoaded) {
      getSpotCallback();
    }

    if (!companiesIsLoaded) {
      getCompaniesCallback();
    }
  }, []);

  React.useEffect(() => {
    //Hide enquiries that only have active companies
    const filteredEnquiries: SpotEnquiry[] = [];
    if (shipOwner) {
      spot.forEach(function (enquiry, index) {
        const enquiryIds = getSpotCompanyIds(enquiry);
        let hasActiveCompany = false;
        enquiryIds.forEach(function (enquiryId, index) {
          const compMatch = companies.find((x) => x.chorusId == enquiryId);
          if (compMatch?.active && compMatch != undefined) {
            hasActiveCompany = true;
          }
        });
        if (hasActiveCompany) {
          filteredEnquiries.push(enquiry);
        }
      });
      setActiveCompanyEnquiries(filteredEnquiries);
    } else {
      spot.forEach(function (enquiry, index) {
        const compMatch = companies.find((x) => x.chorusId == enquiry.companyId);
        if (compMatch?.active && compMatch != undefined) {
          filteredEnquiries.push(enquiry);
        }
      });
      setActiveCompanyEnquiries(filteredEnquiries);
    }
  }, [spot, companies]);

  const [activeCompanyEnquiries, setActiveCompanyEnquiries] = React.useState<SpotEnquiry[]>([]);
  const [activeTab, setActiveTab] = useState(archivedAsInitialTab ? '2' : '1');

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderSpots = (archived: boolean, displayAsTable?: boolean) => {
    if (displayAsTable) {
      return (
        <SpotTableContainer
          spots={archived ? archivedSpot : activeCompanyEnquiries}
          archived={archived}
          sortByNeedsAction={sortByNeedsAction ? sortByNeedsAction : false}
        />
      );
    } else {
      return (
        <SpotListContainer
          spots={archived ? archivedSpot : activeCompanyEnquiries}
          setNewSpot={setNewSpot}
          chooseSpot={chooseSpot}
          displayingArchivedSpots={archived}
          activeSpotId={activeSpotId ? activeSpotId : ''}
          minified={minified ? minified : false}
          activeTab={activeTab}
        />
      );
    }
  };

  return (
    <div className={className}>
      {!minified && (
        <Nav tabs className={`sticky-top bg-white bunker-events-nav`}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              Quotations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                if (!archivedSpotIsLoaded) {
                  getArchivedSpotCallback();
                }
                toggle('2');
              }}
            >
              Archived
            </NavLink>
          </NavItem>
        </Nav>
      )}

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {spotLoading && <Loading type="quotations" />}
          {spotError && spotErrorDetails && <Error error={spotErrorDetails} />}
          {!spotLoading && !spotError && renderSpots(false, displayAsTable)}
        </TabPane>
        <TabPane tabId="2">
          {archivedSpotLoading && <Loading type="archived quotations" />}
          {archivedSpotError && archivedSpotErrorDetails && <Error error={archivedSpotErrorDetails} />}

          {!archivedSpotLoading && !archivedSpotError && renderSpots(true, displayAsTable)}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Enquiries;
