import * as Yup from 'yup';
const validationMessage = {message: 'Not valid', exculdeEmptyString: true};

export default Yup.object().shape({
    //veracityAssetId: Yup.string().required('Location required'),
    //id: Yup.string().required('Location required'),
    //assetType: Yup.string().required('Location required'),
    //assetTypeId: Yup.string().required('Location required'),
    //ownerCompanyId: Yup.string().required('Location required'),
    //timeStamp: Yup.string().required('Location required'),
    fbAssetId: Yup.string().required('FuelBoss Asset is required'),
    fbAssetType: Yup.string().required('Asset type is required'),
    fbAssetBunkerShip: Yup.string().when('fbAssetType', {
        is: (fbAssetType) => fbAssetType == 'Bunkership',
        then: Yup.string().required('Bunkership is required.'),
        otherwise: Yup.string().optional(),
      }),
    fbAssetPipeline: Yup.string().when('fbAssetType', {
        is: (fbAssetType) => fbAssetType == 'Pipeline',
        then: Yup.string().required('Pipeline is required.'),
        otherwise: Yup.string().optional(),
    }),
    fbAssetReceivingship: Yup.string().when('fbAssetType', {
        is: (fbAssetType) => fbAssetType == 'Receivingship',
        then: Yup.string().required('Receivingship is required.'),
        otherwise: Yup.string().optional(),
      }),
    integrationAsset: Yup.object().required('Asset is required'),
    integrationAssetTopic: Yup.object().required('Topic is required'),
})