import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';

const validationMessage = {message: 'Not valid', exculdeEmptyString: true};

export default Yup.object().shape({
    veracityAssetId: Yup.string().matches(integrationAssetConfigs.veracityAssetId.regex, validationMessage).required('Veracity id required'),
    assetName: Yup.string().matches(integrationAssetConfigs.assetName.regex, validationMessage).required('Name required'),
    assetExternalName: Yup.string().matches(integrationAssetConfigs.assetExternalName.regex, validationMessage).required('External name required'),
    assetDescription: Yup.string().required('Description required'),
    assetLocation: Yup.string().required('Location required'),
    type: Yup.string().required('Type required'),
    company: Yup.string().required('Owner company required'),
    workspaceId: Yup.string().required('Workspace required'),
})