import { Dispatch } from 'redux';
import {
  ReportActions,
  REPORT_TYPE_CLEAR_TOAST,
  REPORT_TYPE_LOADING,
  GET_REPORT_TYPE,
  REPORT_TYPE_FETCH_ERROR,
  ADD_REPORT_TYPE,
  DELETE_REPORT_TYPE,
  EDIT_REPORT_TYPE,
  REPORT_TYPE_EDIT_ERROR,
  REPORT_TYPE_ADD_ERROR,
  REPORT_TYPE_DELETE_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { ReportTypeResponseModel, ReportType } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { REPORTS_DELETE_ERROR } from '../Reports/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating report type...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Report type updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating report type...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Report type created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;


export const getReportTypes = () => (dispatch: Dispatch<ReportActions>) => {
  dispatch({
    type: REPORT_TYPE_LOADING,
  });

  new api<ReportTypeResponseModel>({
    dispatch,
    errorType: REPORT_TYPE_FETCH_ERROR,
  })
    .get(`/api/VAPReportType`)
    .then((response) => {
      dispatch({
        type: GET_REPORT_TYPE,
        payload: response,
      });
    });
};

export const addReportType = (reportType: ReportType, onSuccess: () => void) => (
  dispatch: Dispatch<ReportActions>,
) => {
  dispatch({
    type: REPORT_TYPE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<ReportTypeResponseModel>({
    dispatch,
    errorType: REPORT_TYPE_ADD_ERROR,
  })
    .post(`/api/VAPReportType`, reportType)
    .then((response) => {
      dispatch({
        type: ADD_REPORT_TYPE,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      /*dispatch({
        type: REPORTS_ADD_ERROR,
        payload: {
          clientFault: false,
          serverFault: true,
          statusCode: 405,
          message: 'Report with the current name already exist! Please contact FuelBoss support for more details',
        },
      });*/
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteReportType = (id: string) => (dispatch: Dispatch<ReportActions>) => {
  dispatch({
    type: REPORT_TYPE_LOADING,
  });

  const url = `/api/VAPReportType/${id}`;
  new api<ReportTypeResponseModel>({
    dispatch,
    errorType: REPORT_TYPE_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_REPORT_TYPE,
        payload: response,
      });
    }).catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const editReportType = (reportType: ReportType, onSuccess: () => void) => (
  dispatch: Dispatch<ReportActions>,
) => {
  dispatch({
    type: REPORT_TYPE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (reportType.id != undefined) {
    const url = `/api/VAPReportType/${reportType.id}`;

    new api<ReportTypeResponseModel>({
      dispatch,
      errorType: REPORT_TYPE_EDIT_ERROR,
    })
      .put(url, reportType)
      .then((response) => {
        dispatch({
          type: EDIT_REPORT_TYPE,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const reportTypesClearToast = () => (dispatch: Dispatch<ReportActions>) => {
  dispatch({
    type: REPORT_TYPE_CLEAR_TOAST,
  });
};
