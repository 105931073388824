import { isEqual } from 'lodash'
import { RecurringNomination } from '../../../../../store/RecurringNomination/models'

/**
 * Check if any fields are different on one nomination then the other nomination
 * Return the keys that have a different value in both nominations
 */
export function comparingRecurringNominationFields(
  initialNomination: RecurringNomination,
  comparingNomination?: RecurringNomination
): (keyof RecurringNomination)[] {
  if (!comparingNomination) {
    return []
  }

  const allKeys = Object.keys(initialNomination) as (keyof RecurringNomination)[]
  return allKeys.filter(key => {
    if (typeof initialNomination[key] === 'string' || typeof initialNomination[key] === 'number') {
      return initialNomination[key] !== comparingNomination[key]
    }
    return !isEqual(initialNomination[key], comparingNomination[key])
  })
}
