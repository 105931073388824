import React, { useState, lazy  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import { ApplicationState } from '../../../store';
import { IntegrationAsset } from '../../../store/IntegrationAsset/models';
import { IntegrationAssetType } from '../../../store/IntegrationAssetType/models';
import { IntegrationAssetCompany } from '../../../store/IntegrationAssetCompanies/models';
import { IntegrationEventType } from '../../../store/IntegrationEventTypes/models';
import { IntegrationAssetTopic } from '../../../store/IntegrationAssetTopic/models';
import { IntegrationAssetIssuer } from '../../../store/IntegrationAssetIssuer/models';
import { IntegrationAssetPermissions } from '../../../store/IntegrationAssetPermissions/models';
import { AddToast } from '../../../helpers/toastService';
import {
  addIntegrationAsset,
  deleteIntegrationAssetAsync,
  getIntegrationAssets,
  integrationAssetClearToast,
  updateIntegrationAsset,
} from '../../../store/IntegrationAsset/actions';
import {
  addIntegrationAssetType,
  deleteIntegrationAssetTypeAsync,
  getIntegrationAssetTypes,
  editIntegrationAssetType,
  integrationAssetTypesClearToast,
} from '../../../store/IntegrationAssetType/actions';
import {
  addIntegrationAssetCompany,
  deleteIntegrationAssetCompanyAsync,
  getIntegrationAssetCompanies,
  editIntegrationAssetCompany,
  integrationAssetCompaniesClearToast,
} from '../../../store/IntegrationAssetCompanies/actions';
import {
  getIntegrationEventTypes,
  deleteIntegrationEventType,
  addIntegrationEventType,
  editIntegrationEventType,
  integrationEventTypesClearToast,
} from '../../../store/IntegrationEventTypes/actions';
import {
  getIntegrationAssetTopic,
  deleteIntegrationAssetTopic,
  addIntegrationAssetTopic,
  editIntegrationAssetTopic,
  integrationAssetTopicClearToast,
} from '../../../store/IntegrationAssetTopic/actions';
import {
  getIntegrationAssetIssuers,
  deleteIntegrationAssetIssuers,
  addIntegrationAssetIssuers,
  editIntegrationAssetIssuers,
  integrationAssetIssuersClearToast,
} from '../../../store/IntegrationAssetIssuer/actions';
import {
  getIntegrationAssetPermissions,
  deleteIntegrationAssetPermissions,
  addIntegrationAssetPermissions,
  editIntegrationAssetPermissions,
  integrationAssetPermissionsClearToast,
} from '../../../store/IntegrationAssetPermissions/actions';
import IntegrationAssets from './Components/IntegrationAsset/IntegrationAssets';
import IntegrationAssetTypes from './Components/IntegrationAssetType/IntegrationAssetType';
import IntegrationAssetCompanies from './Components/IntegrationAssetCompany/IntegrationAssetCompanies';
import IntegrationEventTypes from './Components/IntegrationEventType/IntegrationEventType';
import AddIntegrationAsset from './Components/AddIntegrationAsset/AddIntegrationAsset';
import EditIntegrationAsset from './Components/EditIntegrationAsset/EditIntegrationAsset';
import AddIntegrationAssetType from './Components/AddIntegrationAssetType/AddIntegrationAssetType';
import EditIntegrationAssetType from './Components/EditIntegrationAssetType/EditIntegrationAssetType';
import AddIntegrationAssetCompany from './Components/AddIntegrationAssetCompany/AddIntegrationAssetCompany';
import EditIntegrationAssetCompany from './Components/EditIntegrationAssetCompany/EditIntegrationAssetCompany';
import AddIntegrationEventType from './Components/AddIntegrationEventType/AddIntegrationEventType';
import EditIntegrationEventType from './Components/EditIntegrationEventType/EditIntegrationEventType';
import AddIntegrationAssetTopic from './Components/AddIntegrationAssetTopic/AddIntegrationAssetTopic';
import AddIntegrationAssetIssuers from './Components/AddIntegrationAssetSchemas/AddIntegrationAssetIssuers';
import AddIntegrationAssetPermissions from './Components/AddIntegrationAssetPermissions/AddIntegrationAssetPermissions';
import { Loading } from '../../Loading';
import { companyClearToast, getCompanies } from '../../../store/Company/actions';
import OutboundIntegrationTable from './Components/OutboundIntegrationAdminView/OutboundIntegrationTable';
import { getIntegrationOutboundApiTracker } from '../../../store/IntegrationOutboundApiTracker/actions';
import { IntegrationOutboundApiTracker } from '../../../store/IntegrationOutboundApiTracker/models';
import OutboundAssetForm from './Components/OutboundAssetForm/OutboundAssetForm';
import AddIntegrationAssetWorkspace from './Components/AddIntegrationAssetWorkspace/AddIntegrationAssetWorkspace';
import EditIntegrationAssetWorkspace from './Components/EditIntegrationAssetWorkspace/EditIntegrationAssetWorkspace';
import { addIntegrationAssetWorkspace, editIntegrationAssetWorkspace, getIntegrationAssetWorkspaces, integrationAssetWorkspacesClearToast } from '../../../store/IntegrationAssetWorkspaces/actions';
import { IntegrationAssetWorkspace } from '../../../store/IntegrationAssetWorkspaces/models';


interface OwnProps {
  systemAdmin?: boolean;
  navAdminType: string;
}


const IntegrationAssetContainer: React.FC<OwnProps> = ({ systemAdmin = false, navAdminType }) => {
  const {
    integrationAssetsLoading,
    integrationAssetsDetailsError,
    integrationAssetsErrorDetails,
    integrationAssets,
    integrationAssetsIsLoaded,
    toastData,
    integrationAssetTypes,
    integrationAssetTypesLoading,
    integrationAssetTypesDetailsError,
    integrationAssetTypesErrorDetails,
    integrationAssetTypesIsLoaded,
    toastDataIntegrationAssetTypes,
    integrationAssetCompanies,
    integrationAssetCompaniesLoading,
    integrationAssetCompaniesDetailsError,
    integrationAssetCompaniesErrorDetails,
    integrationAssetCompaniesIsLoaded,
    toastDataIntegrationAssetCompanies,
    integrationEventTypes,
    integrationEventTypesLoading,
    integrationEventTypesDetailsError,
    integrationEventTypesErrorDetails,
    integrationEventTypesIsLoaded,
    toastDataIntegrationEventTypes,
    integrationAssetTopic,
    integrationAssetTopicLoading,
    integrationAssetTopicDetailsError,
    integrationAssetTopicErrorDetails,
    integrationAssetTopicIsLoaded,
    toastDataIntegrationAssetTopic,
    integrationAssetIssuers,
    integrationAssetIssuersLoading,
    integrationAssetIssuersDetailsError,
    integrationAssetIssuersErrorDetails,
    integrationAssetIssuersIsLoaded,
    toastDataIntegrationAssetIssuers,
    integrationAssetPermissions,
    integrationAssetPermissionsLoading,
    integrationAssetPermissionsDetailsError,
    integrationAssetPermissionsErrorDetails,
    integrationAssetPermissionsIsLoaded,
    toastDataIntegrationAssetPermissions,
    integrationAssetWorkspaces,
    integrationAssetWorkspacesLoading,
    integrationAssetWorkspacesDetailsError,
    integrationAssetWorkspacesErrorDetails,
    integrationAssetWorkspacesIsLoaded,
    toastDataIntegrationAssetWorkspaces,
    companies,
    companiesDetailsError,
    companiesErrorDetails,
    companiesIsLoaded,
    companiesLoading,
    companiesToastData,
    integrationOutboundApiTrackers,
    integrationOutboundApiTrackersLoaded,
    integrationOutboundApiTrackersDetailsError,
    integrationOutboundApiTrackersErrorDetails,
    integrationOutboundApiTrackersToastData,
    auth,
  } = useSelector((state: ApplicationState) => ({
    integrationAssetsLoading: state.integration_assets.loading,
    integrationAssetsDetailsError: state.integration_assets.error,
    integrationAssetsErrorDetails: state.integration_assets.errorDetails,
    integrationAssets: state.integration_assets.data,
    integrationAssetsIsLoaded: state.integration_assets.dataIsLoaded,
    toastData: state.integration_assets.toastData,

    integrationAssetTypes: state.integration_assets_types.data,
    integrationAssetTypesLoading: state.integration_assets_types.loading,
    integrationAssetTypesDetailsError: state.integration_assets_types.error,
    integrationAssetTypesErrorDetails: state.integration_assets_types.errorDetails,
    integrationAssetTypesIsLoaded: state.integration_assets_types.dataIsLoaded,
    toastDataIntegrationAssetTypes: state.integration_assets_types.toastData,

    integrationAssetCompanies: state.integration_assets_companies.data,
    integrationAssetCompaniesLoading: state.integration_assets_companies.loading,
    integrationAssetCompaniesDetailsError: state.integration_assets_companies.error,
    integrationAssetCompaniesErrorDetails: state.integration_assets_companies.errorDetails,
    integrationAssetCompaniesIsLoaded: state.integration_assets_companies.dataIsLoaded,
    toastDataIntegrationAssetCompanies: state.integration_assets_companies.toastData,

    integrationEventTypes: state.integration_events_types.data,
    integrationEventTypesLoading: state.integration_events_types.loading,
    integrationEventTypesDetailsError: state.integration_events_types.error,
    integrationEventTypesErrorDetails: state.integration_events_types.errorDetails,
    integrationEventTypesIsLoaded: state.integration_events_types.dataIsLoaded,
    toastDataIntegrationEventTypes: state.integration_events_types.toastData,

    integrationAssetTopic: state.integration_assets_topic.data,
    integrationAssetTopicLoading: state.integration_assets_topic.loading,
    integrationAssetTopicDetailsError: state.integration_assets_topic.error,
    integrationAssetTopicErrorDetails: state.integration_assets_topic.errorDetails,
    integrationAssetTopicIsLoaded: state.integration_assets_topic.dataIsLoaded,
    toastDataIntegrationAssetTopic: state.integration_assets_topic.toastData,

    integrationAssetIssuers: state.integration_assets_issuers.data,
    integrationAssetIssuersLoading: state.integration_assets_issuers.loading,
    integrationAssetIssuersDetailsError: state.integration_assets_issuers.error,
    integrationAssetIssuersErrorDetails: state.integration_assets_issuers.errorDetails,
    integrationAssetIssuersIsLoaded: state.integration_assets_issuers.dataIsLoaded,
    toastDataIntegrationAssetIssuers: state.integration_assets_issuers.toastData,

    integrationAssetPermissions: state.integration_assets_permissions.data,
    integrationAssetPermissionsLoading: state.integration_assets_permissions.loading,
    integrationAssetPermissionsDetailsError: state.integration_assets_permissions.error,
    integrationAssetPermissionsErrorDetails: state.integration_assets_permissions.errorDetails,
    integrationAssetPermissionsIsLoaded: state.integration_assets_permissions.dataIsLoaded,
    toastDataIntegrationAssetPermissions: state.integration_assets_permissions.toastData,

    integrationAssetWorkspaces: state.integration_assets_workspaces.data,
    integrationAssetWorkspacesLoading: state.integration_assets_workspaces.loading,
    integrationAssetWorkspacesDetailsError: state.integration_assets_workspaces.error,
    integrationAssetWorkspacesErrorDetails: state.integration_assets_workspaces.errorDetails,
    integrationAssetWorkspacesIsLoaded: state.integration_assets_workspaces.dataIsLoaded,
    toastDataIntegrationAssetWorkspaces: state.integration_assets_workspaces.toastData,

    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    companiesLoading: state.companies.loading,
    companiesDetailsError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,
    companiesToastData: state.companies.toastData,

    integrationOutboundApiTrackers: state.integration_outbound_api_tracker.data,
    integrationOutboundApiTrackersLoaded: state.integration_outbound_api_tracker.dataIsLoaded,
    integrationOutboundApiTrackersDetailsError: state.integration_outbound_api_tracker.error,
    integrationOutboundApiTrackersErrorDetails: state.integration_outbound_api_tracker.errorDetails,
    integrationOutboundApiTrackersToastData: state.integration_outbound_api_tracker.toastData,

    auth: state.auth.data,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const [veracityClientId, setVeracityClientId] = useState<string>();

  const [assetId, setAssetId] = useState<string>();

  const [assetCompany, setAssetCompany] = useState<string>();

  /* useEffect(() => {
    setAssetId(JSON.parse(window.sessionStorage.getItem('assetId') as string));
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('assetId', assetId as string);
  }, [assetId]);*/

  const getAllCompanies = useCallback(() => dispatch(getCompanies()), []);

  const getAllIntegrationAssets = useCallback(() => dispatch(getIntegrationAssets()), []);

  const getAllIntegrationAssetTypes = useCallback(() => dispatch(getIntegrationAssetTypes()), []);

  const getAllIntegrationAssetCompanies = useCallback(() => dispatch(getIntegrationAssetCompanies()), []);

  const getAllIntegrationEventTypes = useCallback(() => dispatch(getIntegrationEventTypes()), []);

  const getAllIntegrationAssetTopic = useCallback(() => dispatch(getIntegrationAssetTopic()), []);

  const getAllIntegrationAssetIssuers = useCallback(() => dispatch(getIntegrationAssetIssuers()), []);

  const getAllIntegrationAssetPermissions = useCallback(() => dispatch(getIntegrationAssetPermissions()), []);

  const addIntegrationAssetCallback = useCallback(
    (integrationAsset: IntegrationAsset) =>
      dispatch(addIntegrationAsset(integrationAsset, () => history.push(`/${navAdminType}admin/integrationassets`))),
    [],
  );

 const OutboundAssetForm = lazy(() => import('./Components/OutboundAssetForm/OutboundAssetForm'));

  const addIntegrationAssetTypeCallback = useCallback(
    (integrationAssetType: IntegrationAssetType) =>
      dispatch(
        addIntegrationAssetType(integrationAssetType, () =>
          history.push(`/${navAdminType}admin/integrationassets/types`),
        ),
      ),
    [],
  );

  const addIntegrationAssetCompanyCallback = useCallback(
    (integrationAssetCompany: IntegrationAssetCompany) =>
      dispatch(
        addIntegrationAssetCompany(integrationAssetCompany, () =>
          history.push(`/${navAdminType}admin/integrationassets/companies`),
        ),
      ),
    [],
  );

  const addIntegrationAssetWorkspaceCallback = useCallback(
    (integrationAssetWorkspace: IntegrationAssetWorkspace) =>
      dispatch(
        addIntegrationAssetWorkspace(integrationAssetWorkspace, () =>
          history.push(`/${navAdminType}admin/integrationassets/companies`),
        ),
      ),
    [],
  );

  const addIntegrationAssetTopicCallback = useCallback(
    (integrationAssetTopic: IntegrationAssetTopic) =>
      dispatch(
        addIntegrationAssetTopic(integrationAssetTopic, () => {
          if (integrationAssetTopic.integrationAssetId)
            history.push(
              `/${navAdminType}admin/integrationassets/${integrationAssetTopic.integrationAssetId}/edit`,
            );
          else history.push(`/${navAdminType}admin/integrationassets`);
        }),
      ),
    [],
  );

  const addIntegrationAssetIssuersCallback = useCallback(
    (integrationAssetIssuers: IntegrationAssetIssuer) =>
      dispatch(
        addIntegrationAssetIssuers(integrationAssetIssuers, () => {
          if (integrationAssetIssuers.integrationAssetId)
            history.push(`/${navAdminType}admin/integrationassets/${integrationAssetIssuers.integrationAssetId}/edit`);
          else history.push(`/${navAdminType}admin/integrationassets`);
        }),
      ),
    [],
  );

  const addIntegrationAssetPermissionsCallback = useCallback(
    (integrationAssetPermissions: IntegrationAssetPermissions) =>
      dispatch(
        addIntegrationAssetPermissions(integrationAssetPermissions, () => {
          if (integrationAssetPermissions.assetId)
            history.push(`/${navAdminType}admin/integrationassets/${integrationAssetPermissions.assetId}/edit`);
          else history.push(`/${navAdminType}admin/integrationassets`);
        }),
      ),
    [],
  );

  const addIntegrationEventTypeCallback = useCallback(
    (integrationEventType: IntegrationEventType) =>
      dispatch(
        addIntegrationEventType(integrationEventType, () =>
          history.push(`/${navAdminType}admin/integrationassets/events`),
        ),
      ),
    [],
  );

  const deleteIntegrationAssetCallback = useCallback((id: string) => dispatch(deleteIntegrationAssetAsync(id)), []);

  const deleteIntegrationAssetTypeCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetTypeAsync(id)),
    [],
  );

  const deleteIntegrationAssetCompanyCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetCompanyAsync(id)),
    [],
  );

  const deleteIntegrationEventTypeCallback = useCallback((id: string) => dispatch(deleteIntegrationEventType(id)), []);

  const deleteIntegrationAssetTopicCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetTopic(id)),
    [],
  );

  const deleteIntegrationAssetIssuersCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetIssuers(id)),
    [],
  );

  const deleteIntegrationAssetPermissionsCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetPermissions(id)),
    [],
  );

  const editIntegrationAssetCallback = useCallback(
    (integrationAsset: IntegrationAsset) =>
      dispatch(
        updateIntegrationAsset(integrationAsset, navAdminType, () =>
          history.push(`/${navAdminType}admin/integrationassets`),
        ),
      ),
    [],
  );

  const editIntegrationAssetTypeCallback = useCallback(
    (integrationAssetType: IntegrationAssetType) =>
      dispatch(
        editIntegrationAssetType(integrationAssetType, () =>
          history.push(`/${navAdminType}admin/integrationassets/types`),
        ),
      ),
    [],
  );

  const editIntegrationAssetCompanyCallback = useCallback(
    (integrationAssetCompany: IntegrationAssetCompany) =>
      dispatch(
        editIntegrationAssetCompany(integrationAssetCompany, () =>
          history.push(`/${navAdminType}admin/integrationassets/companies`),
        ),
      ),
    [],
  );

  const editIntegrationAssetWorkspaceCallback = useCallback(
    (integrationAssetWorkspace: IntegrationAssetWorkspace) =>
      dispatch(
        editIntegrationAssetWorkspace(integrationAssetWorkspace, () =>
          history.push(`/${navAdminType}admin/integrationassets/companies`),
        ),
      ),
    [],
  );

  const editIntegrationEventTypeCallback = useCallback(
    (integrationEventType: IntegrationEventType) =>
      dispatch(
        editIntegrationEventType(integrationEventType, () =>
          history.push(`/${navAdminType}admin/integrationassets/events`),
        ),
      ),
    [],
  );

  const editIntegrationAssetTopicCallback = useCallback(
    (integrationAssetTopic: IntegrationAssetTopic) =>
      dispatch(
        editIntegrationAssetTopic(integrationAssetTopic, () =>
          history.push(`/${navAdminType}admin/integrationassets/datachannel`),
        ),
      ),
    [],
  );

  const editIntegrationAssetIssuersCallback = useCallback(
    (integrationAssetIssuers: IntegrationAssetIssuer) =>
      dispatch(
        editIntegrationAssetIssuers(integrationAssetIssuers, () =>
          history.push(`/${navAdminType}admin/integrationassets`),
        ),
      ),
    [],
  );

  const editIntegrationAssetPermissionsCallback = useCallback(
    (integrationAssetPermissions: IntegrationAssetPermissions) =>
      dispatch(
        editIntegrationAssetPermissions(integrationAssetPermissions, () =>
          history.push(`/${navAdminType}admin/integrationassets/permissions`),
        ),
      ),
    [],
  );

  const clearToastCallback = useCallback(() => dispatch(integrationAssetClearToast()), []);

  const clearToastIntegrationAssetTypeCallback = useCallback(() => dispatch(integrationAssetTypesClearToast()), []);

  const clearToastIntegrationAssetCompanyCallback = useCallback(
    () => dispatch(integrationAssetCompaniesClearToast()),
    [],
  );

  const clearToastIntegrationEventTypeCallback = useCallback(() => dispatch(integrationEventTypesClearToast()), []);

  const clearToastIntegrationAssetTopicCallback = useCallback(
    () => dispatch(integrationAssetTopicClearToast()),
    [],
  );

  const clearToastIntegrationAssetIssuersCallback = useCallback(
    () => dispatch(integrationAssetIssuersClearToast()),
    [],
  );

  const clearToastIntegrationAssetPermissionsCallback = useCallback(
    () => dispatch(integrationAssetPermissionsClearToast()),
    [],
  );

  const clearToastIntegrationAssetWorkspaceCallback = useCallback(
    () => dispatch(integrationAssetWorkspacesClearToast()),
    [],
  );
  const getAllWorkspacesCallback = useCallback(() => dispatch(getIntegrationAssetWorkspaces()), []);

  const clearToastCompanyCallback = useCallback(() => dispatch(companyClearToast()), []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  useEffect(() => {
    if (toastDataIntegrationAssetTypes != null || toastDataIntegrationAssetTypes != undefined) {
      AddToast(toastDataIntegrationAssetTypes);
      clearToastIntegrationAssetTypeCallback();
    }
  }, [toastDataIntegrationAssetTypes]);

  useEffect(() => {
    if (toastDataIntegrationAssetCompanies != null || toastDataIntegrationAssetCompanies != undefined) {
      AddToast(toastDataIntegrationAssetCompanies);
      clearToastIntegrationAssetCompanyCallback();
    }
  }, [toastDataIntegrationAssetCompanies]);

  useEffect(() => {
    if (toastDataIntegrationEventTypes != null || toastDataIntegrationEventTypes != undefined) {
      AddToast(toastDataIntegrationEventTypes);
      clearToastIntegrationEventTypeCallback();
    }
  }, [toastDataIntegrationEventTypes]);

  useEffect(() => {
    if (toastDataIntegrationAssetTopic != null || toastDataIntegrationAssetTopic != undefined) {
      AddToast(toastDataIntegrationAssetTopic);
      clearToastIntegrationAssetTopicCallback();
    }
  }, [toastDataIntegrationAssetTopic]);

  useEffect(() => {
    if (toastDataIntegrationAssetIssuers != null || toastDataIntegrationAssetIssuers != undefined) {
      AddToast(toastDataIntegrationAssetIssuers);
      clearToastIntegrationAssetIssuersCallback();
    }
  }, [toastDataIntegrationAssetIssuers]);

  useEffect(() => {
    if (toastDataIntegrationAssetPermissions != null || toastDataIntegrationAssetPermissions != undefined) {
      AddToast(toastDataIntegrationAssetPermissions);
      clearToastIntegrationAssetPermissionsCallback();
    }
  }, [toastDataIntegrationAssetPermissions]);

  useEffect(() => {
    if (toastDataIntegrationAssetWorkspaces != null || toastDataIntegrationAssetWorkspaces != undefined) {
      AddToast(toastDataIntegrationAssetWorkspaces);
      integrationAssetWorkspacesClearToast();
    }
  }, [toastDataIntegrationAssetWorkspaces]);

  useEffect(() => {
    if (!integrationAssetPermissionsIsLoaded) {
      getAllIntegrationAssetPermissions();
    }
  }, [integrationAssetPermissionsIsLoaded]);

  useEffect(() => {
    if (!integrationAssetsIsLoaded) {
      getAllIntegrationAssets();
    }
    if (!integrationAssetTypesIsLoaded) {
      getAllIntegrationAssetTypes();
    }
    if (!integrationAssetCompaniesIsLoaded) {
      getAllIntegrationAssetCompanies();
    }
    if (!integrationEventTypesIsLoaded) {
      getAllIntegrationEventTypes();
    }
    if (!integrationAssetTopicIsLoaded) {
      getAllIntegrationAssetTopic();
    }
    if (!integrationAssetIssuersIsLoaded) {
      getAllIntegrationAssetIssuers();
    }
    if (!integrationAssetPermissions) {
      getAllIntegrationAssetPermissions();
    }
    if(!integrationAssetWorkspacesIsLoaded){
      getAllWorkspacesCallback();
    }
    if (!companiesIsLoaded) {
      getAllCompanies();
    }


  }, []);


  const [rejectContract, setRejectContract] = React.useState<IntegrationAsset>();
  const [writePermission, setWritePermission] = React.useState(systemAdmin);
  const [updateRequired, setUpdateRequired] = React.useState<boolean>(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModalsetIntegrationAsset = (integrationAsset: IntegrationAsset) => {
    setRejectContract(integrationAsset);
    setUpdateRequired(true);
    toggleModal();
  };

  const integrationAssetEdited = (integrationAsset: IntegrationAsset) => {
    setUpdateRequired(true);
    editIntegrationAssetCallback(integrationAsset);
  };

  const integrationAssetAdded = (integrationAsset: IntegrationAsset) => {
    setUpdateRequired(true);
    addIntegrationAssetCallback(integrationAsset);
  };

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }

  if (
    !integrationAssetCompaniesIsLoaded ||
    !integrationAssetIssuersIsLoaded ||
    !integrationAssetTopicIsLoaded ||
    !companiesIsLoaded
  ) {
    return <Loading />;
  } else if (integrationAssetCompanies && integrationAssetIssuers && integrationAssetTopic && companies) {
    return (
      <Switch>
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/`}
          render={(props) => (
            <IntegrationAssets
              integrationAssets={integrationAssets}
              integrationAssetCompanies={integrationAssetCompanies}
              integrationAssetTopic={integrationAssetTopic}
              integrationAssetIssuers={integrationAssetIssuers}
              integrationAssetPermissions={integrationAssetPermissions}
              integrationAssetWorkspaces={integrationAssetWorkspaces}
              companies={companies}
              isLoading={integrationAssetsLoading}
              hasError={integrationAssetsDetailsError}
              errorDetails={integrationAssetsErrorDetails}
              addIntegrationAssetIdIssuer={addIntegrationAssetIssuersCallback}
              deleteIntegrationAsset={deleteIntegrationAssetCallback}
              editIntegrationAsset={editIntegrationAssetCallback}
              navAdminType={navAdminType}
              defaultSelectedPage={selectedPage}
              setDefaultSelectedPage={handleDefaultChange}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/add`}
          render={(props) => (
            <AddIntegrationAsset
              {...{ props }}
              addNewIntegrationAsset={addIntegrationAssetCallback}
              systemAdmin={systemAdmin}
              navAdminType={navAdminType}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/outbound`}
          render={(props) => (
              <OutboundIntegrationTable
                navAdminType={navAdminType}
                {...{ props }}
              />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/outbound/assetform`}
          render={(props) => (
            <OutboundAssetForm
              defaultSelectedPage={selectedPage}
              setDefaultSelectedPage={handleDefaultChange}
              navAdminType={navAdminType}
              {...{ props }}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/:integrationAssetId/edit`}
          render={(props) => (
            <EditIntegrationAsset
              {...props}
              assetId={assetId}
              veracityClientId={veracityClientId}
              assetCompany={assetCompany}
              setVeracityClientId={setVeracityClientId}
              setAssetId={setAssetId}
              setAssetCompany={setAssetCompany}
              isLoading={integrationAssetsLoading}
              editIntegrationAsset={editIntegrationAssetCallback}
              integrationAssets={integrationAssets}
              integrationAssetId={props.match.params.integrationAssetId}
              navAdminType={navAdminType}
              systemAdmin={systemAdmin}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/types`}
          render={(props) => (
            <>
              <IntegrationAssetTypes
                {...props}
                integrationAssetTypes={integrationAssetTypes}
                isLoading={integrationAssetTypesLoading}
                hasError={integrationAssetTypesDetailsError}
                errorDetails={integrationAssetTypesErrorDetails}
                deleteIntegrationAssetType={deleteIntegrationAssetTypeCallback}
                editIntegrationAssetType={editIntegrationAssetTypeCallback}
                systemAdmin={systemAdmin}
                navAdminType={navAdminType}
              />
            </>
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/addtype`}
          render={(props) => (
            <AddIntegrationAssetType
              {...props}
              addNewIntegrationAssetType={addIntegrationAssetTypeCallback}
              navAdminType={navAdminType}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/:integrationAssetTypeId/edittype`}
          render={(props) => (
            <EditIntegrationAssetType
              {...props}
              isLoading={integrationAssetTypesLoading}
              editIntegrationAssetType={editIntegrationAssetTypeCallback}
              integrationAssetTypes={integrationAssetTypes}
              integrationAssetTypeId={props.match.params.integrationAssetTypeId}
              navAdminType={navAdminType}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/companies`}
          render={(props) => (
            <>
              <IntegrationAssetCompanies
                {...props}
                integrationAssetCompanies={integrationAssetCompanies}
                isLoading={integrationAssetCompaniesLoading}
                hasError={integrationAssetCompaniesDetailsError}
                errorDetails={integrationAssetCompaniesErrorDetails}
                deleteIntegrationAssetCompany={deleteIntegrationAssetCompanyCallback}
                editIntegrationAssetCompany={editIntegrationAssetCompanyCallback}
                systemAdmin={systemAdmin}
                navAdminType={navAdminType}
              />
            </>
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/addcompany`}
          render={(props) => (
            <AddIntegrationAssetCompany
              {...props}
              addNewIntegrationAssetCompany={addIntegrationAssetCompanyCallback}
              navAdminType={navAdminType}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/:integrationAssetCompanyId/editcompany`}
          render={(props) => (
            <EditIntegrationAssetCompany
              {...props}
              isLoading={integrationAssetCompaniesLoading}
              editIntegrationAssetCompany={editIntegrationAssetCompanyCallback}
              integrationAssetCompanies={integrationAssetCompanies}
              integrationAssetCompanyId={props.match.params.integrationAssetCompanyId}
              navAdminType={navAdminType}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/:integrationAssetCompanyId/editcompany/addworkspace`}
          render={(props) => (
            <AddIntegrationAssetWorkspace
              {...props}
              addNewIntegrationAssetWorkspace={addIntegrationAssetWorkspaceCallback}
              navAdminType={navAdminType}
              integrationAssetCompanies={integrationAssetCompanies}
              integrationAssetCompanyId={props.match.params.integrationAssetCompanyId}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/:integrationAssetCompanyId/editcompany/:integrationAssetWorkspaceId/editworkspace`}
          render={(props) => (
            <EditIntegrationAssetWorkspace
              {...props}
              isLoading={integrationAssetCompaniesLoading}
              editIntegrationAssetWorkspace={editIntegrationAssetWorkspaceCallback}
              integrationAssetWorkspaces={integrationAssetWorkspaces}
              integrationAssetWorkspaceId={props.match.params.integrationAssetWorkspaceId}
              navAdminType={navAdminType}
              integrationAssetCompanies={integrationAssetCompanies}
              integrationAssetCompanyId={props.match.params.integrationAssetCompanyId}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/events`}
          render={(props) => (
            <>
              <IntegrationEventTypes
                {...props}
                integrationEventTypes={integrationEventTypes}
                isLoading={integrationEventTypesLoading}
                hasError={integrationEventTypesDetailsError}
                errorDetails={integrationEventTypesErrorDetails}
                deleteIntegrationEventType={deleteIntegrationEventTypeCallback}
                editIntegrationEventType={editIntegrationEventTypeCallback}
                systemAdmin={systemAdmin}
                navAdminType={navAdminType}
              />
            </>
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/addevent`}
          render={(props) => (
            <AddIntegrationEventType
              {...props}
              addNewIntegrationEventType={addIntegrationEventTypeCallback}
              navAdminType={navAdminType}
            />
          )}
        />
        <Route
          exact
          path={`/${navAdminType}admin/integrationassets/:integrationEventTypeId/editevent`}
          render={(props) => (
            <EditIntegrationEventType
              {...props}
              isLoading={integrationEventTypesLoading}
              editIntegrationEventType={editIntegrationEventTypeCallback}
              integrationEventTypes={integrationEventTypes}
              integrationEventTypeId={props.match.params.integrationEventTypeId}
              navAdminType={navAdminType}
            />
          )}
        />
        {veracityClientId && assetId && (
          <Route
            exact
            path={`/${navAdminType}admin/integrationassets/:integrationAssetId/edit/adddatachannel`}
            render={(props) => (
              <AddIntegrationAssetTopic
                {...props}
                integrationAssetId={assetId}
                veracityAssetId={veracityClientId}
                addNewIntegrationAssetTopic={addIntegrationAssetTopicCallback}
                navAdminType={navAdminType}
              />
            )}
          />
        )}
        {veracityClientId && assetId && (
          <Route
            exact
            path={`/${navAdminType}admin/integrationassets/:integrationAssetId/edit/addschemas`}
            render={(props) => (
              <AddIntegrationAssetIssuers
                {...props}
                integrationAssetId={assetId}
                veracityAssetId={veracityClientId}
                addNewIntegrationAssetIssuers={addIntegrationAssetIssuersCallback}
                navAdminType={navAdminType}
              />
            )}
          />
        )}
        {veracityClientId && assetId && assetCompany && (
          <Route
            exact
            path={`/${navAdminType}admin/integrationassets/:integrationAssetId/edit/addpermissions`}
            render={(props) => (
              <AddIntegrationAssetPermissions
                {...props}
                integrationAssetId={assetId}
                veracityAssetId={veracityClientId}
                integrationAssetCompany={assetCompany}
                addNewIntegrationAssetPermissions={addIntegrationAssetPermissionsCallback}
                navAdminType={navAdminType}
              />
            )}
          />
        )}
      </Switch>
    );
  } else {
    return null;
  }
};

export default IntegrationAssetContainer;
