import { FleetVesselWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const FLEETVESSEL_LOADING = 'fleetVessel/loading';
export type FLEETVESSEL_LOADING = typeof FLEETVESSEL_LOADING;

export const GET_FLEETVESSEL = 'fleetVessel/get';
export type GET_FLEETVESSEL = typeof GET_FLEETVESSEL;

export const FLEETVESSEL_FETCH_ERROR = 'fleetVessel/fetchError';
export type FLEETVESSEL_FETCH_ERROR = typeof FLEETVESSEL_FETCH_ERROR;

export const ADD_FLEETVESSEL = 'fleetVessel/add';
export type ADD_FLEETVESSEL = typeof ADD_FLEETVESSEL;

export const FLEETVESSEL_ADD_ERROR = 'fleetVessel/addError';
export type FLEETVESSEL_ADD_ERROR = typeof FLEETVESSEL_ADD_ERROR;

export const FLEETVESSEL_FETCH_SUCCESS = 'fleetVessel/fetchSuccess';
export type FLEETVESSEL_FETCH_SUCCESS = typeof FLEETVESSEL_FETCH_SUCCESS;

export const DELETE_FLEETVESSEL = 'fleetVessel/delete';
export type DELETE_FLEETVESSEL = typeof DELETE_FLEETVESSEL;

export const EDIT_FLEETVESSEL = 'fleetVessel/edit';
export type EDIT_FLEETVESSEL = typeof EDIT_FLEETVESSEL;

export const FLEETVESSEL_EDIT_ERROR = 'fleetVessel/editError';
export type FLEETVESSEL_EDIT_ERROR = typeof FLEETVESSEL_EDIT_ERROR;

export const SETCHARTERABLE_FLEETVESSEL = 'fleetVessel/charterable';
export type SETCHARTERABLE_FLEETVESSEL = typeof SETCHARTERABLE_FLEETVESSEL;

export const FLEETVESSEL_SETCHARTERABLE_ERROR = 'fleetVessel/charterableError';
export type FLEETVESSEL_SETCHARTERABLE_ERROR = typeof FLEETVESSEL_SETCHARTERABLE_ERROR;

export const FLEETVESSEL_CLEAR_TOAST = 'fleetVessel/clearToast';
export type FLEETVESSEL_CLEAR_TOAST = typeof FLEETVESSEL_CLEAR_TOAST;

export type FleetVesselActions =
  | {
      type: GET_FLEETVESSEL;
      payload: FleetVesselWrapperModel['data'];
    }
  | {
      type: FLEETVESSEL_LOADING;
    }
  | {
      type: FLEETVESSEL_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_FLEETVESSEL;
      payload: FleetVesselWrapperModel['data'];
    }
  | {
      type: FLEETVESSEL_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: FLEETVESSEL_FETCH_SUCCESS;
    }
  | {
      type: DELETE_FLEETVESSEL;
      payload: FleetVesselWrapperModel['data'];
    }
  | {
      type: EDIT_FLEETVESSEL;
      payload: FleetVesselWrapperModel['data'];
    }
  | {
      type: FLEETVESSEL_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: SETCHARTERABLE_FLEETVESSEL;
      payload: FleetVesselWrapperModel['data'];
    }
  | {
      type: FLEETVESSEL_SETCHARTERABLE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: FLEETVESSEL_CLEAR_TOAST;
    };
