import { IntegrationAssetWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_LOADING = 'asset/loading';
export type INTEGRATION_ASSET_LOADING = typeof INTEGRATION_ASSET_LOADING;

export const GET_INTEGRATION_ASSETS = 'asset/get';
export type GET_INTEGRATION_ASSETS = typeof GET_INTEGRATION_ASSETS;

export const INTEGRATION_ASSETS_FETCH_ERROR = 'asset/fetchError';
export type INTEGRATION_ASSETS_FETCH_ERROR = typeof INTEGRATION_ASSETS_FETCH_ERROR;

export const DELETE_INTEGRATION_ASSET = 'asset/delete';
export type DELETE_INTEGRATION_ASSET = typeof DELETE_INTEGRATION_ASSET;

export const DELETE_INTEGRATION_ASSET_ERROR = 'asset/deleteError';
export type DELETE_INTEGRATION_ASSET_ERROR = typeof DELETE_INTEGRATION_ASSET_ERROR;

export const INTEGRATION_ASSET_EDIT_MODE = 'asset/editMode';
export type INTEGRATION_ASSET_EDIT_MODE = typeof INTEGRATION_ASSET_EDIT_MODE;

export const UPDATE_INTEGRATION_ASSET = 'asset/update';
export type UPDATE_INTEGRATION_ASSET = typeof UPDATE_INTEGRATION_ASSET;

export const UPDATE_INTEGRATION_ASSET_ERROR = 'asset/updateError';
export type UPDATE_INTEGRATION_ASSET_ERROR = typeof UPDATE_INTEGRATION_ASSET_ERROR;

export const ADD_INTEGRATION_ASSET = 'asset/add';
export type ADD_INTEGRATION_ASSET = typeof ADD_INTEGRATION_ASSET;

export const ADD_INTEGRATION_ASSET_ERROR = 'asset/addError';
export type ADD_INTEGRATION_ASSET_ERROR = typeof ADD_INTEGRATION_ASSET_ERROR;

export const INTEGRATION_ASSET_CLEAR_TOAST = 'asset/clearToast';
export type INTEGRATION_ASSET_CLEAR_TOAST = typeof INTEGRATION_ASSET_CLEAR_TOAST;



export type IntegrationAssetActions =
  | {
      type: GET_INTEGRATION_ASSETS;
      payload: IntegrationAssetWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_LOADING;
    }
  | {
      type: INTEGRATION_ASSETS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_INTEGRATION_ASSET;
      payload: IntegrationAssetWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: UPDATE_INTEGRATION_ASSET;
      payload: IntegrationAssetWrapperModel['data'];
    }
  | {
      type: UPDATE_INTEGRATION_ASSET_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_INTEGRATION_ASSET;
      payload: IntegrationAssetWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_ASSET_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_EDIT_MODE;
      payload: boolean;
    }
  | {
      type: INTEGRATION_ASSET_CLEAR_TOAST;
    }

