import React from 'react';
import {Formik} from 'formik';
import IntegrationAssetForm from '../IntegrationAssetForm/IntegrationAssetForm';

import { initialValues} from '../FormInitialValues/integrationAssetFormInitialValues';
import integrationAssetFormValidation from '../FormValidations/integrationAssetFormValidation';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';

interface OwnProps {
    addIntegrationAsset: (integrationAsset: IntegrationAsset) => void;
    header: string;
    integrationAsset?: IntegrationAsset;
    systemAdmin?: boolean;
    navAdminType: string;
}

const FormikWrapper: React.FC<OwnProps> = ({
    addIntegrationAsset,
    header,
    integrationAsset,
    systemAdmin,
    navAdminType,
}) => {
    const submitIntegrationAsset = (integrationAsset: IntegrationAsset) => {
        addIntegrationAsset(integrationAsset);
    }


    return(
        <Formik
        initialValues={integrationAsset ? integrationAsset : initialValues}
        validationSchema = {integrationAssetFormValidation}
        onSubmit={(values, actions: any) => {
            actions.validateForm();
            actions.setSubmitting(false);
        }}
        >
        {(formikProps) => (
            <IntegrationAssetForm
                header= {header}
                saveMethod={submitIntegrationAsset}
                integrationAsset={integrationAsset}
                formikProps={formikProps}
                systemAdmin={systemAdmin}
                navAdminType={navAdminType}
            />
        )}

        </Formik>
    );
};

export default React.memo(FormikWrapper);