import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getCompanies } from '../../../../../store/Company/actions';
import { getReportTypes } from '../../../../../store/ReportTypes/actions';
import { Company } from '../../../../../store/Company/models';
import { getRoles } from '../../../../../store/Roles/actions';
import { Role } from '../../../../../store/Roles/models';
import { Report,ReportReference } from '../../../../../store/Reports/models';
import { ReportType } from '../../../../../store/ReportTypes/models';
import { Category } from '../../../../../store/Category/models';
import { getAuth } from '../../../../../store/Auth/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { RoleType } from '../../../../../models/RoleType';
import usePrevious from '../../../../../helpers/usePrevious';
import { getFleetVessels } from '../../../../../store/CompanyFleet/actions';
import {
  renderTextInput,
  renderComplexDropdown,
  renderComplexMultiselectDropdown,
  renderDropdown,
  renderReportsDropdown,
  renderCheckbox,
  renderLabel,
  renderRadioGroup,
  renderRadio,
  renderSimpleDropdown,
} from '../../../../Form/FormInputs';
import reportConfigs from './reportConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import _ from 'lodash';
import { addNameValuesForCompanies } from '../../../../../helpers/addNameValuesForCompanies';
import { addNameValuesForCompanyCategories } from '../../../../../helpers/addNameValuesForCompanyCategories';
import { addNameValuesForUserRoles } from '../../../../../helpers/addNameValuesForUserRoles';



const StyledInnerForm = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
  margin-top: 1em;
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    flex: 1;
    justify-content: flex-end;
  }
  div[class^='input-'] {
    flex: 0 1 40%;
    margin-right: 35px;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
`;

const StyledFormSectionHeader = styled.div`
  font-size: 22px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 0.75em;
`;

const StyledReportReference = styled.div`
  display: flex;
  flex-direction: row;
  div {
    flex: 1;
  }
`;

interface OwnProps {
  report?: Report;
  header: string;
  saveMethod: (report: Report) => void;
  systemAdminPage?: boolean;
  companyAdminForm?: boolean;
  company?: Company[];
  companyCategory?: Category[];
  selectedRole?: Role[];
  selectedReportType?: ReportType;
  formikProps: FormikProps<Report>;
  navAdminType: string;
}

const ReportForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  report,
  systemAdminPage = false,
  companyAdminForm = false,
  company,
  selectedRole,
  companyCategory,
  selectedReportType,
  navAdminType,
  formikProps,
}) => {
  const {
    companies,
    companiesIsLoaded,
    companiesLoading,
    companiesError,
    companiesErrorDetails,

    categories,

    roles,
    rolesIsLoaded,
    rolesLoading,
    rolesError,
    rolesErrorDetails,

    authLoading,
    authError,
    authErrorDetails,
    authIsLoaded,

    reportTypeLoading,
    reportTypeError,
    reportTypeErrorDetails,
    reportType,
    reportTypeIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    companiesLoading: state.companies.loading,
    companiesError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,

    categories: state.categories.data,

    roles: state.roles.data,
    rolesIsLoaded: state.roles.dataIsLoaded,
    rolesLoading: state.roles.loading,
    rolesError: state.roles.error,
    rolesErrorDetails: state.roles.errorDetails,

    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    reportTypeLoading: state.report_types.loading,
    reportTypeError: state.report_types.error,
    reportTypeErrorDetails: state.report_types.errorDetails,
    reportType: state.report_types.data,
    reportTypeIsLoaded: state.report_types.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const getAllReportTypesCallback = useCallback(() => dispatch(getReportTypes()), []);

  const getAllCompaniesCallback = useCallback(() => dispatch(getCompanies()), []);

  const getAllRolesCallback = useCallback(() => dispatch(getRoles()), []);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getAllCompaniesCallback();
    }
  }, [companiesIsLoaded]);

  useEffect(() => {
    if (!rolesIsLoaded) {
      getAllRolesCallback();
    }
  }, [rolesIsLoaded]);

  useEffect(() => {
    if (!reportTypeIsLoaded) {
      getAllCompaniesCallback();
    }
  }, [reportTypeIsLoaded]);

  useEffect(() => {
    //calculating company categories
    const companyCategories = _.uniqBy(
      companies.map((x) => x.category),
      'categoryName',
    );
    setComapnyCategory(companyCategories);
  }, [companies]);

  //States for storing form answers
  const newCompanies = formikProps.values.companies;
  const newCompanyCategory = formikProps.values.companyCategories;
  const newFleetVessel = undefined;
  const allOption={value:'All',label:'All'};


  //State and methods for roles
  const role = undefined;
  const [roleArray, setRoleArray] = React.useState<Role[]>();
  const [companiesArray, setCompaniesArray] = React.useState<Company[]>();
  const prevRoleArray = usePrevious(roleArray);
  const [systemAdmin, setSystemAdmin] = React.useState<Role>();
  const [companyAdmin, setCompanyAdmin] = React.useState<Role>();
  const [reportTypeArray, setReportTypeArray] = React.useState<ReportType[]>();
  const [reportReference, setReportReference] = React.useState(''); // URL or ID
  const [comapnyCategories, setComapnyCategory] = React.useState<Category[]>(); // Shipowner or Supplier

  //Init roles if edit
  useEffect(() => {
    if(report!= undefined){
      // for edit load companies, roles and companyCategories objects again from ids
      report.companyCategories = categories.filter((x) => report.companyCategoriesIds.includes( x.id ));
      //@ts-ignore
      report.companies = companies.filter((x) => report.companiesIds.includes( x.companyId ));
      report.roles = roles.filter((x) => report.rolesIds.includes( x.id ));
    }
    
    if (companyCategory) {
      formikProps.setFieldValue('companyCategories', addNameValuesForCompanyCategories(companyCategory));
    }
    if (company) {
      formikProps.setFieldValue('companies', addNameValuesForCompanies(company));
    }
    if (selectedReportType) {
      formikProps.setFieldValue('type', selectedReportType);
    }
    if(report!= undefined){
      //@ts-ignore
      setReportReference(report?.reference);
      formikProps.setFieldValue('reference', report?.reference == ReportReference.URL? ReportReference.URL: ReportReference.ID);
    }else{
      setReportReference(ReportReference.URL);
      formikProps.setFieldValue('reference', ReportReference.URL);
    }
    setTimeout(() => {
      if(header=="Edit report")
      {
        reloadCompanyCategoriesDropDownValues();
      }
    }, 300);
  }, []);

  const reloadCompanyCategoriesDropDownValues = () => {
    //need to load drop down values after this time interval
    if (companyCategory) {
      formikProps.setFieldValue('companyCategories', addNameValuesForCompanyCategories(companyCategory));
    }else if (companyCategory == undefined){
      formikProps.setFieldValue('companyCategories', [allOption]);
    }
    setTimeout(() => {
      reloadCompaniesDropDownValues();
    }, 300);
  }

  const reloadCompaniesDropDownValues = () => {
    if (company) {
      formikProps.setFieldValue('companies', addNameValuesForCompanies(company));
    }else if (company == undefined){
      formikProps.setFieldValue('companies', [allOption]);
    }
    setTimeout(() => {
      reloadRolesDropDownValues();
    }, 300);
  }

  const reloadRolesDropDownValues = () => {
    if (selectedRole) {
      formikProps.setFieldValue('roles', addNameValuesForUserRoles(selectedRole));
    }else if (selectedRole == undefined){
      formikProps.setFieldValue('roles', [allOption]);
    }
    
  }

  //const prevCompany = usePrevious(newCompany);
  useEffect(() => {
    setRoleArray(getRolesForCompany());
  }, [newCompanies]);

  useEffect(() => {
    setRoleArray(getRolesForCompany());
  }, [roles]);

  useEffect(() => {
    const companiesArray=getCompaniesForCategory(); 
    setCompaniesArray(companiesArray);
  }, [newCompanyCategory]);

  const prevRole = usePrevious(role);
  useEffect(() => {
    if (report) {
      formikProps.setFieldTouched('role');
    }
  }, [role]);

  useEffect(() => {
    formikProps.setFieldValue('role', []);
  }, [roleArray]);

  //Getting roles for saving
  const getRolesArray = () => {
    const arr: Role[] = [];
    if (systemAdmin) arr.push(systemAdmin);
    if (companyAdmin) arr.push(companyAdmin);
    if (role) {
      // @ts-ignore
      arr.push(role);
    }

    return arr;
  };

  const isSupplierRole = (roleToCheck: Role) =>
    roleToCheck.roleType === RoleType.Operator ||
    roleToCheck.roleType === RoleType.Commercial ||
    roleToCheck.roleType === RoleType.BackOffice ||
    roleToCheck.roleType === RoleType.BunkerVesselCrew;

  const isShipownerOrChartererRole = (roleToCheck: Role) =>
    roleToCheck.roleType === RoleType.VesselOperator ||
    roleToCheck.roleType === RoleType.Procurement ||
    roleToCheck.roleType === RoleType.Administrative ||
    roleToCheck.roleType === RoleType.RecVesselCrew;

  //Get companies based on selected category
  const getCompaniesForCategory = () => {
    //Check if company admin rest is null checks, might need to break them out
    if (newCompanyCategory && newCompanyCategory.length>0) {
      //@ts-ignore
      const currentCategoryNames = newCompanyCategory.map((cat)=> cat.categoryName);
      return companies.filter((x) => currentCategoryNames.includes( x.category.categoryName ));
    } else {
      //Backup return empty array
      return [];
    }
  };

  //Get roles for dropdown
  const getRolesForCompany = () => {
    //Check if company admin rest is null checks, might need to break them out
    if (newCompanies && newCompanies.length>0) {

      const companyDropDownAllValues = companiesArray && companiesArray.length > 0 ? companiesArray : companies;
      const isAllCompaniesSelected = newCompanies.length==1 && newCompanies[0].value=='All';
      const currentCategoryList = isAllCompaniesSelected ? companyDropDownAllValues.map(x => x.category.categoryName) : newCompanies.map(x => x.category.categoryName);
      
      //SUPPLIER
      const supplierRoles = roles.filter((role) => isSupplierRole(role));
      
      //SHIPOWNER/CHARTERE
      const shipownerRoles= roles.filter((role) => isShipownerOrChartererRole(role));

      //Backup return shipowner or charterer
      var myRoles: Role[]=[];
      if(currentCategoryList.includes('Supplier')){
        supplierRoles.forEach(function (val, index) {
          myRoles.push(val);
        });

      }
      if(currentCategoryList.includes('Shipowner') || currentCategoryList.includes('Charterer')){
        shipownerRoles.forEach(function (val, index) {
          myRoles.push(val);
        });
      }

      return myRoles;
    }
    else {
      //Backup return empty array
      return [];
    }
  };

  //const renderCheckbo

  const onFormSave = () => {

    formikProps.submitForm();

    const reportToSave: Report = {
      ...formikProps.values,
      //active: true,
    };
    if (report) {
      reportToSave.id = report.id;
    }
    //only send ids for companies, roles and categories
    //@ts-ignore
    reportToSave.companiesIds= reportToSave.companies.map(x=> x.value);
    //@ts-ignore
    reportToSave.rolesIds = reportToSave.roles.map(x =>x.value);
    //@ts-ignore
    reportToSave.companyCategoriesIds = reportToSave.companyCategories.map(x =>x.value);

    // placeholder we send to backend for all values
    const ALLValuesPlaceHolder: string[] = [];

    const isAllCompaniesSelected = Object.keys(reportToSave.companiesIds).length==1 && reportToSave.companiesIds[0]=='All';
    if(isAllCompaniesSelected){
      reportToSave.companiesIds= ALLValuesPlaceHolder;
    }

    const isAllRolesSelected = Object.keys(reportToSave.rolesIds).length==1 && reportToSave.rolesIds[0]=='All';
    if(isAllRolesSelected){
      reportToSave.rolesIds= ALLValuesPlaceHolder;
    }

    const isAllCategoriesSelected = Object.keys(reportToSave.companyCategoriesIds).length==1 && reportToSave.companyCategoriesIds[0]=='All';
    if(isAllCategoriesSelected){
      reportToSave.companyCategoriesIds= ALLValuesPlaceHolder;
    }

    if (formikProps.isValid) {
      saveMethod(reportToSave);
    }
  };

  const handleReportReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportReference(e.target.value as ReportReference);
    
    const reportData: Report = {
      ...formikProps.values,
    };
    // reset url or entityId,reportId based on report reference
    if(reportReference == ReportReference.URL){
      formikProps.setFieldValue('entityId', '');
      formikProps.setFieldValue('reportId', '');
    }else if(reportReference == ReportReference.ID){
      formikProps.setFieldValue('url', '');
    }
    formikProps.setFieldValue('reference', e.target.value);
  };

  const renderReportReference = (name: string, config: object) => {
    return (
      <div className={`input-${name}`}>
        <StyledFormSectionHeader>Report reference</StyledFormSectionHeader>
        <StyledReportReference>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" value="ID" onChange={handleReportReferenceChange} checked={reportReference == ReportReference.ID? true : false } /> Use ID
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="radio" name="radio1" value="URL" onChange={handleReportReferenceChange} checked={reportReference == ReportReference.URL? true : false } /> Use
              URL
            </Label>
          </FormGroup>
        </StyledReportReference>
      </div>
    );
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: reportConfigs, errors, touched, setFieldValue, setFieldTouched };

  if (companiesLoading || authLoading || rolesLoading) {
    return <Loading type="report form" />;
  } else if (companiesError && companiesErrorDetails) {
    return <Error error={companiesErrorDetails} />;
  } else if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else if (rolesError && rolesErrorDetails) {
    return <Error error={rolesErrorDetails} />;
  } else {
    
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save report"
          cancelButtonText="Cancel"
          formUrl="reports"
        />
        <StyledInnerForm as={Form}>
          <StyledFormSectionHeader>Report title and description</StyledFormSectionHeader>
          {renderTextInput('name', false, props)}
          {renderTextInput('description', false, props)}

          {renderReportReference('reference', props.config)}
          {reportReference == 'URL' && renderTextInput('url', false, props)}
          <StyledReportReference>
            {reportReference == 'ID' && renderTextInput('entityId', false, props)}
            {reportReference == 'ID' && renderTextInput('reportId', false, props)}
          </StyledReportReference>
          <StyledReportReference>
              {reportReference == 'ID' && renderTextInput('powerBiReportId', false, props)}
              {reportReference == 'ID' && renderTextInput('powerBiReportRoleType', false, props)}
          </StyledReportReference>
          {renderCheckbox('active', false , props)}
          <StyledFormSectionHeader>Who will see the report</StyledFormSectionHeader>

          {renderReportsDropdown('companyCategories', comapnyCategories, false,true, true, {
            config: props.config,
            disabledDropdowns: null,
            isDisabled: false,
            setFieldValue,
            setFieldTouched,
          })}
          {renderReportsDropdown(
            'companies',
            companiesArray && companiesArray.length > 0 ? companiesArray : companies, false, true, true, {
            config: props.config,
            disabledDropdowns: null,
            isDisabled: false,
            setFieldValue,
            setFieldTouched,
          })}
          {renderReportsDropdown('roles', roleArray, false, true, true, {
            config: props.config,
            disabledDropdowns: null,
            isDisabled: false,
            setFieldValue,
            setFieldTouched,
          })}
          <StyledFormSectionHeader>Report type</StyledFormSectionHeader>
          {renderComplexDropdown('type', reportType, false, 'name', props)}
          {renderCheckbox('openInNewTab', false, props)}
        </StyledInnerForm>
      </StyledForm>
    );
  }
};

export default React.memo(ReportForm);
