import { ErrorActionPayload } from '../../models/ErrorAction';
import { FieldPermissionRecurringWrapperModel } from './models';

export const FIELDPERMISSIONRECURRING_LOADING = 'fieldPermissionRecurring/loading';
export type FIELDPERMISSIONRECURRING_LOADING = typeof FIELDPERMISSIONRECURRING_LOADING;

export const GET_FIELDPERMISSIONRECURRING = 'fieldPermissionRecurring/get';
export type GET_FIELDPERMISSIONRECURRING = typeof GET_FIELDPERMISSIONRECURRING;

export const NOTIFY_FIELDPERMISSIONRECURRING = 'fieldPermissionRecurring/notify';
export type NOTIFY_FIELDPERMISSIONRECURRING = typeof NOTIFY_FIELDPERMISSIONRECURRING;

export const NOTIFY_FIELDPERMISSIONRECURRING_ERROR = 'fieldPermissionRecurring/notifyError';
export type NOTIFY_FIELDPERMISSIONRECURRING_ERROR = typeof NOTIFY_FIELDPERMISSIONRECURRING_ERROR;

export const FIELDPERMISSIONRECURRING_FETCH_ERROR = 'fieldPermissionRecurring/fetchError';
export type FIELDPERMISSIONRECURRING_FETCH_ERROR = typeof FIELDPERMISSIONRECURRING_FETCH_ERROR;

export type FieldPermissionActions =
  | {
      type: GET_FIELDPERMISSIONRECURRING;
      payload: FieldPermissionRecurringWrapperModel['data'];
    }
  | {
      type: FIELDPERMISSIONRECURRING_LOADING;
    }
  | {
      type: FIELDPERMISSIONRECURRING_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
