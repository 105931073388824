import { IntegrationAssetApiTrackerWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_APITRACKER_LOADING = 'integrationAssetApiTracker/loading';
export type INTEGRATION_ASSET_APITRACKER_LOADING = typeof INTEGRATION_ASSET_APITRACKER_LOADING;

export const GET_INTEGRATION_ASSET_APITRACKERS = 'integrationAssetApiTracker/get';
export type GET_INTEGRATION_ASSET_APITRACKERS = typeof GET_INTEGRATION_ASSET_APITRACKERS;

export const INTEGRATION_ASSET_APITRACKERS_FETCH_ERROR = 'integrationAssetApiTracker/fetchError';
export type INTEGRATION_ASSET_APITRACKERS_FETCH_ERROR = typeof INTEGRATION_ASSET_APITRACKERS_FETCH_ERROR;

export const ADD_INTEGRATION_ASSET_APITRACKER = 'integrationAssetApiTracker/add';
export type ADD_INTEGRATION_ASSET_APITRACKER = typeof ADD_INTEGRATION_ASSET_APITRACKER;

export const INTEGRATION_ASSET_APITRACKERS_ADD_ERROR = 'integrationAssetApiTracker/addError';
export type INTEGRATION_ASSET_APITRACKERS_ADD_ERROR = typeof INTEGRATION_ASSET_APITRACKERS_ADD_ERROR;

export const INTEGRATION_ASSET_APITRACKERS_DELETE_ERROR = 'integrationAssetApiTracker/deleteError';
export type INTEGRATION_ASSET_APITRACKERS_DELETE_ERROR = typeof INTEGRATION_ASSET_APITRACKERS_DELETE_ERROR;

export const INTEGRATION_ASSET_APITRACKERS_EDIT_ERROR = 'integrationAssetApiTracker/editError';
export type INTEGRATION_ASSET_APITRACKERS_EDIT_ERROR = typeof INTEGRATION_ASSET_APITRACKERS_EDIT_ERROR;

export const DELETE_INTEGRATION_ASSET_APITRACKER = 'integrationAssetApiTracker/delete';
export type DELETE_INTEGRATION_ASSET_APITRACKER = typeof DELETE_INTEGRATION_ASSET_APITRACKER;

export const EDIT_INTEGRATION_ASSET_APITRACKER = 'integrationAssetApiTracker/edit';
export type EDIT_INTEGRATION_ASSET_APITRACKER = typeof EDIT_INTEGRATION_ASSET_APITRACKER;

export const INTEGRATION_ASSET_APITRACKERS_CLEAR_TOAST = 'integrationAssetApiTrackers/ClearToast';
export type INTEGRATION_ASSET_APITRACKERS_CLEAR_TOAST = typeof INTEGRATION_ASSET_APITRACKERS_CLEAR_TOAST;


export type IntegrationAssetApiTrackerActions =
  | {
      type: GET_INTEGRATION_ASSET_APITRACKERS;
      payload: IntegrationAssetApiTrackerWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_APITRACKER_LOADING;
    }
  | {
      type: INTEGRATION_ASSET_APITRACKERS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_APITRACKERS_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_APITRACKERS_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_APITRACKERS_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_INTEGRATION_ASSET_APITRACKER;
      payload: IntegrationAssetApiTrackerWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_APITRACKER;
      payload: IntegrationAssetApiTrackerWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_ASSET_APITRACKER;
      payload: IntegrationAssetApiTrackerWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_APITRACKERS_CLEAR_TOAST;
    };
