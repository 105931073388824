import { findIconDefinition } from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, CustomInput } from 'reactstrap';
import styles from './SearchBar.module.scss';

const searchIcon = findIconDefinition({ prefix: 'fal', iconName: 'search' });

interface OwnProps {
  placeholder: string;
  value: string;
  onChange: (search: string) => void;
  isExclude: boolean;
  setIsExclude: (isExclude: boolean) => void;
}

const SearchBar: React.FC<OwnProps> = ({ value, placeholder, onChange, isExclude, setIsExclude }) => {
  const onclickIncludeHandle= () => {
    setIsExclude(false);
  }
  
  const onclickExcludeHandle = () => {
    setIsExclude(true);
  }

  return (
    <div className={styles['root']}>
       <input
       type="text"
       placeholder={placeholder}
       onChange={(e)=> onChange(e.target.value)}
       value={value}
       className={styles['search-input']}
       >
       </input>
       <div className={styles['button-selector']}>
       <Button color="white" className={isExclude ? styles['unclicked'] : styles ['clicked']} onClick={onclickIncludeHandle}>
         <h3 className={styles['btn-text']}> INCLUDE </h3>
       </Button>
       <Button color="white" className={isExclude ? styles['clicked'] : styles['unclicked']} onClick={onclickExcludeHandle}>
        <h3 className={styles['btn-text']}>  EXCLUDE </h3>
       </Button>     
       </div>
    </div>
  );
};

export default SearchBar;
