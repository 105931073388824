import React from 'react';
import { Nomination } from '../../../../store/Nomination/models';
import CountDown from '../CountDown/CountDown';
import NominationStatus from '../NominationStatus/NominationStatus';
import DeliveryModeIcon from '../DeliveryModeIcon/DeliveryModeIcon';
import Moment from 'react-moment';
import styles from './NominationTable.module.scss';
import Table from '../../../Table';
import { useHistory } from 'react-router-dom';

interface OwnProps {
  nominations: Nomination[];
  archived: boolean;
}

const NominationTable: React.FC<OwnProps> = ({ nominations, archived }) => {
  const history = useHistory();

  return (
    <Table
      headers={['Days left', 'Vessel', 'Port', 'Quantity', 'Delivery', 'Date']}
      numberOfRowsPerPagination={5}
      paginationOnBottom={true}
      noPagination={false}
      striped={false}
      hover={false}
    >
      {nominations.map((nom) =>
        nom ? (
          <tr
            key={nom._id}
            className={`${styles.tr} ${styles[nom.attributes.fbStatus]}`}
            onClick={(e) => {
              history.push(`/bunkerevents/${archived ? 'archived' : 'bunker'}/${nom.eventId}`);
            }}
          >
            <td className={`${styles.td} ${styles['status-container']}`}>
              <div className={styles['status-days']}>
                <CountDown date={nom.bst} />
              </div>
              {nom.attributes.fbStatus && (
                <div className={styles['status-fuelbossStatus']}>
                  <NominationStatus status={nom.attributes.fbStatus} />
                </div>
              )}
            </td>
            <td className={styles.td}>{nom.attributes.receivingShipName}</td>
            <td className={styles.td}>{nom.attributes.port}</td>
            <td className={styles.td}>{nom.amount} mt</td>
            <td className={styles.td}>
              <DeliveryModeIcon deliveryMode={nom.deliveryMode} />
            </td>
            <td className={styles.td}>
              <Moment date={nom.bst} format="DD MMM YYYY" />
            </td>
          </tr>
        ) : (
          <tr></tr>
        ),
      )}
    </Table>
  );
};

export default NominationTable;
