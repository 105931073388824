import * as TYPE from './types';
import { ApplicationState } from '..';

const errorMessage = (errorCode: number) => {
  switch (errorCode) {
  case 403:
      return 'Not authorized to do this action.';
  case 409:
      return "A BDNLocation already exsists with the same propterties. Please Check the table. If a problem stil exsist please contact Fuelboss.";
  default:
      return 'Something went wrong! We could not create the new BDN Location. Please contact FuelBoss support for more details.';
  }
};

export const bdnLocationsReducer = (
  state: ApplicationState['bdn_locations'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.BDNLocationsActions,
): ApplicationState['bdn_locations'] => {
  switch (action.type) {
    case TYPE.BDN_LOCATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_BDN_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.BDN_LOCATIONS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.BDN_LOCATIONS_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.UPDATE_BDN_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'locations edited',
          type: 'info',
        },
      };
    case TYPE.UPDATE_BDN_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: action.payload.message ? action.payload.message : "Sorry, can't update BDN location.",
          type: 'error',
        },
      };
      case TYPE.DELETE_BDN_LOCATIONS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
          toastData: {
            content: 'BDN Location succesfully deleted!',
            type: 'success',
          },
        };
      case TYPE.DELETE_BDN_LOCATIONS_ERROR:
        return {
          ...state,
          loading: false,
          error: false,
          toastData: {
            content: action.payload.message ? action.payload.message : "Sorry, can't delete BDN location.",
            type: 'error',
          },
        };
      case TYPE.ADD_BDN_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'BDN Location succesfully created!',
          type: 'success',
        },
      };
    case TYPE.ADD_BDN_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: errorMessage(action.payload.statusCode),
          type: 'error',
        },
      };
    default:
      return { ...state };
  }
};
