export default {
  name: {
    label: 'Report title',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
  description: {
    label: 'Report description',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
  },
  url: {
    label: 'Report URL',
    show: true,
    regex: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    placeholder: '',
  },
  companies: {
    label: 'Company',
    show: true,
    placeholder: 'Please select company',
  },
  roles: {
    label: 'User role',
    show: true,
    placeholder: 'Please select a role',
  },
  type: {
    label: 'Report type',
    show: true,
    placeholder: 'Please select report type',
  },
  openInNewTab: {
    label: 'Open in new tab?',
    show: true,
    placeholder: '',
  },
  subset: {
    label: 'Report type order',
    show: true,
    placeholder: 'Please select a subset',
  },
  showAddVessel: {
    label: 'Show "Add vessel" functionality?',
    show: true,
    placeholder: '',
  },
  reference: {
    label: 'Report reference',
    show: true,
    placeholder: '',
  },
  entityId: {
    label: 'Entity ID',
    show: true,
    placeholder: '',
  },
  reportId: {
    label: 'Report ID',
    show: true,
    placeholder: '',
    },
  powerBiReportId: {
      label: 'PowerBi report ID',
      show: true,
      placeholder: '',
  },
  powerBiReportRoleType: {
      label: 'PowerBi report role type',
      show: true,
      placeholder: '',
  },
  active: {
    label: 'Report is active',
    show: true,
    placeholder: '',
  },
  companyCategories: {
    label: 'Company type',
    show: true,
    placeholder: 'Please select company type',
  },
};
