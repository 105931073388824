import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import {IntegrationAssetCompany} from '../../../../../store/IntegrationAssetCompanies/models';

import { Role } from '../../../../../store/Roles/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';
import { Formik } from 'formik';
import IntegrationAssetCompanyForm from '../IntegrationAssetForm/IntegrationAssetCompanyForm'; 
import integrationAssetCompanyFormValidation from '../FormValidations/integrationAssetCompanyFormValidation';
import { initialValues } from '../FormInitialValues/integrationAssetCompanyFormInitialValues';

interface OwnProps {
  addNewIntegrationAssetCompany: (integrationAssetCompany: IntegrationAssetCompany) => void;
  navAdminType: string;
  integrationAssetCompany?: IntegrationAssetCompany;
}

const AddIntegrationAssetCompany: React.FunctionComponent<OwnProps> = ({ addNewIntegrationAssetCompany, navAdminType, integrationAssetCompany }) => {
  const submitIntegrationAssetCompany = (integrationAssetCompany: IntegrationAssetCompany) => {
    addNewIntegrationAssetCompany(integrationAssetCompany);
  };

  return (
    <Formik
      initialValues={ integrationAssetCompany? integrationAssetCompany : initialValues}
      validationSchema={integrationAssetCompanyFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetCompanyForm
          integrationAssetCompany={integrationAssetCompany}
          header={'Add integration asset company'}
          saveMethod={submitIntegrationAssetCompany}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationAssetCompany);
