/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
// import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { FuelBossLocation } from '../../../../../store/FuelbossLocations/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table/index';
import FuelBossTableContainer from '../../../../FuelBossTable';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
import styles from './Locations.module.scss';
import CsvDownloader from 'react-csv-downloader';

const PlusCircle = findIconDefinition({
  prefix: 'fal',
  iconName: 'plus-circle',
});
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

interface OwnProps {
  csvData: any;
  fbLocations: FuelBossLocation[];
  csvIsLoading: boolean;
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteLocation: (id: string) => void;
  bulkUpdate: (file: any) => void;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const Locations: React.FC<OwnProps> = ({
  csvData,
  fbLocations,
  csvIsLoading,
  isLoading,
  hasError,
  errorDetails,
  deleteLocation,
  bulkUpdate,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const headersNew: Array<Column<FuelBossLocation>> = React.useMemo(
    () => [
      {
        Header: 'Port abbreviation',
        accessor: 'portAbbreviation',
      },
      {
        Header: 'Port type',
        accessor: 'portType',
      },
      {
        Header: 'Area of Operation',
        accessor: 'areaOfOperation',
      },
      // {
      //   Header: 'Terminal',
      //   accessor: 'name',
      // },
      {
        Header: 'Port',
        accessor: 'port',
      },
      {
        Header: 'Country abbreviation',
        accessor: 'countryAbbreviation',
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      // {
      //   Header: 'Purpose',
      //   accessor: 'purpose',
      // },
      // {
      //   Header: 'Type',
      //   accessor: 'type',
      // },
      {
        Header: 'Longitude',
        accessor: 'longitude',
      },
      {
        Header: 'Latitude',
        accessor: 'latitude',
      },
      {
        Header: 'TimeZone',
        accessor: 'timezone',
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const location = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={location.locationId ? location.locationId : ''}
                deleteMethod={deleteLocation}
                header="Delete location"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const location = props.cell.row.original;
          return (
            <div className="edit-cell">
              <EditById redirectUrl={`/systemadmin/locations/${location.locationId}/edit`} />
            </div>
          );
        },
      },
    ],
    [],
  );
  const history = useHistory();
  const [fileContent, setFileContent] = useState();
  const [fileName, setFileName] = useState<string>('');
  const [fileIsValid, setFileIsValid] = useState<boolean>(true);
  const firstRowTemplate: string = 'PortAbbr;PortName;Country;Area of operation;prtType;lat;lon;CountryAbbr';

  const handleFile = (e: any) => {
    const content = e.target.result;
    // console.log(content);
    const validFirstFow = checkFile(content);
    setFileIsValid(validFirstFow);
    if (validFirstFow) {
      setFileContent(content);
    }
  };

  const checkFile = (content: any) => {
    const fileRows = content.split(/\r\n|\r|\n/);
    if (fileRows[0] != undefined) {
      const firstRow: string = fileRows[0];
      return firstRow.trim() == firstRowTemplate.trim();
    }
    return false;
  };

  const handleChangeFile = (fileList: any) => {
    // eslint-disable-next-line prefer-destructuring
    const file = fileList.files[0];
    if (file != undefined) {
      const fileData = new FileReader();
      fileData.onloadend = handleFile;
      setFileName(file.name);
      fileData.readAsText(file);
    }
  };

  const importMethod = () => {
    bulkUpdate(fileContent);
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    // @ts-ignore: Object is possibly 'null'.
    hiddenFileInput.current.click();
  };

  const csvHeaders = [
    { displayName: 'PortAbbr', id: 'portAbbreviaton' },
    { displayName: 'PortName', id: 'portName' },
    { displayName: 'Country', id: 'country' },
    { displayName: 'Area of operation', id: 'areaOfOperation' },
    { displayName: 'prtType', id: 'portType' },
    { displayName: 'lat', id: 'latitude' },
    { displayName: 'lon', id: 'longitude' },
    { displayName: 'CountryAbbr', id: 'countryAbbreviation' },
  ];

  if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <>
        <div className={styles['buttons']}>
          <div>{fileName}</div>
          <Button color="primary" size="lg" onClick={handleClick}>
            Choose file
          </Button>
          {fileIsValid ? null : (
            <div style={{ position: 'absolute', top: '4rem', right: '7rem' }}>
              {`File must be in the following format: ${firstRowTemplate}`}
            </div>
          )}
          <input
            ref={hiddenFileInput}
            type="file"
            accept=".csv"
            onChange={(e) => handleChangeFile(e.target)}
            style={{ display: 'none' }}
          />
          <Button
            color="primary"
            size="lg"
            onClick={() => importMethod()}
            disabled={!fileIsValid || fileName.length < 1}
          >
            Upload locations <FontAwesomeIcon icon={PlusCircle} />
          </Button>
          <CsvDownloader
            filename="FuelBoss_locations"
            separator=";"
            wrapColumnChar=""
            columns={csvHeaders}
            datas={csvData}
            prefix={new Date().toLocaleDateString('fr-CA')}
          >
            <Button color="primary" size="lg" disabled={csvIsLoading}>
              Export locations to .csv
            </Button>
          </CsvDownloader>
          <Button color="primary" size="lg" onClick={() => history.push(`/systemadmin/locations/add`)}>
            Add location <FontAwesomeIcon icon={PlusCircle} />
          </Button>
        </div>

        <FuelBossTableContainer
          className={styles['table']}
          headers={headersNew}
          tableRows={fbLocations}
          showPagination={true}
          defaultSelectedPage={defaultSelectedPage}
          setDefaultSelectedPage={setDefaultSelectedPage}
        />
      </>
    );
  }
};
export default React.memo(Locations);
