import * as TYPE from './types';

import { ApplicationState } from '..';

export const integrationAssetApiTrackerReducer = (
  state: ApplicationState['integration_assets_apitracker'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.IntegrationAssetApiTrackerActions,
): ApplicationState['integration_assets_apitracker'] => {
  switch (action.type) {
    case TYPE.INTEGRATION_ASSET_APITRACKER_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.INTEGRATION_ASSET_APITRACKERS_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.GET_INTEGRATION_ASSET_APITRACKERS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.INTEGRATION_ASSET_APITRACKERS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_INTEGRATION_ASSET_APITRACKER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Inbound connection succesfully created!',
          type: 'success',
        },
      };

    case TYPE.INTEGRATION_ASSET_APITRACKERS_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `Inbound connection already exist with this asset and topic. If not please contact Fuelboss support`
            : `We could not create the new user. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_INTEGRATION_ASSET_APITRACKER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Inbound connection edited',
          type: 'info',
        },
      };
    case TYPE.INTEGRATION_ASSET_APITRACKERS_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not edit the inbound connection. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    case TYPE.DELETE_INTEGRATION_ASSET_APITRACKER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Inbound connection succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.INTEGRATION_ASSET_APITRACKERS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not delete the inbound connection. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    default:
      return { ...state };
  }
};
