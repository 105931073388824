import { Refresher } from '@teqplay/chorus-components';
import { KeysMatching } from '@teqplay/chorus-components/dist/utils/generics';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface InfiniteScrollProps<T> {
  fetchNextPage: (page: number) => Promise<T[]>;
  uniqueKey: KeysMatching<T, string | undefined>;
  initialPage?: number;
  renderItem: (item: T) => React.ReactNode;
  filter?: (item: T) => boolean;
  refresh?: (page: number) => Promise<T[]>;
  refreshInterval?: number;
  bufferNextPage?: number;
  loadingComponent?: JSX.Element;
  refreshingComponent?: JSX.Element;
  loadFirstPageInitialy?: boolean;
  data: T[];
  pageSize?: number;
}

function InfiniteScroll<T>({
  fetchNextPage,
  uniqueKey,
  renderItem,
  initialPage = 0,
  bufferNextPage = 5,
  filter = undefined,
  refresh = undefined,
  refreshInterval = 1000 * 5,
  loadingComponent = undefined,
  refreshingComponent = undefined,
  data,
  pageSize = 20,
  loadFirstPageInitialy = true,
}: InfiniteScrollProps<T>): JSX.Element {
  //const [data, setData] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const loaderRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (loadFirstPageInitialy) {
      loadNextPage(initialPage);
    }
  }, []);

  useEffect(() => {
    if(data && pageSize && data.length == pageSize && !hasMore){
      //special case to reset hasmore when we have a full page of data and reset the page to 0
      setHasMore(true);
      setCurrentPage(0);
    }
  }, [data]);

  const loadNextPage = useCallback(
    async (page) => {
      if (loading || !hasMore) return;

      setLoading(true);
      try {
        const newData = await fetchNextPage(page);
        if (newData.length === 0) {
          setHasMore(false);
        } else {
          //setData((prevData) => [...prevData, ...newData]);
          setCurrentPage(page);
        }
      } catch (error) {
        setHasMore(false);
      } finally {
        setLoading(false);
      }
    },
    [fetchNextPage, loading, hasMore, currentPage],
  );

  const handleRefresh = useCallback(async () => {
    if (!data || data.length === 0) return;
    try {
      setRefreshing(true);
      const newData = await refresh?.(currentPage);
      if (typeof newData !== 'undefined' && newData.length > 0) {
        //setData(newData);
      }
    } catch (error) {
    } finally {
      setRefreshing(false);
    }
  }, [currentPage, data]);

  useEffect(() => {
    const observerDown = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadNextPage(currentPage + 1);
      }
    });

    if (loaderRef.current) {
      observerDown.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observerDown.unobserve(loaderRef.current);
      }
    };
  }, [loadNextPage, loaderRef.current]);

  return (
    <div>
      {refreshing && refreshingComponent && refreshingComponent}
      <Refresher
        onRefresh={handleRefresh}
        interval={refreshInterval}
        refreshOnMount={false}
        refreshOnFocus={false}
        refreshOnInterval={true}
      />
      {data &&
        data.map((item, index) => {
          const key = typeof item[uniqueKey] === 'string' ? ((item[uniqueKey] as unknown) as string) : undefined;
          if (!filter || filter(item)) {
            return (
              <div key={key}>
                {renderItem(item)}
                {index === data.length - bufferNextPage && <div ref={loaderRef} />}
              </div>
            );
          } else if (index === data.length - bufferNextPage) {
            return <div ref={loaderRef} />;
          }
        })}
      {data && data.length < bufferNextPage && <div ref={loaderRef} />}
      {<div>{loading && loadingComponent ? loadingComponent : loading && 'Loading more data...'}</div>}
      {!loading && !hasMore && <div>No more items found.</div>}
    </div>
  );
}
export default InfiniteScroll;
