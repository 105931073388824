export default {
    timeStamp : {
        label: 'Integration asset time stamp',
        show: false,
        placeholder: '',
    },
    assetType: {
        label: 'Integration asset type',
        show: true,
        placeholder: 'Please select asset type',
    },
    assetTypeId: {
        label: 'Integration asset type id',
        show: true,
        placeholder: "",
    },
    ownerCompanyId: {
        label: 'Integration asset company owner id',
        show: true,
        placeholder: '',
    },
    createdBy: {
        label: 'Created by',
        show: true,
        placeholder: "",
    },
    fbAssetId: {
        label: "Fuelboss integration asset id",
        show: true,
        placeholder: "",
    },
    fbAssetType: {
        label: 'Fuelboss integration asset type',
        show: true,
        placeholder: 'Please select asset type',
    },
    fbAssetPipeline: {
        label: "Fuelboss integration asset pipeline",
        show: true,
        placeholder: "",
    },
    fbAssetBunkerShip: {
        label: "Fuelboss integration asset bunkership",
        show: true,
        placeholder: "",
    },
    fbAssetReceivingship: {
        label: "Fuelboss integration asset receivingship",
        show: true,
        placeholder: "",
    },
    assetTopic: {
        label: 'Asset topic',
        show: true,
        placeholder: "",
    },
    veracityAssetId: {
        label: "Veracity asset id",
        show: true,
        placeholder: "",
    },
    integrationAsset: {
        label: "Integration asset",
        show: true,
        placeholder: 'Please select an asset',
    },
    integrationAssetTopic: {
        label: "Integration asset topic",
        show: true,
        placeholder: "Please select topic",
    },
    assetName: {
        label: 'Integration asset name',
        show: true,
        placeholder: '',
    },
    assetExternalName: {
        label: 'Integration asset external name',
        show: true,
        placeholder: '',
    },
    assetDescription: {
        label: 'Integration asset description',
        show: true,
        placeholder: '',
    },
    assetLocation: {
        label: 'Integration asset location',
        show: true,
        placeholder: '',
    },
    assetType: {
        label: 'Integration asset type',
        show: true,
        placeholder: 'Please select asset type',
    },
};