import { ErrorActionPayload } from '../../models/ErrorAction';
import { ChorusContractsWrapperModel } from './models';

export const CHORUSCONTRACTS_LOADING = 'choruscontracts/loading';
export type CHORUSCONTRACTS_LOADING = typeof CHORUSCONTRACTS_LOADING;

export const GET_CHORUSCONTRACTS = 'choruscontracts/get';
export type GET_CHORUSCONTRACTS = typeof GET_CHORUSCONTRACTS;

export const CHORUSCONTRACTS_FETCH_ERROR = 'choruscontracts/fetchError';
export type CHORUSCONTRACTS_FETCH_ERROR = typeof CHORUSCONTRACTS_FETCH_ERROR;

export type ChorusContractsActions =
  | {
      type: GET_CHORUSCONTRACTS;
      payload: ChorusContractsWrapperModel['data'];
    }
  | {
      type: CHORUSCONTRACTS_LOADING;
    }
  | {
      type: CHORUSCONTRACTS_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
