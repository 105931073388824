import * as TYPE from './types';

import { ApplicationState } from '..';

export const reportReducer = (
  state: ApplicationState['reports'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.ReportActions,
): ApplicationState['reports'] => {
  switch (action.type) {
    case TYPE.REPORT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.REPORTS_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.GET_REPORTS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.REPORTS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_REPORT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Report succesfully created!',
          type: 'success',
        },
      };

    case TYPE.REPORTS_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `Report already exist in FuelBoss. If not please contact Fuelboss support`
            : `We could not create the new user. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_REPORT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Report edited',
          type: 'info',
        },
      };
    case TYPE.REPORTS_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not edit the report. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    case TYPE.DELETE_REPORT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Report succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.REPORTS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not delete the report. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.TOGGLE_VISIBILITY_REPORT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Report visibility changed',
          type: 'info',
        },
      };

    case TYPE.REPORTS_TOGGLE_VISIBILITY_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not change the report visibility. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    default:
      return { ...state };
  }
};
