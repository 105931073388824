import { useState } from 'react';
import { string } from 'prop-types';

export const useNetworkSpeedCheck = () => {

  const getNetworkLatency = async () => {
    const startTime = new Date();
    const response = await fetch('/api/NetworkSpeedCheck/latencyTest/');
    const endTime = new Date();
    const latencyMiliSeconds = endTime.getTime() - startTime.getTime();
    return latencyMiliSeconds;
  };

  const getDonwloadSpeed = async (downloadSizeInMegaBytes: number) => {
    const fileSizeInKiloBytes = downloadSizeInMegaBytes * 1024;
    const fileSizeInBytes = fileSizeInKiloBytes * 1024;
    const baseUrl = '/api/NetworkSpeedCheck/downloadTest/' + String(fileSizeInBytes);

    const time_start = new Date().getTime();

    const header = new Headers();
    header.append('content-type', 'application/json');
    const fetchParams: RequestInit = {
      cache: 'no-cache',
      method: 'GET',
      mode: 'same-origin',
      headers: header,
    };
    const response = await fetch(baseUrl, fetchParams);
    if (response.ok) {
      const jsonValue = await response.json();
    }

    const end_time = new Date().getTime();

    var timeDuration = (end_time - time_start) / 1000;
    var loadedBits = fileSizeInBytes * 8;

    /* Converts a number into string
         using toFixed(2) rounding to 2 */
    const speedInbps = (loadedBits / timeDuration).toFixed(2);
    const speedInKbps = (Number(speedInbps) / 1024).toFixed(2);
    const speedInMbps = (Number(speedInKbps) / 1024).toFixed(2);
    /* alert("Your internet connection speed is: \n" 
            + speedInbps + " bps\n" + speedInKbps 
            + " kbps\n" + speedInMbps + " Mbps\n"); */
    return speedInKbps;
  };

  const getUploadSpeed = async (downloadSizeInMegaBytes: number) => {
    const fileSizeInKiloBytes = downloadSizeInMegaBytes * 1024;
    const fileSizeInBytes = fileSizeInKiloBytes * 1024;
    const baseUrl = '/api/NetworkSpeedCheck/uploadTest/';
    const uploadBytes = getRadmomString(
      fileSizeInBytes,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    );
    const uploadData = {
      file: uploadBytes,
    };
    const time_start = new Date().getTime();

    const header = new Headers();
    header.append('content-type', 'application/json');
    const fetchParams: RequestInit = {
      cache: 'no-cache',
      method: 'PUT',
      mode: 'same-origin',
      headers: header,
      body: JSON.stringify(uploadData),
    };
    const response = await fetch(baseUrl, fetchParams);
    if (response.ok) {
      const jsonValue = await response.json();
    }

    const end_time = new Date().getTime();

    var timeDuration = (end_time - time_start) / 1000;
    var loadedBits = fileSizeInBytes * 8;

    /* Converts a number into string
         using toFixed(2) rounding to 2 */
    const speedInbps = (loadedBits / timeDuration).toFixed(2);
    const speedInKbps = (Number(speedInbps) / 1024).toFixed(2);
    const speedInMbps = (Number(speedInKbps) / 1024).toFixed(2);

    return speedInKbps;
  };

  const getRadmomString = (length: number, charsString: string) => {
    var result = '';
    var chars = charsString.split('');
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  return { getNetworkLatency, getDonwloadSpeed, getUploadSpeed };
};
