import { url } from 'inspector';
import { useLocation } from 'react-router-dom';

export interface ConnectivityCheckUrls {
  fuelboss: string;
  chorus: string;
  signalR: string;
  mapbox: string;
  veracityNotifications: string;
  tqplayIcons: string;
}

export const useGetConnectivityURLs = () => {
  const location = useLocation();
  const pathName= window.location.hostname;
  var URLs: ConnectivityCheckUrls = {
    fuelboss:'',
    chorus: '',
    signalR: '',
    mapbox: '',
    veracityNotifications: '',
    tqplayIcons: '',
  };

  if (pathName.includes('dev') || pathName.includes('localhost')) {
    URLs.fuelboss='/account/GetProfileData';
    URLs.chorus = 'https://backendfuelbossdev.teqplay.nl/v1/profiles/currentUser';
    URLs.mapbox = 'https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2,mapbox.mapbox-streets-v7.json?secure&access_token=';
    URLs.signalR = '/signalrchat';
    URLs.veracityNotifications = '/api/ConnectivityCheck/checkVeracityNotificationsConnectivity';
    URLs.tqplayIcons = 'https://icons.teqplay.nl/font/teqplay-icons.woff';
  } else if (pathName.includes('test')) {
    URLs.fuelboss='/account/GetProfileData';
    URLs.chorus = 'https://backendfuelbosstest.teqplay.nl/v1/profiles/currentUser';
    URLs.mapbox = 'https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2,mapbox.mapbox-streets-v7.json?secure&access_token=';
    URLs.signalR = '/signalrchat';
    URLs.veracityNotifications = '/api/ConnectivityCheck/checkVeracityNotificationsConnectivity';
    URLs.tqplayIcons = 'https://icons.teqplay.nl/font/teqplay-icons.woff';
  } else {
    URLs.fuelboss='/account/GetProfileData';
    URLs.chorus = 'https://backendfuelboss.teqplay.nl/v1/profiles/currentUser';
    URLs.mapbox = 'https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2,mapbox.mapbox-streets-v7.json?secure&access_token=';
    URLs.signalR = '/signalrchat';
    URLs.veracityNotifications = '/api/ConnectivityCheck/checkVeracityNotificationsConnectivity';
    URLs.tqplayIcons = 'https://icons.teqplay.nl/font/teqplay-icons.woff';
  }
  return URLs;
}
