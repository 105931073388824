import { Dispatch } from 'redux';
import {
  ReportActions,
  REPORT_LOADING,
  REPORTS_FETCH_ERROR,
  GET_REPORTS,
  ADD_REPORT,
  REPORTS_ADD_ERROR,
  DELETE_REPORT,
  EDIT_REPORT,
  REPORTS_DELETE_ERROR,
  REPORTS_EDIT_ERROR,
  REPORTS_CLEAR_TOAST,
  REPORTS_TOGGLE_VISIBILITY_ERROR,
  TOGGLE_VISIBILITY_REPORT,
} from './types';
import { api } from '../../helpers/api';
import { ReportResponseModel, Report } from './models';
import { Role } from '../Roles/models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating report...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Report updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating report...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Report created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getReports = () => (dispatch: Dispatch<ReportActions>) => {
  dispatch({
    type: REPORT_LOADING,
  });

  new api<ReportResponseModel>({
    dispatch,
    errorType: REPORTS_FETCH_ERROR,
  })
    .get(`/api/VAPReport`)
    .then((response) => {
      dispatch({
        type: GET_REPORTS,
        payload: response,
      });
    });
};

export const addReport = (report: Report, onSuccess: () => void) => (
  dispatch: Dispatch<ReportActions>,
) => {
  dispatch({
    type: REPORT_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<ReportResponseModel>({
    dispatch,
    errorType: REPORTS_ADD_ERROR,
  })
    .post(`/api/VAPReport`, report)
    .then((response) => {
      dispatch({
        type: ADD_REPORT,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      /*dispatch({
        type: REPORTS_ADD_ERROR,
        payload: {
          clientFault: false,
          serverFault: true,
          statusCode: 405,
          message: 'Report with the current name already exist! Please contact FuelBoss support for more details',
        },
      });*/
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteReport = (id: string) => (dispatch: Dispatch<ReportActions>) => {
  dispatch({
    type: REPORT_LOADING,
  });

  const url = `/api/VAPReport/${id}`;
  new api<ReportResponseModel>({
    dispatch,
    errorType: REPORTS_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_REPORT,
        payload: response,
      });
    });
};


export const toggleReportVisibility = (id: string) => (dispatch: Dispatch<ReportActions>) => {
  dispatch({
    type: REPORT_LOADING,
  });

  const url = `/api/VAPReport/toggleVisibility/${id}`;
  new api<ReportResponseModel>({
    dispatch,
    errorType: REPORTS_TOGGLE_VISIBILITY_ERROR,
  })
    .put(url, id)
    .then((response) => {
      dispatch({
        type: TOGGLE_VISIBILITY_REPORT,
        payload: response,
      });
    });
};

export const editReport = (report: Report, onSuccess: () => void) => (
  dispatch: Dispatch<ReportActions>,
) => {
  dispatch({
    type: REPORT_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (report.id != undefined) {
    const url = `/api/VAPReport/${report.id}`;

    new api<ReportResponseModel>({
      dispatch,
      errorType: REPORTS_EDIT_ERROR,
    })
      .put(url, report)
      .then((response) => {
        dispatch({
          type: EDIT_REPORT,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const reportsClearToast = () => (dispatch: Dispatch<ReportActions>) => {
  dispatch({
    type: REPORTS_CLEAR_TOAST,
  });
};
