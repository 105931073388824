import {
  getNominationStatusIcon,
  INominationEnquiryEvent,
  IPromptNomination,
  isOneOf,
  toRelativeTime
} from '@teqplay/chorus-components'
import { DateTime } from 'luxon'
import React from 'react'
import { RecurringNomination } from '../../../../../store/RecurringNomination/models'

interface NegotiationStatusSummaryProps {
  negotiationItem?: IPromptNomination | INominationEnquiryEvent | RecurringNomination
}

const NegotiationStatusSummary: React.FunctionComponent<NegotiationStatusSummaryProps> = (props) => {
  const { negotiationItem } = props

  if (!negotiationItem) {
    return null
  }

  const timeUntilDelivery = negotiationItem && negotiationItem.bst
    ? toRelativeTime(DateTime.fromISO(negotiationItem.bst).toMillis())
    : undefined

  const showStateIcon = isOneOf(negotiationItem.state, ['CANCELLED', 'COMPLETED', 'FINALISED'])

  return (
    <div className="nomination-delivery-summary">
      {showStateIcon && <div>{getNominationStatusIcon(negotiationItem.state)}</div>}

      {timeUntilDelivery !== undefined && !showStateIcon && (
        <>
          <span>{timeUntilDelivery.value}</span>
          {timeUntilDelivery.unit}
        </>
      )}

      {timeUntilDelivery === undefined && !showStateIcon && <div>-</div>}
    </div>
  )
}

export default NegotiationStatusSummary
