import * as TYPE from './types';

import { ApplicationState } from '..';

export const chatReducer = (
  state: ApplicationState['chat'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.ChatActions,
): ApplicationState['chat'] => {
  switch (action.type) {
    case TYPE.CHAT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.CHAT_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_CHAT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.NOTIFY_CHAT:
        return {
            ...state,
            error: false,
        };
    case TYPE.NOTIFY_CHAT_ERROR:
        return {
            ...state,
            error: true,
            errorDetails: action.payload,
        };
    default:
      return { ...state };
  }
};
