import { IntegrationEventTypeWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_EVENT_TYPE_CLEAR_TOAST = 'integrationEventType/ClearToast';
export type INTEGRATION_EVENT_TYPE_CLEAR_TOAST = typeof INTEGRATION_EVENT_TYPE_CLEAR_TOAST;

export const INTEGRATION_EVENT_TYPE_LOADING = 'integrationEventType/loading';
export type INTEGRATION_EVENT_TYPE_LOADING = typeof INTEGRATION_EVENT_TYPE_LOADING;

export const GET_INTEGRATION_EVENT_TYPE = 'integrationEventType/get';
export type GET_INTEGRATION_EVENT_TYPE = typeof GET_INTEGRATION_EVENT_TYPE;

export const INTEGRATION_EVENT_TYPE_FETCH_ERROR = 'integrationEventType/fetchError';
export type INTEGRATION_EVENT_TYPE_FETCH_ERROR = typeof INTEGRATION_EVENT_TYPE_FETCH_ERROR;

export const ADD_INTEGRATION_EVENT_TYPE = 'integrationEventType/add';
export type ADD_INTEGRATION_EVENT_TYPE = typeof ADD_INTEGRATION_EVENT_TYPE;

export const EDIT_INTEGRATION_EVENT_TYPE = 'integrationEventType/edit';
export type EDIT_INTEGRATION_EVENT_TYPE = typeof EDIT_INTEGRATION_EVENT_TYPE;

export const DELETE_INTEGRATION_EVENT_TYPE = 'integrationEventType/delete';
export type DELETE_INTEGRATION_EVENT_TYPE = typeof DELETE_INTEGRATION_EVENT_TYPE;

export const INTEGRATION_EVENT_TYPE_ADD_ERROR = 'integrationEventType/addError';
export type INTEGRATION_EVENT_TYPE_ADD_ERROR = typeof INTEGRATION_EVENT_TYPE_ADD_ERROR;

export const INTEGRATION_EVENT_TYPE_EDIT_ERROR = 'integrationEventType/editError';
export type INTEGRATION_EVENT_TYPE_EDIT_ERROR = typeof INTEGRATION_EVENT_TYPE_EDIT_ERROR;

export const INTEGRATION_EVENT_TYPE_DELETE_ERROR = 'integrationEventType/deleteError';
export type INTEGRATION_EVENT_TYPE_DELETE_ERROR = typeof INTEGRATION_EVENT_TYPE_DELETE_ERROR;

export type IntegrationEventTypeActions =
  | {
      type: INTEGRATION_EVENT_TYPE_LOADING;
    }
  | {
      type: GET_INTEGRATION_EVENT_TYPE;
      payload: IntegrationEventTypeWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_EVENT_TYPE;
      payload: IntegrationEventTypeWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_EVENT_TYPE;
      payload: IntegrationEventTypeWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_EVENT_TYPE;
      payload: IntegrationEventTypeWrapperModel['data'];
    }
  | {
      type: INTEGRATION_EVENT_TYPE_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_EVENT_TYPE_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_EVENT_TYPE_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_EVENT_TYPE_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_EVENT_TYPE_CLEAR_TOAST;
    };
