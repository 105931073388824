import * as React from 'react';
import { useEffect, useState } from 'react';
import { SignFlowType } from '@teqplay/chorus-components';
import { SignFlow } from './SignFlow';

interface OwnProps {
  pathName: string;
  search: string;
  chorusBaseURL: string;
  loggedIn: boolean;
}

export const SignFlowContainer: React.FC<OwnProps> = ({ pathName, search, chorusBaseURL, loggedIn }) => {
  const signFlowType = pathName == '/sign/return' ? SignFlowType.POST_SIGNING : SignFlowType.PRE_SIGNING;
  const [signFlowToken, setSignFlowToken] = useState<string | null>(null);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    setSignFlowToken(token);
    //check if token is valid
    if (!token || token.split('.').length !== 3) {
      setIsValidToken(false);
      //token not valid redirect to signin
      if (!loggedIn) window.location.href = '/account/signin';
      else window.location.href = '/overview';
    } else {
      setIsValidToken(true);
    }
  }, []);

  if (!isValidToken) {
    return <></>;
  }
  return (
    <SignFlow
      chorusBaseURL={chorusBaseURL}
      loggedIn={loggedIn}
      signFlowType={signFlowType}
      signFlowToken={signFlowToken ? signFlowToken : ''}
    ></SignFlow>
  );
};
