import * as React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { DeliveryModes } from '../../../../../../models/DeliveryMode';
import ToggleButton from './ToggleButton/ToggleButton';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './ButtonContainer.module.scss';
import classnames from 'classnames';
import { ReactComponent as TruckIcon } from '../../../../../../svgs/truck-to-ship.svg';
import { ReactComponent as PipeIcon } from '../../../../../../svgs/pipe-to-ship-blue.svg';
import { ReactComponent as ShipIcon } from '../../../../../../svgs/ship-to-ship.svg';
import { ReactComponent as ContainerIcon } from '../../../../../../svgs/container-to-ship.svg';
import { pipeline } from 'stream';

interface OwnProps {
  toggle: (deliveryMode: DeliveryModes) => void;
  initialDeliveryModes: string[];
  addAsset: (event: any, deliveryMode: DeliveryModes) => void;
  getAssetCount: (deliveryMode: DeliveryModes) => number;
}

const ButtonContainer: React.FC<OwnProps> = ({ toggle, initialDeliveryModes, addAsset, getAssetCount }) => {
  const [ship, setShip] = useState(initialDeliveryModes.includes(DeliveryModes.Ship));
  const [truck, setTruck] = useState(initialDeliveryModes.includes(DeliveryModes.Truck));
  const [pipe, setPipe] = useState(initialDeliveryModes.includes(DeliveryModes.Pipe));
  const [container, setContainer] = useState(initialDeliveryModes.includes(DeliveryModes.Container));

  return (
    <div className={styles['delivery-mode-buttons']}>
      <ToggleButton initToggled={ship} onClick={() => toggle(DeliveryModes.Ship)}>
        <div className={classnames(styles.icondiv)}>
          <ShipIcon className={classnames(styles.icon, styles.ship)} />
          {ship && <div className={classnames(styles.count)}>{getAssetCount(DeliveryModes.Ship)}</div>}
        </div>
        <div className={classnames(styles.text)}>Ship to ship</div>
        <div className={classnames(styles.desc)}>
          {ship ? (
            <Button color="primary" onClick={(event) => addAsset(event, DeliveryModes.Ship)}>
              Add LBV
            </Button>
          ) : (
            'Add LBV to complete service offer'
          )}
        </div>
      </ToggleButton>
      <ToggleButton initToggled={truck} onClick={() => toggle(DeliveryModes.Truck)}>
        <div className={classnames(styles.icondiv)}>
          <TruckIcon className={classnames(styles.icon, styles.truck)} />
          {false && <div className={classnames(styles.count)}>{getAssetCount(DeliveryModes.Truck)}</div>}
        </div>

        <div className={classnames(styles.text)}>Truck to ship</div>
        <div className={classnames(styles.desc)}>Nothing to add</div>
      </ToggleButton>
      <ToggleButton initToggled={pipe} onClick={() => toggle(DeliveryModes.Pipe)}>
        <div className={classnames(styles.icondiv)}>
          <PipeIcon className={classnames(styles.icon, styles.pipe)} />
          {pipe && <div className={classnames(styles.count)}>{getAssetCount(DeliveryModes.Pipe)}</div>}
        </div>

        <div className={classnames(styles.text)}>Pipeline to ship</div>
        <div className={classnames(styles.desc)}>
          {pipe ? (
            <Button color="primary" onClick={(event) => addAsset(event, DeliveryModes.Pipe)}>
              Add pipeline
            </Button>
          ) : (
            'Add Pipeline to complete service offer'
          )}
        </div>
      </ToggleButton>
      <ToggleButton initToggled={container} onClick={() => toggle(DeliveryModes.Container)}>
        <div className={classnames(styles.icondiv)}>
          <ContainerIcon className={classnames(styles.icon, styles.container)} />{' '}
          {false && <div className={classnames(styles.count)}>{getAssetCount(DeliveryModes.Container)}</div>}
        </div>
        <div className={classnames(styles.text)}>Container to ship</div>
        <div className={classnames(styles.desc)}>Nothing to add</div>
      </ToggleButton>
    </div>
  );
};

export default ButtonContainer;
