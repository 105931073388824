import { PaginationMeta } from '../../helpers/PaginationMeta';
import { ErrorActionPayload } from '../../models/ErrorAction';
import { RecurringNomination, RecurringNominationWrapperModel } from './models';

export const RECURRINGNOMINATION_LOADING = 'recurringNomination/loading';
export type RECURRINGNOMINATION_LOADING = typeof RECURRINGNOMINATION_LOADING;

export const GET_RECURRINGNOMINATION = 'recurringNomination/get';
export type GET_RECURRINGNOMINATION = typeof GET_RECURRINGNOMINATION;

export const DELETE_RECURRINGNOMINATION = 'recurringNomination/delete';
export type DELETE_RECURRINGNOMINATION = typeof DELETE_RECURRINGNOMINATION;

export const RECURRINGNOMINATION_FETCH_ERROR = 'recurringNomination/fetchError';
export type RECURRINGNOMINATION_FETCH_ERROR = typeof RECURRINGNOMINATION_FETCH_ERROR;

export const RECURRINGNOMINATION_DELETE_ERROR = 'recurringNomination/deleteError';
export type RECURRINGNOMINATION_DELETE_ERROR = typeof RECURRINGNOMINATION_DELETE_ERROR;


export const UPDATE_RECURRINGNOMINATION = 'recurringNomination/update';
export type UPDATE_RECURRINGNOMINATION = typeof UPDATE_RECURRINGNOMINATION;

export const UPDATE_RECURRINGNOMINATION_ERROR = 'recurringNomination/updateError';
export type UPDATE_RECURRINGNOMINATION_ERROR = typeof UPDATE_RECURRINGNOMINATION_ERROR;

export const ADD_RECURRINGNOMINATION = 'recurringNomination/add';
export type ADD_RECURRINGNOMINATION = typeof ADD_RECURRINGNOMINATION;

export const ADD_RECURRINGNOMINATION_ERROR = 'recurringNomination/updateError';
export type ADD_RECURRINGNOMINATION_ERROR = typeof ADD_RECURRINGNOMINATION_ERROR;


export const RECURRINGNOMINATION_CLEAR_TOAST = 'recurringNomination/clearToast';
export type RECURRINGNOMINATION_CLEAR_TOAST = typeof RECURRINGNOMINATION_CLEAR_TOAST;

export type RecurringNominationActions =
  | {
      type: GET_RECURRINGNOMINATION;
      payload: { data: RecurringNomination[]; metaData?: PaginationMeta };
    }
  | {
      type: DELETE_RECURRINGNOMINATION;
      payload: RecurringNominationWrapperModel['data'];
    }
  | {
      type: RECURRINGNOMINATION_LOADING;
    }
  | {
      type: RECURRINGNOMINATION_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
    | {
      type: RECURRINGNOMINATION_CLEAR_TOAST
    }
  | {
      type: RECURRINGNOMINATION_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
    | {
      type: UPDATE_RECURRINGNOMINATION;
      payload: RecurringNominationWrapperModel['data'];
    }
  | {
      type: UPDATE_RECURRINGNOMINATION_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_RECURRINGNOMINATION;
      payload: RecurringNominationWrapperModel['data'];
    }
  | {
      type: ADD_RECURRINGNOMINATION_ERROR;
      payload: ErrorActionPayload;
    };
