import React from 'react';
import styles from './Message.module.scss';
import { ChatMessage } from '../../../../../store/Chat/models';
import { Auth } from '../../../../../store/Auth/models';
import Initials from './../../../../../helpers/Initials';
import { getNameColor } from '../../../../../helpers/getNameColor';

interface OwnProps {
  message: ChatMessage;
  index: number;
  loggedInUser: Auth;
}

export const Message: React.FC<OwnProps> = ({ message, index, loggedInUser }) => {
  const you = ((message || {}).user || {}).id === loggedInUser.userId;

  const formatTimestamp = () => {
    let timestamp = 'Unkown time';

    if (message.timeStamp) {
      const split = message.timeStamp.toString().split('T');
      timestamp = `${split[0].replace(new RegExp('-', 'g'), '.')}, ${split[1].substring(0, 5)}`;
    }

    return timestamp;
  };

  if (message.user) {
    return (
      <div className={`${styles['message-wrapper']} ${you ? styles['you'] : ''}`}>
        <div
          className={styles['user-image']}
          style={getNameColor(message.user.firstName) as React.CSSProperties}
          id={`user${message.chatId}`}
        >
          {Initials(message.user.firstName, message.user.lastName, true)}
        </div>
        <div className={styles['message']} key={index} id={`message${message.chatId}`}>
          <p className={styles['message-meta']}>
            <span className={styles['name']}>{message.user.firstName}{' '}{message.user.lastName} </span>{' '}
            <span className={styles['timestamp']}>{formatTimestamp()} UTC</span>
          </p>
          <p className={styles['message-text']}>{message.message}</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
