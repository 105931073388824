import { Message } from './Components/Message';
import styles from './Chat.module.scss';
import React, { useEffect, useRef, useState } from 'react'
import { ChatMessage } from './../../../../store/Chat/models';
import { Auth } from './../../../../store/Auth/models';
import { ReactComponent as SendIcon } from '../../../../svgs/paper-plane.svg';
import { CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';

import classnames from 'classnames';

interface OwnProps {
    type: string;
    connectionId: string;
    message: string;
    setMessage: (message: string) => void;
    messages: ChatMessage[];
    keyPressed: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    sendMessage: () => void;
    loggedInUser: Auth;
    className?: string;
    isChatDisabled: boolean;
    operationsPhase: boolean;
    setOperationsPhaseAction: (operationsPhase: boolean) => void;
}

const Chat: React.FC<OwnProps> = ({
    type,
    message,
    setMessage,
    messages,
    connectionId,
    keyPressed,
    sendMessage,
    loggedInUser,
    className,
    isChatDisabled,
    operationsPhase,
    setOperationsPhaseAction
}) => {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = () => {
        if (messagesEndRef.current !== null) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
        }
    }

    useEffect(scrollToBottom, [messages]);

    const gasPumpIcon = findIconDefinition({ prefix: 'fal', iconName: 'gas-pump' });
    const calendarIcon = findIconDefinition({ prefix: 'fal', iconName: 'calendar-alt' });
    const isNomination = type === "nomination";

    if (connectionId) {
        return (
            <div className={className}>
                <div className={styles['chat-header']}>
                    <div className={styles['chat-header-top']}>
                        <div className={styles['title']}>Chat</div>
                        {isNomination && !isChatDisabled && (
                            <div className={styles['sort-switch-wrapper']}>
                                <div className={styles['switch-desc']}>Notification settings: </div>
                                <FontAwesomeIcon
                                    title="Planning phase"
                                    icon={calendarIcon}
                                    className={operationsPhase ? styles['switch-icon-deselected'] : styles['switch-icon']}
                                />
                                <CustomInput
                                    title="Toggle between Planning and Operations phase to limit user notifications. If Operations phase is enabled, only involved captains will receive notifications"
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    onChange={(e) => setOperationsPhaseAction(e.target.checked)}
                                    className={styles['switch']}
                                    checked={operationsPhase}
                                />
                                <FontAwesomeIcon
                                    title="Operations phase"
                                    icon={gasPumpIcon}
                                    className={operationsPhase ? styles['switch-icon'] : styles['switch-icon-deselected']}
                                />
                            </div>
                        )}
                    </div>
                    {isNomination && !isChatDisabled && (
                        <div className={styles.notificationsInfo}>
                            {operationsPhase ? 'Bunkering: Only onboard personnel receive mails about new chat messages.' : 'Planning: Everyone receives mails about new chat messages.'}
                        </div>
                    )}
                </div>

                <div className={isNomination && !isChatDisabled? styles.nominationMessages : styles.messages}>
                    {messages.map((message, index) => (
                        <Message key={index} message={message} index={index} loggedInUser={loggedInUser} />
                    ))}
                    <div ref={messagesEndRef} className={styles.messagesEnd} />
                </div>
                
                <div className={classnames('input-group', styles['input'])}>
                    <input
                        type="text"
                        disabled={isChatDisabled}
                        placeholder={isChatDisabled? "Chat is disabled" : "Enter message here"}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => keyPressed(e)}
                        className={`form-control add-row-input input-group-prepend" ${styles['input-field']} ${styles['input-field-extra-selector']}`}
                    />
                    <div className="input-group-append">
                        <button disabled={isChatDisabled} onClick={sendMessage} className={`${styles['send-button']}`}>
                            <SendIcon />
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Chat;