import * as TYPE from './types';
import { ApplicationState } from '..';

export const companyReducer = (
  state: ApplicationState['companies'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
    isCompanyEditMode: false,
    toggleDeliveryModeFailed: false,
    toggleLowCarbonFuelFailed: false,
  },
  action: TYPE.CompanyActions,
): ApplicationState['companies'] => {
  switch (action.type) {
    case TYPE.COMPANY_EDIT_MODE:
      return {
        ...state,
        isCompanyEditMode: action.payload,
      };
    case TYPE.COMPANY_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_COMPANIES:
      return {
        ...state,
        data: action.payload.sort((a, b) => (a.name < b.name ? -1 : 1)),
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.COMPANIES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.UPDATE_COMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Company updated',
          type: 'info',
        },
      };
    case TYPE.UPDATE_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not update the Company. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.ADD_COMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Company succesfully added!',
          type: 'success',
        },
      };
    case TYPE.ADD_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not create the new Company. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.DELETE_COMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Company deleted',
          type: 'info',
        },
      };
    case TYPE.DELETE_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not delete Company. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.COMPANY_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.COMPANY_DELIVERY_MODE:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case TYPE.COMPANY_DELIVERY_MODE_ERROR:
      return {
        ...state,
        loading: false,
        toggleDeliveryModeFailed: action.payload.statusCode == 403 ? true : false,
        toastData: {
          content:
            'Something went wrong! We could not toggle delivery mode. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.COMPANY_CLEAR_DELIVERY_MODE_ERROR:
      return {
        ...state,
        toggleDeliveryModeFailed: false,
      };
    case TYPE.COMPANY_LOWCARBON_FUEL:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case TYPE.COMPANY_LOWCARBON_FUEL_ERROR:
      return {
        ...state,
        loading: false,
        toggleLowCarbonFuelFailed: action.payload.statusCode == 403 ? true : false,
        toastData: {
          content:
            'Something went wrong! We could not toggle low carbon fuel. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.COMPANY_CLEAR_LOWCARBON_FUEL_ERROR:
      return {
        ...state,
        toggleLowCarbonFuelFailed: false,
      };
    default:
      return { ...state };
  }
};
