import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { ErrorMessage, Field } from 'formik';
import { addNameAndValueFields } from '../../helpers/addNameAndValueFields';
import { addNameValuesForUserRoles } from '../../helpers/addNameValuesForUserRoles';
import { addNameValuesForCompanyCategories } from '../../helpers/addNameValuesForCompanyCategories';
import { addNameValuesForCompanies } from '../../helpers/addNameValuesForCompanies';
import MySelect from './MySelect';
import MySelectModified from './MySelectModified';
import CheckboxGroup from './CheckboxGroup';
import RadioButtonGroup from './RadioButtonGroup';
import StyledCheckboxSelector from './StyledCheckboxSelector';
import StyledBasicLabel from './StyledBasicLabel';
import StyledBasicInput from './StyledBasicInput';
import StyledMySelectDropdown from './StyledMySelectDropdown';
import StyledCheckbox from './StyledCheckbox';
import StyledRadioList from './StyledRadioList';
import StyledBasicErrorMessage from './StyledBasicErrorMessage';
import DatePickerField from './DatePickerField';
import SimpleDropdown from './SimpleDropdown';
import ComplexDropdown from './ComplexDropdown';
import ComplexMultiselectDropdown from './ComplexMultiselectDropdown';


const StyledMySelect = styled(StyledMySelectDropdown)``;
const StyledDropdown = styled.div`
  border-radius: 0px;
  height: 49px;
  padding: 10px;
  font-weight: 600;
  color: #000;
  border: 1px solid #666;
  &:hover {
    border: 1px solid gray;
  }
  &:focus {
    border: 1px solid gray;
  }
`;
const StyledField = styled(StyledBasicInput)``;
const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  input {
    height: 49px !important;
    padding-left: 5px;
    &:hover {
      border: 1px solid gray;
    }
    &:focus {
      border: 1px solid gray;
    }
  }
  .react-datepicker-wrapper input {
    border-radius: 0px;
    margin-left: 0px;
    border: 1px solid rgb(204, 204, 204);
    padding: 12px;
    font-weight: 600;
    width: 90%;
  }
`;
const StyledRadioSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.2em;
  margin-top: -1em;
`;

export const renderLabel = (name, config) => (
  <StyledBasicLabel>
    {config[name].label}{config[name].required? <span className='required'>*</span> : ''} <span className='unit'>{config[name].unit}</span>
  </StyledBasicLabel>
);

export const renderDropdown = (
  name,
  data,
  isLoading,
  isMultiselect = false,
  allowSelectAll = false,
  { config, disabledDropdowns, isDisabled = false, setFieldValue, setFieldTouched },
) => {
  const _data = addNameAndValueFields(data);

  const disabledEntryData = (() => {
    if ((disabledDropdowns || []).indexOf(name) === -1) {
      return [];
    }

    return _data.map((it) => {
      if (!it.enabled) {
        it.disabled = true;
      }

      return it;
    });
  })();

  return (
    <div className={`input-${name}`}>
      {renderLabel(name, config)}
      <Field name={name}>
        {({ field: { value } }) => (
          <StyledMySelect
            as={MySelect}
            id={`${name}-dropwdown`}
            options={disabledEntryData.length ? disabledEntryData : _data}
            placeholder={config[name].placeholder}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            {...{ name, value, isLoading, allowSelectAll, isMultiselect, isDisabled }}
          />
        )}
      </Field>
      <StyledBasicErrorMessage component="div" name={name} />
    </div>
  );
};

export const renderReportsDropdown = (
  name,
  data,
  isLoading,
  isMultiselect = false,
  allowSelectAll = false,
  { config, disabledDropdowns, isDisabled = false, setFieldValue, setFieldTouched },
) => {
  let _data;
  if(name=='roles')
    _data = addNameValuesForUserRoles(data);
  else if (name=='companies')
    _data = addNameValuesForCompanies(data);
  else if (name=='companyCategories')
    _data = addNameValuesForCompanyCategories(data);

  const disabledEntryData = (() => {
    if ((disabledDropdowns || []).indexOf(name) === -1) {
      return [];
    }

    return _data.map((it) => {
      if (!it.enabled) {
        it.disabled = true;
      }
      return it;
    });
  })();

  return (
    <div className={`input-${name}`}>
      {renderLabel(name, config)}
      <Field name={name}>
        {({ field: { value } }) => (
          <StyledMySelect
            as={MySelectModified}
            id={`${name}-dropwdown`}
            options={disabledEntryData.length ? disabledEntryData : _data}
            placeholder={config[name].placeholder}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            {...{ name, value, isLoading, allowSelectAll, isMultiselect, isDisabled }}
          />
        )}
      </Field>
      <StyledBasicErrorMessage component="div" name={name} />
    </div>
  );
};

export const renderSimpleDropdown = (name, data, disabled = false, accessor, { config }) => (
  <div className={`input-${name}`}>
    {renderLabel(name, config)}
    <StyledDropdown
      as={Field}
      name={name}
      component={SimpleDropdown}
      id={name}
      options={data}
      placeholder={config[name].placeholder}
      disabled={disabled}
      accessor={accessor}
    />
    <StyledBasicErrorMessage component="div" name={name} />
  </div>
);

export const renderComplexDropdown = (name, data, disabled = false, accessor, { config, setFieldValue }) => (
  <div className={`input-${name}`}>
    {renderLabel(name, config)}
    <StyledDropdown
      as={Field}
      name={name}
      component={ComplexDropdown}
      id={name}
      options={data}
      placeholder={config[name].placeholder}
      disabled={disabled}
      accessor={accessor}
      setFieldValue={setFieldValue}
    />
    <ErrorMessage
      component="div"
      name={name}
      render={(msg) => (
        <StyledBasicErrorMessage as="div">
          {typeof msg === 'string' ? msg : msg[Object.keys(msg)[0]]}
        </StyledBasicErrorMessage>
      )}
    />
  </div>
);

export const renderComplexMultiselectDropdown = (name, data, disabled = false, accessor, { config, setFieldValue }) => (
  <div className={`input-${name}`}>
    {renderLabel(name, config)}
    <StyledDropdown
      as={Field}
      name={name}
      component={ComplexMultiselectDropdown}
      id={name}
      options={data}
      placeholder={config[name].placeholder}
      disabled={disabled}
      accessor={accessor}
      setFieldValue={setFieldValue}
    />
    <ErrorMessage
      component="div"
      name={name}
      render={(msg) => (
        <StyledBasicErrorMessage as="div">
          {typeof msg === 'string' ? msg : msg[Object.keys(msg)[0]]}
        </StyledBasicErrorMessage>
      )}
    />
  </div>
);

export const renderTextInput = (
  name,
  disabled = false,
  { config, errors, touched, setFieldValue, setFieldTouched },
) => (
  <div className={`input-${name}`}>
    {renderLabel(name, config)}
    <StyledField
      className={classnames(`${name}`, { error: errors[name] && touched[name] })}
      as={Field}
      onBlur={(event) => {
        setFieldTouched(name);
        setFieldValue(event.target.name, event.target.value.trim());
      }}
      type="text"
      id={classnames(`control_${name}`)}
      name={name}
      placeholder={config[name].placeholder || ''}
      disabled={disabled}
      readOnly={config[name].readOnly || false}
      maxLength={config[name].maxLength}
    />
    <StyledBasicErrorMessage component="div" name={name} />
  </div>
);

export const renderTextAreaInput = (name, disabled = false, { config, errors, touched }) => (
  <div className={`input-${name}`}>
    {renderLabel(name, config)}
    <StyledField
      className={classnames(`${name}`, { error: errors[name] && touched[name] })}
      as={Field}
      type="textarea"
      component="textarea"
      id={classnames(`control_${name}`)}
      name={name}
      placeholder={config[name].placeholder || ''}
      disabled={disabled}
      readOnly={config[name].readOnly || false}
      rows="5"
      cols="50"
      maxLength={config[name].maxLength || 500}
    />
    <StyledBasicErrorMessage component="div" name={name} />
  </div>
);

export const renderNumericInput = (name,  disabled = false, { config, errors, touched }) => (
  <div className={`input-${name}`}>
    {renderLabel(name, config)}
    <StyledField
      className={classnames(`${name}`, { error: errors[name] && touched[name] })}
      as={Field}
      type="number"
      id={classnames(`control_${name}`)}
      name={name}
      placeholder={config[name].placeholder || ''}
      disabled={disabled}
      readOnly={config[name].readOnly || false}
      maxLength={config[name].maxLength || 30}
    />
    <StyledBasicErrorMessage component="div" name={name} />
  </div>
);

export const checkboxComponent = ({
  field: { name, value, onChange, onBlur },
  form: { touched },
  id,
  label,
  disabled = false,
  className,
}) => (
  <>
    <StyledCheckbox htmlFor={id}>
      <input
        className={className}
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <span className={classnames(`checkmark`, {})} />
      <span className={classnames(`text-label`, {})}>{label}</span>
    </StyledCheckbox>
    {touched[name] && <StyledBasicErrorMessage component="div" name={name} />}
  </>
);

export const renderCheckbox = (name, disabled = false, { config }) => (
  <div className={`input-${name}`}>
    <Field component={checkboxComponent} name={name} id={name} label={(config[name] || {}).label}  disabled={disabled} />
  </div>
);

export const renderCheckboxGroup = (name, { config, values, errors, touched, setFieldValue, setFieldTouched }) => (
  <StyledCheckboxSelector>
    {renderLabel(name, config)}
    <CheckboxGroup
      className={name}
      id={name}
      name={name}
      value={values[name] || []}
      error={errors[name]}
      touched={touched[name]}
      onChange={setFieldValue}
      onBlur={setFieldTouched}
    >
      {((config[name] || {}).options || []).map((it) => (
        <Field key={it.id} component={checkboxComponent} className={name} name={name} id={it.id} label={it.name} />
      ))}
    </CheckboxGroup>
  </StyledCheckboxSelector>
);

export const renderDatepicker = (name, disabled, config) => (
  <div className={`input-${name}`} id="datePicker">
    <StyledDatePicker>
      {renderLabel(name, config)}
      <DatePickerField
        name={name}
        disabled={disabled}
        dateFormat={config[name].dateFormat}
        showTimeSelect={config[name].showTimeSelect}
        timeFormat={config[name].timeFormat}
        timeIntervals={config[name].timeIntervals}
        timeCaption={config[name].timeCaption}
      />
      <StyledBasicErrorMessage component="div" name={name} noMargin />
    </StyledDatePicker>
  </div>
);

export const renderRadio = ({ field: { name, value, onChange, onBlur }, id, label, className, ...props }) => (
  <li>
    <input
      name={name}
      id={id}
      type="radio"
      value={id}
      checked={id === value}
      onChange={onChange}
      onBlur={onBlur}
      className={`radio-button ${className}`}
      {...props}
    />
    <label htmlFor={id}>{label}</label>
    <div className="check">
      <div className="inside" />
    </div>
  </li>
);

export const renderRadioGroup = (name, children, { config, values, errors, touched }) => (
  <>
    {renderLabel(name, config)}
    <StyledRadioSelectorWrapper>
      <RadioButtonGroup id={name} value={values[name]} error={errors[name]} touched={touched[name]}>
        <StyledRadioList>
          {((config[name] || {}).options || []).map((it) => (
            <Field key={it.id} component={renderRadio} className={name} name={name} id={it.id} label={it.name} />
          ))}
        </StyledRadioList>
      </RadioButtonGroup>
      {children}
    </StyledRadioSelectorWrapper>
  </>
);
