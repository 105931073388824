import React, { useEffect, useCallback } from 'react';
import Moment from 'react-moment';
import { Company } from '../../../../../store/Company/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { getCategories } from '../../../../../store/Category/actions';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Route, useHistory } from 'react-router-dom';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import styled from 'styled-components';
import { Column } from 'react-table';
import FuelBossTableContainer from '../../../../FuelBossTable';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
import { IntegrationAsset } from '../../../../../store/IntegrationAsset/models';
import { IntegrationAssetTopic } from '../../../../../store/IntegrationAssetTopic/models';
import { IntegrationAssetIssuer } from '../../../../../store/IntegrationAssetIssuer/models';
import { IntegrationAssetPermissions } from '../../../../../store/IntegrationAssetPermissions/models';
import { getIntegrationAssets } from '../../../../../store/IntegrationAsset/actions';
import { getIntegrationAssetCompanies } from '../../../../../store/IntegrationAssetCompanies/actions';
import { IntegrationAssetCompany } from '../../../../../store/IntegrationAssetCompanies/models';
import AddIntegrationAssetIssuers from '../AddIntegrationAssetSchemas/AddIntegrationAssetIssuers';
import { getIntegrationAssetPermissions } from '../../../../../store/IntegrationAssetPermissions/actions';
import { getCompanies } from '../../../../../store/Company/actions';
import { getIntegrationAssetIssuers } from '../../../../../store/IntegrationAssetIssuer/actions';
import { IntegrationAssetWorkspace } from '../../../../../store/IntegrationAssetWorkspaces/models';
const PlusCircle = findIconDefinition({
  prefix: 'fal',
  iconName: 'plus-circle',
});
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

const StyledAssetTableWrapper = styled.div`
  position: relative;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

interface OwnProps {
  integrationAssets: IntegrationAsset[];
  integrationAssetCompanies: IntegrationAssetCompany[];
  integrationAssetTopic: IntegrationAssetTopic[];
  integrationAssetIssuers: IntegrationAssetIssuer[];
  integrationAssetPermissions: IntegrationAssetPermissions[];
  integrationAssetWorkspaces: IntegrationAssetWorkspace[];
  companies: Company[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  addIntegrationAssetIdIssuer: (integrationAssetIssuer: IntegrationAssetIssuer) => void;
  deleteIntegrationAsset: (id: string) => void;
  editIntegrationAsset: (integrationAsset: IntegrationAsset) => void;
  navAdminType: string;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const IntegrationAssets: React.FC<OwnProps> = ({
  integrationAssets,
  integrationAssetCompanies,
  integrationAssetTopic,
  integrationAssetIssuers,
  integrationAssetPermissions,
  integrationAssetWorkspaces,
  companies,
  isLoading,
  hasError,
  errorDetails,
  addIntegrationAssetIdIssuer,
  deleteIntegrationAsset,
  editIntegrationAsset,
  navAdminType,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const {
    integrationassets,
    integrationassetsIsLoaded,
    integrationassetPermissions,
    integrationassetPermissionsIsLoaded,
    companiesIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    integrationassets: state.integration_assets.data,
    integrationassetsIsLoaded: state.integration_assets.dataIsLoaded,

    integrationassetPermissions: state.integration_assets_permissions.data,
    integrationassetPermissionsIsLoaded: state.integration_assets_permissions.dataIsLoaded,

    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const getIntegratedAssetsCallback = useCallback(() => {
    dispatch(getIntegrationAssets());
  }, []);

  const getIntegratedAssetsPermissionsCallback = useCallback(() => {
    dispatch(getIntegrationAssetPermissions());
  }, []);

  const getComapniesCallback = useCallback(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if (!integrationassetsIsLoaded) {
      getIntegratedAssetsCallback();
    }
  }, [integrationassetsIsLoaded]);

  useEffect(() => {
    if (!integrationassetPermissionsIsLoaded) {
      getIntegratedAssetsPermissionsCallback();
    }
  }, [integrationassetPermissionsIsLoaded]);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getComapniesCallback();
    }
  }, [companiesIsLoaded]);

  /*const onClickEdit = (veracityId: string, id : string) => {
    setVeracityClientId(veracityId);
    setAssetId(id);
  };*/

  const headersNew: Array<Column<IntegrationAsset>> = React.useMemo(
    () => [
      {
        Header: 'Veracity Asset id',
        accessor: (row) => row.veracityAssetId,
      },
      {
        Header: 'Name',
        accessor: (row) => row.assetName,
      },
      {
        Header: 'External name',
        accessor: (row) => row.assetExternalName,
      },
      {
        Header: 'Description',
        accessor: (row) => row.assetDescription,
      },
      {
        Header: 'Location',
        accessor: (row) => row.assetLocation,
      },
      {
        Header: 'Asset type',
        accessor: (row) => row.assetType,
      },
      {
        Header: 'Owner ID',
        //accessor:(row) => row.ownerCompanyId,
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original;
          let integrationname = props.cell.row.original.ownerCompanyId;
          if (integrationAssetCompanies) {
            integrationAssetCompanies.filter((x) => {
              if (x.companyId === integrationAsset.ownerCompanyId) {
                integrationname = x.companyName;
              }
            });
          }
          return <div>{integrationname ? integrationname : null}</div>;
        },
        accessor: (row) => {
          if (row.ownerCompanyId)
            if (integrationAssetCompanies) {
              integrationAssetCompanies.filter((x) => {
                if (x.companyId === row.ownerCompanyId) {
                  return x.companyName;
                }
              });
            } else return '-';
          else return '-';
        },
      },
      {
        Header: 'Workspace',
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original;
          let veracityWorkspaceId = props.cell.row.original.workspaceId;
          if (integrationAssetWorkspaces) {
            integrationAssetWorkspaces.filter((x) => {
              if (x.workspace === integrationAsset.workspaceId) {
                veracityWorkspaceId = x.name;
              }
            });
          }
          return <div>{veracityWorkspaceId ? veracityWorkspaceId : null}</div>;
        },
      },
      {
        Header: 'External access',
        Cell: (props: any) => {
          const integrationasset: IntegrationAsset = props.cell.row.original;
          const permissionList = integrationAssetPermissions.filter((x) =>
            x.veracityAssetId?.includes(integrationasset.veracityAssetId),
          );
          if (permissionList.length == 0) return <div> {null}</div>;
          else if (permissionList.length == 1) {
            return (
              <div>
                {permissionList.map((x) =>
                  companies.map((y) => {
                    if (y.chorusId == x.externalAccessCompanyId) return <div key={x.id}>{y.name}</div>;
                  }),
                )}
              </div>
            );
          } else {
            return (
              <div>
                {permissionList.map((x) =>
                  companies.map((y) => {
                    if (y.chorusId == x.externalAccessCompanyId) return <div key={x.id}>{y.name} ,</div>;
                  }),
                )}{' '}
              </div>
            );
          }
        },
        accessor: (row) => {
          const integrationasset: IntegrationAsset = row;
          const permissionList = integrationAssetPermissions.filter((x) =>
            x.veracityAssetId?.includes(integrationasset.veracityAssetId),
          );
          if (permissionList.length == 0) return '';
          else {
            let permList = '';
            permissionList.forEach(function (x, index) {
              const selectedCompany = companies.find((y) => y.chorusId == x.externalAccessCompanyId);
              if (selectedCompany) {
                permList += selectedCompany.name + ',';
              }
            });
            return permList;
          }
        },
      },
      {
        Header: 'Asset id Issuer',
        Cell: (props: any) => {
          const integrationasset: IntegrationAsset = props.cell.row.original;
          const Issuerlist = integrationAssetIssuers.filter((x) =>
            x.veracityAssetId?.includes(integrationasset.veracityAssetId ) && x.integrationAssetId === integrationasset.id ,
          );
          if (Issuerlist.length == 0) return <div> {null}</div>;
          else if (Issuerlist.length == 1) {
            return (
              <div>
                {Issuerlist.map((x) => (
                  <div key={x.id}>{x.assetIdIssuer} </div>
                ))}
              </div>
            );
          } else {
            return (
              <div>
                {Issuerlist.map((x) => (
                  <div key={x.id}>{x.assetIdIssuer} ,</div>
                ))}
              </div>
            );
          }
        },
        accessor: (row) => {
          const integrationasset: IntegrationAsset = row;
          const Issuerlist = integrationAssetIssuers.filter((x) =>
            x.veracityAssetId?.includes(integrationasset.veracityAssetId),
          );
          if (Issuerlist.length == 0) return '';
          else {
            let IssuerItem = '';
            Issuerlist.forEach(function (x, index) {
              IssuerItem += x.assetIdIssuer + ',';
            });
            return IssuerItem;
          }
        },
      },
      {
        Header: 'Topic',
        Cell: (props: any) => {
          const integrationasset: IntegrationAsset = props.cell.row.original;
          const topiclist = integrationAssetTopic.filter((x) =>
            x.veracityAssetId.includes(integrationasset.veracityAssetId) && x.integrationAssetId === integrationasset.id,
          );
          if (topiclist.length == 0) return <div> {null}</div>;
          else if (topiclist.length == 1) {
            return (
              <div>
                {topiclist.map((x) => (
                  <div key={x.id}>{x.topic} </div>
                ))}
              </div>
            );
          } else {
            return (
              <div>
                {topiclist.map((x) => (
                  <div key={x.id}>{x.topic}, </div>
                ))}
              </div>
            );
          }
        },
        accessor: (row) => {
          const integrationasset: IntegrationAsset = row;
          const topiclist = integrationAssetTopic.filter((x) =>
            x.veracityAssetId.includes(integrationasset.veracityAssetId),
          );
          if (topiclist.length == 0) return '';
          else {
            let topicItem = '';
            topiclist.forEach(function (x, index) {
              topicItem += x.topic + ',';
            });
            return topicItem;
          }
        },
      },
      {
        Header: 'Timestamp',
        //accessor:(row) => row.timeStamp,
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original.timeStamp;
          return <Moment date={integrationAsset} format={integrationAsset ? 'DD MMM, YYYY HH:mm ' : ''} />;
        },
        accessor: (row) => {
          if (row.timeStamp) return row.timeStamp;
          else return '-';
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={integrationAsset.id ? integrationAsset.id : ''}
                deleteMethod={deleteIntegrationAsset}
                header="Delete Integrated Asset"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original;
          const id = props.cell.row.original.id;
          const veracityId = props.cell.row.original.veracityAssetId;
          return (
            <div className="edit-cell">
              <EditById redirectUrl={`/${navAdminType}admin/integrationassets/${integrationAsset.id}/edit`} />
            </div>
          );
        },
      },
      /*{
        Header: 'Add schemas',
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original;
          const id = props.cell.row.original.id;
          const veracityId = props.cell.row.original.veracityAssetId
          return (
            <Button color="primary" size="lg" onClick={() =>  onClickSchema(veracityId, id)}>
              <FontAwesomeIcon icon={PlusCircle} /> Schemas
           </Button>
          );
        },
      },
      {
        Header: 'Add data channel',
        Cell: (props: any) => {
          const integrationAsset = props.cell.row.original;
          const id = props.cell.row.original.id;
          const veracityId = props.cell.row.original.veracityAssetId
          return (
            <Button color="primary" size="lg" onClick={() =>  onClickData(veracityId, id)}>
              <FontAwesomeIcon icon={PlusCircle} /> data channel
           </Button>
          );
        },
      },*/
    ],
    [],
  );

  if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledAssetTableWrapper>
        <div className="buttons-div">
          <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/integrationassets/add`)}>
            Add integrated asset <FontAwesomeIcon icon={PlusCircle} />
          </Button>
          <Button
            color="primary"
            size="lg"
            onClick={() => history.push(`/${navAdminType}admin/integrationassets/companies`)}
          >
            Manage asset company
          </Button>
          <Button
            color="primary"
            size="lg"
            onClick={() => history.push(`/${navAdminType}admin/integrationassets/types`)}
          >
            Manage asset type
          </Button>
          <Button
            color="primary"
            size="lg"
            onClick={() => history.push(`/${navAdminType}admin/integrationassets/events`)}
          >
            Manage event types
          </Button>
          <Button
            color="primary"
            size="lg"
            onClick={() => history.push(`/${navAdminType}admin/integrationassets/outbound`)}
          >
            Outbound Event Subscription
          </Button>
        </div>
        {companiesIsLoaded && (
          <FuelBossTableContainer
            headers={headersNew}
            tableRows={integrationAssets}
            showPagination={true}
            defaultSelectedPage={defaultSelectedPage}
            setDefaultSelectedPage={setDefaultSelectedPage}
          />
        )}
      </StyledAssetTableWrapper>
    );
  }
};

export default React.memo(IntegrationAssets);
