import React from 'react';
import { FuelbossStatus } from '../../store/Nomination/models';
import styles from './NominationFilterButton.module.scss';

interface OwnProps {
  status: FuelbossStatus;
  filteredStatuses: FuelbossStatus[];
  onClick: (status: FuelbossStatus) => void;
}

const NominationFilterButton: React.FC<OwnProps> = ({ status, filteredStatuses, onClick }) => (
  <button
    className={`${styles['nomination-button']} ${styles[status]} ${
      filteredStatuses.indexOf(status) >= 0 ? styles.active : ''
    }`}
    onClick={(e) => {
      onClick(status);
    }}
  >
    {status.replace(/-/g, ' ').toUpperCase()}
  </button>
);

export default NominationFilterButton;
