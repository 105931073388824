import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { BUNKERPIPELINE_LOADING, BUNKERPIPELINE_FETCH_ERROR, GET_BUNKERPIPELINE, BunkerPipelineActions } from './types';
import { BunkerPipelineResponseModel } from './models';

export const getBunkerPipelines = () => (dispatch: Dispatch<BunkerPipelineActions>) => {
  dispatch({
    type: BUNKERPIPELINE_LOADING,
  });

  new api<BunkerPipelineResponseModel>({
    dispatch,
    errorType: BUNKERPIPELINE_FETCH_ERROR,
  })
    .get(`api/chorus/GetBunkerPipelines`)
    .then((response) => {
      dispatch({
        type: GET_BUNKERPIPELINE,
        payload: response,
      });
    });
};
