import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { ApplicationState } from '../../store';
import { getNominationsInfiniteScroll } from '../../store/Nomination/actions';
import { Loading } from '../Loading/index';
import { Error } from '../Error/index';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { getArchivedNominationsInfiniteScroll } from '../../store/ArchivedNomination/actions';
import NominationListContainer from './Components/NominationList/NominationListContainer';
import NominationTableContainer from './Components/NominationTable/NominationTableContainer';
import { Nomination as NominationModel } from '../../store/Nomination/models';
import { getCompanies } from '../../store/Company/actions';

interface OwnProps {
  chooseNomination: (nominationId: string) => void;
  className?: string;
  setNewNomination: (newNomination: boolean) => void;
  displayAsTable?: boolean;
  activeNominationId?: string;
  archivedAsInitialTab?: boolean;
  sortByNeedsAction?: boolean;
  minified?: boolean;
  shipOwner: boolean | undefined;
}

const Nomination: React.FC<OwnProps> = ({
  chooseNomination,
  className,
  setNewNomination,
  displayAsTable,
  activeNominationId,
  archivedAsInitialTab = false,
  sortByNeedsAction,
  minified,
  shipOwner,
}) => {
  const {
    nominationLoading,
    nominationError,
    nominationErrorDetails,
    nomination,
    nominationIsLoaded,
    nominationMetaData,
    companies,
    companiesIsLoaded,
    archivedNominationLoading,
    archivedNominationError,
    archivedNominationErrorDetails,
    archivedNomination,
    archivedNominationIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    nominationLoading: state.nomination.loading,
    nominationError: state.nomination.error,
    nominationErrorDetails: state.nomination.errorDetails,
    nomination: state.nomination.data,
    nominationIsLoaded: state.nomination.dataIsLoaded,
    nominationMetaData: state.nomination.metadata,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    archivedNominationLoading: state.archived_nomination.loading,
    archivedNominationError: state.archived_nomination.error,
    archivedNominationErrorDetails: state.archived_nomination.errorDetails,
    archivedNomination: state.archived_nomination.data,
    archivedNominationIsLoaded: state.archived_nomination.dataIsLoaded,
  }));
  const dispatch = useDispatch();
  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const getNominationCallback = useCallback(() => dispatch(getNominationsInfiniteScroll(0)), []);

  const getArchivedNominationCallback = useCallback(() => dispatch(getArchivedNominationsInfiniteScroll(0)), []);

  useEffect(() => {
    if (!nominationIsLoaded) {
      getNominationCallback();
    }
    if (!archivedNominationIsLoaded && activeTab === 'archived') {
      getArchivedNominationCallback();
    }

    if (!companiesIsLoaded) {
      getCompaniesCallback();
    }
  }, []);

  React.useEffect(() => {
    //Hide nominations that only have active companies
    const filteredNominations: NominationModel[] = [];
    if (shipOwner) {
      nomination.forEach(function (nom, index) {
        const compMatch = companies.find((x) => x.chorusId == nom.vendorCompanyId);
        if (compMatch?.active && compMatch != undefined) {
          filteredNominations.push(nom);
        }
      });
      setActiveCompaniesNominations(filteredNominations);
    } else {
      nomination.forEach(function (nom, index) {
        const compMatch = companies.find((x) => x.chorusId == nom.companyId);
        if (compMatch?.active && compMatch != undefined) {
          filteredNominations.push(nom);
        }
      });
      setActiveCompaniesNominations(filteredNominations);
    }
  }, [nomination, companies]);

  React.useEffect(() => {
    setAllArchivedNominations(
      archivedNomination.sort((a, b) => (new Date(b.bst) > new Date(a.bst) ? 1 : -1)),
    );
  }, [archivedNomination]);

  const [activeCompaniesNominations, setActiveCompaniesNominations] = React.useState<NominationModel[]>([]);
  const [activeTab, setActiveTab] = useState(archivedAsInitialTab ? 'archived' : 'bunker');
  const [allArchivedNominations, setAllArchivedNominations] = React.useState<NominationModel[]>([]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderNominations = (archived: boolean, displayAsTable?: boolean) => {
    if (displayAsTable) {
      return (
        <NominationTableContainer
          nominations={archived ? allArchivedNominations : activeCompaniesNominations}
          archived={archived}
          sortByNeedsAction={sortByNeedsAction ? sortByNeedsAction : false}
        />
      );
    } else {
      return (
        <NominationListContainer
          nominations={archived ? allArchivedNominations : activeCompaniesNominations}
          nominationLoading={archived ? archivedNominationLoading : nominationLoading}
          setNewNomination={setNewNomination}
          chooseNomination={chooseNomination}
          displayingArchivedNominations={archived}
          activeNominationId={activeNominationId ? activeNominationId : ''}
          minified={minified ? minified : false}
          activeTab={activeTab}
        />
      );
    }
  };

  return (
    <div className={className}>
      {!minified && (
        <Nav tabs className={`sticky-top bg-white bunker-events-nav`}>
          <div />

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'bunker' })}
              onClick={() => {
                toggle('bunker');
              }}
            >
              Bunker events
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'archived' })}
              onClick={() => {
                if (!archivedNominationIsLoaded) {
                  getArchivedNominationCallback();
                }
                toggle('archived');
              }}
            >
              Archived
            </NavLink>
          </NavItem>
        </Nav>
      )}

      <TabContent activeTab={activeTab}>
        <TabPane tabId="bunker">
          {/* {nominationLoading && <Loading type="nomination" />} */}
          {nominationError && nominationErrorDetails && <Error error={nominationErrorDetails} />}
          {!nominationError && renderNominations(false, displayAsTable)}
        </TabPane>
        <TabPane tabId="archived">
          {/* {archivedNominationLoading && <Loading type="archived nominations" />} */}
          {archivedNominationError && archivedNominationErrorDetails && (
            <Error error={archivedNominationErrorDetails} />
          )}

          {!archivedNominationError && renderNominations(true, displayAsTable)}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Nomination;
