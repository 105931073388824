import React from 'react';
import { Formik } from 'formik';
import CompanyForm from '../CompanyForm/CompanyForm';

import { initialValues } from '../companyFormInitialValues';
import companyFormValidation from '../companyFormValidation';
import { Company } from '../../../../../store/Company/models';

interface OwnProps {
  addCompany: (company: Company) => void;
  header: string;
  company?: Company;
  systemAdmin?: boolean;
  navAdminType: string;
  saveLogoMethod?: (companyId: string, logo: string, mimeType: string) => void;
  companyLogo?: Uint8Array[];
}

const FormikWrapper: React.FC<OwnProps> = ({ addCompany, header, systemAdmin, navAdminType, company, saveLogoMethod, companyLogo }) => {
  const submitCompany = (company: Company) => {
    addCompany(company);
  };

  return (
    <Formik
      initialValues={company ? company : initialValues}
      validationSchema={companyFormValidation}
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <CompanyForm
          header={header}
          saveMethod={submitCompany}
          company={company}
          formikProps={formikProps}
          systemAdmin={systemAdmin}
          navAdminType={navAdminType}
          saveLogoMethod={saveLogoMethod}
          companyLogo={companyLogo}
        />
      )}
    </Formik>
  );
};

export default React.memo(FormikWrapper);
