import React from 'react';
import styles from './MinifiedSpotList.module.scss';
import { SpotEnquiry } from './../../../../../../store/Spot/models';

interface OwnProps {
  spots: SpotEnquiry[];
  activeSpotId: string;
}

const MinifiedSpotList: React.FC<OwnProps> = ({ spots, activeSpotId }) => (
  <div className={`${styles['minified-nomination']}`}>
    <div className={styles['header']}>Quotations</div>
    {spots.map((spot) => {
      if (spot)
        return (
          <div className={`${styles['wrapper']} ${activeSpotId === spot._id ? styles['active'] : ''}`} key={spot._id}>
            <div
              className={`${styles['nomination-indicator']} ${spot.attributes ? styles[spot.attributes.fbStatus] : ''}`}
            ></div>
          </div>
        );
      else return null;
    })}
  </div>
);

export default MinifiedSpotList;
