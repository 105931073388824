import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from 'styled-components';
import { Route, Switch, useLocation } from 'react-router';
import Layout from './scenes/Layout/Layout';
import BunkerEvents from './scenes/BunkerEvents/BunkerEvents';
import SpotEnquiry from './scenes/SpotEnquiry/SpotEnquiry';
import { ServiceCheck } from './scenes/ServiceCheck/ServiceCheck';
import { EmailSettings } from './scenes/EmailSettings/EmailSettings';
import Overview from './scenes/Overview/Overview';
import Toolbox from './scenes/Toolbox/Toolbox';
import { Loading } from './components/Loading';
import RecurringOverview from './scenes/RecurringOverview/RecurringOverview';
import SystemAdmin from './scenes/SystemAdmin/SystemAdmin';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAuth } from './store/Auth/actions';
import { ApplicationState } from './store';
import { Error } from './components/Error';
//import Map from './scenes/Map/Map';
import { Redirect } from 'react-router-dom';
import CompanyAdmin from './scenes/CompanyAdmin/CompanyAdmin';
import Schedule from './scenes/Schedule/Schedule';
import AnalyticsInsights from './scenes/AnalyticsInsights/AnalyticsInsights';
import 'moment/locale/en-gb';
import * as moment from 'moment';
import ErrorLayout from './scenes/ErrorLayout/ErrorLayout';
import { LoginMessage } from './components/Error/components/LoginMesage';
import { PageNotFoundError } from './components/Error/components/PageNotFoundError';
import {
  checkSystemAdmin,
  checkCompanyAdmin,
  checkBunkerVesselCrew,
  checkSupplier,
  checkTerminalOperator,
  checkReceivingVesselCrew,
  checkCaptainScheduler,
  checkCaptains,
  checkProcurement,
  checkVesselOperator,
  checkCommercial,
  checkOperator,
  canAccessRecurringNominations,
} from './helpers/roleChecks';
import myTheme from './theme/myTheme';
import shouldRenewSession from './helpers/shouldRenewSession';
import Modal from './components/Modal/Modal';
import { dependencies } from '../package.json';
import BunkerVessel from './scenes/BunkerAsset/BunkerVessel';
import { Onboarding } from './scenes/Onboarding/Onboarding';
import { UserPermissionProvider, useLocationService } from '@teqplay/chorus-components';
import { ServiceLayer } from './helpers/ServiceLayer';
import { AuthenticationServiceLayer, CachedLocationService } from '@teqplay/chorus-components';
import { ErrorWrapper } from './models/ErrorAction';
import { Auth } from './store/Auth/models';
import { CustomError } from './components/Error/components/CustomError/index';
import BunkerTerminal from './scenes/BunkerAsset/BunkerTerminal';
import RecurringEvents from './scenes/RecurringEvents/RecurringEvents';
import DocumentList from'./scenes/DocumentList/DocumentList';
import { SignFlowContainer } from './scenes/SignFlow/SignFlowContainer';
import { CookieList } from './scenes/CookieList/CookieList';

moment.locale('en-gb');
//const LazyBunkerEvents = lazy(() => import('./scenes/BunkerEvents/BunkerEvents'));
//const LazySpotEnquiry = lazy(() => import('./scenes/SpotEnquiry/SpotEnquiry'));
const LazyMap = lazy(() => import('./scenes/Map/Map'));
const LazyDocumentMap = lazy(() => import('./scenes/DocumentList/DocumentList'));
const LazyAISList = lazy(() => import('./scenes/AISList/AISList'));
const LazyBunkerAvailabilityMap = lazy(() => import('./scenes/BunkerAvailabilityMap/BunkerAvailabilityMap'));


const App: React.FunctionComponent = () => {
  const authLoading = useSelector((state: ApplicationState) => state.auth.loading);
  const authError = useSelector((state: ApplicationState) => state.auth.error);
  const authErrorDetails = useSelector((state: ApplicationState) => state.auth.errorDetails);
  const auth = useSelector((state: ApplicationState) => state.auth.data);
  const authIsLoaded = useSelector((state: ApplicationState) => state.auth.dataIsLoaded);
  const modal = useSelector((state: ApplicationState) => state.global.modal);
  const [timeoutBoxDisplayed, setTimeoutBoxDisplayed] = React.useState(false);

  toast.configure();

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }

    console.log('teqplay-ui:', dependencies['@teqplay/teqplay-ui']);
    console.log('chorus-components:', dependencies['@teqplay/chorus-components']);
    getChorusBackendInfoURL();
  }, []);


  useEffect(() => {
    if (authIsLoaded && auth.isAdmin && !auth.isMFA) {
        if (auth.mfaRedirected) {
            getAuthCallback();
        }
        else {
            auth.mfaRedirected = true;
            window.location.href = '/account/SignInMFA';
        }
    }
    if (authIsLoaded && !auth.isAuthed && !isSignRequestPath()) {
      window.location.href = '/account/signin';
    }
    setServiceLayer(new ServiceLayer(errorWrapperObject, auth, tokenRefreshCallaback));
  }, [auth]);

  useEffect(() => {
    serviceLayer.updateTimeoutBoxDisplayed(timeoutBoxDisplayed);
  }, [timeoutBoxDisplayed]);

  const location = useLocation();


  const isSignRequestPath = () => {
    const pathName = window.location.pathname;
    if (pathName == '/sign/go' || pathName == '/sign/return') {
      return true;
    }
    return false;
  };

  const getChorusBackendURL = () => {
    const pathName = window.location.hostname;
    let url = 'https://backendfuelbossdev.teqplay.nl/v1';
    if (pathName.includes('dev') || pathName.includes('localhost')) {
      //environment is dev
      url = 'https://backendfuelbossdev.teqplay.nl/v1';
    } else if (pathName.includes('test')) {
      //environment is test
      url = 'https://backendfuelbosstest.teqplay.nl/v1';
    } else if (pathName.includes('demo')) {
      //environment is demo
      url = 'https://backendfuelbossdemo.teqplay.nl/v1';
    } else {
      //environment is prod
      url = 'https://backendfuelboss.teqplay.nl/v1';
    }
    return url;
  };

  const getChorusBackendInfoURL = async () => {
    const chorusBackendURL = getChorusBackendURL();
    const infoURL = chorusBackendURL.replace('v1', 'actuator') + '/info';
    try {
      const response = await fetch(infoURL);
      const json = await response.json();
      if (json.git) console.log('chorus-api: ' + json.git.commit.id + '-' + json.git.branch + '-' + json.git.commit.time);
    } catch (e) {
      console.error(e);
    }
  };

  const tokenRefreshCallaback = () => {
    //reload auth to get new token
    console.log('serviceLayer tokenRefreshCallaback');
    getAuthCallback();
  };

  const serviceLayerAuthLoaded = () => {
    if (serviceLayer.auth && serviceLayer.auth.email && serviceLayer.auth.email.length > 1) return true;
    else return false;
  };

  const errorWrapperObject: ErrorWrapper = {};
  const [serviceLayer, setServiceLayer] = React.useState(
    new ServiceLayer(errorWrapperObject, auth, tokenRefreshCallaback),
  );

  const locationService = useLocationService(serviceLayer);
  const chorusBaseURL = getChorusBackendURL();

  const standardLayout = (
    <ThemeProvider theme={myTheme}>
      <Layout setTimeoutBoxDisplayed={setTimeoutBoxDisplayed}>
        {modal && <Modal content={modal} />}
        <ToastContainer />
        {authIsLoaded && serviceLayerAuthLoaded() && (
          <UserPermissionProvider authenticationService={serviceLayer} userProfile={auth.chorusUserProfile}>
            <Suspense
              fallback={
                <CustomError
                  errorTitle={'Loading data please wait'}
                  errorMessage={''}
                  errorStatusCode={'Loading'}
                  errorStatusCodeFontSize={'22px'}
                  displayContact={false}
                ></CustomError>
              }
            >
              <Switch>
                <Route exact path={['/overview', '/']}>
                  <Overview />
                </Route>

                <Route exact path="/bunkerevents/:type/:id">
                  <BunkerEvents serviceLayer={serviceLayer} locationService={locationService} />
                </Route>
                <Route exact path="/bunkerevents/new">
                  <BunkerEvents
                    openNewNomination={true}
                    serviceLayer={serviceLayer}
                    locationService={locationService}
                  />
                </Route>

                <Route path="/bunkerevents">
                  <BunkerEvents serviceLayer={serviceLayer} locationService={locationService} />
                </Route>

                <Route exact path="/recurringevents/:type/:id">
                  <RecurringEvents serviceLayer={serviceLayer} locationService={locationService} />
                </Route>
                <Route exact path="/recurringevents/new">
                  <RecurringEvents
                    openNewNomination={true}
                    serviceLayer={serviceLayer}
                    locationService={locationService}
                  />
                </Route>
                {canAccessRecurringNominations(auth) && (
                  <Route path="/recurringevents">
                    <RecurringEvents serviceLayer={serviceLayer} locationService={locationService} />
                  </Route>
                )}

                {canAccessRecurringNominations(auth) && (
                  <Route path="/recurringnominations">
                    <RecurringOverview locationService={locationService} />
                  </Route>
                )}

                <Route exact path="/requestquotation/:type/:id">
                  <SpotEnquiry serviceLayer={serviceLayer} locationService={locationService} />
                </Route>
                <Route exact path="/requestquotation/new/:id">
                  <SpotEnquiry openNewSpot={true} serviceLayer={serviceLayer} locationService={locationService} />
                </Route>
                <Route exact path="/requestquotation/new">
                  <SpotEnquiry openNewSpot={true} serviceLayer={serviceLayer} locationService={locationService} />
                </Route>
                <Route path="/requestquotation">
                  <SpotEnquiry serviceLayer={serviceLayer} locationService={locationService} />
                </Route>

                <Route exact path="/AIS/list">
                  <LazyAISList />
                </Route>

                <Route exact path="/bunkeravailability">
                  <LazyBunkerAvailabilityMap />
                </Route>

                <Route path="/AIS">
                  <LazyMap />
                </Route>

                <Route path="/documentarchive">
                  <LazyDocumentMap />
                </Route>

                {/* Have these at the bottom*/}
                
                {checkSupplier(auth) && (
                  <Route path="/schedule">
                    <Schedule serviceLayer={serviceLayer} locationService={locationService} />
                  </Route>
                )}
                <Route exact path="/analytics/:defaultId">
                  <AnalyticsInsights />
                </Route>
                <Route path="/analytics">
                  <AnalyticsInsights />
                </Route>
                {checkSystemAdmin(auth) && (
                  <Route path="/systemadmin">
                    <SystemAdmin locationService={locationService} />
                  </Route>
                )}

                {checkSystemAdmin(auth) && (
                  <Route path="/toolbox">
                    <Toolbox />
                  </Route>
                )}

                {!checkCaptains(auth) && !checkTerminalOperator(auth) && (
                  <Route path="/companyadmin">
                    <CompanyAdmin serviceLayer={serviceLayer} />
                  </Route>
                )}

                <Route path="/sign/go">
                  <SignFlowContainer
                    pathName={window.location.pathname}
                    search={window.location.search}
                    chorusBaseURL={chorusBaseURL}
                    loggedIn={true}
                  />
                </Route>

                <Route path="/sign/return">
                  <SignFlowContainer
                    pathName={window.location.pathname}
                    search={window.location.search}
                    chorusBaseURL={chorusBaseURL}
                    loggedIn={true}
                  />
                </Route>

                <Route path="/help" component={Onboarding} />

                <Route path="/servicecheck">
                  <ServiceCheck />
                </Route>

                <Route path="/cookielist">
                  <CookieList />
                </Route>

                {!checkReceivingVesselCrew(auth) && !checkBunkerVesselCrew(auth) && !checkTerminalOperator(auth) && (
                  <Route path="/emailsettings">
                    <EmailSettings />
                  </Route>
                )}
                <Route>
                  <PageNotFoundError />
                </Route>
              </Switch>
            </Suspense>
          </UserPermissionProvider>
        )}
      </Layout>
    </ThemeProvider>
  );

  if (!authIsLoaded || authLoading) {
    return <Loading type="auth" />;
  } else if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else if (auth && !auth.isAuthed && isSignRequestPath()) {
    const pathName = window.location.pathname;
    const search = window.location.search;
    const chorusBaseURL = getChorusBackendURL();
    return (
      <SignFlowContainer
        pathName={pathName}
        search={search}
        chorusBaseURL={chorusBaseURL}
        loggedIn={false}
      ></SignFlowContainer>
    );
  } else if (auth && !auth.isAuthed) {
    return (
      <ErrorLayout>
        <LoginMessage />
      </ErrorLayout>
    );
  } else {
    return standardLayout;
  }
};

export default App;
