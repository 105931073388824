import React, { useCallback } from 'react';
import { Nomination } from '../../../../store/Nomination/models';
import { ILocation, IPromptNomination, IShipWithId } from '@teqplay/chorus-components';
import CountDown from '../CountDown/CountDown';
import styles from './NominationList.module.scss';
import Moment from 'react-moment';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '../../../Loading';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import {
  getNominationsInfiniteScroll,
  getNominationsInfiniteScrollRefresh,
} from '../../../../store/Nomination/actions';
import { useDispatch } from 'react-redux';
import {
  getArchivedNominationsInfiniteScroll,
  getArchivedNominationsInfiniteScrollRefresh,
} from '../../../../store/ArchivedNomination/actions';

const archiveIcon = findIconDefinition({ prefix: 'fal', iconName: 'archive' });
const banIcon = findIconDefinition({ prefix: 'fal', iconName: 'ban' });

interface OwnProps {
  nominations: Nomination[];
  chooseNomination: (nominationId: string) => void;
  activeNominationId: string;
  displayingArchivedNominations: boolean;
}

const NominationList: React.FC<OwnProps> = ({
  nominations,
  chooseNomination,
  activeNominationId,
  displayingArchivedNominations,
}) => {
  const displayNomintion = (nom: Nomination) => {
    return (
      <button
        key={nom._id}
        className={`${styles['nomination-button']} ${styles[nom.attributes.fbStatus]} ${
          activeNominationId === nom.eventId ? styles['active'] : ''
        }`}
        onClick={() => {
          chooseNomination(nom.eventId);
        }}
      >
        <div className={styles['eta-wrapper']}>
          {displayingArchivedNominations && nom.attributes.fbStatus === 'cancelled' && (
            <FontAwesomeIcon className={styles['cancelled-icon']} icon={banIcon} />
          )}
          {displayingArchivedNominations && nom.attributes.fbStatus === 'completed' && (
            <FontAwesomeIcon className={styles['completed-icon']} icon={archiveIcon} />
          )}
          {!displayingArchivedNominations && <CountDown date={nom.bst} />}
        </div>
        <div className={styles['right']}>
          <div className={styles['header']}>{nom.attributes.receivingShipName}</div>
          <div className={styles['description']}>
            <Moment date={nom.bst} format="DD MMM YYYY" />,{' ' + nom.attributes.port}
          </div>
        </div>
      </button>
    );
  };

  const dispatch = useDispatch();

  const getNominationsPaginatedCallback = useCallback((page: number) => {
    return dispatch(getNominationsInfiniteScroll(page));
  }, []);

  const getNominationsPaginatedRefreshCallback = useCallback((page: number) => {
    return dispatch(getNominationsInfiniteScrollRefresh(page));
  }, []);

  const getArchivedNominationsPaginatedCallback = useCallback((page: number) => {
    return dispatch(getArchivedNominationsInfiniteScroll(page));
  }, []);

  const getArchivedNominationsPaginatedRefreshCallback = useCallback((page: number) => {
    return dispatch(getArchivedNominationsInfiniteScrollRefresh(page));
  }, []);

  return (
    <div className={`${styles['nomination-cards']}`}>
      <InfiniteScroll<Nomination>
        //@ts-ignore
        fetchNextPage={
          displayingArchivedNominations ? getArchivedNominationsPaginatedCallback : getNominationsPaginatedCallback
        }
        uniqueKey="eventId"
        initialPage={0}
        renderItem={(item) => {
          return displayNomintion(item);
        }}
        //loadingComponent={<Loading type="nominations" />}
        //@ts-ignore
        refresh={
          displayingArchivedNominations
            ? getArchivedNominationsPaginatedRefreshCallback
            : getNominationsPaginatedRefreshCallback
        }
        refreshInterval={60 * 1000} //60sec
        data={nominations}
        loadFirstPageInitialy={false}
        pageSize={20}
      />
    </div>
  );
};

export default NominationList;
