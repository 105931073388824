import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { ApplicationState } from '../../store';
import { Loading } from '../Loading/index';
import { Error } from '../Error/index';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import RecurringNominationListContainer from './Components/RecurringNominationList/RecurringNominationListContainer';
import NominationTableContainer from './Components/NominationTable/NominationTableContainer';
import { Nomination as NominationModel } from '../../store/Nomination/models';
import { getCompanies } from '../../store/Company/actions';
import { RecurringNomination } from '../../store/RecurringNomination/models';
import { getRecurringNominations } from '../../store/RecurringNomination/actions';
import { LocationService } from '@teqplay/chorus-components';

interface OwnProps {
  chooseNomination: (nominationId: string) => void;
  className?: string;
  setNewNomination: (newNomination: boolean) => void;
  displayAsTable?: boolean;
  activeNominationId?: string;
  archivedAsInitialTab?: boolean;
  sortByNeedsAction?: boolean;
  minified?: boolean;
  shipOwner: boolean | undefined;
  locationService: LocationService;
}

const RecurringNominations: React.FC<OwnProps> = ({
  chooseNomination,
  className,
  setNewNomination,
  displayAsTable,
  activeNominationId,
  archivedAsInitialTab = false,
  sortByNeedsAction,
  minified,
  shipOwner,
  locationService,
}) => {
  const {
    recurringNominationLoading,
    recurringNominationError,
    recurringNominationErrorDetails,
    recurringNomination,
    recurringNominationIsLoaded,
    recurringNominationMetaData,
    companies,
    companiesIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    recurringNominationLoading: state.recurring_nomination.loading,
    recurringNominationError: state.recurring_nomination.error,
    recurringNominationErrorDetails: state.recurring_nomination.errorDetails,
    recurringNomination: state.recurring_nomination.data,
    recurringNominationIsLoaded: state.recurring_nomination.dataIsLoaded,
    recurringNominationMetaData: state.recurring_nomination.metadata,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);
  const getRecurringNominationCallback = React.useCallback(() => dispatch(getRecurringNominations()), []);

  useEffect(() => {
    if (!recurringNominationIsLoaded || recurringNominationMetaData) {
      getRecurringNominationCallback();
    }

    if (!companiesIsLoaded) {
      getCompaniesCallback();
    }
  }, []);

  React.useEffect(() => {
    //Hide nominations that only have active companies
    const filteredNominations: RecurringNomination[] = [];
    if (shipOwner) {
      recurringNomination.forEach(function (nom, index) {
        const compMatch = companies.find((x) => x.chorusId == nom.vendorCompanyId);
        if (compMatch?.active && compMatch != undefined) {
          filteredNominations.push(nom);
        }
      });
      setActiveCompaniesNominations(filteredNominations);
    } else {
      recurringNomination.forEach(function (nom, index) {
        const compMatch = companies.find((x) => x.chorusId == nom.companyId);
        if (compMatch?.active && compMatch != undefined) {
          filteredNominations.push(nom);
        }
      });
      setActiveCompaniesNominations(filteredNominations);
    }
  }, [recurringNomination, companies]);


  const [activeCompaniesNominations, setActiveCompaniesNominations] = React.useState<RecurringNomination[]>([]);
  const [activeTab, setActiveTab] = useState(archivedAsInitialTab ? 'archived' : 'recurring');

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderRecurringNominations = (archived: boolean, displayAsTable?: boolean) => {
    if (displayAsTable) {
      return <></>;
    } else {
      return (
        <RecurringNominationListContainer
          nominations={activeCompaniesNominations}
          setNewNomination={setNewNomination}
          chooseNomination={chooseNomination}
          activeNominationId={activeNominationId ? activeNominationId : ''}
          minified={minified ? minified : false}
          activeTab={activeTab}
          locationService={locationService}
        />
      );
    }
  };

  return (
    <div className={className}>
      {!minified && (
        <Nav tabs className={`sticky-top bg-white bunker-events-nav`}>
          <div />

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'recurring' })}
              onClick={() => {
                toggle('recurring');
              }}
            >
              Recurring events
            </NavLink>
          </NavItem>
        </Nav>
      )}

      <TabContent activeTab={activeTab}>
        <TabPane tabId="recurring">
          {recurringNominationLoading && <Loading type="recurring nomination" />}
          {recurringNominationError && recurringNominationErrorDetails && <Error error={recurringNominationErrorDetails} />}
          {!recurringNominationLoading && !recurringNominationError && renderRecurringNominations(false, displayAsTable)}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default RecurringNominations;
