import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './SpotCompanyInfo.module.scss';

const actionRequiredIcon = findIconDefinition({ prefix: 'fal', iconName: 'bell' });
const pendingIcon = findIconDefinition({ prefix: 'fal', iconName: 'hourglass-half' });
const completedIcon = findIconDefinition({ prefix: 'fal', iconName: 'file-contract' });
const needsContractIcon = findIconDefinition({ prefix: 'fal', iconName: 'file-contract' });
const cancledIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const confirmedIcon = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
const acceptedIcon = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
const finalisedIcon = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });

const isSelectedIcon = findIconDefinition({ prefix: 'fal', iconName: 'chevron-right' });
interface OwnProps {
  companyName: string;
  status: string;
  isSelected?: boolean;
}

const SpotCompanyInfo: React.FC<OwnProps> = ({ companyName, status, isSelected = false }) => {
  return (
    <div className={`${styles['spot-company-info']}`}>
      <div className={styles['company-name']}>{companyName}</div>
      <div className={styles['spot-company-status']}>
        <div className={styles['company-status']}>
          {status == 'pending' ? (
            <FontAwesomeIcon size={'lg'} icon={pendingIcon} className={styles[status]} />
          ) : status == 'completed' ? (
            <FontAwesomeIcon size={'lg'} icon={completedIcon} className={styles[status]} />
          ) : status == 'cancelled' ? (
            <FontAwesomeIcon size={'lg'} icon={cancledIcon} className={styles[status]} />
          ) : status == 'action-required' ? (
            <FontAwesomeIcon size={'lg'} icon={actionRequiredIcon} className={styles[status]} />
          ) : status == 'confirmed' ? (
            <FontAwesomeIcon size={'lg'} icon={confirmedIcon} className={styles[status]} />
          ) : status == 'accepted' ? (
            <FontAwesomeIcon size={'lg'} icon={acceptedIcon} className={styles[status]} />
          ) : status == 'needs-contract' ? (
            <FontAwesomeIcon size={'lg'} icon={needsContractIcon} className={styles[status]} />
          ) : status == 'finalised' ? (
            <FontAwesomeIcon size={'lg'} icon={finalisedIcon} className={styles[status]} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className={styles['company-selected']}>
        {isSelected ? (
          <FontAwesomeIcon color="black" icon={isSelectedIcon} />
        ) : (
          <FontAwesomeIcon color="transparent" icon={isSelectedIcon} />
        )}
      </div>
    </div>
  );
};

export default SpotCompanyInfo;
