import styled from 'styled-components';
import BaseButton from './BaseButton';

export const PrimaryButton = styled(BaseButton)`
  background-color: ${({ theme: { colors } }) => colors.darkBlue};
  color: ${({ theme: { colors } }) => colors.primaryWhite};
  margin: 1px;
  &:hover {
    background-color: #19367f;
  }
`;

export const CtaButton = styled(BaseButton)`
  background-color: ${({ theme: { colors } }) => colors.landGreen};
  color: ${({ theme: { colors } }) => colors.primaryWhite};
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.landGreenDarkest};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  background-color: ${({ theme: { colors } }) => colors.skyBlueLight};
  border: ${({ theme: { colors } }) => `1px solid ${colors.skyBlueLight}`};
  color: ${({ theme: { colors } }) => colors.digitalBlue};
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.skyBlue};
  }
`;

export const SubtleButton = styled(BaseButton)`
  background-color: ${({ theme: { colors } }) => colors.primaryWhite};
  border: ${({ theme: { colors } }) => `1px solid ${colors.skyBlue}`};
  color: ${({ theme: { colors } }) => colors.digitalBlue};
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.skyBlueLight};
  }
`;

export const FlatButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.digitalBlue};
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.skyBlueLight};
  }
`;
