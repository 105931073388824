import { Dispatch } from 'redux';
import {
  MapActions,
  LATEST_POSITIONS_LOADING,
  LATEST_POSITIONS_FETCH_ERROR,
  GET_LATEST_POSITIONS,
  HISTORIC_POSITIONS_LOADING,
  HISTORIC_POSITIONS_FETCH_ERROR,
  GET_HISTORIC_POSITIONS,
  MYFLEET_IMOS_LOADING,
  MYFLEET_IMOS_FETCH_ERROR,
  MYFLEET_IMOS,
  MAPBOX_TOKEN_FETCH_ERROR,
  MAPBOX_TOKEN_LOADING,
  GET_MAPBOX_TOKEN,
} from './types';
import { api } from '../../helpers/api';
import {
  LatestPositionsResponseModel,
  HistoricPositionsResponseModel,
  MyFleetImosResponseModel,
  MapboxTokenResponseModel,
} from './models';

export const getLatestPositions = () => (dispatch: Dispatch<MapActions>) => {
  dispatch({
    type: LATEST_POSITIONS_LOADING,
  });
  new api<LatestPositionsResponseModel>({
    dispatch,
    errorType: LATEST_POSITIONS_FETCH_ERROR,
  })
    .get(`/map/GetLatestPositions`)
    .then((response) => {
      dispatch({
        type: GET_LATEST_POSITIONS,
        payload: response,
      });
    });
};

export const getHistoricPositions = (fleet: number[], from: string, to: string) => (dispatch: Dispatch<MapActions>) => {
  dispatch({
    type: HISTORIC_POSITIONS_LOADING,
  });
  if (fleet.length == 0) {
    fleet = [0];
  }
  const url = `/map/getpaths/${from}/${to}/${fleet.toString()}`;

  new api<HistoricPositionsResponseModel>({
    dispatch,
    errorType: HISTORIC_POSITIONS_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_HISTORIC_POSITIONS,
        payload: response,
      });
    });
};

export const getMyFleetImos = () => (dispatch: Dispatch<MapActions>) => {
  dispatch({
    type: MYFLEET_IMOS_LOADING,
  });
  new api<MyFleetImosResponseModel>({
    dispatch,
    errorType: MYFLEET_IMOS_FETCH_ERROR,
  })
    .get(`/map/GetMyFleetImos`)
    .then((response) => {
      dispatch({
        type: MYFLEET_IMOS,
        payload: response,
      });
    });
};

export const getMapboxToken = () => (dispatch: Dispatch<MapActions>) => {
  dispatch({
    type: MAPBOX_TOKEN_LOADING,
  });
  new api<MapboxTokenResponseModel>({
    dispatch,
    errorType: MAPBOX_TOKEN_FETCH_ERROR,
  })
    .get(`/map/GetToken`)
    .then((response) => {
      dispatch({
        type: GET_MAPBOX_TOKEN,
        payload: response,
      });
    });
};
