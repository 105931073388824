import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';

const validationMessage = {message: 'Not valid', exculdeEmptyString: true};

export default Yup.object().shape({
    //id: Yup.string().required('Company id required').min(24, 'Must be exactly 24 digits').max(24, 'Must be exactly 24 digits'),
    workspace: Yup.string().matches(integrationAssetConfigs.veracityTenantId.regex, validationMessage).required('Workspace id required'),
    name: Yup.string().matches(integrationAssetConfigs.company.regex, validationMessage).required('Workspace name required'),
    timeStamp: Yup.date().optional(),
})