import { Dispatch } from 'redux';
import {
  ConnectivityCheckActions,
  FUELBOSS_CONNECTIVITY_CHECK_LOADING,
  FUELBOSS_CONNECTIVITY_CHECK_ERROR,
  GET_FUELBOSS_CONNECTIVITY_CHECKS,
  CHORUS_CONNECTIVITY_CHECK_LOADING,
  CHORUS_CONNECTIVITY_CHECK_ERROR,
  GET_CHORUS_CONNECTIVITY_CHECKS,
  MAPBOX_CONNECTIVITY_CHECK_LOADING,
  MAPBOX_CONNECTIVITY_CHECK_ERROR,
  GET_MAPBOX_CONNECTIVITY_CHECKS,
  SIGNALR_CONNECTIVITY_CHECK_LOADING,
  SIGNALR_CONNECTIVITY_CHECK_ERROR,
  GET_SIGNALR_CONNECTIVITY_CHECKS,
  CLEAR_CONNECTIVITY_CHECKS,
  ADD_CONNECTIVITYCHECK_RESULT,
  ADD_CONNECTIVITYCHECK_RESULT_ERROR,
  TQPLAYICONS_CONNECTIVITY_CHECK_LOADING,
  TQPLAYICONS_CONNECTIVITY_CHECK_ERROR,
  GET_TQPLAYICONS_CONNECTIVITY_CHECKS,
  VERACITY_CONNECTIVITY_CHECK_LOADING,
  VERACITY_CONNECTIVITY_CHECK_ERROR,
  GET_VERACITY_CONNECTIVITY_CHECKS,
} from './types';
import { api } from '../../helpers/api';
import { ConnectivityCheckResultResponseModel, ConnectivityResponseModel, ConnectivityCheckResult } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const checkFuelbossConnectivity = (url: string) => (dispatch: Dispatch<ConnectivityCheckActions>) => {
  dispatch({
    type: FUELBOSS_CONNECTIVITY_CHECK_LOADING,
  });

  new api<ConnectivityResponseModel>({
    dispatch,
    errorType: FUELBOSS_CONNECTIVITY_CHECK_ERROR,
  })
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_FUELBOSS_CONNECTIVITY_CHECKS,
        payload: response,
      });
    });
};

export const checkChorusConnectivity = (url: string, token: string) => (
  dispatch: Dispatch<ConnectivityCheckActions>,
) => {
  dispatch({
    type: CHORUS_CONNECTIVITY_CHECK_LOADING,
  });

  new api<ConnectivityResponseModel>({
    dispatch,
    errorType: CHORUS_CONNECTIVITY_CHECK_ERROR,
  })
    .get(url, token)
    .then((response) => {
      dispatch({
        type: GET_CHORUS_CONNECTIVITY_CHECKS,
        payload: response,
      });
    });
};

export const checkMapboxConnectivity = (url: string, token: string) => (
  dispatch: Dispatch<ConnectivityCheckActions>,
) => {
  dispatch({
    type: MAPBOX_CONNECTIVITY_CHECK_LOADING,
  });

  new api<ConnectivityResponseModel>({
    dispatch,
    errorType: MAPBOX_CONNECTIVITY_CHECK_ERROR,
  })
    .get(url, token)
    .then((response) => {
      dispatch({
        type: GET_MAPBOX_CONNECTIVITY_CHECKS,
        payload: response,
      });
    });
};

export const checkTqplayIconsConnectivity = (url: string) => (dispatch: Dispatch<ConnectivityCheckActions>) => {
  dispatch({
    type: TQPLAYICONS_CONNECTIVITY_CHECK_LOADING,
  });

  //new api<ConnectivityResponseModel>({
  //  dispatch,
  //  errorType: TQPLAYICONS_CONNECTIVITY_CHECK_ERROR,
  //})
  fetch(url)
    .then((response) => {
      dispatch({
        type: GET_TQPLAYICONS_CONNECTIVITY_CHECKS,
        payload: response,
      });
    });
};

export const checkSignalRConnectivity = (url: string) => (dispatch: Dispatch<ConnectivityCheckActions>) => {
  dispatch({
    type: SIGNALR_CONNECTIVITY_CHECK_LOADING,
  });

  new api<ConnectivityResponseModel>({
    dispatch,
    errorType: SIGNALR_CONNECTIVITY_CHECK_ERROR,
  })
    .get(`/api/UserAsset`)
    .then((response) => {
      dispatch({
        type: GET_SIGNALR_CONNECTIVITY_CHECKS,
        payload: response,
      });
    });
};

export const checkVeracityNotificationsConnectivity = (url: string) => (
  dispatch: Dispatch<ConnectivityCheckActions>,
) => {
  dispatch({
    type: VERACITY_CONNECTIVITY_CHECK_LOADING,
  });

  new api<ConnectivityResponseModel>({
    dispatch,
    errorType: VERACITY_CONNECTIVITY_CHECK_ERROR,
  })
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_VERACITY_CONNECTIVITY_CHECKS,
        payload: response,
      });
    });
};

export const clearConnectivityChecks = () => (dispatch: Dispatch<ConnectivityCheckActions>) => {
  dispatch({
    type: CLEAR_CONNECTIVITY_CHECKS,
  });
};

export const addConnectivityResult = (result: ConnectivityCheckResult) => (
  dispatch: Dispatch<ConnectivityCheckActions>,
) => {
  new api<ConnectivityCheckResultResponseModel>({
    dispatch,
    errorType: ADD_CONNECTIVITYCHECK_RESULT_ERROR,
  })
    .put(`/api/ConnectivityCheck`, result)
    .then((response) => {
      dispatch({
        type: ADD_CONNECTIVITYCHECK_RESULT,
        payload: response,
      });
    });
};
