import React from 'react';
import { Report } from '../../../../../store/Reports/models';
import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import FormikWrapper from '../Formik/FormikWrapper';

interface OwnProps {
  addNewReport: (report: Report) => void;
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
}

const AddReport: React.FunctionComponent<OwnProps> = ({ addNewReport, companyAdmin, navAdminType, company }) => {
  const submitReport = (report: Report) => {
    addNewReport(report);
  };

  return (
    <FormikWrapper
      companyAdmin={companyAdmin}
      addReport={submitReport}
      header="Add report"
      company={company}
      navAdminType={navAdminType}
    />
  );
};

export default React.memo(AddReport);
