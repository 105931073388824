import * as React from 'react';
import { connect } from 'react-redux';
import { FbButton } from '../../components/Button/Button';
import styles from './Toolbox.module.scss';
import { Form, FormGroup, Label, Input, FormText, FormFeedback, CustomInput } from 'reactstrap';
import { Loading } from '../../components/Loading';
import Table from '../../components/Table';

const Toolbox = () => (
  <div className={`${styles['toolbox']} scroller-wrapper`}>
    <h1>Toolbox</h1>
    <section>
      <h2>Typography</h2>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
    </section>

    <section>
      <h2>Buttons</h2>
      <div>Our buttons are wrapped in our own component named FbButton to easily customize them, if necessary.</div>
      <div className={styles['flex-wrapper']}>
        <FbButton color="primary">primary</FbButton> <FbButton color="secondary">secondary</FbButton>{' '}
        <FbButton color="success">success</FbButton> <FbButton color="info">info</FbButton>{' '}
        <FbButton color="warning">warning</FbButton> <FbButton color="danger">danger</FbButton>{' '}
        <FbButton color="link">link</FbButton>
      </div>
      <div className={styles['flex-wrapper']}>
        <FbButton outline color="primary">
          primary
        </FbButton>{' '}
        <FbButton outline color="secondary">
          secondary
        </FbButton>{' '}
        <FbButton outline color="success">
          success
        </FbButton>{' '}
        <FbButton outline color="info">
          info
        </FbButton>{' '}
        <FbButton outline color="danger">
          danger
        </FbButton>{' '}
        <FbButton outline color="link">
          link
        </FbButton>
      </div>
    </section>
    <section>
      <h2>Form</h2>
      <div>
        Form components are used directly from reactstrap. The following are taken directly from their{' '}
        <a href="https://reactstrap.github.io/components/form/">example</a>
      </div>
      <div>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input type="password" name="password" id="examplePassword" placeholder="password placeholder" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleSelect">Select</Label>
            <Input type="select" name="select" id="exampleSelect">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exampleSelectMulti">Select Multiple</Label>
            <Input type="select" name="selectMulti" id="exampleSelectMulti" multiple>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Text Area</Label>
            <Input type="textarea" name="text" id="exampleText" />
          </FormGroup>
          <FormGroup>
            <Label for="exampleFile">File</Label>
            <Input type="file" name="file" id="exampleFile" />
            <FormText color="muted">
              This is some placeholder block-level help text for the above input. It is a bit lighter and easily wraps
              to a new line.
            </FormText>
          </FormGroup>
          <FormGroup tag="fieldset">
            <legend>Radio Buttons</legend>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio1" /> Option one is this and that—be sure to include why it is great
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" name="radio1" /> Option two can be something else and selecting it will deselect
                option one
              </Label>
            </FormGroup>
            <FormGroup check disabled>
              <Label check>
                <Input type="radio" name="radio1" disabled /> Option three is disabled
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" /> Check me out
            </Label>
          </FormGroup>
          <FbButton>Submit</FbButton>
        </Form>
      </div>
    </section>
    <section>
      <h2>Form validation</h2>
      <Form>
        <FormGroup>
          <Label for="exampleEmail">Input without validation</Label>
          <Input />
          <FormFeedback>You will not be able to see this</FormFeedback>
          <FormText>Example help text that remains unchanged.</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Valid input</Label>
          <Input valid />
          <FormFeedback valid>Sweet! that name is available</FormFeedback>
          <FormText>Example help text that remains unchanged.</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Invalid input</Label>
          <Input invalid />
          <FormFeedback>Oh noes! that name is already taken</FormFeedback>
          <FormText>Example help text that remains unchanged.</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Input without validation</Label>
          <Input />
          <FormFeedback tooltip>You will not be able to see this</FormFeedback>
          <FormText>Example help text that remains unchanged.</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="exampleEmail">Valid input</Label>
          <Input valid />
          <FormFeedback valid tooltip>
            Sweet! that name is available
          </FormFeedback>
          <FormText>Example help text that remains unchanged.</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="examplePassword">Invalid input</Label>
          <Input invalid />
          <FormFeedback tooltip>Oh noes! that name is already taken</FormFeedback>
          <FormText>Example help text that remains unchanged.</FormText>
        </FormGroup>
      </Form>
    </section>
    <section>
      <h2>Custom inputs</h2>
      <Form>
        <FormGroup>
          <Label for="exampleCheckbox">Checkboxes</Label>
          <div>
            <CustomInput type="checkbox" id="exampleCustomCheckbox" label="Check this custom checkbox" />
            <CustomInput type="checkbox" id="exampleCustomCheckbox2" label="Or this one" />
            <CustomInput type="checkbox" id="exampleCustomCheckbox3" label="But not this disabled one" disabled />
            <CustomInput
              type="checkbox"
              id="exampleCustomCheckbox4"
              label="Can't click this label to check!"
              htmlFor="exampleCustomCheckbox4_X"
              disabled
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCheckbox">Radios</Label>
          <div>
            <CustomInput type="radio" id="exampleCustomRadio" name="customRadio" label="Select this custom radio" />
            <CustomInput type="radio" id="exampleCustomRadio2" name="customRadio" label="Or this one" />
            <CustomInput type="radio" id="exampleCustomRadio3" label="But not this disabled one" disabled />
            <CustomInput
              type="radio"
              id="exampleCustomRadio4"
              label="Can't click this label to select!"
              htmlFor="exampleCustomRadio4_X"
              disabled
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCheckbox">Switches</Label>
          <div>
            <CustomInput
              type="switch"
              id="exampleCustomSwitch"
              name="customSwitch"
              label="Turn on this custom switch"
            />
            <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" label="Or this one" />
            <CustomInput type="switch" id="exampleCustomSwitch3" label="But not this disabled one" disabled />
            <CustomInput
              type="switch"
              id="exampleCustomSwitch4"
              label="Can't click this label to turn on!"
              htmlFor="exampleCustomSwitch4_X"
              disabled
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCheckbox">Inline</Label>
          <div>
            <CustomInput type="checkbox" id="exampleCustomInline" label="An inline custom input" inline />
            <CustomInput type="checkbox" id="exampleCustomInline2" label="and another one" inline />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomSelect">Custom Select</Label>
          <CustomInput type="select" id="exampleCustomSelect" name="customSelect">
            <option value="">Select</option>
            <option>Value 1</option>
            <option>Value 2</option>
            <option>Value 3</option>
            <option>Value 4</option>
            <option>Value 5</option>
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomMutlipleSelect">Custom Multiple Select</Label>
          <CustomInput type="select" id="exampleCustomMutlipleSelect" name="customSelect" multiple>
            <option value="">Select</option>
            <option>Value 1</option>
            <option>Value 2</option>
            <option>Value 3</option>
            <option>Value 4</option>
            <option>Value 5</option>
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomSelectDisabled">Custom Select Disabled</Label>
          <CustomInput type="select" id="exampleCustomSelectDisabled" name="customSelect" disabled>
            <option value="">Select</option>
            <option>Value 1</option>
            <option>Value 2</option>
            <option>Value 3</option>
            <option>Value 4</option>
            <option>Value 5</option>
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomMutlipleSelectDisabled">Custom Multiple Select Disabled</Label>
          <CustomInput type="select" id="exampleCustomMutlipleSelectDisabled" name="customSelect" multiple disabled>
            <option value="">Select</option>
            <option>Value 1</option>
            <option>Value 2</option>
            <option>Value 3</option>
            <option>Value 4</option>
            <option>Value 5</option>
          </CustomInput>
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomRange">Custom Range</Label>
          <CustomInput type="range" id="exampleCustomRange" name="customRange" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomFileBrowser">File Browser</Label>
          <CustomInput type="file" id="exampleCustomFileBrowser" name="customFile" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomFileBrowser2">File Browser with Custom Label</Label>
          <CustomInput type="file" id="exampleCustomFileBrowser2" name="customFile" label="Yo, pick a file!" />
        </FormGroup>
        <FormGroup>
          <Label for="exampleCustomFileBrowser3">File Browser Disabled</Label>
          <CustomInput type="file" id="exampleCustomFileBrowser3" name="customFile" disabled />
        </FormGroup>
      </Form>
    </section>

    <section>
      <h2>Loading</h2>
      <Loading type="some data" />;
    </section>

    <section>
      <h2>Table</h2>

      <Table headers={['#', 'First Name', 'Last Name', 'UserName']} numberOfRowsPerPagination={5}>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
      </Table>
    </section>
  </div>
);

export default Toolbox;
