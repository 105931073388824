import {
  LatestPositionsWrapperModel,
  HistoricPositionsWrapperModel,
  MyFleetImosWrapperModel,
  MapboxTokenWrapperModel,
} from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const LATEST_POSITIONS_LOADING = 'latest_positions/loading';
export type LATEST_POSITIONS_LOADING = typeof LATEST_POSITIONS_LOADING;

export const GET_LATEST_POSITIONS = 'latest_positions/get';
export type GET_LATEST_POSITIONS = typeof GET_LATEST_POSITIONS;

export const LATEST_POSITIONS_FETCH_ERROR = 'latest_positions/fetchError';
export type LATEST_POSITIONS_FETCH_ERROR = typeof LATEST_POSITIONS_FETCH_ERROR;

export const HISTORIC_POSITIONS_LOADING = 'historic_positions/loading';
export type HISTORIC_POSITIONS_LOADING = typeof HISTORIC_POSITIONS_LOADING;

export const GET_HISTORIC_POSITIONS = 'historic_positions/get';
export type GET_HISTORIC_POSITIONS = typeof GET_HISTORIC_POSITIONS;

export const HISTORIC_POSITIONS_FETCH_ERROR = 'historic_positions/fetchError';
export type HISTORIC_POSITIONS_FETCH_ERROR = typeof HISTORIC_POSITIONS_FETCH_ERROR;

export const MYFLEET_IMOS_LOADING = 'myfleet_imos/loading';
export type MYFLEET_IMOS_LOADING = typeof MYFLEET_IMOS_LOADING;

export const MYFLEET_IMOS = 'myfleet_imos/get';
export type MYFLEET_IMOS = typeof MYFLEET_IMOS;

export const MYFLEET_IMOS_FETCH_ERROR = 'myfleet_imos/fetchError';
export type MYFLEET_IMOS_FETCH_ERROR = typeof MYFLEET_IMOS_FETCH_ERROR;

export const MAPBOX_TOKEN_LOADING = 'mapbox_token/loading';
export type MAPBOX_TOKEN_LOADING = typeof MAPBOX_TOKEN_LOADING;

export const GET_MAPBOX_TOKEN = 'mapbox_token/get';
export type GET_MAPBOX_TOKEN = typeof GET_MAPBOX_TOKEN;

export const MAPBOX_TOKEN_FETCH_ERROR = 'mapbox_token/fetchError';
export type MAPBOX_TOKEN_FETCH_ERROR = typeof MAPBOX_TOKEN_FETCH_ERROR;

export type MapActions =
  | {
      type: GET_LATEST_POSITIONS;
      payload: LatestPositionsWrapperModel['data'];
    }
  | {
      type: LATEST_POSITIONS_LOADING;
    }
  | {
      type: LATEST_POSITIONS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_HISTORIC_POSITIONS;
      payload: HistoricPositionsWrapperModel['data'];
    }
  | {
      type: HISTORIC_POSITIONS_LOADING;
    }
  | {
      type: HISTORIC_POSITIONS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: MYFLEET_IMOS;
      payload: MyFleetImosWrapperModel['data'];
    }
  | {
      type: MYFLEET_IMOS_LOADING;
    }
  | {
      type: MYFLEET_IMOS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_MAPBOX_TOKEN;
      payload: MapboxTokenWrapperModel['data'];
    }
  | {
      type: MAPBOX_TOKEN_LOADING;
    }
  | {
      type: MAPBOX_TOKEN_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
