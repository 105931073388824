import React, { useState, useEffect } from 'react';
import { useBdnConfigurationService, BdnConfigurationForm } from '@teqplay/chorus-components';
import { BdnConfiguration as BdnConfig } from '@teqplay/chorus-components';
import { Auth } from '../../store/Auth/models';
import { AddToast } from '../../helpers/toastService';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import Button from '../SharedStyledComponents/ButtonsThemed';
import BackNavigation from '../BackNavigation/BackNavigation';
import { CustomError } from '../Error/components/CustomError/index';

interface OwnProps {
  auth: Auth;
  goBack: () => void;
  selectedConfigurationId: BdnConfig['_id'];
  isNew?: boolean;
  serviceLayer: AuthenticationServiceLayer;
}

export const EditBDNConfiguration: React.FC<OwnProps> = ({ auth, selectedConfigurationId, goBack, isNew = false, serviceLayer }) => {

  const onError = () => {
    const action = isNew ? 'create' : 'edit';
    AddToast({
      content: 'Unable to ' + action + 'BDN template',
      type: 'error',
      toastId: 'editBDNTemplate',
    });
    goBack();
  };

  const onActionSuccess = (x: any) => {
    const action = isNew ? 'created' : 'modified';
    if (x != 'FETCH') {
      AddToast({
        content: 'BDN template ' + action,
        type: 'success',
        toastId: 'editBDNTemplate',
      });
      goBack();
    }
  };

  const { bdnConfigurationsList, createOrUpdateBdnConfiguration } = useBdnConfigurationService(
    serviceLayer,
    auth.chorusUserProfile.companyId ? auth.chorusUserProfile.companyId : '',
    undefined,
    onError,
    onActionSuccess,
  );

  const [matchingConfiguration, setActiveConfiguration] = useState<undefined | null | BdnConfig>(null);

  useEffect(() => {
    if (selectedConfigurationId && bdnConfigurationsList && bdnConfigurationsList.length > 0) {
      setActiveConfiguration(bdnConfigurationsList?.find((conf) => conf._id === selectedConfigurationId));
    }
  }, [bdnConfigurationsList, selectedConfigurationId]);

  if (auth && auth.company && matchingConfiguration && !isNew) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack} />}
        <BdnConfigurationForm
          createOrUpdateBdnConfiguration={createOrUpdateBdnConfiguration}
          configuration={matchingConfiguration}
        />
      </>
    );
  } else if (auth && auth.company && isNew) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack} />}
        <BdnConfigurationForm createOrUpdateBdnConfiguration={createOrUpdateBdnConfiguration} configuration={undefined} />
      </>
    );
  } else if (matchingConfiguration === undefined) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack}>Go back to BDN template list</BackNavigation>}
        <CustomError
          errorTitle={"Error! Can't find BDN configuration"}
          errorMessage={
            "We are sorry, but we can't find the BDN configuration. There seem to be some mismatches in our data. Please contact us if you believe this is wrong."
          }
          displayContact={true}
        ></CustomError>
      </>
    );
  } else {
    return null;
  }
};
