import * as React from 'react'
import {
  IPromptNomination,
  INominationEnquiry,
  INominationEnquiryEvent
} from '@teqplay/chorus-components'
import { NavigationButton } from '@teqplay/teqplay-ui'
import { RecurringNomination } from '../../../../../store/RecurringNomination/models'

interface IProps {
  selectedItemIndex: number
  latestItemIsSelected: boolean
  eventHistory?: IPromptNomination[] | INominationEnquiry[] | INominationEnquiryEvent[] | RecurringNomination[]
  editFormActive: boolean
  sandbox: boolean
  seePreviousProposal: () => void
  seeNextProposal: () => void
}

export default class HistoryButton extends React.PureComponent<IProps> {
  public render() {
    const {
      selectedItemIndex,
      eventHistory,
      seePreviousProposal,
      seeNextProposal,
      editFormActive,
      sandbox,
      latestItemIsSelected
    } = this.props

    if (editFormActive) {
      return null
    }

    const proposalsAmount = eventHistory ? eventHistory.length : 0

    const buttonText = sandbox
      ? selectedItemIndex === 0
        ? 'Original'
        : 'Sandbox edit'
      : selectedItemIndex + 1 === proposalsAmount
      ? 'Latest Proposal'
      : 'Previous Proposal'

    const stepToShow = `(${
      editFormActive && latestItemIsSelected ? proposalsAmount : selectedItemIndex + 1
    } / ${proposalsAmount})`

    return (
      <NavigationButton
        className="proposal-history large"
        buttonText={`${buttonText} ${stepToShow}`}
        disablePreviousButton={!eventHistory || selectedItemIndex === 0}
        disableNextButton={
          sandbox || !eventHistory || eventHistory.length === selectedItemIndex + 1
        }
        onClickNext={seeNextProposal}
        onClickPrevious={seePreviousProposal}
      ></NavigationButton>
    )
  }
}
