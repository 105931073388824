import * as TYPE from './types';

import { ApplicationState } from '..';

export const categoriesReducer = (
  state: ApplicationState['categories'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.CategoryActions,
): ApplicationState['categories'] => {
  switch (action.type) {
    case TYPE.CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_CATEGORIES:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.CATEGORIES_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.CATEGORIES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.ADD_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.DELETE_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.EDIT_CATEGORY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
