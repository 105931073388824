import React from 'react';
import { ErrorActionPayload } from '../../../../models/ErrorAction';
import { ReactComponent as ErrorIcon } from '../svgs/error-generic-graphic.svg';
import styles from '../../Error.module.scss';

interface OwnProps {
  error: ErrorActionPayload;
}

export const AuthenticationError: React.FC<OwnProps> = React.memo(({ error }) => (
  <div className={styles.errorComponent}>
    <div className={styles['icon-wrapper']}>
      <ErrorIcon className={styles.image} />
      <div className={styles['status-code']}>{error.statusCode}</div>
    </div>
    <h5 className={styles.header}>We are sorry, but you seem to be unauthorized.</h5>
    <p className={styles['error-description']}>Your token might have expired.</p>
    <a className={`btn btn-primary ${styles['continue-button']}`} href="/account/signin">
      Click here to continue
    </a>

    {error.message && <div>{error.message}</div>}
  </div>
));
