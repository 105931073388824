import React from 'react';
import{ IntegrationEventType } from '../../../../../store/IntegrationEventTypes/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import FuelBossTableContainer from '../../../../FuelBossTable';
import { TableOptions, Column, Accessor, UseTableOptions } from 'react-table/index';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
import styled from 'styled-components';
import BackNavigation from '../../../../../components/BackNavigation/BackNavigation';
import Moment from 'react-moment';

const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const minusCircle = findIconDefinition({ prefix: 'fal', iconName: 'minus-circle' });
const checkCircle = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
const PlusCircle = findIconDefinition({  prefix: 'fal', iconName: 'plus-circle'  });

const addIntegrationEventTypeIcon = findIconDefinition({
  prefix: 'fal',
  iconName: 'user-plus',
});

const StyledIntegrationEventTypeTableWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

interface OwnProps {
  integrationEventTypes: IntegrationEventType[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteIntegrationEventType: (id: string) => void;
  editIntegrationEventType: (integrationEventType: IntegrationEventType) => void;
  systemAdmin?: boolean;
  navAdminType: string;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const IntegrationEventTypes: React.FC<OwnProps> = ({
  integrationEventTypes,
  isLoading,
  hasError,
  errorDetails,
  deleteIntegrationEventType,
  editIntegrationEventType,
  systemAdmin,
  navAdminType,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const headersNew: Array<Column<IntegrationEventType>> = React.useMemo(
    () => [
     /* {
        Header: 'Event type id',
        accessor: 'integrationEventTypeId',
      },*/
      {
        Header: 'Event type name',
        accessor: 'eventType',
      },
      {
        Header: 'Time stamp',
        //accessor: 'timeStamp',
        Cell: (props: any) => {
          const timeStamp = props.cell.row.original.timeStamp;
          return(
            <Moment date={timeStamp} format={timeStamp ? "DD MMM, YYYY HH:mm" : ""} />
          );
        },
        accessor: (row) => {
          if (row.timeStamp) return row.timeStamp;
          else return '-';
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const integrationEventType = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={integrationEventType.id ? integrationEventType.id : ''}
                deleteMethod={deleteIntegrationEventType}
                header="Delete integration event"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const integrationEventType = props.cell.row.original;
          return (
            <div className="edit-cell">
              <EditById redirectUrl={`/${navAdminType}admin/integrationassets/${integrationEventType.id}/editevent`} />
            </div>
          );
        },
      },
    ],
    [],
  );

  const history = useHistory();
  const goBack = () => {
    history.push(`/${navAdminType}admin/integrationassets`);
  }

 if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledIntegrationEventTypeTableWrapper>
        {goBack && <BackNavigation onClick={goBack} />}
        <h3>Integration event types</h3>
        <div className="buttons-div">
          <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/integrationassets/addevent`)}>
            <FontAwesomeIcon icon={PlusCircle} /> Add integration event type
          </Button>
        </div>
          <FuelBossTableContainer
            headers={headersNew}
            tableRows={integrationEventTypes}
            showPagination={true}
            defaultSelectedPage={defaultSelectedPage}
            setDefaultSelectedPage={setDefaultSelectedPage}
          />
      </StyledIntegrationEventTypeTableWrapper>
    );
  }
};

export default React.memo(IntegrationEventTypes);
