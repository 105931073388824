import React from 'react';
import { ErrorActionPayload } from '../../../../models/ErrorAction';
import styles from '../../Error.module.scss';
import { ReactComponent as ErrorIcon } from '../svgs/error-generic-graphic.svg';

interface OwnProps {
  error?: ErrorActionPayload;
}

export const ClientError: React.FC<OwnProps> = React.memo(({ error }) => (
  <div className={styles.errorComponent}>
    <div className={styles['icon-wrapper']}>
      <ErrorIcon className={styles.image} />
      <div className={styles['status-code']}>{error ? error.statusCode : 405}</div>
    </div>
    <h2>Error</h2>
    <h5 className={styles.header}>We are sorry, but an error has happened.</h5>
    <div className={styles['error-description']}>
      <p>Try refreshing the page.</p>
      <p>
        If the problem persists, please <a href="mailto:fuelboss@dnv.com">contact us</a> and let us know.
      </p>
    </div>

    <a className={`btn btn-primary ${styles['continue-button']}`} href="/">
      Click here to continue
    </a>
    {error && error.message && <div>{error.message}</div>}
  </div>
));
