export default {
  name: {
    label: 'Contract name/description',
    unit: '',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
    required: true,
  },
  supplierId: {
    label: 'Supplier',
    unit: '',
    show: true,
    placeholder: 'Please choose a supplier',
    required: true,
  },
  customerId: {
    label: 'Customer',
    unit: '',
    show: true,
    placeholder: 'Please choose a customer',
    required: true,
  },
  contractRef: {
    label: 'Contract reference',
    unit: '(reference to existing contract)',
    show: true,
    regex: /^[a-z0-9,!,@,#,$,%,^,&,*,9,\,,\.\?,\~,:,\+,\-, ,\"]*/,
    placeholder: '',
    required: true,
  },
  startDate: {
    label: 'Start date',
    unit: '',
    show: true,
    placeholder: '',
    dateFormat: 'dd/MM/yyyy',
    required: true,
  },
  endDate: {
    label: 'End date',
    unit: '',
    show: true,
    placeholder: '',
    dateFormat: 'dd/MM/yyyy',
    required: true,
  },
  endDateOption: {
    label: 'End date option',
    unit: '',
    show: true,
    placeholder: '',
    dateFormat: 'dd/MM/yyyy',
  },
  signedDate: {
    label: 'Signed date',
    unit: '',
    show: true,
    placeholder: '',
    dateFormat: 'dd/MM/yyyy',
  },
  quantityMinimum: {
    label: 'Contracted minimum quantities',
    unit: '(mt/year)',
    show: true,
    placeholder: '',
  },
  quantityOption: {
    label: 'Optional quantities',
    unit: '(mt/year)',
    show: true,
    placeholder: '',
  },
  pricePerUnit: {
    label: 'Price formula',
    unit: '(price/unit)',
    show: true,
    placeholder: '',
  },
  currency: {
    label: 'Currency',
    unit: '',
    show: true,
    placeholder: 'Please choose a currency',
    selections: [
      { _id: 'USD', name: 'USD' },
      { _id: 'EUR', name: 'EUR' },
      { _id: 'NOK', name: 'NOK' },
    ],
  },
  unit: {
    label: 'Unit',
    unit: '',
    show: true,
    placeholder: 'Please choose a unit',
    selections: [
      { _id: 'm3', name: 'm3' },
      { _id: 'mt', name: 'mt' },
      { _id: 'MWh', name: 'MWh' },
      { _id: 'MMBtu', name: 'MMBtu' },
    ],
  },
  calorificBasis: {
    label: 'Calorific basis',
    unit: '',
    show: true,
    placeholder: 'Please choose a calorific basis',
    selections: [
      { _id: 'Net', name: 'Net' },
      { _id: 'Gross', name: 'Gross' },
    ],
  },
  notes: {
    label: 'Notes',
    unit: '',
    show: true,
    placeholder: '',
  },
  negotiateOnPrice: {
    label: 'Negotiate on price?',
    unit: '',
    show: true,
    placeholder: '',
  },
  rejectReason: {
    label: 'Reject reason',
    unit: '',
    show: true,
    placeholder: '',
  },
  customerNumber: {
    label: 'Supplier\'s customer ID/reference',
    unit: '',
    show: true,
    placeholder: '',
  },
  invoiceAddress: {
    label: 'Invoice address',
    unit: '',
    show: true,
    placeholder: '',
  },
  invoiceEmailAddress: {
    label: 'Invoice email address',
    unit: '',
    show: true,
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
    placeholder: '',
  },
  additionalFees: {
    label: 'Additional fees',
    unit: '',
    show: true,
    placeholder: '',
  },
  contractBunkerLocations: {
    label: 'Agreed bunkering ports',
    show: true,
    placeholder: 'Select ports',
  },
  priceForPort: {
    label: 'Price for port',
    show: true,
    placeholder: 'Select price',
  },
  allowedBunkeringTime: {
    label: 'Allowed bunkering time (Hours)',
    show: true,
    placeholder: 'Select hours',
  },
  active: {
    label: 'Is contract active?',
    show: true,
    placeholder: 'Active',
  },
};
