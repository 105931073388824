import * as TYPE from './types';

import { ApplicationState } from '..';

export const chorusCompanyReducer = (
  state: ApplicationState['choruscompany'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.ChorusCompanyActions,
): ApplicationState['choruscompany'] => {
  switch (action.type) {
    case TYPE.CHORUSCOMPANY_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.CHORUSCOMPANY_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_CHORUSCOMPANY:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
