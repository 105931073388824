import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getCompanies } from '../../../../../store/Company/actions';
import { Company } from '../../../../../store/Company/models';
import { getRoles } from '../../../../../store/Roles/actions';
import { Role } from '../../../../../store/Roles/models';
import { getAuth } from '../../../../../store/Auth/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { RoleType } from '../../../../../models/RoleType';
import usePrevious from '../../../../../helpers/usePrevious';
import { getFleetVessels } from '../../../../../store/CompanyFleet/actions';
import { renderTextInput, renderComplexDropdown, renderSimpleDropdown, renderCheckbox } from '../../../../Form/FormInputs';
import integrationAssetConfigs from './integrationAssetConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import {IntegrationAssetType} from '../../../../../store/IntegrationAssetType/models';
import { assetTypeOptions } from '../../../../IntegrationTypes/IntegrationTypes';

const StyledInnerForm = styled.div`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
  margin-top: 1em;
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
  }
  .buttonContainer button{
    justify-self: flex-end;
    margin-left: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    flex: 0 1 40%;
    margin-right: 35px;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
  .form-check {
    margin-right: 5px;
  }
`;

interface OwnProps {
  integrationAssetType?: IntegrationAssetType;
  header: string;
  saveMethod: (integrationAssetType: IntegrationAssetType) => void;
  systemAdminPage?: boolean;
  formikProps: FormikProps<IntegrationAssetType>;
  navAdminType: string;
}

const IntegrationAssetTypeForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  integrationAssetType,
  systemAdminPage = false,
  navAdminType,
  formikProps,
}) => {
  const { authLoading, authError, authErrorDetails, authIsLoaded } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const onFormSave = () => {
    formikProps.submitForm();
    //const roleArray = getRolesArray();

    const integrationAssetTypeToSave: IntegrationAssetType = {
      ...formikProps.values,
      //  active: true,
    };
    if (integrationAssetType) {
      integrationAssetTypeToSave.id = integrationAssetType.id;
    }
    if (formikProps.isValid) {
      saveMethod(integrationAssetTypeToSave);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetConfigs, errors, touched, setFieldValue, setFieldTouched };

  if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save integration asset type"
          cancelButtonText="Cancel"
          formUrl="integrationassets/types"
        />
        <StyledInnerForm as={Form}>
          {renderSimpleDropdown('assetType', assetTypeOptions, false, 'assetType', props)}
        </StyledInnerForm>
      </StyledForm>
    );
  }
};

export default React.memo(IntegrationAssetTypeForm);
