import React, { useCallback, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Company } from '../../../../../store/Company/models';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { Form, FormikProps } from 'formik';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  renderCheckbox,
  renderComplexDropdown,
  renderSimpleDropdown,
  renderTextAreaInput,
  renderTextInput,
  renderLabel,
} from '../../../../Form/FormInputs';
import { setCompanyFormEditMode } from '../../../../../store/Company/actions';
import { getCategories } from '../../../../../store/Category/actions';
import companyConfigs from './companyConfigs';
import { countries } from 'countries-list';
import { FormHeader } from '../../../../Form/FormHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import editButtonStyles from '../../../../../components/EditById/EditById.module.scss';
import FilesUpload from '../../../../../components/FilesUpload/FilesUpload';
import { deleteCompanyLogo } from '../../../../../store/CompanyLogo/actions';

const cross = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const save = findIconDefinition({ prefix: 'fal', iconName: 'save' });
const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });

const StyledInnerForm = styled(Form)`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div<{ fullWidth: boolean }>`
  .formHeaderWrapper {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    margin-bottom: 1em;
    border-bottom: 1px solid #ccc;
    .iconWrapper {
      padding: 5px 11px;
      border-radius: 5px;
      background-color: rgb(204, 236, 248);
      margin: 0 10px 0 0;
      transition: background-color 300ms ease-in-out;
      width: 40px;
      height: 35px;
      &:hover {
        background-color: rgb(153, 217, 240);
      }
    }
  }
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
  }
  .buttonContainer button {
    justify-self: flex-end;
    margin-left: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    width: 399px;
    flex: 0 35%;
    margin-right: 5em;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
`;

const StyledLogoWrapper = styled.div`
  height: 150px;
  position: relative;
  border-radius: 5px;
  text-align: center;
  display: flex;
  border: 1px solid #e5e5e5;
  padding: 10px;
  margin: 0 0 20px;
  align-items: center;
  justify-content: center;
  top: -23px;
  button {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 7px !important;
  }
  button svg {
    width: 1.2rem !important;
  }
  button:hover {
    background-color: #dedede;
    border-color: black;
  }
  span {
    width: 50%;
    top: 35%;
    overflow: auto;
    margin: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
  :hover {
    cursor: pointer;
    background-color: #e5e5e5;
  }
  img {
    width: auto;
    height: auto;
    position: relative;
    max-width: 312px;
    max-height: 150px;
  }
`;

const StyledLogoLabel = styled.span`
  font-size: 14px;
  color: #bbb;
  height: 23px;
  position: relative;
  top: -29px;
  left: 120px;
`;

const StyledModalWrapper = styled.div`
  background-color: #09122b;
  color: white;
  border: none;
  .modal-header {
    border: none !important;
    text-align: center;
    display: block;
    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      color: white;
    }
  }
  .modal-body {
    border: none !important;
    position: relative;
    flex: 1 1 auto !important;
    padding: 0 20px;
  }
  .modal-footer {
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px;
    border-top: 1px solid #dedede;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    button.btn-secondary {
      font-size: 20px;
      border-radius: 7px;
      font-weight: 400;
      border: none;
      background: rgb(9, 18, 43);
      color: #999;
      border: 1px solid #999;
      margin-left: 5px;
    }
    button.btn-secondary:hover {
      background: #555;
      color: #fff;
      border-color: #555;
      transition: all 0.5s ease 0s;
    }
    button.btn-primary {
      background-color: rgb(36, 54, 92) !important;
      border-color: rgb(36, 54, 92) !important;
      color: rgb(255, 255, 255) !important;
      font-weight: 600 !important;
      transition: all 0.5s ease 0s !important;
      font-size: 20px !important;
      padding: 7px 20px !important;
      margin-right: 5px !important;
    }
    button.btn-primary:hover {
      color: #fff !important;
      background-color: #003591 !important;
      border-color: #003591 !important;
    }
  }
  .modal-footer > :not(:first-child) {
    margin-left: 0px;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  button.btn-primary,
  button.btn-secondary {
    display: flex !important;
    -webkit-box-pack: end !important;
    justify-content: center !important;
    float: none !important;
  }
  button.btn-primary {
    width: 100px !important;
  }
  button.btn-secondary {
    width: 75px !important;
  }
`;

const StyledIconsContainer = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-bottom: 0px;
  position: relative;
  right: -18px;
  top: -40px;
  button {
    width: 43px;
    height: 39px;
    padding: 0.1rem;
  }
  .btn-primary > .svg-inline--fa.fa-w-14 {
    width: 0.75em;
  }
  .btn-secondary > .svg-inline--fa.fa-w-10 {
    width: 0.55em;
    color: #24365c;
  }
`;

const StyledCollumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface OwnProps {
  saveMethod: (company: Company) => void;
  company?: Company;
  header: string;
  systemAdmin?: boolean;
  navAdminType: string;
  formikProps: FormikProps<Company>;
  saveLogoMethod?: (companyId: string, logo: string, mimeType: string) => void;
  companyLogo?: Uint8Array[];
}

const CompanyForm: React.FC<OwnProps> = ({
  company,
  header,
  saveMethod,
  systemAdmin = false,
  formikProps,
  navAdminType,
  saveLogoMethod,
  companyLogo,
}) => {
  const { categories } = useSelector((state: ApplicationState) => ({
    categories: state.categories.data,
  }));

  const dispatch = useDispatch();
  const companyFormEditModeCallback = React.useCallback(
    (isEditMode) => dispatch(setCompanyFormEditMode(isEditMode)),
    [],
  );

  const getCategoriesCallback = useCallback(() => {
    dispatch(getCategories());
  }, []);

  const deleteCompanyLogoCallback = useCallback(() => {
    if (company && company?.companyId) dispatch(deleteCompanyLogo(company?.companyId));
  }, []);

  useEffect(() => {
    if (!categories.length) {
      getCategoriesCallback();
    }
  }, [categories]);

  const history = useHistory();

  useEffect(() => {
    formikProps.validateForm();
    return () => {
      if (!company) {
        formikProps.resetForm();
      }
    };
  }, []);

  const onFormSave = () => {
    formikProps.submitForm();
    const companyToSave: Company = {
      ...formikProps.values,
    };
    if (company) {
      companyToSave.companyId = company.companyId;
    }
    if (formikProps.isValid) {
      saveMethod(companyToSave);
    }
  };

  const onEditLogo = () => {
    toggleEditLogoModal();
  };

  const [companyNewLogo, setCompanyNewLogo] = React.useState('');
  const [companyNewLogoMimeType, setCompanyNewLogoMimeType] = React.useState('');
  const handelSaveLogo = () => {
    if (company?.companyId && companyNewLogo && saveLogoMethod) {
      saveLogoMethod(company.companyId, companyNewLogo, companyNewLogoMimeType);
    }
    toggleEditLogoModal();
  };

  //@ts-ignore
  const handleLoadFile = async (file, fileType, fileReader) => {
    if (file == null || fileType == null || fileReader == null) {
      return;
    }
    const loadedFileContent = fileReader.result.split(',')[1];
    setCompanyNewLogo(loadedFileContent);
    setCompanyNewLogoMimeType(fileType);
  };

  //@ts-ignore
  const handleDeleteFile = async (file, fileType) => {
    deleteCompanyLogoCallback();
    setCompanyNewLogo('');
    setCompanyNewLogoMimeType('');
  };

  const [editLogoModal, setEditLogoModal] = React.useState(false);
  const toggleEditLogoModal = () => setEditLogoModal(!editLogoModal);

  const renderImageInput = (
    name: string,
    logo: Uint8Array[] | undefined,
    // @ts-ignore
    { config, errors },
  ) => (
    <div className={`input-${name}`} onClick={() => onEditLogo()}>
      {renderLabel(name, config)}
      <StyledLogoLabel>Appears on all operational documents</StyledLogoLabel>
      <StyledLogoWrapper>
        {logo && logo.length > 1 && <img src={`${'data:image/png;base64,'}${logo}`}></img>}
        {!logo && <span>Upload Company logo</span>}
        <Button className={editButtonStyles['btn-edit']} onClick={() => onEditLogo()}>
          <FontAwesomeIcon icon={edit} size="2x" />
        </Button>
      </StyledLogoWrapper>
    </div>
  );

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: companyConfigs, errors, touched, setFieldValue, setFieldTouched };
  const _countries: object[] = Object.values(countries).map((it) => ({ id: it.name, name: it.name }));

  const isCompanyPage = navAdminType === 'company';
  const FormWrapper = isCompanyPage ? Form : StyledInnerForm;
  const onCancelButtonClick = () =>
    navAdminType === 'system' ? history.push(`/${navAdminType}admin/companies`) : companyFormEditModeCallback(false);

  return (
    <StyledForm {...{ fullWidth: isCompanyPage }}>
      {!isCompanyPage ? (
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save company"
          cancelButtonText="Cancel"
          formUrl="companies"
          onCancelButtonClick={onCancelButtonClick}
        />
      ) : (
        <div className="formHeader">{header}</div>
      )}
      {header === 'Edit company' && navAdminType === 'company' && (
        <StyledIconsContainer>
          {/* <Button color="primary" onClick={onFormSave}>
            <FontAwesomeIcon icon={save} size="2x" />
          </Button>
          <Button color="secondary" onClick={() => companyFormEditModeCallback(false)}>
            <FontAwesomeIcon icon={cross} size="2x" />
          </Button> */}
        </StyledIconsContainer>
      )}
      <hr />

      <FormWrapper>
        <StyledRowWrapper>
          <StyledCollumnWrapper>
            {systemAdmin && renderComplexDropdown('category', categories, !!company, 'categoryName', props)}
            {renderTextInput('name', false, props)}
            {renderSimpleDropdown('country', _countries, false, 'id', props)}
            {renderTextInput('address', false, props)}
            {renderTextInput('orgNumber', false, props)}
            {renderTextInput('invoiceEmailAddress', false, props)}
            {renderTextInput('invoiceAddress', false, props)}
            {renderTextAreaInput('companyInBrief', false, props)}
          </StyledCollumnWrapper>
          <StyledCollumnWrapper>
            {navAdminType === 'company' && renderImageInput('logo', companyLogo, props)}
          </StyledCollumnWrapper>
        </StyledRowWrapper>
        {isCompanyPage && (
          <>
            <StyledButtonWrapper>
              <Button color="primary" onClick={onFormSave}>
                Save
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  navAdminType === 'system'
                    ? history.push(`/${navAdminType}admin/companies`)
                    : companyFormEditModeCallback(false)
                }
              >
                Cancel
              </Button>
            </StyledButtonWrapper>
          </>
        )}
      </FormWrapper>
      <Modal isOpen={editLogoModal} toggle={toggleEditLogoModal} style={{ border: 'none' }}>
        <StyledModalWrapper>
          <ModalHeader toggle={toggleEditLogoModal}>Change company logo</ModalHeader>
          <ModalBody>
            <FilesUpload
              files={
                companyLogo && companyLogo.length > 1
                  ? [
                      {
                        id: 1,
                        name: 'logo.png',
                        url: `/api/CompanyModels/getCompanyLogoImage/${company?.companyId}`,
                        fileData: companyLogo,
                      },
                    ]
                  : undefined
              }
              fileType={'image'}
              //@ts-ignore
              acceptMimeTypes={['image/jpeg', 'image/png']}
              onLoadFile={handleLoadFile}
              onDeleteFile={handleDeleteFile}
              maxNumberFiles={1}
            />
          </ModalBody>
          <ModalFooter>
            <Button className="save-button" color="primary" onClick={handelSaveLogo}>
              Save
            </Button>{' '}
            <Button className="cancel-button" color="secondary" width="20px" onClick={toggleEditLogoModal}>
              Cancel
            </Button>
          </ModalFooter>
        </StyledModalWrapper>
      </Modal>
    </StyledForm>
  );
};

export default React.memo(CompanyForm);
