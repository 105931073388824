import * as React from 'react';
import styles from './CollapseButton.module.scss';

import { ReactComponent as CollapseIcon } from './collapse-chevron.svg';

interface OwnProps {
  direction: 'left' | 'right';
  onClick: () => void;
  className: string;
}

const CollapseButton: React.FC<OwnProps> = ({ direction, onClick, className }) => (
  <button
    className={`${styles['wrapper']} ${styles[direction]} ${className}`}
    onClick={() => {
      onClick();
    }}
  >
    <CollapseIcon className={styles[direction === 'left' ? '' : 'rotate']} />
  </button>
);
export default CollapseButton;
