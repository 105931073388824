import { ErrorActionPayload } from '../../models/ErrorAction';
import { BunkerShipWrapperModel } from './models';

export const BUNKERSHIP_LOADING = 'bunkership/loading';
export type BUNKERSHIP_LOADING = typeof BUNKERSHIP_LOADING;

export const GET_BUNKERSHIP = 'bunkership/get';
export type GET_BUNKERSHIP = typeof GET_BUNKERSHIP;

export const BUNKERSHIP_FETCH_ERROR = 'bunkership/fetchError';
export type BUNKERSHIP_FETCH_ERROR = typeof BUNKERSHIP_FETCH_ERROR;

export type BunkerShipActions =
  | {
      type: GET_BUNKERSHIP;
      payload: BunkerShipWrapperModel['data'];
    }
  | {
      type: BUNKERSHIP_LOADING;
    }
  | {
      type: BUNKERSHIP_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
