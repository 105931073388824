import React, { useState, useCallback, useEffect } from 'react';
import { FuelbossStatus, Nomination } from '../../../../store/Nomination/models';
import styles from './NominationList.module.scss';
import { Button } from 'reactstrap';
import NominationList from './NominationList';
import { ReactComponent as GasPumpIcon } from './../../../../svgs/add-bunker-event.svg';
import MinifiedNominationList from './Components/MinifiedNominationList/MinifiedNominationList';
import StatusFilter from './../../../StatusFilter/StatusFilter';
import NoNominations from '../NoNominations/NoNominations';
import { useSelector, useDispatch } from 'react-redux';
import { getAuth } from '../../../../store/Auth/actions';
import { Loading } from '../../../Loading';
import { Error } from '../../../Error';
import { Category } from '../../../../models/Category';
import { ApplicationState } from '../../../../store';
import { RoleType } from '../../../../models/RoleType';
import { canCreateNominationOnBehalf } from '../../../../helpers/roleChecks';

interface OwnProps {
  nominations: Nomination[];
  className?: string;
  setNewNomination: (newNomination: boolean) => void;
  chooseNomination: (nominationId: string) => void;
  displayingArchivedNominations?: boolean;
  activeNominationId: string;
  minified: boolean;
  activeTab: string;
  nominationLoading: boolean;
}

const NominationListContainer: React.FC<OwnProps> = ({
  nominations,
  className,
  setNewNomination,
  chooseNomination,
  displayingArchivedNominations,
  activeNominationId,
  minified,
  activeTab,
  nominationLoading
}) => {
  const [filteredStates, setFilteredStates] = useState<FuelbossStatus[]>([]);

  const filteredNominations = nominations.filter((nom) => filteredStates.includes(nom.attributes.fbStatus));

  const { authLoading, authError, authErrorDetails, auth, authIsLoaded } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const createNominationButton = () => {
    if (authLoading) {
      return <Loading type="auth" />;
    } else if (authError && authErrorDetails) {
      return <Error error={authErrorDetails} />;
    } else {
      const isCaptain = auth && auth.roles.includes(RoleType.RecVesselCrew);
      if (
        auth.company &&
        auth.company.category.categoryName !== Category.Supplier &&
        !isCaptain &&
        activeTab === 'bunker'
      ) {
        return (
          <Button className={styles['new-nomination-button']} color="primary" onClick={() => setNewNomination(true)}>
            <GasPumpIcon />
          </Button>
        );
      }
      if (canCreateNominationOnBehalf(auth)) {
        return (
          <Button className={styles['new-nomination-button']} color="primary" onClick={() => setNewNomination(true)}>
            <GasPumpIcon />
          </Button>
        );
      }
    }
  };

  return (
    <div className={className}>
      {!minified && (
        <div className={styles['nomination-header']}>
          <StatusFilter
            setFilteredStatuses={setFilteredStates}
            filteredStates={filteredStates}
            displayNormalStatuses={!displayingArchivedNominations}
            displayArchivedStatuses={displayingArchivedNominations === true}
            className={styles['nomination-filters']}
          />
          {createNominationButton()}
        </div>
      )}
      {nominations.length < 1 && !nominationLoading && <NoNominations archived={displayingArchivedNominations === true} />}
      {nominations.length > 0 && !minified && (
        <NominationList
          nominations={filteredNominations}
          chooseNomination={chooseNomination}
          activeNominationId={activeNominationId}
          displayingArchivedNominations={displayingArchivedNominations ? displayingArchivedNominations : false}
        />
      )}
      {nominations.length > 0 && minified && (
        <MinifiedNominationList nominations={filteredNominations} activeNominationId={activeNominationId} />
      )}
    </div>
  );
};

export default NominationListContainer;
