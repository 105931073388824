import * as TYPE from './types';
import { ApplicationState } from '..';

export const vesselsReducer = (
  state: ApplicationState['vessels'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.VesselsActions,
): ApplicationState['vessels'] => {
  switch (action.type) {
    case TYPE.VESSELS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_VESSELS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.VESSELS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_VESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Vessel succesfully created!',
          type: 'success',
        },
      };
    case TYPE.ADD_VESSEL_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not create the add the new vessel. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.DELETE_VESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Vessel succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.DELETE_VESSEL_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not delete the vessel. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_VESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Vessel succesfully edited!',
          type: 'success',
        },
      };
    case TYPE.EDIT_VESSEL_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not edit the vessel. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
  }
  return { ...state };
};
