import { ContractDocumentsWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const CONTRACT_DOCUMENT_LOADING = 'contractdocument/loading';
export type CONTRACT_DOCUMENT_LOADING = typeof CONTRACT_DOCUMENT_LOADING;

export const GET_CONTRACT_DOCUMENT = 'contractdocument/get';
export type GET_CONTRACT_DOCUMENT = typeof GET_CONTRACT_DOCUMENT;

export const CONTRACT_DOCUMENT_FETCH_ERROR = 'contractdocument/fetchError';
export type CONTRACT_DOCUMENT_FETCH_ERROR = typeof CONTRACT_DOCUMENT_FETCH_ERROR;

export const DELETE_CONTRACT_DOCUMENT = 'contractdocument/delete';
export type DELETE_CONTRACT_DOCUMENT = typeof DELETE_CONTRACT_DOCUMENT;

export const DELETE_CONTRACT_DOCUMENT_ERROR = 'contractdocument/deleteError';
export type DELETE_CONTRACT_DOCUMENT_ERROR = typeof DELETE_CONTRACT_DOCUMENT_ERROR;

export const ADD_CONTRACT_DOCUMENT = 'contractdocument/add';
export type ADD_CONTRACT_DOCUMENT = typeof ADD_CONTRACT_DOCUMENT;

export const ADD_CONTRACT_DOCUMENT_ERROR = 'contractdocument/updateError';
export type ADD_CONTRACT_DOCUMENT_ERROR = typeof ADD_CONTRACT_DOCUMENT_ERROR;

export const CONTRACT_DOCUMENT_CLEAR_TOAST = 'contractdocument/clearToast';
export type CONTRACT_DOCUMENT_CLEAR_TOAST = typeof CONTRACT_DOCUMENT_CLEAR_TOAST;

export type CompanyActions =
  | {
      type: GET_CONTRACT_DOCUMENT;
      payload: ContractDocumentsWrapperModel['data'];
    }
  | {
      type: CONTRACT_DOCUMENT_LOADING;
    }
  | {
      type: CONTRACT_DOCUMENT_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_CONTRACT_DOCUMENT;
      payload: ContractDocumentsWrapperModel['data'];
    }
  | {
      type: DELETE_CONTRACT_DOCUMENT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_CONTRACT_DOCUMENT;
      payload: ContractDocumentsWrapperModel['data'];
    }
  | {
      type: ADD_CONTRACT_DOCUMENT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: CONTRACT_DOCUMENT_CLEAR_TOAST;
    };
