import React, { useEffect } from 'react';
//import { ReactComponent as GasPumpIcon } from './../../../../svgs/add-bunker-event.svg';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomInput } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import useInterval from '../../../../helpers/useInterval';

const repeat = findIconDefinition({ prefix: 'fal', iconName: 'repeat-alt' });

type SortBy = 'time' | 'needsAction';
interface OwnProps {
  nominationCreateAllowed: boolean;
  sortBy: SortBy;
  setSortBy: (value: SortBy) => void;
}

const Header: React.FC<OwnProps> = ({ nominationCreateAllowed, sortBy, setSortBy }) => {
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <div className={`${styles['root']} ${nominationCreateAllowed ? styles['shifted'] : ''}`}>
      <div className={styles['left']}>
        <h1>Recurring nominations</h1>
      </div>

      <div className={styles['middle']}></div>
      <div className={styles['right']}>
        {nominationCreateAllowed && (
          <Link color="primary" className={`${styles['nomination-button']} btn btn-primary`} to={'recurringevents/new'}>
            <FontAwesomeIcon icon={repeat} />
            {'New recurring nomination'}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
