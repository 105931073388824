import React from 'react';
import CountDown from '../../../../../../components/Nomination/Components/CountDown/CountDown';
import NominationStatus from '../../../../../../components/Nomination/Components/NominationStatus/NominationStatus';
import { FuelbossStatus } from '../../../../../../store/Nomination/models';
import styles from './CountDownCell.module.scss';

interface OwnProps {
  bst: Date;
  fbStatus: FuelbossStatus;
}

const CountDownCell: React.FC<OwnProps> = ({ bst, fbStatus }) => {
  return (
    <div className={styles['root']}>
      <CountDown date={bst} />
      <div className={styles['status-icon']}>
        <NominationStatus status={fbStatus} />
      </div>
    </div>
  );
};

export default CountDownCell;
