import React, { useState, useEffect } from 'react';
import {
  useBunkerShipService,
  AuthenticationServiceLayer,
  IBunkerShip,
  BunkerAssetLNGContents,
} from '@teqplay/chorus-components';
import { Auth } from '../../store/Auth/models';
import { AddToast } from '../../helpers/toastService';
import Button from '../SharedStyledComponents/ButtonsThemed';
import { CustomError } from '../Error/components/CustomError/index';
import BackNavigation from '../BackNavigation/BackNavigation';

interface OwnProps {
  auth: Auth;
  goBack?: () => void;
  selectedBunkerShipId: IBunkerShip['_id'];
  serviceLayer: AuthenticationServiceLayer;
}

export const EditBunkership: React.FC<OwnProps> = ({ auth, selectedBunkerShipId, goBack, serviceLayer }) => {

  const onError = () => {
    AddToast({
      content: 'Unable to change bunker ship',
      type: 'error',
      toastId: 'editBunkership',
    });
  };

  const onActionSuccess = (x: any) => {
    if (x != 'FETCH') {
      AddToast({
        content: 'Bunker ship edited',
        type: 'success',
        toastId: 'editBunkership',
      });
    }
  };

  const { bunkerShips, updateLNGCompositionData, updateLNGPropertiesData, updateLNGQuality } = useBunkerShipService(
    serviceLayer,
    undefined,
    onError,
    onActionSuccess,
  );

  const [matchingBunkerShip, setActiveBunkerShip] = useState<undefined | null | IBunkerShip>(null);

  useEffect(() => {
    if (selectedBunkerShipId && bunkerShips && bunkerShips.length > 0) {
      setActiveBunkerShip(bunkerShips?.find((ship) => ship._id === selectedBunkerShipId));
    }
  }, [bunkerShips, selectedBunkerShipId]);
  if (auth && auth.company && matchingBunkerShip) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack}>Go back to fleet list</BackNavigation>}
        <BunkerAssetLNGContents
          bunkerAsset={matchingBunkerShip}
          updateLNGCompositionData={updateLNGCompositionData}
          updateLNGPropertiesData={updateLNGPropertiesData}
          updateLNGQuality={updateLNGQuality}
          calculationsEnabled={true}
        />
      </>
    );
  } else if (matchingBunkerShip === undefined) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack}>Go back to fleet list</BackNavigation>}
        <CustomError
          errorTitle={"Error! Can't find bunkership"}
          errorMessage={
            "We are sorry, but we can't find the bunkership. There seem to be some mismatches in our data. Please contact us if you believe this is wrong."
          }
          displayContact={true}
        ></CustomError>
      </>
    );
  } else {
    return null;
  }
};
