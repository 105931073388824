import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { Nomination, NominationResponseModel, PaginatedNominationResponseModel } from './models';
import {
  NominationActions,
  NOMINATION_LOADING,
  NOMINATION_FETCH_ERROR,
  GET_NOMINATION,
  NOMINATION_ARCHIVE_ERROR,
  NOMINATION_CLEAR_TOAST,
  ARCHIVE_NOMINATION,
  GET_NOMINATION_INFINITE_SCROLL_APPEND,
  GET_NOMINATION_INFINITE_SCROLL_REFRESH,
  CLEAR_NOMINATION,
} from './types';
import { IPromptNomination } from '@teqplay/chorus-components';

/* export const getNominations = () => (dispatch: Dispatch<NominationActions>) => {
  dispatch({
    type: NOMINATION_LOADING,
  });
  const url = `api/chorus/GetNominations/` + '2020-01-01T00:00:00' + `/` + '2040-01-01T00:00:00' + `/bunker/`;
  new api<NominationResponseModel>({
    dispatch,
    errorType: NOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: NOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_NOMINATION,
          payload: { data: response },
        });
      }
    });
}; */

export const archiveNomination = (nomination: Nomination, onSuccess?: () => {}) => (
  dispatch: Dispatch<NominationActions>,
) => {
  const url = `api/chorus/ArchiveNomination/${nomination.eventId}`;
  new api<NominationResponseModel>({
    dispatch,
    errorType: NOMINATION_ARCHIVE_ERROR,
  })
    .post(url, null)
    .then(() => {
      dispatch({
        type: ARCHIVE_NOMINATION,
        payload: nomination,
      });
      if (onSuccess) onSuccess();
    });
};

export const getNominationsPaginated = (sortByNeedsAction: boolean, pageIndex: number, pageSize = 10) => (
  dispatch: Dispatch<NominationActions>,
) => {
  dispatch({
    type: NOMINATION_LOADING,
  });
  const url = `api/chorus/GetNominationsPaginated/bunker/?sortByNeedsAction=${sortByNeedsAction}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
  new api<PaginatedNominationResponseModel>({
    dispatch,
    errorType: NOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: NOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_NOMINATION,
          payload: { data: response.page, metaData: { ...response } },
        });
      }
    });
};

export const getNominationsInfiniteScroll = (page: number) => (dispatch: Dispatch<NominationActions>) => {
  const pageSize = 20;
  return new Promise<Nomination[]>((resolve, reject) => {
    dispatch({
      type: NOMINATION_LOADING,
    });
    const url = `api/chorus/GetNominationsInfiniteScroll/bunker/?sortByNeedsAction=${false}&pageIndex=${
      page * pageSize
    }&pageSize=${pageSize}`;
    new api<NominationResponseModel>({
      dispatch,
      errorType: NOMINATION_FETCH_ERROR,
    })
      .get(url)
      .then((response) => {
        //@ts-ignore
        if (response.status) {
          dispatch({
            type: NOMINATION_FETCH_ERROR,
            //@ts-ignore
            payload: {
              clientFault: true,
              serverFault: false,
              //@ts-ignore
              statusCode: response.status,
              //@ts-ignore
              message: response.message,
            },
          });
          reject(new Error('Failed to fetch nominations'));
        } else {
          dispatch({
            type: page > 0 ? GET_NOMINATION_INFINITE_SCROLL_APPEND : GET_NOMINATION_INFINITE_SCROLL_REFRESH,
            payload: { data: response as Nomination[] },
          });
          resolve((response as unknown) as Nomination[]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getNominationsInfiniteScrollRefresh = (page: number) => (dispatch: Dispatch<NominationActions>) => {
  const pageSize = 20;
  const dataSize = (page + 1) * pageSize;
  return new Promise<Nomination[]>((resolve, reject) => {
    dispatch({
      type: NOMINATION_LOADING,
    });
    const url = `api/chorus/GetNominationsInfiniteScroll/bunker/?sortByNeedsAction=${false}&pageIndex=${0}&pageSize=${dataSize}`;
    new api<NominationResponseModel>({
      dispatch,
      errorType: NOMINATION_FETCH_ERROR,
    })
      .get(url)
      .then((response) => {
        //@ts-ignore
        if (response.status) {
          dispatch({
            type: NOMINATION_FETCH_ERROR,
            //@ts-ignore
            payload: {
              clientFault: true,
              serverFault: false,
              //@ts-ignore
              statusCode: response.status,
              //@ts-ignore
              message: response.message,
            },
          });
          reject(new Error('Failed to fetch nominations'));
        } else {
          dispatch({
            type: GET_NOMINATION_INFINITE_SCROLL_REFRESH,
            payload: { data: response as Nomination[]},
          });
          resolve((response as unknown) as Nomination[]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getNominationBySearchBar = (sortByNeedsAction: boolean, search: string, isExclude: boolean) => (
  dispatch: Dispatch<NominationActions>,
) => {
  dispatch({
    type: NOMINATION_LOADING,
  });
  const url = `api/chorus/GetNominationsBySearchBar/bunker/?sortByNeedsAction=${sortByNeedsAction}&search=${search}&isExclude=${isExclude}`;
  new api<NominationResponseModel>({
    dispatch,
    errorType: NOMINATION_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: NOMINATION_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_NOMINATION,
          payload: { data: response },
        });
      }
    });
};

export const clearNominations = () => (dispatch: Dispatch<NominationActions>) => {
  dispatch({
    type: CLEAR_NOMINATION,
  });
};