import { PipelineWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const PIPELINE_LOADING = 'pipeline/loading';
export type PIPELINE_LOADING = typeof PIPELINE_LOADING;

export const GET_PIPELINE = 'pipeline/get';
export type GET_PIPELINE = typeof GET_PIPELINE;

export const PIPELINE_FETCH_ERROR = 'pipeline/fetchError';
export type PIPELINE_FETCH_ERROR = typeof PIPELINE_FETCH_ERROR;

export const ADD_PIPELINE = 'pipeline/add';
export type ADD_PIPELINE = typeof ADD_PIPELINE;

export const PIPELINE_ADD_ERROR = 'pipeline/addError';
export type PIPELINE_ADD_ERROR = typeof PIPELINE_ADD_ERROR;

export const PIPELINE_FETCH_SUCCESS = 'pipeline/fetchSuccess';
export type PIPELINE_FETCH_SUCCESS = typeof PIPELINE_FETCH_SUCCESS;

export const DELETE_PIPELINE = 'pipeline/delete';
export type DELETE_PIPELINE = typeof DELETE_PIPELINE;

export const PIPELINE_DELETE_ERROR = 'pipeline/deleteError';
export type PIPELINE_DELETE_ERROR = typeof PIPELINE_DELETE_ERROR;

export const EDIT_PIPELINE = 'pipeline/edit';
export type EDIT_PIPELINE = typeof EDIT_PIPELINE;

export const PIPELINE_CLEAR_TOAST = 'pipeline/clearToast';
export type PIPELINE_CLEAR_TOAST = typeof PIPELINE_CLEAR_TOAST;

export type PipelineActions =
  | {
      type: GET_PIPELINE;
      payload: PipelineWrapperModel['data'];
    }
  | {
      type: PIPELINE_LOADING;
    }
  | {
      type: PIPELINE_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_PIPELINE;
      payload: PipelineWrapperModel['data'];
    }
  | {
      type: PIPELINE_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: PIPELINE_FETCH_SUCCESS;
    }
  | {
      type: DELETE_PIPELINE;
      payload: PipelineWrapperModel['data'];
    }
  | {
      type: PIPELINE_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: EDIT_PIPELINE;
      payload: PipelineWrapperModel['data'];
    }
  | {
      type: PIPELINE_CLEAR_TOAST;
    };
