export enum RoleType {
  Operator = 'Operator',
  TerminalOperator = 'Terminal Operator',
  TruckOperator = 'Truck Operator',
  Commercial = 'Commercial',
  BackOffice = 'Back Office',
  BunkerVesselCrew = 'Bunker Vessel Crew',
  CaptainScheduler = 'Captain Scheduler',
  ReceivingCaptainScheduler = 'Receiving Vessel Captain Scheduler',
  VesselOperator = 'Vessel Operator',
  Procurement = 'Procurement',
  Administrative = 'Administrative',
  RecVesselCrew = 'Receiving Vessel Crew',
  SysAdmin = 'System Admin',
  CompAdmin = 'Company Admin',
  CompAdminBuyer = 'Company Admin Buyer',
}
