import React, { useCallback, useRef } from 'react';
import styles from './OutboundIntegrationSettings.module.scss';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { CustomInput, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { addIntegrationOutboundApiTracker, editIntegrationOutboundApiTracker, getIntegrationOutboundApiTracker } from '../../../../../store/IntegrationOutboundApiTracker/actions';
import { IntegrationOutboundApiTracker as MyIntegrationOutboundApiTracker, IntegrationStatus } from '../../../../../store/IntegrationOutboundApiTracker/models';
import _ from 'lodash';
import { ReactComponent as EmailSettingsLogo } from '../../../../../svgs/email-settings.svg';
import { Loading } from '../../../../Loading';
import { useHistory } from 'react-router';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkSupplier } from '../../../../../helpers/roleChecks';
import { EventType } from '../../../../IntegrationTypes/IntegrationTypes';
import { OutboundDocumentation } from './OutboundDocumentation/OutboundDocumentation';
import { getIntegrationAssetCompanies } from '../../../../../store/IntegrationAssetCompanies/actions';
import { getIntegrationAssets } from '../../../../../store/IntegrationAsset/actions';
import { getCompanies } from '../../../../../store/Company/actions';
import DownloadPage from '../DownloadPage/DownloadPage';
import Modal from '../../../../Modal/Modal';
import RejectModal from '../../../Contracts/Components/RejectModal/RejectModal';
import ConfirmModal from './ConfirmModal';

interface OwnProps {

}
const StyledModalWrapper = styled.div`
  background-color: #09122b;
  color: white;
  border: none;
  .modal-header {
    border: none !important;
    text-align: center;
    display: block;
    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      color: white;
    }
  }
  .modal-body {
    border: none !important;
    position: relative;
    flex: 1 1 auto !important;
    padding: 0 20px;
  }
  .modal-footer {
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px;
    border-top: 1px solid #dedede;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    button.btn-secondary {
      font-size: 20px;
      border-radius: 7px;
      font-weight: 400;
      border: none;
      background: rgb(9, 18, 43);
      color: #999;
      border: 1px solid #999;
      margin-left: 5px;
    }
    button.btn-secondary:hover {
      background: #555;
      color: #fff;
      border-color: #555;
      transition: all 0.5s ease 0s;
    }
    button.btn-primary {
      background-color: rgb(36, 54, 92) !important;
      border-color: rgb(36, 54, 92) !important;
      color: rgb(255, 255, 255) !important;
      font-weight: 600 !important;
      transition: all 0.5s ease 0s !important;
      font-size: 20px !important;
      padding: 7px 20px !important;
      margin-right: 5px !important;
    }
    button.btn-primary:hover {
      color: #fff !important;
      background-color: #003591 !important;
      border-color: #003591 !important;
    }
  }
  .modal-footer > :not(:first-child) {
    margin-left: 0px;
  }
`;

const StyledCustomSwitch = styled.div`
  .custom-switch .custom-control-label:after {
    background-color: #b4b4b4;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border: 1px solid #24365c;
  }
`;

const IntegrationOutboundApiTrackers: React.FC<OwnProps> = ({ }) => {

  const { authLoading, authError, authErrorDetails, auth, authIsLoaded } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const addApiTrackerSettingsCallback = React.useCallback(
    (settings: MyIntegrationOutboundApiTracker) => dispatch(addIntegrationOutboundApiTracker(settings, () => { })),
    [],
  );

  const editApiTrackerSettingsCallback = React.useCallback(
    (settings: MyIntegrationOutboundApiTracker) => {
      dispatch(editIntegrationOutboundApiTracker(settings, true, () => { }));
    },
    [],
  );

  const getApiTrackerSettingsCallback = React.useCallback(
    () => dispatch(getIntegrationOutboundApiTracker(true)), []);

  const integrationOutboundApiTrackers = useSelector((state: ApplicationState) => state.integration_outbound_api_tracker.data);
  const integrationOutboundApiTrackersLoaded = useSelector((state: ApplicationState) => state.integration_outbound_api_tracker.dataIsLoaded);
  const integrationOutboundApiTrackersToastData = useSelector((state: ApplicationState) => state.integration_outbound_api_tracker.toastData);

  const integrationAssetCompanies = useSelector((state: ApplicationState) => state.integration_assets_companies.data);
  const integrationAssetCompaniesLoading = useSelector((state: ApplicationState) => state.integration_assets_companies.loading);

  const companies = useSelector((state: ApplicationState) => state.companies.data);
  const companiesIsLoaded = useSelector((state: ApplicationState) => state.companies.dataIsLoaded);
  const companiesLoading = useSelector((state: ApplicationState) => state.companies.loading);

  const integrationAssets = useSelector((state: ApplicationState) => state.integration_assets.data);
  const integrationAssetsIsLoaded = useSelector((state: ApplicationState) => state.integration_assets.dataIsLoaded);

  const getAllIntegrationAssetCompanies = useCallback(() => dispatch(getIntegrationAssetCompanies()), []);
  const getAllIntegrationAssets = useCallback(() => dispatch(getIntegrationAssets()), []);
  const getAllCompanies = useCallback(() => dispatch(getCompanies()), []);

  const [isSaveEnabled, setIsSaveEnabled] = React.useState(false);
  const [myApiTrackerSettings, setMyApiTrackerSettings] = React.useState<MyIntegrationOutboundApiTracker>();
  const [rerenderPlaceHolder, setRerenderPlaceHolder] = React.useState(false);


  const [nominationEventTrack, setNominationEventTrack] = React.useState(false);
  const [supplierEventTrack, setSupplierEventTrack] = React.useState(false);
  const [bdnEventTrack, setBdnEventTrack] = React.useState(false);
  //const [bdnPdfEventTrack, setBdnPdfEventTrack] = React.useState(false);

  const [tenantId, setTenantId] = React.useState("");
  const [workspaceId, setWorkspaceId] = React.useState("");
  const [showSettings, setShowSettings] = React.useState(false);

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const checkCircle = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
  const hourGlass = findIconDefinition({ prefix: 'fal', iconName: 'hourglass-half' });
  const bellIcon = findIconDefinition({ prefix: 'fal', iconName: 'bell' });
  const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
  const PlusCircle = findIconDefinition({
    prefix: 'fal',
    iconName: 'plus-circle',
  });

  React.useEffect(() => {
    getApiTrackerSettingsCallback();
  }, []);

  React.useEffect(() => {
    getAllIntegrationAssetCompanies();
  }, []);

  React.useEffect(() => {
    getAllIntegrationAssets();
  }, []);

  React.useEffect(() => {

    getAllCompanies();
  }, []);

  React.useEffect(() => {

    getAllCompanies();
  }, []);

  React.useEffect(() => {
    if (integrationOutboundApiTrackers && integrationAssetCompanies && companies && integrationOutboundApiTrackers.IntegrationStatus === IntegrationStatus.Completed) {


      var findCompany = companies.find((x) => x.companyId === integrationOutboundApiTrackers.CompanyId);
      var findChorusId = integrationAssetCompanies.find((x) => x.companyId === findCompany?.chorusId);

      var findAsset = integrationAssets.find((x) => x.ownerCompanyId === findChorusId?.companyId);

      if (findChorusId) {
        setTenantId(findChorusId.veracityTenantId)
      }

      if (findAsset) {
        setWorkspaceId(findAsset.workspaceId ?? "")
      }
    }

  }, [integrationOutboundApiTrackers, integrationAssetCompanies, companies]);

  React.useEffect(() => {
    if (integrationOutboundApiTrackersLoaded) {
      if (!apiTrackerSettingsExist()) {
        setIsSaveEnabled(true);
        const newApiTrackerSettings = createNewApiTrackerSettings();
        setMyApiTrackerSettings(newApiTrackerSettings);
      } else {

        const loadedSettings = LoadApiTrackerSettings(integrationOutboundApiTrackers);
        setMyApiTrackerSettings(loadedSettings);
        setIsSaveEnabled(false);

      }
    }
  }, [integrationOutboundApiTrackers]);


  const apiTrackerSettingsExist = () => {
    if (
      _.isEmpty(integrationOutboundApiTrackers) ||
      (integrationOutboundApiTrackers.Id == '00000000-0000-0000-0000-000000000000')
    ) {
      setShowSettings(false);
      return false;
    } else {
      setShowSettings(true);
      return true;
    }
  };

  const LoadApiTrackerSettings = (loadedSettings: MyIntegrationOutboundApiTracker) => {
    let mySettings = loadedSettings;

    if (mySettings.TrackAll) {
      setTrack(true);
    } else {
      setNominationEventTrack(mySettings.Nomination)
      setBdnEventTrack(mySettings.BDN);
      //setBdnPdfEventTrack(mySettings.BDNPDF);
      if (checkSupplier(auth)) {
        setSupplierEventTrack(mySettings.SupplierInternal ? mySettings.SupplierInternal : false);
      }
    }
    isSelectAllSwitchChecked();
    setRerenderPlaceHolder(!rerenderPlaceHolder);
    return mySettings;
  };

  const setTrack = (track: boolean) => {
    setNominationEventTrack(track);
    setBdnEventTrack(track);
    //setBdnPdfEventTrack(track);
    if (checkSupplier(auth)) {
      setSupplierEventTrack(track);
    }
  }


  const updateEmailNotificationSettings = (id: string, trackEnabled: boolean, eventTypes: EventType | undefined) => {
    setIsSaveEnabled(true);


    let updatedSettings: MyIntegrationOutboundApiTracker = {
      Id: myApiTrackerSettings?.Id || '',
      CompanyId: myApiTrackerSettings?.CompanyId || '',
      TrackAll: myApiTrackerSettings?.TrackAll || false,
      IntegrationStatus: myApiTrackerSettings?.IntegrationStatus || IntegrationStatus.NewRequest,
      Nomination: myApiTrackerSettings?.Nomination || false,
      BDN: myApiTrackerSettings?.BDN || false,
      SupplierInternal: myApiTrackerSettings?.SupplierInternal || false,
      //BDNPDF: myApiTrackerSettings?.BDNPDF || false,
    };

    if (eventTypes !== undefined) {
      if (eventTypes === EventType.BDN) {
        updatedSettings.BDN = trackEnabled;
        // } else if (eventTypes === EventType.BDNPDF) {
        //   updatedSettings.BDNPDF = trackEnabled;
      } else if (eventTypes === EventType.Nomination) {
        updatedSettings.Nomination = trackEnabled;
      } else if (eventTypes === EventType.SupplierInternal && checkSupplier(auth)) {
        updatedSettings.SupplierInternal = trackEnabled;
      }

      if (!checkSupplier(auth)) {
        if (!updatedSettings.BDN || !updatedSettings.Nomination) {//if (!updatedSettings.BDN|| !updatedSettings.BDNPDF || !updatedSettings.Nomination) {
          updatedSettings.TrackAll = false;
        } else if (updatedSettings.Nomination && updatedSettings.BDN) {//} else if (updatedSettings.Nomination && updatedSettings.BDNPDF && updatedSettings.BDN) {
          updatedSettings.TrackAll = true;
        }
      }

      if (checkSupplier(auth)) {
        if (!updatedSettings.Nomination || !updatedSettings.BDN || !updatedSettings.SupplierInternal) {//if (!updatedSettings.Nomination || !updatedSettings.BDNPDF || !updatedSettings.BDN || !updatedSettings.SupplierInternal) {
          updatedSettings.TrackAll = false;
        } else if (updatedSettings.Nomination && updatedSettings.BDN && updatedSettings.SupplierInternal) {//} else if (updatedSettings.Nomination && updatedSettings.BDNPDF && updatedSettings.BDN && updatedSettings.SupplierInternal) {
          updatedSettings.TrackAll = true;
        }
      }
    } else {
      // Select All switch
      if (id === 'selectAllSwitch') {

        updatedSettings.TrackAll = trackEnabled;
        if (updatedSettings.TrackAll === true) {
          setTrack(true);
          updatedSettings.BDN = true;
          //updatedSettings.BDNPDF = true;
          updatedSettings.Nomination = true;
          if (checkSupplier(auth)) { updatedSettings.SupplierInternal = true; }
        } else {
          setTrack(false);
          updatedSettings.BDN = false;
          //updatedSettings.BDNPDF = false;
          updatedSettings.Nomination = false;
          if (checkSupplier(auth)) updatedSettings.SupplierInternal = false;
        }
      }
    }
    setMyApiTrackerSettings(updatedSettings);
    // Force rerender the checkboxes and switches
    setRerenderPlaceHolder(!rerenderPlaceHolder);
  };



  const createNewApiTrackerSettings = () => {

    let newApiTrackerSettings = integrationOutboundApiTrackers != undefined ? integrationOutboundApiTrackers : ({} as MyIntegrationOutboundApiTracker);

    newApiTrackerSettings.Id = '00000000-0000-0000-0000-000000000000'
    newApiTrackerSettings.TrackAll = true;
    newApiTrackerSettings.IntegrationStatus = 0;
    newApiTrackerSettings.BDN = true;
    //newApiTrackerSettings.BDNPDF = true;
    newApiTrackerSettings.Nomination = true;
    setTrack(true)

    if (auth.company && auth.company.companyId) {
      newApiTrackerSettings.CompanyId = auth.company.companyId;
    }
    if (checkSupplier(auth)) {
      newApiTrackerSettings.SupplierInternal = true;
    }
    return newApiTrackerSettings;
  };

  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    eventType: EventType | undefined,
    uniqueId: string,
  ) => {

    const trackEnabled = event.target.checked;

    if (eventType === EventType.Nomination) {
      setNominationEventTrack(trackEnabled)
    } else if (eventType === EventType.BDN) {
      setBdnEventTrack(trackEnabled);
      // } else if (eventType === EventType.BDNPDF) {
      //   setBdnPdfEventTrack(trackEnabled);
    } else if (eventType === EventType.SupplierInternal) {
      setSupplierEventTrack(trackEnabled);
    }
    updateEmailNotificationSettings(
      uniqueId,
      trackEnabled,
      eventType,
    );
  };

  const DisplaySettingOption = (
    TrackEnabled: boolean,
    eventType: EventType
  ) => {
    const uniqueId = EventType[eventType];
    return (
      <>
        <div className={styles['options-div']}>
          <div className={styles['settings-header']}>
            {uniqueId.replace(/(P|I)/g, ' $1').trim() + " Event"}
          </div>
          <StyledCustomSwitch>
            <CustomInput
              type="switch"
              id={uniqueId}
              label=""
              onChange={(e) => onChangeSwitch(e, eventType, uniqueId)}
              checked={TrackEnabled}
            />
          </StyledCustomSwitch>
        </div>
        <hr className={styles['line-break']} />
      </>
    );
  };

  const onFormSave = () => {
    var apiTrackerSettingsToSave = myApiTrackerSettings;
    if (apiTrackerSettingsToSave) {
      if (!apiTrackerSettingsExist()) {
        //create new api tracker setting
        addApiTrackerSettingsCallback(apiTrackerSettingsToSave);
      } else {
        //update existing api tracker setting
        editApiTrackerSettingsCallback(apiTrackerSettingsToSave);
      }
    }
  };

  const isSelectAllSwitchChecked = () => {
    const isChecked = myApiTrackerSettings ? myApiTrackerSettings.TrackAll : false;


    if (!checkSupplier(auth)) {
      if (!nominationEventTrack || !bdnEventTrack) {//if (!nominationEventTrack || !bdnPdfEventTrack || !bdnEventTrack) {
        return false
      } else if (nominationEventTrack && bdnEventTrack) {//} else if (nominationEventTrack && bdnPdfEventTrack && bdnEventTrack) {
        return true
      }
    }

    if (checkSupplier(auth)) {
      if (!nominationEventTrack || !bdnEventTrack || !supplierEventTrack) {//if ( !nominationEventTrack || !bdnPdfEventTrack || !bdnEventTrack || !supplierEventTrack) {
        return false
      } else if (checkSupplier(auth) && nominationEventTrack && bdnEventTrack && supplierEventTrack) {//}else if (checkSupplier(auth) && nominationEventTrack && bdnPdfEventTrack && bdnEventTrack && supplierEventTrack) {
        return true
      }
    }
    return isChecked;
  };

  const DisplaySettings = () => {
    //{DisplaySettingOption(bdnPdfEventTrack, EventType.BDNPDF)}
    return (
      <>
        {DisplaySettingOption(nominationEventTrack, EventType.Nomination)}
        {DisplaySettingOption(bdnEventTrack, EventType.BDN)}
        {checkSupplier(auth) ? DisplaySettingOption(supplierEventTrack, EventType.SupplierInternal) : ""}
      </>
    );
  };

  const onConfirmClick = () => {
    onFormSave();
    setShowSettings(true);
  }

  const DisplayeStatusIcon = (integrationOutboundApiTrackers: any) => {
    if (integrationOutboundApiTrackers.IntegrationStatus === 0) {
      return (
        <div className={styles['status-div']}>
          <div className={styles['icon-box']}>
            <FontAwesomeIcon icon={bellIcon} size={'3x'} color="#c4262e" />
            New Request
          </div>
        </div>
      );
    } else if (integrationOutboundApiTrackers.IntegrationStatus === 1) {
      return (
        <div className={styles['status-div']}>
          <div className={styles['icon-box']}>
            <FontAwesomeIcon icon={hourGlass} size={'3x'} color="#66c5e9" />
            {IntegrationStatus[integrationOutboundApiTrackers.IntegrationStatus]}
          </div>
        </div>
      );

    } else if (integrationOutboundApiTrackers.IntegrationStatus === 2) {
      return (
        <div className={styles['status-div']}>
          <div className={styles['icon-box']}>
            <FontAwesomeIcon icon={checkCircle} size={'3x'} color="#46aa1e" />
            {IntegrationStatus[integrationOutboundApiTrackers.IntegrationStatus]}
          </div>
        </div>
      );

    } else if (integrationOutboundApiTrackers.IntegrationStatus === 3) {
      return (
        <div className={styles['status-div']}>
          <div className={styles['icon-box']}>
            <FontAwesomeIcon icon={edit} size={'3x'} color="#46aa1e" />
            {IntegrationStatus[integrationOutboundApiTrackers.IntegrationStatus]}
          </div>
        </div>
      );
    }
  };

  if (!integrationOutboundApiTrackersLoaded) {
    return <Loading />;
  } else if (integrationOutboundApiTrackers && showSettings) {
    return (
      <React.Fragment>
        <div className={styles['header']}>
          <div className={styles['email-notifications']}>
            <EmailSettingsLogo className={styles['email-logo']} />
            <div className={styles['email-logo-text']}>Outbound events settings</div>
            <div className={styles['form-buttons-container']}>
              <Button type="submit" color="primary" disabled={!isSaveEnabled} onClick={onFormSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className={styles['content-wrapper']}>
          <div className={styles['settings-wrapper']}>
            <div className={styles['settings-header']} style={{ textAlign: 'center' }}>
              I would like to listen to the following outbound events
            </div>
            {DisplayeStatusIcon(integrationOutboundApiTrackers)}
            <div className={styles['select-all-div']}>
              <div className={styles['select-all-label']}>Select all</div>
              <StyledCustomSwitch>
                <CustomInput
                  type="switch"
                  id="selectAllSwitch"
                  name="customSwitch"
                  label=""
                  onChange={(e) => onChangeSwitch(e, undefined, "selectAllSwitch")}
                  className={styles['custom-switch']}
                  checked={isSelectAllSwitchChecked()}
                />
              </StyledCustomSwitch>
            </div>
            {DisplaySettings()}
          </div>{integrationOutboundApiTrackers.IntegrationStatus == IntegrationStatus.Completed && <div>
            <div className={styles['input-group']}>
              <p className={styles['lable-group']}>Workspace Id: </p>
              <input className={styles['input']}
                type="text"
                value={workspaceId}
                disabled={true}
              />
            </div>
            <div className={styles['input-group']}>
              <p className={styles['lable-group']}>Tenant Id: </p>
              <input className={styles['tenantInput']}
                type="text"
                value={tenantId}
                disabled={true}
              />
            </div>
          </div>}

        </div>
        <div style={{ paddingTop: '200px' }}>      <hr className={styles['soft-line-break']} /></div>


        <div>
          <DownloadPage rootElementId={"documentation"} downloadFileName={"Outbound_ Documentation"}></DownloadPage>
        </div>       
         <div id={"documentation"}style={{ width: '100%' }}>
          <OutboundDocumentation></OutboundDocumentation>
        </div>

      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={styles['header']}>
          <div style={{ paddingRight: '100px' }}>
            <div className={styles['email-notifications']}>
              <EmailSettingsLogo className={styles['email-logo']} />
              <div className={styles['email-logo-text']}>Outbound events settings</div>
            </div>
            <div style={{ textAlignLast: 'center' }}>
              <div className={styles['form-buttons-container']}>
                <Button type="submit" color="primary" onClick={toggle}>
                  Request for outbound integration
                </Button>
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
        onClick={onConfirmClick}
        toggle={toggle}
        showModal={modal}
        />
        {/* {modal && (
          <Modal isOpen={modal} toggle={toggle}  className={styles['position']}>
            <div className={styles['wrapper']}>
              <ModalHeader toggle={toggle} className={styles['modal-header']}>Create request outbound setting</ModalHeader>
              <ModalBody className={styles['body']}>
                Are you sure you want to create request for outbound settings?
              </ModalBody>
              <ModalFooter className={styles['modal-footer']}>
                <Button
                  color="primary"
                  onClick={() => {
                    onFormSave();
                    setShowSettings(true);
                  }}
                >
                  Confirm
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>

            </div>
          </Modal >
        )} */}
      </React.Fragment>
    );
  }
};

export default IntegrationOutboundApiTrackers;