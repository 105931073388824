import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './NominationStatus.module.scss';

import { ReactComponent as ActionRequiredIcon } from '../../../../svgs/icon-alert.svg';
import { ReactComponent as PendingIcon } from '../../../../svgs/icon-pending.svg';
import { ReactComponent as NeedsContractIcon } from '../../../../svgs/icon-contract.svg';
import { ReactComponent as ConfirmedIcon } from '../../../../svgs/icon-confirmed.svg';
import { ReactComponent as CancledIcon } from '../../../../svgs/icon-cancelled.svg';
import { ReactComponent as ArchiveIcon } from '../../../../svgs/ship-to-ship.svg';
const archiveIcon = findIconDefinition({ prefix: 'fal', iconName: 'archive' });
const finalisedIcon = findIconDefinition({ prefix: 'fal', iconName: 'clipboard-check' });
const background = findIconDefinition({ prefix: 'fas', iconName: 'circle' });
const repeat = findIconDefinition({ prefix: 'fal', iconName: 'repeat-alt' });

interface OwnProps {
  status: string | undefined;
}

const NominationStatus: React.FC<OwnProps> = ({ status }) => {
  return (
    <div className={`${styles['nomination-status-icon']}`}>
      {status == 'pending' ? (
        <PendingIcon className={styles[status]} />
      ) : status == 'completed' ? (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={background} size="lg" className={styles['background']} />
          <FontAwesomeIcon
            size={'lg'}
            icon={archiveIcon}
            className={styles[status]}
            style={{ color: 'var(--completed)' }}
          />
        </span>
      ) : status == 'cancelled' ? (
        <CancledIcon className={styles[status]} />
      ) : status == 'action-required' ? (
        <ActionRequiredIcon className={styles[status]} />
      ) : status == 'confirmed' || status === 'accepted' ? (
        <ConfirmedIcon className={styles[status]} />
      ) : status == 'needs-contract' ? (
        <NeedsContractIcon className={styles[status]} />
      ) : status == 'finalised' ? (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={background} size="lg" className={styles['background']} />
          <FontAwesomeIcon
            size={'lg'}
            icon={finalisedIcon}
            className={styles[status]}
            style={{ color: 'var(--completed)' }}
          />
        </span>
      ) : status == 'recurring' ? (
        <FontAwesomeIcon icon={repeat} className={styles[status]} />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default NominationStatus;
