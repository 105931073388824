import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useState } from 'react';
import styles from './RecurringOverview.module.scss';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Category } from '../../models/Category';
import { RoleType } from '../../models/RoleType';
import {
  checkReceivingVesselCrew,
  checkBunkerVesselCrew,
  checkTerminalOperator,
  checkShipOwnerAdministrative,
  canCreateNominationOnBehalf,
  checkCharterer,
  checkCaptainScheduler,
} from '../../helpers/roleChecks';
import Header from './Components/Header/Header';
import { DeliveryModes } from '../../models/DeliveryMode';
import OverviewRecurringList from './Components/OverviewRecurringList/OverviewRecurringList';
import classnames from 'classnames';
import { CachedLocationService } from '@teqplay/chorus-components';

interface OwnProps {
  locationService: CachedLocationService;
}

const RecurringOverview: React.FC<OwnProps> = ({ locationService }) => {
  const auth = useSelector((state: ApplicationState) => state.auth.data);

  const [deliveryModes, setdeliveryModes] = useState([
    DeliveryModes.Container,
    DeliveryModes.Pipe,
    DeliveryModes.Ship,
    DeliveryModes.Truck,
  ]);
  const [sortBy, setSortBy] = useState<'time' | 'needsAction'>('time');
  const [activeBunkerEventTab, setActiveBunkerEventTab] = useState('1');

  const isShipOwner = auth && auth.company?.category.categoryName === Category.Shipowner;
  const isSupplier = auth && auth.company?.category.categoryName === Category.Supplier;
  const isCaptain = auth && auth.roles.includes(RoleType.RecVesselCrew);
  const canCreateRecurringNomination = auth && auth.company?.category.categoryName !== Category.Supplier && !isCaptain;

  if (auth) {
    return (
      <div className={styles['overview-wrapper']}>
        <Header
          nominationCreateAllowed={canCreateRecurringNomination ?? false}
          sortBy={sortBy}
          setSortBy={(value) => setSortBy(value)}
        />
        <div className={styles['content-wrapper']}>
          <Nav tabs className={`${styles['nav']} sticky-top bg-white bunker-events-nav`}>
            <NavItem>
              <NavLink
                className={classnames({ active: activeBunkerEventTab === '1' })}
                onClick={() => setActiveBunkerEventTab('1')}
              >
                Recurring events
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={classnames({ active: activeBunkerEventTab === '2' })}
                onClick={() => {
                  setActiveBunkerEventTab('2');
                }}
              >
                Deleted
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent activeTab={activeBunkerEventTab}>
            <TabPane tabId="1">
              <div className={styles['tab-pane-content']}>
                <OverviewRecurringList
                  deliveryModes={deliveryModes}
                  sortBy={sortBy}
                  userCompanyId={auth.company?.chorusId}
                  isSupplier={isSupplier}
                  fetchData={activeBunkerEventTab === '1'}
                  locationService={locationService}
                />
              </div>
            </TabPane>
            {/* <TabPane tabId="2">
              <div className={styles['tab-pane-content']}>
                <OverviewRecurringList
                  deliveryModes={deliveryModes}
                  sortBy="time"
                  archived
                  fetchData={activeBunkerEventTab === '2'}
                />
              </div>
            </TabPane> */}
          </TabContent>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default RecurringOverview;
