import React from 'react';
import FormikWrapper from '../Formik/FormikWrapper';

import { Contract } from '../../../../../store/Contract/models';
import { ICompany } from '@teqplay/chorus-components';
import { Company } from '../../../../../store/Company/models';
import TimerUpdate from '../../../../TimerUpdate/TimerUpdate';
import { toast } from 'react-toastify';

interface OwnProps {
  editContract: (contract: Contract) => void;
  getContracts: () => void;
  systemAdmin?: boolean;
  customers: ICompany[];
  suppliers: ICompany[];
  contractId: string;
  company?: Company;
  contracts: Contract[];
  navAdminType: string;
  isLoading?: boolean;
}

const EditContract: React.FC<OwnProps> = ({
  editContract,
  getContracts,
  contractId,
  contracts,
  systemAdmin,
  customers,
  suppliers,
  company,
  navAdminType,
  isLoading,
}) => {
  const submitContract = (contract: Contract) => {
    editContract(contract);
    setChangeBySelf(true);
  };

  const [contractEdit, setContractEdit] = React.useState<Contract | undefined>();
  const [changeBySelf, setChangeBySelf] = React.useState<boolean>(false);

  const contract: Contract | undefined = contracts.find((it) => it.contractId === contractId);

  const CustomContractToast = (desc: string | undefined) => {
    if (desc === undefined) desc = 'Contracts';
    return (
      <div>
        <div>New contract information is available.</div>
        {desc} has been edited.
      </div>
    );
  };

  React.useEffect(() => {
    const contractIsUpToDate = JSON.stringify(contract) === JSON.stringify(contractEdit);
    if (contractEdit === undefined) {
      setContractEdit(contracts.find((it) => it.contractId === contractId));
    } else {
      if (!contractIsUpToDate && !changeBySelf) {
        toast(CustomContractToast(contract?.name), {
          toastId: 'contractEditId',
          position: 'bottom-right',
          autoClose: 10000,
          // autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          type: 'warning',
        });
        setContractEdit(contracts.find((it) => it.contractId === contractId));
      }
    }
  }, [contracts]);
  return (
    <>
      <TimerUpdate updateCall={getContracts} />
      <FormikWrapper
        systemAdmin={systemAdmin}
        addContract={submitContract}
        header="Edit Contract"
        customers={customers}
        suppliers={suppliers}
        company={company}
        contract={contract}
        navAdminType={navAdminType}
      />
    </>
  );
};

export default React.memo(EditContract);
