import React from 'react';
import FormikWrapper from '../Formik/FormikWrapper';
import { IntegrationAsset } from '../../../../../store/IntegrationAsset/models';
import { IntegrationAssetApiTracker } from '../../../../../store/IntegrationAssetApiTracker/models';
import { Formik } from 'formik';
import { initialValues } from '../FormInitialValues/integrationAssetApiFormFormInitialValues';
import integrationAssetApiTrackerFormValidation from '../FormValidations/integrationAssetApiTrackerFormValidation';
import { IntegrationAssetTopic } from '../../../../../store/IntegrationAssetTopic/models';
import IntegrationAssetApiTrackerForm from '../IntegrationAssetForm/IntegrationAssetApiTrackerForm';

interface OwnProps {
  addNewIntegrationAsset: (integrationAssetTopic: IntegrationAssetTopic, integrationAsset: IntegrationAsset, fbAssetType: string, fbAssetId: string) => void;
  systemAdmin?: boolean;
  navAdminType: string;
  integrationAssetApiTracker?: IntegrationAssetApiTracker;
  companyId: string | undefined;
}

const AddIntegrationAssetApiTracker: React.FC<OwnProps> = ({
  addNewIntegrationAsset,
  systemAdmin,
  navAdminType,
  integrationAssetApiTracker,
  companyId,
}) => {
  const submitIntegrationAsset = (integrationAssetTopic: IntegrationAssetTopic, integrationAsset: IntegrationAsset, fbAssetType: string, fbAssetId: string) => {
    addNewIntegrationAsset(integrationAssetTopic, integrationAsset, fbAssetType, fbAssetId);
  };

  return (
    <Formik
      initialValues={integrationAssetApiTracker ? integrationAssetApiTracker : initialValues}
      validationSchema={integrationAssetApiTrackerFormValidation}
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetApiTrackerForm
          header={'Add data connection'}
          saveMethod={submitIntegrationAsset}
          integrationAssetApiTracker={integrationAssetApiTracker}
          formikProps={formikProps}
          systemAdmin={systemAdmin}
          navAdminType={navAdminType}
          companyId={companyId}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationAssetApiTracker);
