import {
  INominationEnquiryEvent,
  instanceOfINominationEnquiryEvent,
  instanceOfNominationTimelineItem,
  IPromptNomination,
  NegotiationState
} from '@teqplay/chorus-components'
import Button, { ButtonGroup } from '@teqplay/teqplay-ui'
import React, { useState } from 'react'
import { RecurringNomination } from '../../../../../store/RecurringNomination/models'
import { instanceOfRecurringNomination } from '../Validators/recurringNominationValidation'
import { IFuelbossNegotiationPermissions } from './NegotiationActions'

interface ActionDialogContentProps {
  selectedEvent: IPromptNomination | INominationEnquiryEvent | RecurringNomination
  initialEvent: IPromptNomination | INominationEnquiryEvent | RecurringNomination
  action: 'ACCEPT' | 'REJECT' | 'CANCEL' | 'COMPLETE' | 'FINALISE' | 'CANCEL_DELEGATION' | 'DELETE'
  permissions: IFuelbossNegotiationPermissions
  error?: string
  setActiveDialog: (
    action?: 'ACCEPT' | 'REJECT' | 'CANCEL' | 'COMPLETE' | 'FINALISE' | 'CANCEL_DELEGATION' | 'DELETE'
  ) => void
  changeStateWhenValid: (state: NegotiationState, onBehalf: boolean, reason?: string) => void
  acceptPendingNomination?: (pendingNomination: IPromptNomination) => void
  updateEvent?: (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE' | 'DELETE',
    updatedNomination: IPromptNomination | RecurringNomination
  ) => void
  delegatedCompanyName?: string
  cancelDelegatedNomination?: (reason?: string) => void
}

const ActionDialogContent: React.FunctionComponent<ActionDialogContentProps> = props => {
  const { action, permissions, setActiveDialog } = props
  const eventDescriptionText = instanceOfINominationEnquiryEvent(props.selectedEvent)
    ? 'Enquiry'
    : 'Nomination'
  const [reasonForReject, setReasonForReject] = useState<string | undefined>(undefined)

  if (action === 'ACCEPT') {
    const nominationHasPendingProposal = props.selectedEvent.state === 'PENDING'

    if (
      instanceOfINominationEnquiryEvent(props.selectedEvent) &&
      props.selectedEvent.deliveryModes &&
      props.selectedEvent.deliveryModes.length > 1
    ) {
      return (
        <div>
          <p>{`A spot enquiry can not be approved until a single delivery mode is selected. Please select a single delivery mode before finalising the enquiry.`}</p>

          <ButtonGroup>
            <Button
              preventDoubleClick
              primary
              outline
              onClick={() => {
                setActiveDialog(undefined)
              }}
            >
              Return to Enquiry
            </Button>
          </ButtonGroup>
        </div>
      )
    } else {
      return (
        <div>
          <p>{`Are you sure you want to approve this ${eventDescriptionText.toLowerCase()}?`}</p>
          {props.error && <p className="status error">{props.error}</p>}

          <ButtonGroup>
            {permissions.canAccept && (
              <Button
                primary
                preventDoubleClick
                onClick={() => {
                  if (
                    instanceOfNominationTimelineItem(props.initialEvent) &&
                    nominationHasPendingProposal &&
                    props.acceptPendingNomination
                  ) {
                    props.acceptPendingNomination(props.initialEvent)
                  } else {
                    props.changeStateWhenValid('ACCEPTED', false)
                  }
                }}
              >
                Accept
              </Button>
            )}
          </ButtonGroup>
        </div>
      )
    }
  } else if (action === 'REJECT') {
    return (
      <div>
        <p>{`Are you sure you want to reject this ${eventDescriptionText.toLowerCase()}?`}</p>
        {props.error && <p className="status error">{props.error}</p>}
        <label>Reason for rejection</label>
        <textarea
          className="input"
          onChange={(e: React.ChangeEvent<any>) => {
            setReasonForReject(e.target.value)
          }}
        />

        <ButtonGroup>
          {permissions.canReject && (
            <Button
              preventDoubleClick
              danger
              onClick={() => {
                props.changeStateWhenValid('REJECTED', false, reasonForReject)
                setReasonForReject(undefined)
              }}
            >
              Reject
            </Button>
          )}
        </ButtonGroup>
      </div>
    )
  } else if (action === 'COMPLETE') {
    return (
      <div>
        <p>{`Are you sure you want to complete this ${eventDescriptionText.toLowerCase()}? This change can not be reverted.`}</p>
        {props.error && <p className="status error">{props.error}</p>}

        <ButtonGroup>
          {permissions.canComplete && (
            <Button
              primary
              preventDoubleClick
              onClick={() => {
                if (props.updateEvent && instanceOfNominationTimelineItem(props.initialEvent)) {
                  props.updateEvent('COMPLETE', props.initialEvent)
                } else {
                  console.warn(
                    'missing update function, or trying to complete a non nomination object'
                  )
                }
              }}
            >
              {`Complete ${eventDescriptionText}`}
            </Button>
          )}
        </ButtonGroup>
      </div>
    )
  } else if (action === 'FINALISE') {
    if (
      instanceOfINominationEnquiryEvent(props.selectedEvent) &&
      props.selectedEvent.deliveryModes &&
      props.selectedEvent.deliveryModes.length > 1
    ) {
      return (
        <div>
          <p>{`A spot enquiry can not be finalised until a single delivery mode is selected. Please select a single delivery mode before finalising the enquiry.`}</p>

          <ButtonGroup>
            {permissions.canFinalise && (
              <Button
                primary
                outline
                preventDoubleClick
                onClick={() => {
                  setActiveDialog(undefined)
                }}
              >
                Return to Enquiry
              </Button>
            )}
          </ButtonGroup>
        </div>
      )
    } else {
      return (
        <div>
          <p>{`Are you sure you want to confirm and finalise this ${eventDescriptionText.toLowerCase()}? This change can not be reverted.`}</p>
          {props.error && <p className="status error">{props.error}</p>}

          <ButtonGroup>
            {permissions.canFinalise && (
              <Button
                primary
                preventDoubleClick
                onClick={() => {
                  props.changeStateWhenValid('FINALISED', false)
                }}
              >
                {`Finalise ${eventDescriptionText}`}
              </Button>
            )}
          </ButtonGroup>
        </div>
      )
    }
  } else if (action === 'CANCEL_DELEGATION') {
    return (
      <div>
        <p>You can un-delegate the bunker event.</p>
        {props.error && <p className="status error">{props.error}</p>}
        <label>
          {props.delegatedCompanyName
            ? `Message to ${props.delegatedCompanyName}`
            : 'Reason for cancelling'}
        </label>
        <textarea
          className="input"
          onChange={(e: React.ChangeEvent<any>) => {
            setReasonForReject(e.target.value)
          }}
        />

        <ButtonGroup>
          <Button
            preventDoubleClick
            danger
            onClick={() => {
              if (props.cancelDelegatedNomination) {
                props.cancelDelegatedNomination(reasonForReject)
              }
              setReasonForReject(undefined)
            }}
          >
            Un-delegate bunkering
          </Button>
        </ButtonGroup>
      </div>
    )
  } 
  else if (action === 'DELETE') { // only for recurring nominaiton
    return (
      <div>
        <p>{`You are about to delete the recurring nomination series. Deleting the series also deletes all single events and will not be recoverable.`}</p>
        {props.error && <p className="status error">{props.error}</p>}

        <ButtonGroup>
          {permissions.canDelete && (
            <Button
              preventDoubleClick
              danger
              onClick={() => {
                if (props.updateEvent && instanceOfRecurringNomination(props.initialEvent)) {
                  props.updateEvent('DELETE', props.initialEvent)
                } else {
                  console.warn(
                    'missing update function or trying to complete a non recurring nomination object'
                  )
                }
              }}
            >
              Delete
            </Button>
          )}
        </ButtonGroup>
      </div>
    )
  }
  else {
    return (
      <div>
        <p>{`Are you sure you want to cancel this ${eventDescriptionText.toLowerCase()}?`}</p>
        {props.error && <p className="status error">{props.error}</p>}

        <ButtonGroup>
          {permissions.canCancel && (
            <Button
              preventDoubleClick
              danger
              onClick={() => {
                props.changeStateWhenValid('CANCELLED', false)
              }}
            >
              Cancel
            </Button>
          )}
        </ButtonGroup>
      </div>
    )
  }
}

export default ActionDialogContent
