import React, { useEffect } from 'react';
import { FuelBossLocation } from '../../../../../store/FuelbossLocations/models';
import { Form, FormikProps } from 'formik';
import styled from 'styled-components';
import { renderNumericInput, renderSimpleDropdown, renderTextInput } from '../../../../Form/FormInputs';
import locationsConfigs from './locationsConfigs';
import { countries } from 'countries-list';
import { FormHeader } from '../../../../Form/FormHeader';

const StyledInnerForm = styled.div`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
  margin-top: 1em;
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
  }
  .buttonContainer button {
    justify-self: flex-end;
    margin-left: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    flex: 0 45%;
    margin-right: 5em;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
`;

interface OwnProps {
  saveMethod: (location: FuelBossLocation) => void;
  location?: FuelBossLocation;
  header: string;
  formikProps: FormikProps<FuelBossLocation>;
}

const LocationForm: React.FC<OwnProps> = ({ location, header, saveMethod, formikProps }) => {
  useEffect(() => {
    formikProps.validateForm();
    return () => {
      if (!location) {
        formikProps.resetForm();
      }
    };
  }, []);

  const onFormSave = () => {
    formikProps.submitForm();
    const locationToSave: FuelBossLocation = {
      ...formikProps.values,
    };
    if (location) {
      locationToSave.locationId = location.locationId;
    }
    if (formikProps.isValid) {
      saveMethod(locationToSave);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: locationsConfigs, errors, touched, setFieldValue, setFieldTouched };

  const _countries: object[] = Object.values(countries).map((it) => ({ id: it.name, name: it.name }));
  const purposeOptions: object[] = (locationsConfigs.purpose || {}).selections;
  const typeOptions: object[] = (locationsConfigs.type || {}).selections;

  return (
    <>
      <StyledForm>
        <FormHeader
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save location"
          cancelButtonText="Cancel"
          formUrl="locations"
        />
        <StyledInnerForm as={Form}>
          {renderTextInput('portAbbreviation', false, props)}
          {renderTextInput('port', false, props)}
          {renderTextInput('areaOfOperation', false, props)}
          {renderTextInput('portType', false, props)}
          {renderNumericInput('longitude', false, props)}
          {renderNumericInput('latitude', false, props)}
          {renderSimpleDropdown('country', _countries, false, 'id', props)}
          {renderTextInput('countryAbbreviation', false, props)}
          {renderSimpleDropdown('purpose', purposeOptions, false, 'id', props)}
          {renderSimpleDropdown('type', typeOptions, false, 'id', props)}
          {renderSimpleDropdown('timezone', [], false, 'id', props)}
        </StyledInnerForm>
      </StyledForm>
    </>
  );
};

export default React.memo(LocationForm);
