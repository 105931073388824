import { Dispatch } from 'redux';
import {
  EmailSettingsActions,
  EMAILSETTINGS_LOADING,
  EMAILSETTINGS_FETCH_ERROR,
  GET_EMAILSETTINGS,
  ADD_EMAILSETTINGS,
  EMAILSETTINGS_ADD_ERROR,
  DELETE_EMAILSETTINGS,
  EDIT_EMAILSETTINGS,
  EMAILSETTINGS_DELETE_ERROR,
  EMAILSETTINGS_EDIT_ERROR,
  EMAILSETTINGS_CLEAR_TOAST
} from './types';
import { api } from '../../helpers/api';
import { EmailSettingsResponseModel, EmailSettings } from './models';
import { Role } from '../Roles/models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating Email Settings...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Email Settings updated successfully</ModalResponse>;
const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating Email Settings...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Email Settings created successfully</ModalResponse>;
const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getEmailSettings = () => (dispatch: Dispatch<EmailSettingsActions>) => {
  dispatch({
    type: EMAILSETTINGS_LOADING,
  });

  new api<EmailSettingsResponseModel>({
    dispatch,
    errorType: EMAILSETTINGS_FETCH_ERROR,
  })
    .get(`/api/EmailSettings`)
    .then((response) => {
      dispatch({
        type: GET_EMAILSETTINGS,
        payload: response,
      });
    });
};

export const addEmailSettings = (eSettings: EmailSettings, onSuccess: () => void) => (
  dispatch: Dispatch<EmailSettingsActions>,
) => {
  dispatch({
    type: EMAILSETTINGS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<EmailSettingsResponseModel>({
    dispatch,
    errorType: EMAILSETTINGS_ADD_ERROR,
  })
    .post(`/api/EmailSettings`, eSettings)
    .then((response) => {  
      dispatch({
        type: ADD_EMAILSETTINGS,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteEmailSettings = (id: string) => (dispatch: Dispatch<EmailSettingsActions>) => {
  dispatch({
    type: EMAILSETTINGS_LOADING,
  });

  const url = `/api/EmailSettings/${id}`;
  new api<EmailSettingsResponseModel>({
    dispatch,
    errorType: EMAILSETTINGS_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_EMAILSETTINGS,
        payload: response,
      });
    });
};


export const editEmailSettings = (eSettings: EmailSettings, onSuccess: () => void) => (
  dispatch: Dispatch<EmailSettingsActions>,
) => {
  dispatch({
    type: EMAILSETTINGS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (eSettings.id != undefined) {
    const url = `/api/EmailSettings/${eSettings.id}`;

    new api<EmailSettingsResponseModel>({
      dispatch,
      errorType: EMAILSETTINGS_EDIT_ERROR,
    })
      .put(url, eSettings)
      .then((response) => {
        dispatch({
          type: EDIT_EMAILSETTINGS,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const emailSettingsClearToast = () => (dispatch: Dispatch<EmailSettingsActions>) => {
  dispatch({
    type: EMAILSETTINGS_CLEAR_TOAST,
  });
};
