import React, { useCallback, useEffect, useState } from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Company } from '../../../../../store/Company/models';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { Form, FormikProps } from 'formik';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  renderCheckbox,
  renderComplexDropdown,
  renderSimpleDropdown,
  renderTextAreaInput,
  renderTextInput,
  renderLabel,
} from '../../../../Form/FormInputs';
import styles from './IntegrationAssetForm.module.scss';
import { getCompanies, setCompanyFormEditMode } from '../../../../../store/Company/actions';
import { getCategories } from '../../../../../store/Category/actions';
import { countries } from 'countries-list';
import { FormHeader } from '../../../../Form/FormHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import editButtonStyles from '../../../../../components/EditById/EditById.module.scss';
import FilesUpload from '../../../../../components/FilesUpload/FilesUpload';
import { deleteCompanyLogo } from '../../../../../store/CompanyLogo/actions';
import { IntegrationAsset } from '../../../../../store/IntegrationAsset/models';
import integrationAssetconfigs from './integrationAssetConfigs';
import { setIntegrationAssetFormEditMode } from '../../../../../store/IntegrationAsset/actions';
import { getIntegrationAssetCompanies } from '../../../../../store/IntegrationAssetCompanies/actions';
import { getIntegrationAssetTypes } from '../../../../../store/IntegrationAssetType/actions';
import {
  deleteIntegrationAssetIssuers,
  getIntegrationAssetIssuers,
} from '../../../../../store/IntegrationAssetIssuer/actions';
import { IntegrationAssetIssuer } from '../../../../../store/IntegrationAssetIssuer/models';
import {
  deleteIntegrationAssetPermissions,
  getIntegrationAssetPermissions,
} from '../../../../../store/IntegrationAssetPermissions/actions';
import {
  deleteIntegrationAssetTopic,
  getIntegrationAssetTopic,
} from '../../../../../store/IntegrationAssetTopic/actions';
import DeleteById from '../../../../DeleteById/DeleteById';
import FuelBossTableContainer from '../../../../FuelBossTable';
import { Column } from 'react-table';
import Moment from 'react-moment';
import { TopicDataType, IntegrationAssetTopic } from '../../../../../store/IntegrationAssetTopic/models';
import { IntegrationAssetPermissions } from '../../../../../store/IntegrationAssetPermissions/models';
import { assetTypeOptions } from '../../../../IntegrationTypes/IntegrationTypes';
import { checkShipOwner } from '../../../../../helpers/roleChecks';
import { Category } from '../../../../../models/Category';
import { IntegrationAssetWorkspace } from '../../../../../store/IntegrationAssetWorkspaces/models';


const cross = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const save = findIconDefinition({ prefix: 'fal', iconName: 'save' });
const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const PlusCircle = findIconDefinition({ prefix: 'fal', iconName: 'plus-circle' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

const StyledInnerForm = styled(Form)`
  width: 50%;
  @media (max-width: 1920px) {
    width: 80%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div<{ fullWidth: boolean }>`
  .formHeaderWrapper {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    margin-bottom: 1em;
    border-bottom: 1px solid #ccc;
    .iconWrapper {
      padding: 5px 11px;
      border-radius: 5px;
      background-color: rgb(204, 236, 248);
      margin: 0 10px 0 0;
      transition: background-color 300ms ease-in-out;
      width: 40px;
      height: 35px;
      &:hover {
        background-color: rgb(153, 217, 240);
      }
    }
  }
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
  }
  .buttonContainer button {
    justify-self: flex-end;
    margin-left: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    width: 399px;
    flex: 0 35%;
    margin-right: 5em;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
`;

const StyledFormSectionHeader = styled.div`
  color: rgb(0, 0, 0);
  line-height: 24px;
  font-weight: bold;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: left;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  font-size: 24px;
  margin: 15px 0px 10px;
  :before {
    content: '';
    border-top: 1px solid rgb(204, 204, 204);
    margin: 0px 10px 0px 0px;
    flex: 0 0 43px;
  }
  :after {
    content: '';
    border-top: 1px solid rgb(204, 204, 204);
    margin: 0px 20px 0px 10px;
    flex: 1 0 20px;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  button.btn-primary,
  button.btn-secondary {
    display: flex !important;
    -webkit-box-pack: end !important;
    justify-content: center !important;
    float: none !important;
  }
  button.btn-primary {
    width: 100px !important;
  }
  button.btn-secondary {
    width: 75px !important;
  }
`;
const StyledIntegrationAssetTypeTableWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

const StyledIconsContainer = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-bottom: 0px;
  position: relative;
  right: -18px;
  top: -40px;
  button {
    width: 43px;
    height: 39px;
    padding: 0.1rem;
  }
  .btn-primary > .svg-inline--fa.fa-w-14 {
    width: 0.75em;
  }
  .btn-secondary > .svg-inline--fa.fa-w-10 {
    width: 0.55em;
    color: #24365c;
  }
`;

const StyledCollumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTopicWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  padding-top: 10px;
  margin-top: 10px;
`;
const StyledPermissionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 32%;
  padding-top: 10px;
  margin-top: 10px;
  div[class^='delete-cell'] {
    right: 100px;
  }
`;
const StyledIssuersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 399px;
  padding-top: 10px;
  margin-top: 10px;
  div[class^='delete-cell-'] {
    right: 100px;
  }
`;

const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface OwnProps {
  saveMethod: (integrationAsset: IntegrationAsset) => void;
  integrationAsset?: IntegrationAsset;
  header: string;
  systemAdmin?: boolean;
  navAdminType: string;
  formikProps: FormikProps<IntegrationAsset>;
}

const IntegrationAssetForm: React.FC<OwnProps> = ({
  integrationAsset,
  header,
  saveMethod,
  systemAdmin = false,
  formikProps,
  navAdminType,
}) => {
  const {
    integrationAssetCompanies,
    integrationAssetCompaniesLoading,
    integrationAssetCompaniesDetailsError,
    integrationAssetCompaniesErrorDetails,
    integrationAssetCompaniesIsLoaded,
    toastDataIntegrationAssetCompanies,

    integrationAssetTypes,
    integrationAssetTypesLoading,
    integrationAssetTypesDetailsError,
    integrationAssetTypesErrorDetails,
    integrationAssetTypesIsLoaded,
    toastDataIntegrationAssetTypes,

    integrationAssetIssuers,
    integrationAssetIssuersLoading,
    integrationAssetIssuersDetailsError,
    integrationAssetIssuersErrorDetails,
    integrationAssetIssuersIsLoaded,
    toastDataIntegrationAssetIssuers,

    integrationAssetPermissions,
    integrationAssetPermissionsLoading,
    integrationAssetPermissionsDetailsError,
    integrationAssetPemissionsErrorDetails,
    integrationAssetPermissionsIsLoaded,
    toastDataIntegrationAssetPermissions,

    integrationAssetTopic,
    integrationAssetTopicLoading,
    integrationAssetTopicDetailsError,
    integrationAssetTopicErrorDetails,
    integrationAssetTopicIsLoaded,
    toastDataIntegrationAssetTopic,

    integrationAssetWorkspaces,
    integrationAssetWorkspacesLoading,
    integrationAssetWorkspacesDetailsError,
    integrationAssetWorkspacesErrorDetails,
    integrationAssetWorkspacesIsLoaded,
    toastDataIntegrationAssetWorkspaces,

    companies,
    companiesDetailsError,
    companiesErrorDetails,
    companiesIsLoaded,
    companiesLoading,
    toastDataCompanies,
  } = useSelector((state: ApplicationState) => ({
    integrationAssetCompanies: state.integration_assets_companies.data,
    integrationAssetCompaniesLoading: state.integration_assets_companies.loading,
    integrationAssetCompaniesDetailsError: state.integration_assets_companies.error,
    integrationAssetCompaniesErrorDetails: state.integration_assets_companies.errorDetails,
    integrationAssetCompaniesIsLoaded: state.integration_assets_companies.dataIsLoaded,
    toastDataIntegrationAssetCompanies: state.integration_assets_companies.toastData,

    integrationAssetTypes: state.integration_assets_types.data,
    integrationAssetTypesLoading: state.integration_assets_types.loading,
    integrationAssetTypesDetailsError: state.integration_assets_types.error,
    integrationAssetTypesErrorDetails: state.integration_assets_types.errorDetails,
    integrationAssetTypesIsLoaded: state.integration_assets_types.dataIsLoaded,
    toastDataIntegrationAssetTypes: state.integration_assets_types.toastData,

    integrationAssetIssuers: state.integration_assets_issuers.data,
    integrationAssetIssuersLoading: state.integration_assets_issuers.loading,
    integrationAssetIssuersDetailsError: state.integration_assets_issuers.error,
    integrationAssetIssuersErrorDetails: state.integration_assets_issuers.errorDetails,
    integrationAssetIssuersIsLoaded: state.integration_assets_issuers.dataIsLoaded,
    toastDataIntegrationAssetIssuers: state.integration_assets_issuers.toastData,

    integrationAssetPermissions: state.integration_assets_permissions.data,
    integrationAssetPermissionsLoading: state.integration_assets_permissions.loading,
    integrationAssetPermissionsDetailsError: state.integration_assets_permissions.error,
    integrationAssetPemissionsErrorDetails: state.integration_assets_permissions.errorDetails,
    integrationAssetPermissionsIsLoaded: state.integration_assets_permissions.dataIsLoaded,
    toastDataIntegrationAssetPermissions: state.integration_assets_permissions.toastData,

    integrationAssetTopic: state.integration_assets_topic.data,
    integrationAssetTopicLoading: state.integration_assets_topic.loading,
    integrationAssetTopicDetailsError: state.integration_assets_topic.error,
    integrationAssetTopicErrorDetails: state.integration_assets_topic.errorDetails,
    integrationAssetTopicIsLoaded: state.integration_assets_topic.dataIsLoaded,
    toastDataIntegrationAssetTopic: state.integration_assets_topic.toastData,

    integrationAssetWorkspaces: state.integration_assets_workspaces.data,
    integrationAssetWorkspacesLoading: state.integration_assets_workspaces.loading,
    integrationAssetWorkspacesDetailsError: state.integration_assets_workspaces.error,
    integrationAssetWorkspacesErrorDetails: state.integration_assets_workspaces.errorDetails,
    integrationAssetWorkspacesIsLoaded: state.integration_assets_workspaces.dataIsLoaded,
    toastDataIntegrationAssetWorkspaces: state.integration_assets_workspaces.toastData,

    companies: state.companies.data,
    companiesLoading: state.companies.loading,
    companiesDetailsError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,
    companiesIsLoaded: state.companies.dataIsLoaded,
    toastDataCompanies: state.companies.toastData,
  }));

  const [isEditMode, setIsEditMode] = useState<boolean>();
  const [listWorkspaces, setListWorkspaces] = React.useState<IntegrationAssetWorkspace[]>([]);
  useEffect(() => {
    const companyId = formikProps.values.company?.companyId;
    if (companyId) {
      const workspacesList = integrationAssetWorkspaces.filter((x) => x.integrationAssetCompanyCompanyId.includes(companyId));
      setListWorkspaces(workspacesList);
    }
  }, [formikProps.values.company]);

  const dispatch = useDispatch();

  const getAllIntegrationAssetCompanies = useCallback(() => dispatch(getIntegrationAssetCompanies()), []);

  const getAllIntegrationAssetTypes = useCallback(() => dispatch(getIntegrationAssetTypes()), []);

  const getAllIntegrationAssetIssuers = useCallback(() => dispatch(getIntegrationAssetIssuers()), []);

  const getAllIntegrationAssetPermissions = useCallback(() => dispatch(getIntegrationAssetPermissions()), []);

  const getAllIntegrationAssetTopic = useCallback(() => dispatch(getIntegrationAssetTopic()), []);

  const deleteIntegrationAssetIssuerCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetIssuers(id)),
    [],
  );

  const deleteIntegrationAssetPermissionsCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetPermissions(id)),
    [],
  );

  const deleteIntegrationAssetTopicCallback = useCallback(
    (id: string) => dispatch(deleteIntegrationAssetTopic(id)),
    [],
  );

  const integrationAssetEditModeCallback = React.useCallback(
    (isEditMode) => dispatch(setIntegrationAssetFormEditMode(isEditMode)),
    [],
  );

  const getCategoriesCallback = useCallback(() => {
    dispatch(getCategories());
  }, []);

  const getAllCompanies = useCallback(() => dispatch(getCompanies()), []);

  const veracityId = formikProps.values.veracityAssetId;

  let issuerList = integrationAssetIssuers.filter((x) => x.veracityAssetId?.includes(veracityId) && x.integrationAssetId === integrationAsset?.id);

  let permissionsList = integrationAssetPermissions.filter((x) => x.veracityAssetId?.includes(veracityId));

  let topicList = integrationAssetTopic.filter((x) => x.veracityAssetId.includes(veracityId) && x.integrationAssetId === integrationAsset?.id);

  const [integrationAssetCompanyType, setIntegrationAssetCompanyType] = useState<string>('');

  useEffect(() => {
    if (!integrationAssetCompaniesIsLoaded) {
      getAllIntegrationAssetCompanies();
    }
  }, [integrationAssetCompaniesIsLoaded]);

  useEffect(() => {
    if (!integrationAssetTypesIsLoaded) {
      getAllIntegrationAssetTypes();
    }
  }, [integrationAssetTypesIsLoaded]);

  useEffect(() => {
    if (!integrationAssetIssuersIsLoaded) {
      getAllIntegrationAssetIssuers();
    }
  }, [integrationAssetIssuersIsLoaded]);

  useEffect(() => {
    if (!integrationAssetTopicIsLoaded) {
      getAllIntegrationAssetTopic();
    }
  }, [integrationAssetTopicIsLoaded]);

  useEffect(() => {
    if (!integrationAssetPermissionsIsLoaded) {
      getAllIntegrationAssetPermissions();
    }
  }, [integrationAssetPermissionsIsLoaded]);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getAllCompanies();
    }
  }, [companiesIsLoaded]);

  useEffect(() => {
    if (formikProps.values.company) {
      const iAssetCompany = companies.find(x => x.chorusId == formikProps.values.company?.companyId);
      if (iAssetCompany) {
        setIntegrationAssetCompanyType(iAssetCompany.category.categoryName);

      }
    }
  }, [companies, formikProps.values.company]);


  useEffect(() => {
    if (integrationAsset?.id) {
      integrationAssetCompanies.filter((x) => {
        if (x.companyId === integrationAsset.ownerCompanyId) {
          integrationAsset.company = x;
        }
      });
    }
  }, [!integrationAsset]);

  useEffect(() => {
    if (integrationAsset?.id) {
      integrationAssetTypes.filter((x) => {
        if (x.id === integrationAsset.assetTypeId) {
          integrationAsset.type = x;
        }
      });
    }
  }, [!integrationAsset]);

  const history = useHistory();

  useEffect(() => {
    formikProps.validateForm();
    return () => {
      if (!integrationAsset) {
        formikProps.resetForm();
      }
    };
  }, []);

  useEffect(() => {
    if (integrationAsset?.veracityAssetId != undefined) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [integrationAsset?.veracityAssetId]);

  const onFormSave = () => {
    formikProps.submitForm();
    const integrationAssetToSave: IntegrationAsset = {
      ...formikProps.values,
    };

    if (integrationAsset) {
      integrationAssetToSave.id = integrationAsset.id;
    }

    integrationAssetToSave.assetType = formikProps.values.type?.assetType;
    integrationAssetToSave.assetTypeId = formikProps.values.type?.id;
    integrationAssetToSave.ownerCompanyId = formikProps.values.company?.companyId;

    if (formikProps.values.ownerCompanyId != formikProps.values.company?.companyId) {
      formikProps.values.ownerCompanyId = formikProps.values.company?.companyId;
    }

    if (formikProps.values.assetTypeId != formikProps.values.type?.id) {
      formikProps.values.assetTypeId = formikProps.values.type?.id;
      formikProps.values.assetType = formikProps.values.type?.assetType;
    }

    if (formikProps.values.company) {
      if (
        integrationAsset?.company?.companyId != formikProps.values.company?.companyId ||
        integrationAsset?.ownerCompanyId != formikProps.values.company.companyId
      ) {
        if (integrationAsset) {
          integrationAsset.ownerCompanyId = formikProps.values.company.companyId;
          integrationAssetToSave.ownerCompanyId = formikProps.values.company.companyId;
        }
      }
    }

    if (formikProps.values.type) {
      if (
        integrationAsset?.type?.id != formikProps.values.type.id ||
        integrationAsset?.assetTypeId != formikProps.values.type.id
      ) {
        if (integrationAsset) {
          integrationAsset.assetTypeId = formikProps.values.type.id;
          integrationAssetToSave.assetTypeId = formikProps.values.type.id;
          integrationAsset.assetType = formikProps.values.type.assetType;
          integrationAssetToSave.assetType = formikProps.values.type.assetType;
        }
      }
    }

    if (formikProps.isValid) {
      saveMethod(integrationAssetToSave);
    }
  };

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetconfigs, errors, touched, setFieldValue, setFieldTouched };
  const _countries: object[] = Object.values(countries).map((it) => ({ id: it.name, name: it.name }));


  const isIntegrationAssetPage = navAdminType === 'company';
  const FormWrapper = isIntegrationAssetPage ? Form : StyledInnerForm;
  const onCancelButtonClick = () =>
    navAdminType === 'system'
      ? history.push(`/${navAdminType}admin/integrationassets`)
      : integrationAssetEditModeCallback(false);

  const headersTopic: Array<Column<IntegrationAssetTopic>> = React.useMemo(
    () => [
      {
        Header: 'Topic',
        accessor: 'topic',
      },
      {
        Header: 'Data structure',
        accessor: 'dataStructure',
      },
      {
        Header: 'Data type',
        accessor: (row) => {
          return TopicDataType[row.dataType];
        },
      },
      {
        Header: 'Time stamp',
        accessor: 'timeStamp',
        Cell: (props: any) => {
          const timeStamp = props.cell.row.original.timeStamp;
          return <Moment date={timeStamp} format={timeStamp ? 'DD MMM, YYYY HH:mm' : ''} />;
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const integrationAssetTopic = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={integrationAssetTopic.id ? integrationAssetTopic.id : ''}
                deleteMethod={deleteIntegrationAssetTopicCallback}
                header="Delete integration asset topic"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const headersIssuer: Array<Column<IntegrationAssetIssuer>> = React.useMemo(
    () => [
      {
        Header: 'Asset Id issuer',
        accessor: 'assetIdIssuer',
      },
      {
        Header: 'Time stamp',
        accessor: 'timeStamp',
        Cell: (props: any) => {
          const timeStamp = props.cell.row.original.timeStamp;
          return <Moment date={timeStamp} format={timeStamp ? 'DD MMM, YYYY HH:mm' : ''} />;
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const integrationAssetIssuer = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={integrationAssetIssuer.id ? integrationAssetIssuer.id : ''}
                deleteMethod={deleteIntegrationAssetIssuerCallback}
                header="Delete integration asset issuer"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const headersPermissions: Array<Column<IntegrationAssetPermissions>> = React.useMemo(
    () => [
      {
        Header: 'Owner Id',
        accessor: 'ownerCompanyId',
        Cell: (props: any) => {
          const integrationassetPermission: IntegrationAssetPermissions = props.cell.row.original;
          let integrationname = props.cell.row.original.ownerCompanyId;
          if (integrationAssetCompanies) {
            integrationAssetCompanies.filter((x) => {
              if (x.companyId === integrationassetPermission.ownerCompanyId) {
                integrationname = x.companyName;
              }
            });
          }
          return <div>{integrationname ? integrationname : integrationassetPermission.ownerCompanyId}</div>;
        },
      },
      {
        Header: 'External access',
        accessor: 'externalAccessCompanyId',
        Cell: (props: any) => {
          const integrationassetPermission: IntegrationAssetPermissions = props.cell.row.original;
          let integrationname = props.cell.row.original.ownerCompanyId;
          if (companies) {
            companies.filter((x) => {
              if (x.chorusId === integrationassetPermission.externalAccessCompanyId) {
                integrationname = x.name;
              }
            });
          }
          return <div>{integrationname ? integrationname : integrationassetPermission.externalAccessCompanyId}</div>;
        },
      },
      {
        Header: 'Time stamp',
        accessor: 'timeStamp',
        Cell: (props: any) => {
          const timeStamp = props.cell.row.original.timeStamp;
          return <Moment date={timeStamp} format={timeStamp ? 'DD MMM, YYYY HH:mm' : ''} />;
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const integrationAssetPermissions = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={integrationAssetPermissions.id ? integrationAssetPermissions.id : ''}
                deleteMethod={deleteIntegrationAssetPermissionsCallback}
                header="Delete integration asset permission"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const DisplayIssuerList = (assetIdIssuer: string, id: string) => {
    return (
      <div className={`${styles['issuerWrapper']}`}>
        {' '}
        <StyledIssuersWrapper>
          {renderLabel('assetIssuer', props.config)}
          <Input type="text" name="assetIssuer" id="assetIssuer" defaultValue={assetIdIssuer} disabled={true} />
          <br />
          <div className={`${styles['btn-del']}`}>
            <DeleteById
              id={id ? id : ''}
              deleteMethod={deleteIntegrationAssetIssuerCallback}
              header="Delete Integrated Asset"
              delBtnIcon={deleteIcon}
            />
          </div>
          <br />
          <br />
        </StyledIssuersWrapper>
        <br />
      </div>
    );
  };

  const DisplayTopicList = (topic: string, dataStructure: string, eventType: string, id: string) => {
    return (
      <div>
        <StyledTopicWrapper>
          {renderLabel('topic', props.config)}
          <Input type="text" name="topic" id="topic" defaultValue={topic} disabled={true} />
          <br />
          {renderLabel('dataStructure', props.config)}
          <Input type="text" name="dataStructure" id="dataStructure" defaultValue={dataStructure} disabled={true} />
          {/* {renderLabel('dataEventType', props.config)}
                <Input
                  type="text"
                  name="dataEventType"
                  id="dataEventType"
                  defaultValue={eventType}
                  disabled={true}
    />*/}
          <br /> <br />
          <div className="delete-cell">
            <DeleteById
              id={id ? id : ''}
              deleteMethod={deleteIntegrationAssetTopicCallback}
              header="Delete Integrated Asset"
              delBtnIcon={deleteIcon}
            />
          </div>
          <br />
          <br />
        </StyledTopicWrapper>
        <br />
      </div>
    );
  };

  const DisplayPermissionsList = (
    ownerCompanyId: string,
    externalAccessCompanyId: string,
    read: boolean,
    write: boolean,
    id: string,
  ) => {
    return (
      <div>
        <StyledPermissionsWrapper>
          {renderLabel('ownerCompanyId', props.config)}
          <Input type="text" name="ownerCompanyId" id="ownerCompanyId" defaultValue={ownerCompanyId} disabled={true} />
          <br />
          {renderLabel('externalAccessCompanyId', props.config)}
          <Input
            type="text"
            name="externalAccessCompanyId"
            id="externalAccessCompanyId"
            defaultValue={externalAccessCompanyId}
            disabled={true}
          />
          <br />
          {renderLabel('read', props.config)}
          <Input type="text" name="read" id="read" defaultValue={read ? 'True' : 'False'} disabled={true} />
          <br />
          {renderLabel('write', props.config)}
          <Input type="text" name="write" id="write" defaultValue={read ? 'True' : 'False'} disabled={true} />
          <br /> <br />
          <div className="delete-cell">
            <DeleteById
              id={id ? id : ''}
              deleteMethod={deleteIntegrationAssetPermissionsCallback}
              header="Delete Integrated Asset"
              delBtnIcon={deleteIcon}
            />
          </div>
          <br /> <br />
        </StyledPermissionsWrapper>
        <br />
      </div>
    );
  };

  return (
    <StyledForm {...{ fullWidth: isIntegrationAssetPage }}>
      {!isIntegrationAssetPage ? (
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save integration asset"
          cancelButtonText="Cancel"
          formUrl="integrationassets"
          onCancelButtonClick={onCancelButtonClick}
        />
      ) : (
        <div className="formHeader">{header}</div>
      )}
      {header === 'Edit integration asset' && navAdminType === 'company' && (
        <StyledIconsContainer>
          {/* <Button color="primary" onClick={onFormSave}>
            <FontAwesomeIcon icon={save} size="2x" />
          </Button>
          <Button color="secondary" onClick={() => companyFormEditModeCallback(false)}>
            <FontAwesomeIcon icon={cross} size="2x" />
          </Button> */}
        </StyledIconsContainer>
      )}

      <FormWrapper>
        <StyledRowWrapper>
          <StyledCollumnWrapper>
            {renderTextInput('veracityAssetId', isEditMode, props)}
            {renderTextInput('assetName', false, props)}
            {renderTextInput('assetExternalName', false, props)}
            {renderTextAreaInput('assetDescription', false, props)}
            {/*renderSimpleDropdown('country', _countries, false, 'id', props)*/}
            {renderTextInput('assetLocation', false, props)}
            {renderComplexDropdown(
              'company',
              integrationAssetCompanies,
              integrationAssetCompaniesLoading,
              'companyName',
              props,
            )}
            {renderSimpleDropdown(
              'workspaceId',
              listWorkspaces,
              integrationAssetWorkspacesLoading,
              'workspace',
              props,
            )}
            {renderComplexDropdown('type', !(integrationAssetCompanyType == Category.Supplier) ? integrationAssetTypes.filter(x => x.assetType == 'Receiving Vessel') : integrationAssetTypes.filter(x => x.assetType != 'Receiving Vessel'), integrationAssetTypesLoading, 'assetType', props)}
            {/*enderSimpleDropdown('assetType', assetTypeOptions, false, 'assetType', props)*/}
          </StyledCollumnWrapper>
        </StyledRowWrapper>
        {isEditMode && <StyledFormSectionHeader>Asset id issuer</StyledFormSectionHeader>}
        {/*isEditMode && issuerList.map((x) => {
            if(x.id)
              return DisplayIssuerList(x.assetIdIssuer, x.id);
          })*/}
        {isEditMode && (
          <div className={`${styles['table-wrapper']}`}>
            <StyledIntegrationAssetTypeTableWrapper>
              <FuelBossTableContainer
                headers={headersIssuer}
                tableRows={issuerList}
                showPagination={false}
                showFilterSearch={false}
                defaultSelectedPage={selectedPage}
                setDefaultSelectedPage={setDefaultSelectedPage}
              />
            </StyledIntegrationAssetTypeTableWrapper>
            <br />{' '}
            {(!issuerList || issuerList.length == 0) && <div className="buttons-div">
              <Button
                color="primary"
                size="lg"
                onClick={() =>
                  history.push(`/${navAdminType}admin/integrationassets/${integrationAsset?.id}/edit/addschemas`)
                }
              >
                <FontAwesomeIcon icon={PlusCircle} />
                Add integration asset issuers
              </Button>
            </div>}
            <br />
          </div>
        )}
        {isEditMode && <StyledFormSectionHeader>Topic</StyledFormSectionHeader>}
        {/* isEditMode && TopicList.map((x) => {
            if(x.id)
              return DisplayTopicList(x.Topic, x.dataStructure, x.eventTypeId, x.id);
          })*/}
        {isEditMode && (
          <div className={`${styles['table-wrapper']}`}>
            <StyledIntegrationAssetTypeTableWrapper>
              <FuelBossTableContainer
                headers={headersTopic}
                tableRows={topicList}
                showFilterSearch={false}
                showPagination={false}
                defaultSelectedPage={selectedPage}
                setDefaultSelectedPage={setDefaultSelectedPage}
              />
            </StyledIntegrationAssetTypeTableWrapper>{' '}
            <br />{' '}
            <div className="buttons-div">
              <Button
                color="primary"
                size="lg"
                onClick={() =>
                  history.push(`/${navAdminType}admin/integrationassets/${integrationAsset?.id}/edit/adddatachannel`)
                }
              >
                <FontAwesomeIcon icon={PlusCircle} />
                Add integration asset topic
              </Button>
            </div>
            <br />
          </div>
        )}
        {isEditMode && <StyledFormSectionHeader>Permissions</StyledFormSectionHeader>}
        {/* isEditMode && permissionsList.map((x) => {
              if(x.id)
              return DisplayPermissionsList(x.ownerCompanyId, x.externalAccessCompanyId, x.read, x.write, x.id);
          })*/}
        {isEditMode && (
          <div className={`${styles['table-wrapper']}`}>
            <StyledIntegrationAssetTypeTableWrapper>
              <FuelBossTableContainer
                headers={headersPermissions}
                tableRows={permissionsList}
                showFilterSearch={false}
                showPagination={false}
                defaultSelectedPage={selectedPage}
                setDefaultSelectedPage={setDefaultSelectedPage}
              />
            </StyledIntegrationAssetTypeTableWrapper>{' '}
            <br />{' '}
            <div className="buttons-div">
              <Button
                color="primary"
                size="lg"
                onClick={() =>
                  history.push(`/${navAdminType}admin/integrationassets/${integrationAsset?.id}/edit/addpermissions`)
                }
              >
                <FontAwesomeIcon icon={PlusCircle} />
                Add integration asset permissions
              </Button>
            </div>
            <br />
            <br />
            <br />
          </div>
        )}
      </FormWrapper>
    </StyledForm>
  );
};

export default React.memo(IntegrationAssetForm);
