import { string } from 'prop-types';
import { IntegrationAssetApiTracker } from '../../../../../store/IntegrationAssetApiTracker/models';

export const initialValues: IntegrationAssetApiTracker = {
    veracityAssetId: '',
    id: '',
    assetType: '',
    assetTypeId: '',
    ownerCompanyId: '',
    timeStamp: '',
    assetTopic: '',
    fbAssetId: '',
    fbAssetType: '',
    createdBy: ''
};
