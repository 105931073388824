/* eslint-disable react/display-name */
import { findIconDefinition } from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Column, Row } from 'react-table';
import { Button } from 'reactstrap';
import FuelBossTable from '../../../../components/FuelBossTable';
import { Loading } from '../../../../components/Loading';
import DeliveryModeIcon from '../../../../components/Nomination/Components/DeliveryModeIcon/DeliveryModeIcon';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { ApplicationState } from '../../../../store';
import {
  archiveNomination,
  getNominationBySearchBar,
  getNominationsPaginated,
} from '../../../../store/Nomination/actions';
import {
  ChecklistDocumentStatus,
  Completion,
  DocumentPart,
  DocumentStatusString,
  DocumentStatusValue,
  EBDNDocumentStatus,
  Nomination,
  SafetyChecklistStatus,
  Signing,
  SofDocumentStatus,
} from '../../../../store/Nomination/models';
import styles from './OverviewNominationList.module.scss';
import { checkListDocumumentStatus, ebdnSofDocumumentStatus, isQuantityInt } from './Utils/Utils';
import { Error } from '../../../../components/Error';
import {
  getArchivedNominationsBySearchBar,
  getArchivedNominationsPaginated,
} from '../../../../store/ArchivedNomination/actions';
import CountDownCell from './Components/CountDownCell/CountDownCell';
import useInterval from '../../../../helpers/useInterval';
import ReactTooltip from 'react-tooltip';
import { StandardWrapper } from '../../../../models/Responses';
import NoNominations from '../../../../components/Nomination/Components/NoNominations/NoNominations';
import { editAISFilterBookmark } from '../../../../store/AISFilterBookmark/actions';
import { daysBetween } from '../../../AISList/Components/FilterBookmarkForm/Utils/Utils';

const archiveIcon = findIconDefinition({ prefix: 'fal', iconName: 'archive' });
const downArrowIcon = findIconDefinition({ prefix: 'fal', iconName: 'arrow-down' });

interface OwnProps {
  deliveryModes: DeliveryModes[];
  sortBy: 'time' | 'needsAction';
  archived?: boolean;
  isSupplier?: boolean;
  userCompanyId?: string;
  refetchInterval?: number;
  fetchData?: boolean;
  search: string;
  isExclude: boolean;
  isSearching: boolean;
}

const OverviewNominationList: React.FC<OwnProps> = ({
  deliveryModes,
  sortBy,
  archived = false,
  isSupplier = true,
  userCompanyId,
  refetchInterval = 60000,
  fetchData = true,
  search,
  isExclude,
  isSearching,
}) => {
  const dispatch = useDispatch();
  const selector = archived
    ? (state: ApplicationState) => state.archived_nomination
    : (state: ApplicationState) => state.nomination;
  const nomination = useSelector((state: ApplicationState) => selector(state).data);
  const nominationLoading = useSelector((state: ApplicationState) => selector(state).loading);
  const nominationMetadata = useSelector((state: ApplicationState) => selector(state).metadata);
  const nominationError = useSelector((state: ApplicationState) => selector(state).error);
  const nominationErrorDetails = useSelector((state: ApplicationState) => selector(state).errorDetails);
  const nominationLoaded = useSelector((state: ApplicationState) => selector(state).dataIsLoaded);
  const [pageSize, setPageSize] = useState(20);


  const getNominations = () => {
    if (!fetchData || isSearching) return;
    dispatch(
      archived
        ? getArchivedNominationsPaginated(0, pageSize)
        : getNominationsPaginated(sortBy === 'needsAction', 0, pageSize),
    );
  };

  const getNominationsBySearchBarBunker = () => {
    if (!fetchData)
      return;
    dispatch(
      getNominationBySearchBar(sortBy === 'needsAction', search, isExclude))
  }

  const getNominationsBySearchBarArchived = () => {
    if (!fetchData) return;
    dispatch(getArchivedNominationsBySearchBar(sortBy === 'needsAction', search, isExclude)
    )
  }

  const getNominationsBySearchBar = () => {
    if (archived) getNominationsBySearchBarArchived();
    else getNominationsBySearchBarBunker();
  }

  useEffect(() => {
    if (search === null || search === undefined || search === "") {
      getNominations();
    } else {
      getNominationsBySearchBar();
    }
  }, [pageSize, sortBy, fetchData, search, isExclude, isSearching]);

  useInterval(getNominations, refetchInterval);

  const statusSort = useCallback((a, b, id, desc) => {
    if (a.original.attributes?.needsAction) return 1;
    if (b.original.attributes?.needsAction) return -1;
    return 0;
  }, []);

  const deliveryModeFilter = useCallback(
    (rows: Row<Nomination>[], _, value) => rows.filter((r) => value.includes(r.original.deliveryMode)),
    [],
  );

  const StatusTag: React.FC<{
    documentStatus: DocumentStatusString;
    completion: Completion[];
    signing: Signing[] | null;
    deliveryMode: DeliveryModes;
    isCheckList: boolean;
    checkListType?: SafetyChecklistStatus['type'];
    checkListPart?: DocumentPart;
  }> = ({ documentStatus, completion, signing, deliveryMode, isCheckList, checkListType = 'IAPH', checkListPart }) => (
    <p className={`${styles['status-tag']} ${styles[documentStatus.replace('_', '-').toLowerCase()]}`}>
      {!isCheckList && ebdnSofDocumumentStatus(documentStatus, completion, signing, deliveryMode)}
      {isCheckList &&
        checkListDocumumentStatus(documentStatus, completion, signing, checkListType, isSupplier, deliveryMode, checkListPart)}
    </p>
  );

  const archiveButton = useMemo(
    () =>
      archived
        ? []
        : [
          {
            id: 'archive',
            Cell: (props: any) =>
              props.cell.row.original.attributes.fbStatus === 'confirmed' &&
              props.cell.row.original.vendorCompanyId === userCompanyId && (
                <Button
                  data-tip="Move to archive"
                  className={styles['archive-button']}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(archiveNomination(props.cell.row.original));
                  }}
                >
                  <ReactTooltip place="top" type="dark" effect="solid" multiline={true} />
                  <FontAwesomeIcon icon={archiveIcon} size="lg" />
                </Button>
              ),
            disableSortBy: true,
          },
        ],
    [archived],
  );

  const getBunkerAsset = (deliveryMode: string | undefined, nomination: Nomination) => {
    if (deliveryMode == 'SHIP') {
      const bunkerShip = nomination.attributes.bunkerShipName;
      if (bunkerShip) return bunkerShip;
      else return <span className={styles['asset-not-assigned']}>{'Bunker vessel not assigned'}</span>;
    }
    if (deliveryMode == 'PIPE') {
      const bunkerPipeId = nomination.pipelineId;
      if (bunkerPipeId) return nomination.attributes.pipelineName;
      else return <span className={styles['asset-not-assigned']}>{'Pipeline not assigned'}</span>;
    }
    if (deliveryMode == 'TRUCK') {
      const terminalId = nomination.loadingTerminalId;
      if (terminalId) return nomination.attributes.loadingTerminalName;
      else return <span className={styles['asset-not-assigned']}>{'Loading terminal not assigned'}</span>;
    } else {
      return '';
    }
  };

  const columns: Array<Column<Nomination>> = React.useMemo(
    () => [
      {
        id: 'fbStatus',
        Header: '',
        maxWidth: 10,
        accessor: (row) => row.attributes.fbStatus,
        Cell: (props: any) => (
          <div className={`${styles['status']} ${styles[props.cell.row.original.attributes.fbStatus]}`}></div>
        ),
        sortType: statusSort,
        disableSortBy: true,

      },
      {
        id: 'bst',
        Header: 'Days left',
        accessor: (row) => row.bst,
        Cell: (props: any) => (
          <CountDownCell bst={props.cell.row.original.bst} fbStatus={props.cell.row.original.attributes.fbStatus} />
        ),
        disableSortBy: false,
      },
      {
        Header: 'Vessel',
        accessor: (row) => row.attributes.receivingShipName,
        disableSortBy: true,
      },

      {
        Header: 'Port',
        accessor: (row) => `${row.attributes.port}, ${row.attributes.country}`,
        disableSortBy: true,
      },
      {
        Header: 'Quantity',
        accessor: (row) => row.amount,
        Cell: (props: any) => (
          <p>
            {isQuantityInt(props.cell.row.original.amount)
              ? props.cell.row.original.amount
              : props.cell.row.original.amount.toFixed(2)}{' '}
            {props.cell.row.original.quantityUnit === 'TONNES' ? (
              'mt'
            ) : props.cell.row.original.quantityUnit === 'KILOGRAMS' ? (
              'kg'
            ) : (
              <>
                m<sup>3</sup>
              </>
            )}
          </p>

        ),
        disableSortBy: true,
      },
      {
        id: 'deliveryMode',
        Header: 'Delivery mode',
        accessor: (row) => row.deliveryMode,
        Cell: (props: any) => <DeliveryModeIcon deliveryMode={props.cell.row.original.deliveryMode} />,
        filter: deliveryModeFilter,
        disableSortBy: true,
      },
      {
        id: 'eta',
        Header: 'ETA',
        accessor: (row) => row.eta,
        Cell: (props: any) => <Moment date={props.cell.row.original.eta} format="DD MMM, HH:mm" />,
        disableSortBy: false,
      },
      {
        id: 'bunkerAsset',
        Header: 'Bunker asset',
        Cell: (props: any) => <p>{getBunkerAsset(props.cell.row.original.deliveryMode, props.cell.row.original)}</p>,
        disableSortBy: true,
      },
      {
        Header: 'Safety checklist',
        Cell: (props: any) => {
          const safetyChecklistStatus: SafetyChecklistStatus = props.cell.row.original.attributes.safetyChecklistStatus;
          if (safetyChecklistStatus) {
            const statues: ChecklistDocumentStatus[] = safetyChecklistStatus.status;
            const documentType = safetyChecklistStatus.type;
            if (documentType == 'IAPH') {
              const statusAB = statues.find((x) => x.part == 'AB');
              const statusCDE = statues.find((x) => x.part == 'CDE');
              return (
                <div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>AB</p>
                    {statusAB && (
                      <StatusTag
                        documentStatus={statusAB.status}
                        completion={statusAB.completion}
                        signing={statusAB.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusAB.part}
                      />
                    )}
                  </div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>CDE</p>
                    {statusCDE && (
                      <StatusTag
                        documentStatus={statusCDE.status}
                        completion={statusCDE.completion}
                        signing={statusCDE.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusCDE.part}
                      />
                    )}
                  </div>
                </div>
              );
            } else if (documentType == 'GOTHENBURG') {
              const statusAB = statues.find((x) => x.part == 'AB');
              const statusCDEF = statues.find((x) => x.part == 'CDEF');
              const statusG = statues.find((x) => x.part == 'G');
              return (
                <div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>AB</p>
                    {statusAB && (
                      <StatusTag
                        documentStatus={statusAB.status}
                        completion={statusAB.completion}
                        signing={statusAB.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusAB.part}
                      />
                    )}
                  </div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>CDEF</p>
                    {statusCDEF && (
                      <StatusTag
                        documentStatus={statusCDEF.status}
                        completion={statusCDEF.completion}
                        signing={statusCDEF.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusCDEF.part}
                      />
                    )}
                  </div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>G</p>
                    {statusG && (
                      <StatusTag
                        documentStatus={statusG.status}
                        completion={statusG.completion}
                        signing={statusG.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusG.part}
                      />
                    )}
                  </div>
                </div>
              );
            } else if (documentType == 'TR56') {
              const statusAB = statues.find((x) => x.part == 'AB');
              const statusCD = statues.find((x) => x.part == 'CD');
              const statusE = statues.find((x) => x.part == 'E');
              return (
                <div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>AB</p>
                    {statusAB && (
                      <StatusTag
                        documentStatus={statusAB.status}
                        completion={statusAB.completion}
                        signing={statusAB.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusAB.part}
                      />
                    )}
                  </div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>CD</p>
                    {statusCD && (
                      <StatusTag
                        documentStatus={statusCD.status}
                        completion={statusCD.completion}
                        signing={statusCD.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusCD.part}
                      />
                    )}
                  </div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>E</p>
                    {statusE && (
                      <StatusTag
                        documentStatus={statusE.status}
                        completion={statusE.completion}
                        signing={statusE.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusE.part}
                      />
                    )}
                  </div>
                </div>
              );
            } else if (documentType == 'IAPH_V2_B' || documentType == 'IAPH_V2_A') {
              const statusA = statues.find((x) => x.part == 'A');
              const statusBCD = statues.find((x) => x.part == 'BCD');
              const statusF = statues.find((x) => x.part == 'F');
              return (
                <div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>A</p>
                    {statusA && (
                      <StatusTag
                        documentStatus={statusA.status}
                        completion={statusA.completion}
                        signing={statusA.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusA.part}
                      />
                    )}
                  </div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>BCD</p>
                    {statusBCD && (
                      <StatusTag
                        documentStatus={statusBCD.status}
                        completion={statusBCD.completion}
                        signing={statusBCD.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusBCD.part}
                      />
                    )}
                  </div>
                  <div className={styles['checklist-status-wrapper']}>
                    <p>F</p>
                    {statusF && (
                      <StatusTag
                        documentStatus={statusF.status}
                        completion={statusF.completion}
                        signing={statusF.signing}
                        deliveryMode={props.cell.row.original.deliveryMode}
                        isCheckList={true}
                        checkListType={documentType}
                        checkListPart={statusF.part}
                      />
                    )}
                  </div>
                </div>
              );
            } else return <div></div>;
          } else return <div></div>;
        },
        disableSortBy: true,
      },
      {
        Header: 'SOF',
        accessor: (row) => row.attributes.sofStatus,
        Cell: (props: any) => {
          const sofStatus: SofDocumentStatus = props.cell.row.original.attributes.sofStatus;
          return (
            <StatusTag
              documentStatus={sofStatus.documentStatus}
              completion={sofStatus.completion}
              signing={sofStatus.signing}
              deliveryMode={props.cell.row.original.deliveryMode}
              isCheckList={false}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'BDN',
        accessor: (row) => row.attributes.ebdnStatus,
        Cell: (props: any) => {
          const ebdnStatus: EBDNDocumentStatus = props.cell.row.original.attributes.ebdnStatus;
          return (
            <StatusTag
              documentStatus={ebdnStatus.documentStatus}
              completion={ebdnStatus.completion}
              signing={ebdnStatus.signing}
              deliveryMode={props.cell.row.original.deliveryMode}
              isCheckList={false}
            />
          );
        },
        disableSortBy: true,
      },
      {
        id: 'quantityDelivered',
        Header: () => (
          <div>
            <p>Quantity</p>
            <p>delivered</p>
          </div>
        ),
        accessor: (row) => row.actualAmount,
        Cell: (props: any): string =>
          props.cell.row.original.actualAmount
            ? `${isQuantityInt(props.cell.row.original.actualAmount)
              ? props.cell.row.original.actualAmount
              : props.cell.row.original.actualAmount.toFixed(2)
            } mt`
            : '',
        disableSortBy: true,
      },
      ...archiveButton,
    ],
    [archiveButton],
  );

  const history = useHistory();
  if (nominationError) return nominationErrorDetails ? <Error error={nominationErrorDetails} /> : null;
  if (!nominationLoaded) return <Loading />;
  if (nomination.length == 0) {
    return <NoNominations className={styles['message-wrapper']} archived={archived} />;
  }
  return (
    <div className={styles['root']}>
      <FuelBossTable
        showSortIcons={false}
        showFilterSearch={false}
        headers={columns}
        showPagination={false}
        tableRows={nomination}
        filters={[{ id: 'deliveryMode', value: deliveryModes }]}
        sortBy={sortBy === 'needsAction' ? [{ id: 'fbStatus', desc: true }] : archived ? [{ id: 'bst', desc: true }] : [{ id: 'bst', desc: false }]}
        onRowClick={(row: Nomination) => {
          history.push(`/bunkerevents/${archived ? 'archived' : 'bunker'}/${row.eventId}`);
        }}
        rowStyle={(row: Nomination) => ({
          backgroundColor: row.attributes.fbStatus === 'action-required' ? '#fff2f3' : 'white',
        })}
      />
      {nominationLoading && <Loading small />}
      {(nominationMetadata?.hasNextPage && search == null) || (nominationMetadata?.hasNextPage && search == undefined) || (nominationMetadata?.hasNextPage && search == "") && (
        <Button className={styles['show-more-button']} onClick={() => setPageSize(pageSize + 10)}>
          Show more bunker events <FontAwesomeIcon icon={downArrowIcon} />
        </Button>
      )}
    </div>
  );
};

export default OverviewNominationList;
