import { ErrorActionPayload } from '../../models/ErrorAction';
import { AuthWrapperModel } from './models';

export const AUTH_LOADING = 'auth/loading';
export type AUTH_LOADING = typeof AUTH_LOADING;

export const GET_AUTH = 'auth/get';
export type GET_AUTH = typeof GET_AUTH;

export const AUTH_FETCH_ERROR = 'auth/fetchError';
export type AUTH_FETCH_ERROR = typeof AUTH_FETCH_ERROR;

export type AuthActions =
  | {
      type: GET_AUTH;
      payload: AuthWrapperModel['data'];
    }
  | {
      type: AUTH_LOADING;
    }
  | {
      type: AUTH_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
