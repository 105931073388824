import React, { useState, useEffect } from 'react';
import { useSofConfigurationService, SofConfiguration } from '@teqplay/chorus-components';
import { StatementOfFactsConfiguration as SOFConfig } from '@teqplay/chorus-components';
import { Auth } from '../../store/Auth/models';
import { AddToast } from '../../helpers/toastService';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import Button from '../SharedStyledComponents/ButtonsThemed';
import BackNavigation from '../BackNavigation/BackNavigation';
import { CustomError } from '../Error/components/CustomError/index';

interface OwnProps {
  auth: Auth;
  goBack: () => void;
  selectedConfigurationId: SOFConfig['_id'];
  isNew?: boolean;
  serviceLayer: AuthenticationServiceLayer;
}

export const EditSOFConfiguration: React.FC<OwnProps> = ({ auth, selectedConfigurationId, goBack, isNew = false, serviceLayer }) => {

  const onError = () => {
    const action = isNew ? 'create' : 'edit';
    AddToast({
      content: 'Unable to ' + action + 'SOF template',
      type: 'error',
      toastId: 'editSOFTemplate',
    });
    goBack();
  };

  const onActionSuccess = (x: any) => {
    const action = isNew ? 'created' : 'modified';
    if (x != 'FETCH') {
      AddToast({
        content: 'SOF template ' + action,
        type: 'success',
        toastId: 'editSOFTemplate',
      });
      goBack();
    }
  };

  const { sofConfigurationsList, createOrUpdateSofConfig } = useSofConfigurationService(
    serviceLayer,
    auth.chorusUserProfile.companyId ? auth.chorusUserProfile.companyId : '',
    undefined,
    onError,
    onActionSuccess,
  );

  const [matchingConfiguration, setActiveConfiguration] = useState<undefined | null | SOFConfig>(null);

  useEffect(() => {
    if (selectedConfigurationId && sofConfigurationsList && sofConfigurationsList.length > 0) {
      setActiveConfiguration(sofConfigurationsList?.find((conf) => conf._id === selectedConfigurationId));
    }
  }, [sofConfigurationsList, selectedConfigurationId]);

  if (auth && auth.company && matchingConfiguration && !isNew) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack} />}
        <SofConfiguration createOrUpdateSofConfig={createOrUpdateSofConfig} configuration={matchingConfiguration} />
      </>
    );
  } else if (auth && auth.company && isNew) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack} />}
        <SofConfiguration createOrUpdateSofConfig={createOrUpdateSofConfig} configuration={undefined} />
      </>
    );
  } else if (matchingConfiguration === undefined) {
    return (
      <>
        {goBack && <BackNavigation onClick={goBack}>Go back to SOF template list</BackNavigation>}
        <CustomError
          errorTitle={"Error! Can't find SOF configuration"}
          errorMessage={
            "We are sorry, but we can't find the SOF configuration. There seem to be some mismatches in our data. Please contact us if you believe this is wrong."
          }
          displayContact={true}
        ></CustomError>
      </>
    );
  } else {
    return null;
  }
};
