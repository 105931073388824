import { css } from 'styled-components';
import PropTypes from 'prop-types';
import myTheme from '../../theme/myTheme';

export const textBase = css`
  font-size: ${(props) => props.theme.fontSizes.small};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

export const textProps = {
  theme: PropTypes.object,
};

export const textDefaults = {
  theme: {
    font: myTheme.font,
    color: myTheme.color,
  },
};
