import { StandardWrapper } from '../../models/Responses';
import { IFuelbossStatus, NegotiationState } from '@teqplay/chorus-components';
import { PaginationMeta } from '../../helpers/PaginationMeta';
import { Author, FuelbossStatus, HeelQuality, LoadedQuality, NominationAttribute, Sof } from '../Nomination/models';
import { Company } from '../Company/models';
import { Contract } from '../Contract/models';
import { FuelBossLocation } from '../FuelbossLocations/models';
import { QuantityUnit } from '@teqplay/chorus-components';

export type RecurringNominationWrapperModel = StandardWrapper<RecurringNomination[], PaginationMeta | undefined>;
export type RecurringNominationResponseModel = RecurringNomination[];
export type PaginatedRecurringNominationResponseModel = { page: RecurringNomination[] } & PaginationMeta;

export interface RecurringNomination {
  //_type: string;
  id: string;
  //eventId: string;
  //sandboxId?: any;
  eta: string;
  etd: string;
  bst: string;
  created: Date;
  vendorCompanyId: string;
  vendorReference: string;
  author?: Author;
  recipient?: Author;
  authorId: string;
  recipientId: string;
  stateChangeReason?: string;
  bunkerShipId: string;
  receivingShipId: string;
  contractId: string;
  companyId: string;
  supplierId: string;
  locationId: string;
  location: string;
  state: NegotiationState; //| 'RECURRING EVENT';
  pipelineId: string;
  buyerRef: string;
  agent: string;
  co2TaxEnabled: boolean;
  nextDestination: string;
  allowedBunkeringTime?: number;
  ata?: Date;
  atd?: Date;
  amount: number;
  quantityUnit: QuantityUnit;
  tolerance: number;
  price: string;
  priceEnabled: boolean;
  priceUnit: string;
  deliveryMode: string;
  altFuelPercentage: number;
  altFuelType: string;
  altFuelTolerance: string;
  fbStatus: FuelbossStatus;
  scheduleRecurrenceType: ScheduleRecurrenceType;
  tbncType: TimeBeforeNominationCreationType;
  timeBeforeNominationCreation: number;
  dailyScheduleId?: string;
  dailySchedule?: DailyScheduleModel;
  weeklyScheduleId?: string;
  weeklySchedule?: WeeklyScheduleModel;

  startDate: string;
  endDate: string;
  numberOfEvents: number;
  counter: number;
  //recurringNominationRecords: RecurringNominationRecord;
  timeStamp: string;
  vendorThirdPartyContactIds?: string; //string[] or string
  customerThirdPartyContactIds?: string;
  supplierTerms?: string;
  buyerTerms?: string;

  attributes?: {
    //nComments: number;
    needsAction: boolean;
    fbStatus: IFuelbossStatus;
    bunkerShipName?: string;
    receivingShipName?: string;
    pipelineName?: string;
    //port?: string;
    //country?: string;
    //chartered?: boolean;
  };

  failedScheduledEventsCount: number;

  //reactionTime?: any;
  //latest: boolean;
  //column: string;
  //documents: Document[];
  //voyageOrders?: any;
  //onBehalf: boolean;
  //reactionOnBehalf: boolean;
  //remark: string;
  //gasUp: boolean;
  //coolDown: boolean;

  //company: Company;
  //vendorCompany: Company;
  //locationModel: FuelBossLocation;
  //contract: Contract;

  //norTendered?: any;
  //bunkershipNorTendered?: any;
  //alongside?: any;
  //hosesConnected?: Date;
  //hosesDisconnected?: Date;
  //bunkershipEta?: any;
  //bunkershipAta?: Date;
  //bunkershipEtd?: any;
  //bunkershipAtd?: Date;

  //totalQuantity: string;

  //actualAmount?: any;
  //heelQuality: HeelQuality;
  //loadedQuality: LoadedQuality;
  //sof: Sof;
  //ncomments: number;
  //attributes: NominationAttribute;
}

export enum ScheduleRecurrenceType {
  Daily = 0,
  Weekly,
  Monthly,
  Yearly,
}

export const SCHEDULERECURRANCE_TYPE: {
  value: ScheduleRecurrenceType;
  text: string;
}[] = [
  { text: 'Daily', value: ScheduleRecurrenceType.Daily },
  { text: 'Weekly', value: ScheduleRecurrenceType.Weekly },
//  { text: 'Monthly', value: ScheduleRecurrenceType.Monthly },
//  { text: 'Yearly', value: ScheduleRecurrenceType.Yearly },
];

/* export const AvailableScheduleRecurrenceTypes: ScheduleRecurrenceType[] = [
  ScheduleRecurrenceType.Daily,
  ScheduleRecurrenceType.Weekly,
  ScheduleRecurrenceType.Monthly,
  ScheduleRecurrenceType.Yearly,
]; */

export enum TimeBeforeNominationCreationType {
  Hours,
  Days,
  Months,
}

export interface DailyScheduleModel {
  id: string;
  recurringNominationTemplateId: string;
  occursEveryNDays: number;
  timeStamp: Date;
}

export interface WeeklyScheduleModel {
  id: string;
  recurringNominationTemplateId: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  timeStamp: Date;
}
