import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as FuelBossLogo } from './img/FuelBoss_logo.svg';
import { ReactComponent as FuelBossIntroBg } from './img/GetStarted_UserManual_illustration.svg';
//import { ReactComponent as FuelBossContract } from './img/AddContracts_illustration.svg';
//import { ReactComponent as FuelBossOverview } from './img/OverviewPlanning_illustration.svg';
//import { ReactComponent as FuelBossNomination } from './img/Nomination_Negotiation_Illustration_ReducedSize.svg';
//import { ReactComponent as FuelBossChecklist } from './img/Checklists_DigiSign_illustration.svg';
//import { ReactComponent as FuelBossFleet } from './img/Fleet.svg';
import { CarouselProvider, DotGroup, Slider, Slide, ButtonNext, ButtonFirst, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core';

/*const svgLookup: any = {
  0: <FuelBossContract />,
  1: <FuelBossOverview />,
  2: <FuelBossNomination />,
  3: <FuelBossChecklist />,
  4: <FuelBossFleet />,
};*/

const slides = [
  {
    step: 0,
    text: <span className="text">Welcome to FuelBoss</span>,
    position: '',
    color: '',
    phase: '',
    imageUrl: '',
    imageText: '',
    icon: '',
    linkUrl: '',
    linkTitle: '',
  },
  {
    step: 1,
    text: (
      <span className="text">
        Set up your <b>company, fleet</b> and <b>contracts</b>
      </span>
    ),
    position: 'right',
    color: '#23acdf',
    phase: '',
    imageUrl: '/img/Onboarding_Slide_02.png',
    imageText: (
      <div
        className="slideBodyText"
        style={{ position: 'absolute', top: '30%', left: '37%', width: '26%', textAlign: 'center' }}
      >
        To get started your company admin needs to enroll <b>your team</b>.<br /> If you are a supplier - they will need
        to enroll <b>your customers</b> too.
        <br /> Both suppliers and shipowners must add their fleet to allow bunkering.
      </div>
    ),
    icon: 'building',
    linkUrl: '/companyadmin/contracts',
    linkTitle: 'Company profile',
  },
  {
    step: 2,
    text: (
      <span className="text">
        Get an <b>overview</b> of all your activities
      </span>
    ),
    position: 'right',
    color: '#23acdf',
    phase: '',
    imageUrl: '/img/Onboarding_Slide_03.png',
    imageText: (
      <div className="slideBodyText" style={{ position: 'absolute', top: '20%', left: '7%', width: '20%' }}>
        <em>Overview</em> allows you to see all <b>your upcoming activities</b> and <b>take action</b> on incoming
        nominations, making planning and scheduling much easier.
        <br />
        <br /> Easily see all bunker events classified as:
      </div>
    ),
    icon: 'layer-group',
    linkUrl: '/overview',
    linkTitle: 'Overview',
  },
  {
    step: 3,
    text: (
      <span className="text">
        How to <b>nominate</b> and negotiate bunkering
      </span>
    ),
    position: 'left',
    color: 'orange',
    phase: 'PLANNING PHASE',
    imageUrl: '/img/Onboarding_Slide_04.png',
    imageText: (
      <div className="slideBodyText">
        <span
          className="textGroup1"
          style={{ position: 'absolute', top: '35%', left: '0', width: '14%', textAlign: 'center' }}
        >
          Shipowners <b>create new nominations</b> by specifying their LNG bunker needs
        </span>{' '}
        <span
          className="textGroup2"
          style={{ position: 'absolute', top: '14%', left: '42%', width: '14%', textAlign: 'center' }}
        >
          <b>Bunkering is agreed</b> by both parties in the Fuel<b>Boss</b> application
        </span>{' '}
        <span
          className="textGroup3"
          style={{ position: 'absolute', top: '35%', right: '3%', width: '10%', textAlign: 'center' }}
        >
          Suppliers <b>receive and negotiate</b> the requests for LNG bunkering
        </span>
      </div>
    ),
    icon: 'gas-pump',
    linkUrl: '/bunkerevents',
    linkTitle: 'Bunker Events',
  },
  {
    step: 4,
    text: (
      <span className="text">
        Checklists, <b>eBDN</b> and digital signing
      </span>
    ),
    position: 'left',
    color: 'green',
    phase: 'OPERATIONS PHASE',
    imageUrl: '/img/Onboarding_Slide_05.png',
    imageText: (
      <div className="slideBodyText" style={{ position: 'absolute', top: '20%', left: '5%', width: '22%' }}>
        In the <b>operational phase</b>, digital checklists ensure everything is <b>on-track, logged and verified</b>.
        All involved parties can monitor planned and ongoing operations to avoid misunderstanding.
        <br />
        <br /> Easily complete all required documentation on the platform, including filling out{' '}
        <b>checklists, populating the eBDN and uploading any necessary documents</b>.<br />
        <br /> Once the documentation is completed, it is signed digitally by both parties on the platform,{' '}
        <b>finalizing the bunker operation</b>.
      </div>
    ),
    icon: 'gas-pump',
    linkUrl: '/bunkerevents',
    linkTitle: 'Bunker Events',
  },
  {
    step: 5,
    text: (
      <span className="text">
        How to see <b>your fleet</b> and other LNG vessels
      </span>
    ),
    position: 'left',
    color: '#23acdf',
    phase: '',
    imageUrl: '/img/Onboarding_Slide_06.png',
    imageText: (
      <div className="slideBodyText" style={{ position: 'absolute', top: '18%', left: '3%', width: '19%' }}>
        In Fleet you have a <b>live view of LNG fuelled vessels and LNG bunkering vessels</b>. You are able to search
        for and track specific vessels and save them as favourites in My Fleet.
        <br />
        <br /> The <b>historic movement of vessels is visible</b> as routes on the world map.
        <br />
        <br /> <b>A time boxed search</b> gives you the ability to see voyages between specific dates only.
      </div>
    ),
    icon: 'ship',
    linkUrl: '/fleet',
    linkTitle: 'Fleet',
  },
];

const OnboardingWrapper = styled.div`
  width: 100%;
  height: calc(100vh - var(--header-height));
  overflow: scroll;
  scrollbar-color: #fff #fff;
  scrollbar-width: thin;
  background-color: #e5f5fb;
`;

const StyledOnboarding = styled.div`
  padding: 1em;
  .carouselContainer {
    width: 60%;
    margin: 2em auto;
    @media (max-width: 1920px) {
      width: 80%;
    }
    @media (max-width: 1200px) {
      width: 100%;
    }
    .carousel {
      .carousel__slide-focus-ring {
        display: none;
      }
      .carousel__dot-group {
        margin: 1em;
      }
      .carousel__inner-slide {
        position: relative !important;
        > svg {
          width: inherit;
          height: inherit;
        }
      }
      .carousel__image {
        object-fit: contain;
      }
      .carouselFooter {
        text-align: center;
        > button {
          border: none;
          padding: 0.3em 0.5em;
          margin-right: 0.5em;
          background-color: #fff;
        }
      }
    }
    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
  .linkWrapper {
    text-align: center;
    color: #8f94a1;
    a {
      color: #2b81cf;
      text-decoration: underline;
    }
  }
`;

const SliderWidget = styled.div<{ color: string; position: string }>`
  padding: 1em;
  cursor: pointer;
  width: 240px;
  position: absolute;
  &#widget-0 {
    bottom: 35%;
    left: 5%;
  }
  &#widget-1 {
    bottom: 60%;
    left: 10%;
  }
  &#widget-2 {
    top: 25%;
    right: 15%;
  }
  &#widget-3 {
    bottom: 45%;
    right: 5%;
  }
  &#widget-4 {
    bottom: 25%;
    right: 8%;
  }
  .iconWrapper {
    display: flex;
    position: absolute;
    top: -10px;
    left: ${({ position }) => position === 'left' && '6.5em'};
    right: ${({ position }) => position === 'right' && '6.5em'};
    .indicator {
      height: 70px;
      width: 10px;
      background-color: ${({ color }) => color};
    }
    .widgetIcon {
      display: flex;
      align-items: center;
      background-color: #0a0e16;
      width: 80px;
      height: 70px;
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      padding: 0.5em;
      svg {
        margin: 0 auto;
        path {
          fill: #23acdf;
        }
      }
    }
  }
  .phase {
    font-size: 14px;
    font-weight: 600;
    color: ${({ color }) => color};
    position: absolute;
    top: 2em;
    left: ${({ position }) => position === 'left' && '-2.5em'};
    right: ${({ position }) => position === 'right' && '-2.5em'};
  }
`;

const StyledSlide = styled(Slide)<{ idx: number }>`
  .entryWrapper {
    display: flex;
    align-items: center;
    .entryIcon {
      display: flex;
      align-items: center;
      background-color: #0a0e16;
      width: 40px;
      height: 40px;
      padding: 0.5em;
      margin-right: 0.5em;
      svg {
        margin: 0 auto;
        width: 25px;
        height: 25px;
        path {
          fill: #23acdf;
        }
      }
    }
    .slideEntry {
      span.text {
        color: #8f94a1;
      }
      span.step {
        font-weight: 600;
      }
      a {
        color: #2b81cf;
        text-decoration: underline;
      }
    }
  }
  .slideBodyText {
    text-align: start;
    font-size: 18px;
    @media (max-width: 1600px) {
      font-size: 16px;
    }
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }
`;
const StyledLogo = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 42%;
  .appLogoInner {
    display: flex;
    margin-bottom: 0.5em;
    svg {
      margin-right: 0.5em;
    }
    .logoIntro {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.5em;
    }
    .appTitle {
      font-size: 50px;
      font-weight: 400;
    }
    .appTitleInner {
      font-weight: 600;
    }
  }
  .logoAux {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
  }
`;

const StyledBubble = styled.div<{ position: string }>`
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  font-size: 14px;
  background: #fff;
  border-radius: 0.4em;
  max-width: 200px;
  min-height: 45px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: -5em;
  left: ${({ position }) => position === 'left' && '-6em'};
  right: ${({ position }) => position === 'right' && '-6em'};

  &:after {
    content: '';
    position: absolute;
    left: ${({ position }) => position === 'left' && '0'};
    right: ${({ position }) => position === 'right' && '0'};
    top: 50%;
    width: 0;
    height: 0;
    border: 13px solid transparent;
    border-right-color: ${({ position }) => position === 'left' && '#fff'};
    border-left-color: ${({ position }) => position === 'right' && '#fff'};
    border-left: ${({ position }) => position === 'left' && '0'};
    border-right: ${({ position }) => position === 'right' && '0'};
    border-bottom: 0;
    margin-top: 0;
    margin-left: ${({ position }) => position === 'left' && '-13px'};
    margin-right: ${({ position }) => position === 'right' && '-13px'};
  }

  span {
    font-weight: 600;
  }

  span.stepNumber {
    margin-right: 0.5em;
    font-size: 22px;
  }
  span.bodyText {
  }
`;

const StyledDotGroup = styled(DotGroup)`
  button {
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    margin-right: 0.5em;
  }
  button[class*='--selected'] {
    background-color: #24365c;
  }
`;

export const Onboarding: React.FC = () => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);

  const renderSlideWidget = (
    idx: number,
    step: number,
    text: any,
    position: string,
    phase: string,
    icon: string,
    color: string,
  ) => {
    const Icon = findIconDefinition({
      prefix: 'fal',
      iconName: icon,
    } as IconLookup);

    return (
      <SliderWidget key={idx} id={`widget-${idx}`} onClick={() => setCurrentSlideIdx(step)} {...{ color, position }}>
        <div className="iconWrapper">
          <div className="indicator" />
          <div className="widgetIcon">
            <FontAwesomeIcon icon={Icon} size="3x" />
          </div>
        </div>
        <div className="phase">{phase}</div>
        <StyledBubble {...{ position }}>
          <span className="stepNumber">{step}.</span>
          <span className="bodyText">{text}</span>
        </StyledBubble>
      </SliderWidget>
    );
  };

  const renderSlide = (
    idx: number,
    step: number,
    text: any,
    icon: string,
    linkUrl: string,
    imageUrl: string,
    linkTitle: string,
    imageText: any,
  ) => {
    const Icon = findIconDefinition({
      prefix: 'fal',
      iconName: icon,
    } as IconLookup);

    return (
      <StyledSlide key={idx} index={step} {...{ idx }}>
        <div className="entryWrapper">
          <div className="entryIcon">
            <FontAwesomeIcon icon={Icon} />
          </div>
          <div className="slideEntry">
            <span className="step">{step}</span>. {text} <span className="text">in</span>{' '}
            <Link to={linkUrl}>{linkTitle}</Link>
          </div>
        </div>
        <Image hasMasterSpinner src={imageUrl} />
        {imageText}
      </StyledSlide>
    );
  };

  return (
    <OnboardingWrapper>
      <StyledOnboarding>
        <div className="carouselContainer">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={slides.length}
            currentSlide={currentSlideIdx}
            dragEnabled={false}
            touchEnabled={false}
            isIntrinsicHeight
          >
            <Slider>
              <Slide index={0}>
                <FuelBossIntroBg />
                <StyledLogo>
                  <div className="appLogoInner">
                    <FuelBossLogo />
                    <span className="logoIntro">
                      <span className="logoIntroWelcome">Welcome to</span>
                      <span className="appTitle">
                        Fuel<span className="appTitleInner">Boss</span>
                      </span>
                    </span>
                  </div>
                  <span className="logoAux">Where to start in FuelBoss</span>
                </StyledLogo>
                {slides
                  .filter((it) => it.step !== 0)
                  .map((it, idx) => renderSlideWidget(idx, it.step, it.text, it.position, it.phase, it.icon, it.color))}
              </Slide>
              {slides
                .filter((it) => it.step !== 0)
                .map((it, idx) =>
                  renderSlide(idx, it.step, it.text, it.icon, it.linkUrl, it.imageUrl, it.linkTitle, it.imageText),
                )}
            </Slider>
            <div className="carouselFooter">
              <StyledDotGroup />
              <ButtonFirst onClick={() => setCurrentSlideIdx(0)}>Return</ButtonFirst>
              <ButtonNext onClick={() => setCurrentSlideIdx(currentSlideIdx + 1)}>Next</ButtonNext>
            </div>
          </CarouselProvider>
        </div>
        <div className="linkWrapper">
          <Link to="/pdf/FuelBoss_user_manual.pdf" target="_blank" download>
            Download
          </Link>{' '}
          the full User Guide
        </div>
      </StyledOnboarding>
    </OnboardingWrapper>
  );
};
