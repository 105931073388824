import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAuth } from '../../../../../store/Auth/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { renderTextInput, renderComplexDropdown, renderSimpleDropdown, renderTextAreaInput } from '../../../../Form/FormInputs';
import integrationAssetConfigs from './integrationAssetConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import {IntegrationAssetIssuer} from '../../../../../store/IntegrationAssetIssuer/models';

const StyledInnerForm = styled.div`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
margin-top: 1em;
.formHeader {
  flex: 3 1 0%;
  font-weight: 600;
  font-size: 1.75em;
  padding-left: 5px;
}
.buttonContainer {
  display: flex;
  flex: 0 1 0%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: right;
}
.buttonContainer button {
  justify-self: flex-end;
  margin-left: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 18px;
  font-size: 18px;
}
div[class^='input-'] {
  flex: 0 1 40%;
  margin-right: 35px;
}
button {
  justify-self: flex-end;
  margin-right: 0.5em;
}
.form-check {
  margin-right: 5px;
}
`;

interface OwnProps {
  integrationAssetIssuers?: IntegrationAssetIssuer;
  header: string;
  saveMethod: (integrationAssetIssuers: IntegrationAssetIssuer) => void;
  systemAdminPage?: boolean;
  formikProps: FormikProps<IntegrationAssetIssuer>;
  navAdminType: string;
  integrationAssetId: string;
}

const IntegrationAssetIssuersForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  integrationAssetIssuers,
  systemAdminPage = false,
  navAdminType,
  formikProps,
  integrationAssetId,
}) => {
  const { authLoading, authError, authErrorDetails, authIsLoaded } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const onFormSave = () => {
    formikProps.submitForm();
    //const roleArray = getRolesArray();

    const integrationAssetIssuersToSave: IntegrationAssetIssuer = {
      ...formikProps.values,
      //  active: true,
    };
    if (integrationAssetIssuers) {
      integrationAssetIssuersToSave.assetIdIssuer = integrationAssetIssuers.assetIdIssuer;
    }
    if (formikProps.isValid) {
      saveMethod(integrationAssetIssuersToSave);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetConfigs, errors, touched, setFieldValue, setFieldTouched };

  const url = `integrationassets/${integrationAssetId}/edit`

  if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save integration asset issuers"
          cancelButtonText="Cancel"
          formUrl={url}
        />
        <StyledInnerForm as={Form}>
         {renderTextInput('assetIdIssuer', false, props)}
        </StyledInnerForm>
      </StyledForm>
    );
  }
};

export default React.memo(IntegrationAssetIssuersForm);
