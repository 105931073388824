import { calculateMaxMonthDate, calculateMinMonthDate, datesAreEqual, isAfter, isBefore } from '@teqplay/teqplay-ui';
import { cloneDeep, setWith } from 'lodash';
import { DateTime } from 'luxon';
//import { isPromptEvent } from '../@types/typeguards'
import {
  IPromptNomination,
  IUserProfile,
  UserRole,
  IContract,
  INominationEnquiry,
  INominationEnquiryEvent,
  isSupplier,
} from '@teqplay/chorus-components';
import { isCustomer, isScheduler, isSchedulerCaptain, isPromptEvent } from '@teqplay/chorus-components';
import {
  RecurringNomination,
  ScheduleRecurrenceType,
  TimeBeforeNominationCreationType,
} from '../../../../../store/RecurringNomination/models';

export const MINIMUM_QUANTITY = 1; // MT
export const MAXIMUM_QUANTITY = 100000; // MT

export function validateRecurringNominationForm(
  deliveryNomination: RecurringNomination,
  userProfile: IUserProfile,
  userRoles: UserRole[],
  deliveryModesEnabled: boolean,
  timezone?: string,
  contracts?: IContract[],
) {
  return validateRecurringNomination(
    [],
    deliveryNomination,
    userProfile,
    userRoles,
    deliveryModesEnabled,
    timezone,
    undefined,
    contracts,
  );
}

function validateEventDate(
  label: string,
  nomination: IPromptNomination | INominationEnquiry | INominationEnquiryEvent | RecurringNomination,
  path: Array<string | number>,
  errors: {},
  customErrorMessage?: string,
) {
  //@ts-ignore
  const dateToCheck = nomination[label];
  const isValidDate = dateToCheck && DateTime.fromISO(dateToCheck).isValid ? true : false;
  //@ts-ignore
  if (!nomination[label]) {
    return;
  }

  if (label in nomination && !isValidDate) {
    const errorMessage = customErrorMessage || 'Fill in a valid date';
    setWith(errors, path.concat(label).join('.'), errorMessage, Object);
  }
}

function getFirstWeekdayAfterDate(date = new Date(), dayInt: number) {
  const dateCopy = new Date(date.getTime());

  const nextWeekday = new Date(dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + dayInt) % 7 || 7)));

  return nextWeekday;
}

export function validateRecurringNomination(
  path: Array<string | number>,
  delivery: RecurringNomination,
  userProfile: IUserProfile,
  userRoles: UserRole[],
  deliveryModesEnabled: boolean,
  timezone?: string,
  yearMonth?: string,
  contracts?: IContract[],
) {
  const errors = {};

  if (!delivery) {
    return errors;
  }

  //
  // REQUIRED FIELDS
  //
  // Required fields to be filled in by the customer
  const REQUIRED_FIELDS_FOR_CUSTOMER: (keyof RecurringNomination)[] = [
    'receivingShipId',
    'locationId',
    'amount',
    'eta',
    'bst',
    'etd',
    'deliveryMode',
    'scheduleRecurrenceType',
    'startDate',
    'endDate',
    'timeBeforeNominationCreation',
  ];
  // Required fields to be filled in by the scheduler
  const REQUIRED_FIELDS_FOR_SCHEDULER: (keyof RecurringNomination)[] = [
    'locationId',
    'amount',
    'bst',
    'deliveryMode',
    'scheduleRecurrenceType',
    'startDate',
    'endDate',
    'timeBeforeNominationCreation',
    'allowedBunkeringTime',
  ];
  /* if (!delivery.delegationOriginEventId) {
    REQUIRED_FIELDS_FOR_SCHEDULER.push('contractId');
  } */

  const isContractHolder = userProfile.companyId === delivery.companyId;
  if (
    //(!delivery.delegatedNominationEventId && !delivery.delegationOriginEventId) ||
    !isContractHolder /* && delivery.delegationOriginEventId */
  ) {
    REQUIRED_FIELDS_FOR_SCHEDULER.push('vendorReference');
  }

  if (deliveryModesEnabled) {
    REQUIRED_FIELDS_FOR_CUSTOMER.push('deliveryMode');
    REQUIRED_FIELDS_FOR_SCHEDULER.push('deliveryMode');
  }

  // Add required fields if it's a prompt nomination
  if (isPromptEvent(delivery)) {
    REQUIRED_FIELDS_FOR_SCHEDULER.push('allowedBunkeringTime');
  }

  // Determines which fields to validate based on the user role.
  const requiredFieldsToValidate =
    ((isScheduler(userRoles) || isSchedulerCaptain(userRoles)) && isSupplier(userRoles))
      ? REQUIRED_FIELDS_FOR_SCHEDULER
      : REQUIRED_FIELDS_FOR_CUSTOMER;

  // loops through all the fields and checks if they have been filled in.
  requiredFieldsToValidate.forEach((field) => {
    if (field == 'scheduleRecurrenceType' && delivery[field] == 0) {
      //discard scheduleRecurrinaceType when 0
      return;
    }
    if (!delivery[field] || delivery[field] === '') {
      setWith(errors, path.concat(field).join('.'), 'This field is Required', Object);
    }
  });

  // check if the selected receiving vessel, location does really exist under the selected contract
  if (delivery['contractId'] && delivery['locationId'] && contracts) {
    var selectedContract = contracts?.find((contract) => contract._id === delivery['contractId']);

    const receivingVesselsWithinContract = selectedContract?.receivingVessels;
    const locationsWithinContract = selectedContract?.locations;

    // Receiving Vessel
    if (!receivingVesselsWithinContract || receivingVesselsWithinContract.length === 0) {
      setWith(
        errors,
        path.concat('receivingShipId').join('.'),
        'The selected contract does not contain any receiving ship.',
        Object,
      );
    } else if (!receivingVesselsWithinContract.includes(delivery['receivingShipId'])) {
      setWith(
        errors,
        path.concat('receivingShipId').join('.'),
        'Please choose a receiving vessel within the selected contract.',
        Object,
      );
    }

    // Location
    if (!locationsWithinContract || locationsWithinContract.length === 0) {
      setWith(
        errors,
        path.concat('locationId').join('.'),
        'The selected contract does not contain any location.',
        Object,
      );
    } else if (!locationsWithinContract.includes(delivery['locationId'])) {
      setWith(
        errors,
        path.concat('locationId').join('.'),
        'Please choose a location within the selected contract.',
        Object,
      );
    }
  }

  // timeBeforeNominationCreation days or hours
  if (delivery.timeBeforeNominationCreation) {
    // restrictions on lower and upper level
    if (delivery.tbncType == TimeBeforeNominationCreationType.Days) {
      if (delivery.timeBeforeNominationCreation < 1)
        setWith(
          errors,
          path.concat('timeBeforeNominationCreation').join('.'),
          'Days value must be greater than 1',
          Object,
        );
      if (delivery.timeBeforeNominationCreation > 8)
        setWith(
          errors,
          path.concat('timeBeforeNominationCreation').join('.'),
          'Days value must be less than 8',
          Object,
        );
    } else if (delivery.tbncType == TimeBeforeNominationCreationType.Hours) {
      if (delivery.timeBeforeNominationCreation < 6)
        setWith(
          errors,
          path.concat('timeBeforeNominationCreation').join('.'),
          'Hours value must be greater than 6',
          Object,
        );
      if (delivery.timeBeforeNominationCreation > 200)
        setWith(
          errors,
          path.concat('timeBeforeNominationCreation').join('.'),
          'Hours value must be less than 200',
          Object,
        );
    }
  }

  // dailySchedule.occursEveryNDays
  if (delivery.scheduleRecurrenceType == ScheduleRecurrenceType.Daily) {
    if (delivery.dailySchedule) {
      if (!delivery.dailySchedule.occursEveryNDays) {
        setWith(errors, path.concat('dailySchedule.occursEveryNDays').join('.'), 'This field is Required', Object);
      }
    }
  }
  //weeklySchedule check if a single day is selected
  if (delivery.scheduleRecurrenceType == ScheduleRecurrenceType.Weekly) {
    if (delivery.weeklySchedule) {
      const isSingleDaySelected = false;
      if (
        !delivery.weeklySchedule.monday &&
        !delivery.weeklySchedule.tuesday &&
        !delivery.weeklySchedule.wednesday &&
        !delivery.weeklySchedule.thursday &&
        !delivery.weeklySchedule.friday &&
        !delivery.weeklySchedule.saturday &&
        !delivery.weeklySchedule.sunday
      ) {
        setWith(errors, path.concat('weeklySchedule').join('.'), 'This field is Required', Object);
      }
    }
    if (delivery.weeklySchedule && delivery.startDate && delivery.endDate) {
      const bst = new Date(delivery.bst);
      //check first day of week of weekly schedule between matches inital bst day of week
      if (delivery.weeklySchedule.monday) {
        const firstMonday = getFirstWeekdayAfterDate(new Date(delivery.startDate), 1);
        if (firstMonday.getDate() !== bst.getDate()) {
          const limitDate =
            firstMonday.getDate() + '/' + (firstMonday.getMonth() + 1) + '/' + firstMonday.getFullYear();
          setWith(errors, path.concat('bst').join('.'), 'First possible initial BST must be Monday ' + limitDate, Object);
        }
      } else if (delivery.weeklySchedule.tuesday) {
        const firstTuesday = getFirstWeekdayAfterDate(new Date(delivery.startDate), 2);
        const limitDate =
          firstTuesday.getDate() + '/' + (firstTuesday.getMonth() + 1) + '/' + firstTuesday.getFullYear();
        if (firstTuesday.getDate() !== bst.getDate()) {
          setWith(errors, path.concat('bst').join('.'), 'First possible initial BST must be Tuesday ' + limitDate, Object);
        }
      } else if (delivery.weeklySchedule.wednesday) {
        const firstWednesday = getFirstWeekdayAfterDate(new Date(delivery.startDate), 3);
        const limitDate =
          firstWednesday.getDate() + '/' + (firstWednesday.getMonth() + 1) + '/' + firstWednesday.getFullYear();
        if (firstWednesday.getDate() !== bst.getDate()) {
          setWith(errors, path.concat('bst').join('.'), 'First possible initial BST must be Wednesday ' + limitDate, Object);
        }
      } else if (delivery.weeklySchedule.thursday) {
        const firstThursday = getFirstWeekdayAfterDate(new Date(delivery.startDate), 4);
        const limitDate =
          firstThursday.getDate() + '/' + (firstThursday.getMonth() + 1) + '/' + firstThursday.getFullYear();
        if (firstThursday.getDate() !== bst.getDate()) {
          setWith(errors, path.concat('bst').join('.'), 'First possible initial BST must be Thursday ' + limitDate, Object);
        }
      } else if (delivery.weeklySchedule.friday) {
        const firstFriday = getFirstWeekdayAfterDate(new Date(delivery.startDate), 5);
        const limitDate = firstFriday.getDate() + '/' + (firstFriday.getMonth() + 1) + '/' + firstFriday.getFullYear();
        if (firstFriday.getDate() !== bst.getDate()) {
          setWith(errors, path.concat('bst').join('.'), 'First possible initial BST must be Friday ' + limitDate, Object);
        }
      } else if (delivery.weeklySchedule.saturday) {
        const firstSaturday = getFirstWeekdayAfterDate(new Date(delivery.startDate), 6);
        const limitDate =
          firstSaturday.getDate() + '/' + (firstSaturday.getMonth() + 1) + '/' + firstSaturday.getFullYear();
        if (firstSaturday.getDate() !== bst.getDate()) {
          setWith(errors, path.concat('bst').join('.'), 'First possible initial BST must be Saturday ' + limitDate, Object);
        }
      } else if (delivery.weeklySchedule.sunday) {
        const firstSunday = getFirstWeekdayAfterDate(new Date(delivery.startDate), 7);
        const limitDate = firstSunday.getDate() + '/' + (firstSunday.getMonth() + 1) + '/' + firstSunday.getFullYear();
        if (firstSunday.getDate() !== bst.getDate()) {
          setWith(errors, path.concat('bst').join('.'), 'First possible initial BST must be Sunday ' + limitDate, Object);
        }
      }
    }
  }
  //
  // DATES / TIMES VALIDATION
  //
  validateEventDate('bst', delivery, path, errors);
  validateEventDate('eta', delivery, path, errors);
  validateEventDate('etd', delivery, path, errors);
  validateEventDate('ata', delivery, path, errors);
  validateEventDate('atd', delivery, path, errors);

  validateEventDate('bunkershipEta', delivery, path, errors);
  validateEventDate('bunkershipAta', delivery, path, errors);
  validateEventDate('bunkershipEtd', delivery, path, errors);
  validateEventDate('bunkershipAtd', delivery, path, errors);

  const monthStart = yearMonth ? calculateMinMonthDate(yearMonth, timezone) : undefined;
  const monthEnd = yearMonth ? calculateMaxMonthDate(yearMonth, timezone) : undefined;

  if (delivery.eta) {
    const pathEta = path.concat('eta').join('.');
    if (delivery.etd && isAfter(DateTime.fromISO(delivery.eta), DateTime.fromISO(delivery.etd))) {
      setWith(errors, pathEta, 'ETA must be before ETD', Object);
    }
  }

  /*   if (delivery.deliveryMode === 'SHIP' && delivery.bunkershipEta) {
    const pathEta = path.concat('bunkershipEta').join('.');

    if (
      delivery.bunkershipEtd &&
      isAfter(DateTime.fromISO(delivery.bunkershipEta), DateTime.fromISO(delivery.bunkershipEtd))
    ) {
      setWith(errors, pathEta, 'LBV ETA must be before ETD', Object);
    }
  } */

  if (delivery.bst) {
    const pathBst = path.concat('bst').join('.');

    if (
      (monthStart && isBefore(DateTime.fromISO(delivery.bst), DateTime.fromJSDate(monthStart))) ||
      (monthEnd && isAfter(DateTime.fromISO(delivery.bst), DateTime.fromJSDate(monthEnd)))
    ) {
      setWith(errors, pathBst, 'BST must be within the current month', Object);
    } else if (
      (selectedContract && isBefore(DateTime.fromISO(delivery.bst), DateTime.fromISO(selectedContract.fromDate))) ||
      (selectedContract && isAfter(DateTime.fromISO(delivery.bst), DateTime.fromISO(selectedContract.toDate)))
    ) {
      setWith(
        errors,
        pathBst,
        `BST must be within the contract's period. (${DateTime.fromISO(selectedContract.fromDate).toFormat(
          'dd-MM-yyyy',
        )} - ${DateTime.fromISO(selectedContract.toDate).toFormat('dd-MM-yyyy')})`,
        Object,
      );
    } else if (
      (delivery.id == '00000000-0000-0000-0000-000000000000' || delivery.id == undefined || delivery.id == null) &&
      isBefore(DateTime.fromISO(delivery.bst), DateTime.local())
    ) {
      setWith(errors, pathBst, 'Initial BST must in the future', Object);
    }
  }

  if (delivery.etd) {
    const pathEtd = path.concat('etd').join('.');
    if (delivery.eta && isBefore(DateTime.fromISO(delivery.etd), DateTime.fromISO(delivery.eta))) {
      setWith(errors, pathEtd, 'ETD must be after ETA', Object);
    }
  }

  if (delivery.startDate) {
    const pathStartDate = path.concat('startDate').join('.');
    if (delivery.bst && isBefore(DateTime.fromISO(delivery.bst), DateTime.fromISO(delivery.startDate))) {
      setWith(errors, pathStartDate, 'BST must be after initial start date', Object);
    }
  }

  if (delivery.endDate) {
    const pathEndDateDate = path.concat('endDate').join('.');
    if (delivery.bst && isAfter(DateTime.fromISO(delivery.bst), DateTime.fromISO(delivery.endDate))) {
      setWith(errors, pathEndDateDate, 'BST must be before end date', Object);
    } else if (
      delivery.startDate &&
      //@ts-ignore
      DateTime.fromISO(delivery.endDate).diff(DateTime.fromISO(delivery.startDate), ['days']).values.days > 730
    ) {
      //not more than 2 years
      setWith(errors, pathEndDateDate, 'Start and end dates must be 2 years apart at maximum', Object);
    }
  }

  /*   if (delivery.deliveryMode === 'SHIP' && delivery.bunkershipEtd) {
    const pathEtd = path.concat('bunkershipEtd').join('.');
    if (
      delivery.bunkershipEta &&
      isBefore(DateTime.fromISO(delivery.bunkershipEtd ), DateTime.fromISO(delivery.bunkershipEta ))
    ) {
      setWith(errors, pathEtd, 'LBV ETD must be after ETA', Object);
    }
  } */

  if (delivery.eta && delivery.etd) {
    const pathEta = path.concat('eta').join('.');
    const pathEtd = path.concat('etd').join('.');
    if (datesAreEqual(DateTime.fromISO(delivery.eta), DateTime.fromISO(delivery.etd))) {
      setWith(errors, pathEta, 'ETA can not be at the same time as ETD', Object);
      setWith(errors, pathEtd, 'ETD can not be at the same time as ETA', Object);
    }
  }

  /* if (delivery.bunkershipEta && delivery.bunkershipEtd) {
    const pathEta = path.concat('bunkershipEta').join('.');
    const pathEtd = path.concat('bunkershipEtd').join('.');
    if (datesAreEqual(DateTime.fromISO(delivery.bunkershipEta ), DateTime.fromISO(delivery.bunkershipEtd ))) {
      setWith(errors, pathEta, 'LBV ETA can not be at the same time as ETD', Object);
      setWith(errors, pathEtd, 'LBV ETD can not be at the same time as ETA', Object);
    }
  } */
  //
  // AMOUNTS VALIDATION
  //
  amountValidation('amount', delivery, path, errors, true);
  amountValidation('actualAmount', delivery, path, errors, false);

  return errors;
}

export function validateNominationBST(
  delivery: IPromptNomination | RecurringNomination,
  userRoles: UserRole[],
): string | undefined {
  function validateReceivingVessel(): string | undefined {
    if (delivery.bst) {
      // Receiving vessel
      if (delivery.eta && isBefore(DateTime.fromISO(delivery.bst), DateTime.fromISO(delivery.eta))) {
        return 'Warning: proposed BST is before receiving vessel ETA';
      }
      if (delivery.etd && isAfter(DateTime.fromISO(delivery.bst), DateTime.fromISO(delivery.etd))) {
        return 'Warning: proposed BST is after receiving vessel ETD';
      }
    }
  }

  function validateBunkerVessel(): string | undefined {
    if (delivery.bst && delivery.deliveryMode === 'SHIP') {
      // Bunker Vessel
      /* if (
        delivery.bunkershipEta &&
        isBefore(DateTime.fromISO(delivery.bst ), DateTime.fromISO(delivery.bunkershipEta ))
      ) {
        return 'Warning: proposed BST is before bunker vessel ETA';
      }

      if (delivery.bunkershipEtd && isAfter(DateTime.fromISO(delivery.bst ), DateTime.fromISO(delivery.bunkershipEtd ))) {
        return 'Warning: proposed BST is after bunker vessel ETD';
      } */
    }
    return undefined;
  }

  // return the right message depending on the user role, i.e. in case of
  // supplier you first want to see the BunkerVessel warning (if any)
  return isScheduler(userRoles) || isSchedulerCaptain(userRoles)
    ? validateBunkerVessel() || validateReceivingVessel()
    : validateReceivingVessel() || validateBunkerVessel();
}

function amountValidation(
  label: string,
  nomination: IPromptNomination | RecurringNomination,
  path: Array<string | number>,
  errors: {},
  required: boolean,
) {
  // Deliveries minimal
  //@ts-ignore
  if (!required && !nomination[label]) {
    return;
  }
  //@ts-ignore
  if (label in nomination && (nomination[label] < MINIMUM_QUANTITY || nomination[label] >= MAXIMUM_QUANTITY)) {
    const errorMessage = `Quantity must be between ${MINIMUM_QUANTITY} and ${MAXIMUM_QUANTITY - 1} MT`;
    setWith(errors, path.concat(label).join('.'), errorMessage, Object);
  }
  //@ts-ignore
  if (!nomination[label] && required) {
    setWith(errors, path.concat(label).join('.'), 'This Field is Required', Object);
  }
}

export function parseRecurringNominationValues(promptNomination: RecurringNomination) {
  const nominationToUpdate = cloneDeep(promptNomination);

  const FIELDS_TO_PARSE = ['amount', 'allowedBunkeringTime'];

  // loops through all the fields and checks if they have been filled in.
  FIELDS_TO_PARSE.forEach((field) => {
    //@ts-ignore
    if (nominationToUpdate[field]) {
      //@ts-ignore
      nominationToUpdate[field] = parseFloat(nominationToUpdate[field]);
    }
  });

  return nominationToUpdate;
}

export function instanceOfRecurringNomination(
  object: IPromptNomination | INominationEnquiryEvent | RecurringNomination,
): object is RecurringNomination {
  //IPromptNomination and INominationEnquiryEvent have _id where as RecurringNomination has id
  return (object as RecurringNomination).id !== undefined;
}
