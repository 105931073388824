import React from 'react';
import { SpotEnquiry } from '../../../../store/Spot/models';
import { IShipWithId, ILocation } from '@teqplay/chorus-components';
import SpotTable from './SpotTable';
import styles from './SpotTable.module.scss';
import NoNominations from '../NoNominations/NoNominations';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
const rightArrow = findIconDefinition({
  prefix: 'far',
  iconName: 'arrow-right',
});

interface OwnProps {
  spots: SpotEnquiry[];
  archived: boolean;
  sortByNeedsAction: boolean;
}

const SpotTableContainer: React.FC<OwnProps> = ({ spots, archived, sortByNeedsAction }) => {
  const sortSpotsByTime = (a: SpotEnquiry, b: SpotEnquiry) => {
    const dateA = new Date(a.bst);
    const dateB = new Date(b.bst);
    if (dateA > dateB) return 1;
    return -1;
  };

  const sortSpotsByNeedsAction = (a: SpotEnquiry, b: SpotEnquiry) => {
    let number = 0;
    if (a.attributes && a.attributes.needsAction) {
      number -= 1;
    }
    if (b.attributes && b.attributes.needsAction) {
      number += 1;
    }
    return number;
  };

  const sortedSpots = [...spots].sort(sortSpotsByTime);
  const sortedSpotsByNeedsAction = [...sortedSpots].sort(sortSpotsByNeedsAction);

  if (spots.length < 1) {
    return <NoNominations className={styles['message-wrapper']} archived={archived} />;
  } else {
    return (
      <div>
        <>
          {sortByNeedsAction && <SpotTable spots={sortedSpotsByNeedsAction} archived={archived} />}
          {!sortByNeedsAction && <SpotTable spots={sortedSpots} archived={archived} />}
        </>
        <Link to={'spotenquiries'}>
          <Button color="link" className={styles['view-all-button']}>
            View all <FontAwesomeIcon icon={rightArrow} />
          </Button>
        </Link>
      </div>
    );
  }
};

export default SpotTableContainer;
