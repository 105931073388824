import { vesselsReducer } from './Vessels/reducers';
import { VesselsWrapperModel } from './Vessels/models';
import { usersReducer } from './Users/reducers';
import { UserWrapperModel } from './Users/models';
import { AuthWrapperModel } from './Auth/models';
import { authReducer } from './Auth/reducers';
import { CompanyWrapperModel } from './Company/models';
import { companyReducer } from './Company/reducers';
import { NominationWrapperModel } from './Nomination/models';
import { nominationReducer } from './Nomination/reducers';
import { SpotWrapperModel } from './Spot/models';
import { spotReducer } from './Spot/reducers';
import { chatReducer } from './Chat/reducers';
import { bunkerShipReducer } from './BunkerShip/reducers';
import { userCompanyFleetReducer } from './UserCompanyFleet/reducers';
import { ChatWrapperModel } from './Chat/models';
import { BunkerShipWrapperModel } from './BunkerShip/models';
import { BunkerPipelineWrapperModel } from './BunkerPipeline/models';
import { bunkerPipelineReducer } from './BunkerPipeline/reducers';
import { UserCompanyFleetWrapperModel } from './UserCompanyFleet/models';
import { CompanyLogoWrapperModel } from './CompanyLogo/models';
import {
  latest_positionsReducer,
  historic_positionsReducer,
  myfleet_imosReducer,
  mapbox_tokenReducer,
} from './Map/reducers';
import {
  LatestPositionsWrapperModel,
  HistoricPositionsWrapperModel,
  MyFleetImosWrapperModel,
  MapboxTokenWrapperModel,
} from './Map/models';
import { RoleWrapperModel } from './Roles/models';
import { rolesReducer } from './Roles/reducers';
import { ChorusCompanyWrapperModel } from './ChorusCompany/models';
import { chorusCompanyReducer } from './ChorusCompany/reducers';
import { LocationWrapperModel, LocUploadWrapperModel } from './ChorusLocations/models';
import { locationReducer, locationUploadReducer } from './ChorusLocations/reducers';
import { BIvesselsWrapperModel } from './BIvessels/models';
import { bivesselsReducer } from './BIvessels/reducers';
import { UserVesselWrapperModel } from './MyFleet/models';
import { userVesselReducer } from './MyFleet/reducers';
import { MyFleetsWrapperModel } from './MyFleets/models';
import { myFleetsReducer } from './MyFleets/reducers';
import { BIcompanyFleetWrapperModel } from './BIcompanyFleet/models';
import { BIcompanyFleetReducer } from './BIcompanyFleet/reducers';
import { FleetVesselWrapperModel } from './CompanyFleet/models';
import { fleetVesselReducer } from './CompanyFleet/reducers';
import { ArchivedNominationWrapperModel } from './ArchivedNomination/models';
import { archived_nominationReducer } from './ArchivedNomination/reducers';
import { ArchivedSpotWrapperModel } from './ArchivedSpot/models';
import { archived_spotReducer } from './ArchivedSpot/reducers';
import { PipelineWrapperModel } from './Pipelines/models';
import { pipelineReducer } from './Pipelines/reducers';
import { connectivityReducer } from './ConnectivityCheck/reducers';
import { ConnectivityWrapperModel } from './ConnectivityCheck/models';
import { CategoryWrapperModel } from './Category/models';
import { categoriesReducer } from './Category/reducers';
import { FuelbossLocationWrapperModel } from './FuelbossLocations/models';
import { fuelbossLocationsReducer } from './FuelbossLocations/reducers';
import { ContractWrapperModel } from './Contract/models';
import { contractReducer } from './Contract/reducers';
import { GlobalWrapperModel } from './Global/models';
import { globalReducer } from './Global/reducers';
import { ChorusContractsWrapperModel } from './ChorusContract/models';
import { chorusContractsReducer } from './ChorusContract/reducers';
import { ReportWrapperModel } from './Reports/models';
import { reportReducer } from './Reports/reducers';
import { ReportTypeWrapperModel } from './ReportTypes/models';
import { reportTypeReducer } from './ReportTypes/reducers';
import { companyLogoReducer } from './CompanyLogo/reducers';
import { EmailSettingsWrapperModel } from './EmailSettings/models';
import { emailSettingsReducer } from './EmailSettings/reducers';
import { ContractDocumentsWrapperModel } from './ContractDocuments/models';
import { contractDocumentsReducer } from './ContractDocuments/reducers';

import { AISFilterBookmarkWrapperModel } from './AISFilterBookmark/models';
import { aisFilterBookmarkReducer } from './AISFilterBookmark/reducers';
import { DocumentFilterBookmarkWrapperModel } from './DocumentFilterBookmark/models';
import { documentFilterBookmarkReducer } from './DocumentFilterBookmark/reducers';
import { ChorusDocumentWrapperModel } from './ChorusDocument/models';
import { chorusDocumentReducer } from './ChorusDocument/reducers';


import { aisListColumnReducer } from './AISListColumn/reducers';
import { AISListColumnrapperModel } from './AISListColumn/models';
import { VolumeStatsWrapperModel } from './VolumeStats/models';
import { volumeStatsReducer } from './VolumeStats/reducers';
import { RecurringNominationWrapperModel } from './RecurringNomination/models';
import { recurring_nominationReducer } from './RecurringNomination/reducers';
import { NominationScheduleWrapperModel } from './NominationSchedule/models';
import { nomination_scheduleReducer } from './NominationSchedule/reducers';
import { FieldPermissionRecurringWrapperModel } from './FieldPermissionsRecurringForm/models';
import { fieldPermissionRecurringReducer } from './FieldPermissionsRecurringForm/reducers';
import { FleetChartererWrapperModel } from './CompanyCharteredFleet/models';
import { fleetChartererReducer } from './CompanyCharteredFleet/reducers';

import { CompanyOperationalLocationsWrapperModel } from './CompanyOperationalLocation/models';
import { companyOperationaLocationsReducer } from './CompanyOperationalLocation/reducers';
import { BDNLocationWrapperModel } from './BDNLocation/models';
import { bdnLocationsReducer } from './BDNLocation/reducers';
import { BDNLocationSettingsWrapperModel } from './BDNLocationSettings/models';
import { bdnLocationSettingsReducer } from './BDNLocationSettings/reducers';
import { CompanyLogosWrapperModel } from './CompanyLogos/models';
import { companyLogosReducer } from './CompanyLogos/reducers';
import { SanityConfigWrapperModel } from './Sanity/models';
import { sanity_configReducer } from './Sanity/reducers';
import { integrationAssetReducer } from './IntegrationAsset/reducers';
import { IntegrationAssetWrapperModel } from './IntegrationAsset/models';
import { IntegrationAssetTypeWrapperModel } from './IntegrationAssetType/models';
import { integrationAssetTypeReducer } from './IntegrationAssetType/reducers';
import { IntegrationAssetCompanyWrapperModel } from './IntegrationAssetCompanies/models';
import { integrationAssetCompanyReducer } from './IntegrationAssetCompanies/reducers';
import { IntegrationEventTypeWrapperModel } from './IntegrationEventTypes/models';
import { integrationEventTypeReducer } from './IntegrationEventTypes/reducers';
import { IntegrationAssetTopicWrapperModel } from './IntegrationAssetTopic/models';
import { integrationAssetTopicReducer } from './IntegrationAssetTopic/reducers';
import { IntegrationAssetIssuersWrapperModel } from './IntegrationAssetIssuer/models';
import { integrationAssetIssuersReducer } from './IntegrationAssetIssuer/reducers';
import { IntegrationAssetPermissionsWrapperModel } from './IntegrationAssetPermissions/models';
import { integrationAssetPermissionsReducer } from './IntegrationAssetPermissions/reducers';
import { IntegrationAssetApiTrackerWrapperModel } from './IntegrationAssetApiTracker/models';
import { integrationAssetApiTrackerReducer } from './IntegrationAssetApiTracker/reducers';
import { IntegrationAssetWorkspaceWrapperModel } from './IntegrationAssetWorkspaces/models';
import { integrationAssetWorkspaceReducer } from './IntegrationAssetWorkspaces/reducers';
import { OutboundIntegrationApiTrackerWrapperModel } from './IntegrationOutboundApiTracker/models';
import { outboundIntegrationApiTrackerReducer } from './IntegrationOutboundApiTracker/reducers';

//�The�top-level�state�object
export interface ApplicationState {
  vessels: VesselsWrapperModel;
  users: UserWrapperModel;
  fuelbosslocations: FuelbossLocationWrapperModel;
  auth: AuthWrapperModel;
  companies: CompanyWrapperModel;
  contracts: ContractWrapperModel;
  nomination: NominationWrapperModel;
  archived_nomination: ArchivedNominationWrapperModel;
  recurring_nomination: RecurringNominationWrapperModel;
  nomination_schedule: NominationScheduleWrapperModel;
  spot: SpotWrapperModel;
  archived_spot: ArchivedSpotWrapperModel;
  chat: ChatWrapperModel;
  latest_positions: LatestPositionsWrapperModel;
  historic_positions: HistoricPositionsWrapperModel;
  myfleet_imos: MyFleetImosWrapperModel;
  mapbox_token: MapboxTokenWrapperModel;
  sanity_config: SanityConfigWrapperModel;
  bunkership: BunkerShipWrapperModel;
  usercompanyfleet: UserCompanyFleetWrapperModel;
  roles: RoleWrapperModel;
  choruscompany: ChorusCompanyWrapperModel;
  choruscontracts: ChorusContractsWrapperModel;
  location: LocationWrapperModel;
  bivessels: BIvesselsWrapperModel;
  location_upload: LocUploadWrapperModel;
  myFleet: UserVesselWrapperModel;
  myFleets: MyFleetsWrapperModel;
  bicompanyfleet: BIcompanyFleetWrapperModel;
  companyFleet: FleetVesselWrapperModel;
  companyCharteredFleet : FleetChartererWrapperModel;
  pipelines: PipelineWrapperModel;
  bunkerpipeline: BunkerPipelineWrapperModel;
  reports: ReportWrapperModel;
  report_types: ReportTypeWrapperModel;
  categories: CategoryWrapperModel;
  global: GlobalWrapperModel;
  company_logo: CompanyLogoWrapperModel;
  connectivity_check: ConnectivityWrapperModel;
  emailsettings: EmailSettingsWrapperModel;
  contract_documents: ContractDocumentsWrapperModel;
  ais_filterbookmarks: AISFilterBookmarkWrapperModel;
  document_filterbookmarks: DocumentFilterBookmarkWrapperModel;
  chorusdocument: ChorusDocumentWrapperModel;
  ais_listcolumn: AISListColumnrapperModel;
  volume_stats: VolumeStatsWrapperModel;
  fieldPermissionRecurringForm: FieldPermissionRecurringWrapperModel;
  company_operational_locations: CompanyOperationalLocationsWrapperModel;
  bdn_locations: BDNLocationWrapperModel;
  bdn_location_settings: BDNLocationSettingsWrapperModel;
  company_logos: CompanyLogosWrapperModel;
  integration_assets: IntegrationAssetWrapperModel;
  integration_assets_types: IntegrationAssetTypeWrapperModel;
  integration_assets_companies: IntegrationAssetCompanyWrapperModel;
  integration_events_types: IntegrationEventTypeWrapperModel;
  integration_assets_topic: IntegrationAssetTopicWrapperModel;
  integration_assets_issuers: IntegrationAssetIssuersWrapperModel;
  integration_assets_permissions: IntegrationAssetPermissionsWrapperModel;
  integration_assets_apitracker: IntegrationAssetApiTrackerWrapperModel;
  integration_outbound_api_tracker: OutboundIntegrationApiTrackerWrapperModel;
  integration_assets_workspaces: IntegrationAssetWorkspaceWrapperModel
}
// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  vessels: vesselsReducer,
  users: usersReducer,
  fuelbosslocations: fuelbossLocationsReducer,
  auth: authReducer,
  companies: companyReducer,
  contracts: contractReducer,
  nomination: nominationReducer,
  archived_nomination: archived_nominationReducer,
  recurring_nomination: recurring_nominationReducer,
  nomination_schedule: nomination_scheduleReducer,
  spot: spotReducer,
  archived_spot: archived_spotReducer,
  chat: chatReducer,
  latest_positions: latest_positionsReducer,
  historic_positions: historic_positionsReducer,
  myfleet_imos: myfleet_imosReducer,
  mapbox_token: mapbox_tokenReducer,
  sanity_config: sanity_configReducer,
  bunkership: bunkerShipReducer,
  usercompanyfleet: userCompanyFleetReducer,
  roles: rolesReducer,
  choruscompany: chorusCompanyReducer,
  choruscontracts: chorusContractsReducer,
  location_upload: locationUploadReducer,
  location: locationReducer,
  bicompanyfleet: BIcompanyFleetReducer,
  bivessels: bivesselsReducer,
  myFleet: userVesselReducer,
  myFleets: myFleetsReducer,
  companyFleet: fleetVesselReducer,
  companyCharteredFleet: fleetChartererReducer,
  pipelines: pipelineReducer,
  bunkerpipeline: bunkerPipelineReducer,
  reports: reportReducer,
  report_types: reportTypeReducer,
  categories: categoriesReducer,
  global: globalReducer,
  company_logo: companyLogoReducer,
  connectivity_check: connectivityReducer,
  emailsettings: emailSettingsReducer,
  contract_documents: contractDocumentsReducer,
  ais_filterbookmarks: aisFilterBookmarkReducer,
  document_filterbookmarks:documentFilterBookmarkReducer,
  chorusdocument: chorusDocumentReducer,
  ais_listcolumn: aisListColumnReducer,
  volume_stats: volumeStatsReducer,
  fieldPermissionRecurringForm: fieldPermissionRecurringReducer,
  company_operational_locations: companyOperationaLocationsReducer,
  bdn_locations: bdnLocationsReducer,
  bdn_location_settings: bdnLocationSettingsReducer,
  company_logos: companyLogosReducer,
  integration_assets: integrationAssetReducer,
  integration_assets_types: integrationAssetTypeReducer,
  integration_assets_companies: integrationAssetCompanyReducer,
  integration_events_types: integrationEventTypeReducer,
  integration_assets_topic: integrationAssetTopicReducer,
  integration_assets_issuers: integrationAssetIssuersReducer,
  integration_assets_permissions: integrationAssetPermissionsReducer,
    integration_assets_apitracker: integrationAssetApiTrackerReducer,
    integration_assets_workspaces: integrationAssetWorkspaceReducer,
    integration_outbound_api_tracker: outboundIntegrationApiTrackerReducer,
};
// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
