import * as TYPE from './types';

import { ApplicationState } from '..';

export const authReducer = (
  state: ApplicationState['auth'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: {
      name: '',
      email: '',
      roles: [],
      userId: '',
      veracityId: '',
      company: undefined,
      defaultFleet: [],
      isAuthed: false,
      isAdmin: false,
      isMFA: false,
      mfaRedirected: false,
      ac: '',
      sessionTimeOut: undefined,
      chorusUserProfile: {
        _id: '',
        email: '',
        name: ' ',
        roles: [],
        active: true,
        companyId: '',
        linked: true,
        notifications: [],
        bunkerShipId: null,
        receivingShipId: '',
        loadingFacilityId: '',
        pipelineId: null,
      },
      vessel: undefined,
      pipeline: undefined,
      chorusId: '',
      chorusBaseUrl: '',
    },
    metadata: {},
  },
  action: TYPE.AuthActions,
): ApplicationState['auth'] => {
  switch (action.type) {
    case TYPE.AUTH_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.AUTH_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_AUTH:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
