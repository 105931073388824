import { ErrorActionPayload } from '../../models/ErrorAction';
import { BunkerPipelineWrapperModel } from './models';

export const BUNKERPIPELINE_LOADING = 'bunkerPipeline/loading';
export type BUNKERPIPELINE_LOADING = typeof BUNKERPIPELINE_LOADING;

export const GET_BUNKERPIPELINE = 'bunkerPipeline/get';
export type GET_BUNKERPIPELINE = typeof GET_BUNKERPIPELINE;

export const BUNKERPIPELINE_FETCH_ERROR = 'bunkerPipeline/fetchError';
export type BUNKERPIPELINE_FETCH_ERROR = typeof BUNKERPIPELINE_FETCH_ERROR;

export type BunkerPipelineActions =
  | {
      type: GET_BUNKERPIPELINE;
      payload: BunkerPipelineWrapperModel['data'];
    }
  | {
      type: BUNKERPIPELINE_LOADING;
    }
  | {
      type: BUNKERPIPELINE_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
