import React from 'react';
import { Company } from '../../../../../store/Company/models';
import FormikWrapper from '../Formik/FormikWrapper';

interface OwnProps {
  addNewCompany: (company: Company) => void;
  systemAdmin?: boolean;
  navAdminType: string;
}

const AddCompany: React.FC<OwnProps> = ({ addNewCompany, systemAdmin, navAdminType }) => {
  const submitCompany = (company: Company) => {
    addNewCompany(company);
  };

  return (
    <FormikWrapper
      systemAdmin={systemAdmin}
      header="Add company"
      addCompany={submitCompany}
      navAdminType={navAdminType}
    />
  );
};

export default React.memo(AddCompany);
