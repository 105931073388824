import { Dispatch } from 'redux';
import * as TYPE from './types';
import { api } from '../../helpers/api';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { BDNLocationResponseModel, BDNLocation } from './models';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating ...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Updated successfully</ModalResponse>;
const errorModalGeneral = <ModalResponse status="error">There has been an error</ModalResponse>;
const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating BDN Location...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">BDN Location created successfully</ModalResponse>;

export const getBDNLocations = () => (dispatch: Dispatch<TYPE.BDNLocationsActions>) => {
  dispatch({
    type: TYPE.BDN_LOCATIONS_LOADING,
  });

  new api<BDNLocationResponseModel>({
    dispatch,
    errorType: TYPE.BDN_LOCATIONS_FETCH_ERROR,
  })
    .get(`/api/BDNLocations/`)
    .then((response) => {
      dispatch({
        type: TYPE.GET_BDN_LOCATIONS,
        payload: response,
      });
    });
};

export const updateBDNLocations = (
  bdnLocations: BDNLocation[],
  onSuccess: () => void,
) => (dispatch: Dispatch<TYPE.BDNLocationsActions>) => {
  dispatch({
    type: TYPE.BDN_LOCATIONS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/BDNLocations/updateBDNLocations`;

  new api<BDNLocationResponseModel>({
    dispatch,
    errorType: TYPE.UPDATE_BDN_LOCATIONS_ERROR,
  })
    .put(url, bdnLocations)
    .then((response) => {
      dispatch({
        type: TYPE.UPDATE_BDN_LOCATIONS,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModalGeneral });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteBDNLocations = (id: string) => (dispatch: Dispatch<TYPE.BDNLocationsActions>) => {
  dispatch({
    type: TYPE.BDN_LOCATIONS_LOADING,
  });

  const url = `/api/BDNLocations/${id}`;
  new api<BDNLocationResponseModel>({
    dispatch,
    errorType: TYPE.DELETE_BDN_LOCATIONS_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: TYPE.DELETE_BDN_LOCATIONS,
        payload: response,
      });
    });
  
};

export const addBDNLocation = (bdnLocation: BDNLocation, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.BDNLocationsActions>,
) => {
  dispatch({
    type: TYPE.BDN_LOCATIONS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<BDNLocationResponseModel>({
    dispatch,
    errorType: TYPE.ADD_BDN_LOCATIONS_ERROR,
  })
    .post(`/api/BDNLocations`, bdnLocation)
    .then((response) => {
      dispatch({
        type: TYPE.ADD_BDN_LOCATIONS,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(error => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModalGeneral });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      return Promise.reject();
    });
};

export const BDNLocationsClearToast = () => (dispatch: Dispatch<TYPE.BDNLocationsActions>) => {
  dispatch({
    type: TYPE.BDN_LOCATIONS_CLEAR_TOAST,
  });
};
