import React, { useState, useEffect } from 'react';

interface OwnProps {
  updateCall: () => void;
}

const TimerUpdate: React.FC<OwnProps> = (props) => {
  const { updateCall } = props;
  const [seconds, setSeconds] = useState(0);

  // if (seconds > 30) {
  if (seconds > 15) {
    updateCall();
    setSeconds(0);
  }

  // console.log(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default TimerUpdate;
