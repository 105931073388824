import * as React from 'react';
import { DeliveryModes } from '../../../../../../../models/DeliveryMode';
import styles from './LowCarbonOption.module.scss';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../../../store';

const checkIcon = findIconDefinition({ prefix: 'fas', iconName: 'check-circle' });

interface OwnProps {
  initToggled: boolean;
  onClick: () => void;
}

const LowCarbonOption: React.FC<OwnProps> = ({ initToggled, onClick, children }) => {
  const [toggled, setToggled] = React.useState(initToggled);
  const [modal, setModal] = React.useState(false);
  const toggleConfirmationModal = () => setModal(!modal);

  //TODO add modal confirmation
  const handelOnClick = () => {
    toggleConfirmationModal();
    onClick();
  };

  const checkIconHandler = () => {
    if (initToggled) {
      return <FontAwesomeIcon className={styles.check} icon={checkIcon} />;
    }
  };

  return (
    <div>
      <div
        className={classnames(styles.button, toggled ? styles.active : styles.inactive)}
        onClick={toggleConfirmationModal}
      >
        {checkIconHandler()}
        {children}
      </div>
      <Modal isOpen={modal} toggle={toggleConfirmationModal} className={'lowcarbonoption-modal-confirmation'}>
        <ModalHeader toggle={toggleConfirmationModal} className={styles['lowcarbonoption-modal-header']}>
          Confirmation
        </ModalHeader>
        <ModalBody className={styles['lowcarbonoption-modal-body']}>
          Are you sure you want to {initToggled ? 'disable' : 'enable'} this low carbon option?
        </ModalBody>
        <ModalFooter className={styles['lowcarbonoption-modal-footer']}>
          <Button color="primary" onClick={handelOnClick}>
            Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggleConfirmationModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LowCarbonOption;
