import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { ChatActions, CHAT_LOADING, CHAT_FETCH_ERROR, GET_CHAT, NOTIFY_CHAT, NOTIFY_CHAT_ERROR } from './types';
import { ChatResponseModel, ChatNotificationResponseModel } from './models';

export const getChat = (nominationId: string) => (dispatch: Dispatch<ChatActions>) => {
  dispatch({
    type: CHAT_LOADING,
  });

  new api<ChatResponseModel>({
    dispatch,
    errorType: CHAT_FETCH_ERROR,
  })
    .get(`/api/chat/${nominationId}`)
    .then((response) => {
      dispatch({
        type: GET_CHAT,
        payload: response,
      });
    });
};

export const getDelegateChat = (nominationId: string, delegateEventId: string) => (dispatch: Dispatch<ChatActions>) => {
  dispatch({
    type: CHAT_LOADING,
  });

  new api<ChatResponseModel>({
    dispatch,
    errorType: CHAT_FETCH_ERROR,
  })
    .get(`/api/chat/delegate/${nominationId}/${delegateEventId}`)
    .then((response) => {
      dispatch({
        type: GET_CHAT,
        payload: response,
      });
    });
};


export const notifyChat = (id: string, relatedValues: {}) => (dispatch: Dispatch<ChatActions>) => {

    const url = `/api/chat/notify/${id}`;

    const ChatNotificationInput = {
        relevantValues: relatedValues
    };

    new api<ChatNotificationResponseModel>({
        dispatch,
        errorType: NOTIFY_CHAT_ERROR,
        })
        .post(url, ChatNotificationInput)
        .then((response) => {
            dispatch({
                type: NOTIFY_CHAT,
                payload: response,
            });
        })

        .catch((err) => {
            dispatch({
                type: NOTIFY_CHAT_ERROR,
                //@ts-ignore
                payload: {
                    clientFault: false,
                    serverFault: true,
                    //@ts-ignore
                    statusCode: Response.error().status,
                    //@ts-ignore
                    message: Response.error().statusText,
                },
            });
            dispatch({
                type: NOTIFY_CHAT,
                payload: [],
            });
        });

        
};