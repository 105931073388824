import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { ArchivedSpotActions, ARCHIVED_SPOT_FETCH_ERROR, ARCHIVED_SPOT_LOADING } from './types';
import { ArchivedSpotResponseModel, PaginatedArchivedSpotResponseModel } from './models';
import { GET_ARCHIVED_SPOT } from './types';

export const getArchivedSpots = () => (dispatch: Dispatch<ArchivedSpotActions>) => {
  dispatch({
    type: ARCHIVED_SPOT_LOADING,
  });
  const url = `api/chorus/GetAllSpotEnquiries/` + '2020-01-01T00:00:00' + `/` + '2099-01-01T00:00:00' + `/archived/`;
  new api<ArchivedSpotResponseModel>({
    dispatch,
    errorType: ARCHIVED_SPOT_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: ARCHIVED_SPOT_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_ARCHIVED_SPOT,
          payload: { data: response },
        });
      }
    });
};

export const getArchivedSpotsPaginated = ( pageIndex: number, pageSize: number) => (
  dispatch: Dispatch<ArchivedSpotActions>,
) => {
  dispatch({
    type: ARCHIVED_SPOT_LOADING,
  });
  const url = `api/chorus/GetAllSpotEnquiriesPaginated/archived/?pageSize=${pageSize}&pageIndex=${pageIndex}`;
  new api<PaginatedArchivedSpotResponseModel>({
    dispatch,
    errorType: ARCHIVED_SPOT_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: ARCHIVED_SPOT_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_ARCHIVED_SPOT,
          payload: { data: response.page, metadata: { ...response } },
        });
      }
    });
};
