import { ErrorActionPayload } from '../../models/ErrorAction';
import { VolumeStats } from './models';

export const VOLUMESTATS_LOADING = 'VolumeStats/loading';
export type VOLUMESTATS_LOADING = typeof VOLUMESTATS_LOADING;

export const GET_VOLUMESTATS = 'volumeStats/get';
export type GET_VOLUMESTATS = typeof GET_VOLUMESTATS;

export const VOLUMESTATS_FETCH_ERROR = 'volumeStats/fetchError';
export type VOLUMESTATS_FETCH_ERROR = typeof VOLUMESTATS_FETCH_ERROR;

export type VolumeStatsActions =
  | {
      type: GET_VOLUMESTATS;
      payload: VolumeStats;
    }
  | {
      type: VOLUMESTATS_LOADING;
    }
  | {
      type: VOLUMESTATS_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
