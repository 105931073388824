import React, { useState, useCallback, useEffect } from 'react';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { FormGroup, Input, Button } from 'reactstrap';
import { Pipeline } from '../../store/Pipelines/models';
import styles from './SearchPipeline.module.scss';

interface OwnProps {
  addPipelineMethod: (pipeline: Pipeline) => void;
  hidden?: boolean;
  buttonStyle?: string;
}

const SearchPipeline: React.FC<OwnProps> = ({ addPipelineMethod, hidden = false, buttonStyle }) => {
  const ref = React.useRef(null);
  const dispatch = useDispatch();

  const [pipelineName, setPipelineName] = useState('');
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);

  let StyledButton: React.CSSProperties = {
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    height: '50px',
    padding: '0 20px 3px 15px',
  } as React.CSSProperties;
  let StyledInput: React.CSSProperties = { height: '50px', width: '400px', borderRadius: '0' } as React.CSSProperties;
  let StyledInputParent: React.CSSProperties = {
    flex: '1',
    display: 'flex',
    height: '46px',
    whiteSpace: 'nowrap',
  } as React.CSSProperties;
  let StyledImage: React.CSSProperties = {
    marginRight: '10px',
    width: '30px',
    height: '25px',
  } as React.CSSProperties;
  let StyledFormGroup: React.CSSProperties = {
    position: 'relative',
    marginBottom: '10px',
  } as React.CSSProperties;
  let StyledOwnedVesselText: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    paddingRight: '5px',
  } as React.CSSProperties;

  useEffect(() => {}, []);

  const onChange = (val: string) => {
    setPipelineName(val);
  };

  const addPipelineClick = () => {
    if (pipelineName != undefined && pipelineName.length > 0) {
      let pipe = {} as Pipeline;
      pipe.name = pipelineName;
      addPipelineMethod(pipe);
    }
  };

  return (
    // @ts-ignore
    <FormGroup hidden={hidden} style={StyledFormGroup} autoComplete="off">
      <div style={StyledInputParent}>
        <h3 style={StyledOwnedVesselText}>Pipelines</h3>
        {!isSearchInputVisible && (
          <Button
            className={buttonStyle}
            color="primary"
            size="lg"
            disabled={false}
            onClick={(e) => setIsSearchInputVisible(true)}
            // @ts-ignore
            style={StyledButton}
          >
            Add pipeline
          </Button>
        )}
        {isSearchInputVisible && (
          <Input
            // @ts-ignore
            style={StyledInput}
            type="text"
            name="pipeline"
            id="pipeline"
            onChange={(e) => onChange(e.target.value)}
            value={pipelineName}
            autoComplete="off"
            placeholder="Type pipeline name ..."
          />
        )}
        {isSearchInputVisible && (
          <Button
            className={buttonStyle}
            color="primary"
            size="lg"
            disabled={pipelineName.length == 0 ? true : false}
            onClick={addPipelineClick}
            // @ts-ignore
            style={StyledButton}
          >
            Add pipeline
          </Button>
        )}
      </div>
    </FormGroup>
  );
};

export default React.memo(SearchPipeline);
