import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';

const validationMessage = {message: 'Not valid', exculdeEmptyString: true};

export default Yup.object().shape({
    companyId: Yup.string().required('Company id required').min(24, 'Must be exactly 24 digits').max(24, 'Must be exactly 24 digits'),
    veracityTenantId: Yup.string().matches(integrationAssetConfigs.veracityTenantId.regex, validationMessage).required('Company id required'),
    companyName: Yup.string().matches(integrationAssetConfigs.company.regex, validationMessage).required('Company name required'),
    timeStamp: Yup.date().optional(),
})