import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  position: relative;
  > button {
    position: absolute;
    top: -50px;
    right: 0;
    margin: 0.5em 0;
    font-weight: 600;
  }
`;
