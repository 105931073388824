import * as TYPE from './types';

import { ApplicationState } from '..';

export const locationUploadReducer = (
  state: ApplicationState['location_upload'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.LocationActions,
): ApplicationState['location_upload'] => {
  switch (action.type) {
    case TYPE.LOCUPLOAD_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.LOCUPLOAD_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_LOCUPLOAD:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.IMPORT_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Locations succesfully updated!',
          type: 'success',
        },
      };
    case TYPE.IMPORT_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Error. Not able to delete/update/add all locations.',
          type: 'error',
        },
      };
    // case TYPE.IMPORT_LOCATIONS_CLEAR_TOAST:
    //   return {
    //     ...state,
    //     toastData: undefined,
    //   };
    default:
      return { ...state };
  }
};

export const locationReducer = (
  state: ApplicationState['location'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.LocationActions,
): ApplicationState['location'] => {
  switch (action.type) {
    case TYPE.LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.LOCATION_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_LOCATION:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
