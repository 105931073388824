/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { Contract } from '../../../../../store/Contract/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { ICompany } from '@teqplay/chorus-components';
import { Company } from '../../../../../store/Company/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import FuelBossTableContainer from '../../../../FuelBossTable';
import DeleteById from '../../../../DeleteById/DeleteById';
import styles from './Contracts.module.scss';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import AddContractsLogo from '../../../../../svgs/add-contract.svg';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { toast } from 'react-toastify';
import TimerUpdate from '../../../../TimerUpdate/TimerUpdate';
import { checkShipOwner } from '../../../../../helpers/roleChecks';
import EditById from '../../../../EditById/EditById';
import { CustomInput, Label, Input, Button } from 'reactstrap';
import { checkSupplier } from '../../../../../helpers/roleChecks';

//const penIcon = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const bellIcon = findIconDefinition({ prefix: 'fal', iconName: 'bell' });
const minusCircle = findIconDefinition({ prefix: 'fal', iconName: 'minus-circle' });
const checkCircle = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
const hourGlass = findIconDefinition({ prefix: 'fal', iconName: 'hourglass-half' });
const warningTriangle = findIconDefinition({ prefix: 'fal', iconName: 'exclamation-triangle' });
const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const pdfIcon = findIconDefinition({ prefix: 'fal', iconName: 'file-pdf' });
const check = findIconDefinition({ prefix: 'fal', iconName: 'check' });

const arrowDown = findIconDefinition({
  prefix: 'fal',
  iconName: 'arrow-down',
});

const arrowRight = findIconDefinition({
  prefix: 'fal',
  iconName: 'arrow-right',
});
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

interface OwnProps {
  contracts: Contract[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteContract: (id: string) => void;
  updateSingleContract: (contract: Contract) => void;
  updateRequired: boolean;
  requireUpdate: (val: boolean) => void;
  toggleRejectModal: (contract: Contract) => void;
  getContracts: () => void;
  systemAdmin?: boolean;
  customers: ICompany[];
  suppliers: ICompany[];
  writePermission: boolean;
  company?: Company;
  navAdminType: string;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
  getContractDocuments?: (contractId: string) => void;
}

const Contracts: React.FC<OwnProps> = ({
  contracts,
  isLoading,
  hasError,
  errorDetails,
  deleteContract,
  updateSingleContract,
  toggleRejectModal,
  updateRequired,
  requireUpdate,
  getContracts,
  systemAdmin,
  customers,
  suppliers,
  writePermission,
  company,
  navAdminType,
  defaultSelectedPage,
  setDefaultSelectedPage,
  getContractDocuments,
}) => {
  const {
    authLoading,
    authError,
    authErrorDetails,
    auth,
    documentsLoading,
    documents,
    documentsIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
    documentsLoading: state.contract_documents.loading,
    documentsError: state.contract_documents.error,
    documentsErrorDetails: state.contract_documents.errorDetails,
    documents: state.contract_documents.data,
    documentsIsLoaded: state.contract_documents.dataIsLoaded,
  }));

  const [currentContracts, setCurrentContracts] = React.useState<Contract[]>([]);
  const [expandedContractId, setExpandedContractId] = React.useState<string>('');
  const isSupplier = checkSupplier(auth);

  const acceptContract = (contract: Contract) => {
    requireUpdate(true);
    contract.status = 'Confirmed';
    contract.hasBeenAccepted = true;
    updateSingleContract(contract);
  };

  const loadLatestStatuses = () => {
    setCurrentContracts(contracts);
    requireUpdate(false);
  };

  const CustomContractToast = () => (
    <div className={styles['toast-wrapper']}>
      <div>New contract information is available.</div>
      <Button className={styles['toast-button']} color="primary">
        Load latest statuses
      </Button>
    </div>
  );

  const contractStatusButton = (contract: any) => {
    switch (contract.status) {
      case null:
        return (
          <>
            <FontAwesomeIcon icon={warningTriangle} size={'lg'} color="#c4262e" />
            <div>Status not defined</div>
          </>
        );
      case 'Pending':
        return (
          <>
            {writePermission ? (
              <>
                <FontAwesomeIcon icon={hourGlass} size={'lg'} color="#66c5e9" />
                <div>Pending</div>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={bellIcon} size={'lg'} color="#c4262e" />
                <div>Action required</div>
              </>
            )}
          </>
        );
      case 'Confirmed':
        return (
          <>
            <FontAwesomeIcon icon={checkCircle} size={'2x'} color="#46aa1e" />
            <div>Accepted</div>
          </>
        );
      case 'Rejected':
        return (
          <>
            <FontAwesomeIcon icon={minusCircle} size={'lg'} color="black" />
            <div>Rejected</div>
          </>
        );
      default:
        return (
          <>
            <FontAwesomeIcon icon={checkCircle} size={'2x'} color="#46aa1e" />
            <div>Accepted</div>
          </>
        );
    }
  };

  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>, contract: Contract) => {
    const checked = event.target.checked;
    console.log('on change checkbox', checked, contract);
    if (!checked && contract.contractId) {
      deleteContract(contract.contractId);
    } else if (checked && contract.contractId) {
      updateSingleContract(contract);
    }
  };

  const headersNew: Array<Column<Contract>> = React.useMemo(
    () => [
      {
        Header: 'Supplier',
        accessor: (row) => {
          if (suppliers && suppliers.length > 0) {
            const sup = suppliers.find((e) => e._id === row.supplierId);
            if (sup !== undefined && sup != null) {
              return sup.name;
            }
            return '';
          }
        },
      },
      {
        Header: 'Customer',
        accessor: (row) => {
          if (customers && customers.length > 0) {
            const cust = customers.find((e) => e._id === row.customerId);
            if (cust !== undefined && cust != null) {
              return cust.name;
            }
            return '';
          }
        },
      },
      {
        Header: 'Name',
        accessor: (row, index) => row.name,
      },
      {
        Header: 'Contract reference',
        accessor: (row, index) => row.contractRef,
      },
      {
        Header: 'Contract status',
        accessor: (row, index) => row.status,
        Cell: (props: any) => (
          <div className={styles['contract-status']}>{true && contractStatusButton(props.cell.row.original)}</div>
        ),
      },
      {
        Header: 'Accept / Reject',
        accessor: (row, index) => row.status,
        // eslint-disable-next-line arrow-body-style
        Cell: (props: any) => {
          const contract: Contract = props.cell.row.original;
          const acceptedOrRejected: boolean = contract.status == 'Confirmed' || contract.status == 'Rejected';
          return (
            <div>
              {!acceptedOrRejected || systemAdmin ? (
                <>
                  <Button
                    outline
                    color="secondary"
                    className={styles['accept-reject-button']}
                    onClick={() => acceptContract(contract)}
                  >
                    <FontAwesomeIcon icon={checkCircle} color="#46aa1e" />
                  </Button>
                  <Button
                    outline
                    color="secondary"
                    className={styles['accept-reject-button']}
                    onClick={() => toggleRejectModal(contract)}
                    // onClick={() => rejectContract(contract)}
                  >
                    <FontAwesomeIcon icon={minusCircle} size={'lg'} color="black" />
                  </Button>
                </>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: 'Active',
        accessor: (row) => {
          const todayDate = new Date();
          const endDate = new Date(row?.endDate!);
          if (row.status == null) {
            return 'Active';
          }
          if (row?.endDateOption != null) {
            const endDateOption = new Date(row?.endDateOption!);
            if (endDateOption > todayDate) {
              return 'Active';
            }
          } else {
            if (endDate > todayDate) {
              return 'Active';
            }
          }
          return 'Expired';
        },
      },
      {
        Header: 'In use',
        /* accessor: (row) => {
          if (row.active) return 'Yes';
          else return 'No';
        }, */
        Cell: (props: any) => {
          const contract: Contract = props.cell.row.original;
          const isActive = contract.active;
          return (
            <div className={styles['in-use-div']}>
              {isActive ? (
                <>
                  <FontAwesomeIcon icon={check} size={'lg'} color="#fff" />
                </>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
      /*    {
        Header: 'Delete',
        Cell: (props: any) => {
          const contract = props.cell.row.original;
          return (
            <div className="delete-cell">
              {writePermission && (
                <DeleteById
                  id={contract.contractId ? contract.contractId : ''}
                  deleteMethod={deleteContract}
                  header="Delete Contract"
                  delBtnIcon={deleteIcon}
                />
              )}
            </div>
          );
        },
      }, */
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const contract = props.cell.row.original;
          if (checkSupplier(auth)) {
            return (
              <div className="edit-cell">
                <EditById redirectUrl={`/${navAdminType}admin/contracts/${contract.contractId}/edit`} />
              </div>
            );
          } else {
            return (
              <div className="edit-cell">
                <Button
                  className={styles['btn-edit']}
                  onClick={() => history.push(`/${navAdminType}admin/contracts/${contract.contractId}/edit`)}
                >
                  <FontAwesomeIcon icon={arrowRight} />
                </Button>
              </div>
            );
          }
        },
      },
      /* {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        //Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        //  <span {...getToggleAllRowsExpandedProps()}>
        //    {isAllRowsExpanded ? (
        //      <Button color="primary">
        //        <FontAwesomeIcon icon={arrowDown} />
        //      </Button>
        //    ) : (
        //      <Button color="primary">
        //        <FontAwesomeIcon icon={arrowRight} />
        //      </Button>
        //    )}
        //  </span>
        //),
        Cell: (props: any) => {
          const { row } = props.cell;
          const toggleRowExpanded = props.toggleRowExpanded;
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand = true;

          if (row.canExpand) {
            return (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                  },
                })}
              >
                {row.isExpanded ? (
                  <Button className={styles['btn-open']} onClick={() => setExpandedContractId('')}>
                    <FontAwesomeIcon icon={arrowDown} />
                  </Button>
                ) : (
                  <Button className={styles['btn-open']} onClick={() => setExpandedContractId(row.original.contractId)}>
                    <FontAwesomeIcon icon={arrowRight} />
                  </Button>
                )}
              </span>
            );
          } else return null;
        },
      }, */
    ],
    [contracts],
  );
  const history = useHistory();

  const filterOnCompanyType = () => {
    if (!systemAdmin && company) {
      if (company.category.chorusType.toUpperCase() === 'VENDOR') {
        contracts = contracts.filter((contract) => contract.supplierId === company.chorusId);
      } else {
        contracts = contracts.filter((contract) => contract.customerId === company.chorusId);
      }
    }
  };

  useEffect(() => {
    if (expandedContractId) {
      if (getContractDocuments) getContractDocuments(expandedContractId);
    }
  }, [expandedContractId]);

  useEffect(() => {
    filterOnCompanyType();
    const contractsAreUpToDate = JSON.stringify(currentContracts) === JSON.stringify(contracts);
    if (currentContracts.length < 1) {
      setCurrentContracts(contracts);
      //Initial contracts
    } else {
      if (contractsAreUpToDate) {
        requireUpdate(false);
      } else {
        if (!updateRequired) {
          //Client that needs change. Showing toast / awaiting loading latest
          toast(CustomContractToast, {
            toastId: 'contractId',
            className: styles['toast-parent'],
            position: 'bottom-right',
            autoClose: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            type: 'warning',
            onClose: loadLatestStatuses,
          });
        } else {
          //Client who initiated change. Updating current events!
          setCurrentContracts(contracts);
        }
      }
    }
  }, [contracts]);

  if (isLoading) {
    return <Loading />;
  } else if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <div>
        <TimerUpdate updateCall={getContracts} />

        <StyledTableWrapper>
          <>
            {navAdminType == 'company' && (
              <div>Your {checkShipOwner(auth) ? 'suppliers' : 'customers'} and related contracts.</div>
            )}
            {writePermission && (
              <Button
                style={{ display: 'flex', alignItems: 'center' }}
                color="primary"
                size="lg"
                onClick={() => history.push(`/${navAdminType}admin/contracts/add`)}
              >
                <img src={AddContractsLogo} width="25px" height="20px" style={{ marginRight: '0.1em' }} /> Add contract
              </Button>
            )}
          </>
          {currentContracts && currentContracts.length ? (
            <FuelBossTableContainer
              headers={headersNew}
              tableRows={currentContracts}
              showPagination={true}
              renderRowSubComponent={renderRowSubComponent}
              hiddenColumns={writePermission ? ['Accept / Reject'] : undefined}
              sortBy={[{ id: isSupplier === true ? 'Customer' : 'Supplier', desc: false }]}
              defaultSelectedPage={defaultSelectedPage}
              setDefaultSelectedPage={setDefaultSelectedPage}
            />
          ) : null}
        </StyledTableWrapper>
      </div>
    );
  }

  //  a function that will render our row sub components for contracts
  function renderRowSubComponent(row: any = null): JSX.Element {
    const renderValue = (value: string): JSX.Element => {
      if (value == undefined || value == '') {
        return <div>{'-'}</div>;
      } else return <div>{value}</div>;
    };

    const renderTime = (value: string): JSX.Element => {
      if (value == undefined || value == '') {
        return <div>{'-'}</div>;
      } else {
        const date: Date = new Date(value);
        const year = date.getFullYear();
        let month: string | number = date.getMonth() + 1;
        let day: string | number = date.getDate();
        const hours: string | number = date.getHours();
        const minutes: string | number = date.getMinutes();

        if (day < 10) {
          day = `0${day}`;
        }
        if (month < 10) {
          month = `0${month}`;
        }
        const final = `${day}/${month}/${year}  ${hours}:${minutes}`;
        return <div>{final}</div>;
      }
    };

    if (row == null || row == undefined) return <div></div>;
    else {
      const contractDocs = documents.filter((x) => x.chorusId == row.original.chorusId);
      return (
        <div className={styles['contract-subcomponent']}>
          <div className={styles['contract-subcomponent-left']}>
            <div className={styles['contract-subcomponent-row']}>
              <div className={styles['contract-subcomponent-value']}>
                <div>Start Date</div>
                {renderTime(row.original.startDate)}
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>End Date</div>
                {renderTime(row.original.endDate)}
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>Option End Date</div>
                {renderTime(row.original.endDateOption)}
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>Signed Date </div>
                {renderTime(row.original.signedDate)}
              </div>
            </div>

            <div className={styles['contract-subcomponent-row']}>
              <div className={styles['contract-subcomponent-value']}>
                <div>Price Per Unit</div>
                {renderValue(row.original.pricePerUnit)}
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>{''}</div>
                <div>{''}</div>
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>Unit</div>
                {renderValue(row.original.unit)}
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>Currency</div>
                {renderValue(row.original.currency)}
              </div>
            </div>

            <div className={styles['contract-subcomponent-row']}>
              <div className={styles['contract-subcomponent-value']}>
                <div>Contracted minimum quantities</div>
                {renderValue(row.original.quantityMinimum)}
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>{''}</div>
                <div>{''}</div>
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>Optional quantities</div>
                {renderValue(row.original.quantityOption)}
              </div>
              <div className={styles['contract-subcomponent-value']}>
                <div>Nomination deadline </div>
                {renderValue('')}
              </div>
            </div>

            <div className={styles['contract-subcomponent-row']}>
              <div className={styles['contract-subcomponent-value']}>
                <div>Notes</div>
                {renderValue(row.original.notes)}
              </div>
            </div>

            <div className={styles['contract-subcomponent-documents']}>
              <div>Documents</div>
              <div className={styles['files-list-container']}>
                {contractDocs &&
                  contractDocs
                    .sort((a, b) => (a.fileName < b.fileName ? -1 : 1))
                    .map((doc, index) => {
                      const document = {
                        id: index,
                        name: doc.fileName,
                        url: `/api/Contract/getContractPdf/${row.original.contractId}/${doc.fileName}`,
                        fileData: doc.file,
                      };
                      return (
                        <div className={styles['file-upload']}>
                          <div>
                            <FontAwesomeIcon icon={pdfIcon} size={'lg'} style={{ marginRight: '10px' }} />
                            <a className={styles['file-upload-link']} href={document.url} target="_blank">
                              {document.name}
                            </a>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
          {row.original.status == 'Rejected' ? (
            <div className={styles['contract-subcomponent-mid']}>
              <div className={styles['contract-subcomponent-value']}>Reason for rejecting</div>
              {row.original.rejectReason}
            </div>
          ) : null}

          {writePermission && (
            <div className={styles['contract-subcomponent-right']}>
              <div className={styles['contract-subcomponent-value']}>
                <Button
                  color="primary"
                  className={styles['edit-button']}
                  onClick={() => history.push(`/${navAdminType}admin/contracts/${row.original.contractId}/edit`)}
                >
                  <FontAwesomeIcon icon={edit} />
                </Button>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
};

export default React.memo(Contracts);
