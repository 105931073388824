import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { BIVESSELS_LOADING, BIVESSELS_FETCH_ERROR, GET_BIVESSELS, BIvesselsActions } from './types';
import { BIvesselsResponseModel } from './models';

export const getBIvessels = (searchText: string) => (dispatch: Dispatch<BIvesselsActions>) => {
  dispatch({
    type: BIVESSELS_LOADING,
  });
  new api<BIvesselsResponseModel>({
    dispatch,
    errorType: BIVESSELS_FETCH_ERROR,
  })
    .get(`/api/BIvessels/getBIvessels/${searchText}`)
    .then((response) => {
      dispatch({
        type: GET_BIVESSELS,
        payload: response,
      });
    });
};
