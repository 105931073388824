import { ErrorActionPayload } from '../../models/ErrorAction';
import { UserCompanyFleetWrapperModel } from './models';

export const USERCOMPANYFLEET_LOADING = 'usercompanyfleet/loading';
export type USERCOMPANYFLEET_LOADING = typeof USERCOMPANYFLEET_LOADING;

export const GET_USERCOMPANYFLEET = 'usercompanyfleet/get';
export type GET_USERCOMPANYFLEET = typeof GET_USERCOMPANYFLEET;

export const USERCOMPANYFLEET_FETCH_ERROR = 'usercompanyfleet/fetchError';
export type USERCOMPANYFLEET_FETCH_ERROR = typeof USERCOMPANYFLEET_FETCH_ERROR;

export type UserCompanyFleetActions =
  | {
      type: GET_USERCOMPANYFLEET;
      payload: UserCompanyFleetWrapperModel['data'];
    }
  | {
      type: USERCOMPANYFLEET_LOADING;
    }
  | {
      type: USERCOMPANYFLEET_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
