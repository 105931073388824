import { ReportTypeWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const REPORT_TYPE_CLEAR_TOAST = 'reportType/ClearToast';
export type REPORT_TYPE_CLEAR_TOAST = typeof REPORT_TYPE_CLEAR_TOAST;

export const REPORT_TYPE_LOADING = 'reportType/loading';
export type REPORT_TYPE_LOADING = typeof REPORT_TYPE_LOADING;

export const GET_REPORT_TYPE = 'reportType/get';
export type GET_REPORT_TYPE = typeof GET_REPORT_TYPE;

export const REPORT_TYPE_FETCH_ERROR = 'reportType/fetchError';
export type REPORT_TYPE_FETCH_ERROR = typeof REPORT_TYPE_FETCH_ERROR;

export const ADD_REPORT_TYPE = 'reportType/add';
export type ADD_REPORT_TYPE = typeof ADD_REPORT_TYPE;

export const EDIT_REPORT_TYPE = 'reportType/edit';
export type EDIT_REPORT_TYPE = typeof EDIT_REPORT_TYPE;

export const DELETE_REPORT_TYPE = 'reportType/delete';
export type DELETE_REPORT_TYPE = typeof DELETE_REPORT_TYPE;

export const REPORT_TYPE_ADD_ERROR = 'reportType/addError';
export type REPORT_TYPE_ADD_ERROR = typeof REPORT_TYPE_ADD_ERROR;

export const REPORT_TYPE_EDIT_ERROR = 'reportType/editError';
export type REPORT_TYPE_EDIT_ERROR = typeof REPORT_TYPE_EDIT_ERROR;

export const REPORT_TYPE_DELETE_ERROR = 'reportType/deleteError';
export type REPORT_TYPE_DELETE_ERROR = typeof REPORT_TYPE_DELETE_ERROR;

export type ReportActions =
  | {
      type: REPORT_TYPE_LOADING;
    }
  | {
      type: GET_REPORT_TYPE;
      payload: ReportTypeWrapperModel['data'];
    }
  | {
      type: ADD_REPORT_TYPE;
      payload: ReportTypeWrapperModel['data'];
    }
  | {
      type: EDIT_REPORT_TYPE;
      payload: ReportTypeWrapperModel['data'];
    }
  | {
      type: DELETE_REPORT_TYPE;
      payload: ReportTypeWrapperModel['data'];
    }
  | {
      type: REPORT_TYPE_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: REPORT_TYPE_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: REPORT_TYPE_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: REPORT_TYPE_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: REPORT_TYPE_CLEAR_TOAST;
    };
