import React from 'react';
import { ReactComponent as ErrorIcon } from '../svgs/error-generic-graphic.svg';
import styles from '../../Error.module.scss';

interface OwnProps {
  errorTitle?: string;
  errorMessage: string;
  displayContact?: boolean;
  errorStatusCode?: string;
  errorStatusCodeFontSize?: string;
}

export const CustomError: React.FC<OwnProps> = React.memo(({ errorTitle, children, errorMessage, displayContact, errorStatusCode, errorStatusCodeFontSize='32px' }) => (
  <div className={styles.errorComponent}>
    <div className={styles['icon-wrapper']}>
      <ErrorIcon className={styles.image} />
      <div className={styles['status-code']} style={{fontSize:errorStatusCodeFontSize}}><span>{errorStatusCode ? errorStatusCode : 'Error'}</span></div>
    </div>
    {errorTitle && <h5 className={styles.header}>{errorTitle}</h5>}
    <p className={styles['error-description']}>{errorMessage}</p>
    {children}
    {displayContact && (
      <p className={styles['error-description']}>
        <a className={`btn btn-primary ${styles['continue-button']}`} href="mailto:fuelboss@dnv.com">
          Contact us
        </a>
      </p>
    )}
  </div>
));
