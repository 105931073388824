import * as TYPE from './types';

import { ApplicationState } from '..';
import { FieldPermissionsRecurring } from './models';
import _ from 'lodash';
import { camelCase } from 'lodash';

export const fieldPermissionRecurringReducer = (
  state: ApplicationState['fieldPermissionRecurringForm'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: {} as FieldPermissionsRecurring,
    metadata: {},
  },
  action: TYPE.FieldPermissionActions,
): ApplicationState['fieldPermissionRecurringForm'] => {
  switch (action.type) {
    case TYPE.FIELDPERMISSIONRECURRING_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.FIELDPERMISSIONRECURRING_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_FIELDPERMISSIONRECURRING:
      const camelizeKeys: any = (obj: any) => {
        if (Array.isArray(obj)) {
          return obj.map((v) => camelizeKeys(v));
        } else if (obj != null && obj.constructor === Object) {
          return Object.keys(obj).reduce(
            (result, key) => ({
              ...result,
              [camelCase(key)]: camelizeKeys(obj[key]),
            }),
            {},
          );
        }
        return obj;
      };
      const apiResponseCamelCase: any = camelizeKeys(action.payload); //_.mapKeys(action.payload, (v, k) => _.camelCase(k));
      return {
        ...state,
        data: apiResponseCamelCase,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
