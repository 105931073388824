import {
  DeliveryMode,
  EnergyUnit,
  getAllowedThirdPartyContactRoles,
  getNominationNegotiationFieldPermissions,
  ICompany,
  IFleetShip,
  //  IPromptNomination,
  isCustomer,
  IThirdPartyContact,
  IThirdPartyOptions,
  IUserProfile,
  LocationService,
  PriceUnit,
  QuantityUnit,
  useLocationTimeZone,
  UserRole,
  isScheduler,
  isAdmin,
  IPromptNomination,
} from '@teqplay/chorus-components';
import {
  Button,
  Checkbox,
  DateTimePicker,
  formatDateTime,
  FormElement,
  FormErrorMessage,
  FormFieldWrapper,
  FormLabel,
  FormWrapper,
  MultiSelectionBox,
  MultiSelectOption,
  NumberInput,
  SelectionBox,
  TextArea,
  TextField,
} from '@teqplay/teqplay-ui';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  AlternativeFuelSelector,
  //  comparingNominationFields,
  DeliveryModeSelection,
  ENERGY_UNITS,
  LocationSearch,
  PRICE_UNITS,
  QUANTITY_UNITS,
  ValidationWarning,
  AddThirdPartyContactDialog,
} from '@teqplay/chorus-components';
import SummaryRow from '../NominationSummary/SummaryRow';
import { comparingRecurringNominationFields } from '../Utils/compareRecurringNominations';
import {
  RecurringNomination,
  ScheduleRecurrenceType,
  SCHEDULERECURRANCE_TYPE,
  TimeBeforeNominationCreationType,
  WeeklyScheduleModel,
  DailyScheduleModel,
} from '../../../../../store/RecurringNomination/models';
import { validateNominationBST } from '../Validators/recurringNominationValidation';
import { canUserEditContract } from '../Utils/nominationPermissions';
import { FieldPermissionsRecurring } from '../../../../../store/FieldPermissionsRecurringForm/models';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { getFieldPermissionRecurring } from '../../../../../store/FieldPermissionsRecurringForm/actions';
import { Loading } from '../../../../../components/Loading';
import { checkReceivingCaptainScheduler } from '../../../../../helpers/roleChecks';

interface CustomerNominationFormProps {
  currentNomination: RecurringNomination;
  previousNomination?: RecurringNomination;
  showDeliveryModeSelection: boolean;
  errors?: any;
  currentUser: IUserProfile;
  creatingNewNomination: boolean;
  vendorCompanies: ICompany[];
  customerFleet: IFleetShip[];
  locationService: LocationService;
  selectedVendor?: ICompany;
  enableAlternativeFuelSelector: boolean;
  //editingOnBehalf: boolean
  customerCompanyName: string;
  thirdPartyContacts: IThirdPartyContact[];
  nominationContacts: IThirdPartyContact[];
  getPreviousValueLabel: (
    field: keyof RecurringNomination,
    nominationValues?: RecurringNomination,
    previousNominationValues?: RecurringNomination,
  ) => any;
  onChangeFormValue: (field: string, value: string | boolean | number | object | undefined | null) => void;
  onChangeDateTimeValue: (isoDate: string | null, inputValue: string, isValid: boolean, fieldName?: string) => void;
  saveThirdPartyContact: (data: IThirdPartyContact) => void;
  setMultipleValues: (fieldsToUpdate: { field: string; value: string | null | any[] }[]) => void;
  nominationEventToCompareWith?: RecurringNomination;
  disabled?: boolean;
}

const CustomerRecurringNominationForm: React.FunctionComponent<CustomerNominationFormProps> = (props) => {
  const {
    getPreviousValueLabel,
    currentNomination,
    onChangeDateTimeValue,
    onChangeFormValue,
    setMultipleValues,
    currentUser,
    previousNomination,
    errors,
    selectedVendor,
    enableAlternativeFuelSelector,
    // editingOnBehalf,
    nominationEventToCompareWith,
    disabled,
  } = props;

  const appliedUserRoles = currentUser.roles;

  const timezone = useLocationTimeZone(currentNomination.locationId, props.locationService);
  const {
    fieldPermissionRecurringLoading,
    fieldPermissionRecurringDetailsError,
    fieldPermissionRecurringErrorDetails,
    fieldPermissionRecurring,
    fieldPermissionRecurringIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    fieldPermissionRecurringLoading: state.fieldPermissionRecurringForm.loading,
    fieldPermissionRecurringDetailsError: state.fieldPermissionRecurringForm.error,
    fieldPermissionRecurringErrorDetails: state.fieldPermissionRecurringForm.errorDetails,
    fieldPermissionRecurring: state.fieldPermissionRecurringForm.data,
    fieldPermissionRecurringIsLoaded: state.fieldPermissionRecurringForm.dataIsLoaded,
  }));

  const auth = useSelector((state: ApplicationState) => state.auth.data);

  const dispatch = useDispatch();
  const getFieldPermissionRecurringCallback = React.useCallback(
    () => dispatch(getFieldPermissionRecurring('RecurringNomination')),
    [],
  );

  const fieldPermissions = fieldPermissionRecurring;

  const previousReceivingVessel = props.customerFleet.find(
    (fleetShip) => fleetShip._id === getPreviousValueLabel('receivingShipId', currentNomination, previousNomination),
  );

  const supplierOffersAltFuels = !isEmpty(selectedVendor ? selectedVendor.altFuelTypes : []);

  const [isActiveAddContactFormDialog, setIsActiveAddContactFormDialog] = useState<boolean>(false);
  const [isRoleRVCS, setIsRoleRVCS] = useState<boolean>(false);

  const allowedThirdPartyRoleOptions = getAllowedThirdPartyContactRoles(currentUser.roles);

  const [thirdPartyOptions, setThirdPartyOptions] = useState<IThirdPartyOptions[]>([]);
  const [multiSelectItems, setMultiSelectitems] = useState<IThirdPartyOptions[]>();

  const [scheduleRecurrance, setScheduleRecurrance] = useState<ScheduleRecurrenceType | undefined>(undefined);
  const [isHoursActive, setIsHoursActive] = useState<boolean>(true);

  const [isEtaChanged, setIsEtaChanged] = useState<boolean>(false);
  const [isEtdChanged, setIsEtdChanged] = useState<boolean>(false);
  const [isBstChanged, setIsBstChanged] = useState<boolean>(false);
  const [isStartDateChanged, setIsStartDateChanged] = useState<boolean>(false);
  const [isEndDateChanged, setIsEndDateChanged] = useState<boolean>(false);
  const [isWeeklyDaysChanged, setIsWeeklyDaysChanged] = useState<boolean>(false);

  useEffect(() => {
    !!multiSelectItems &&
      props.onChangeFormValue(
        'customerThirdPartyContactIds',
        Object.values(multiSelectItems)
          .map((e) => e.value)
          .join(','),
      );
  }, [multiSelectItems]);

  useEffect(() => {
    const isRoleRSVC = checkReceivingCaptainScheduler(auth);
    setIsRoleRVCS(isRoleRSVC);
  }, [auth]);

  useEffect(() => {
    setScheduleRecurrance(currentNomination.scheduleRecurrenceType);
    if (!fieldPermissionRecurringIsLoaded) {
      getFieldPermissionRecurringCallback();
    }
    setIsHoursActive(currentNomination.tbncType == TimeBeforeNominationCreationType.Hours ? true : false);
  }, []);

  useEffect(() => {
    setScheduleRecurrance(currentNomination.scheduleRecurrenceType);
  }, [currentNomination.scheduleRecurrenceType]);

  const [newContactFormValues, setNewContactFormValues] = useState<IThirdPartyContact>({
    _id: '',
    companyId: currentUser.companyId,
    companyName: '',
    email: '',
    name: '',
    role: '',
  });

  useEffect(() => {
    setNewContactFormValues({
      _id: '',
      companyId: currentUser.companyId,
      companyName: '',
      email: '',
      name: '',
      role: '',
    });
  }, [isActiveAddContactFormDialog]);

  useEffect(() => {
    setThirdPartyOptions(
      props.thirdPartyContacts.map((contact) => ({
        text: `${contact.name}, ${contact.companyName} <${contact.email}>`,
        value: contact._id,
      })),
    );
  }, [props.thirdPartyContacts]);

  useEffect(() => {
    if (props.nominationContacts.length > 0 && !props.creatingNewNomination) {
      setMultiSelectitems(
        props.nominationContacts.map((contact) => ({
          text: `${contact.name}, ${contact.companyName} <${contact.email}>`,
          value: contact._id,
        })),
      );
    }
  }, [props.nominationContacts]);

  const fieldsThatAreDifferent = comparingRecurringNominationFields(currentNomination, nominationEventToCompareWith);

  if (!fieldPermissionRecurringIsLoaded) {
    return <Loading type="Permissions" />;
  }
  return (
    <div className={'prompt-form nomination-form customer-form'}>
      {/* {false && (
        <div className="onbehalf-warning">
          This function permits you to make changes to the Buyer's ({props.customerCompanyName})
          nomination request. <span className="bold-underline">It does not</span> create an agency
          relationship between you and your customer, and{' '}
          <span className="bold-underline">does not</span> bind your customer to these changes
          unless separately agreed by your customer.
        </div>
      )} */}

      {/* Renders the onbehalf selection if passed through the children */}
      {props.children}

      <section className="form-section">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">1</div>
            <h2 className="">General information</h2>
          </div>
        </div>

        {/* {editingOnBehalf && (
          <section className="summary-section">
            <div className="section-content">
              <div className="single-row">
                <div className="rows">
                  <SummaryRow
                    label={'Buyer'}
                    hideNewIndicator
                    currentValue={`${props.customerCompanyName || ''}`}
                    emptyValueMessage={'-'}
                    isInitialValue={false}
                  />
                </div>
              </div>
            </div>
          </section>
        )} */}

        <FormWrapper>
          {props.creatingNewNomination && (
            <>
              <FormElement>
                <FormLabel fieldName="receivingShipId">Receiving vessel *</FormLabel>
                <SelectionBox<IFleetShip>
                  fieldName={'receivingShipId'}
                  placeholder={'Select Vessel...'}
                  value={
                    !fieldPermissions.receivingShipId && !currentNomination.receivingShipId
                      ? '-'
                      : currentNomination.receivingShipId || ''
                  }
                  valueKey="_id"
                  displayKey="name"
                  sortOptions
                  disabled={disabled || !fieldPermissions.receivingShipId.write}
                  onChangeValue={(option) => {
                    onChangeFormValue('receivingShipId', option?._id || null);
                  }}
                  options={isRoleRVCS ? props.customerFleet.filter(x => x._id == auth.chorusUserProfile.receivingShipId) : props.customerFleet}
                  renderOption={(option) => {
                    return (
                      <div className="shipsearch-select-option">
                        <b>{option.name}</b>
                        {option.charterers &&
                          option.charterers.length > 0 &&
                          currentUser.companyId !== option.companyId && (
                            <span className="select-chartered-tag"> Chartered</span>
                          )}
                      </div>
                    );
                  }}
                ></SelectionBox>

                <div className="form-field-message">
                  <FormLabel fieldName="receivingShipId">
                    {previousReceivingVessel ? previousReceivingVessel.name : undefined}
                  </FormLabel>
                  <FormErrorMessage
                    errorMessage={errors ? get(errors, 'receivingShipId') : undefined}
                  ></FormErrorMessage>
                </div>
              </FormElement>

              {
                <FormElement>
                  <FormLabel fieldName="vendorCompanyId">Supplier *</FormLabel>
                  <SelectionBox<{ value: string; text: string }>
                    fieldName={'vendorCompanyId'}
                    placeholder={'Select Supplier...'}
                    value={
                      !fieldPermissions.vendorCompanyId && !currentNomination.vendorCompanyId
                        ? '-'
                        : currentNomination.vendorCompanyId
                        ? currentNomination.vendorCompanyId
                        : ''
                    }
                    options={props.vendorCompanies.map((company) => ({
                      value: company._id,
                      text: company.name,
                    }))}
                    disabled={disabled || !fieldPermissions.vendorCompanyId.write}
                    valueKey="value"
                    displayKey="text"
                    renderFieldValue={(selectedItem) => {
                      return `${selectedItem.text}`;
                    }}
                    onChangeValue={(selectedItem) => {
                      setMultipleValues([
                        { field: 'vendorCompanyId', value: selectedItem?.value || null },
                        { field: 'deliveryMode', value: '' },
                      ]);
                    }}
                    renderOption={(option) => {
                      return (
                        <div>
                          <span>{option.text}</span>
                        </div>
                      );
                    }}
                  />
                  <div className="form-field-message">
                    <FormLabel fieldName="vendorCompanyId">
                      {getPreviousValueLabel('vendorCompanyId', currentNomination, previousNomination)}
                    </FormLabel>
                    <FormErrorMessage errorMessage={errors?.vendorCompanyId}></FormErrorMessage>
                  </div>
                </FormElement>
              }
            </>
          )}

          <LocationSearch
            locationService={props.locationService}
            onChange={(value) => {
              props.onChangeFormValue('locationId', value);
            }}
            label="Delivery point *"
            placeholder={'Select port...'}
            disabled={disabled || !fieldPermissions.location.write}
            fieldName="locationId"
            error={errors ? get(errors, 'locationId') : undefined}
            value={
              !fieldPermissions.location && !currentNomination.locationId ? '-' : currentNomination.locationId || ''
            }
            prevValue={previousNomination ? previousNomination.locationId : undefined}
            comparingNominationIsDifferent={fieldsThatAreDifferent.includes('locationId')}
          />

          <FormElement className={fieldsThatAreDifferent.includes('location') ? 'delegated-is-different' : ''}>
            <FormLabel fieldName="location">Location</FormLabel>
            <TextField
              fieldName="location"
              disabled={disabled || !fieldPermissions.location.write}
              placeholder={'Detailed description, e.g. berth, terminal, anchorage etc.'}
              value={
                !fieldPermissions.location && !currentNomination.location
                  ? '-'
                  : currentNomination.location
                  ? currentNomination.location
                  : ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                props.onChangeFormValue('location', event.target.value);
              }}
            ></TextField>
            <div className="form-field-message">
              <FormLabel fieldName="prevlocation" strikeThrough>
                {getPreviousValueLabel('location', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'location') : undefined} />
            </div>
          </FormElement>

          <FormElement>
            <FormLabel fieldName="co2TaxEnabled">
              Excise fee/
              <br />
              CO2 fee applicable
            </FormLabel>
            <Checkbox
              field={'co2TaxEnabled'}
              value={currentNomination.co2TaxEnabled || false}
              className="co2TaxEnabled"
              onChange={() => onChangeFormValue('co2TaxEnabled', !currentNomination.co2TaxEnabled)}
            />
          </FormElement>

          {currentNomination.co2TaxEnabled && (
            <FormElement>
              <FormLabel fieldName="nextDestination">Next destination</FormLabel>
              <TextField
                fieldName="nextDestination"
                disabled={disabled || !fieldPermissions.agent.write}
                value={
                  !fieldPermissions.agent && !currentNomination.nextDestination
                    ? '-'
                    : currentNomination.nextDestination
                    ? currentNomination.nextDestination
                    : ''
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.onChangeFormValue('nextDestination', event.target.value);
                }}
              ></TextField>
              <div className="form-field-message">
                <FormErrorMessage errorMessage={errors ? get(errors, 'nextDestination') : undefined} />
              </div>
            </FormElement>
          )}

          <FormElement>
            <FormLabel fieldName="agent">Agent</FormLabel>
            <TextField
              fieldName="agent"
              disabled={disabled || !fieldPermissions.agent.write}
              value={
                !fieldPermissions.agent && !currentNomination.agent
                  ? '-'
                  : currentNomination.agent
                  ? currentNomination.agent
                  : ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeFormValue('agent', event.target.value);
              }}
            ></TextField>
            <div className="form-field-message">
              <FormLabel fieldName="prevAgent" strikeThrough>
                {getPreviousValueLabel('agent', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'agent') : undefined} />
            </div>
          </FormElement>

          <FormElement>
            <FormLabel fieldName="buyerRef">Buyer reference</FormLabel>
            <TextField
              fieldName="buyerRef"
              disabled={disabled || !fieldPermissions.buyerRef.write}
              placeholder={'E.g. PO no. or similar'}
              value={
                !fieldPermissions.buyerRef && !currentNomination.buyerRef
                  ? '-'
                  : currentNomination.buyerRef
                  ? currentNomination.buyerRef
                  : ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeFormValue('buyerRef', event.target.value);
              }}
            ></TextField>
            <div className="form-field-message">
              <FormLabel fieldName="prevBuyer" strikeThrough>
                {getPreviousValueLabel('buyerRef', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'buyerRef') : undefined} />
            </div>
          </FormElement>
        </FormWrapper>
      </section>

      <section className="form-section">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">2</div>
            <h2 className="">Quantity and delivery</h2>
          </div>
        </div>

        <FormWrapper>
          <FormElement className={fieldsThatAreDifferent.includes('amount') ? 'delegated-is-different' : ''}>
            <FormLabel fieldName="amount">Total quantity *</FormLabel>
            <div className="flex amount-and-quantityUnit-container">
              <FormFieldWrapper>
                <NumberInput
                  name={'amount'}
                  maxDecimals={3}
                  value={
                    !fieldPermissions.amount && !currentNomination.amount
                      ? '-'
                      : currentNomination.amount
                      ? currentNomination.amount
                      : ''
                  }
                  disabled={disabled || !fieldPermissions.amount.write}
                  onChangeFormValue={onChangeFormValue}
                />
              </FormFieldWrapper>
              <SelectionBox<{ value: QuantityUnit; text: string }>
                fieldName={'quantityUnit'}
                placeholder={'Select quantity'}
                value={currentNomination?.quantityUnit}
                renderFieldValue={(selectedItem) => {
                  return `${selectedItem.text}`;
                }}
                valueKey="value"
                displayKey="text"
                disabled={disabled || !fieldPermissions.quantityUnit.write}
                onChangeValue={(unit) => {
                  onChangeFormValue('quantityUnit', unit?.value);
                }}
                options={QUANTITY_UNITS.sort((a, b) => (a.text > b.text ? -1 : 1))}
                renderOption={(option) => {
                  return (
                    <div className="shipsearch-select-option">
                      <b>{option.text}</b>
                    </div>
                  );
                }}
              ></SelectionBox>
            </div>
            <div className="form-field-message">
              <FormLabel fieldName="prevAmount" strikeThrough>
                {getPreviousValueLabel('amount', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'amount') : undefined} />
            </div>
          </FormElement>

          <FormElement className={fieldsThatAreDifferent.includes('tolerance') ? 'delegated-is-different' : ''}>
            <FormLabel fieldName="tolerance">
              Tolerance <div className="label-unit">(%)</div>
            </FormLabel>
            <NumberInput
              name="tolerance"
              maxDecimals={3}
              disabled={disabled || !fieldPermissions.tolerance.write}
              value={
                !fieldPermissions.tolerance && !currentNomination?.tolerance
                  ? '-'
                  : currentNomination?.tolerance
                  ? currentNomination.tolerance
                  : ''
              }
              onChangeFormValue={props.onChangeFormValue}
            ></NumberInput>
            <div className="form-field-message">
              <FormLabel fieldName="prevTolerance" strikeThrough>
                {getPreviousValueLabel('tolerance', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'tolerance') : undefined} />
            </div>
          </FormElement>

          <FormElement>
            <FormLabel fieldName="altFuelTolerance">Alternative fuel tolerance</FormLabel>
            <TextField
              fieldName="altFuelTolerance"
              disabled={!fieldPermissions.altFuelTolerance.write}
              value={
                !fieldPermissions.altFuelTolerance && !currentNomination?.altFuelTolerance
                  ? '-'
                  : currentNomination?.altFuelTolerance
                  ? currentNomination.altFuelTolerance
                  : ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeFormValue('altFuelTolerance', event.target.value);
              }}
            ></TextField>
            <div className="form-field-message">
              <FormLabel fieldName="prevaltFuelTolerance" strikeThrough>
                {getPreviousValueLabel('altFuelTolerance', currentNomination, previousNomination)}
              </FormLabel>
              <FormErrorMessage errorMessage={errors ? get(errors, 'altFuelTolerance') : undefined} />
            </div>
          </FormElement>

          {props.showDeliveryModeSelection && (
            <FormElement>
              <FormLabel fieldName="deliveryMode">Delivery by *</FormLabel>
              <DeliveryModeSelection
                fieldName="deliveryMode"
                availableDeliveryModes={selectedVendor?.deliveryModes}
                selectedDeliveryModes={currentNomination.deliveryMode as DeliveryMode}
                readOnly={disabled || !fieldPermissions.deliveryMode}
                changeDeliveryMode={(deliveryMode) => {
                  if (props.onChangeFormValue) {
                    props.onChangeFormValue('deliveryMode', deliveryMode);
                  }
                }}
              />
              <div className="form-field-message">
                <FormLabel fieldName="prevDeliveryMode" strikeThrough>
                  {getPreviousValueLabel('deliveryMode', currentNomination, previousNomination)}
                </FormLabel>
                <FormErrorMessage errorMessage={errors ? get(errors, 'deliveryMode') : undefined} />
              </div>
            </FormElement>
          )}

          {supplierOffersAltFuels && enableAlternativeFuelSelector && (
            <AlternativeFuelSelector
              totalAmount={currentNomination.amount}
              quantityUnit={currentNomination?.quantityUnit}
              onChangeFormValue={props.onChangeFormValue}
              percentage={currentNomination.altFuelPercentage}
              readOnly={disabled || !fieldPermissions.altFuelPercentage}
              type={'SLIDER'}
              previousPercentage={
                getPreviousValueLabel('altFuelPercentage', currentNomination, previousNomination) as number | undefined
              }
            />
          )}
        </FormWrapper>
      </section>

      <section className="form-section form-section__third-party-communication">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">3</div>
            <h2>Communication</h2>
          </div>
        </div>

        <h5 className="third_party-title">
          Third party contacts added here will receive notifications about updates to this nomination
        </h5>
        <FormWrapper>
          <>
            <FormElement>
              <FormLabel fieldName="customerThirdPartyContactIds">Third party contacts</FormLabel>
              <MultiSelectionBox
                disabled={disabled}
                fieldName="customerThirdPartyContactIds"
                values={multiSelectItems}
                placeholder={'Ports, agents, etc.'}
                valueKey="value"
                searchKey="text"
                searchKeys={['text', 'value']}
                sortOptions
                showClearButton={!disabled}
                renderFieldValue={(selectedItem, removeItem) => {
                  return (
                    <MultiSelectOption
                      optionText={selectedItem.text}
                      removeItem={() => {
                        removeItem(selectedItem);
                      }}
                    ></MultiSelectOption>
                  );
                }}
                onChangeValue={(selectedItems) => {
                  setMultiSelectitems(selectedItems);
                }}
                options={thirdPartyOptions}
                renderOption={(option) => {
                  return (
                    <div>
                      <div>{option.text}</div>
                    </div>
                  );
                }}
              />
            </FormElement>
            <FormElement className="justify-center">
              <Button
                disabled={disabled}
                preventDoubleClick
                primary
                onClick={(e) => {
                  setIsActiveAddContactFormDialog(true);
                }}
              >
                Add a <strong>new</strong> contact to the list
              </Button>
            </FormElement>
          </>
        </FormWrapper>
        {isActiveAddContactFormDialog && (
          <AddThirdPartyContactDialog
            onCloseDialog={() => setIsActiveAddContactFormDialog(false)}
            showDialog={isActiveAddContactFormDialog}
            title="Add contact person"
            formValues={newContactFormValues}
            allowedThirdPartyRoleOptions={allowedThirdPartyRoleOptions}
            onInputChange={setNewContactFormValues}
            saveThirdPartyContact={props.saveThirdPartyContact}
            callback={setIsActiveAddContactFormDialog}
          />
        )}
      </section>

      <section className="form-section">
        <div className="form-header">
          <div className="step-header">
            <div className="step-indicator">4</div>
            <h2 className="">Schedule</h2>
          </div>
        </div>

        <h5 className="timezone-title">Timezone: {timezone.text}</h5>

        {!props.creatingNewNomination && currentNomination.deliveryMode === 'SHIP' && (
          <section className="summary-section">
            <div className="section-content">
              <div className="single-row">
                <h5 className="summary-section-sub-title">Bunker vessel</h5>
                <div className="rows">
                  {/* <SummaryRow
                    className="rv-row"
                    label={'ETA'}
                    formatAsDate
                    formatTimezone={timezone.value}
                    currentValue={currentNomination?.bunkershipEta}
                    hideNewIndicator
                    emptyValueMessage={'entered by supplier'}
                    isInitialValue={false}
                  /> */}
                  <SummaryRow
                    className="rv-row"
                    label={'Allowed Bunkering Time (hours)'}
                    currentValue={currentNomination?.allowedBunkeringTime}
                    hideNewIndicator
                    emptyValueMessage={'entered by supplier'}
                    isInitialValue={false}
                  />
                  {/* <SummaryRow
                    formatAsDate
                    formatTimezone={timezone.value}
                    className="rv-row"
                    label={'ETD'}
                    currentValue={currentNomination?.bunkershipEtd}
                    hideNewIndicator
                    emptyValueMessage={'entered by supplier'}
                    isInitialValue={false}
                  /> */}
                </div>
              </div>
            </div>
          </section>
        )}

        <FormWrapper>
          <FormElement>
            <FormLabel fieldName="scheduleRecurrenceType">Bunkering occurs *</FormLabel>
            <SelectionBox<{ value: ScheduleRecurrenceType; text: string }>
              fieldName={'scheduleRecurrenceType'}
              placeholder={'Select schedule...'}
              //@ts-ignore
              value={currentNomination ? currentNomination.scheduleRecurrenceType : ''}
              renderFieldValue={(selectedItem) => {
                return `${selectedItem.text}`;
              }}
              valueKey="value"
              displayKey="text"
              sortOptions
              disabled={disabled || !fieldPermissions.scheduleRecurrenceType.write}
              onChangeValue={(unit) => {
                //onChangeFormValue('scheduleRecurrenceType', unit?.value);
                //setScheduleRecurrance(unit?.value);
                if (unit) {
                  setMultipleValues([
                    //@ts-ignore
                    { field: 'scheduleRecurrenceType', value: unit?.value },
                    {
                      field: 'dailySchedule',
                      //@ts-ignore
                      value:
                        unit.value == ScheduleRecurrenceType.Daily
                          ? ({
                              occursEveryNDays: 15,
                              id: '00000000-0000-0000-0000-000000000000',
                              recurringNominationTemplateId: '00000000-0000-0000-0000-000000000000',
                              timeStamp: new Date(),
                            } as DailyScheduleModel)
                          : null,
                    },
                    {
                      field: 'weeklySchedule',
                      //@ts-ignore
                      value:
                        unit.value == ScheduleRecurrenceType.Weekly
                          ? ({
                              monday: false,
                              tuesday: false,
                              wednesday: false,
                              thursday: false,
                              friday: false,
                              saturday: false,
                              sunday: false,
                              id: '00000000-0000-0000-0000-000000000000',
                              recurringNominationTemplateId: '00000000-0000-0000-0000-000000000000',
                              timeStamp: new Date(),
                            } as WeeklyScheduleModel)
                          : null,
                    },
                  ]);
                }
              }}
              options={SCHEDULERECURRANCE_TYPE}
              renderOption={(option) => {
                return (
                  <div className="shipsearch-select-option">
                    <b>{option.text}</b>
                  </div>
                );
              }}
            ></SelectionBox>
            <div className="form-field-message">
              <FormLabel fieldName={`prevScheduleRecurrenceType`} strikeThrough>
                {formatDateTime(
                  getPreviousValueLabel('scheduleRecurrenceType', currentNomination, previousNomination) as string,
                  timezone.value,
                )}
              </FormLabel>

              <FormErrorMessage errorMessage={errors ? get(errors, 'scheduleRecurrenceType') : undefined} />
            </div>
          </FormElement>

          {scheduleRecurrance == ScheduleRecurrenceType.Daily && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <FormElement>
                  <div className="form-field-wrapper recurring-section-header">
                    Occurs every {'  '}
                    <NumberInput
                      name="dailySchedule.occursEveryNDays"
                      disabled={disabled || !fieldPermissions.dailySchedule.write}
                      placeholder={'hours or days'}
                      value={
                        !fieldPermissions.dailySchedule && !currentNomination.dailySchedule
                          ? '-'
                          : currentNomination.dailySchedule && currentNomination.dailySchedule.occursEveryNDays
                          ? currentNomination.dailySchedule.occursEveryNDays
                          : ''
                      }
                      onChangeFormValue={(
                        field: string,
                        value: string | number | boolean | object | null | undefined,
                      ) => {
                        onChangeFormValue('dailySchedule.occursEveryNDays', Number(value));
                      }}
                    ></NumberInput>
                    day
                  </div>
                  <div className="form-field-message">
                    <FormErrorMessage
                      errorMessage={errors ? get(errors, 'dailySchedule.occursEveryNDays') : undefined}
                    />
                  </div>
                </FormElement>
              </div>
            </div>
          )}

          {scheduleRecurrance != undefined && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <div className="times-field-wrapper">
                  <FormElement>
                    <FormLabel fieldName={'eta'}>ETA *</FormLabel>
                    <FormFieldWrapper>
                      <DateTimePicker
                        key={`${currentNomination?.id} eta`}
                        name={'eta'}
                        onDateTimeChange={(
                          isoDate: string | null,
                          inputValue: string,
                          isValid: boolean,
                          fieldName?: string | undefined,
                        ) => {
                          setIsEtaChanged(true);
                          onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
                        }}
                        disabled={disabled || !fieldPermissions.eta.write}
                        icon={<i className="icon-calendar-1" />}
                        placeholder="dd/mm/yyyy hh:mm"
                        //@ts-ignore
                        value={!fieldPermissions.eta && !currentNomination.eta ? '-' : currentNomination.eta || ''}
                        timeZone={timezone.value}
                        hideDialogHeader
                        useInitialStart={'hour'}
                      />
                    </FormFieldWrapper>

                    <div className="form-field-message">
                      <FormLabel fieldName={`prevEta`} strikeThrough>
                        {formatDateTime(
                          getPreviousValueLabel('eta', currentNomination, previousNomination) as string,
                          timezone.value,
                        )}
                      </FormLabel>

                      <FormErrorMessage errorMessage={errors ? get(errors, 'eta') : undefined} />
                    </div>
                    <div className="form-field-message">
                      <ValidationWarning
                        warning={
                          !props.creatingNewNomination && isEtaChanged
                            ? 'Warning: Please be aware that any changes made to the recurring nomination series will replace all single events previously created.'
                            : undefined
                        }
                        className="form-field-error-message warning"
                      />
                    </div>
                  </FormElement>
                </div>

                <div className="times-field-wrapper">
                  <FormElement>
                    <FormLabel fieldName={'bst'}>Bunkering Start Time (BST) *</FormLabel>
                    <FormFieldWrapper>
                      <DateTimePicker
                        key={`${currentNomination?.id} bst`}
                        name={'bst'}
                        onDateTimeChange={(
                          isoDate: string | null,
                          inputValue: string,
                          isValid: boolean,
                          fieldName?: string | undefined,
                        ) => {
                          setIsBstChanged(true);
                          onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
                        }}
                        disabled={disabled || !fieldPermissions.bst.write}
                        icon={<i className="icon-calendar-1" />}
                        placeholder="dd/mm/yyyy hh:mm"
                        //@ts-ignore
                        value={!fieldPermissions.bst && !currentNomination.bst ? '-' : currentNomination.bst || ''}
                        timeZone={timezone.value}
                        hideDialogHeader
                        useInitialStart={'hour'}
                      />
                    </FormFieldWrapper>
                    <div className="form-field-message">
                      <FormLabel fieldName={`prevBst`} strikeThrough>
                        {formatDateTime(
                          getPreviousValueLabel('bst', currentNomination, previousNomination) as string,
                          timezone.value,
                        )}
                      </FormLabel>

                      <FormErrorMessage errorMessage={errors ? get(errors, 'bst') : undefined} />
                      <ValidationWarning
                        warning={validateNominationBST(currentNomination, props.currentUser.roles)}
                        className="form-field-error-message warning"
                      />
                    </div>
                    <div className="form-field-message">
                      <ValidationWarning
                        warning={
                          !props.creatingNewNomination && isBstChanged
                            ? 'Warning: Please be aware that any changes made to the recurring nomination series will replace all single events previously created.'
                            : undefined
                        }
                        className="form-field-error-message warning"
                      />
                    </div>
                  </FormElement>
                </div>

                <div className="times-field-wrapper">
                  <FormElement>
                    <FormLabel fieldName={'etd'}>ETD *</FormLabel>
                    <FormFieldWrapper>
                      <DateTimePicker
                        key={`${currentNomination?.id} etd`}
                        name={'etd'}
                        onDateTimeChange={(
                          isoDate: string | null,
                          inputValue: string,
                          isValid: boolean,
                          fieldName?: string | undefined,
                        ) => {
                          setIsEtdChanged(true);
                          onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
                        }}
                        disabled={disabled || !fieldPermissions.etd.write}
                        icon={<i className="icon-calendar-1" />}
                        placeholder="dd/mm/yyyy hh:mm"
                        //@ts-ignore
                        value={!fieldPermissions.etd && !currentNomination.etd ? '-' : currentNomination.etd || ''}
                        timeZone={timezone.value}
                        hideDialogHeader
                        useInitialStart={'hour'}
                      />
                    </FormFieldWrapper>
                    <div className="form-field-message">
                      <FormLabel fieldName={`prevEtd`} strikeThrough>
                        {formatDateTime(
                          getPreviousValueLabel('etd', currentNomination, previousNomination) as string,
                          timezone.value,
                        )}
                      </FormLabel>
                      <FormErrorMessage errorMessage={errors ? get(errors, 'etd') : undefined} />
                    </div>
                    <div className="form-field-message">
                      <ValidationWarning
                        warning={
                          !props.creatingNewNomination && isEtdChanged
                            ? 'Warning: Please be aware that any changes made to the recurring nomination series will replace all single events previously created.'
                            : undefined
                        }
                        className="form-field-error-message warning"
                      />
                    </div>
                  </FormElement>
                </div>
              </div>
            </div>
          )}

          {scheduleRecurrance == ScheduleRecurrenceType.Weekly && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <FormElement>
                  <div className="form-field-wrapper recurring-section-header">Select recurring weekday(s)</div>
                </FormElement>
                <FormElement>
                  <FormFieldWrapper>
                    <Button
                      className={`recurring-duration-button-hours ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.monday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsWeeklyDaysChanged(true);
                        onChangeFormValue(
                          'weeklySchedule.monday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.monday : false,
                        );
                      }}
                    >
                      MON
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.tuesday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsWeeklyDaysChanged(true);
                        onChangeFormValue(
                          'weeklySchedule.tuesday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.tuesday : false,
                        );
                      }}
                    >
                      TUE
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.wednesday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsWeeklyDaysChanged(true);
                        onChangeFormValue(
                          'weeklySchedule.wednesday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.wednesday : false,
                        );
                      }}
                    >
                      WED
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.thursday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsWeeklyDaysChanged(true);
                        onChangeFormValue(
                          'weeklySchedule.thursday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.thursday : false,
                        );
                      }}
                    >
                      THR
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.friday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsWeeklyDaysChanged(true);
                        onChangeFormValue(
                          'weeklySchedule.friday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.friday : false,
                        );
                      }}
                    >
                      FRI
                    </Button>
                    <Button
                      className={`recurring-duration-button-week ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.saturday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsWeeklyDaysChanged(true);
                        onChangeFormValue(
                          'weeklySchedule.saturday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.saturday : false,
                        );
                      }}
                    >
                      SAT
                    </Button>
                    <Button
                      className={`recurring-duration-button-days ${
                        currentNomination.weeklySchedule && currentNomination.weeklySchedule.sunday ? 'active' : ''
                      }`}
                      disabled={disabled || !fieldPermissions.weeklySchedule.write}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsWeeklyDaysChanged(true);
                        onChangeFormValue(
                          'weeklySchedule.sunday',
                          currentNomination.weeklySchedule ? !currentNomination.weeklySchedule.sunday : false,
                        );
                      }}
                    >
                      SUN
                    </Button>
                  </FormFieldWrapper>
                  <div className="form-field-message">
                    <FormErrorMessage errorMessage={errors ? get(errors, 'weeklySchedule') : undefined} />
                  </div>
                  <div className="form-field-message">
                    <ValidationWarning
                      warning={
                        !props.creatingNewNomination && isWeeklyDaysChanged
                          ? 'Warning: Please be aware that any changes made to the recurring nomination series will replace all single events previously created.'
                          : undefined
                      }
                      className="form-field-error-message warning"
                    />
                  </div>
                </FormElement>
              </div>
            </div>
          )}

          {scheduleRecurrance != undefined && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <FormElement>
                  <div className="form-field-wrapper recurring-section-header">
                    How long before the bunkering event would you like to send a nomination to the supplier?
                  </div>
                </FormElement>

                <FormElement>
                  <FormFieldWrapper>
                    <NumberInput
                      name="timeBeforeNominationCreation"
                      disabled={disabled || !fieldPermissions.timeBeforeNominationCreation.write}
                      placeholder={'hours or days'}
                      value={
                        !fieldPermissions.timeBeforeNominationCreation &&
                        !currentNomination.timeBeforeNominationCreation
                          ? '-'
                          : currentNomination.timeBeforeNominationCreation
                          ? currentNomination.timeBeforeNominationCreation
                          : ''
                      }
                      onChangeFormValue={(
                        field: string,
                        value: string | number | boolean | object | null | undefined,
                      ) => {
                        onChangeFormValue('timeBeforeNominationCreation', Number(value));
                      }}
                    ></NumberInput>
                    <Button
                      className={`recurring-duration-button-hours ${isHoursActive ? 'active' : ''}`}
                      disabled={false}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsHoursActive(true);
                        setMultipleValues([
                          //@ts-ignore
                          { field: 'timeBeforeNominationCreation', value: 6 },
                          //@ts-ignore
                          { field: 'tbncType', value: TimeBeforeNominationCreationType.Hours },
                        ]);
                      }}
                    >
                      Hours
                    </Button>
                    <Button
                      className={`recurring-duration-button-days ${isHoursActive == false ? 'active' : ''}`}
                      disabled={false}
                      preventDoubleClick
                      primary
                      onClick={(e) => {
                        setIsHoursActive(false);
                        setMultipleValues([
                          //@ts-ignore
                          { field: 'timeBeforeNominationCreation', value: 1 },
                          //@ts-ignore
                          { field: 'tbncType', value: TimeBeforeNominationCreationType.Days },
                        ]);
                      }}
                    >
                      Days
                    </Button>
                  </FormFieldWrapper>
                  <div className="form-field-message">
                    <FormLabel fieldName="prevTimeBeforeNominationCreation" strikeThrough>
                      {getPreviousValueLabel('timeBeforeNominationCreation', currentNomination, previousNomination)}
                    </FormLabel>
                    <FormErrorMessage errorMessage={errors ? get(errors, 'timeBeforeNominationCreation') : undefined} />
                  </div>
                </FormElement>
              </div>
            </div>
          )}

          {scheduleRecurrance != undefined && (
            <div className="form-element recurring-section-wrapper">
              <FormLabel fieldName=""> </FormLabel>
              <div className="form-field-wrapper recurring-section">
                <div className="times-field-wrapper">
                  <FormElement>
                    <FormLabel fieldName={'startDate'}>Start date for recurrence *</FormLabel>
                    <FormFieldWrapper>
                      <DateTimePicker
                        key={`${currentNomination?.id} startDate`}
                        name={'startDate'}
                        onDateTimeChange={(
                          isoDate: string | null,
                          inputValue: string,
                          isValid: boolean,
                          fieldName?: string | undefined,
                        ) => {
                          setIsStartDateChanged(true);
                          onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
                        }}
                        disabled={disabled || !fieldPermissions.startDate.write}
                        icon={<i className="icon-calendar-1" />}
                        placeholder="dd/mm/yyyy hh:mm"
                        //@ts-ignore
                        value={
                          !fieldPermissions.startDate && !currentNomination.startDate
                            ? '-'
                            : currentNomination.startDate || ''
                        }
                        timeZone={timezone.value}
                        hideDialogHeader
                        useInitialStart={'hour'}
                      />
                    </FormFieldWrapper>

                    <div className="form-field-message">
                      <FormLabel fieldName={`prevStartDate`} strikeThrough>
                        {formatDateTime(
                          getPreviousValueLabel('startDate', currentNomination, previousNomination) as string,
                          timezone.value,
                        )}
                      </FormLabel>

                      <FormErrorMessage errorMessage={errors ? get(errors, 'startDate') : undefined} />
                    </div>
                    <div className="form-field-message">
                      <ValidationWarning
                        warning={
                          !props.creatingNewNomination && isStartDateChanged
                            ? 'Warning: Please be aware that any changes made to the recurring nomination series will replace all single events previously created.'
                            : undefined
                        }
                        className="form-field-error-message warning"
                      />
                    </div>
                  </FormElement>
                </div>

                <div className="times-field-wrapper">
                  <FormElement>
                    <FormLabel fieldName={'endDate'}>End date for recurrence *</FormLabel>
                    <FormFieldWrapper>
                      <DateTimePicker
                        key={`${currentNomination?.id} endDate`}
                        name={'endDate'}
                        onDateTimeChange={(
                          isoDate: string | null,
                          inputValue: string,
                          isValid: boolean,
                          fieldName?: string | undefined,
                        ) => {
                          setIsEndDateChanged(true);
                          onChangeDateTimeValue(isoDate, inputValue, isValid, fieldName);
                        }}
                        disabled={disabled || !fieldPermissions.endDate.write}
                        icon={<i className="icon-calendar-1" />}
                        placeholder="dd/mm/yyyy hh:mm"
                        //@ts-ignore
                        value={
                          !fieldPermissions.endDate && !currentNomination.endDate
                            ? '-'
                            : currentNomination.endDate || ''
                        }
                        timeZone={timezone.value}
                        hideDialogHeader
                        useInitialStart={'hour'}
                      />
                    </FormFieldWrapper>

                    <div className="form-field-message">
                      <FormLabel fieldName={`prevEndDate`} strikeThrough>
                        {formatDateTime(
                          getPreviousValueLabel('endDate', currentNomination, previousNomination) as string,
                          timezone.value,
                        )}
                      </FormLabel>

                      <FormErrorMessage errorMessage={errors ? get(errors, 'endDate') : undefined} />
                    </div>
                    <div className="form-field-message">
                      <ValidationWarning
                        warning={
                          !props.creatingNewNomination && isEndDateChanged
                            ? 'Warning: Please be aware that any changes made to the recurring nomination series will replace all single events previously created.'
                            : undefined
                        }
                        className="form-field-error-message warning"
                      />
                    </div>
                  </FormElement>
                </div>
              </div>
            </div>
          )}
        </FormWrapper>
      </section>

      {canUserEditContract(currentNomination, props.currentUser, props.creatingNewNomination) && (
        <section className="form-section">
          <div className="form-header">
            <div className="step-header">
              <div className="step-indicator">5</div>
              <h2 className="">Contract</h2>
            </div>
          </div>
          <FormWrapper>
            {isCustomer(appliedUserRoles) && currentNomination.priceEnabled && (
              <>
                <FormElement>
                  <FormLabel fieldName="price">Price</FormLabel>

                  <FormFieldWrapper>
                    <TextField
                      fieldName="price"
                      disabled={disabled || !fieldPermissions.price.write}
                      value={currentNomination.price || ''}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        props.onChangeFormValue('price', event.target.value);
                      }}
                    />
                  </FormFieldWrapper>

                  <div className="form-field-message">
                    <FormLabel strikeThrough fieldName="prevPrice">
                      {getPreviousValueLabel('price', currentNomination, previousNomination)}
                    </FormLabel>

                    <FormErrorMessage errorMessage={errors ? get(errors, 'price') : undefined} />
                  </div>
                </FormElement>
                <FormElement>
                  <FormLabel>Unit</FormLabel>
                  <div className="form-field-wrapper">
                    <SelectionBox<{ value: PriceUnit; text: string }>
                      disableSearch
                      fieldName="priceUnit"
                      value={currentNomination.priceUnit}
                      valueKey="value"
                      displayKey="text"
                      disabled={disabled || !fieldPermissions.priceUnit.write}
                      className="price-unit-selection-box"
                      renderFieldValue={(selectedItem) => {
                        return `${selectedItem.text}`;
                      }}
                      onChangeValue={(selectedItem) => {
                        props.onChangeFormValue('priceUnit', selectedItem?.value);
                      }}
                      options={PRICE_UNITS}
                      renderOption={(option) => {
                        return (
                          <div>
                            <span>{option.text}</span>
                          </div>
                        );
                      }}
                    />
                    {/* <SelectionBox<{ value: EnergyUnit; text: string }>
                      disableSearch
                      fieldName="energyUnit"
                      value={currentNomination.energyUnit}
                      valueKey="value"
                      displayKey="text"
                      disabled={disabled || !fieldPermissions.energyUnit.write}
                      renderFieldValue={selectedItem => {
                        return `${selectedItem.text}`
                      }}
                      onChangeValue={selectedItem => {
                        props.onChangeFormValue('energyUnit', selectedItem?.value)
                      }}
                      options={ENERGY_UNITS}
                      renderOption={option => {
                        return (
                          <div>
                            <span>{option.text}</span>
                          </div>
                        )
                      }}
                    /> */}
                  </div>
                  <div className="form-field-message">
                    <FormLabel fieldName="prevPriceUnit" strikeThrough>
                      {getPreviousValueLabel('priceUnit', currentNomination, previousNomination)}
                    </FormLabel>
                    {/* <FormLabel fieldName="prevEnergyUnit" strikeThrough>
                      {getPreviousValueLabel('energyUnit', currentNomination, previousNomination)}
                    </FormLabel> */}
                    {/* <FormErrorMessage errorMessage={get(errors, 'energyUnit')} /> */}
                    <FormErrorMessage errorMessage={get(errors, 'priceUnit')} />
                  </div>
                </FormElement>
              </>
            )}
            <FormElement>
              <FormLabel fieldName="buyerTerms">Customer's additional terms</FormLabel>
              <TextArea
                disabled={disabled || !fieldPermissions.buyerTerms.write}
                fieldName="buyerTerms"
                placeholder={'E.g. legal requirements or similar'}
                value={currentNomination.buyerTerms || ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  props.onChangeFormValue(e.target.name, e.target.value)
                }
              ></TextArea>
            </FormElement>
          </FormWrapper>
        </section>
      )}
    </div>
  );
};

export default CustomerRecurringNominationForm;
