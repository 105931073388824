/* eslint-disable react/jsx-key */
import * as React from 'react';
import { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
//import Chat from '../../components/Chat'; move chat to global components
import Chat from '../BunkerEvents/Components/Chat';
import styles from './SpotEnquiry.module.scss';
import { ApplicationState } from '../../store';
import { getBunkerShips } from '../../store/BunkerShip/actions';
import { getBunkerPipelines } from '../../store/BunkerPipeline/actions';
import SpotChorusIntegration from './Components/SpotChorusIntegration/SpotChorusIntegration';
import SpotCompanyInfo from './Components/SpotCompanyInfo/SpotCompanyInfo';
import { Loading } from '../../components/Loading';
import { getChorusCompanies } from '../../store/ChorusCompany/actions';
import NewSpotEnquiry from './Components/SpotChorusIntegration/NewSpotEnquiry';
import Enquiries from '../../components/Nomination/Enquiries';
import {
  useParams,
  withRouter,
  RouterProps,
  useLocation,
  RouteComponentProps,
  StaticContext,
  useHistory,
} from 'react-router';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import { ICompany } from '@teqplay/chorus-components';
import { getSpotEnquiries } from '../../store/Spot/actions';
import { getArchivedSpots } from '../../store/ArchivedSpot/actions';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Category } from '../../models/Category';
import { IContract, ILocation, INominationEnquiry, CachedLocationService } from '@teqplay/chorus-components';
import { SpotEnquiry as ChorusSpotEnquiry } from '../../store/Spot/models';
import { getCompanies } from '../../store/Company/actions';
import { contractHasExpired, getSpotCompanyIds, getSpotEventStatuses } from '../../helpers/spotEnquiryHelper';
import { checkShipOwnerAdministrative, checkCharterer } from '../../helpers/roleChecks';
import { Nomination } from '../../store/Nomination/models';
import { getContracts } from '../../store/Contract/actions';
import { getChorusContracts } from '../../store/ChorusContract/actions';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { getFuelbossLocations } from '../../store/FuelbossLocations/actions';
import { convertLocationType } from '../../helpers/fBtoChorusType';

interface OwnProps {
  openNewSpot?: boolean;
  serviceLayer: AuthenticationServiceLayer;
  locationService: CachedLocationService;
}

type LocationState = {
  redirectedSpotObject: ChorusSpotEnquiry;
};

type Params = {
  id: string;
  type: string;
};

const SpotEnquiry: React.FC<OwnProps> = ({ openNewSpot, serviceLayer, locationService }) => {
  const [spotIds, setSpotIds] = React.useState([] as string[]);
  const [newSpot, setNewSpot] = React.useState(false);

  //variables for shipowners to display multiple spot enquiries
  const [shipOwnerSpotObject, setShipOwnerSpotObject] = React.useState({});
  const [spotChorusId, setSpotChorusId] = React.useState('');
  const [spotCompanyIds, setSpotCompanyIds] = React.useState(['']);
  const [spotCompanyNames, setSpotCompanyNames] = React.useState(['']);
  const [spotCompanyActive, setSpotCompanyActive] = React.useState<boolean[]>([]);
  const [spotStatuses, setSpotStatuses] = React.useState(['']);

  const [bunkerEventsMinified, setBunkerEventsMinified] = React.useState(false);
  const [spotDetailsMinified, setSpotDetailsMinified] = React.useState(false);
  const [chatMinified, setChatMinified] = React.useState(false);
  const [renderChat, setRenderChat] = React.useState(false);

  const history = useHistory();
  const location = useLocation<LocationState>();

  const setNewSpotMethod = (_newSpot: boolean) => {
    setNewSpot(_newSpot);
    setSpotIds([]);
  };

  const setSpotIdMethod = (ids: string[], spotObject: ChorusSpotEnquiry, archived: boolean = false) => {
    // @ts-ignore
    setSpotIds(ids);
    setNewSpot(false);

    setSpotChorusId(spotObject._id ? spotObject._id : '');
    if (isUserShipOwner()) {
      const companyIds = getSpotCompanyIds(spotObject);
      setSpotCompanyIds(companyIds);
      //set company names
      const companyNames: string[] = [];
      const companyActiveStatus: boolean[] = [];
      companyIds.forEach(function (companyId, index) {
        const company = companies.filter((x) => x.chorusId == companyId)[0];
        if (company?.active) {
          companyNames.push(company.name);
          companyActiveStatus.push(company.active!);
        }
      });
      setSpotCompanyNames(companyNames);
      setSpotCompanyActive(companyActiveStatus);
      setSpotStatuses(getSpotEventStatuses(spotObject));
    }

    if (history) {
      history.push({
        pathname: `/requestquotation/${archived ? 'archived' : 'spots'}/${ids.join(';')}`,
        state: { redirectedSpotObject: spotObject },
      });
    }
  };

  /* const companyStatusUpdate = (spotId: string, status: string) => {
    const companyIndex = spotIds.indexOf(spotId);
    if (companyIndex != -1) {
      let statuses = spotStatuses;
      statuses[companyIndex] = status;
      setSpotStatuses(statuses);
    }
  }; */

  const {
    chorusCompanyLoading,
    chorusCompany,
    chorusCompanyIsLoaded,
    fuelBossLocation,
    fuelBossLocationIsLoaded,
    fuelBossLocationLoading,
    chorusContracts,
    chorusContractsLoading,
    chorusContractsIsLoaded,
    contractsLoading,
    contractsDetailsError,
    contractsErrorDetails,
    contracts,
    contractsIsLoaded,
    companies,
    companiesIsLoaded,
    companiesLoading,
    bunkerShipLoading,
    bunkerShip,
    bunkerShipIsLoaded,
    bunkerPipelineLoading,
    bunkerPipelines,
    bunkerPipelineIsLoaded,
    spotIsLoaded,
    spots,
    spotMetadata,
    archivedSpot,
    archivedSpotIsLoaded,
    auth,
  } = useSelector((state: ApplicationState) => ({
    fuelBossLocation: state.fuelbosslocations.data,
    fuelBossLocationLoading: state.fuelbosslocations.loading,
    fuelBossLocationIsLoaded: state.fuelbosslocations.dataIsLoaded === true,
    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompanyErrorDetails: state.choruscompany.errorDetails,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded === true,
    companies: state.companies.data,
    companiesLoading: state.companies.loading,
    companiesIsLoaded: state.companies.dataIsLoaded,
    chorusContractsLoading: state.choruscontracts.loading,
    chorusContractsError: state.choruscontracts.error,
    chorusContractsErrorDetails: state.choruscontracts.errorDetails,
    chorusContracts: state.choruscontracts.data,
    chorusContractsIsLoaded: state.choruscontracts.dataIsLoaded === true,
    contractsLoading: state.contracts.loading,
    contractsDetailsError: state.contracts.error,
    contractsErrorDetails: state.contracts.errorDetails,
    contracts: state.contracts.data,
    contractsIsLoaded: state.contracts.dataIsLoaded,
    bunkerShipLoading: state.bunkership.loading,
    bunkerShipError: state.bunkership.error,
    bunkerShipErrorDetails: state.bunkership.errorDetails,
    bunkerShip: state.bunkership.data,
    bunkerShipIsLoaded: state.bunkership.dataIsLoaded === true,
    bunkerPipelineLoading: state.bunkerpipeline.loading,
    bunkerPipelineError: state.bunkerpipeline.error,
    bunkerPipelineErrorDetails: state.bunkerpipeline.errorDetails,
    bunkerPipelines: state.bunkerpipeline.data,
    bunkerPipelineIsLoaded: state.bunkerpipeline.dataIsLoaded === true,
    spotIsLoaded: state.spot.dataIsLoaded,
    spots: state.spot.data,
    spotMetadata: state.spot.metadata,
    archivedSpotIsLoaded: state.archived_spot.dataIsLoaded,
    archivedSpot: state.archived_spot.data,
    auth: state.auth.data,
  }));

  const { id, type } = useParams<Params>();

  const [yearMonth, setYearMonth] = React.useState('');
  const [vendorCompanies, setVendorCompanies] = React.useState<ICompany[]>([]);
  const [filteredContracts, setFilteredContracts] = React.useState<IContract[]>([]);
  const [chorusLocations, setChorusLocations] = React.useState<ILocation[]>([]);

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getYearAndMonth = () => {
    const currentDate = new Date();
    let currentMonth = '';
    currentDate.getMonth() + 1 < 10
      ? (currentMonth = `0${currentDate.getMonth() + 1}`)
      : (currentMonth = currentMonth + 1);

    return `${currentDate.getFullYear()}-${currentMonth}`;
  };

  const dispatch = useDispatch();
  const getBunkerShipCallback = React.useCallback(() => dispatch(getBunkerShips()), []);

  const getBunkerPipelineCallback = React.useCallback(() => dispatch(getBunkerPipelines()), []);

  const getLocationCallback = React.useCallback(() => dispatch(getFuelbossLocations()), []);

  const getChorusCompaniesCallback = React.useCallback(() => dispatch(getChorusCompanies()), []);

  const getAllContractsCallback = React.useCallback(() => dispatch(getContracts()), []);

  const getChorusContractsCallback = React.useCallback(() => dispatch(getChorusContracts()), []);

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const getArchivedSpotCallback = React.useCallback(() => dispatch(getArchivedSpots()), []);

  const getSpotCallback = React.useCallback(() => dispatch(getSpotEnquiries()), []);

  React.useEffect(() => {
    let bypassRedirection = false;
    if (type == 'new'){
      setNewSpot(true);
    }
    else if (id && type) {
      if (isUserShipOwner()) {
        if (location.state) {
          const spotObject = location.state.redirectedSpotObject;
          setShipOwnerSpotObject(spotObject);
          if (spotObject != undefined && spotObject != null) {
            setSpotChorusId(spotObject._id ? spotObject._id : '');
            setSpotCompanyIds(getSpotCompanyIds(spotObject));
            setSpotStatuses(getSpotEventStatuses(spotObject));
          }
        } else {
          if (history) {
            bypassRedirection = true;
            setSpotIds([]);
            history.push(`/requestquotation/spots/`);
          }
        }
      } else {
        if (location.state) {
          const spotObject = location.state.redirectedSpotObject;
          setSpotChorusId(spotObject._id ? spotObject._id : '');
        }
      }
      if (!bypassRedirection) setSpotIds(id.split(';'));

      setNewSpot(false);
    }
    if (openNewSpot) {
      setNewSpotMethod(true);
    }
    if (!bunkerShipIsLoaded) {
      getBunkerShipCallback();
    }
    if (!contractsIsLoaded) {
      getAllContractsCallback();
    }
    if (!chorusContractsIsLoaded) {
      getChorusContractsCallback();
    }

    if (!chorusCompanyIsLoaded) {
      getChorusCompaniesCallback();
    }

    if (!companiesIsLoaded) {
      getCompaniesCallback();
    }

    if (!archivedSpotIsLoaded || spotMetadata) {
      getArchivedSpotCallback();
    }

    if (!bunkerPipelineIsLoaded) {
      getBunkerPipelineCallback();
    }

    setYearMonth(getYearAndMonth);

    const interval = setInterval(() => {
      getSpotCallback();
      getArchivedSpotCallback();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (companies.length > 0 && spotCompanyIds.length > 0) {
      const companyNames: string[] = [];
      const companyActiveStatus: boolean[] = [];
      spotCompanyIds.forEach(function (companyId, index) {
        const company = companies.filter((x) => x.chorusId == companyId)[0];
        if (company != null && company != undefined) {
          companyNames.push(company.name);
          companyActiveStatus.push(company.active!);
        }
      });
      setSpotCompanyNames(companyNames);
      setSpotCompanyActive(companyActiveStatus);
    }
  }, [companies, spotCompanyIds]);

  React.useEffect(() => {
    //update company statuses when spots or archieved spots on redux get updated
    const spot = spots.find((x) => x._id == spotChorusId);
    if (isUserShipOwner() && spot != undefined) {
      const newStatuses = getSpotEventStatuses(spot as ChorusSpotEnquiry);
      setSpotStatuses(newStatuses);
    } else if (isUserShipOwner() && (spot == undefined || spot == null)) {
      //find new spot statuses from archived
      const selectedArchivedspot = archivedSpot.find((x) => x._id == spotChorusId);
      if (selectedArchivedspot != undefined) {
        const newStatuses = getSpotEventStatuses(selectedArchivedspot);
        setSpotStatuses(newStatuses);
      }
    } else if (!isUserShipOwner() && spotChorusId == '') {
      const castedSpots = spots as Nomination[];
      const selectedSpot = castedSpots.find((x) => x.eventId == id);
      if (selectedSpot != undefined) {
        setSpotChorusId(selectedSpot?._id ? selectedSpot?._id : '');
      } else {
        //find in archived spots
        const castedArchievedSpots = archivedSpot as Nomination[];
        const selectedArchievedSpot = castedArchievedSpots.find((x) => x.eventId == id);
        selectedArchievedSpot ? setSpotChorusId(selectedArchievedSpot._id) : setSpotChorusId('');
      }
    }
  }, [spots, archivedSpot]);

  React.useEffect(() => {
    //reset active tab on change of selected spot spot
    setActiveTab('1');
  }, [spotIds]);

  const isUserShipOwner = () =>
    (auth.company && auth.company.category.categoryName == Category.Shipowner) ||
    (auth.company && auth.company.category.categoryName == Category.Charterer);

  const getShipOwnerSpotView = () => (
    <div className={styles['chorus-wrapper']}>
      <div className={styles['chorus-multi-nomination-wrapper']}>
        {spotIds.length > 0 && (
          <div className={styles['multi-offer-header']}>
            <div className={styles['offer-header']}>Offers</div>
            <div className={`${styles['spot-company-header']}`}>
              <div className={styles['company-name-header']}>{'Company'}</div>
              <div className={styles['company-status-header']}>{'Status'}</div>
            </div>
            <Nav
              tabs
              vertical={true}
              className={` ${styles['navbar']} spot-events-nav ${styles['scroller-spot-events']} `}
            >
              {spotIds.map((spotId, i) => {
                if (!spotCompanyActive[i]) {
                  return null;
                }

                return (
                  <NavItem key={`nav-${i}`} className={styles['company-nav-item']}>
                    <NavLink
                      className={styles['company-nav-link']}
                      onClick={() => {
                        toggle((i + 1).toString());
                      }}
                    >
                      <SpotCompanyInfo
                        companyName={
                          spotCompanyNames.hasOwnProperty(i) && spotCompanyNames[i] != ''
                            ? spotCompanyNames[i]
                            : `Company${i + 1}`
                        }
                        status={spotStatuses.hasOwnProperty(i) ? spotStatuses[i] : 'Unknown'}
                        isSelected={(i + 1).toString() == activeTab ? true : false}
                      />
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </div>
        )}

        <TabContent activeTab={activeTab} className={styles['chorus-spotintegration-wrapper']}>
          {!spotDetailsMinified &&
            spotIds.map((spotId, i) => (
              <TabPane key={`tab-${i}`} tabId={(i + 1).toString()}>
                <SpotChorusIntegration
                  setRenderChat={setRenderChat}
                  className={styles['chorus']}
                  key={`spot-${i}`}
                  spotId={spotId}
                  yearMonth={yearMonth}
                  bunkerShips={bunkerShip}
                  bunkerPipelines={bunkerPipelines}
                  companies={chorusCompany}
                  locationService={locationService}
                  contracts={filteredContracts}
                  //statusUpdateCallback={companyStatusUpdate}
                  serviceLayer={serviceLayer}
                />
              </TabPane>
            ))}
        </TabContent>
      </div>
    </div>
  );

  React.useEffect(() => {
    const activeVendorCompanies: ICompany[] = [];
    chorusCompany.forEach(function (comp, index) {
      const compMatch = companies.find((x) => x.chorusId == comp._id);
      if (compMatch?.active && compMatch != undefined && comp.companyType == 'VENDOR') {
        activeVendorCompanies.push(comp);
      }
    });

    setVendorCompanies(activeVendorCompanies);
  }, [chorusCompany, companies]);

  React.useEffect(() => {
    const nonRejectedContracts: IContract[] = [];
    chorusContracts.forEach(function (choruscontract, index) {
      const contractMatch = contracts.find((x) => x.chorusId == choruscontract._id);
      if (contractMatch?.status != 'Rejected' && contractMatch !== undefined && !contractHasExpired(contractMatch!)) {
        nonRejectedContracts.push(choruscontract);
      }
    });
    setFilteredContracts(nonRejectedContracts);
  }, [chorusContracts, contracts]);

  const loadingString = `${bunkerShipLoading ? 'bunkerships, ' : ''} ${chorusCompanyLoading ? 'companies, ' : ''} ${
    fuelBossLocationLoading ? 'locations, ' : ''
  }`;

  if (bunkerShipLoading || chorusCompanyLoading) {
    return <Loading type={loadingString} />;
  }

  getYearAndMonth();
  return (
    <div className={styles['bunker-events']}>
      <div
        className={`${styles['nomination-wrapper']} ${
          bunkerEventsMinified ? styles['isMinified'] : styles['isNotMinified']
        }`}
      >
        <Enquiries
          className={styles['nomination']}
          chooseSpot={setSpotIdMethod}
          setNewSpot={setNewSpotMethod}
          activeSpotId={spotChorusId}
          archivedAsInitialTab={type === 'archived' ? true : false}
          minified={bunkerEventsMinified}
          shipOwner={isUserShipOwner()}
        />
        <CollapseButton
          direction={bunkerEventsMinified ? 'right' : 'left'}
          onClick={() => {
            setBunkerEventsMinified(!bunkerEventsMinified);
          }}
          className={styles['nomination-collapse']}
        />
      </div>

      {newSpot && isUserShipOwner() && !checkShipOwnerAdministrative(auth) ? (
        <NewSpotEnquiry
          yearMonth={yearMonth}
          chooseSpot={setSpotIdMethod}
          className={`${styles['chorus']} chorus-wrapper ${styles['new-nomination']}`}
          bunkerShips={bunkerShip}
          bunkerPipelines={bunkerPipelines}
          companies={vendorCompanies}
          locationService={locationService}
          creatingNewSpotBool={newSpot}
          serviceLayer={serviceLayer}
          preselectedSupplierIds={id}
        />
      ) : (
        spotIds && (
          <div className={`${styles['chorus-wrapper']} ${spotDetailsMinified ? styles['isMinified'] : ''}`}>
            {!spotDetailsMinified && !isUserShipOwner() && (
              <SpotChorusIntegration
                setRenderChat={setRenderChat}
                className={styles['chorus']}
                spotId={spotIds[0]}
                yearMonth={yearMonth}
                bunkerShips={bunkerShip}
                bunkerPipelines={bunkerPipelines}
                companies={chorusCompany}
                locationService={locationService}
                contracts={filteredContracts}
                serviceLayer={serviceLayer}
              />
            )}

            {!spotDetailsMinified && isUserShipOwner() && spotIds.length > 0 && getShipOwnerSpotView()}

            {spotDetailsMinified && (
              <div className={styles['minified']}>
                <div className={styles['title']}>Spot details</div>
              </div>
            )}

            {!chatMinified && (
              <CollapseButton
                direction={chatMinified ? 'left' : 'right'}
                onClick={() => {
                  if (spotDetailsMinified) {
                    setSpotDetailsMinified(false);
                  } else {
                    setChatMinified(!chatMinified);
                  }
                }}
                className={`${styles['chorus-collapse']} ${spotDetailsMinified ? styles['isMinified'] : ''}`}
              />
            )}
          </div>
        )
      )}
      {spotIds && (
        <div
          className={`${styles['chat-wrapper']} ${chatMinified ? styles['isMinified'] : ''} ${
            spotDetailsMinified ? styles['nomIsMinified'] : ''
          }`}
        >
          {!chatMinified && !isUserShipOwner() && renderChat && (
            <Chat className={styles['chat']} connectionId={spotIds[0]} type="spot" />
          )}
          {!chatMinified && isUserShipOwner() && renderChat && (
            <Chat className={styles['chat']} connectionId={spotIds[Number(activeTab) - 1]} type="spot" />
          )}
          {chatMinified && (
            <div className={styles['minified']}>
              <div className={styles['title']}>Chat</div>
            </div>
          )}
          {!spotDetailsMinified && (
            <CollapseButton
              direction={'left'}
              onClick={() => {
                if (chatMinified) {
                  setChatMinified(false);
                } else {
                  setSpotDetailsMinified(true);
                  setBunkerEventsMinified(true);
                }
              }}
              className={`${styles['chat-collapse']} ${chatMinified ? styles['isMinified'] : ''}`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SpotEnquiry;
