import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import { IntegrationAssetCompany} from '../../../../../store/IntegrationAssetCompanies/models';

import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs'
import { Formik } from 'formik';
import IntegrationAssetCompanyForm from '../IntegrationAssetForm/IntegrationAssetCompanyForm'
import { Loading } from '../../../../Loading';
import integrationAssetCompanyFormValidation from '../FormValidations/integrationAssetCompanyFormValidation';
import { initialValues } from '../FormInitialValues/integrationAssetCompanyFormInitialValues';

interface OwnProps {
  editIntegrationAssetCompany: (integrationAssetCompany: IntegrationAssetCompany) => void;
  integrationAssetCompanyId: string;
  integrationAssetCompanies: IntegrationAssetCompany[];
  navAdminType: string;
  companyAdmin?: boolean;
  isLoading?: boolean;
}

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

const EditIntegrationAssetCompany: React.FunctionComponent<OwnProps> = ({
  editIntegrationAssetCompany,
  companyAdmin,
  navAdminType,
  integrationAssetCompanyId,
  integrationAssetCompanies,
  isLoading,
}) => {
  const submitIntegrationAssetCompany = (integrationAssetCompany: IntegrationAssetCompany) => {
    editIntegrationAssetCompany(integrationAssetCompany);
  };

  const selectedIntegrationAssetCompany: IntegrationAssetCompany | undefined = integrationAssetCompanies.find((it) => it.veracityTenantId === integrationAssetCompanyId);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Formik
      initialValues={selectedIntegrationAssetCompany ? selectedIntegrationAssetCompany : initialValues}
      validationSchema={integrationAssetCompanyFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetCompanyForm
          integrationAssetCompany={selectedIntegrationAssetCompany}
          header={'Edit integration asset company'}
          saveMethod={submitIntegrationAssetCompany}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(EditIntegrationAssetCompany);
