import React, { useState } from 'react';
import { SpotEnquiry } from '../../../../store/Spot/models';
import CountDown from '../CountDown/CountDown';
import NominationStatus from '../NominationStatus/NominationStatus';
import DeliveryModeIcon from '../DeliveryModeIcon/DeliveryModeIcon';
import Moment from 'react-moment';
import styles from './SpotTable.module.scss';
import Table from '../../../Table';
import { useHistory } from 'react-router-dom';
import { INominationEnquiry } from '@teqplay/chorus-components';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { getFleetVessels } from '../../../../store/CompanyFleet/actions';
import { Category } from '../../../../models/Category';
import { Nomination } from '../../../../store/Nomination/models';
import { getSpotEventStatuses, getSpotEventIds } from './../../../../helpers/spotEnquiryHelper';

interface OwnProps {
  spots: SpotEnquiry[];
  archived: boolean;
}

const SpotTable: React.FC<OwnProps> = ({ spots, archived }) => {
  const history = useHistory();

  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));

  const isUserShipOwner = () => auth.company && (auth.company.category.categoryName == Category.Shipowner || auth.company.category.categoryName == Category.Charterer);

  const getSpotResponseCount = (spot: SpotEnquiry) => {
    let responeCount: number = 0;
    let totalResponses: number = 0;
    const statuses = getSpotEventStatuses(spot);
    statuses.forEach(function (status, index) {
      if (
        status == 'action-required' ||
        status == 'needs-contract' ||
        status == 'confirmed' ||
        status == 'finalised' ||
        status == 'accepted'
      ) {
        responeCount = responeCount + 1;
      }
      totalResponses = totalResponses + 1;
    });
    return `${responeCount.toString()} / ${totalResponses.toString()}`;
  };

  const RenderDeliveryModeIcons = (spot: SpotEnquiry) => {
    const deliveryModes = getDeliveryModes(spot);
    const icons: JSX.Element[] = [];
    deliveryModes.forEach((deliveryMode, index) => {
      icons.push(<DeliveryModeIcon key={index} deliveryMode={deliveryMode}></DeliveryModeIcon>);
    });
    return icons;
  };

  const getDeliveryModes = (spot: SpotEnquiry) => {
    const deliveryModes: string[] = isUserShipOwner()
      ? getShipOwnerDeliveryModes(spot)
      : getSupplierDeliveryModes(spot);
    return deliveryModes;
  };

  const getShipOwnerDeliveryModes = (spot: SpotEnquiry) => {
    const spotEnquiry = spot as INominationEnquiry;
    return spotEnquiry.deliveryModes;
  };

  const getSupplierDeliveryModes = (spot: SpotEnquiry) => {
    const spotEnquiry = spot as Nomination;
    return spotEnquiry.deliveryModes;
  };

  const header = ['Days left', 'Vessel', 'Port', 'Quantity', 'Delivery', 'Date'];
  const headerShipOwner = ['Days left', 'Responses', 'Vessel', 'Port', 'Quantity', 'Delivery', 'Date'];
  return (
    <Table
      headers={isUserShipOwner() ? headerShipOwner : header}
      numberOfRowsPerPagination={5}
      paginationOnBottom={true}
      noPagination={false}
      striped={false}
      hover={false}
    >
      {spots.map((spot) =>
        spot ? (
          <tr
            key={spot._id}
            className={`${styles.tr} ${spot.attributes ? styles[spot.attributes.fbStatus] : ''}`}
            onClick={(e) => {
              if (isUserShipOwner()) {
                const ids: string[] = getSpotEventIds(spot);
                history.push({
                  pathname: `/requestquotation/${archived ? 'archived' : 'spots'}/${ids.join(';')}`,
                  state: { redirectedSpotObject: spot },
                });
              } else {
                const spotenquiry = spot as Nomination;
                history.push({
                  pathname: `/requestquotation/${archived ? 'archived' : 'spots'}/${spotenquiry.eventId}`,
                  state: { redirectedSpotObject: spot },
                });
              }
            }}
          >
            <td className={`${styles.td} ${styles['status-container']}`}>
              <div className={styles['status-days']}>
                <CountDown date={new Date(spot.bst)} />
              </div>
              {spot.attributes?.fbStatus && (
                <div className={styles['status-fuelbossStatus']}>
                  <NominationStatus status={spot.attributes?.fbStatus} />
                </div>
              )}
            </td>
            {isUserShipOwner() && <td className={styles.td}>{getSpotResponseCount(spot).toString()}</td>}
            <td className={styles.td}>{spot.attributes?.receivingShipName}</td>
            <td className={styles.td}>{spot.attributes?.port}</td>
            <td className={styles.td}>{spot.amount} mt</td>
            <td className={styles.td}>
              <div className={styles['delivery-icons-wrapper']}>{RenderDeliveryModeIcons(spot)}</div>
            </td>
            <td className={styles.td}>
              <Moment date={spot.bst} format="DD MMM YYYY" />
            </td>
          </tr>
        ) : (
          <tr></tr>
        ),
      )}
    </Table>
  );
};

export default SpotTable;
