import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import React from 'react';
import { ApplicationState } from '../../../store';

import {
  getFuelbossLocations,
  addFuelbossLocation,
  deleteFuelbossLocation,
  editFuelbossLocation,
  bulkUpdate,
} from '../../../store/FuelbossLocations/actions';
import { FuelBossLocation } from '../../../store/FuelbossLocations/models';
import AddLocation from './Components/AddLocation/AddLocation';
import Locations from './Components/Locations/Locations';
import { AddToast } from '../../../helpers/toastService';
import { Route } from 'react-router';
import { Switch, useHistory } from 'react-router-dom';
import EditLocation from './Components/EditLocation/EditLocation';
import { locationClearToast } from '../../../store/FuelbossLocations/actions';
import { GetFileUploadData } from '../../../store/ChorusLocations/actions';

interface OwnProps {
  companyAdmin?: boolean;
}

const LocationsContainer: React.FC<OwnProps> = ({ companyAdmin }) => {
  const {
    location_upload,
    location_uploadLoading,
    location_uploadIsLoaded,
    fuelbossLocationLoading,
    fuelbossLocationDetailsError,
    fuelbossLocationErrorDetails,
    fuelbossLocation,
    fuelbossLocationIsLoaded,
    toastData,
    // toastDataImport,
  } = useSelector((state: ApplicationState) => ({
    location_upload: state.location_upload.data,
    location_uploadIsLoaded: state.location_upload.dataIsLoaded,
    location_uploadLoading: state.location_upload.loading,
    fuelbossLocationLoading: state.fuelbosslocations.loading,
    fuelbossLocationDetailsError: state.fuelbosslocations.error,
    fuelbossLocationErrorDetails: state.fuelbosslocations.errorDetails,
    fuelbossLocation: state.fuelbosslocations.data,
    fuelbossLocationIsLoaded: state.fuelbosslocations.dataIsLoaded,
    toastData: state.fuelbosslocations.toastData,
    // toastDataImport: state.location_upload.toastData,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const getLocationCallback = useCallback(() => dispatch(getFuelbossLocations()), []);
  const getFileUploadDataCallback = useCallback(() => dispatch(GetFileUploadData()), []);
  const bulkUpdateCallback = useCallback((file: any) => dispatch(bulkUpdate(file)), []);
  const deleteLocationCallback = useCallback((id: string) => dispatch(deleteFuelbossLocation(id)), []);
  const addLocationCallback = useCallback(
    (location: FuelBossLocation) =>
      dispatch(addFuelbossLocation(location, () => history.push(`/systemadmin/locations`))),
    [],
  );
  const editLocationCallback = useCallback(
    (location: FuelBossLocation) =>
      dispatch(editFuelbossLocation(location, () => history.push(`/systemadmin/locations`))),
    [],
  );

  const clearToastCallback = useCallback(() => dispatch(locationClearToast()), []);

  useEffect(() => {
    if (!fuelbossLocationIsLoaded) {
      getLocationCallback();
    }
    if (!location_uploadIsLoaded) {
      getFileUploadDataCallback();
    }
  }, []);

  useEffect(() => {
    getFileUploadDataCallback();
  }, [fuelbossLocation]);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }

  return (
    <Switch>
      <Route
        exact
        path={`/systemadmin/locations`}
        render={(props) => (
          <Locations
            {...{ props }}
            csvData={location_upload}
            bulkUpdate={bulkUpdateCallback}
            fbLocations={(fuelbossLocation as unknown) as FuelBossLocation[]}
            csvIsLoading={location_uploadLoading}
            isLoading={fuelbossLocationLoading}
            hasError={fuelbossLocationDetailsError}
            errorDetails={fuelbossLocationErrorDetails}
            deleteLocation={deleteLocationCallback}
            defaultSelectedPage={selectedPage}
            setDefaultSelectedPage={handleDefaultChange}
          />
        )}
      />
      <Route
        exact
        path={`/systemadmin/locations/add`}
        render={(props) => <AddLocation {...{ props }} addNewLocation={addLocationCallback} />}
      />
      <Route
        exact
        path={`/systemadmin/locations/:locationId/edit`}
        render={(props) => (
          <EditLocation
            {...props}
            isLoading={fuelbossLocationLoading}
            editLocation={editLocationCallback}
            locations={fuelbossLocation}
            locationId={props.match.params.locationId}
          />
        )}
      />
    </Switch>
  );
};

export default LocationsContainer;
