/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import styles from './MyFleetCard.module.scss';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Vessel } from '../../../../../../store/Vessels/models';
import VesselCard from './Components/VesselCard/VesselCard';
import { MyFleets, MyFleetVessel } from '../../../../../../store/MyFleets/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import SearchVessel from '../../../../../../components/SearchVessel/SearchVessel';
import DeleteList from './Components/DeleteList/DeleteList';
import { useDispatch } from 'react-redux';
import { setFleetVisibility } from '../../../../../../store/MyFleets/actions';
import arrowUp from './arrow-up.png';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';
import { ReactComponent as EyeOpenIcon } from '../../../../../../svgs/eye-open.svg';
import { ReactComponent as EyeClosedIcon } from '../../../../../../svgs/eye-closed.svg';

const penIcon = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

interface OwnProps {
  deleteVesselMethod: (id: string) => void;
  deleteFleet: (id: string) => void;
  chooseVesselMethod: (vessel: Vessel, fleetId: string) => void;
  fleet: MyFleets;
}

const MyFleetCard: React.FC<OwnProps> = ({ fleet, deleteFleet, deleteVesselMethod, chooseVesselMethod }) => {
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (fleet.myFleetVessel[0] != undefined) {
      const anyVesselsVisible: any = fleet.myFleetVessel.some((role: MyFleetVessel) => role.visible == true);
      setVisible(anyVesselsVisible);
    }
  }, []);

  const toggleEdit = () => setEdit(!edit);
  const dispatch = useDispatch();

  //Callbacks to redux
  const setFleetVisibilityCallback = useCallback(
    (id: string, visible: boolean) => dispatch(setFleetVisibility(id, visible)),
    [],
  );

  const toggleFilter = () => {
    if (fleet.myFleetVessel[0] != undefined) {
      setFleetVisibilityCallback(fleet.fleetId, !visible);
      setVisible(!visible);
    }
  };

  const chooseVessel = (vessel: Vessel) => {
    chooseVesselMethod(vessel, fleet.fleetId);
    setVisible(true);
  };

  const vesselcards = fleet.myFleetVessel.map((subitem: any, i: any) => (
    <VesselCard key={i} myFleetItem={subitem} deleteVessel={deleteVesselMethod} edit={edit} />
  ));

  return (
    <div className={styles['wrapper']}>
      <div className={styles['grid']}>
        <div className={styles['header']}>
          {fleet.fleetname}
          <Button className={styles['eyeButton']} onClick={toggleFilter}>
            {visible ? (
              <EyeOpenIcon className={classnames(styles.eyeClosed, styles.eye)} />
            ) : (
              <EyeClosedIcon className={classnames(styles.eyeOpen, styles.eye)} />
            )}
          </Button>
        </div>
        <div className={styles['buttonGrid']}>
          <Button
            outline
            color="secondary"
            className={edit ? styles['editButtonClicked'] : styles['editButton']}
            onClick={toggleEdit}
          >
            <FontAwesomeIcon icon={penIcon} />
          </Button>
        </div>
      </div>

      {edit ? (
        <SearchVessel buttonStyle={styles.buttonStyle} mapMyFleet={true} chooseVesselMethod={chooseVessel} />
      ) : null}

      <Collapse isOpen={visible} style={{ cursor: 'pointer' }}>
        {vesselcards}
      </Collapse>

      {fleet.myFleetVessel[0] != undefined || edit ? null : (
        <div>
          <div className={styles['addVessels']}>Click the button to add vessels</div>
          <img src={arrowUp} alt="" className={styles['addVesselsImg']} />
        </div>
      )}

      {edit ? (
        <DeleteList id={fleet.fleetId} deleteMethod={deleteFleet} header="Delete vessel" delBtnIcon={deleteIcon} />
      ) : null}
    </div>
  );
};

export default React.memo(MyFleetCard);
