import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import React from 'react';
import AddCompany from './Components/AddCompany/AddCompany';
import Companies from './Components/Companies/Companies';
import { ApplicationState } from '../../../store';
import {
  getCompanies,
  addCompany,
  deleteCompany,
  updateCompany,
  companyClearToast,
} from '../../../store/Company/actions';
import { addCompanyLogo } from '../../../store/CompanyLogo/actions';
import { Company } from '../../../store/Company/models';
import { AddToast } from '../../../helpers/toastService';
import { Route, useHistory } from 'react-router';
import { Switch } from 'react-router-dom';
import EditCompany from './Components/EditCompany/EditCompany';

interface OwnProps {
  systemAdmin?: boolean;
  navAdminType: string;
}

const CompaniesContainer: React.FC<OwnProps> = ({ systemAdmin = false, navAdminType }) => {
  const {
    companiesLoading,
    companiesDetailsError,
    companiesErrorDetails,
    companies,
    companiesIsLoaded,
    toastData,
  } = useSelector((state: ApplicationState) => ({
    companiesLoading: state.companies.loading,
    companiesDetailsError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    toastData: state.companies.toastData,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const getAllCompaniesCallback = useCallback(() => dispatch(getCompanies()), []);

  //Add company methods
  const addCompanyCallback = useCallback(
    (company: Company) => dispatch(addCompany(company, () => history.push(`/systemadmin/companies`))),
    [],
  );

  const saveLogoCallback = useCallback(
    (companyId: string, logo: string, mimeType: string) =>
      dispatch(addCompanyLogo(companyId, logo, mimeType, () => console.log('logo added'))),
    [],
  );

  const deleteCompanyCallback = useCallback((id: string) => dispatch(deleteCompany(id)), []);

  const editCompanyCallback = useCallback(
    (company: Company) => dispatch(updateCompany(company, navAdminType, () => history.push(`/systemadmin/companies`))),
    [],
  );
  const clearToastCallback = useCallback(() => dispatch(companyClearToast()), []);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getAllCompaniesCallback();
    }
  }, []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }

  return (
    <Switch>
      <Route
        exact
        path={`/${navAdminType}admin/companies`}
        render={(props) => (
          <Companies
            {...{ props }}
            companies={companies}
            isLoading={companiesLoading}
            hasError={companiesDetailsError}
            errorDetails={companiesErrorDetails}
            deleteCompany={deleteCompanyCallback}
            navAdminType={navAdminType}
            defaultSelectedPage={selectedPage}
            setDefaultSelectedPage={handleDefaultChange}
          />
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/companies/add`}
        render={(props) => (
          <AddCompany
            {...{ props }}
            addNewCompany={addCompanyCallback}
            systemAdmin={systemAdmin}
            navAdminType={navAdminType}
          />
        )}
      />
      <Route
        exact
        path={`/${navAdminType}admin/companies/:companyId/edit`}
        render={(props) => (
          <EditCompany
            {...props}
            isLoading={companiesLoading}
            editCompany={editCompanyCallback}
            companies={companies}
            companyId={props.match.params.companyId}
            navAdminType={navAdminType}
            systemAdmin={systemAdmin}
            saveLogoMethod={saveLogoCallback}
          />
        )}
      />
    </Switch>
  );
};

export default CompaniesContainer;
