import { IntegrationAssetWorkspaceWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_WORKSPACE_CLEAR_TOAST = 'integrationAssetWorkspace/ClearToast';
export type INTEGRATION_ASSET_WORKSPACE_CLEAR_TOAST = typeof INTEGRATION_ASSET_WORKSPACE_CLEAR_TOAST;

export const INTEGRATION_ASSET_WORKSPACE_LOADING = 'integrationAssetWorkspace/loading';
export type INTEGRATION_ASSET_WORKSPACE_LOADING = typeof INTEGRATION_ASSET_WORKSPACE_LOADING;

export const GET_INTEGRATION_ASSET_WORKSPACE = 'integrationAssetWorkspace/get';
export type GET_INTEGRATION_ASSET_WORKSPACE = typeof GET_INTEGRATION_ASSET_WORKSPACE;

export const INTEGRATION_ASSET_WORKSPACE_FETCH_ERROR = 'integrationAssetWorkspace/fetchError';
export type INTEGRATION_ASSET_WORKSPACE_FETCH_ERROR = typeof INTEGRATION_ASSET_WORKSPACE_FETCH_ERROR;

export const ADD_INTEGRATION_ASSET_WORKSPACE = 'integrationAssetWorkspace/add';
export type ADD_INTEGRATION_ASSET_WORKSPACE = typeof ADD_INTEGRATION_ASSET_WORKSPACE;

export const EDIT_INTEGRATION_ASSET_WORKSPACE = 'integrationAssetWorkspace/edit';
export type EDIT_INTEGRATION_ASSET_WORKSPACE = typeof EDIT_INTEGRATION_ASSET_WORKSPACE;

export const DELETE_INTEGRATION_ASSET_WORKSPACE = 'integrationAssetWorkspace/delete';
export type DELETE_INTEGRATION_ASSET_WORKSPACE = typeof DELETE_INTEGRATION_ASSET_WORKSPACE;

export const INTEGRATION_ASSET_WORKSPACE_ADD_ERROR = 'integrationAssetWorkspace/addError';
export type INTEGRATION_ASSET_WORKSPACE_ADD_ERROR = typeof INTEGRATION_ASSET_WORKSPACE_ADD_ERROR;

export const INTEGRATION_ASSET_WORKSPACE_EDIT_ERROR = 'integrationAssetWorkspace/editError';
export type INTEGRATION_ASSET_WORKSPACE_EDIT_ERROR = typeof INTEGRATION_ASSET_WORKSPACE_EDIT_ERROR;

export const INTEGRATION_ASSET_WORKSPACE_DELETE_ERROR = 'integrationAssetWorkspace/deleteError';
export type INTEGRATION_ASSET_WORKSPACE_DELETE_ERROR = typeof INTEGRATION_ASSET_WORKSPACE_DELETE_ERROR;

export type IntegrationAssetWorkspaceActions =
  | {
      type: INTEGRATION_ASSET_WORKSPACE_LOADING;
    }
  | {
      type: GET_INTEGRATION_ASSET_WORKSPACE;
      payload: IntegrationAssetWorkspaceWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_ASSET_WORKSPACE;
      payload: IntegrationAssetWorkspaceWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_ASSET_WORKSPACE;
      payload: IntegrationAssetWorkspaceWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_WORKSPACE;
      payload: IntegrationAssetWorkspaceWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_WORKSPACE_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_WORKSPACE_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_WORKSPACE_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_WORKSPACE_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_WORKSPACE_CLEAR_TOAST;
    };
