import * as React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from './../../../../../store/index';
import styles from './TokenTimeout.module.scss';
import { SessionTimeOutError } from '../../../../../components/Error/components/SessionTimeOutErrror';
import { getAuth } from './../../../../../store/Auth/actions';
import { Button } from 'reactstrap';
import logo from '../FuelBoss_icon.png';
import { ReactComponent as FuelBossLogout } from '../logout_illustration.svg';
import jwt_decode from 'jwt-decode';

interface OwnProps {
  setTimeoutBoxDisplayed: (val: boolean) => void;
}

const TokenTimeout: React.FC<OwnProps> = ({ setTimeoutBoxDisplayed }) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));

  const dispatch = useDispatch();
  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const [currentTime, setCurrentTime] = React.useState<Date>(new Date());
  const [tokenExpiryTime, setTokenExpiryTime] = React.useState<Date>(new Date(new Date().getTime() + 60 * 60 * 1000)); //some random time (now+60) at start
  const [timeoutInMinutes, setTimeoutInMinutes] = React.useState<number>(20);
  const [displayTimeoutBox, setDisplayTimeoutBox] = React.useState<boolean>(false);
  const [isEnabledAutoTokenRefresh, setIsEnabledAutoTokenRefresh] = React.useState<boolean>(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    //decode token and get updated token timeout
    const decoded = jwt_decode(auth.ac.slice(0, -2));
    //@ts-ignore
    const expiryTime = new Date(decoded.exp * 1000);
    setTokenExpiryTime(expiryTime);
  }, [auth.ac]);

  React.useEffect(() => {
    if (auth) {
      const localTimeoutInMinutes = Math.floor((new Date(tokenExpiryTime).getTime() - currentTime.getTime()) / 60000);
      setTimeoutInMinutes(localTimeoutInMinutes);
      if (localTimeoutInMinutes <= 3 && localTimeoutInMinutes > 1 && timeoutInMinutes != localTimeoutInMinutes) {
        //token auto refreshed at 3 and 2 munites before expiry
        if (isEnabledAutoTokenRefresh) {
          getAuthCallback();
        }
      }
      // logout sceen shown 1 munite before token expiry in case refresh wasn't successful
      if (localTimeoutInMinutes <= 1 && timeoutInMinutes != localTimeoutInMinutes) {
        setDisplayTimeoutBox(true);
        setTimeoutBoxDisplayed(true);
      }
    }
  }, [currentTime]);

  const getSignoutLogo = () => {
    return <FuelBossLogout className={styles['status-logo']} />;
  };

  if (!auth.sessionTimeOut) {
    return null;
  }

  if (displayTimeoutBox) {
    return (
      <div className={styles['session-timeout-wrapper']}>
        <div className={styles['session-wrapper']}>
          <div className={styles['circle']}></div>
          <div className={styles['content']}>
            <div className={styles['content-left']}>
              <div className={styles['fb-logo']}>
                <div className={styles['fb-logo-img']}>
                  <img src={logo} alt="Logo" className={styles.logo} />
                </div>
                <div className={styles['fb-logo-text']}>FuelBoss</div>
              </div>
              <div className={styles['session-div']}>You are logged out.</div>
              <div className={styles['nom-button']}>
                <Button
                  className={styles['refresh-button']}
                  onClick={() => {
                    setTimeoutBoxDisplayed(false);
                    getAuthCallback();
                  }}
                >
                  Take me back in
                </Button>
              </div>
            </div>
            <div className={styles['content-right']}>{getSignoutLogo()}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default TokenTimeout;
