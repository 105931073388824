import { AuthenticationServiceLayer, IFetchOptions, IRestError } from '@teqplay/chorus-components';
import { cloneDeep } from 'lodash';
import { ErrorWrapper, ToastData } from '../models/ErrorAction';
import { toast } from 'react-toastify';
import { Auth } from '../store/Auth/models';
import FetchError from './FetchError';
import { AddToast } from './toastService';

export class ServiceLayer implements AuthenticationServiceLayer {
  errorWrapperObject: ErrorWrapper;
  auth: Auth;
  errorToastData: ToastData = {
    content: 'Error reaching chorus servers. Try (Ctrl + Shift + R) and if problem persists, please contact us on fuelboss@dnv.com',
    type: 'error',
    toastId: 'serviceLayer',
  };
  tokenRefreshCallback: () => void;
  timeoutBoxDisplayed: boolean = false;

  public updateTimeoutBoxDisplayed(value: boolean): void {
    this.timeoutBoxDisplayed = value;
  };

  setErrorObject = (error: any): void => {
    const backendFault = error.status >= 500 ? true : false;
    this.errorWrapperObject = {
      payload: {
        clientFault: !backendFault,
        serverFault: backendFault,
        statusCode: error.status,
        message: error.message,
      },
    };
  };

  constructor(errorWrapperObject: ErrorWrapper, auth: Auth, refreshCallback: () => void) {
    this.errorWrapperObject = errorWrapperObject;
    this.auth = auth;
    this.tokenRefreshCallback = refreshCallback;
  }

  public handleRestResponse = (response: any): Promise<any> => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response.json());
    } else {
      return Promise.resolve(response.json())
        .catch((err) => ({ status: response.status, message: err.message }))
        .then((restError: IRestError) => {
          if (response.status == 401 && !this.timeoutBoxDisplayed) {
            console.log('serviceLayer got auth error');
            this.setErrorObject(restError);
            AddToast({
              content: 'Token expired. Refreshing token..',
              type: 'error',
              toastId: 'serviceLayer',
            });
            //redirect to sign in page
            window.location.href = '/account/signin';
          }
          if (response.status != 404 && response.status != 403 && response.status != 422 && response.status != 412 && response.status != 401) {
            this.setErrorObject(restError);
            if (restError && !toast.isActive('serviceLayer')) {
              AddToast(this.errorToastData);
            }
          }
          throw new FetchError(restError.status, restError.error, restError.message);
        });
    }
  };

  public async fetchApiCall(
    url: string,
    options: IFetchOptions,
    handleResponse?: (response: Response) => Promise<any>,
  ) {
    //const fetchOptions = cloneDeep(options);
    const { chorusBaseUrl } = this.auth;
    const apiEndpoint = `${chorusBaseUrl}${url}`;
    const headerWithAuthorisation = Object.assign(options.headers, {
      Authorization: this.auth.ac.slice(0, -2),
    });
    const fetchOptions = cloneDeep(options);
    fetchOptions.headers = headerWithAuthorisation;
    // eslint-disable-next-line no-useless-catch
    try {
      return await fetch(apiEndpoint, fetchOptions).then(handleResponse ? handleResponse : this.handleRestResponse);
    } catch (error) {
      throw error;
    }
  }
}
