import React, { useState } from 'react';
import { Route } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
//import AddContract from './Components/AddContract/AddContract';
//import Contracts from './Components/Contracts/Contracts';
import { Company } from '../../../../store/Company/models';
import BDNLocations from '../BDNLocations/BDNLocations';
import { ApplicationState } from '../../../../store';
import { getContracts, addContract, deleteContract, updateContract } from '../../../../store/Contract/actions';
//import { Contract } from '../../../store/Contract/models';
import { AddToast } from '../../../../helpers/toastService';
import { companyClearToast, getCompanies } from '../../../../store//Company/actions';
import { CachedLocationService, ICompany, LocationService } from '@teqplay/chorus-components';
//import EditContract from '../Components/EditContract/EditContract';
//import RejectModal from './Components/RejectModal/RejectModal';
import { getContractDocuments, deleteContractDocuments } from '../../../../store/ContractDocuments/actions';
import { getBDNLocations, deleteBDNLocations, addBDNLocation, BDNLocationsClearToast } from '../../../../store/BDNLocation/actions';
import { BDNLocation } from '../../../../store/BDNLocation/models';
import CompanyAdmin from '../../../../scenes/CompanyAdmin/CompanyAdmin';
import { Loading } from '../../../Loading';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';

interface OwnProps {
  systemAdmin?: boolean;
  navAdminType: string;
  locationService: LocationService;
}

const BDNLocationsContainer: React.FC<OwnProps> = ({ systemAdmin = false, navAdminType, locationService }) => {
  const {
    bdnLocationsLoading,
    bdnLocationsDetailsError,
    bdnLocationsErrorDetails,
    bdnLocations,
    bdnLocationsIsLoaded,
    toastData,
    companyLoading,
    companyError,
    companies,
    companyIsLoaded,
    auth,
  } = useSelector((state: ApplicationState) => ({
    bdnLocationsLoading: state.bdn_locations.loading,
    bdnLocationsDetailsError: state.bdn_locations.error,
    bdnLocationsErrorDetails: state.bdn_locations.errorDetails,
    bdnLocations: state.bdn_locations.data,
    bdnLocationsIsLoaded: state.bdn_locations.dataIsLoaded,
    toastData: state.bdn_locations.toastData,
    companyLoading: state.companies.loading,
    companyError: state.companies.error,
    companies: state.companies.data,
    companyIsLoaded: state.companies.dataIsLoaded,
    auth: state.auth.data,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const getAllBDNLocationsCallback = useCallback(() => dispatch(getBDNLocations()), []);

  const deleteBDNLocationCallback = useCallback((id: string) => dispatch(deleteBDNLocations(id)), []);

  const addBDNLocationCallback = useCallback(
    (bdnlocation: BDNLocation) =>
      dispatch(addBDNLocation(bdnlocation, () => history.push(`/${navAdminType}admin/bdnlocations`))),
    [],
  );

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const clearToastCallback = useCallback(() => dispatch(BDNLocationsClearToast()), []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);
  

  useEffect(() => {
    if (!bdnLocationsIsLoaded) {
      getAllBDNLocationsCallback();
    }
    if (!companyIsLoaded) {
      getCompaniesCallback();
    }
  }, []);

  const [suppliers, setSuppliers] = React.useState<Company[]>();
  const [customers, setCustomers] = React.useState<Company[]>();
  //const [rejectContract, setRejectContract] = React.useState<Contract>();
  const [writePermission, setWritePermission] = React.useState(systemAdmin);

  const bdnLocationAdded = (bdnLocation: BDNLocation) => {
    addBDNLocationCallback(bdnLocation);
  };

  useEffect(() => {
    const sup: Company[] = [];
    const cust: Company[] = [];

    if (companyIsLoaded && companies.length > 0) {
      companies.forEach((e: Company) => {
        if (e.category.categoryName.toLowerCase() === 'supplier') {
          sup.push(e);
          if (auth.company && e.companyId === auth.company.companyId) {
            setWritePermission(true);
          }
        } else {
          cust.push(e);
        }
      });
      setSuppliers(sup);
    }
  }, [companies]);

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue: number) {
    setDefaultSelectedPage(newValue);
  }
  if (bdnLocationsLoading || companyLoading || !suppliers || suppliers?.length == 0) {
    return <Loading />;
  } else if (suppliers) {
    return (
      <>
        <Switch>
          <Route
            exact
            path={`/${navAdminType}admin/bdnlocations`}
            render={(props) => (
              <BDNLocations
                {...props}
                bdnlocations={bdnLocations}
                isLoading={bdnLocationsLoading}
                hasError={bdnLocationsDetailsError}
                navAdminType={navAdminType}
                errorDetails={bdnLocationsErrorDetails}
                systemAdmin={systemAdmin}
                suppliers={suppliers}
                addBDNLocations={bdnLocationAdded}
                deleteBDNLocation={deleteBDNLocationCallback}
                defaultSelectedPage={selectedPage}
                setDefaultSelectedPage={handleDefaultChange}
                company={auth.company}
                locationService={locationService}
              />
            )}
          />
        </Switch>
      </>
    );
  } else {
    return null;
  }
};

export default BDNLocationsContainer;
