import * as TYPE from './types';
import { ApplicationState } from '..';

export const companyOperationaLocationsReducer = (
  state: ApplicationState['company_operational_locations'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.CompanyOperationaLocationsActions,
): ApplicationState['company_operational_locations'] => {
  switch (action.type) {
    case TYPE.COMPANY_OPERATIONAL_LOCATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_COMPANY_OPERATIONAL_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.COMPANY_OPERATIONAL_LOCATIONS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.DELETE_COMPANY_OPERATIONAL_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Operational location succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.DELETE_COMPANY_OPERATIONAL_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: action.payload.message ? action.payload.message : "Sorry, can't delete operational location.",
          type: 'error',
        },
      };
    case TYPE.ADD_COMPANY_OPERATIONAL_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Operational location succesfully created!',
          type: 'success',
        },
      };
    case TYPE.ADD_COMPANY_OPERATIONAL_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: action.payload.message ? action.payload.message : "Sorry, can't add operational location.",
          type: 'error',
        },
      };
    case TYPE.UPDATE_COMPANY_OPERATIONAL_LOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Operational location edited',
          type: 'info',
        },
      };
    case TYPE.UPDATE_COMPANY_OPERATIONAL_LOCATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: action.payload.message ? action.payload.message : "Sorry, can't update operational location.",
          type: 'error',
        },
      };
    case TYPE.COMPANY_OPERATIONAL_LOCATIONS_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    default:
      return { ...state };
  }
};
