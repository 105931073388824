import React, { Component, Fragment } from 'react';
import Select, { components } from 'react-select';
import { FlatButton } from '../SharedStyledComponents/Buttons';
import PropTypes from "prop-types";

class MySelect extends Component {
  handleChange = (value) => {
    // this is going to call setFieldValue and manually update values[name]
    let _value = value instanceof Array ? value : [value];
    if (value === null) {
      _value = [];
    }
    this.props.onChange(this.props.name, _value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched[name]
    this.props.onBlur(this.props.name, true);
  };

  getOptionsLabels = (opt) => {
    return opt.map(x => x.label)
  };
  
  componentDidUpdate(prevProps) {
    const previousOptions = this.getOptionsLabels(prevProps.options);
    //console.log('previos options ',previousOptions);
    const currentOptions = this.getOptionsLabels(this.props.options);
    //console.log('current options ',currentOptions);
    let isEqual = JSON.stringify(previousOptions) === JSON.stringify(currentOptions);
    //console.log('is equal',isEqual);
    if (!isEqual) {
      this.props.onChange(this.props.name, []);
    }
  } 

  render() {
    const {
      className,
      id,
      options,
      value,
      name,
      placeholder,
      isLoading,
      isMultiselect,
      isDisabled,
      allowSelectAll,
    } = this.props;

    const customStyles = {
      control: (styles) => ({
        ...styles,
        minHeight:'47px',
        // This line disable the blue border
        boxShadow: 'none',
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 10,
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#33B2E1',
        borderRadius: '4px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#fff',
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        color: '#fff',
        ':hover': {
          backgroundColor: 'red',
          borderRadius: '4px',
          color: '#fff',
          cursor: 'pointer',
        },
      }),
      valueContainer: (styles) => ({
        ...styles,
        maxHeight: '60px',
        overflowY: 'scroll',
      }),
    };

    const customTheme = (theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
      },
    });

    const MenuList = props => {
      const [isSelectAllHovered, setIsSelectAllHovered] = React.useState(false);
      const toggleHover = () =>{
        setIsSelectAllHovered(!isSelectAllHovered);
      } 
      const selectAllSectionStyles = {
        width:'100%',
        backgroundColor: 'white',
        display: 'block',
        padding: '8px 12px',
      };
      const selectAllHoverStyles = {
        width:'100%',
        //backgroundColor: '#DEEBFF',
        backgroundColor: '#24365c',
        color: 'white',
        padding: '8px 12px',
      }
      return (
        <components.MenuList {...props}>
          {/* display SelectAll button in the menu on the top*/}
          <div style={{width:'100%'}}>
          {isMultiselect && allowSelectAll && value && value.length !== options.length && (
          <div
            style={isSelectAllHovered? selectAllHoverStyles :selectAllSectionStyles}
            type="button"
            disabled={value.length === options.length}
            onClick={() => this.handleChange(options)}
            onMouseEnter={toggleHover} 
            onMouseLeave={toggleHover}
          >
            Select all
          </div>
        )}
        </div>
          {props.children}
        </components.MenuList>
      );
    };

    return (
      <>
        <Select
          className={className}
          styles={customStyles}
          theme={customTheme}
          isMulti={isMultiselect}
          isDisabled={isDisabled}
          components={{ MenuList }}
          //menuIsOpen={true}
          isOptionDisabled={(option) => option.disabled}
          isSearchable
          closeMenuOnSelect={!isMultiselect}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          {...{ id, name, options, placeholder, isLoading, value }}
        />
      </>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

export default MySelect;
