import React, { useEffect, useCallback } from 'react';
import { Company } from '../../../../../store/Company/models';
import { ErrorActionPayload } from '../../../../../models/ErrorAction';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { getCategories } from '../../../../../store/Category/actions';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { StyledTableWrapper } from '../../../StyledComponents/StyledTableWrapper';
import { Column } from 'react-table';
import FuelBossTableContainer from '../../../../FuelBossTable';
import DeleteById from '../../../../DeleteById/DeleteById';
import EditById from '../../../../EditById/EditById';
const PlusCircle = findIconDefinition({
  prefix: 'fal',
  iconName: 'plus-circle',
});
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

interface OwnProps {
  companies: Company[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteCompany: (id: string) => void;
  navAdminType: string;
  defaultSelectedPage?: number;
  setDefaultSelectedPage?: (number: number) => void;
}

const Companies: React.FC<OwnProps> = ({
  companies,
  isLoading,
  hasError,
  errorDetails,
  deleteCompany,
  navAdminType,
  defaultSelectedPage,
  setDefaultSelectedPage,
}) => {
  const { categoriesIsLoaded } = useSelector((state: ApplicationState) => ({
    categories: state.categories.data,
    categoriesIsLoaded: state.categories.dataIsLoaded,
  }));

  const headersNew: Array<Column<Company>> = React.useMemo(
    () => [
      {
        Header: 'Category',
        accessor: (row) => row.category.categoryName,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'OrgNumber',
        accessor: 'orgNumber',
      },
      {
        Header: 'InvoiceAddress',
        accessor: 'invoiceAddress',
      },
      {
        Header: 'InvoiceEmailAddress',
        accessor: 'invoiceEmailAddress',
      },
      {
        Header: 'CompanyInBrief',
        accessor: 'companyInBrief',
      },
      {
        Header: 'Active',
        accessor: (row) => {
          if (row.active) return 'Yes';
          else return 'No';
        },
      },
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const company = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteById
                id={company.companyId ? company.companyId : ''}
                deleteMethod={deleteCompany}
                header="Delete company"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const company = props.cell.row.original;
          return (
            <div className="edit-cell">
              <EditById redirectUrl={`/${navAdminType}admin/companies/${company.companyId}/edit`} />
            </div>
          );
        },
      },
    ],
    [],
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const getCategoriesCallback = useCallback(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (!categoriesIsLoaded) {
      getCategoriesCallback();
    }
  }, [categoriesIsLoaded]);

  if (isLoading) {
    return <Loading />;
  } else if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledTableWrapper>
        <Button color="primary" size="lg" onClick={() => history.push(`/${navAdminType}admin/companies/add`)}>
          Add company <FontAwesomeIcon icon={PlusCircle} />
        </Button>
        <FuelBossTableContainer headers={headersNew} tableRows={companies} showPagination={true} defaultSelectedPage={defaultSelectedPage} setDefaultSelectedPage={setDefaultSelectedPage}/>
      </StyledTableWrapper>
    );
  }
};

export default React.memo(Companies);
