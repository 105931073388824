import React from 'react';
import styles from './MinifiedRecurringNominationList.module.scss';
import { RecurringNomination } from '../../../../../../store/RecurringNomination/models';

interface OwnProps {
  nominations: RecurringNomination[];
  activeNominationId: string;
}

const MinifiedRecurringNominationList: React.FC<OwnProps> = ({ nominations, activeNominationId }) => (
  <div className={`${styles['minified-nomination']}`}>
    <div className={styles['header']}>Recurring nominations</div>
    {nominations.map((nom) => {
      if (nom)
        return (
          <div
            className={`${styles['wrapper']} ${activeNominationId === nom.id ? styles['active'] : ''}`}
            key={nom.id}
          >
            <div className={`${styles['nomination-indicator']} ${styles[nom.fbStatus]}`}></div>
          </div>
        );
      else return null;
    })}
  </div>
);

export default MinifiedRecurringNominationList;
