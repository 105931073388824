import React from 'react';
import styles from './CountDown.module.scss';
import moment from 'moment';
import { toRelativeTime } from '@teqplay/chorus-components';
import { time } from 'console';

interface OwnProps {
  date: Date;
}

const CountDown: React.FC<OwnProps> = ({ date }) => {
  const chorusRelativeTime = toRelativeTime(moment(date).valueOf());
  return (
    <div className={styles.box}>
      <div>
        <div className={styles.first}>{chorusRelativeTime.value}</div>
        <div className={styles['the-rest']}>{chorusRelativeTime.unit}</div>
      </div>
    </div>
  );
};

export default CountDown;
