import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AuthenticationServiceLayer,
  ICompany,
  ILocation,
  IShipWithId,
  IPromptNomination,
  INominationEnquiryEvent,
  INominationEnquiry,
  IPipeline,
  IShipWithCompanyId,
} from '@teqplay/chorus-components';
import { ErrorWrapper } from './../../../../models/ErrorAction';
import { useEnquiryService, CreateEnquiry, useLocationService, CachedLocationService } from '@teqplay/chorus-components';

import { useState } from 'react';
import { ApplicationState } from '../../../../store';
import { Loading } from '../../../../components/Loading/index';
import { Error } from '../../../../components/Error/index';
import './../../../BunkerEvents/Components/ChorusIntegration/ChorusStyleOverrides.scss';
import { getSpotEnquiries } from '../../../../store/Spot/actions';
import { SpotEnquiry } from '../../../../store/Spot/models';

interface OwnProps {
  chooseSpot: (spotId: string[], spotObject: SpotEnquiry) => void;
  bunkerShips: IShipWithCompanyId[];
  bunkerPipelines: IPipeline[];
  companies: ICompany[];
  locationService: CachedLocationService;
  creatingNewSpotBool?: boolean;
  creatingSpotOnBehalfBool?: boolean;
  className?: string;
  yearMonth: string;
  serviceLayer: AuthenticationServiceLayer;
  preselectedSupplierIds?: string;
}

const NewSpotEnquiry: React.FC<OwnProps> = ({
  chooseSpot,
  bunkerShips,
  bunkerPipelines,
  companies,
  locationService,
  creatingNewSpotBool = false,
  creatingSpotOnBehalfBool = false,
  className,
  yearMonth,
  serviceLayer,
  preselectedSupplierIds,
}) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));
  const dispatch = useDispatch();
  const errorWrapperObject: ErrorWrapper = {};
  
  const getSpotCallback = React.useCallback(() => dispatch(getSpotEnquiries()), []);

  const getSpotEventIds = (spot: INominationEnquiry) => {
    let eventsByVendor = spot.eventsByVendor;
    let eventIds: string[] = [];
    for (var key in eventsByVendor) {
      if (eventsByVendor.hasOwnProperty(key)) {
        eventIds.push(eventsByVendor[key]);
      }
    }
    return eventIds;
  };

  const successCallback = (
    action: 'AMEND' | 'COUNTER' | 'CREATE' | 'CONTRACT_ADDED_AND_FINALISED' | 'STATE_UPDATE',
    updatedEnquiry: IPromptNomination | INominationEnquiryEvent | INominationEnquiry | undefined,
  ) => {
    getSpotCallback();
    if (action === 'CREATE') {
      const eventIds = getSpotEventIds(updatedEnquiry as INominationEnquiry);
      chooseSpot(eventIds, updatedEnquiry as SpotEnquiry);
    }
  };
  const [loading, setLoading] = useState(false);

  const [preselectedSpotCompanies, setPreselectedSpotCompanies] = React.useState([] as ICompany[]);

  React.useEffect(() => {
    if (preselectedSupplierIds) {
      //set preselected supplier companies
      const preCompanies = [] as ICompany[];
      const ids = preselectedSupplierIds.split(';');
      ids.forEach((id: string) => {
        const company = companies.find(x => x._id == id);
        if (company) {
          preCompanies.push(company);
        }
      });
      setPreselectedSpotCompanies(preCompanies);
    }
  }, [preselectedSupplierIds, companies]);

  const {
    enquirySelection,
    latestEnquiry,
    enquiryHistory,
    updateEnquiryState,
    updateSpotEnquiry,
    fetchEnquiryList,
    addContractAndFinaliseEnquiry,
    createNewEnquiry,
  } = useEnquiryService(
    undefined,
    serviceLayer,
    setLoading,
    () => {
      console.log('there is a newer version of the quotation available.');
    },
    successCallback,
  );

  React.useEffect(() => {
    //if (latestEnquiry && latestEnquiry.eventId) chooseSpot(latestEnquiry.eventId);
  }, [latestEnquiry]);

  if (errorWrapperObject.payload) {
    return <Error error={errorWrapperObject.payload} />;
  } else if (creatingNewSpotBool || creatingSpotOnBehalfBool) {
    return (
      <div className={className}>
        <div className={`sticky-top prompt-negotiation-wrapper`}>
          <div className="prompt-form">
            <div className="form-section create-nomination-header">
              <h2 className="form-header">Create new quotation request</h2>
            </div>
          </div>
        </div>
        <CreateEnquiry
          className={'new-enquiry'}
          user={auth.chorusUserProfile}
          authenticationService={serviceLayer}
          locationService={locationService}
          createNewEnquiry={createNewEnquiry}
          showDeliveryModeSelection={true}
          companies={companies}
          bunkerShips={bunkerShips}
          pipelines={bunkerPipelines}
          preselectedSuppliers={preselectedSpotCompanies && preselectedSpotCompanies.length > 0 ? preselectedSpotCompanies : undefined}
        />
      </div>
    );
  } else if (loading) {
    return <Loading type="Chorus API" />;
  } else {
    return null;
  }
};

export default NewSpotEnquiry;
