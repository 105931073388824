import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { FieldPermissionActions, FIELDPERMISSIONRECURRING_LOADING, FIELDPERMISSIONRECURRING_FETCH_ERROR, GET_FIELDPERMISSIONRECURRING } from './types';
import { FieldPermissionRecurringResponseModel } from './models';

export const getFieldPermissionRecurring = (formId: string) => (dispatch: Dispatch<FieldPermissionActions>) => {
  dispatch({
    type: FIELDPERMISSIONRECURRING_LOADING,
  });

  new api<FieldPermissionRecurringResponseModel>({
    dispatch,
    errorType: FIELDPERMISSIONRECURRING_FETCH_ERROR,
  })
    .get(`/api/perm/forms/${formId}`)
    .then((response) => {
      dispatch({
        type: GET_FIELDPERMISSIONRECURRING,
        payload: response,
      });
    });
};

