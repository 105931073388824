import * as TYPE from './types';
import { ApplicationState } from '..';

export const fleetVesselReducer = (
  state: ApplicationState['companyFleet'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.FleetVesselActions,
): ApplicationState['companyFleet'] => {
  switch (action.type) {
    case TYPE.FLEETVESSEL_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_FLEETVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorDetails: undefined,
        dataIsLoaded: true,
      };
    case TYPE.FLEETVESSEL_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_FLEETVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
        toastData: {
          content: 'Vessel successfully added',
          type: 'success',
        },
      };
    case TYPE.FLEETVESSEL_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `The vessel already exists in your company fleet.`
            : `We could not add a new vessel. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.DELETE_FLEETVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
        toastData: {
          content: 'Vessel deleted',
          type: 'info',
        },
      };
    case TYPE.EDIT_FLEETVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
        toastData: {
          content: 'Vessel successfully edited',
          type: 'success',
        },
      };
    case TYPE.FLEETVESSEL_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.SETCHARTERABLE_FLEETVESSEL:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
        toastData: {
          content: 'Vessel charterable status changed',
          type: 'success',
        },
      };
    case TYPE.FLEETVESSEL_SETCHARTERABLE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: `We could not change the charterable status. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.FLEETVESSEL_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
  }
  return { ...state };
};
