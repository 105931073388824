export default class FetchError extends Error {
  public status: number; // for example 400
  public error?: string; // for example "Bad Request"
  constructor(status: number, error?: string, message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.error = error;
    this.status = status;
  }
  public toString() {
    return `FetchError: ${this.error || this.status}. ${this.message}`;
  }
}
