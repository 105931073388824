import { ErrorActionPayload } from '../../models/ErrorAction';
import { CompanyLogosWrapperModel } from './models';

export const COMPANY_LOGOS_LOADING = 'companylogos/loading';
export type COMPANY_LOGOS_LOADING = typeof COMPANY_LOGOS_LOADING;

export const GET_COMPANY_LOGOS = 'companylogos/get';
export type GET_COMPANY_LOGOS = typeof GET_COMPANY_LOGOS;

export const COMPANY_LOGOS_FETCH_ERROR = 'companylogos/fetchError';
export type COMPANY_LOGOS_FETCH_ERROR = typeof COMPANY_LOGOS_FETCH_ERROR;

export type CompanyLogosActions =
  | {
      type: GET_COMPANY_LOGOS;
      payload: CompanyLogosWrapperModel['data'];
    }
  | {
      type: COMPANY_LOGOS_LOADING;
    }
  | {
      type: COMPANY_LOGOS_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
