import * as React from 'react';
import styles from './NavMenu.module.scss';
import logo from './FuelBoss_icon.png';
import { ReactComponent as CaptainIcon } from './LBV-captain.svg';
import { ReactComponent as TerminalIcon } from './Terminal-operator.svg';

import { NavLink } from './Components/NavLink/NavLink';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  checkCompanyAdmin,
  checkBunkerVesselCrew,
  checkReceivingVesselCrew,
  checkSupplier,
  checkTerminalOperator,
  checkCaptainScheduler,
  checkReceivingCaptainScheduler,
  checkCaptains,
  canAccessRecurringNominations,
} from '../../../../helpers/roleChecks';
import { checkSystemAdmin } from './../../../../helpers/roleChecks';
import { useLocation } from 'react-router';

const layer = findIconDefinition({ prefix: 'fal', iconName: 'layer-group' });
const gas = findIconDefinition({ prefix: 'fal', iconName: 'gas-pump' });
const ship = findIconDefinition({ prefix: 'fal', iconName: 'ship' });
const toolbox = findIconDefinition({ prefix: 'fal', iconName: 'toolbox' });
const anchor = findIconDefinition({ prefix: 'fal', iconName: 'anchor' });
const building = findIconDefinition({ prefix: 'fal', iconName: 'building' });
const info = findIconDefinition({ prefix: 'fal', iconName: 'info' });
const bullseye = findIconDefinition({ prefix: 'fal', iconName: 'bullseye' });
const calendar = findIconDefinition({ prefix: 'fal', iconName: 'calendar' });
const analytics = findIconDefinition({ prefix: 'fal', iconName: 'analytics' });
const globe = findIconDefinition({ prefix: 'fal', iconName: 'globe' });
const compass = findIconDefinition({ prefix: 'fal', iconName: 'compass' });
const repeat = findIconDefinition({ prefix: 'fal', iconName: 'repeat-alt' });
const archive = findIconDefinition({ prefix: 'fal', iconName: 'cabinet-filing'})

const NavMenu: React.FunctionComponent = () => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));

  const location = useLocation();

  return (
    <aside className={styles['sidebar']}>
      <span className={styles.sidebarIndicator}></span>
      <div className={styles.sidebarNavs}>
        <NavLink title="" route="/">
          <img src={logo} alt="Logo" className={styles.logo} />
        </NavLink>
        <NavLink title="Overview" route="/overview">
          <FontAwesomeIcon icon={layer} />
        </NavLink>
        <NavLink title="Bunker events" route="/bunkerevents">
          <FontAwesomeIcon icon={gas} />
        </NavLink>
        {canAccessRecurringNominations(auth) && (
          <NavLink title={'Recurring nominations'} route="/recurringnominations">
            <FontAwesomeIcon icon={repeat} />
          </NavLink>
        )}
        {!checkReceivingVesselCrew(auth) &&
          !checkBunkerVesselCrew(auth) &&
          !checkTerminalOperator(auth) &&
          !checkCaptainScheduler(auth) &&
          !checkReceivingCaptainScheduler(auth) && (
            <NavLink title="Request quotation" route="/requestquotation">
              <FontAwesomeIcon icon={bullseye} />
            </NavLink>
          )}
        <NavLink title="Bunker availability" route="/bunkeravailability">
          <FontAwesomeIcon icon={compass} />
        </NavLink>
        <NavLink title="AIS" route="/AIS">
          <FontAwesomeIcon icon={globe} />
        </NavLink>
        {checkSupplier(auth) && (
          <NavLink title="Schedule" route="/schedule">
            <FontAwesomeIcon icon={calendar} />
          </NavLink>
        )}
          <NavLink title="Document Archive" route="/documentarchive">
          <FontAwesomeIcon icon={archive} />
        </NavLink>
        {!checkCaptains(auth) && !checkTerminalOperator(auth) && (
          <NavLink title="Company profile" route="/companyadmin">
            <FontAwesomeIcon icon={building} />
          </NavLink>
        )}
    

        {checkSystemAdmin(auth) && (
          <>
            <NavLink title="System admin" route="/systemadmin">
              <FontAwesomeIcon icon={anchor} />
            </NavLink>
            <NavLink title="Toolbox" route="/toolbox">
              <FontAwesomeIcon icon={toolbox} />
            </NavLink>
          </>
        )}

        <NavLink title="Analytics & insights" route="/analytics">
          <FontAwesomeIcon icon={analytics} />
        </NavLink>

        <NavLink title="Help" route="/help">
          <FontAwesomeIcon icon={info} />
        </NavLink>
      </div>
    </aside>
  );
};
export default NavMenu;
