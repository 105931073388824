import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { PaginatedSpotResponseModel, SpotResponseModel } from './models';
import { SpotActions, SPOT_LOADING, SPOT_FETCH_ERROR, GET_SPOT } from './types';

export const getSpotEnquiries = () => (dispatch: Dispatch<SpotActions>) => {
  dispatch({
    type: SPOT_LOADING,
  });
  const url = `api/chorus/GetAllSpotEnquiries/` + '2020-01-01T00:00:00' + `/` + '2099-01-01T00:00:00' + `/spots/`;
  new api<SpotResponseModel>({
    dispatch,
    errorType: SPOT_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: SPOT_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_SPOT,
          payload: {data: response},
        });
      }
    });
};


export const getSpotEnquiriesPaginated = (sortByNeedsAction: boolean, pageIndex: number, pageSize: number) => (dispatch: Dispatch<SpotActions>) => {
  dispatch({
    type: SPOT_LOADING,
  });
  const url = `api/chorus/GetAllSpotEnquiriesPaginated/spots/?sortByNeedsAction=${sortByNeedsAction}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
  new api<PaginatedSpotResponseModel>({
    dispatch,
    errorType: SPOT_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      //@ts-ignore
      if (response.status) {
        dispatch({
          type: SPOT_FETCH_ERROR,
          //@ts-ignore
          payload: {
            clientFault: true,
            serverFault: false,
            //@ts-ignore
            statusCode: response.status,
            //@ts-ignore
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: GET_SPOT,
          payload: {data: response.page, metadata: {...response}},
        });
      }
    });
};
