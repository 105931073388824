import * as TYPE from './types';

import { ApplicationState } from '..';
import { AISListColumn } from './models';

export const aisListColumnReducer = (
  state: ApplicationState['ais_listcolumn'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: {} as AISListColumn,
    metadata: {},
  },
  action: TYPE.AISListColumnActions,
): ApplicationState['ais_listcolumn'] => {
  switch (action.type) {
    case TYPE.AISLISTCOLUMN_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case TYPE.GET_AISLISTCOLUMN:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.AISLISTCOLUMN_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
        toastData:
          action.payload.statusCode !== 404
            ? {
                content: action.payload.message
                  ? action.payload.message
                  : 'Something went wrong! We could not fetch you list view.',
                type: 'error',
              }
            : undefined,
      };
    case TYPE.ADD_AISLISTCOLUMN:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Your list view was saved',
          type: 'info',
        },
      };

    case TYPE.AISLISTCOLUMN_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not save your list view.',
          type: 'error',
        },
      };
    case TYPE.EDIT_AISLISTCOLUMN:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Your list view was saved',
          type: 'info',
        },
      };
    case TYPE.AISLISTCOLUMN_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not save your list view.',
          type: 'error',
        },
      };
    case TYPE.AISLISTCOLUMN_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    default:
      return { ...state };
  }
};
