import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import styles from './Schedule.module.scss';
import { ApplicationState } from '../../store';
import { getBunkerShips } from '../../store/BunkerShip/actions';
import { getBunkerPipelines } from '../../store/BunkerPipeline/actions';
import { getChorusCompanies } from '../../store/ChorusCompany/actions';
import { getAuth } from '../../store/Auth/actions';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import { useEffect } from 'react';
import {
  usePromptCollectionService,
  useContractService,
  TimelineFilter,
  SchedulingTimeline,
  PromptEventSelector,
  ILocation,
  useLocationService,
  CachedLocationService,
  usePromptEventService,
  IPromptEvent,
  IPromptTentative,
  useGlobalDataService,
} from '@teqplay/chorus-components';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { CompanyDeliveryMode } from '../../models/CompanyDeliveryMode';
import { DeliveryModes } from '../../models/DeliveryMode';
import { getFuelbossLocations } from '../../store/FuelbossLocations/actions';
import { convertLocationType } from '../../helpers/fBtoChorusType';
import { isBunkerCaptain } from '@teqplay/chorus-components';
import { checkBunkerVesselCrew, checkTerminalOperator } from '../../helpers/roleChecks';

interface OwnProps {
  serviceLayer: AuthenticationServiceLayer;
  locationService: CachedLocationService;
}

const Schedule: React.FunctionComponent<OwnProps> = ({ serviceLayer, locationService }) => {
  const {
    auth,
    authIsLoaded,
    fuelBossLocation,
    fuelBossLocationIsLoaded,
    fuelBossLocationLoading,
    chorusCompanyLoading,
    chorusCompany,
    chorusCompanyIsLoaded,
    bunkerShipLoading,
    bunkerShips,
    bunkerShipIsLoaded,
    bunkerPipelineLoading,
    bunkerPipelineError,
    bunkerPipelineErrorDetails,
    bunkerPipelines,
    bunkerPipelineIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
    fuelBossLocationLoading: state.fuelbosslocations.loading,
    fuelBossLocation: state.fuelbosslocations.data,
    fuelBossLocationIsLoaded: state.fuelbosslocations.dataIsLoaded === true,
    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompanyErrorDetails: state.choruscompany.errorDetails,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded === true,
    bunkerShipLoading: state.bunkership.loading,
    bunkerShipError: state.bunkership.error,
    bunkerShipErrorDetails: state.bunkership.errorDetails,
    bunkerShips: state.bunkership.data,
    bunkerShipIsLoaded: state.bunkership.dataIsLoaded === true,
    bunkerPipelineLoading: state.bunkerpipeline.loading,
    bunkerPipelineError: state.bunkerpipeline.error,
    bunkerPipelineErrorDetails: state.bunkerpipeline.errorDetails,
    bunkerPipelines: state.bunkerpipeline.data,
    bunkerPipelineIsLoaded: state.bunkerpipeline.dataIsLoaded === true,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const getLocationCallback = React.useCallback(() => dispatch(getFuelbossLocations()), []);

  const getChorusCompaniesCallback = React.useCallback(() => dispatch(getChorusCompanies()), []);

  const getBunkerShipCallback = React.useCallback(() => dispatch(getBunkerShips()), []);

  const getBunkerPipelineCallback = React.useCallback(() => dispatch(getBunkerPipelines()), []);

  React.useEffect(() => {
    if (!bunkerShipIsLoaded) {
      getBunkerShipCallback();
    }

    if (!bunkerPipelineIsLoaded) {
      getBunkerPipelineCallback();
    }

    if (!chorusCompanyIsLoaded) {
      getChorusCompaniesCallback();
    }

    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  const [scheduleSliderMinified, setScheduleSliderMinified] = React.useState(false);

  const routes = history.location.pathname.split('/');

  useEffect(() => {
    if (routes.length > 2) {
      return;
    }
  }, []);

  const [, , baseRoute] = routes;
  const isRootRoute = (routes || []).length <= 3;

  const getAllDeliveryModes = () => [
    DeliveryModes.Ship,
    DeliveryModes.Truck,
    DeliveryModes.Pipe,
    DeliveryModes.Container,
  ];

  const getEnabledDeliveryModes = () => {
    const modes: string[] = [];

    if (auth.company && auth.company.companyDeliveryModes) {
      auth.company.companyDeliveryModes.forEach((element: CompanyDeliveryMode) => {
        modes.push(element.deliveryMode.mode);
      });
    }
    // for bunker captains only ship mode enabled in the schedule
    if(checkBunkerVesselCrew(auth) && modes.some(u => u == DeliveryModes.Ship)){
      return [DeliveryModes.Ship];
    }
    // for terminal operators only pipe mode enabled in the schedule
    if(checkTerminalOperator(auth) && modes.some(u => u == DeliveryModes.Pipe)){
      return [DeliveryModes.Pipe];
    }
    return modes;
  };

  const [chorusLocations, setChorusLocations] = React.useState<ILocation[]>([]);
  const [enabledDeliveryModes, setEnabledDeliveryModes] = React.useState(getEnabledDeliveryModes());
  const [disabledDeliveryModes, setDisabledDeliveryModes] = React.useState(
    getAllDeliveryModes().filter((item) => enabledDeliveryModes.indexOf(item) < 0),
  );
  const [selectedEventId, setSelectedEventId] = React.useState<string | undefined>(undefined);

  const {
    eventsCollections,
    timelineReceivingShips,
    fetchEventsCollections,
    createNewPromptEvent,
    updateTimelineEvent,
    timelineFiltering,
    loading,
  } = usePromptCollectionService(
    serviceLayer,
    auth.chorusUserProfile,
    false,
    auth.chorusUserProfile.companyId ? auth.chorusUserProfile.companyId : '',
    (action, error) => {
      console.log(action, error);
    },
    (action, error) => {
      console.log(action, error);
    },
    bunkerShips,
    bunkerPipelines,
    undefined,
    disabledDeliveryModes,
  );

  const { bunkerShips: newBunkerShips, pipelines } = useGlobalDataService(serviceLayer, auth.chorusUserProfile);
  const { promptEvent, promptEventAction, readOnly, convertTentativeToNomination } = usePromptEventService(
    selectedEventId,
    serviceLayer,
    auth.chorusUserProfile,
    undefined,
    false,
  );

  const { vendorsWithContract } = useContractService(serviceLayer);

  const handleRemoveEvent = () => {
    if (promptEvent) {
      promptEventAction('remove', promptEvent);
    }
  };

  const handleSelectEventType = (selectedEventType: string) => {
    setSelectedEventId(undefined);
  };

  const handleFormEditComplete = (event: IPromptEvent, isNew: boolean) => {
    if (!event) {
      return;
    }
    promptEventAction(isNew ? 'create' : 'update', event);
  };

  const handleConvertTentativeToNomination = async (event: IPromptEvent) => {
    const nomination = await convertTentativeToNomination(event as IPromptTentative);
    if (nomination) {
      console.log('converted to nominations', nomination);
    }
  };
  const ownPipeline = bunkerPipelines.find((x) => x._id == auth.chorusUserProfile.pipelineId);
  const ownBunkerShip = bunkerShips.find((x) => x._id == auth.chorusUserProfile.bunkerShipId);
  const selectedBunkerShips = checkBunkerVesselCrew(auth)
    ? ownBunkerShip
      ? [ownBunkerShip]
      : []
    : checkTerminalOperator(auth)
    ? []
    : bunkerShips;
  const selectedPipelines = checkTerminalOperator(auth)
    ? ownPipeline
      ? [ownPipeline]
      : []
    : checkBunkerVesselCrew(auth)
    ? []
    : bunkerPipelines;

  return (
    <div className={styles['schedule']}>
      <div
        className={`${styles['schedule-wrapper']} ${
          scheduleSliderMinified ? styles['isMinified'] : styles['isNotMinified']
        }`}
      >
        {' '}
        <div className={styles['schedule-sidebar']}>
          {!scheduleSliderMinified ? (
            <TimelineFilter
              timelineFiltering={timelineFiltering}
              bunkerships={selectedBunkerShips}
              pipelines={selectedPipelines}
              isCollapsed={false}
              disabledDeliveryModes={disabledDeliveryModes}
            />
          ) : (
            <div>
              <p className={styles['minified-schedule']}>Bunker assets</p>
              <TimelineFilter
                timelineFiltering={timelineFiltering}
                bunkerships={selectedBunkerShips}
                pipelines={selectedPipelines}
                isCollapsed={true}
                hideDeliveryModeFilter={true}
                disabledDeliveryModes={disabledDeliveryModes}
              />
            </div>
          )}
        </div>
        <CollapseButton
          direction={scheduleSliderMinified ? 'right' : 'left'}
          onClick={() => {
            setScheduleSliderMinified(!scheduleSliderMinified);
          }}
          className={styles['schedule-collapse']}
        />
      </div>
      <div className={`${styles['schedule-content']} scroller-wrapper`}>
        <div className={styles['schedule-chorus-wrapper']}>
          <PromptEventSelector
            authenticationService={serviceLayer}
            user={auth.chorusUserProfile}
            bunkerShips={newBunkerShips}
            locationService={locationService}
            vendorsWithContract={vendorsWithContract}
            companies={chorusCompany}
            readOnly={readOnly}
            selectedPromptEvent={promptEvent}
            closeSelectedEvent={() => setSelectedEventId(undefined)}
            handleSelectEventType={handleSelectEventType}
            //loadingFacilities={}
            convertTentativeToNomination={handleConvertTentativeToNomination}
            handleRemoveEvent={handleRemoveEvent}
            handleFormEditComplete={handleFormEditComplete}
          ></PromptEventSelector>
          {
            <SchedulingTimeline
              authenticationService={serviceLayer}
              companies={chorusCompany}
              locationService={locationService}
              bunkerShips={timelineFiltering.bunkerAssets.bunkerShips}
              pipelines={timelineFiltering.bunkerAssets.pipelines}
              userProfile={auth.chorusUserProfile}
              eventsCollection={eventsCollections}
              receivingShips={timelineReceivingShips}
              sandboxMode={false}
              fetchEventsCollections={fetchEventsCollections}
              loading={loading}
              navigateToTimelineEvent={(type, event, sandboxId) => {
                if (type == 'nomination') {
                  history.push(`/bunkerevents/bunker/${event?.eventId}`);
                } else if (type == 'spot') {
                  history.push(`/requestquotation/spots/${event?.eventId}`);
                } else {
                  //in case of other event types open the even inside the calendar component
                  if (event && event._id) setSelectedEventId(event?._id);
                }
              }}
              updateTimelineEvent={updateTimelineEvent}
              createNewPromptEvent={createNewPromptEvent}
              showDataExport={false}
              timelineFiltering={timelineFiltering}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default Schedule;
