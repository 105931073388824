import { ReportWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const REPORT_LOADING = 'report/loading';
export type REPORT_LOADING = typeof REPORT_LOADING;

export const GET_REPORTS = 'report/get';
export type GET_REPORTS = typeof GET_REPORTS;

export const REPORTS_FETCH_ERROR = 'report/fetchError';
export type REPORTS_FETCH_ERROR = typeof REPORTS_FETCH_ERROR;

export const ADD_REPORT = 'report/add';
export type ADD_REPORT = typeof ADD_REPORT;

export const REPORTS_ADD_ERROR = 'report/addError';
export type REPORTS_ADD_ERROR = typeof REPORTS_ADD_ERROR;

export const REPORTS_DELETE_ERROR = 'report/deleteError';
export type REPORTS_DELETE_ERROR = typeof REPORTS_DELETE_ERROR;

export const REPORTS_EDIT_ERROR = 'report/editError';
export type REPORTS_EDIT_ERROR = typeof REPORTS_EDIT_ERROR;

export const DELETE_REPORT = 'report/delete';
export type DELETE_REPORT = typeof DELETE_REPORT;

export const EDIT_REPORT = 'report/edit';
export type EDIT_REPORT = typeof EDIT_REPORT;

export const REPORTS_CLEAR_TOAST = 'reports/ClearToast';
export type REPORTS_CLEAR_TOAST = typeof REPORTS_CLEAR_TOAST;

export const GET_REPORT_CONFIG = 'report/getConfig';
export type GET_REPORT_CONFIG = typeof GET_REPORT_CONFIG;

export const REPORT_CONFIG_FETCH_ERROR = 'report/getConfigError';
export type REPORT_CONFIG_FETCH_ERROR = typeof REPORT_CONFIG_FETCH_ERROR;

export const TOGGLE_VISIBILITY_REPORT = 'report/toggle';
export type TOGGLE_VISIBILITY_REPORT = typeof TOGGLE_VISIBILITY_REPORT;

export const REPORTS_TOGGLE_VISIBILITY_ERROR = 'report/toggleError';
export type REPORTS_TOGGLE_VISIBILITY_ERROR = typeof REPORTS_TOGGLE_VISIBILITY_ERROR;

export type ReportActions =
  | {
      type: GET_REPORTS;
      payload: ReportWrapperModel['data'];
    }
  | {
      type: REPORT_LOADING;
    }
  | {
      type: REPORTS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: REPORTS_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: REPORTS_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: REPORTS_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_REPORT;
      payload: ReportWrapperModel['data'];
    }
  | {
      type: DELETE_REPORT;
      payload: ReportWrapperModel['data'];
    }
  | {
      type: EDIT_REPORT;
      payload: ReportWrapperModel['data'];
    }
  | {
      type: REPORTS_CLEAR_TOAST;
  }| {
      type: TOGGLE_VISIBILITY_REPORT;
      payload: ReportWrapperModel['data'];
  }| {
      type: REPORTS_TOGGLE_VISIBILITY_ERROR;
      payload: ErrorActionPayload;
    };
