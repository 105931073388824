import { DocumentFilterBookmark, DocumentFilterBookmarkResponseModel, DocumentFilterBookmarkWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const DOCUMENTFILTERBOOKMARKS_LOADING = 'documentFilterBookmark/loading';
export type DOCUMENTFILTERBOOKMARKS_LOADING = typeof DOCUMENTFILTERBOOKMARKS_LOADING;

export const GET_DOCUMENTFILTERBOOKMARKS = 'documentFilterBookmark/get';
export type GET_DOCUMENTFILTERBOOKMARKS = typeof GET_DOCUMENTFILTERBOOKMARKS;

export const DOCUMENTFILTERBOOKMARKS_FETCH_ERROR = 'documentFilterBookmark/fetchError';
export type DOCUMENTFILTERBOOKMARKS_FETCH_ERROR = typeof DOCUMENTFILTERBOOKMARKS_FETCH_ERROR;

export const ADD_DOCUMENTFILTERBOOKMARK = 'documentFilterBookmark/add';
export type ADD_DOCUMENTFILTERBOOKMARK = typeof ADD_DOCUMENTFILTERBOOKMARK;

export const DOCUMENTFILTERBOOKMARK_ADD_ERROR = 'documentFilterBookmark/addError';
export type DOCUMENTFILTERBOOKMARK_ADD_ERROR = typeof DOCUMENTFILTERBOOKMARK_ADD_ERROR;

export const DOCUMENTFILTERBOOKMARK_DELETE_ERROR = 'documentFilterBookmark/deleteError';
export type DOCUMENTFILTERBOOKMARK_DELETE_ERROR = typeof DOCUMENTFILTERBOOKMARK_DELETE_ERROR;

export const DOCUMENTFILTERBOOKMARK_EDIT_ERROR = 'documentFilterBookmark/editError';
export type DOCUMENTFILTERBOOKMARK_EDIT_ERROR = typeof DOCUMENTFILTERBOOKMARK_EDIT_ERROR;

export const DELETE_DOCUMENTFILTERBOOKMARK = 'documentFilterBookmark/delete';
export type DELETE_DOCUMENTFILTERBOOKMARK = typeof DELETE_DOCUMENTFILTERBOOKMARK;

export const EDIT_DOCUMENTFILTERBOOKMARK = 'documentFilterBookmark/edit';
export type EDIT_DOCUMENTFILTERBOOKMARK = typeof EDIT_DOCUMENTFILTERBOOKMARK;

export const DOCUMENTFILTERBOOKMARK_CLEAR_TOAST = 'documentFilterBookmark/clearToast'
export type DOCUMENTFILTERBOOKMARK_CLEAR_TOAST = typeof DOCUMENTFILTERBOOKMARK_CLEAR_TOAST;

export type DocumentFilterBookmarkActions =
  | {
      type: GET_DOCUMENTFILTERBOOKMARKS;
      payload: DocumentFilterBookmarkWrapperModel['data'];
    }
  | {
      type: DOCUMENTFILTERBOOKMARKS_LOADING;
    }
  | {
      type: DOCUMENTFILTERBOOKMARKS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DOCUMENTFILTERBOOKMARK_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DOCUMENTFILTERBOOKMARK_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DOCUMENTFILTERBOOKMARK_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_DOCUMENTFILTERBOOKMARK;
      payload: DocumentFilterBookmark;
    }
  | {
      type: DELETE_DOCUMENTFILTERBOOKMARK;
      payload: string;
    }
  | {
      type: EDIT_DOCUMENTFILTERBOOKMARK;
      payload: DocumentFilterBookmark;
    } | {
      type: DOCUMENTFILTERBOOKMARK_CLEAR_TOAST
    };
