import * as TYPE from './types';
import { ApplicationState } from '..';

export const companyLogoReducer = (
  state: ApplicationState['company_logo'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.CompanyActions,
): ApplicationState['company_logo'] => {
  switch (action.type) {
    case TYPE.COMPANY_LOGO_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_COMPANY_LOGO:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.COMPANY_LOGO_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.DELETE_COMPANY_LOGO:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Company logo deleted',
          type: 'info',
        },
      };
    case TYPE.DELETE_COMPANY_LOGO_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not delete the company logo. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.COMPANY_LOGO_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.ADD_COMPANY_LOGO:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case TYPE.ADD_COMPANY_LOGO_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content:
            'Something went wrong! We could not add the company logo. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    default:
      return { ...state };
  }
};
