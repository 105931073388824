import { PaginationMeta } from '../../helpers/PaginationMeta';
import { ErrorActionPayload } from '../../models/ErrorAction';
import { NominationSchedule, NominationScheduleWrapperModel } from './models';

export const NOMINATIONSCHEDULE_LOADING = 'nominationSchedule/loading';
export type NOMINATIONSCHEDULE_LOADING = typeof NOMINATIONSCHEDULE_LOADING;

export const GET_NOMINATIONSCHEDULE = 'nominationSchedule/get';
export type GET_NOMINATIONSCHEDULE = typeof GET_NOMINATIONSCHEDULE;

export const DELETE_NOMINATIONSCHEDULE = 'nominationSchedule/delete';
export type DELETE_NOMINATIONSCHEDULE = typeof DELETE_NOMINATIONSCHEDULE;

export const NOMINATIONSCHEDULE_FETCH_ERROR = 'nominationSchedule/fetchError';
export type NOMINATIONSCHEDULE_FETCH_ERROR = typeof NOMINATIONSCHEDULE_FETCH_ERROR;

export const NOMINATIONSCHEDULE_DELETE_ERROR = 'nominationSchedule/deleteError';
export type NOMINATIONSCHEDULE_DELETE_ERROR = typeof NOMINATIONSCHEDULE_DELETE_ERROR;

export const UPDATE_NOMINATIONSCHEDULE = 'nominationSchedule/update';
export type UPDATE_NOMINATIONSCHEDULE = typeof UPDATE_NOMINATIONSCHEDULE;

export const UPDATE_NOMINATIONSCHEDULE_ERROR = 'nominationSchedule/updateError';
export type UPDATE_NOMINATIONSCHEDULE_ERROR = typeof UPDATE_NOMINATIONSCHEDULE_ERROR;

//export const ADD_NOMINATIONSCHEDULE = 'nominationSchedule/add';
//export type ADD_NOMINATIONSCHEDULE = typeof ADD_NOMINATIONSCHEDULE;

//export const ADD_NOMINATIONSCHEDULE_ERROR = 'nominationSchedule/updateError';
//export type ADD_NOMINATIONSCHEDULE_ERROR = typeof ADD_NOMINATIONSCHEDULE_ERROR;

export const CREATENOMINATION_NOMINATIONSCHEDULE = 'nominationSchedule/createNomination';
export type CREATENOMINATION_NOMINATIONSCHEDULE = typeof CREATENOMINATION_NOMINATIONSCHEDULE;

export const CREATENOMINATION_NOMINATIONSCHEDULE_ERROR = 'nominationSchedule/createNominationError';
export type CREATENOMINATION_NOMINATIONSCHEDULE_ERROR = typeof CREATENOMINATION_NOMINATIONSCHEDULE_ERROR;

export const NOMINATIONSCHEDULE_CLEAR_TOAST = 'nominationSchedule/clearToast';
export type NOMINATIONSCHEDULE_CLEAR_TOAST = typeof NOMINATIONSCHEDULE_CLEAR_TOAST;

export type NominationScheduleActions =
  | {
      type: GET_NOMINATIONSCHEDULE;
      payload: { data: NominationSchedule[]; metaData?: PaginationMeta };
    }
  | {
      type: DELETE_NOMINATIONSCHEDULE;
      payload: { data: NominationSchedule[]; metaData?: PaginationMeta };
    }
  | {
      type: NOMINATIONSCHEDULE_LOADING;
    }
  | {
      type: NOMINATIONSCHEDULE_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: NOMINATIONSCHEDULE_CLEAR_TOAST;
    }
  | {
      type: NOMINATIONSCHEDULE_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: UPDATE_NOMINATIONSCHEDULE;
      payload: { data: NominationSchedule[]; metaData?: PaginationMeta };
    }
  | {
      type: UPDATE_NOMINATIONSCHEDULE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: CREATENOMINATION_NOMINATIONSCHEDULE;
      payload: { data: NominationSchedule[]; metaData?: PaginationMeta };
    }
  | {
      type: CREATENOMINATION_NOMINATIONSCHEDULE_ERROR;
      payload: ErrorActionPayload;
    };
