import { Dispatch } from 'redux';
import * as TYPE from './types';
import { api } from '../../helpers/api';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { ContractResponseModel, ContractNotificationResponseModel, Contract } from './models';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating contract...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Contract updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating contract...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Contract created successfully</ModalResponse>;

const deleteLoaderModal: ReactElement = <ModalResponse status="loading">Deactivating contract...</ModalResponse>;
const deleteSuccessModal: ReactElement = (
  <ModalResponse status="success">Contract deactivated successfully</ModalResponse>
);

const errorModalGeneral = <ModalResponse status="error">There has been an error</ModalResponse>;
const errorModalConflict = (
  <ModalResponse status="error">
    Contract with ContractRef/CustomerId/SupplierId already exists. Please use a different ContractRef
  </ModalResponse>
);
const errorModalPermission = <ModalResponse status="error">Permission denied</ModalResponse>;

export const getContracts = () => (dispatch: Dispatch<TYPE.ContractActions>) => {
  dispatch({
    type: TYPE.CONTRACT_LOADING,
  });

  new api<ContractResponseModel>({
    dispatch,
    errorType: TYPE.CONTRACTS_FETCH_ERROR,
  })
    .get(`/api/Contract`)
    .then((response) => {
      dispatch({
        type: TYPE.GET_CONTRACTS,
        payload: response,
      });
    });
};

export const deleteContract = (id: string) => (dispatch: Dispatch<TYPE.ContractActions>) => {
  dispatch({
    type: TYPE.CONTRACT_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: deleteLoaderModal });
  const url = `/api/Contract/${id}`;

  new api<ContractResponseModel>({
    dispatch,
    errorType: TYPE.DELETE_CONTRACT_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: TYPE.DELETE_CONTRACT,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: deleteSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModalGeneral });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const updateContract = (contract: Contract, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.ContractActions>,
) => {
  dispatch({
    type: TYPE.CONTRACT_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/Contract/${contract.contractId}`;

  new api<ContractResponseModel>({
    dispatch,
    errorType: TYPE.UPDATE_CONTRACT_ERROR,
  })
    .put(url, contract)
    .then((response) => {
      dispatch({
        type: TYPE.UPDATE_CONTRACT,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModalGeneral });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const addContract = (contract: Contract, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.ContractActions>,
) => {
  dispatch({
    type: TYPE.CONTRACT_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<ContractResponseModel>({
    dispatch,
    errorType: TYPE.ADD_CONTRACT_ERROR,
  })
    .post(`/api/Contract`, contract)
    .then((response) => {
      dispatch({
        type: TYPE.ADD_CONTRACT,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch((error) => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModalGeneral });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      return Promise.reject();
    });
};

export const contractsClearToast = () => (dispatch: Dispatch<TYPE.ContractActions>) => {
  dispatch({
    type: TYPE.CONTRACT_CLEAR_TOAST,
  });
};

export const notifyContract = (id: string) => (dispatch: Dispatch<TYPE.ContractActions>) => {
  const url = `/api/Contract/notify/${id}`;
  new api<ContractNotificationResponseModel>({
    dispatch,
    errorType: TYPE.NOTIFY_CONTRACT_ERROR,
  })
    .post(url, id)
    .then((response) => {
      dispatch({
        type: TYPE.NOTIFY_CONTRACT,
        payload: response,
      });
    });
};
