import React, { useState, useCallback, useEffect } from 'react';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { getVessels } from '../../store/Vessels/actions';
import { FormGroup, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Vessel } from '../../store/Vessels/models';
import SearchVesselResult from './Components/SearchVesselResult/SearchVesselResult';
import styles from './SearchVessel.module.scss';
import ShipsLogo from '../../svgs/add-ship.svg';
import { FleetCharterer } from '../../store/CompanyCharteredFleet/models';

interface OwnProps {
  chooseVesselMethod: (vessel: Vessel) => void;
  hidden?: boolean;
  mapMyFleet?: boolean;
  buttonStyle?: string;
  isCompanyAdmin?: boolean;
  isSupplier?: boolean;
  companyCharteredFleet?: FleetCharterer[];
}

const SearchVessel: React.FC<OwnProps> = ({
  chooseVesselMethod,
  hidden = false,
  mapMyFleet = false,
  buttonStyle,
  isCompanyAdmin,
  isSupplier,
  companyCharteredFleet,
}) => {
  const { vessels, vesselsIsLoaded } = useSelector((state: ApplicationState) => ({
    vessels: state.vessels.data,
    vesselsIsLoaded: state.vessels.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const getVesselsCallback = useCallback(() => dispatch(getVessels()), []);

  const [suggestions, setSuggestions] = useState<Vessel[]>([]);
  const [activeVessel, setActiveVessel] = useState(0);
  const [chosenVessel, setChosenVessel] = useState('');
  const [vesselObj, setVesselObj] = useState<Vessel>();
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const checkVesselInFleet = () => {
    if (companyCharteredFleet && vesselObj) {
      const existsInFleet = companyCharteredFleet.find((x) => x.fleetVessel?.vessel.imo == vesselObj.imo);
      if (existsInFleet) {
        toggle();
        return;
      }
    }
    addVesselClick();
  };

  let StyledButton: React.CSSProperties = {
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    height: '50px',
    padding: '0 20px 3px 15px',
  } as React.CSSProperties;
  let StyledInput: React.CSSProperties = { height: '50px', width: '400px', borderRadius: '0' } as React.CSSProperties;
  let StyledInputParent: React.CSSProperties = {
    flex: '1',
    display: 'flex',
    height: '46px',
    whiteSpace: 'nowrap',
  } as React.CSSProperties;
  let StyledImage: React.CSSProperties = {
    marginRight: '10px',
    width: '30px',
    height: '25px',
  } as React.CSSProperties;
  let StyledFormGroup: React.CSSProperties = {
    position: 'relative',
    marginBottom: '10px',
  } as React.CSSProperties;
  let StyledOwnedVesselText: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    paddingRight: '5px',
  } as React.CSSProperties;

  useEffect(() => {
    if (!vesselsIsLoaded) {
      getVesselsCallback();
    }
    if (mapMyFleet) {
      setIsSearchInputVisible(true);
    }
  }, []);

  if (mapMyFleet) {
    StyledInput = { height: '38px', borderRadius: 'unset', marginRight: '2px' };
    StyledButton = { display: 'flex', alignItems: 'center' };
    StyledInputParent = { flex: '1', display: 'flex', height: '46px', whiteSpace: 'nowrap', marginTop: '10px' };
    StyledFormGroup = { margin: 0 };
  }

  const onChange = (val: string) => {
    setActiveVessel(0);
    setChosenVessel(val);
    setVesselObj(undefined);
    setSuggestions(vessels.filter((vessel) => checkMatch(vessel, val)));
  };

  const checkMatch = (vessel: Vessel, val: string) => {
    if (val.length < 1) return false;
    let match = false;

    //Compares input value with imo
    if (vessel.imo !== null && vessel.imo !== undefined) {
      if (vessel.imo.toString().indexOf(val) > -1) {
        match = true;
      }
    }

    //Compares input value with vessel name
    if (vessel.vesselName !== undefined && vessel.vesselName !== null) {
      if (vessel.vesselName.toLowerCase().indexOf(val.toLowerCase()) > -1) {
        match = true;
      }
    }

    //Place for more cases if needed in the future
    return match;
  };

  const chooseVessel = (vessel: Vessel, index: number) => {
    setChosenVessel(vessel.vesselName);
    setVesselObj(vessel);
    setSuggestions([]);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      //Enter key
      chooseVessel(suggestions[activeVessel], activeVessel);
    } else if (e.keyCode === 38) {
      //Up key
      if (activeVessel > 0) {
        setActiveVessel(activeVessel - 1);
      }
    } else if (e.keyCode === 40) {
      //Down key
      if (activeVessel + 1 < suggestions.length) {
        setActiveVessel(activeVessel + 1);
      }
    }
  };

  const addVesselClick = () => {
    if (vesselObj !== undefined) {
      chooseVesselMethod(vesselObj);
      setChosenVessel('');
    }
  };

  const listStyles = () => {
    if (mapMyFleet) {
      return styles.mapsuggestions;
    } else {
      return styles.suggestions;
    }
  };

  return (
    // @ts-ignore
    <FormGroup hidden={hidden} style={StyledFormGroup} autoComplete="off">
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
        <ModalBody>
          <>
            The vessel {vesselObj?.vesselName} s already in your fleet as a chartered vessel. Are you sure you want to
            add it as an owned vessel?
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              addVesselClick();
              toggle();
            }}
          >
            Confirm
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div style={StyledInputParent}>
        {!mapMyFleet && (
          <>
            <h3 style={StyledOwnedVesselText}>Owned vessels</h3>
            {!isSearchInputVisible && (isCompanyAdmin || isSupplier) && (
              <Button
                className={buttonStyle}
                color="primary"
                size="lg"
                disabled={false}
                onClick={(e) => setIsSearchInputVisible(true)}
                // @ts-ignore
                style={StyledButton}
              >
                <img src={ShipsLogo} alt="" width="25px" height="20px" style={StyledImage} />
                Add vessel
              </Button>
            )}
          </>
        )}
        {isSearchInputVisible && (
          <Input
            // @ts-ignore
            style={StyledInput}
            type="text"
            name="vessel"
            id="vessel"
            onChange={(e) => onChange(e.target.value)}
            value={chosenVessel}
            onKeyDown={onKeyDown}
            autoComplete="off"
            placeholder="Type vessel name ..."
          />
        )}
        {isSearchInputVisible && (
          <Button
            className={buttonStyle}
            color="primary"
            size="lg"
            disabled={!vesselObj}
            onClick={mapMyFleet ? addVesselClick : checkVesselInFleet}
            // @ts-ignore
            style={StyledButton}
          >
            {mapMyFleet ? (
              <>Add</>
            ) : (
              <>
                <img src={ShipsLogo} alt="" width="25px" height="20px" style={StyledImage} />
                Add vessel
              </>
            )}
          </Button>
        )}
      </div>

      <ul className={listStyles() as string}>
        {suggestions.map((vessel: any, index: any) => (
          <SearchVesselResult
            vessel={vessel}
            chooseVessel={chooseVessel}
            index={index}
            key={index}
            active={activeVessel === index}
          />
        ))}
      </ul>
    </FormGroup>
  );
};

export default React.memo(SearchVessel);
