import { Dispatch } from 'redux';
import {
  FleetChartererActions,
  FLEETCHAETERER_LOADING,
  FLEETCHAETERER_FETCH_ERROR,
  GET_FLEETCHAETERER,
  DELETE_FLEETCHAETERER,
  ADD_FLEETCHAETERER,
  FLEETCHAETERER_ADD_ERROR,
  FLEETCHAETERER_CLEAR_TOAST,
  FLEETCHAETERER_DELETE_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { FleetChartererResponseModel, FleetCharterer } from './models';
import { Vessel } from '../Vessels/models';
import { FleetVessel } from '../CompanyFleet/models';

export const getCharteredFleetVessels = (id: string) => (dispatch: Dispatch<FleetChartererActions>) => {
  dispatch({
    type: FLEETCHAETERER_LOADING,
  });

  new api<FleetChartererResponseModel>({
    dispatch,
    errorType: FLEETCHAETERER_FETCH_ERROR,
  })
    .get(`/api/FleetCharteredVessel/${id}`)
    .then((response) => {
      dispatch({
        type: GET_FLEETCHAETERER,
        payload: response,
      });
    });
};

export const addCharteredFleetVessel = (id: string, allowShipOwnerToCreateNominations: boolean, fleetVessel: FleetVessel) => (
  dispatch: Dispatch<FleetChartererActions>,
) => {
  dispatch({
    type: FLEETCHAETERER_LOADING,
  });

  new api<FleetChartererResponseModel>({
    dispatch,
    errorType: FLEETCHAETERER_ADD_ERROR,
  })
    .post(`/api/FleetCharteredVessel/${id}/${allowShipOwnerToCreateNominations}`, fleetVessel)
    .then((response) => {
      dispatch({
        type: GET_FLEETCHAETERER,
        payload: response,
      });
      dispatch({
        type: ADD_FLEETCHAETERER,
        payload: response,
      });
    });
};

export const deleteCharteredFleetVessel = (id: string) => (dispatch: Dispatch<FleetChartererActions>) => {
  dispatch({
    type: FLEETCHAETERER_LOADING,
  });

  const url = `/api/FleetCharteredVessel/${id}`;
  new api<FleetChartererResponseModel>({
    dispatch,
    errorType: FLEETCHAETERER_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_FLEETCHAETERER,
        payload: response,
      });
    });
};

export const companyCharteredFleetClearToast = () => (dispatch: Dispatch<FleetChartererActions>) => {
  dispatch({
    type: FLEETCHAETERER_CLEAR_TOAST,
  });
};
