import React from 'react';
import { Vessel } from '../../../store/Vessels/models';
import { ErrorActionPayload } from '../../../models/ErrorAction';
import { Loading } from '../../Loading';
import { TableOptions, Column, Accessor, UseTableOptions } from 'react-table/index';
import FuelBossTableContainer from '../../FuelBossTable';
import { StyledTableWrapper } from '../StyledComponents/StyledTableWrapper';

import { Error } from '../../Error';
interface OwnProps {
  vessels: Vessel[];
  isLoading: boolean;
  hasError: boolean;
  errorDetails: ErrorActionPayload | undefined;
  deleteVessel: (id: string) => void;
  editVessel: (id: string, imo: number | undefined, vesselName: string, vesselType: string) => void;
}

const Vessels: React.FC<OwnProps> = ({ vessels, isLoading, hasError, errorDetails, deleteVessel, editVessel }) => {
  const headersNew: Array<Column<Vessel>> = React.useMemo(
    () => [
      {
        Header: 'IMO',
        accessor: (row) => {
          if (row.imo) return row.imo;
          else return '';
        },
      },
      {
        Header: 'Name',
        accessor: (row) => {
          if (row.vesselName) return row.vesselName;
          else return '';
        },
      },
      {
        Header: 'Type',
        accessor: (row) => {
          if (row.vesselType) return row.vesselType;
          else return '';
        },
      },
    ],
    [],
  );

  if (isLoading) {
    return <Loading type="vessels" />;
  } else if (hasError && errorDetails) {
    return <Error error={errorDetails} />;
  } else {
    return (
      <StyledTableWrapper>
        <FuelBossTableContainer headers={headersNew} tableRows={vessels} showPagination={true} />
      </StyledTableWrapper>
    );
  }
};

export default React.memo(Vessels);
