import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faSortUp as faSortUpSolid,
  faSortDown as faSortDownSolid,
  faCircle as faCircleSolid,
  faCheckCircle as faCheckCircleSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faCloudUploadAlt,
  faStar as faStarSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle as faCheckCircleRegular,
  faArrowRight as faArrowRightRegular,
  faStethoscope,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faText,
  faDownload,
  faEnvelope,
  faCabinetFiling,
  faLayerGroup,
  faGasPump,
  faShip,
  faToolbox,
  faAnchor,
  faBuilding,
  faInfo,
  faBullseye,
  faCalendar,
  faAnalytics,
  faGlobe,
  faPlusCircle,
  faPen,
  faTimes,
  faPlus,
  faBell,
  faMinusCircle,
  faCheckCircle,
  faHourglassHalf,
  faExclamationTriangle,
  faArrowRight,
  faArrowDown,
  faArrowLeft,
  faUserPlus,
  faTrash,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faSort,
  faArchive,
  faBan,
  faRoute,
  faSearch,
  faCalendarAlt,
  faClock,
  faFileContract,
  faCheck,
  faSave,
  faClipboardCheck,
  faSync,
  faFile,
  faFilePdf,
  faThList,
  faMapMarkedAlt,
  faFilter,
  faStar,
  faSortAmountUp,
  faSortAmountDown,
  faMapMarkerAlt,
  faFileSignature,
  faUsers,
  faAddressBook,
  faPaste,
  faCogs,
  faUserAstronaut,
  faMapMarker,
  faCompass,
  faHandshake,
  faMapPin,
  faRepeatAlt,
  faShare,
  faPencil,
  faMapMarkerEdit,
  faFlaskPotion,
  faLink,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  // @ts-ignore
  faText,
  // @ts-ignore
  faCheck,
  faCabinetFiling,
  faSortUpSolid,
  faSortDownSolid,
  faCloudUploadAlt,
  faCircleSolid,
  // @ts-ignore
  faCheckCircleRegular,
  faArrowRightRegular,
  // @ts-ignore
  faLayerGroup,
  faGasPump,
  faDownload,
  faEnvelope,
  faShip,
  faToolbox,
  faAnchor,
  faBuilding,
  faInfo,
  faBullseye,
  faCalendar,
  faAnalytics,
  faGlobe,
  faPlusCircle,
  faPen,
  faTimes,
  faPlus,
  faBell,
  faMinusCircle,
  faCheckCircle,
  faHourglassHalf,
  faExclamationTriangle,
  faArrowRight,
  faArrowDown,
  faArrowLeft,
  faUserPlus,
  faTrash,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faSort,
  faArchive,
  faBan,
  faRoute,
  faSearch,
  faCalendarAlt,
  faClock,
  faFile,
  faFileContract,
  faSave,
  faClipboardCheck,
  faStethoscope,
  faCheckCircleSolid,
  faExclamationTriangleSolid,
  faSync,
  faFilePdf,
  faThList,
  faMapMarkedAlt,
  faFilter,
  faStar,
  faStarSolid,
  faSortAmountUp,
  faSortAmountDown,
  faMapMarkerAlt,
  faFileSignature,
  faUsers,
  faAddressBook,
  faPaste,
  faCogs,
  faUserAstronaut,
  faMapMarker,
  faCompass,
  faHandshake,
  faMapPin,
  faRepeatAlt,
  faShare,
  faPencil,
  faMapMarkerEdit,
  faFlaskPotion,
  faLink,
);
