import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import {
  LOCATION_LOADING,
  LOCATION_FETCH_ERROR,
  GET_LOCATION,
  LOCUPLOAD_LOADING,
  LOCUPLOAD_FETCH_ERROR,
  GET_LOCUPLOAD,
  LocationActions,
  IMPORT_LOCATIONS,
  IMPORT_LOCATIONS_ERROR,
} from './types';
import { LocationResponseModel, LocUploadResponseModel } from './models';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating locations...</ModalResponse>;
const updateSuccessModal: ReactElement = (
  <ModalResponse status="success">Locations added/updated successfully</ModalResponse>
);

const createLoaderModal: ReactElement = <ModalResponse status="loading">Importing locations...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Location created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getLocations = () => (dispatch: Dispatch<LocationActions>) => {
  dispatch({
    type: LOCATION_LOADING,
  });

  new api<LocationResponseModel>({
    dispatch,
    errorType: LOCATION_FETCH_ERROR,
  })
    .get(`api/chorus/GetLocations/`)
    .then((response) => {
      dispatch({
        type: GET_LOCATION,
        payload: response,
      });
    });
};

// export const GetFileUploadData = (onSuccess: () => void) => (dispatch: Dispatch<LocationActions>) => {
export const GetFileUploadData = () => (dispatch: Dispatch<LocationActions>) => {
  dispatch({
    type: LOCUPLOAD_LOADING,
  });
  new api<LocUploadResponseModel>({
    dispatch,
    errorType: LOCUPLOAD_FETCH_ERROR,
  })
    .get(`/api/location/GetFileUploadData`)
    .then((response) => {
      dispatch({
        type: GET_LOCUPLOAD,
        payload: response,
      });
      // // @ts-ignore
      // dispatch({ type: SET_MODAL, payload: updateLoaderModal });
      // setTimeout(() => {
      //   // @ts-ignore
      //   dispatch({ type: SET_MODAL, payload: null });
      // }, 1000);
      // onSuccess();
    });
};

// export const bulkUpdate = (file: any, onSuccess: () => void) => (dispatch: Dispatch<LocationActions>) => {
export const bulkUpdateold = (file: any) => (dispatch: Dispatch<LocationActions>) => {
  dispatch({
    type: LOCUPLOAD_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<LocUploadResponseModel>({
    dispatch,
    errorType: IMPORT_LOCATIONS_ERROR,
  })
    .post(`/api/location/BulkUpdate`, file)
    .then((response) => {
      dispatch({
        type: GET_LOCUPLOAD,
        payload: response,
      });
      dispatch({
        type: IMPORT_LOCATIONS,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      // onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

// export const importClearToast = () => (dispatch: Dispatch<LocationActions>) => {
//   dispatch({
//     type: IMPORT_LOCATIONS_CLEAR_TOAST,
//   });
// };
