import {
  formatPortCountry,
  getCompanyDetails,
  ICompany,
  IContract,
  IPipeline,
  IPromptNomination,
  isCustomer,
  IShipWithCompanyId,
  IShipWithId,
  isScheduler,
  isSchedulerCaptain,
  isSupplier,
  IThirdPartyContact,
  IUserProfile,
  LocationService,
  NominationSelection,
  useLocationName,
  useLocationTimeZone,
  userCanEditContract,
} from '@teqplay/chorus-components';
import React from 'react';
import { ENERGY_UNITS, getQuantityUnitLabel, PRICE_UNITS, ResizableBox } from '@teqplay/chorus-components';
import SummaryRow from './SummaryRow';
import {
  RecurringNomination,
  ScheduleRecurrenceType,
  TimeBeforeNominationCreationType,
  WeeklyScheduleModel,
} from '../../../../../store/RecurringNomination/models';
import { canUserEditContract } from '../Utils/nominationPermissions';
import { instanceOfRecurringNomination, validateNominationBST } from '../Validators/recurringNominationValidation';

interface NominationSummaryProps {
  latestNomination: IPromptNomination | RecurringNomination;
  nominationSelection: NominationSelection;
  companies: ICompany[];
  locationService: LocationService;
  bunkerShips: IShipWithCompanyId[];
  pipelines: IPipeline[];
  contractsList: IContract[];
  proposalsAmount: number;
  currentUser: IUserProfile;
  companyContacts: IThirdPartyContact[];
  nominationEventToCompareWith?: IPromptNomination | RecurringNomination;
  isDelegatedNomination?: boolean;
}

const NominationSummary: React.FunctionComponent<NominationSummaryProps> = (props) => {
  const currentNomination = props.nominationSelection.selectedHistoricNomination || props.latestNomination;
  const previousNomination = props.nominationSelection.previousHistoricNomination;

  const currentLocation = useLocationName(currentNomination.locationId, props.locationService, formatPortCountry);

  const locationComparing = useLocationName(
    props.nominationEventToCompareWith?.locationId,
    props.locationService,
    formatPortCountry,
  );
  const previousLocation = useLocationName(previousNomination?.locationId, props.locationService, formatPortCountry);

  // note that we only use the resolved port/country when both current and previous locationDetails are resolved,
  // else the UI will temporarily display that the locationDetails is changed
  const loading = currentLocation.loading || previousLocation.loading;
  const currentPortName: string = loading ? '(Loading...)' : currentLocation.text;
  const comparingPortName: string | null = locationComparing?.loading ? '(Loading...)' : locationComparing?.text;
  const previousPortName: string | undefined = previousNomination?.locationId
    ? loading
      ? '(Loading...)'
      : previousLocation.text
    : undefined;

  const currentBunkerVessel = props.bunkerShips.find((bunkership) => bunkership._id === currentNomination.bunkerShipId);
  const previousBunkerVessel = props.bunkerShips.find(
    (bunkership) => bunkership._id === previousNomination?.bunkerShipId,
  );

  const currentPipeline = props.pipelines.find((pipeline) => pipeline._id === currentNomination.pipelineId);
  const previousPipeline = props.pipelines.find((pipeline) => pipeline._id === previousNomination?.pipelineId);

  const currentThirdPartyContacts = getNominationContacts(currentNomination);
  const previousThirdPartyContacts = getNominationContacts(previousNomination || null);

  const currentContract = props.contractsList.find((contract) => contract._id === currentNomination.contractId);
  const previousContract = props.contractsList.find((contract) => contract._id === previousNomination?.contractId);

  const timezone = useLocationTimeZone(currentNomination.locationId, props.locationService);
  const altFuelAmounts = getAltFuelNumbers(currentNomination, previousNomination);

  const isInitialValue = props.proposalsAmount <= 1;

  const vendorCompanyName = props.companies.find((company) => company._id === props.latestNomination.vendorCompanyId)
    ?.name;

  const isContractHolder = props.currentUser.companyId === currentNomination.companyId;
  const counterCompanyName =
    (!isContractHolder && props.isDelegatedNomination === true) ||
    (props.isDelegatedNomination === false && isScheduler(props.currentUser.roles))
      ? currentNomination.companyId
        ? getCompanyDetails(props.companies, currentNomination.companyId).companyName
        : '-'
      : currentNomination.vendorCompanyId
      ? getCompanyDetails(props.companies, currentNomination.vendorCompanyId).companyName
      : '-';

  const isRecurringNomination = instanceOfRecurringNomination(currentNomination);
  const getReccuranceDaysForWeeklySchedule = (schedule: WeeklyScheduleModel | undefined) => {
    let days = '';
    if(!schedule) return '';
    if (schedule.monday) days = days + 'Monday,';
    if (schedule.tuesday) days = days + 'Tuesday,';
    if (schedule.wednesday) days = days + 'Wednesday,';
    if (schedule.thursday) days = days + 'Thursday,';
    if (schedule.friday) days = days + 'Friday,';
    if (schedule.saturday) days = days + 'Saturday,';
    if (schedule.sunday) days = days + 'Sunday,';
    if (days.length > 1) return days.slice(0, -1);
    return days;
  };
  const currentSupplierTerms = currentNomination.supplierTerms || '-'
  const previousSupplierTerms =
    currentSupplierTerms !== previousNomination?.supplierTerms ? previousNomination?.supplierTerms || '' : '';

  const currentCustomerTerms = currentNomination.buyerTerms || '-'
  const previousCustomerTerms =
    currentCustomerTerms !== previousNomination?.buyerTerms ? previousNomination?.buyerTerms || '' : '';

  return (
    <div className="nomination-summary">
      <section className="summary-section">
        <h4 className="summary-section-title">General information</h4>
        {isCustomer(props.currentUser.roles) && (
          <SummaryRow
            label={'Supplier'}
            currentValue={`${vendorCompanyName || ''}`}
            hideNewIndicator
            emptyValueMessage={'-'}
            isInitialValue={isInitialValue}
          />
        )}
        {(isScheduler(props.currentUser.roles) || isSchedulerCaptain(props.currentUser.roles)) && (
          <SummaryRow
            label={
              props.isDelegatedNomination === true
                ? isContractHolder
                  ? 'Executing supplier'
                  : 'Contract-holding supplier'
                : isScheduler(props.currentUser.roles)
                ? 'Buyer'
                : 'Supplier'
            }
            hideNewIndicator
            currentValue={counterCompanyName}
            emptyValueMessage={'-'}
            isInitialValue={isInitialValue}
          />
        )}
        <SummaryRow
          label={'Delivery port'}
          currentValue={currentPortName}
          previousValue={previousPortName}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
          shouldCompare={!!props.nominationEventToCompareWith}
          rowToCompareWith={comparingPortName}
        />
        <SummaryRow
          label={'Location'}
          currentValue={currentNomination.location}
          previousValue={previousNomination?.location}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
          shouldCompare={!!props.nominationEventToCompareWith}
          rowToCompareWith={props.nominationEventToCompareWith?.location}
        />
        <SummaryRow
          label={'Excise fee/CO2 fee applicable'}
          currentValue={currentNomination.co2TaxEnabled ? 'yes' : null}
          previousValue={previousNomination?.co2TaxEnabled ? 'yes' : null}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
        />
        <SummaryRow
          label={'Next destination'}
          currentValue={currentNomination.nextDestination}
          previousValue={previousNomination?.nextDestination}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
        />
        <SummaryRow
          label={'Agent'}
          currentValue={currentNomination.agent}
          previousValue={previousNomination?.agent}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
        />
        <SummaryRow
          label={props.isDelegatedNomination === true ? 'Contract-holding supplier reference' : 'Buyer reference'}
          currentValue={currentNomination.buyerRef}
          previousValue={previousNomination?.buyerRef}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
        />
      </section>

      <section className="summary-section">
        <h4 className="summary-section-title">Quantity and delivery</h4>

        <SummaryRow
          label={'Quantity total'}
          currentValue={`${currentNomination.amount} ${getQuantityUnitLabel(currentNomination.quantityUnit)}`}
          previousValue={`${previousNomination?.amount} ${getQuantityUnitLabel(previousNomination?.quantityUnit)}`}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
          shouldCompare={!!props.nominationEventToCompareWith}
          rowToCompareWith={
            props.nominationEventToCompareWith &&
            `${props.nominationEventToCompareWith?.amount} ${getQuantityUnitLabel(
              props.nominationEventToCompareWith?.quantityUnit,
            )}`
          }
        />

        <SummaryRow
          label={'Tolerance (%)'}
          currentValue={currentNomination.tolerance}
          previousValue={previousNomination?.tolerance}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
          shouldCompare={!!props.nominationEventToCompareWith}
          rowToCompareWith={props.nominationEventToCompareWith?.tolerance}
        />

        {altFuelAmounts && (
          <>
            <SummaryRow
              label={'LBG (%)'}
              currentValue={currentNomination.altFuelPercentage}
              previousValue={previousNomination?.altFuelPercentage}
              emptyValueMessage={'-'}
              isInitialValue={isInitialValue}
            />

            <SummaryRow
              label={`Quantity LNG ${getQuantityUnitLabel(currentNomination.quantityUnit)}`}
              currentValue={altFuelAmounts.currentLngAmount}
              previousValue={altFuelAmounts.previouslngAmount}
              emptyValueMessage={'-'}
              isInitialValue={isInitialValue}
            />

            <SummaryRow
              label={`Quantity LBG ${getQuantityUnitLabel(currentNomination.quantityUnit)}`}
              currentValue={altFuelAmounts.currentLbgAmount}
              previousValue={altFuelAmounts.previousLbgAmount}
              emptyValueMessage={'-'}
              isInitialValue={isInitialValue}
            />

            {/* <SummaryRow
              label={'Alternative Fuel Tolerance'}
              currentValue={currentNomination.altFuelTolerance}
              previousValue={previousNomination?.altFuelTolerance}
              emptyValueMessage={'-'}
            /> */}
          </>
        )}

        <SummaryRow
          label={'Delivery by'}
          currentValue={currentNomination.deliveryMode}
          previousValue={previousNomination?.deliveryMode}
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
        />

        {currentNomination.deliveryMode === 'SHIP' && (
          <SummaryRow
            label={'Bunker vessel'}
            currentValue={currentBunkerVessel?.name || currentNomination.attributes?.bunkerShipName}
            previousValue={previousBunkerVessel?.name || previousNomination?.attributes?.bunkerShipName}
            emptyValueMessage={'entered by supplier'}
            isInitialValue={isInitialValue}
          />
        )}

        {currentNomination.deliveryMode === 'PIPE' && (
          <SummaryRow
            label={'Pipeline'}
            currentValue={currentPipeline?.name || currentNomination.attributes?.pipelineName}
            previousValue={previousPipeline?.name || previousNomination?.attributes?.pipelineName}
            emptyValueMessage={'entered by supplier'}
            isInitialValue={isInitialValue}
          />
        )}
      </section>

      <section className="summary-section">
        <h4 className="summary-section-title">Communication</h4>

        <SummaryRow
          label={'Contacts'}
          currentValue={prepareThirdPartyMarkup(currentThirdPartyContacts)}
          previousValue={
            JSON.stringify(currentThirdPartyContacts) !== JSON.stringify(previousThirdPartyContacts)
              ? prepareThirdPartyMarkup(previousThirdPartyContacts)
              : ''
          }
          emptyValueMessage={'-'}
          isInitialValue={isInitialValue}
        />
      </section>

      <section className="summary-section">
        <h4 className="summary-section-title">Schedule</h4>
        <h5 className="timezone-title">Timezone: {timezone.text}</h5>

        <div className="section-content">
          <div
            className={`split-rows receiving-vessel-section ${
              currentNomination.deliveryMode !== 'SHIP' ? 'single-schedule' : ''
            }`}
          >
            <h5 className="summary-section-sub-title right-align">Receiving vessel</h5>
            <div className="rows">
              <SummaryRow
                className="rv-row"
                label={'ETA'}
                formatAsDate
                currentValue={currentNomination.eta}
                previousValue={previousNomination?.eta}
                formatTimezone={timezone.value}
                emptyValueMessage={'entered by customer'}
                isInitialValue={isInitialValue}
              />
              <SummaryRow
                className="rv-row"
                label={'BST'}
                formatAsDate
                currentValue={currentNomination.bst}
                previousValue={previousNomination?.bst}
                formatTimezone={timezone.value}
                emptyValueMessage={'-'}
                isInitialValue={isInitialValue}
                warning={validateNominationBST(currentNomination, props.currentUser.roles)}
                shouldCompare={!!props.nominationEventToCompareWith}
                rowToCompareWith={props.nominationEventToCompareWith?.bst}
              />
              <SummaryRow
                className="rv-row"
                label={'ETD'}
                formatAsDate
                currentValue={currentNomination.etd}
                previousValue={previousNomination?.etd}
                formatTimezone={timezone.value}
                emptyValueMessage={'entered by customer'}
                isInitialValue={isInitialValue}
              />
              {instanceOfRecurringNomination(currentNomination) && (
                <SummaryRow
                  label={'Recurrence type'}
                  currentValue={ScheduleRecurrenceType[currentNomination.scheduleRecurrenceType]}
                  previousValue={undefined}
                  emptyValueMessage={'-'}
                  isInitialValue={isInitialValue}
                />
              )}
              {instanceOfRecurringNomination(currentNomination) &&
                currentNomination.scheduleRecurrenceType == ScheduleRecurrenceType.Weekly && (
                  <SummaryRow
                    label={'Recurrence days'}
                    currentValue={getReccuranceDaysForWeeklySchedule(currentNomination.weeklySchedule)}
                    previousValue={undefined}
                    emptyValueMessage={'-'}
                    isInitialValue={isInitialValue}
                  />
                )}
              {instanceOfRecurringNomination(currentNomination) && isCustomer(props.currentUser.roles) && (
                <SummaryRow
                  label={'Sent to supplier'}
                  currentValue={
                    currentNomination.timeBeforeNominationCreation +
                    ' ' +
                    TimeBeforeNominationCreationType[currentNomination.tbncType]
                  }
                  previousValue={undefined}
                  emptyValueMessage={'-'}
                  isInitialValue={isInitialValue}
                />
              )}
              {instanceOfRecurringNomination(currentNomination) && currentNomination.startDate && (
                <SummaryRow
                  label={'Start date'}
                  formatAsDate
                  formatTimezone={timezone.value}
                  //@ts-ignore
                  currentValue={currentNomination.startDate}
                  previousValue={undefined}
                  emptyValueMessage={'-'}
                  isInitialValue={isInitialValue}
                />
              )}
              {instanceOfRecurringNomination(currentNomination) && currentNomination.endDate && (
                <SummaryRow
                  label={'End date'}
                  formatAsDate
                  formatTimezone={timezone.value}
                  //@ts-ignore
                  currentValue={currentNomination.endDate}
                  previousValue={undefined}
                  emptyValueMessage={'-'}
                  isInitialValue={isInitialValue}
                />
              )}
            </div>
          </div>

          {currentNomination.deliveryMode === 'SHIP' && (
            <div className="split-rows bunker-vessel-section">
              <h5 className="summary-section-sub-title">Bunker vessel</h5>
              <div className="rows">
                {!instanceOfRecurringNomination(currentNomination) && currentNomination.bunkershipEta && (
                  <SummaryRow
                    label={'ETA'}
                    formatAsDate
                    currentValue={currentNomination.bunkershipEta}
                    previousValue={previousNomination?.bunkershipEta}
                    formatTimezone={timezone.value}
                    emptyValueMessage={'entered by supplier'}
                    isInitialValue={isInitialValue}
                  />
                )}
                <SummaryRow
                  label={'Allowed bunkering time'}
                  formatTimezone={timezone.value}
                  currentValue={currentNomination.allowedBunkeringTime}
                  previousValue={previousNomination?.allowedBunkeringTime}
                  emptyValueMessage={'entered by supplier'}
                  isInitialValue={isInitialValue}
                />
                {!instanceOfRecurringNomination(currentNomination) && currentNomination.bunkershipEta && (
                  <SummaryRow
                    label={'ETD'}
                    formatAsDate
                    formatTimezone={timezone.value}
                    currentValue={currentNomination.bunkershipEtd}
                    previousValue={previousNomination?.bunkershipEtd}
                    emptyValueMessage={'entered by supplier'}
                    isInitialValue={isInitialValue}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      {canUserEditContract(currentNomination, props.currentUser, false) && (
        <section className="summary-section">
          <h4 className="summary-section-title">Contract</h4>

          <SummaryRow
            label={'Supplier reference'}
            currentValue={currentNomination.vendorReference}
            previousValue={previousNomination?.vendorReference}
            emptyValueMessage={'-'}
            isInitialValue={isInitialValue}
          />

          {!props.isDelegatedNomination && (
            <SummaryRow
              label={'Contract'}
              currentValue={currentContract?.name}
              previousValue={previousContract?.name}
              emptyValueMessage={'entered by supplier'}
              isInitialValue={isInitialValue}
            />
          )}

          {(isScheduler(props.currentUser.roles) || isCustomer(props.currentUser.roles)) &&
            currentNomination?.priceEnabled && (
              <>
                <SummaryRow
                  label={'Price'}
                  currentValue={formatPrice(currentNomination)}
                  previousValue={formatPrice(previousNomination)}
                  emptyValueMessage={'entered by supplier'}
                  isInitialValue={isInitialValue}
                />
              </>
            )}

          {!isSchedulerCaptain(props.currentUser.roles) && (
            <SummaryRow
              className="summary-row__additional-terms"
              label={props.isDelegatedNomination ? 'Executing supplier Terms' : 'Supplier terms'}
              currentValue={<ResizableBox>{currentSupplierTerms}</ResizableBox>}
              previousValue={
                previousSupplierTerms.length > 0 ? <ResizableBox>{previousSupplierTerms}</ResizableBox> : ''
              }
              emptyValueMessage={'-'}
              isInitialValue={isInitialValue}
            />
          )}

          {!isSchedulerCaptain(props.currentUser.roles) && (
            <SummaryRow
              className="summary-row__additional-terms"
              label={props.isDelegatedNomination ? 'Contract-holding supplier Terms' : 'Customer terms'}
              currentValue={<ResizableBox>{currentCustomerTerms}</ResizableBox>}
              previousValue={
                previousCustomerTerms.length > 0 ? <ResizableBox>{previousCustomerTerms}</ResizableBox> : ''
              }
              emptyValueMessage={'-'}
              isInitialValue={isInitialValue}
            />
          )}
        </section>
      )}
    </div>
  );

  function getAltFuelNumbers(
    currentNomination: IPromptNomination | RecurringNomination,
    previousNomination?: IPromptNomination | RecurringNomination,
  ) {
    let currentLbgAmount = 0;
    let currentLngAmount = 0;
    let previousLbgAmount = 0;
    let previouslngAmount = 0;
    const currentNominationLBGEnabled = currentNomination.altFuelPercentage && currentNomination.altFuelPercentage > 0;
    const previousNominationLBGEnabled =
      previousNomination && previousNomination.altFuelPercentage && previousNomination.altFuelPercentage > 0;

    if (!currentNominationLBGEnabled && !previousNominationLBGEnabled) {
      return;
    }

    if (currentNominationLBGEnabled) {
      currentLbgAmount = currentNomination.altFuelPercentage
        ? currentNomination.amount * (currentNomination.altFuelPercentage / 100)
        : 0;

      currentLngAmount = currentNomination.amount - currentLbgAmount;
    }

    if (previousNominationLBGEnabled && previousNomination) {
      previousLbgAmount = previousNomination.altFuelPercentage
        ? previousNomination.amount * (previousNomination.altFuelPercentage / 100)
        : 0;

      previouslngAmount = previousNomination.amount - previousLbgAmount;
    }

    return {
      currentLbgAmount: currentLbgAmount.toFixed(2),
      currentLngAmount: currentLngAmount.toFixed(2),
      previousLbgAmount: previousLbgAmount.toFixed(2),
      previouslngAmount: previouslngAmount.toFixed(2),
    };
  }

  function formatPrice(nomination?: IPromptNomination | RecurringNomination): string | null {
    if (!nomination || !nomination.priceEnabled) {
      return null;
    }

    const price = nomination.price || '<price undefined>';

    const priceUnit =
      (nomination.priceUnit != null
        ? PRICE_UNITS.find((item) => item.value === nomination.priceUnit)?.text || nomination.priceUnit
        : undefined) || '<price unit undefined>';

    const energyUnit = instanceOfRecurringNomination(nomination)
      ? ''
      : (nomination.energyUnit != null && nomination.energyUnit != undefined
          ? ENERGY_UNITS.find((item) => item.value === nomination.energyUnit)?.text || nomination.energyUnit || ''
          : undefined) || '<energy unit undefined>';

    return `${price} ${priceUnit} / ${energyUnit}`;
  }

  function getNominationContacts(nomination: IPromptNomination | RecurringNomination | null) {
    if (!nomination) return [] as IThirdPartyContact[];

    const contactVendorIdFieldName: 'vendorThirdPartyContactIds' | 'customerThirdPartyContactIds' =
      props.isDelegatedNomination && isContractHolder ? 'customerThirdPartyContactIds' : 'vendorThirdPartyContactIds';

    const contactType = isSupplier(props.currentUser.roles) ? contactVendorIdFieldName : 'customerThirdPartyContactIds';

    const nominationContacts = instanceOfRecurringNomination(nomination)
      ? props.companyContacts.filter((contact) => {
          return nomination[contactType]?.split(',').find((contactId) => contact._id === contactId);
        })
      : props.companyContacts.filter((contact) => {
          return nomination[contactType]?.find((contactId) => contact._id === contactId);
        });

    return nominationContacts;
  }

  function prepareThirdPartyMarkup(contacts: IThirdPartyContact[]) {
    return contacts.length > 0 ? (
      <>
        {contacts
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((contact) => (
            <>
              <span>{`${contact.name} <${contact.email}>`}</span>
              <br />
            </>
          ))}
      </>
    ) : (
      ''
    );
  }
};

export default NominationSummary;
