import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { initialValues } from '../reportFormInitialValues';
import reportConfigs from '../ReportForm/reportConfigs';

import ReportForm from '../ReportForm/ReportForm';
import { Company } from '../../../../../store/Company/models';
import { Report } from '../../../../../store/Reports/models';
import { ReportType } from '../../../../../store/ReportTypes/models';
import { Category } from '../../../../../store/Category/models';

import { Role } from '../../../../../store/Roles/models';

import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { getCategories } from '../../../../../store/Category/actions';
import { getCompanies } from '../../../../../store/Company/actions';
import { getRoles } from '../../../../../store/Roles/actions';

interface OwnProps {
  addReport: (report: Report) => void;
  companyAdmin?: boolean;
  header: string;
  report?: Report;
  company?: Company;
  navAdminType: string;
}

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

const FormikWrapper: React.FC<OwnProps> = ({ addReport, header, report, companyAdmin, navAdminType, company }) => {
  const submitReport = (report: Report) => {
    addReport(report);
  };

  const fleetVesselValidationSchema = Yup.object().shape({
    id: Yup.string().required('Vessel required'),
  });

  const rolesValidationSchema = Yup.object().shape({
    id: Yup.string().required('Role required'),
    roleType: Yup.string().optional(),
    chorusRole: Yup.string().optional(),
  });

  const companyValidationSchema = Yup.object().shape({
    companyId: Yup.string().required('Company required'),
    roleType: Yup.string().optional(),
    chorusRole: Yup.string().optional(),
  });

  const reportTypeValidationSchema = Yup.object().shape({
    id: Yup.string().required('Type required'),
    name: Yup.string().optional(),
    subset: Yup.string().optional(),
  });

  const validationShape = {
    name: Yup.string().matches(reportConfigs.name.regex, validationMessage).required('Name required'),
    description: Yup.string()
      .matches(reportConfigs.description.regex, validationMessage)
      .required('Description required'),
    url: Yup.string()
      .matches(reportConfigs.url.regex, 'Not a valid URL Format: https://insight.dnvgl.com/FBA/ ')
      .when('reference', {
        is: (reference) => reference == 'URL',
        then: Yup.string().required('URL is required.'),
        otherwise: Yup.string().optional(),
      }),
    type: reportTypeValidationSchema.required('Type required'),
    companies: Yup.array().required('Company is required'),
    roles: Yup.array().required('Role is required'),
    companyCategories: Yup.array().required('Category is required'),
    openInNewTab: Yup.boolean().optional(),
    active: Yup.boolean().optional(),
    reference: Yup.string().required('Reference is required'),
    entityId: Yup.string().when('reference', {
      is: (reference) => reference == 'ID',
      then: Yup.string().required('EntityId is required.'),
      otherwise: Yup.string().optional(),
    }),
    reportId: Yup.string().when('reference', {
      is: (reference) => reference == 'ID',
      then: Yup.string().required('ReportId is required.'),
      otherwise: Yup.string().optional(),
    }),
    powerBiReportId: Yup.string().when('reference', {
      is: (reference) => reference == 'ID',
      then: Yup.string().required('PowerBiReportId is required.'),
      otherwise: Yup.string().optional(),
    }),
  };

  const reportFormValidation = Yup.object().shape(validationShape);

  const {
    companiesLoading,
    companiesDetailsError,
    companiesErrorDetails,
    companies,
    companiesIsLoaded,
    categories,
    roles,
  } = useSelector((state: ApplicationState) => ({
    companiesLoading: state.companies.loading,
    companiesDetailsError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,
    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    categories: state.categories.data,
    roles: state.roles.data,
  }));

  const dispatch = useDispatch();

  const getCategoriesCallback = React.useCallback(() => {
    dispatch(getCategories());
  }, []);

  const getCompaniesCallback = React.useCallback(() => dispatch(getCompanies()), []);

  const getAllRolesCallback = React.useCallback(() => dispatch(getRoles()), []);

  React.useEffect(() => {
    if (!categories.length) {
      getCategoriesCallback();
    }
    if (!companies.length) {
      getCompaniesCallback();
    }
    if (!roles.length) {
      getAllRolesCallback();
    }
  }, []);

  return (
    <Formik
      initialValues={report ? report : initialValues}
      validationSchema={reportFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <ReportForm
          report={report}
          header={header}
          saveMethod={submitReport}
          systemAdminPage={true}
          companyAdminForm={companyAdmin}
          //@ts-ignore
          company={report && report.companiesIds && report.companiesIds.length>0 ? companies.filter((x) => report?.companiesIds.includes(x.companyId)) : undefined}
          //@ts-ignore
          companyCategory={report && report.companyCategoriesIds && report.companyCategoriesIds.length>0 ? categories.filter((x) => report.companyCategoriesIds.includes(x.id)) : undefined} 
          //@ts-ignore
          selectedRole={report && report.rolesIds && report.rolesIds.length>0 ? roles.filter((x) => report?.rolesIds.includes(x.id)) : undefined}
          selectedReportType={report?.type}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(FormikWrapper);
