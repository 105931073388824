import { toast } from 'react-toastify';
import { ToastData } from '../models/ErrorAction';

export const AddToast = (details: ToastData): void => {
  if (details.toastId === undefined || !toast.isActive(details.toastId)) {
    toast(details.content, {
      position: 'bottom-right',
      autoClose: 10000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      type: details.type,
    });
  }
};
