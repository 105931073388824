import React from 'react';
import styles from './ServiceCheck.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  checkFuelbossConnectivity,
  checkChorusConnectivity,
  checkMapboxConnectivity,
  checkSignalRConnectivity,
  checkTqplayIconsConnectivity,
  checkVeracityNotificationsConnectivity,
  clearConnectivityChecks,
  addConnectivityResult,
} from '../../store/ConnectivityCheck/actions';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { ConnectivityCheckResult } from '../../store/ConnectivityCheck/models';
import { ConnectivityCheckUrls, useGetConnectivityURLs } from '../../helpers/useGetConnectivityURLs';
import * as signalR from '@microsoft/signalr';
import Logg from '../../helpers/consoleLogger';
import { getAuth } from '../../store/Auth/actions';
import { getMapboxToken } from '../../store/Map/actions';
import { useNetworkSpeedCheck } from '../../helpers/useNetworkSpeedCheck';

const healthIcon = findIconDefinition({
  prefix: 'far',
  iconName: 'stethoscope',
});
const checkCircle = findIconDefinition({ prefix: 'fas', iconName: 'check-circle' });
const warningTriangle = findIconDefinition({ prefix: 'fas', iconName: 'exclamation-triangle' });

declare type ServiceCheckStatus = 'INITIAL' | 'RUNNING' | 'SUCCESS' | 'FAILURE' | 'WARNING';

export const ServiceCheck: React.FC = () => {
  const [serviceCheckStatus, setServiceCheckStatus] = React.useState('INITIAL' as ServiceCheckStatus);
  const [header, setHeader] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [speedTestPerformed, setSpeedTestPerformed] = React.useState(false);

  const dispatch = useDispatch();
  const connectivityCheckUrls: ConnectivityCheckUrls = useGetConnectivityURLs();

  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const getmapbox_tokenCallback = React.useCallback(() => dispatch(getMapboxToken()), []);

  const checkFuelbossConnectivityCallback = React.useCallback(
    (url: string) => dispatch(checkFuelbossConnectivity(url)),
    [],
  );

  const checkChorusConnectivityCallback = React.useCallback(
    (url: string, token: string) => dispatch(checkChorusConnectivity(url, token)),
    [],
  );

  const checkMapboxConnectivityCallback = React.useCallback(
    (url: string, token: string) => dispatch(checkMapboxConnectivity(url, token)),
    [],
  );

  const checkSignalRConnectivityCallback = React.useCallback(
    (url: string) => dispatch(checkSignalRConnectivity(url)),
    [],
  );

  const checkTqplayIconsConnectivityCallback = React.useCallback(
    (url: string) => dispatch(checkTqplayIconsConnectivity(url)),
    [],
  );

  const checkVeracityNotificationsConnectivityCallback = React.useCallback(
    (url: string) => dispatch(checkVeracityNotificationsConnectivity(url)),
    [],
  );

  const clearConnectivityChecksCallback = React.useCallback(() => dispatch(clearConnectivityChecks()), []);

  const addConnectivityResultCallback = React.useCallback((result: ConnectivityCheckResult) => {
    dispatch(addConnectivityResult(result));
  }, []);

  const {
    auth,
    authIsLoaded,
    mapbox_token,
    mapbox_tokenIsLoaded,
    fuelbossCheck,
    fuelbossCheckIsLoaded,
    fuelbossCheckLoading,
    fuelbossCheckError,
    chorusCheck,
    chorusCheckIsLoaded,
    chorusCheckLoading,
    chorusCheckError,
    mapboxCheck,
    mapboxCheckIsLoaded,
    mapboxCheckLoading,
    mapboxCheckError,
    veracityNotificationsCheck,
    veracityNotificationsCheckIsLoaded,
    veracityNotificationsCheckLoading,
    veracityCheckError,
    tqplayIconsCheck,
    tqplayIconsCheckIsLoaded,
    tqplayIconsCheckLoading,
    tqplayIconsCheckError,
  } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    mapbox_token: state.mapbox_token.data,
    mapbox_tokenIsLoaded: state.mapbox_token.dataIsLoaded,

    fuelbossCheck: state.connectivity_check.data_fuelboss_check,
    fuelbossCheckIsLoaded: state.connectivity_check.dataIsLoaded_fuelboss_check,
    fuelbossCheckLoading: state.connectivity_check.loading_fuelboss_check,
    fuelbossCheckError: state.connectivity_check.error_fuelboss_check,

    chorusCheck: state.connectivity_check.data_chorus_check,
    chorusCheckIsLoaded: state.connectivity_check.dataIsLoaded_chorus_check,
    chorusCheckLoading: state.connectivity_check.loading_chorus_check,
    chorusCheckError: state.connectivity_check.error_chorus_check,

    mapboxCheck: state.connectivity_check.data_mapbox_check,
    mapboxCheckIsLoaded: state.connectivity_check.dataIsLoaded_mapbox_check,
    mapboxCheckLoading: state.connectivity_check.loading_mapbox_check,
    mapboxCheckError: state.connectivity_check.error_mapbox_check,

    veracityNotificationsCheck: state.connectivity_check.data_veracity_check,
    veracityNotificationsCheckIsLoaded: state.connectivity_check.dataIsLoaded_veracity_check,
    veracityNotificationsCheckLoading: state.connectivity_check.loading_veracity_check,
    veracityCheckError: state.connectivity_check.error_veracity_check,

    tqplayIconsCheck: state.connectivity_check.data_tqplayicon_check,
    tqplayIconsCheckIsLoaded: state.connectivity_check.dataIsLoaded_tqplayicon_check,
    tqplayIconsCheckLoading: state.connectivity_check.loading_tqplayicon_check,
    tqplayIconsCheckError: state.connectivity_check.error_tqplayicon_check,
  }));

  //--START OF SIGNALR----------------------
  const [hubConnection, setHubConnection] = React.useState(
    new signalR.HubConnectionBuilder()
      .withUrl(connectivityCheckUrls.signalR)
      .configureLogging(signalR.LogLevel.Trace)
      .build(),
  );
  const [signalRCheck, setSignalRCheck] = React.useState(false);
  const [signalRCheckIsLoaded, setSignalRCheckIsLoaded] = React.useState(false);
  const [signalRCheckLog, setSignalRCheckLog] = React.useState<string[]>(['']);

  const addSignalRLog = (log: string) => {
    const value = signalRCheckLog;
    value.push(log);
    setSignalRCheckLog(value);
  };
  const resetSignalRLog = () => {
    setSignalRCheckLog(['']);
  };

  //Run TEST and SIGNALR
  const runChatTest = (userId: string) => {
    resetSignalRLog();
    addSignalRLog(`Starting chat hub connection with User id: ${userId}`);
    hubConnection
      .start()
      .then(() => {
        addSignalRLog('Connection started to chathub');
        hubConnection.invoke('Echo', userId, 'Test SignalR Message').catch((err: any) => {
          console.log(err);
          setSignalRCheck(false);
          setSignalRCheckIsLoaded(true);
        });
        addSignalRLog('Message sent to chathub');
        hubConnection.on('echo', (uid, message) => {
          // do something after receiving message
          addSignalRLog('Received message back from server:' + message + ' UserId: ' + uid);
          setSignalRCheck(true);
          setSignalRCheckIsLoaded(true);
        });
      })
      .catch((err) => {
        Logg(`Error while establishing chathub connection${err}`);
        setSignalRCheck(false);
        setSignalRCheckIsLoaded(true);
      });
  };

  React.useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
    if (!mapbox_tokenIsLoaded) {
      getmapbox_tokenCallback();
    }
  }, []);

  const onButtonClick = () => {
    setSignalRCheck(false);
    setSignalRCheckIsLoaded(false);
    clearConnectivityChecksCallback();
    setServiceCheckStatus('RUNNING');
    checkFuelbossConnectivityCallback(connectivityCheckUrls.fuelboss);
    checkChorusConnectivityCallback(connectivityCheckUrls.chorus, auth.ac.slice(0, -2));
    checkMapboxConnectivityCallback(connectivityCheckUrls.mapbox + mapbox_token, 'exampleToken');
    checkTqplayIconsConnectivityCallback(connectivityCheckUrls.tqplayIcons);
    checkVeracityNotificationsConnectivityCallback(connectivityCheckUrls.veracityNotifications);
    //chat connectivity test
    const userId = auth.userId;
    if (hubConnection && userId && userId !== '') {
      runChatTest(userId);
    }
  };

  React.useEffect(() => {
    if (serviceCheckStatus == 'INITIAL') {
      setHeader('Click the icon to run the FuelBoss connectivity check');
      setDesc('Check if all FuelBoss services work fine.');
    } else if (serviceCheckStatus == 'RUNNING') {
      setHeader('Connectivity check running');
      setDesc('Please wait patiently.');
    } else if (serviceCheckStatus == 'SUCCESS') {
      setHeader('All good!');
      setDesc('We have a connection!');
    } else if (serviceCheckStatus == 'FAILURE') {
      setHeader('Something needs our attention.');
      setDesc(
        'We are investigating the results of the connectivity check and will get in touch with you as soon as possible',
      );
    } else if (serviceCheckStatus == 'WARNING') {
      setHeader('');
      setDesc('');
    }
  }, [serviceCheckStatus]);

  React.useEffect(() => {
    async function finalizeResults(status: ServiceCheckStatus) {
      await performSpeedTest();
      if (status == 'SUCCESS') {
        setServiceCheckStatus('SUCCESS');
      } else if (status == 'FAILURE') {
        setServiceCheckStatus('FAILURE');
      }
    }
    if (
      fuelbossCheckIsLoaded &&
      chorusCheckIsLoaded &&
      mapboxCheckIsLoaded &&
      tqplayIconsCheckIsLoaded &&
      veracityNotificationsCheckIsLoaded &&
      signalRCheckIsLoaded
    ) {
      //result is ready
      if (
        fuelbossCheck != false &&
        chorusCheck != false &&
        mapboxCheck != false &&
        tqplayIconsCheck != false &&
        veracityNotificationsCheck != false &&
        signalRCheck != false
      ) {
        finalizeResults('SUCCESS');
      } else {
        finalizeResults('FAILURE');
      }
      const resultString =
        'Fuelboss:' +
        (fuelbossCheck == false ? fuelbossCheckError : 'Fuelboss backend check passed') +
        ', Chorus:' +
        (chorusCheck == false ? chorusCheckError : 'Chorus backend check passed') +
        ', Mapbox:' +
        (mapboxCheck == false ? mapboxCheckError : 'Mapbox API check passed') +
        ', VeracityNotification:' +
        (veracityNotificationsCheck == false ? veracityCheckError : 'Veracity Notifications API check passed') +
        ', TqplayIcons:' +
        (tqplayIconsCheck == false ? tqplayIconsCheckError : 'Tqplay Icons check passed') +
        ', SignalR:' +
        signalRCheckLog.join(';');
      const result: ConnectivityCheckResult = {
        id: auth.veracityId,
        veracityId: auth.veracityId,
        chorus: chorusCheck == false ? false : true,
        mapbox: mapboxCheck == false ? false : true,
        tqplayIcons: tqplayIconsCheck == false ? false : true,
        signalR: signalRCheck == false ? false : true,
        veracity: veracityNotificationsCheck == false ? false : true,
        results: resultString,
      };
      addConnectivityResultCallback(result);
    }
  }, [
    fuelbossCheckIsLoaded,
    chorusCheckIsLoaded,
    mapboxCheckIsLoaded,
    tqplayIconsCheckIsLoaded,
    veracityNotificationsCheckIsLoaded,
    signalRCheckIsLoaded,
  ]);

  const { getNetworkLatency, getDonwloadSpeed, getUploadSpeed } = useNetworkSpeedCheck();

  const performSpeedTest = async () => {
    console.log('Speed Test initiated');

    const latency = await getNetworkLatency();
    const downloadSpeed2 = await getDonwloadSpeed(2);
    const uploadSpeed2 = await getUploadSpeed(2);
    console.log('Speed Test finished');
    console.log('latency', latency);
    console.log('downloadSpeed', downloadSpeed2);
    console.log('uploadSpeed', uploadSpeed2);

    const speedTestResult = {
      id: '00000000-0000-0000-0000-000000000000',
      veracityId: auth.veracityId,
      downloadSpeed: String(downloadSpeed2) + ' Kbps',
      uploadSpeed: String(uploadSpeed2) + ' Kbps',
      latency: String(latency) + ' Milisec',
      results: ' ',
    };
    const header = new Headers();
    header.append('content-type', 'application/json');
    const fetchParams: RequestInit = {
      cache: 'no-cache',
      method: 'PUT',
      mode: 'same-origin',
      headers: header,
      body: JSON.stringify(speedTestResult),
    };

    const response = await fetch('/api/NetworkSpeedCheck/', fetchParams);
  };

  return (
    <div className={styles.serviceCheckWrapper}>
      <div className={styles['connectivity-header']}>FuelBoss connectivity check</div>
      <div className={styles['icon-wrapper']}>
        {serviceCheckStatus == 'INITIAL' && (
          <button className={styles['circular-wrapper-initial']}>
            <FontAwesomeIcon icon={healthIcon} className={styles.image} size={'4x'} onClick={onButtonClick} />
          </button>
        )}
        {serviceCheckStatus == 'RUNNING' && (
          <button className={styles['circular-wrapper-running']} disabled>
            <div className={styles['icon-div']}>
              <FontAwesomeIcon icon={healthIcon} className={styles.image} size={'4x'} />
            </div>
            <div className={styles['spinning-div']}></div>
          </button>
        )}
        {serviceCheckStatus == 'SUCCESS' && (
          <button className={styles['circular-wrapper-success']}>
            <FontAwesomeIcon icon={healthIcon} className={styles.image} size={'4x'} />
            <div className={styles['check-icon']}>
              <FontAwesomeIcon icon={checkCircle} size={'2x'} />
            </div>
          </button>
        )}
        {serviceCheckStatus == 'FAILURE' && (
          <button className={styles['circular-wrapper-failure']}>
            <FontAwesomeIcon icon={healthIcon} className={styles.image} size={'4x'} />
            <div className={styles['exclamation-icon']}>
              <FontAwesomeIcon icon={warningTriangle} size={'2x'} />
            </div>
          </button>
        )}
        {serviceCheckStatus == 'WARNING' && (
          <button className={styles['circular-wrapper-warning']}>
            <FontAwesomeIcon icon={healthIcon} className={styles.image} size={'4x'} />
          </button>
        )}
        {/* Results */}
        {(serviceCheckStatus == 'INITIAL' || serviceCheckStatus == 'RUNNING') && (
          <>
            <div className={styles.header}>{header}</div>
            <div className={styles.desc}>{desc}</div>
          </>
        )}
        {(serviceCheckStatus == 'SUCCESS' || serviceCheckStatus == 'FAILURE' || serviceCheckStatus == 'WARNING') && (
          <>
            <div className={styles.result}>{header}</div>
            <div className={styles.resultdesc}>{desc}</div>
          </>
        )}
      </div>
    </div>
  );
};
