import { Dispatch } from 'redux';
import {
  FuelbossLocationActions,
  FUELBOSSLOCATION_LOADING,
  GET_FUELBOSSLOCATIONS,
  ADD_FUELBOSSLOCATION,
  FUELBOSSLOCATION_FETCH_ERROR,
  ADD_FUELBOSSLOCATION_ERROR,
  DELETE_FUELBOSSLOCATION,
  DELETE_FUELBOSSLOCATION_ERROR,
  EDIT_FUELBOSSLOCATION_ERROR,
  EDIT_FUELBOSSLOCATION,
  FUELBOSSLOCATION_CLEAR_TOAST,
} from './types';
import { api } from '../../helpers/api';
import { FuelbossLocationResponseModel, FuelBossLocation } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import * as TYPE from './types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating location...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Location updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating location...</ModalResponse>;
const bulkUpdateLoaderModal: ReactElement = <ModalResponse status="loading">Bulk updating...</ModalResponse>;
const bulkSuccessLoaderModal: ReactElement = <ModalResponse status="loading">Bulk updating...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Location created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getFuelbossLocations = () => (dispatch: Dispatch<FuelbossLocationActions>) => {
  dispatch({
    type: FUELBOSSLOCATION_LOADING,
  });
  new api<FuelbossLocationResponseModel>({
    dispatch,
    errorType: FUELBOSSLOCATION_FETCH_ERROR,
  })
    .get(`/api/location`)
    .then((response) => {
      dispatch({
        type: GET_FUELBOSSLOCATIONS,
        payload: response,
      });
    });
};

export const addFuelbossLocation = (fuelbosslocation: FuelBossLocation, onSuccess: () => void) => (
  dispatch: Dispatch<FuelbossLocationActions>,
) => {
  dispatch({
    type: FUELBOSSLOCATION_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<FuelbossLocationResponseModel>({
    dispatch,
    errorType: ADD_FUELBOSSLOCATION_ERROR,
  })
    .post(`/api/location`, fuelbosslocation)
    .then((response) => {
      dispatch({
        type: ADD_FUELBOSSLOCATION,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteFuelbossLocation = (id: string) => (dispatch: Dispatch<FuelbossLocationActions>) => {
  dispatch({
    type: FUELBOSSLOCATION_LOADING,
  });

  const url = `/api/location/${id}`;
  new api<FuelbossLocationResponseModel>({
    dispatch,
    errorType: DELETE_FUELBOSSLOCATION_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_FUELBOSSLOCATION,
        payload: response,
      });
    });
};

export const editFuelbossLocation = (fuelbosslocation: FuelBossLocation, onSuccess: () => void) => (
  dispatch: Dispatch<FuelbossLocationActions>,
) => {
  dispatch({
    type: FUELBOSSLOCATION_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  new api<FuelbossLocationResponseModel>({
    dispatch,
    errorType: EDIT_FUELBOSSLOCATION_ERROR,
  })
    .put(`/api/location/${fuelbosslocation.locationId}`, fuelbosslocation)
    .then((response) => {
      dispatch({
        type: EDIT_FUELBOSSLOCATION,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

// export const getFuelbossLocations = () => (dispatch: Dispatch<FuelbossLocationActions>) => {
//   dispatch({
//     type: FUELBOSSLOCATION_LOADING,
//   });
//   new api<FuelbossLocationResponseModel>({
//     dispatch,
//     errorType: FUELBOSSLOCATION_FETCH_ERROR,
//   })
//     .get(`/api/location`)
//     .then((response) => {
//       dispatch({
//         type: GET_FUELBOSSLOCATIONS,
//         payload: response,
//       });
//     });
// };

export const bulkUpdate = (file: any) => (dispatch: Dispatch<FuelbossLocationActions>) => {
  dispatch({
    type: FUELBOSSLOCATION_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: bulkUpdateLoaderModal });

  new api<FuelbossLocationResponseModel>({
    dispatch,
    errorType: FUELBOSSLOCATION_FETCH_ERROR,
  })
    .post(`/api/location/BulkUpdate`, file)
    .then((response) => {
      dispatch({
        type: GET_FUELBOSSLOCATIONS,
        payload: response,
      });
      // dispatch({
      //   type: IMPORT_LOCATIONS,
      //   payload: response,
      // });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: bulkSuccessLoaderModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      // onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const locationClearToast = () => (dispatch: Dispatch<TYPE.FuelbossLocationActions>) => {
  dispatch({
    type: FUELBOSSLOCATION_CLEAR_TOAST,
  });
};
