import * as Yup from 'yup';
import locationsConfigs from './LocationsForm/locationsConfigs';

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

export default Yup.object().shape({
  port: Yup.string().matches(locationsConfigs.port.regex, validationMessage).required('Port required'),
  country: Yup.string().optional(''),
  countryAbbreviation: Yup.string().optional(''),
  portAbbreviation: Yup.string().required('Port abbreviation required'),
  areaOfOperation: Yup.string().optional(''),
  portType: Yup.string().optional(''),
  longitude: Yup.number().required('Longitude required'),
  latitude: Yup.number().required('Latitude required'),
  purpose: Yup.string().required('Purpose required'),
  type: Yup.string().required('Type required'),
  timezone: Yup.string().required('Timezone required'),
});
