import { Dispatch } from 'redux';
import {
  FleetVesselActions,
  FLEETVESSEL_LOADING,
  FLEETVESSEL_FETCH_ERROR,
  GET_FLEETVESSEL,
  DELETE_FLEETVESSEL,
  ADD_FLEETVESSEL,
  EDIT_FLEETVESSEL,
  FLEETVESSEL_ADD_ERROR,
  FLEETVESSEL_CLEAR_TOAST,
  FLEETVESSEL_SETCHARTERABLE_ERROR,
  SETCHARTERABLE_FLEETVESSEL,
} from './types';
import { api } from '../../helpers/api';
import { FleetVesselResponseModel, FleetVessel } from './models';
import { Vessel } from '../Vessels/models';

export const getFleetVessels = (id: string) => (dispatch: Dispatch<FleetVesselActions>) => {
  dispatch({
    type: FLEETVESSEL_LOADING,
  });

  new api<FleetVesselResponseModel>({
    dispatch,
    errorType: FLEETVESSEL_FETCH_ERROR,
  })
    .get(`/api/FleetVessels/${id}`)
    .then((response) => {
      dispatch({
        type: GET_FLEETVESSEL,
        payload: response,
      });
    });
};

export const addFleetVessel = (id: string, vessel: Vessel) => (dispatch: Dispatch<FleetVesselActions>) => {
  dispatch({
    type: FLEETVESSEL_LOADING,
  });

  new api<FleetVesselResponseModel>({
    dispatch,
    errorType: FLEETVESSEL_ADD_ERROR,
  })
    .post(`/api/FleetVessels/${id}`, vessel)
    .then((response) => {
      dispatch({
        type: GET_FLEETVESSEL,
        payload: response,
      });
      dispatch({
        type: ADD_FLEETVESSEL,
        payload: response,
      });
    });
};

export const deleteFleetVessel = (id: string, companyId: string) => (dispatch: Dispatch<FleetVesselActions>) => {
  dispatch({
    type: FLEETVESSEL_LOADING,
  });

  const url = `/api/FleetVessels/${id}`;
  new api<FleetVesselResponseModel>({
    dispatch,
    errorType: FLEETVESSEL_FETCH_ERROR,
  })
    .delete(url, companyId)
    .then((response) => {
      dispatch({
        type: DELETE_FLEETVESSEL,
        payload: response,
      });
    });
};

export const editFleetVessel = (id: string, vessel: FleetVessel) => (dispatch: Dispatch<FleetVesselActions>) => {
  dispatch({
    type: FLEETVESSEL_LOADING,
  });

  new api<FleetVesselResponseModel>({
    dispatch,
    errorType: FLEETVESSEL_ADD_ERROR,
  })
    .put(`/api/FleetVessels/${id}`, vessel)
    .then((response) => {
      dispatch({
        type: GET_FLEETVESSEL,
        payload: response,
      });
      dispatch({
        type: EDIT_FLEETVESSEL,
        payload: response,
      });
    });
};

export const setCharterableFleetVessel = (id: string, isCharterable: boolean, vessel: FleetVessel) => (
  dispatch: Dispatch<FleetVesselActions>,
) => {
  dispatch({
    type: FLEETVESSEL_LOADING,
  });

  new api<FleetVesselResponseModel>({
    dispatch,
    errorType: FLEETVESSEL_SETCHARTERABLE_ERROR,
  })
    .put(`/api/FleetVessels/charterable/${id}/${isCharterable}`, vessel)
    .then((response) => {
      dispatch({
        type: GET_FLEETVESSEL,
        payload: response,
      });
      dispatch({
        type: SETCHARTERABLE_FLEETVESSEL,
        payload: response,
      });
    });
};

export const companyFleetClearToast = () => (dispatch: Dispatch<FleetVesselActions>) => {
  dispatch({
    type: FLEETVESSEL_CLEAR_TOAST,
  });
};
