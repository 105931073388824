import React from 'react';
import { Link } from 'react-router-dom';
import './BackNavigation.scss';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const BackArrow = findIconDefinition({
  prefix: 'fal',
  iconName: 'arrow-left',
});

const StyledIconWrapper = styled.div`
  .iconWrapper {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: rgb(204, 236, 248);
    margin: 0 7px 0 0;
    transition: background-color 300ms ease-in-out 0s;
    width: 40px;
    height: 35px;
    &:hover {
      background-color: rgb(153, 217, 240);
    }
  }
`;

export default (props) => (
  <StyledIconWrapper onClick={props.onClick}>
    <div className="iconWrapper">
      <div className="backButton">
        <Link to={props.to}>
          <div className="backButtonTitle">
            <FontAwesomeIcon icon={BackArrow} /> {props.name && props.name}
          </div>
        </Link>
      </div>
    </div>
  </StyledIconWrapper>
);
