import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ServiceOfferings.module.scss';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';
import { ApplicationState } from '../../../../store';
import { Auth } from '../../../../store/Auth/models';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import DeliveryModeContainer from '../../Components/DeliveryModes';
import LowCarbonOptionsContainer from '../../Components/LowCarbonOprions';
import {
  getFleetVessels,
  deleteFleetVessel,
  addFleetVessel,
  editFleetVessel,
  setCharterableFleetVessel,
} from '../../../../store/CompanyFleet/actions';
import { Vessel } from '../../../../store/Vessels/models';
import MyFleet from '../../../../components/AdminComponents/MyFleet/MyFleet';
import { FleetVessel } from '../../../../store/CompanyFleet/models';
import { DeliveryModes } from '../../../../models/DeliveryMode';
import { CompanyDeliveryMode } from '../../../../models/CompanyDeliveryMode';
import { Label, Button } from 'reactstrap';
import { ReactComponent as ShipIcon } from '../../../../svgs/ship-to-ship.svg';
import { ReactComponent as PipeIcon } from '../../../../svgs/pipe-to-ship.svg';
import Pipelines from '../../../../components/AdminComponents/Pipelines/Pipelines';
import { getPipeline, deletePipeline, addPipeline } from '../../../../store/Pipelines/actions';
import { Pipeline } from '../../../../store/Pipelines/models';
import {
  addCharteredFleetVessel,
  deleteCharteredFleetVessel,
  getCharteredFleetVessels,
} from '../../../../store/CompanyCharteredFleet/actions';
//import OperationalAreas from '../ServiceOfferingAreas/OperationalAreas';
const LazyOperationalAreas = React.lazy(() => import('../ServiceOfferingAreas/OperationalAreas'));

interface OwnProps {
  auth: Auth;
  navAdminType: string;
  serviceLayer: AuthenticationServiceLayer;
}

const ServiceOfferings: React.FunctionComponent<OwnProps> = ({ auth, navAdminType, serviceLayer }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const routes = history.location.pathname.split('/');

  const [, , baseRoute] = routes;

  const {
    companyFleetLoading,
    companyFleetError,
    companyFleetErrorDetails,
    companyFleet,
    companyFleetIsLoaded,
    companyPipelineLoading,
    companyPipelineError,
    companyPipelineErrorDetails,
    companyPipelines,
    companyPipelineIsLoaded,
    companyCharteredFleetLoading,
    companyCharteredFleetError,
    companyCharteredFleetErrorDetails,
    companyCharteredFleet,
    companyCharteredFleetIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,
    companyPipelineLoading: state.pipelines.loading,
    companyPipelineError: state.pipelines.error,
    companyPipelineErrorDetails: state.pipelines.errorDetails,
    companyPipelines: state.pipelines.data,
    companyPipelineIsLoaded: state.pipelines.dataIsLoaded,
    companyCharteredFleetLoading: state.companyCharteredFleet.loading,
    companyCharteredFleetError: state.companyCharteredFleet.error,
    companyCharteredFleetErrorDetails: state.companyCharteredFleet.errorDetails,
    companyCharteredFleet: state.companyCharteredFleet.data,
    companyCharteredFleetIsLoaded: state.companyCharteredFleet.dataIsLoaded,
  }));

  const getCompanyCharteredFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyCharteredFleetIsLoaded) {
      dispatch(getCharteredFleetVessels(auth.company.companyId));
    }
  }, []);

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyFleetIsLoaded) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  const deleteFleetVesselCallback = React.useCallback((id: string) => {
    if (auth.company && auth.company.companyId) dispatch(deleteFleetVessel(id, auth.company.companyId));
  }, []);

  const addFleetVesselCallback = React.useCallback((vessel: Vessel) => {
    if (auth.company && auth.company.companyId) dispatch(addFleetVessel(auth.company.companyId, vessel));
  }, []);

  const editFleetVesselCallback = React.useCallback((vessel: FleetVessel) => {
    if (auth.company && auth.company.companyId) dispatch(editFleetVessel(auth.company.companyId, vessel));
  }, []);

  const deleteFleetVesselMethod = (id: string) => {
    deleteFleetVesselCallback(id);
  };

  const setCharterableFleetVesselCallback = React.useCallback((isCharterable: boolean, vessel: FleetVessel) => {
    if (auth.company && auth.company.companyId)
      dispatch(setCharterableFleetVessel(auth.company.companyId, isCharterable, vessel));
  }, []);

  const addCharteredFleetVesselCallback = React.useCallback((vessel: FleetVessel, allowShipOwnerToCreateNominations: boolean) => {
    if (auth.company && auth.company.companyId) dispatch(addCharteredFleetVessel(auth.company.companyId, allowShipOwnerToCreateNominations, vessel));
  }, []);

  const deleteCharteredFleetVesselCallback = React.useCallback((id: string) => {
    dispatch(deleteCharteredFleetVessel(id));
  }, []);

  const addFleetVesselMethod = (vessel: Vessel) => {
    addFleetVesselCallback(vessel);
    setIsAddShipActive(false);
  };

  const editFleetVesselMethod = (vessel: FleetVessel) => {
    editFleetVesselCallback(vessel);
  };

  const setCharterableFleetVesselMethod = (isCharterable: boolean, vessel: FleetVessel) => {
    setCharterableFleetVesselCallback(isCharterable, vessel);
  };

  const addCharteredFleetVesselMethod = (vessel: FleetVessel) => {
    if (!vessel.fleetCharterers) {
      vessel.fleetCharterers = [];
    }
    addCharteredFleetVesselCallback(vessel, false);
  };

  const deleteCharteredFleetVesselMethod = (id: string) => {
    deleteCharteredFleetVesselCallback(id);
  };

  const getCompanyPipelineCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyPipelineIsLoaded) {
      dispatch(getPipeline(auth.company.companyId));
    }
  }, []);

  const deletePipelineCallback = React.useCallback((id: string) => {
    if (auth.company && auth.company.companyId) dispatch(deletePipeline(id, auth.company.companyId));
  }, []);

  const addPipelineCallback = React.useCallback((pipeline: Pipeline) => {
    if (auth.company && auth.company.companyId) dispatch(addPipeline(auth.company.companyId, pipeline));
  }, []);

  const deletePipelineMethod = (id: string) => {
    deletePipelineCallback(id);
  };

  const addPipelineMethod = (pipeline: Pipeline) => {
    addPipelineCallback(pipeline);
    setIsAddPipelineActive(false);
  };

  const [displayAddShip, setDisplayAddShip] = React.useState(false);
  const [shipToShip, setShipToShip] = React.useState(false);
  const [isAddShipActive, setIsAddShipActive] = React.useState(true);

  const [displayAddPipeline, setDisplayAddPipeline] = React.useState(true);
  const [pipeToShip, setPipeToShip] = React.useState(false);
  const [isAddPipelineActive, setIsAddPipelineActive] = React.useState(true);

  const calcultateDeliveryModesArray = () => {
    const ret: string[] = [];

    if (auth.company && auth.company.companyDeliveryModes) {
      auth.company.companyDeliveryModes.forEach((element: CompanyDeliveryMode) => {
        ret.push(element.deliveryMode.mode);
      });
    }
    return ret;
  };

  React.useEffect(() => {
    const deliveryModes: string[] = calcultateDeliveryModesArray();
    if (deliveryModes.includes(DeliveryModes.Ship)) {
      setShipToShip(true);
    } else {
      setShipToShip(false);
    }

    if (deliveryModes.includes(DeliveryModes.Pipe)) {
      setPipeToShip(true);
    } else {
      setPipeToShip(false);
    }
  }, [auth]);

  const [activeTab, setActiveTab] = React.useState(baseRoute);
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  React.useEffect(() => {
    const finalRoute = routes[routes.length - 1];
    if (finalRoute != 'delivery') {
      toggle(finalRoute);
      history.push(`/${navAdminType}admin/delivery/${finalRoute}`);
    } else {
      toggle('DeliveryMode');
      history.push(`/${navAdminType}admin/delivery/DeliveryMode`);
    }

    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }
    if (!companyPipelineIsLoaded) {
      getCompanyPipelineCallback();
    }
    if (!companyCharteredFleetIsLoaded) {
      getCompanyCharteredFleetCallback();
    }
  }, []);

  const onClickAddShip = () => {
    setDisplayAddShip(!displayAddShip);
  };

  const onClickAddPipeline = () => {
    setDisplayAddPipeline(!displayAddPipeline);
  };

  const hideAddShip = () => {
    setIsAddShipActive(false);
    setDisplayAddShip(false);
  };

  const hideAddPipeline = () => {
    setIsAddPipelineActive(false);
    setDisplayAddPipeline(false);
  };

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
  const refShip = React.useRef(null);
  const executeScroll = () => scrollToRef(refShip);

  const refPipe = React.useRef<HTMLDivElement>(null);
  const executeScrollPipe = () => scrollToRef(refPipe);

  const addAsset = (event: any, deliveryMode: DeliveryModes) => {
    event.stopPropagation();
    switch (deliveryMode) {
      case DeliveryModes.Ship:
        toggle('ManageAssets');
        setIsAddShipActive(true);
        setDisplayAddShip(true);
        hideAddPipeline();
        executeScroll();
        break;
      case DeliveryModes.Truck:
        break;
      case DeliveryModes.Pipe:
        toggle('ManageAssets');
        setIsAddPipelineActive(true);
        setDisplayAddPipeline(true);
        hideAddShip();
        if (refPipe.current !== null) {
          refPipe.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
        break;
      case DeliveryModes.Container:
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles['confiurations-wrapper']} style={{ height: '95vh' }}>
      <Nav tabs className={`bg-white ${styles['navbar']}`}>
        <NavItem className={classnames({ active: activeTab == 'DeliveryMode' })}>
          <NavLink
            className={classnames({ active: activeTab == 'DeliveryMode' })}
            onClick={() => {
              toggle('DeliveryMode');
              history.push(`/${navAdminType}admin/delivery/DeliveryMode`);
            }}
          >
            Select delivery mode
          </NavLink>
        </NavItem>
        <NavItem className={classnames({ active: activeTab == 'ManageAssets' })}>
          <NavLink
            className={classnames({ active: activeTab == 'ManageAssets' })}
            onClick={() => {
              toggle('ManageAssets');
              history.push(`/${navAdminType}admin/delivery/ManageAssets`);
            }}
          >
            Manage assets
          </NavLink>
        </NavItem>
        <NavItem className={classnames({ active: activeTab == 'LowCarbon' })}>
          <NavLink
            className={classnames({ active: activeTab == 'LowCarbon' })}
            onClick={() => {
              toggle('LowCarbon');
              history.push(`/${navAdminType}admin/delivery/LowCarbon`);
            }}
          >
            Low carbon options
          </NavLink>
        </NavItem>
        {/* <NavItem className={classnames({ active: activeTab == 'OperationalAreas' })}>
          <NavLink
            className={classnames({ active: activeTab == 'OperationalAreas' })}
            onClick={() => {
              toggle('OperationalAreas');
              history.push(`/${navAdminType}admin/delivery/OperationalAreas`);
            }}
          >
            Operational areas
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab} style={{ marginLeft: '0.7em' }}>
        <TabPane tabId="DeliveryMode">
          <div className="delivery-mode-wrapper">
            <Label style={{ display: 'block', marginTop: '20px' }}>
              Specify which delivery modes your company is (or will be) offering, or delivery modes you're interested in
              receiving spot quotations for.
            </Label>
            <DeliveryModeContainer auth={auth} addAsset={addAsset} />
          </div>
        </TabPane>
        <TabPane tabId="ManageAssets">
          <div className="manage-assets-wrapper">
            <Label style={{ display: 'block', marginTop: '20px' }}>
              Based on the delivery modes your company offers, please add the bunkering assets to assign them to your
              bunkering operations.
            </Label>
            {shipToShip && (
              <div className="ship-to-ship-section">
                <div ref={refShip} className={styles['ship-to-ship-header']}>
                  <div className="icon">
                    <ShipIcon className={classnames(styles.ship)} />
                  </div>

                  <div className={classnames(styles.text)}>Ship to ship</div>
                  {/* {!displayAddShip && (
                    <div className="add">
                      <Button className={styles['add-btn']} color="primary" onClick={() => onClickAddShip()}>
                        Add LBV
                      </Button>
                    </div>
                  )}
                  {displayAddShip && (
                    <div className={styles['add']}>
                      <div className={styles['button-arrowdown']}></div>

                      <Button className={styles['add-btn-active']} onClick={() => onClickAddShip()}>
                        Add LBV
                      </Button>
                    </div>
                  )} */}
                </div>
                <MyFleet
                  chooseVesselMethod={addFleetVesselMethod}
                  companyFleet={companyFleet}
                  isLoading={companyFleetLoading}
                  hasError={companyFleetError}
                  errorDetails={companyFleetErrorDetails}
                  deleteVesselMethod={deleteFleetVesselMethod}
                  displaySearchVesselCustomized={false}
                  setDisplaySearchVesselCustomized={setDisplayAddShip}
                  isSearchVesselCustomizedActive={isAddShipActive}
                  setSearchVesselCustomizedActive={setIsAddShipActive}
                  serviceLayer={serviceLayer}
                  editVesselMethod={editFleetVesselMethod}
                  setCharterableFleetVesselMethod={setCharterableFleetVesselMethod}
                  companyCharteredFleet={companyCharteredFleet}
                  addCharteredFleetVesselMethod={addCharteredFleetVesselMethod}
                  deleteCharteredFleetVesselMethod={deleteCharteredFleetVesselMethod}
                />
              </div>
            )}
            {pipeToShip && (
              <div className="pipe-to-ship-section">
                <div ref={refPipe} className={styles['pipe-to-ship-header']}>
                  <div className="icon">
                    <PipeIcon className={classnames(styles.pipe)} />
                  </div>

                  <div className={classnames(styles.text)}>Pipe to ship</div>
                  {/* {!displayAddPipeline && (
                    <div className="add">
                      <Button className={styles['add-btn']} color="primary" onClick={() => onClickAddPipeline()}>
                        Add pipeline
                      </Button>
                    </div>
                  )}
                  {displayAddPipeline && (
                    <div className={styles['add']}>
                      <div className={styles['button-arrowdown']}></div>

                      <Button className={styles['add-btn-active']} onClick={() => onClickAddPipeline()}>
                        Add pipeline
                      </Button>
                    </div>
                  )} */}
                </div>
                <Pipelines
                  addPipelineMethod={addPipelineMethod}
                  companyPipelines={companyPipelines}
                  isLoading={companyPipelineLoading}
                  hasError={companyPipelineError}
                  errorDetails={companyPipelineErrorDetails}
                  deletePipelineMethod={deletePipelineMethod}
                  displayAddPipeline={displayAddPipeline}
                  setDisplayAddPipeline={setDisplayAddPipeline}
                  isAddPipelineActive={isAddPipelineActive}
                  setAddPipelineActive={setIsAddPipelineActive}
                  serviceLayer={serviceLayer}
                />
              </div>
            )}
          </div>
        </TabPane>
        <TabPane tabId="LowCarbon">
          <div className="low-carbon-wrapper">
            <Label style={{ display: 'block', marginTop: '20px' }}>
              Activate low carbon options to enable your customers to order these in the nomination form.
            </Label>
            <LowCarbonOptionsContainer auth={auth} />
          </div>
        </TabPane>
        {/* <TabPane tabId="OperationalAreas">
          <div className="operational-areas-wrapper">
            <LazyOperationalAreas />
          </div>
        </TabPane> */}
      </TabContent>
    </div>
  );
};
export default ServiceOfferings;
