import React from 'react';
import { FuelBossLocation } from '../../../../../store/FuelbossLocations/models';
import FormikWrapper from '../Formik/FormikWrapper';
import { Loading } from '../../../../Loading';

interface OwnProps {
  editLocation: (location: FuelBossLocation) => void;
  locations: FuelBossLocation[];
  locationId: string;
  isLoading?: boolean;
}

const EditLocation: React.FC<OwnProps> = ({ editLocation, isLoading, locationId, locations }) => {
  const submitLocation = (location: FuelBossLocation) => {
    editLocation(location);
  };

  const location: FuelBossLocation | undefined = locations.find((it) => it.locationId === locationId);

  if (isLoading) {
    return <Loading />;
  }

  return <FormikWrapper header="Edit location" location={location} addLocation={submitLocation} />;
};

export default React.memo(EditLocation);
