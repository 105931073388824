import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import {IntegrationAssetType} from '../../../../../store/IntegrationAssetType/models';

import { Role } from '../../../../../store/Roles/models';
import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';
import { Formik } from 'formik';
import IntegrationAssetTypeForm from '../IntegrationAssetForm/IntegrationAssetTypeForm'; 
import integrationAssetTypeFormValidation from '../FormValidations/integrationAssetTypeFormValidation';
import { initialValues } from '../FormInitialValues/integrationAssetTypeFormInitialValues';


interface OwnProps {
  addNewIntegrationAssetType: (integrationAssetType: IntegrationAssetType) => void;
  navAdminType: string;
  integrationAssetType?: IntegrationAssetType;
}

const AddIntegrationAssetType: React.FunctionComponent<OwnProps> = ({ addNewIntegrationAssetType, navAdminType, integrationAssetType }) => {
  const submitIntegrationAssetType = (integrationAssetType: IntegrationAssetType) => {
    addNewIntegrationAssetType(integrationAssetType);
  };

  return (
    <Formik
      initialValues={ integrationAssetType? integrationAssetType : initialValues}
      validationSchema={integrationAssetTypeFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetTypeForm
          integrationAssetType={integrationAssetType}
          header={'Add integration asset type'}
          saveMethod={submitIntegrationAssetType}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationAssetType);
