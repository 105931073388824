import { BIcompanyFleetWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';
export const BI_COMPANY_FLEET_LOADING = 'BIcompanyFleet/loading';
export type BI_COMPANY_FLEET_LOADING = typeof BI_COMPANY_FLEET_LOADING;
export const GET_BI_COMPANY_FLEET = 'BIcompanyFleet/get';
export type GET_BI_COMPANY_FLEET = typeof GET_BI_COMPANY_FLEET;
export const BI_COMPANY_FLEET_FETCH_ERROR = 'BIcompanyFleet/fetchError';
export type BI_COMPANY_FLEET_FETCH_ERROR = typeof BI_COMPANY_FLEET_FETCH_ERROR;
export const ADD_BI_COMPANY_FLEET = 'BIcompanyFleet/add';
export type ADD_BI_COMPANY_FLEET = typeof ADD_BI_COMPANY_FLEET;
export const ADD_BI_COMPANY_FLEET_ERROR = 'BIcompanyFleet/addError';
export type ADD_BI_COMPANY_FLEET_ERROR = typeof ADD_BI_COMPANY_FLEET_ERROR;
export const BI_COMPANY_FLEET_FETCH_SUCCESS = 'BIcompanyFleet/fetchSuccess';
export type BI_COMPANY_FLEET_FETCH_SUCCESS = typeof BI_COMPANY_FLEET_FETCH_SUCCESS;
export const DELETE_BI_COMPANY_FLEET = 'BIcompanyFleet/delete';
export type DELETE_BI_COMPANY_FLEET = typeof DELETE_BI_COMPANY_FLEET;
export const DELETE_BI_COMPANY_FLEET_ERROR = 'BIcompanyFleet/deleteError';
export type DELETE_BI_COMPANY_FLEET_ERROR = typeof DELETE_BI_COMPANY_FLEET_ERROR;
export const EDIT_BI_COMPANY_FLEET = 'BIcompanyFleet/edit';
export type EDIT_BI_COMPANY_FLEET = typeof EDIT_BI_COMPANY_FLEET;
export const BI_COMPANY_CLEAR_TOAST = 'BIcompanyFleet/clearToast';
export type BI_COMPANY_CLEAR_TOAST = typeof BI_COMPANY_CLEAR_TOAST;
export type BIcompanyFleetActions =
  | {
      type: GET_BI_COMPANY_FLEET;
      payload: BIcompanyFleetWrapperModel['data'];
    }
  | {
      type: BI_COMPANY_FLEET_LOADING;
    }
  | {
      type: BI_COMPANY_FLEET_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_BI_COMPANY_FLEET;
      payload: BIcompanyFleetWrapperModel['data'];
    }
  | {
      type: ADD_BI_COMPANY_FLEET_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: BI_COMPANY_FLEET_FETCH_SUCCESS;
    }
  | {
      type: DELETE_BI_COMPANY_FLEET;
      payload: BIcompanyFleetWrapperModel['data'];
    }
  | {
      type: DELETE_BI_COMPANY_FLEET_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: BI_COMPANY_CLEAR_TOAST;
    }
  | {
      type: EDIT_BI_COMPANY_FLEET;
      payload: BIcompanyFleetWrapperModel['data'];
    };
