import * as TYPE from './types';
import { ApplicationState } from '..';
import { ContractDocument } from './models';

export const contractDocumentsReducer = (
  state: ApplicationState['contract_documents'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.CompanyActions,
): ApplicationState['contract_documents'] => {
  switch (action.type) {
    case TYPE.CONTRACT_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_CONTRACT_DOCUMENT:
      /* let updatedDocs: ContractDocument[] = [];
      const newDocs = action.payload;
      if (newDocs.length > 0) {
        const filtered: ContractDocument[] = state.data.filter((x) => x.chorusId != newDocs[0].chorusId);
        const updatedDocs = filtered.concat(newDocs);
      } else {
        updatedDocs = newDocs;
      } */
      return {
        ...state,
        data:
          action.payload.length > 0
            ? state.data.filter((x) => x.chorusId != action.payload[0].chorusId).concat(action.payload)
            : state.data,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.CONTRACT_DOCUMENT_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.DELETE_CONTRACT_DOCUMENT:
      return {
        ...state,
        data:
          action.payload.length > 0
            ? state.data.filter((x) => x.chorusId != action.payload[0].chorusId).concat(action.payload)
            : state.data,
        loading: false,
        toastData: {
          content: 'Contract document deleted',
          type: 'info',
        },
      };
    case TYPE.DELETE_CONTRACT_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not delete the contract document. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.CONTRACT_DOCUMENT_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.ADD_CONTRACT_DOCUMENT:
      return {
        ...state,
        data:
          action.payload.length > 0
            ? state.data.filter((x) => x.chorusId != action.payload[0].chorusId).concat(action.payload)
            : state.data,
        loading: false,
        error: false,
      };
    case TYPE.ADD_CONTRACT_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content:
            'Something went wrong! We could not add the contract document. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    default:
      return { ...state };
  }
};
