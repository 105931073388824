import {
  AuthenticationServiceLayer,
  doesUserHavePermission,
  getCompanyDetails,
  handleFetchError,
  ICompany,
  IContract,
  IPipeline,
  isAdmin,
  isCustomer,
  IShipWithCompanyId,
  IShipWithId,
  isScheduler,
  isSchedulerCaptain,
  IThirdPartyContact,
  IUserProfile,
  LocationService,
  NegotiationState,
  NominationSelection,
  SANDBOX_ID_LIVE,
  useContractService,
  useLocationTimeZone,
  UserPermissionContext,
  UserRole,
  useThirdPartyContactService,
} from '@teqplay/chorus-components';
import useForm from '@teqplay/react-useform';
import { Button, ButtonGroup } from '@teqplay/teqplay-ui';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import PermissionsWarning from './Permissions/PermissionWarning';
import CounterProposalDialog from './CounterProposalDialog/CounterProposalDialog';
import NegotiationActions from './NegotiationActions/NegotiationActions';
import NegotiationStatusHeader from './NegotiationStatusHeader/NegotiationStatusHeader';
import NominationSummary from './NominationSummary/NominationSummary';
import PromptHistoryButton from './PromptHistoryButton/PromptHistoryButton';
import RecurringNominationForm from './RecurringNominationForm/RecurringNominationForm';
import { RecurringNomination } from '../../../../store/RecurringNomination/models';
import {
  parseRecurringNominationValues,
  validateRecurringNominationForm,
} from './Validators/recurringNominationValidation';
import { useGlobalDataService } from '@teqplay/chorus-components';

interface NominationNegotiationProps {
  readOnly: boolean;
  userProfile: IUserProfile;
  isNominationInSandbox: boolean;
  vendorCompanyId?: ICompany['_id'];
  // Nomination Selection & History properties
  initialNomination: RecurringNomination;
  nominationHistory: RecurringNomination[];
  nominationSelection: NominationSelection;
  // Auth Service handling all the api calls
  authenticationService: AuthenticationServiceLayer;
  // List properties from the global state
  companies: ICompany[];
  locationService: LocationService;
  bunkerShips: IShipWithCompanyId[];
  pipelines: IPipeline[];
  // presentation props
  showProposalInformation?: boolean;
  showFuelbossStates: boolean;
  enableAlternativeFuelSelector: boolean;
  className?: string;
  // update / api functions
  updatePromptNomination: (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE' | 'DELETE',
    updatedNomination: RecurringNomination,
    suppressNotification?: boolean,
    amendAndAccept?: boolean,
  ) => Promise<void>;
  updateSandboxPromptNomination: (updatedNomination: RecurringNomination, sandboxId: string) => void;
  updatePromptNominationState: (newState: string, onBehalf: boolean, reason?: string) => void;
  //delegatePromptNomination?: (promptNomination: RecurringNomination, supplierId: string) => void;
  acceptPendingNomination?: (pendingNomination: RecurringNomination) => void;
  generateAndSendOrderConfirmation?: () => void;

  showDeliveryModeSelection: boolean;
  contractsList: IContract[];
  //hideDelegatingCompany?: boolean; // default is false

  // Optional callbacks for DNV
  onOrderPDFConfirmed?: () => void;
  // Optional to compare with another nomination
  nominationEventToCompareWith?: RecurringNomination;
  // Optional delegation state

  nominationType?: 'SELECTED_NOMINATION' | 'ASSOCIATED_NOMINATION';
  delegationState?: 'EDITING_SN' | 'EDITING_AN' | 'CREATING_DELEGATION' | 'OVERVIEW';
  setDelegationState?: (state: 'EDITING_SN' | 'EDITING_AN' | 'CREATING_DELEGATION' | 'OVERVIEW') => void;
  cancelDelegatedNomination?: (originNominationId: string, reason?: string) => void;
}

interface ISubmitOptions {
  onBehalf: boolean;
  amendAndAccept: boolean;
}

enum LAST_SUBMIT_ACTION {
  SUBMIT,
  SUBMIT_AND_APPROVE,
}

const RecurringNominationNegotiation: React.FunctionComponent<NominationNegotiationProps> = (props) => {
  const {
    initialNomination,
    nominationSelection,
    userProfile,
    delegationState,
    nominationType,
    cancelDelegatedNomination,
  } = props;

  const getNewRecurringNomination = () => {
    return {} as RecurringNomination;
  };
  // State variables
  const [formState, setFormState] = useState<'EDIT' | 'EDIT_ON_BEHALF' | 'SUMMARY'>('SUMMARY');
  const [counterProposalCandidate, setCounterProposalCandidate] = useState<RecurringNomination>();
  const [initialNominationValues, setInitialNominationValues] = useState<RecurringNomination>(
    props.initialNomination ? props.initialNomination : getNewRecurringNomination(),
  );
  const [lastSubmitAction, setLastSubmitAction] = useState(LAST_SUBMIT_ACTION.SUBMIT);

  const [inActiveNomination, setInActiveNomination] = useState<boolean>(false);

  const { allowedContacts } = useThirdPartyContactService(
    props.authenticationService,
    props.userProfile.companyId,
    props.userProfile.roles,
  );

  const [companyContacts, setCompanyContacts] = useState<IThirdPartyContact[]>([]);

  useEffect(() => {
    setCompanyContacts(allowedContacts);
  }, [allowedContacts]);

  useEffect(() => {
    if (delegationState === 'OVERVIEW' && formState !== 'SUMMARY') {
      return setFormState('SUMMARY'), setInActiveNomination(false);
    }
    if (
      (delegationState === 'EDITING_SN' && nominationType === 'ASSOCIATED_NOMINATION') ||
      (delegationState === 'EDITING_AN' && nominationType === 'SELECTED_NOMINATION')
    ) {
      return setFormState('EDIT'), setInActiveNomination(true);
    }
  }, [delegationState]);

  useEffect(() => {
    if (props.initialNomination) {
      setInitialNominationValues(props.initialNomination);
    } else {
      setInitialNominationValues(getNewRecurringNomination());
    }
  }, []);

  const { vendorsWithContract } = useContractService(props.authenticationService);

  const userRoles = userProfile ? userProfile.roles : undefined;
  const selectedNomination = nominationSelection.selectedHistoricNomination
    ? nominationSelection.selectedHistoricNomination
    : initialNomination;
  const sandboxId = initialNomination ? undefined : undefined;
  // Form Logic Manager
  const {
    handleSubmit,
    values,
    errors,
    onChangeValue,
    triggerValidation,
    isDifferentFromInitial,
    setAllValues,
  } = useForm<RecurringNomination>({
    initialValues: initialNominationValues,
    validate: validateRecurringNomination,
    onValidSubmit: submitPromptNomination,
  });

  const timezone = useLocationTimeZone(values?.locationId, props.locationService);

  const customerFleetCompanyId = selectedNomination?.companyId
    ? selectedNomination.companyId
    : props.userProfile.companyId;

  //const customerFleet = useCompanyFleetService(props.authenticationService, customerFleetCompanyId);
  const { userFleet: customerFleet, pipelines } = useGlobalDataService(props.authenticationService, props.userProfile);

  const errorMessage = 'Please fill in all fields correctly.';

  const latestNominationIsSelected =
    props.nominationSelection.selectedNominationIndex + 1 === props.nominationHistory?.length;
  const proposalsAmount = props.nominationHistory ? props.nominationHistory.length : 0;

  const receivingVessel =
    customerFleet.find((ship) => ship._id === selectedNomination?.receivingShipId)?.name ||
    selectedNomination?.attributes?.receivingShipName;
  const { userPermissions } = useContext(UserPermissionContext);

  const editFormActive = formState === 'EDIT' || formState === 'EDIT_ON_BEHALF';

  const customerCompanyName =
    selectedNomination && selectedNomination.companyId
      ? getCompanyDetails(props.companies, selectedNomination.companyId).companyName
      : 'your customer';
  const delegatedCompanyName =
    selectedNomination && selectedNomination.vendorCompanyId
      ? getCompanyDetails(props.companies, selectedNomination.vendorCompanyId).companyName
      : undefined;

  if (!doesUserHavePermission(userPermissions, 'NOMINATION_READ')) {
    return <PermissionsWarning>You are not allowed to view this Nomination'</PermissionsWarning>;
  }

  const isContractHolder = props.userProfile.companyId === initialNomination.companyId;

  return (
    <div
      className={`prompt-negotiation-wrapper ${props.className || ''} ${
        !inActiveNomination && (formState === 'EDIT' || formState === 'EDIT_ON_BEHALF')
          ? 'active-nomination'
          : 'inactive-nomination'
      }`}
    >
      <div
        className={`${initialNomination ? 'negotiation-header-wrapper' : ''} ${
          ((false && isContractHolder) || (isScheduler(props.userProfile.roles) && false)) &&
          !formState.includes('EDIT')
            ? 'delegated-nomination'
            : ''
        }`}
      >
        <NegotiationStatusHeader
          receivingVesselName={receivingVessel}
          negotiationItem={selectedNomination}
          isNominationInSandbox={props.isNominationInSandbox}
          userProfile={userProfile}
          showFuelbossStates={props.showFuelbossStates}
          companies={props.companies}
          isDelegatedNomination={false}
        />

        {/** todo remove it */}
        <PromptHistoryButton
          //proposalsAmount={proposalsAmount}
          selectedItemIndex={props.nominationSelection.selectedNominationIndex}
          eventHistory={props.nominationHistory}
          seeNextProposal={() => {
            props.nominationSelection.selectNextHistoricNomination();
            setFormState('SUMMARY');
          }}
          seePreviousProposal={() => {
            props.nominationSelection.selectPreviousHistoricNomination();
            setFormState('SUMMARY');
          }}
          editFormActive={formState === 'EDIT' || formState === 'EDIT_ON_BEHALF'}
          sandbox={props.isNominationInSandbox}
          latestItemIsSelected={latestNominationIsSelected}
        />

        <div className="prompt-negotiation-actions">
          {formState === 'SUMMARY' && (
            <>
              {
                <NegotiationActions
                  updateEvent={(action, event) => {
                    props.updatePromptNomination(action, event as RecurringNomination);
                  }}
                  activateEditMode={activateEditMode}
                  changeStateWhenValid={changeStateWhenValid}
                  isDifferentFromInitial={isDifferentFromInitial}
                  isNominationInSandbox={props.isNominationInSandbox}
                  readOnly={props.readOnly}
                  userProfile={props.userProfile}
                  userRoles={userRoles}
                  initialEvent={initialNomination}
                  selectedEvent={selectedNomination}
                  //@ts-ignore
                  acceptPendingNomination={props.acceptPendingNomination}
                  error={errors && !isEmpty(errors) ? errorMessage : undefined}
                  customerCompanyName={customerCompanyName}
                  delegatedCompanyName={delegatedCompanyName}
                  generateAndSendOrderConfirmation={props.generateAndSendOrderConfirmation}
                  onOrderPDFConfirmed={props.onOrderPDFConfirmed}
                  delegatedNominationEventId={undefined}
                  setDelegationState={props.setDelegationState}
                  nominationType={nominationType}
                  delegationBtnEnabled={false}
                  cancelDelegatedNomination={undefined}
                />
              }
            </>
          )}

          {editFormActive && !inActiveNomination && (
            <ButtonGroup>
              {formState === 'EDIT' && <>{submitButtons()}</>}

              {formState === 'EDIT_ON_BEHALF' && <>{submitButtons(true)}</>}
              <Button
                className="discard-button"
                preventDoubleClick
                onClick={() => {
                  setFormState('SUMMARY');
                  if (props.setDelegationState) {
                    props.setDelegationState('OVERVIEW');
                  }
                }}
              >
                Discard
              </Button>
            </ButtonGroup>
          )}
        </div>
      </div>

      <CounterProposalDialog
        amendOrCounterNegotiation={(action, event) => {
          amendOrCounterNomination(action, event as RecurringNomination, false);
        }}
        counterProposalCandidate={counterProposalCandidate}
        ignoringApproveRequest={lastSubmitAction === LAST_SUBMIT_ACTION.SUBMIT_AND_APPROVE}
        clearCounterProposalCandidate={() => {
          setCounterProposalCandidate(undefined);
        }}
      />

      <div>
        {(formState === 'EDIT' || formState === 'EDIT_ON_BEHALF') && latestNominationIsSelected && (
          <form noValidate className="form-wrapper-prompt">
            {values && (
              <>
                <RecurringNominationForm
                  currentUser={userProfile}
                  creatingNewNomination={false}
                  values={values}
                  //prevValues={nominationSelection.previousHistoricNomination}
                  prevValues={undefined}
                  authenticationService={props.authenticationService}
                  locationService={props.locationService}
                  errors={errors}
                  bunkerShips={props.bunkerShips}
                  customerFleet={customerFleet}
                  onChangeFormValue={onChangeValue}
                  companies={props.companies}
                  //creatingNominationOnBehalf={false}
                  showDeliveryModeSelection={props.showDeliveryModeSelection}
                  vendorsWithContract={vendorsWithContract}
                  contractsList={props.contractsList}
                  setAllValues={setAllValues}
                  pipelines={props.pipelines}
                  enableAlternativeFuelSelector={props.enableAlternativeFuelSelector}
                  editType={formState}
                  customerCompanyName={customerCompanyName}
                  nominationEventToCompareWith={props.nominationEventToCompareWith}
                  //isDelegatedNomination={false}
                  disabled={inActiveNomination}
                />
                {!inActiveNomination && (
                  <>
                    {isScheduler(userRoles) || isSchedulerCaptain(userRoles) ? (
                      <div className="form-action-bar">
                        <ButtonGroup>
                          {formState === 'EDIT' && <>{submitButtons()}</>}

                          {formState === 'EDIT_ON_BEHALF' && <>{submitButtons(true)}</>}

                          <Button
                            preventDoubleClick
                            className="discard-button"
                            onClick={() => {
                              setFormState('SUMMARY');
                              if (props.setDelegationState) {
                                props.setDelegationState('OVERVIEW');
                              }
                            }}
                          >
                            Discard
                          </Button>
                        </ButtonGroup>
                      </div>
                    ) : (
                      <div className="form-action-bar">
                        <ButtonGroup>
                          {submitButtons()}
                          <Button
                            className="discard-button"
                            preventDoubleClick
                            onClick={() => {
                              setFormState('SUMMARY');
                              if (props.setDelegationState) {
                                props.setDelegationState('OVERVIEW');
                              }
                            }}
                          >
                            Discard
                          </Button>
                        </ButtonGroup>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </form>
        )}

        {(!editFormActive || !latestNominationIsSelected) && (
          <>
            {selectedNomination && (
              <NominationSummary
                currentUser={userProfile}
                latestNomination={props.initialNomination}
                nominationSelection={props.nominationSelection}
                locationService={props.locationService}
                pipelines={props.pipelines}
                companies={props.companies}
                bunkerShips={props.bunkerShips}
                contractsList={props.contractsList}
                proposalsAmount={proposalsAmount}
                companyContacts={companyContacts}
                nominationEventToCompareWith={props.nominationEventToCompareWith}
                isDelegatedNomination={false}
              />
            )}
          </>
        )}
      </div>
    </div>
  );

  function submitButtons(onBehalf: boolean = false) {
    const isProposalOfCustomer =
      values && values.author && values.state === 'PROPOSED' && isCustomer(props.userProfile.roles); //(isCustomer(values.author.roles));

    const isEditedBySupplier =
      (isScheduler(props.userProfile.roles) ||
        isSchedulerCaptain(props.userProfile.roles) ||
        isAdmin(props.userProfile.roles)) &&
      !onBehalf;

    return (
      <>
        <Button
          preventDoubleClick
          primary
          title={isDifferentFromInitial ? '' : 'No changes detected'}
          disabled={sandboxId === SANDBOX_ID_LIVE}
          onClick={(e) => {
            setLastSubmitAction(LAST_SUBMIT_ACTION.SUBMIT);
            handleSubmit(e, {
              onBehalf,
              amendAndAccept: false,
            });
          }}
        >
          {onBehalf ? 'Submit for Customer' : 'Submit'}
        </Button>
        {isProposalOfCustomer && isEditedBySupplier && (
          <Button
            preventDoubleClick
            primary
            title={isDifferentFromInitial ? '' : 'No changes detected'}
            disabled={sandboxId === SANDBOX_ID_LIVE}
            onClick={(e) => {
              setLastSubmitAction(LAST_SUBMIT_ACTION.SUBMIT_AND_APPROVE);
              handleSubmit(e, {
                onBehalf,
                amendAndAccept: true,
              });
            }}
          >
            {onBehalf ? 'Submit and approve for Customer' : 'Submit and approve'}
          </Button>
        )}
      </>
    );
  }

  function validateRecurringNomination(nominationToValidate: RecurringNomination) {
    const roles: UserRole[] = props.userProfile.roles;

    const errors: { [index: string]: any } = validateRecurringNominationForm(
      nominationToValidate,
      props.userProfile,
      roles,
      props.showDeliveryModeSelection,
      timezone.value,
    );

    if (timezone.loading && !errors['locationId']) {
      errors['locationId'] = 'Location is still loading. Submit again once loaded';
    }

    return errors;
  }

  async function submitPromptNomination(promptNomination: RecurringNomination, options: ISubmitOptions) {
    const parsedNomination = parseRecurringNominationValues(promptNomination);

    const sandboxId = props.initialNomination ? undefined : undefined;
    if (sandboxId) {
      await props.updateSandboxPromptNomination(parsedNomination, sandboxId);
    } else {
      const nomination = cloneDeep(parsedNomination);
      await amendOrCounterNomination('AMEND', nomination, options.amendAndAccept);
    }

    if (props.setDelegationState) {
      props.setDelegationState('OVERVIEW');
    }
  }

  async function amendOrCounterNomination(
    action: 'AMEND' | 'COUNTER',
    nominationToUpdate: RecurringNomination,
    amendAndAccept: boolean,
  ) {
    try {
      await props.updatePromptNomination(action, nominationToUpdate, false, amendAndAccept);
      setFormState('SUMMARY');
    } catch (error) {
      // 422 => Unprocessable Entity code from the backend which tells us a amend request is not possible.
      // Therefore a counter should be done.
      // the 422 specifically will be thrown by the updatePromptNomination function to handle in the UI here
      // Other errors will be handled by the service, but there's a fallback in place here just to be sure.
      //@ts-ignore
      if (error.status && error.status === 422) {
        setCounterProposalCandidate(nominationToUpdate);
      } else {
        handleFetchError(error);
        setFormState('SUMMARY');
      }
    }
  }

  async function changeStateWhenValid(state: NegotiationState, onBehalf: boolean, reason?: string) {
    try {
      // Validate the initial nomination before changing state
      const latestPromptNomination = values;

      // check if the latest nomination is present and if it's state is not pending
      if (latestPromptNomination !== null && latestPromptNomination.id) {
        const schedulerIsChangingDelegatedProposal =
          isScheduler(props.userProfile.roles) && latestPromptNomination.state === 'PENDING';

        triggerValidation();

        // if the scheduler is accepting / rejecting a delegated proposal,
        // we can skip the validation as these fields should be filled in by the 3rd party scheduler in case of delegation.
        // also only need to check if the nomintion is valid if changing the state to accepted.
        const validateData =
          schedulerIsChangingDelegatedProposal || state !== 'ACCEPTED'
            ? {}
            : validateRecurringNominationForm(
                latestPromptNomination,
                props.userProfile,
                props.userProfile.roles,
                props.showDeliveryModeSelection,
                timezone.value,
              );

        if (!validateData || isEmpty(validateData)) {
          props.updatePromptNominationState(state, onBehalf, reason);
        } else {
          // Data is not valid, activate the edit mode
          activateEditMode('EDIT');
        }
      } else {
        console.error('No latestPromptNomination, cannot update state');
      }
    } catch (err) {
      handleFetchError(err);
    }
  }

  function activateEditMode(type: 'EDIT' | 'EDIT_ON_BEHALF') {
    // only allow edit mode for non-cancelled and non-finalized events
    if (!props.readOnly) {
      setFormState(type);
    }
  }
};

export default React.memo(RecurringNominationNegotiation);
