import React, { useEffect, useCallback } from 'react';
import { Loading } from '../../../../Loading';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Column } from 'react-table';
import FuelBossTableContainer from '../../../../FuelBossTable';
import styles from './OutboundIntegrationTable.module.scss';
import { getCompanies } from '../../../../../store/Company/actions';
import { editIntegrationOutboundApiTracker, getIntegrationOutboundApiTracker, getIntegrationOutboundApiTrackerById } from '../../../../../store/IntegrationOutboundApiTracker/actions';
import { IntegrationOutboundApiTracker, IntegrationStatus } from '../../../../../store/IntegrationOutboundApiTracker/models';
import BackNavigation from '../../../../BackNavigation/BackNavigation';
import { getAuth } from '../../../../../store/Auth/actions';
import { Category } from '../../../../../models/Category';
import { getIntegrationAssets } from '../../../../../store/IntegrationAsset/actions';
import { EventType } from '../../../../IntegrationTypes/IntegrationTypes';

const StyledAssetTableWrapper = styled.div`
  position: relative;
  .buttons-div {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 10px;
  }
`;

interface OwnProps {
  navAdminType: string;
}

const OutboundIntegrationTable: React.FC<OwnProps> = ({
  navAdminType,
}) => {
  const {
    integrationOutboundApiTrackers,
    integrationOutboundApiTrackersLoaded,

    integrationassets,
    integrationassetsIsLoaded,

    companies,
    companiesIsLoaded,

    auth,
    authIsLoaded

  } = useSelector((state: ApplicationState) => ({
    integrationOutboundApiTrackers: state.integration_outbound_api_tracker.data,
    integrationOutboundApiTrackersLoaded: state.integration_outbound_api_tracker.dataIsLoaded,

    integrationassets: state.integration_assets.data,
    integrationassetsIsLoaded: state.integration_assets.dataIsLoaded,

    companies: state.companies.data,
    companiesIsLoaded: state.companies.dataIsLoaded,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const dispatch = useDispatch();
  const history = useHistory();
  const goBack = () => {
    history.push(`/${navAdminType}admin/integrationassets`);
  }

  const getAllCompanies = useCallback(() => dispatch(getCompanies()), []);
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);
  const editApiTrackerSettingsCallback = React.useCallback(
    (settings: IntegrationOutboundApiTracker) => {
      dispatch(editIntegrationOutboundApiTracker(settings, false, () => { }));
    },
    [],
  );


  const getApiTrackerSettingsByIdCallback = React.useCallback((id) => dispatch(getIntegrationOutboundApiTrackerById(id)), []);
  const getApiTrackerSettingsCallback = React.useCallback(() => dispatch(getIntegrationOutboundApiTracker(false)), []);
  const getIntegrationAssetsSettingsCallback = React.useCallback(() => dispatch(getIntegrationAssets()), []);


  const checkCircle = findIconDefinition({ prefix: 'fal', iconName: 'check-circle' });
  const hourGlass = findIconDefinition({ prefix: 'fal', iconName: 'hourglass-half' });
  const plusCircle = findIconDefinition({ prefix: 'fal', iconName: 'plus-circle' });
  const apiTrackersArray = Object.values(integrationOutboundApiTrackers);
  const [completedActive, setCompletedActive] = React.useState(false)

  
  const removeDisabled = (): object[] => {
    const notTrackAll = apiTrackersArray.filter((x): x is IntegrationOutboundApiTracker => {
      const tracker = x as IntegrationOutboundApiTracker;
      return (
        tracker.TrackAll ||
        tracker.BDN ||
        //tracker.BDNPDF ||
        tracker.Nomination ||
          tracker.SupplierInternal ||
        false 
      );
    });
  
    return notTrackAll as object[];
  };
  
  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }

    if (!companiesIsLoaded) {
      getAllCompanies();
    }

    getIntegrationAssetsSettingsCallback();
    getApiTrackerSettingsCallback();

  }, []);



  const findCompanyName = (companyId: any) => {
    var companyName = companies.find((x) => x.companyId === companyId)

    return companyName?.name;
  };

  const handlePendingButton = (integrationOutboundApiTracker: any) => {
    var apiTrackerSettingsToSave = integrationOutboundApiTracker;
    apiTrackerSettingsToSave.IntegrationStatus = 1;
    editApiTrackerSettingsCallback(apiTrackerSettingsToSave);
  };

  const handleCompleteButton = (integrationOutboundApiTracker: any) => {
    var apiTrackerSettingsToSave = integrationOutboundApiTracker;
    apiTrackerSettingsToSave.IntegrationStatus = 2;

    editApiTrackerSettingsCallback(apiTrackerSettingsToSave);
  };


  const handleAssetFormButton = (id: any) => {

    getApiTrackerSettingsByIdCallback(id);
    history.push(`/${navAdminType}admin/integrationassets/outbound/assetform`);
  };

  const disabledAssetFormButton = (asset: IntegrationOutboundApiTracker) => {

    const findName = findCompanyName(asset.CompanyId);
    const foundAssets = integrationassets.filter((x) => x.assetExternalName === findName);
    if (foundAssets.length === 0) {
      return false;
    }

    const foundAssetNames = foundAssets.map(asset => asset.assetName);

    const eventTypes = Object.values(EventType)
      .filter(value => isNaN(Number(value)))
      .filter(value => foundAssetNames.includes(value as string));
    
  
    // Check if every event type exists as an assetName in foundAssets
    const allEventTypesMatch = eventTypes.every(
      eventTypeName => foundAssets.some(findAsset => findAsset.assetName === eventTypeName)
    );

    setCompletedActive(allEventTypesMatch);
    return allEventTypesMatch;
};



  const headersNew: Array<Column<IntegrationOutboundApiTracker>> = React.useMemo(
    () => [
      {
        Header: 'Company name',
        Cell: (props: any) => <div>{findCompanyName(props.cell.row.original.CompanyId)}</div>,
      },
      {
        Header: 'Nomination Event',
        Cell: (props: any) => <div>{props.cell.row.original.Nomination ? 'Enabled' : 'Disabled'}</div>,
      },
      // {
      //   Header: 'BDN PDF Event',
      //   Cell: (props: any) => {
      //     return <div>{props.cell.row.original.BDNPDF ? 'Enabled' : 'Disabled'}</div>;
      //   },
      // },
      {
        Header: 'BDN Event',
        Cell: (props: any) => {

          return <div>{props.cell.row.original.BDN ? 'Enabled' : 'Disabled'}</div>;
        },
      },
      {
        Header: 'Supplier Internal Event',
        Cell: (props: any) => {

          if (companies.find((x) => x.companyId === props.cell.row.original.CompanyId && x.category.categoryName === Category.Supplier)) {
              return <div>{props.cell.row.original.SupplierInternal ? 'Enabled' : 'Disabled'}</div>;
          } else return <div>--</div>;
        },
      },
      {
        Header: 'Status',
        Cell: (props: any) => (
          <div>
            {props.cell.row.original.IntegrationStatus === 0
              ? 'New Request'
              : IntegrationStatus[props.cell.row.original.IntegrationStatus]}
          </div>
        ),
      },
      {
        Header: 'Pending',
        Cell: (props: any) => (
          <div>
            <Button
              outline
              color="secondary"
              className={styles['button']}
              onClick={(e) => handlePendingButton(props.cell.row.original)}
              disabled={props.cell.row.original.IntegrationStatus === 1 || props.cell.row.original.IntegrationStatus === 2}
            >
              <FontAwesomeIcon icon={hourGlass} size={'2x'} color="#66c5e9" />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Asset pdf',
        Cell: (props: any) => {
          var disable = disabledAssetFormButton(props.cell.row.original);
          return <div>
            <Button
              onClick={() => handleAssetFormButton(props.cell.row.original.Id)}
              disabled={disable || props.cell.row.original.IntegrationStatus === 0 || props.cell.row.original.IntegrationStatus === 2}
              outline
              className={styles['button']}>
              <FontAwesomeIcon icon={plusCircle} color="#24365c" />
            </Button>
          </div>

        }
        ,
      },
      {
        Header: 'Completed',
        Cell: (props: any) => (
          <div>
            <Button
              outline
              color="secondary"
              className={styles['button']}
              onClick={(e) => handleCompleteButton(props.cell.row.original)}
              disabled={!disabledAssetFormButton(props.cell.row.original) || props.cell.row.original.IntegrationStatus === 2}
            >
              <FontAwesomeIcon icon={checkCircle} size={'2x'} color="#46aa1e" />
            </Button>
          </div>
        ),
      },
    ],
    [integrationOutboundApiTrackers, integrationassets],
  );
  if (!integrationOutboundApiTrackersLoaded) {
    return <Loading />;
  } else if (integrationOutboundApiTrackers) {
    return (
      <StyledAssetTableWrapper>
        {goBack && <BackNavigation onClick={goBack} />}
        <h1>Outbound Events</h1>
        <FuelBossTableContainer
          headers={headersNew}
          tableRows={removeDisabled()}
          showPagination={true}
          showSortIcons={false}
        />
      </StyledAssetTableWrapper>
    );
  } else return null;
};

export default React.memo(OutboundIntegrationTable);
