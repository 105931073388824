import { PaginationMeta } from '../../helpers/PaginationMeta';
import { ErrorActionPayload } from '../../models/ErrorAction';
import { SpotEnquiry } from './models';

export const SPOT_LOADING = 'spot/loading';
export type SPOT_LOADING = typeof SPOT_LOADING;

export const GET_SPOT = 'spot/get';
export type GET_SPOT = typeof GET_SPOT;

export const SPOT_FETCH_ERROR = 'spot/fetchError';
export type SPOT_FETCH_ERROR = typeof SPOT_FETCH_ERROR;

export type SpotActions =
  | {
      type: GET_SPOT;
      payload: {data: SpotEnquiry[], metadata?: PaginationMeta};
    }
  | {
      type: SPOT_LOADING;
    }
  | {
      type: SPOT_FETCH_ERROR;
      payload: ErrorActionPayload;
    };
