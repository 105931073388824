import React, { Component } from 'react';

class Icon extends Component {
  render() {
    const { svg, width = '10px', height = '10px', fill } = this.props;
    let icon = '';

    switch (svg) {
      case 'caret-left':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 14 29"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon fill="#231F20" points="14,28.524 -0.35,14.175 14,-0.175 " />
          </svg>
        );
        break;

      case 'caret-right':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 14 29"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon fill="#231F20" points="0,28.524 14.35,14.175 0,-0.175 " />
          </svg>
        );
        break;

      case 'caret-up':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 29 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon fill="#231F20" points="0.15,14 14.5,-0.35 28.85,14 " />
          </svg>
        );
        break;

      case 'caret-down':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 29 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon fill="#231F20" points="0.15,0 14.5,14.35 28.85,0 " />
          </svg>
        );
        break;

      case 'chevron-left':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 256 256"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M179.199,38.399c0,1.637-0.625,3.274-1.875,4.524l-85.076,85.075l85.076,85.075c2.5,2.5,2.5,6.55,0,9.05s-6.55,2.5-9.05,0  l-89.601-89.6c-2.5-2.5-2.5-6.551,0-9.051l89.601-89.6c2.5-2.5,6.55-2.5,9.05,0C178.574,35.124,179.199,36.762,179.199,38.399z" />
          </svg>
        );
        break;

      case 'chevron-right':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 256 256"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M76.8,217.6c0-1.637,0.625-3.274,1.875-4.524L163.75,128L78.675,42.925c-2.5-2.5-2.5-6.55,0-9.05s6.55-2.5,9.05,0  l89.601,89.6c2.5,2.5,2.5,6.551,0,9.051l-89.601,89.6c-2.5,2.5-6.55,2.5-9.05,0C77.425,220.875,76.8,219.237,76.8,217.6z" />
          </svg>
        );
        break;

      case 'chevron-up':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 256 256"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.6,179.2c-1.637,0-3.274-0.625-4.524-1.875L128,92.25l-85.075,85.075c-2.5,2.5-6.55,2.5-9.05,0s-2.5-6.55,0-9.05  l89.6-89.601c2.5-2.5,6.551-2.5,9.051,0l89.6,89.601c2.5,2.5,2.5,6.55,0,9.05C220.875,178.575,219.237,179.2,217.6,179.2z" />
          </svg>
        );
        break;

      case 'chevron-down':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 256 256"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M38.399,76.8c1.637,0,3.274,0.625,4.524,1.875l85.075,85.076l85.075-85.076c2.5-2.5,6.55-2.5,9.05,0s2.5,6.55,0,9.05  l-89.6,89.601c-2.5,2.5-6.551,2.5-9.051,0l-89.6-89.601c-2.5-2.5-2.5-6.55,0-9.05C35.124,77.425,36.762,76.8,38.399,76.8z" />
          </svg>
        );
        break;

      case 'arrow-right':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16.818,7.646 L10.878,2.206 C10.644,1.992 10.264,1.993 10.029,2.208 L10.024,6.001 L2,6.001 C1.447,6.001 1,6.448 1,7.001 L1,9.001 C1,9.554 1.447,10.001 2,10.001 L10.019,10.001 L10.013,13.878 C10.245,14.091 10.626,14.09 10.862,13.875 L16.816,8.423 C17.049,8.206 17.052,7.859 16.818,7.646 L16.818,7.646z" />
          </svg>
        );
        break;

      case 'map-marker':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 40 52"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M20.421-0.125c-11.046,0-20,8.954-20,20s20,32,20,32s20-20.954,20-32S31.467-0.125,20.421-0.125z    M20.421,27.875c-5.522,0-10-4.477-10-10s4.478-10,10-10s10,4.477,10,10S25.943,27.875,20.421,27.875z" />
              <path d="M20.421,11.875c-3.309,0-6,2.691-6,6s2.691,6,6,6s6-2.691,6-6S23.729,11.875,20.421,11.875z" />
            </g>
          </svg>
        );
        break;

      case 'telephone':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 80 80"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M46.143,46.143c-7.917,7.917-17.085,15.485-20.708,11.861c-5.189-5.188-8.388-9.707-19.825-0.515   c-11.438,9.196-2.653,15.323,2.373,20.351c5.8,5.799,27.421,0.305,48.792-21.063C78.143,35.404,83.641,13.783,77.837,7.981   C72.81,2.954,66.683-5.828,57.491,5.611c-9.194,11.434-4.677,14.634,0.513,19.823C61.626,29.058,54.06,38.225,46.143,46.143z" />
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
          </svg>
        );
        break;

      case 'envelope':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M31.949,372.155c0,20.078,16.264,36.34,36.34,36.34h363.421  c20.078,0,36.34-16.262,36.34-36.34v-245.31c0-20.079-16.262-36.34-36.34-36.34H68.29c-20.077,0-36.34,16.261-36.34,36.34V372.155z   M165.684,249.775l-83.135-83.678c-6.895-6.906-6.895-18.083,0-24.985c6.912-6.907,18.09-6.907,24.984,0l132.654,132.822  c5.358,5.27,14.176,5.27,19.449,0l132.821-132.822c6.9-6.907,18.081-6.907,24.985,0c6.904,6.902,6.904,18.079,0,24.985  l-83.224,83.678l83.224,83.127c6.904,6.9,6.904,18.08,0,24.984c-6.904,6.9-18.085,6.9-24.985,0l-83.135-83.039  c0,0-25.979,26.44-30.7,31.164c-7.363,7.175-17.543,11.63-28.623,11.63c-11.268,0-21.445-4.543-28.8-11.906  c-4.819-4.722-30.619-30.888-30.619-30.888l-83.047,83.039c-6.895,6.9-18.072,6.9-24.984,0c-6.895-6.904-6.895-18.084,0-24.984  L165.684,249.775z" />
          </svg>
        );
        break;

      case 'cross':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22.245,4.015c0.313,0.313,0.313,0.826,0,1.139l-6.276,6.27c-0.313,0.312-0.313,0.826,0,1.14l6.273,6.272  c0.313,0.313,0.313,0.826,0,1.14l-2.285,2.277c-0.314,0.312-0.828,0.312-1.142,0l-6.271-6.271c-0.313-0.313-0.828-0.313-1.141,0  l-6.276,6.267c-0.313,0.313-0.828,0.313-1.141,0l-2.282-2.28c-0.313-0.313-0.313-0.826,0-1.14l6.278-6.269  c0.313-0.312,0.313-0.826,0-1.14L1.709,5.147c-0.314-0.313-0.314-0.827,0-1.14l2.284-2.278C4.308,1.417,4.821,1.417,5.135,1.73  L11.405,8c0.314,0.314,0.828,0.314,1.141,0.001l6.276-6.267c0.312-0.312,0.826-0.312,1.141,0L22.245,4.015z" />
          </svg>
        );
        break;

      case 'tick':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16.972,6.251c-0.967-0.538-2.185-0.188-2.72,0.777l-3.713,6.682l-2.125-2.125c-0.781-0.781-2.047-0.781-2.828,0  c-0.781,0.781-0.781,2.047,0,2.828l4,4C9.964,18.792,10.474,19,11,19c0.092,0,0.185-0.006,0.277-0.02  c0.621-0.087,1.166-0.46,1.471-1.009l5-9C18.285,8.005,17.937,6.788,16.972,6.251z" />
          </svg>
        );
        break;

      case 'plus':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="448,224 288,224 288,64 224,64 224,224 64,224 64,288 224,288 224,448 288,448 288,288 448,288 " />
          </svg>
        );
        break;

      case 'plus-round':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M384,265H264v119h-17V265   H128v-17h119V128h17v120h120V265z" />
            </g>
          </svg>
        );
        break;

      case 'minus':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect height="64" width="384" x="64" y="224" />
          </svg>
        );
        break;

      case 'minus-round':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M384,265H128v-17h256V265z" />
            </g>
          </svg>
        );
        break;

      case 'pencil':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.0"
            viewBox="0 0 256 256"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)">
              <path d="M2048 2470 c-36 -11 -504 -469 -1182 -1156 -502 -508 -536 -545 -536 -578 0 -44 22 -66 66 -66 33 0 81 46 818 788 912 918 948 955 944 991 -3 23 -8 26 -43 28 -22 1 -52 -2 -67 -7z" />
              <path d="M2267 2409 c-47 -25 -555 -519 -1243 -1211 -495 -496 -522 -525 -533 -572 -15 -62 -1 -110 39 -131 35 -18 85 -19 122 -2 29 13 1574 1566 1677 1686 106 123 119 179 55 230 -33 26 -68 26 -117 0z" />
              <path d="M1549 1303 c-770 -768 -870 -872 -875 -904 -5 -30 -2 -41 16 -59 53 -53 24 -79 879 782 433 436 812 821 843 855 60 66 79 114 62 162 -24 67 -2 87 -925 -836z" />
              <path d="M172 423 c-37 -89 -84 -248 -85 -293 l-2 -45 55 2 c57 2 204 41 278 73 42 19 43 21 37 52 -19 86 -102 186 -193 232 -28 14 -55 26 -60 26 -5 0 -19 -21 -30 -47z" />
            </g>
          </svg>
        );
        break;

      case 'info':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 46 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M35.162,0c6.696,0,10.043,4.567,10.043,9.789c0,6.522-5.814,12.555-13.391,12.555c-6.344,0-10.045-3.752-9.869-9.947   C21.945,7.176,26.35,0,35.162,0z M14.543,100c-5.287,0-9.164-3.262-5.463-17.615l6.07-25.457c1.057-4.077,1.23-5.707,0-5.707   c-1.588,0-8.451,2.816-12.51,5.59L0,52.406C12.863,41.48,27.662,35.072,34.004,35.072c5.285,0,6.168,6.361,3.525,16.148   L30.58,77.98c-1.234,4.729-0.703,6.359,0.527,6.359c1.586,0,6.787-1.963,11.896-6.041L46,82.377C33.488,95.1,19.83,100,14.543,100z   " />
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
          </svg>
        );
        break;

      case 'image':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z" />
              <path d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z" />
            </g>
          </svg>
        );
        break;

      case 'key':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M56.629,379.2c-14.09,14.071-14.09,36.975,0,51.055  c14.08,14.087,36.981,14.087,50.965,0l10.177-10.08l42.438,42.428c7.257,7.268,18.888,7.268,26.155,0l63.244-63.244  c7.268-7.255,7.268-18.89,0-26.157l-42.429-42.427l75.586-75.682c16.174,8.357,34.61,13.075,54.059,13.075  c65.234,0,118.111-52.869,118.111-118.109c0-65.232-52.877-118.111-118.111-118.111c-65.238,0-118.11,52.879-118.11,118.111  c0,19.449,4.721,37.886,13.077,54.06L56.629,379.2z M291.396,150.059c0-25.075,20.354-45.429,45.427-45.429  c25.076,0,45.426,20.354,45.426,45.429s-20.35,45.426-45.426,45.426C311.751,195.485,291.396,175.133,291.396,150.059z"
              fill="#010101"
              fillRule="evenodd"
            />
          </svg>
        );
        break;

      case 'list':
        icon = (
          <svg width={width} height={height} fill={fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d=" M3 8 A3 3 0 0 0 9 8 A3 3 0 0 0 3 8 M12 6 L28 6 L28 10 L12 10z M3 16 A3 3 0 0 0 9 16 A3 3 0 0 0 3 16 M12 14 L28 14 L28 18 L12 18z M3 24 A3 3 0 0 0 9 24 A3 3 0 0 0 3 24 M12 22 L28 22 L28 26 L12 26z " />
          </svg>
        );
        break;

      case 'tiles':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 25.945 25.527"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M6.333,0H0.991C0.443,0,0,0.443,0,0.991v5.343c0,0.546,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.444,0.99-0.99V0.991   C7.323,0.443,6.88,0,6.333,0z" />
              <path d="M15.539,0h-5.343c-0.546,0-0.99,0.443-0.99,0.991v5.343c0,0.546,0.444,0.99,0.99,0.99h5.343   c0.548,0,0.991-0.444,0.991-0.99V0.991C16.53,0.443,16.087,0,15.539,0z" />
              <path d="M24.955,0h-5.342c-0.548,0-0.991,0.443-0.991,0.991v5.343c0,0.546,0.443,0.99,0.991,0.99h5.342   c0.547,0,0.99-0.444,0.99-0.99V0.991C25.945,0.444,25.502,0,24.955,0z" />
              <path d="M6.333,8.997H0.991C0.443,8.998,0,9.44,0,9.987v5.343c0,0.547,0.443,0.99,0.991,0.99h5.342c0.547,0,0.99-0.443,0.99-0.99   V9.987C7.323,9.44,6.88,8.997,6.333,8.997z" />
              <path d="M15.539,8.997h-5.343c-0.546,0-0.99,0.443-0.99,0.99v5.343c0,0.547,0.444,0.99,0.99,0.99h5.343   c0.548,0,0.991-0.443,0.991-0.99V9.987C16.53,9.44,16.087,8.997,15.539,8.997z" />
              <path d="M24.955,8.997h-5.342c-0.548,0-0.991,0.443-0.991,0.99v5.343c0,0.547,0.443,0.99,0.991,0.99h5.342   c0.547,0,0.99-0.443,0.99-0.99V9.987C25.945,9.44,25.502,8.997,24.955,8.997z" />
              <path d="M6.333,18.203H0.991C0.443,18.203,0,18.647,0,19.193v5.343c0,0.547,0.443,0.991,0.991,0.991h5.342   c0.547,0,0.99-0.444,0.99-0.991v-5.343C7.323,18.647,6.88,18.203,6.333,18.203z" />
              <path d="M15.539,18.203h-5.343c-0.546,0-0.99,0.444-0.99,0.99v5.343c0,0.547,0.444,0.991,0.99,0.991h5.343   c0.548,0,0.991-0.444,0.991-0.991v-5.343C16.53,18.647,16.087,18.203,15.539,18.203z" />
              <path d="M24.955,18.203h-5.342c-0.548,0-0.991,0.444-0.991,0.99v5.343c0,0.547,0.443,0.991,0.991,0.991h5.342   c0.547,0,0.99-0.444,0.99-0.991v-5.343C25.945,18.647,25.502,18.203,24.955,18.203z" />
            </g>
          </svg>
        );
        break;

      case 'loaderMessage':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <g transform="translate(25 50)">
              <circle cx="0" cy="0" r="5" fill="#1d3f72" transform="scale(0.773605 0.773605)">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.3333333333333333s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
            <g transform="translate(50 50)">
              <circle cx="0" cy="0" r="5" fill="#5699d2" transform="scale(0.996741 0.996741)">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.16666666666666666s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
            <g transform="translate(75 50)">
              <circle cx="0" cy="0" r="5" fill="#d8ebf9" transform="scale(0.68693 0.68693)">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="0s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>
          </svg>
        );
        break;

      case 'eye':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M256,128c-81.9,0-145.7,48.8-224,128c67.4,67.7,124,128,224,128c99.9,0,173.4-76.4,224-126.6   C428.2,198.6,354.8,128,256,128z M256,347.3c-49.4,0-89.6-41-89.6-91.3c0-50.4,40.2-91.3,89.6-91.3s89.6,41,89.6,91.3   C345.6,306.4,305.4,347.3,256,347.3z" />
              <g>
                <path d="M256,224c0-7.9,2.9-15.1,7.6-20.7c-2.5-0.4-5-0.6-7.6-0.6c-28.8,0-52.3,23.9-52.3,53.3c0,29.4,23.5,53.3,52.3,53.3    s52.3-23.9,52.3-53.3c0-2.3-0.2-4.6-0.4-6.9c-5.5,4.3-12.3,6.9-19.8,6.9C270.3,256,256,241.7,256,224z" />
              </g>
            </g>
          </svg>
        );
        break;

      case 'upload':
        icon = (
          <svg
            width={width}
            height={height}
            fill={fill}
            version="1.1"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M398.1,233.2c0-1.2,0.2-2.4,0.2-3.6c0-65-51.8-117.6-115.7-117.6c-46.1,0-85.7,27.4-104.3,67c-8.1-4.1-17.2-6.5-26.8-6.5  c-29.5,0-54.1,21.9-58.8,50.5C57.3,235.2,32,269.1,32,309c0,50.2,40.1,91,89.5,91H224v-80l-48.2,0l80.2-83.7l80.2,83.6l-48.2,0v80  h110.3c45.2,0,81.7-37.5,81.7-83.4C480,270.6,443.3,233.3,398.1,233.2z" />
          </svg>
        );
        break;
      case 'clock':
        icon = (
          <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="*-Article-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="clock" fill="#666666">
                <path d="M4.23573826,1.1409396 C5.53831096,0.380313199 6.95973154,0 8.5,0 C10.0407438,0 11.461689,0.380313199 12.7647371,1.1409396 C14.0668345,1.901566 15.098434,2.93316555 15.8595358,4.23573826 C16.6196868,5.53878635 17,6.95973154 17,8.5 C17,10.0402685 16.6196868,11.461689 15.8595358,12.7642617 C15.098434,14.0668345 14.0668345,15.098434 12.7647371,15.8590604 C11.461689,16.6196868 10.0407438,17 8.5,17 C6.95973154,17 5.53831096,16.6196868 4.23573826,15.8590604 C2.93269016,15.098434 1.9010906,14.0668345 1.1409396,12.7642617 C0.380313199,11.461689 0,10.0402685 0,8.5 C0,6.95973154 0.380313199,5.53878635 1.1409396,4.23573826 C1.9010906,2.93316555 2.93269016,1.901566 4.23573826,1.1409396 C4.23573826,1.1409396 2.93269016,1.901566 4.23573826,1.1409396 L4.23573826,1.1409396 L4.23573826,1.1409396 Z M12.6720358,4.3284396 C11.5168345,3.17323826 10.1258389,2.59563758 8.5,2.59563758 C6.87416107,2.59563758 5.48364094,3.17323826 4.3284396,4.3284396 C3.17323826,5.48364094 2.59563758,6.87416107 2.59563758,8.5 C2.59563758,10.1258389 3.17323826,11.5163591 4.3284396,12.6715604 C5.48364094,13.8267617 6.87416107,14.4043624 8.5,14.4043624 C10.1258389,14.4043624 11.5168345,13.8267617 12.6720358,12.6715604 C13.8267617,11.5163591 14.4043624,10.1258389 14.4043624,8.5 C14.4043624,6.87416107 13.8267617,5.48364094 12.6720358,4.3284396 C12.6720358,4.3284396 13.8267617,5.48364094 12.6720358,4.3284396 L12.6720358,4.3284396 L12.6720358,4.3284396 Z M8.5,8.5 L11.352349,8.5 L11.352349,9.9261745 L7.0738255,9.9261745 L7.0738255,4.22147651 L8.5,4.22147651 L8.5,8.5 L8.5,8.5 Z" />
              </g>
            </g>
          </svg>
        );
        break;
      case 'person':
        icon = (
          <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="*-Article-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="person" transform="translate(-1.000000, -1.000000)" fill="#666666">
                <path d="M16,14.2125 L16,16 L1,16 L1,14.2125 L6,11 L6,9.75 C5.69166667,9.75 5.40666667,9.43958333 5.14416667,8.81875 C4.88125,8.19791667 4.75,7.675 4.75,7.25 L4.75,4.75 C4.75,3.71708333 5.11666667,2.83333333 5.85041667,2.1 C6.58375,1.36666667 7.46666667,1 8.5,1 C9.53333333,1 10.4166667,1.36666667 11.15,2.1 C11.8833333,2.83333333 12.25,3.71708333 12.25,4.75 L12.25,7.25 C12.25,7.675 12.1191667,8.19791667 11.85625,8.81875 C11.59375,9.43958333 11.30875,9.75 11,9.75 L11,11 L16,14.2125" />
              </g>
            </g>
          </svg>
        );
        break;

      case 'clipboard':
        icon = (
          <svg width={width} height={height} fill={fill} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g>
              <g>
                <path d="M160,160h192c-1.7-20-9.7-35.2-27.9-40.1c-0.4-0.1-0.9-0.3-1.3-0.4c-12-3.4-20.8-7.5-20.8-20.7V78.2    c0-25.5-20.5-46.3-46-46.3c-25.5,0-46,20.7-46,46.3v20.6c0,13.1-8.8,17.2-20.8,20.6c-0.4,0.1-0.9,0.4-1.4,0.5    C169.6,124.8,161.9,140,160,160z M256,64.4c7.6,0,13.8,6.2,13.8,13.8c0,7.7-6.2,13.8-13.8,13.8c-7.6,0-13.8-6.2-13.8-13.8    C242.2,70.6,248.4,64.4,256,64.4z" />
                <path d="M404.6,63H331v14.5c0,10.6,8.7,18.5,19,18.5h37.2c6.7,0,12.1,5.7,12.4,12.5l0.1,327.2c-0.3,6.4-5.3,11.6-11.5,12.1    l-264.4,0.1c-6.2-0.5-11.1-5.7-11.5-12.1l-0.1-327.3c0.3-6.8,5.9-12.5,12.5-12.5H162c10.3,0,19-7.9,19-18.5V63h-73.6    C92.3,63,80,76.1,80,91.6V452c0,15.5,12.3,28,27.4,28H256h148.6c15.1,0,27.4-12.5,27.4-28V91.6C432,76.1,419.7,63,404.6,63z" />
              </g>
              <rect height="16" width="112" x="144" y="192" />
              <rect height="16" width="160" x="144" y="288" />
              <rect height="16" width="129" x="144" y="384" />
              <rect height="16" width="176" x="144" y="336" />
              <rect height="16" width="208" x="144" y="240" />
            </g>
          </svg>
        );
        break;

      case 'exclamation':
        icon = (
          <svg width={width} height={height} fill={fill} viewBox="0 0 139 139" xmlns="http://www.w3.org/2000/svg">
            <path d="M69.699,90.294c-5.815,0-10.528-4.712-10.528-10.528V24.119c0-5.814,4.713-10.528,10.528-10.528  c5.813,0,10.528,4.714,10.528,10.528v55.647C80.228,85.582,75.513,90.294,69.699,90.294z" />
            <circle cx="69.023" cy="110.598" r="9.024" />
          </svg>
        );
        break;

      case 'forward':
        icon = (
          <svg width={width} height={height} fill={fill} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M288,298.1v92.3L448,256L288,112v80C100.8,192,64,400,64,400C117,307,186.4,298.1,288,298.1z" />
            </g>
          </svg>
        );
        break;

      default:
        icon = '';
    }

    return icon;
  }
}

export default Icon;
