import * as React from 'react';
import { FormGroup, Input } from 'reactstrap';

const ReactstrapSelectInputComplex = ({ field, disabled = false, accessor, ...props }) => {
  const onDropdownChange = (e) => {
    const value = props.options.find(
      (option) => (field.name === 'fleetVessel' ? option.vessel[accessor] : option[accessor]) === e.target.value,
    );
    props.setFieldValue(field.name, value);
  };

  const inputValue =
    field.name === 'fleetVessel' ? ((field.value || {}).vessel || {})[accessor] : (field.value || {})[accessor];

  const options = (props.options || []).sort((a, b) => {
    const optionA = field.name === 'fleetVessel' ? a.vessel[accessor] : a[accessor];
    const optionB = field.name === 'fleetVessel' ? b.vessel[accessor] : b[accessor];
    return optionA.localeCompare(optionB);
  });

  return (
    <FormGroup>
      <Input
        {...field}
        id={props.id}
        name={props.name}
        className={props.className}
        placeholder={props.placeholder}
        onChange={(e) => onDropdownChange(e)}
        value={inputValue}
        type="select"
        disabled={disabled}
      >
        <option hidden>{props.placeholder}</option>
        {(options || []).map((option, idx) => {
          const optionValue = field.name === 'fleetVessel' ? option.vessel[accessor] : option[accessor];

          return <option key={idx}>{optionValue}</option>;
        })}
      </Input>
    </FormGroup>
  );
};

export default ReactstrapSelectInputComplex;
