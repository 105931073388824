import { IntegrationAssetPermissionsWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_PERMISSIONS_CLEAR_TOAST = 'integrationAssetPermissions/ClearToast';
export type INTEGRATION_ASSET_PERMISSIONS_CLEAR_TOAST = typeof INTEGRATION_ASSET_PERMISSIONS_CLEAR_TOAST;

export const INTEGRATION_ASSET_PERMISSIONS_LOADING = 'integrationAssetPermissions/loading';
export type INTEGRATION_ASSET_PERMISSIONS_LOADING = typeof INTEGRATION_ASSET_PERMISSIONS_LOADING;

export const GET_INTEGRATION_ASSET_PERMISSIONS = 'integrationAssetPermissions/get';
export type GET_INTEGRATION_ASSET_PERMISSIONS = typeof GET_INTEGRATION_ASSET_PERMISSIONS;

export const INTEGRATION_ASSET_PERMISSIONS_FETCH_ERROR = 'integrationAssetPermissions/fetchError';
export type INTEGRATION_ASSET_PERMISSIONS_FETCH_ERROR = typeof INTEGRATION_ASSET_PERMISSIONS_FETCH_ERROR;

export const ADD_INTEGRATION_ASSET_PERMISSIONS = 'integrationAssetPermissions/add';
export type ADD_INTEGRATION_ASSET_PERMISSIONS = typeof ADD_INTEGRATION_ASSET_PERMISSIONS;

export const EDIT_INTEGRATION_ASSET_PERMISSIONS = 'integrationAssetPermissions/edit';
export type EDIT_INTEGRATION_ASSET_PERMISSIONS = typeof EDIT_INTEGRATION_ASSET_PERMISSIONS;

export const DELETE_INTEGRATION_ASSET_PERMISSIONS = 'integrationAssetPermissions/delete';
export type DELETE_INTEGRATION_ASSET_PERMISSIONS = typeof DELETE_INTEGRATION_ASSET_PERMISSIONS;

export const INTEGRATION_ASSET_PERMISSIONS_ADD_ERROR = 'integrationAssetPermissions/addError';
export type INTEGRATION_ASSET_PERMISSIONS_ADD_ERROR = typeof INTEGRATION_ASSET_PERMISSIONS_ADD_ERROR;

export const INTEGRATION_ASSET_PERMISSIONS_EDIT_ERROR = 'integrationAssetPermissions/editError';
export type INTEGRATION_ASSET_PERMISSIONS_EDIT_ERROR = typeof INTEGRATION_ASSET_PERMISSIONS_EDIT_ERROR;

export const INTEGRATION_ASSET_PERMISSIONS_DELETE_ERROR = 'integrationAssetPermissions/deleteError';
export type INTEGRATION_ASSET_PERMISSIONS_DELETE_ERROR = typeof INTEGRATION_ASSET_PERMISSIONS_DELETE_ERROR;

export type IntegrationAssetPermissionsActions =
  | {
      type: INTEGRATION_ASSET_PERMISSIONS_LOADING;
    }
  | {
      type: GET_INTEGRATION_ASSET_PERMISSIONS;
      payload: IntegrationAssetPermissionsWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_ASSET_PERMISSIONS;
      payload: IntegrationAssetPermissionsWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_ASSET_PERMISSIONS;
      payload: IntegrationAssetPermissionsWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_PERMISSIONS;
      payload: IntegrationAssetPermissionsWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_PERMISSIONS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_PERMISSIONS_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_PERMISSIONS_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_PERMISSIONS_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_PERMISSIONS_CLEAR_TOAST;
    };
