import { StandardWrapper } from '../../models/Responses';
import { Company } from '../Company/models';
import { ReportType } from '../ReportTypes/models';
import { UserRole } from '../../models/UserRole';
import { Role } from '../Roles/models';
import { Category } from '../Category/models';

export type ReportWrapperModel = StandardWrapper<Report[], {}>;
export type ReportResponseModel = Report[];

//export interface ReportConfig{
//  id?: string;
//  name: string;
//  //other stuff
//}
export interface Report {
  id?: string;
  name: string;
  description: string;
  url: string;
  companies: any[];
  companyCategories: any[];
  roles: any[];
  companiesIds: string[];
  companyCategoriesIds: string[];
  rolesIds: string[];
  active?: boolean;
  type: ReportType;
  openInNewTab?: boolean;
  entityId: string;
  reportId: string;
  powerBiReportId?: string;
  powerBiReportRoleType?: string;
  reference: string; //URL or ID
}

export enum ReportReference {
  URL = 'URL',
  ID = 'ID',
}
