import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './RecurringChorusIntegration.module.scss';
import {
  AuthenticationServiceLayer,
  IPromptNomination,
  ICompany,
  ILocation,
  IShipWithId,
  IPipeline,
  IContract,
  NominationSelection,
  IShipWithCompanyId,
} from '@teqplay/chorus-components';

import './../../../BunkerEvents/Components/ChorusIntegration/ChorusStyleOverrides.scss';

import {
  DocumentHandler,
  useNominationService,
  useNominationCollectionService,
  useLocationService,
  //NominationNegotiation,
  CachedLocationService,
  NominationServiceProps,
} from '@teqplay/chorus-components';
import { useState } from 'react';
import { ApplicationState } from '../../../../store';
import { Loading } from '../../../../components/Loading/index';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import { ErrorWrapper } from './../../../../models/ErrorAction';
import { Error } from './../../../../components/Error/index';
import { RoleType } from '../../../../models/RoleType';
import { toast } from 'react-toastify';
import useInterval from '../../../../helpers/useInterval';
import { getVolumeStats } from '../../../../store/VolumeStats/actions';
import { addRecurringNomination, deleteRecurringNomination, getRecurringNominations, updateRecurringNomination } from '../../../../store/RecurringNomination/actions';
import { RecurringNomination } from '../../../../store/RecurringNomination/models';
import RecurringNominationNegotiation from './RecurringNominationNegotiation';
import { useHistory } from 'react-router';

interface OwnProps {
  nominationId: string;
  className?: string;
  bunkerShips: IShipWithCompanyId[];
  bunkerPipelines: IPipeline[];
  companies: ICompany[];
  locationService: CachedLocationService;
  contracts: IContract[];
  yearMonth: string;
  vendorCompanies?: ICompany[];
  setRenderChat: (b: boolean) => void;
  serviceLayer: AuthenticationServiceLayer;
  selectedRecurringNomination: RecurringNomination | undefined;
}

const RecurringChorusIntegration: React.FC<OwnProps> = ({
  nominationId,
  className,
  bunkerShips,
  bunkerPipelines,
  companies,
  contracts,
  locationService,
  yearMonth,
  vendorCompanies,
  setRenderChat,
  serviceLayer,
  selectedRecurringNomination,
}) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));

  const errorWrapperObject: ErrorWrapper = {};

  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('1');
  const [newVersionToastShowing, setNewVersionToastShowing] = useState(false);
  const [bdnWriteable, setBdnWritable] = useState(false);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const getRecurringNominationCallback = React.useCallback(() => dispatch(getRecurringNominations()), []);

  const addRecurringNominationCallback = React.useCallback(
    (nomination: RecurringNomination) => dispatch(addRecurringNomination(nomination, () => {history.push(`/recurringnominations`)})),
    [],
  );

  const editRecurringNominationCallback = React.useCallback(
    (nomination: RecurringNomination) => dispatch(updateRecurringNomination(nomination, () => {history.push(`/recurringnominations`)})),
    [],
  );
  
  const deleteRecurringNominationCallback = React.useCallback((id: string) => dispatch(deleteRecurringNomination(id)), []);
  
  const successCallback = (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE',
    updatedRecurringNomination: RecurringNomination,
  ) => {
    getRecurringNominationCallback();
  };

  const isCustomerOperator = auth.roles.includes(RoleType.VesselOperator);
  const isProcurement = auth.roles.includes(RoleType.Procurement);

  const notReceivingCaptain = () => {
    let ret = true;
    auth.roles.forEach((role) => {
      if (role === RoleType.RecVesselCrew) ret = false;
    });
    return ret;
  };

  const notBunkerVesselCrew = () => {
    let ret = true;
    auth.roles.forEach((role) => {
      if (role === RoleType.BunkerVesselCrew) ret = false;
    });
    return ret;
  };

  React.useEffect(() => {
    if (newVersionToastShowing) toast.dismiss();
    setNewVersionToastShowing(false);
  }, [nominationId]);


  const callbackOnOrderPDFConfirmed = () => {
    toggle('2');
    refetchNominationData();
  };

  const [latestNomination, setLatestNomination] = React.useState<RecurringNomination | undefined>(undefined);
  const [nominationHistory, setNominationHistory] = React.useState<RecurringNomination[]>([]);
  const [nominationSelection, setNominationSelection] = React.useState<NominationSelection>({} as NominationSelection);

  React.useEffect(() => {
    setLatestNomination(selectedRecurringNomination);
    if (selectedRecurringNomination) setNominationHistory([selectedRecurringNomination])
    if (selectedRecurringNomination){
      const updatedNominationSelection = {
        selectedNominationIndex: 0,
        //@ts-ignore
        selectedHistoricNomination: selectedRecurringNomination,
        //@ts-ignore
        previousHistoricNomination: selectedRecurringNomination,
        selectNextHistoricNomination: () => {},
        selectPreviousHistoricNomination: () => {},
      };
      // @ts-ignore
      setNominationSelection(updatedNominationSelection);
    }
  }, [selectedRecurringNomination]);

  const updatePromptNomination = (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE' | 'DELETE',
    updatedNomination: RecurringNomination,
    suppressNotification?: boolean,
    amendAndAccept?: boolean,
  ) => {
    if(action == 'CREATE'){
      addRecurringNominationCallback(updatedNomination);
    }
    if(action == 'AMEND'){
      editRecurringNominationCallback(updatedNomination);
    }
    if(action == 'COUNTER'){
      editRecurringNominationCallback(updatedNomination);
    }
    if(action == 'DELETE'){
      deleteRecurringNominationCallback(updatedNomination.id);
    }
    const promise = new Promise<void>(() => {});
    return promise;
  };

  const updatePromptNominationState = (newState: string, onBehalf: boolean, reason?: string) => {
    return;
  };

  const updateSandboxPromptNomination = (updatedNomination: RecurringNomination, sandboxId: string) => {
    return;
  };
  
  const refetchNominationData = () => {
    return;
  };

  const generateAndSendOrderConfirmation = () => {
    return;
  };

  //continually checks if a new nomination is availible
  //useInterval(() => {
    //checkIfNewerNominationAvailable();
  //}, 60000);

  React.useEffect(() => {
    setRenderChat(true);
  }, [latestNomination]);

  React.useEffect(() => {
    if (auth && auth.roles) {
      auth.roles.forEach((role) => {
        if (role === RoleType.Operator || role === RoleType.Commercial || role === RoleType.BunkerVesselCrew) {
          setBdnWritable(true);
          return;
        }
      });
    }
  }, [auth]);

  if (errorWrapperObject.payload) {
    return <Error error={errorWrapperObject.payload} />;
  } else if (nominationId && nominationId != '' && latestNomination) {
    return (
      <div className={className}>
        <Nav tabs className={`sticky-top bg-white ${styles['navbar']} bunker-events-nav`}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              Nomination details
            </NavLink>
          </NavItem>
        </Nav>
        {loading && (
          <Loading small={true} type="nomination data" className={styles['loading']}>
            loading
          </Loading>
        )}
        <TabContent activeTab={activeTab} className={'chorus-wrapper'}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <RecurringNominationNegotiation
                  key={latestNomination.id}
                  readOnly={false}
                  //yearMonth={yearMonth}
                  userProfile={auth.chorusUserProfile}
                  initialNomination={latestNomination}
                  nominationHistory={nominationHistory}
                  nominationSelection={nominationSelection}
                  isNominationInSandbox={false}
                  updatePromptNomination={updatePromptNomination}
                  updateSandboxPromptNomination={updateSandboxPromptNomination}
                  updatePromptNominationState={updatePromptNominationState}
                  authenticationService={serviceLayer}
                  companies={companies}
                  //using location service instead of locationlist
                  locationService={locationService}
                  bunkerShips={bunkerShips}
                  pipelines={bunkerPipelines}
                  showProposalInformation={true}
                  showFuelbossStates={true}
                  showDeliveryModeSelection={true}
                  contractsList={contracts}
                  enableAlternativeFuelSelector={true}
                  generateAndSendOrderConfirmation={generateAndSendOrderConfirmation}
                  onOrderPDFConfirmed={callbackOnOrderPDFConfirmed}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  } else if (loading) {
    return <Loading type="Chorus API" />;
  } else {
    setRenderChat(false);
    return null;
  }
};

export default RecurringChorusIntegration;
