import { BunkerPlannerDocumentType } from '@teqplay/chorus-components';

export const checkListDocumentTypesIAPH = [
  'SAFETY_CHECKLIST_IAPH_AB',
  'SAFETY_CHECKLIST_IAPH_CD',
  'SAFETY_CHECKLIST_IAPH_E',
  'SAFETY_CHECKLIST_IAPH_F',
  'SAFETY_CHECKLIST_CDE',
] as BunkerPlannerDocumentType[];

export const checkListDocumentTypesGothenburg = [
  'SAFETY_CHECKLIST_GOTHENBURG_AB',
  'SAFETY_CHECKLIST_GOTHENBURG_CDEF',
  'SAFETY_CHECKLIST_GOTHENBURG_G',
] as BunkerPlannerDocumentType[];

export const checkListDocumentTypesTR56 = [
  'SAFETY_CHECKLIST_TR56_AB',
  'SAFETY_CHECKLIST_TR56_CD',
  'SAFETY_CHECKLIST_TR56_E',
] as BunkerPlannerDocumentType[];

export const checkListDocumentTypesIAPHV2 = [
  'SAFETY_CHECKLIST_IAPH_V2_A_A',
  'SAFETY_CHECKLIST_IAPH_V2_A_BCD',
  'SAFETY_CHECKLIST_IAPH_V2_A_E',
  'SAFETY_CHECKLIST_IAPH_V2_A_F',
  'SAFETY_CHECKLIST_IAPH_V2_B_A',
  'SAFETY_CHECKLIST_IAPH_V2_B_BCD',
  'SAFETY_CHECKLIST_IAPH_V2_B_E',
  'SAFETY_CHECKLIST_IAPH_V2_B_F',
] as BunkerPlannerDocumentType[];
