import React, { Component } from 'react';

import Icon from './Icon';
import './ModalResponse.scss';

class ModalResponse extends Component {
  getIcon = (status) => {
    switch (status) {
      case 'loading':
        return 'loaderMessage';
      case 'success':
        return 'tick';
      case 'error':
        return 'cross';
      default:
        return '';
    }
  };

  render() {
    const { status } = this.props;
    const icons = {
      loading: { svg: 'loaderMessage', fill: '', height: '80px', width: '80px' },
      success: { svg: 'tick', fill: '#5F9DB6', height: '80px', width: '80px' },
      error: { svg: 'cross', fill: '#c4262e', height: '50px', width: '50px' },
    };

    return (
      <div className="ModalResponse">
        <div className="response_icon">
          <Icon {...icons[status]} />
        </div>
        <p>
          <span>{this.props.children}</span>
        </p>
      </div>
    );
  }
}

export default ModalResponse;
