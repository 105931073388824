import { useSelector, useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';
import Chat from './Chat';
import { ApplicationState } from '../../../../store';
import { getAuth } from '../../../../store/Auth/actions';
import { getChat, notifyChat, getDelegateChat } from './../../../../store/Chat/actions';
import { ChatMessage } from './../../../../store/Chat/models';
import { Loading } from './../../../../components/Loading/index';
import { arrayListCombiner } from './../../../../helpers/arrayListCombiner';
import { Error } from './../../../../components/Error';
import Logg from './../../../../helpers/consoleLogger';
import usePrevious from './../../../../helpers/usePrevious';
import { Nomination } from '../../../../store/Nomination/models';
import { INominationEnquiry } from '@teqplay/chorus-components';

interface OwnProps {
    type: string;
    connectionId: string;
    className?: string;
    isDelegateChat?: boolean;
    delegateSupplierId?: string;
    delegationOriginEventId?: string;
}

const filterSpots = (spots: INominationEnquiry[], connId: String): INominationEnquiry => {
    let currentSpot = {} as INominationEnquiry;
    if (spots) {
        spots.map(function (item, i) {
            if (item.eventsByVendor && Object.keys(item.eventsByVendor)) {
                Object.keys(item.eventsByVendor).map(function (keyName, keyIndex) {
                    if (item.eventsByVendor[keyName] === connId) {
                        currentSpot = item;
                    }
                })
            }
        })
    }
    return currentSpot;
};

const ChatContainer: React.FC<OwnProps> = ({ type, connectionId, className, isDelegateChat, delegateSupplierId, delegationOriginEventId }) => {
    const {
        authLoading,
        authError,
        authErrorDetails,
        auth,
        authIsLoaded,
        chatLoading,
        chatError,
        chatErrorDetails,
        chat,
        chatIsLoaded,
        nomination,
        nominations,
        spotVendor,
        spotShipOwner,
    } = useSelector((state: ApplicationState) => ({
        authLoading: state.auth.loading,
        authError: state.auth.error,
        authErrorDetails: state.auth.errorDetails,
        auth: state.auth.data,
        authIsLoaded: state.auth.dataIsLoaded === true,
        chatLoading: state.chat.loading,
        chatError: state.chat.error,
        chatErrorDetails: state.chat.errorDetails,
        chat: state.chat.data,
        chatIsLoaded: state.chat.dataIsLoaded === true,
        nomination: (type === 'nomination') ? state.nomination.data.filter(nomination => nomination.eventId === connectionId) : null,
        nominations: (type === 'nomination') ? state.nomination.data : null,
        spotVendor: (type === 'spot') ? (state.spot.data as Nomination[]).filter(spot => spot.eventId === connectionId) : null,
        spotShipOwner: (type === 'spot') ? filterSpots((state.spot.data as INominationEnquiry[]), connectionId) : null,
    }));

    const { nominationDelegate } = useSelector((state: ApplicationState) => ({
        nominationDelegate: (type === 'nomination' && isDelegateChat) ? state.nomination.data.filter(nomination => nomination.delegationOriginEventId === delegationOriginEventId) : null,
    }));
    const { nominationArchivedDelegate } = useSelector((state: ApplicationState) => ({
        nominationArchivedDelegate: (type === 'nomination' && isDelegateChat) ? state.archived_nomination.data.filter(nomination => nomination.delegationOriginEventId === delegationOriginEventId) : null,
    }));

    const dispatch = useDispatch();
    const getAuthCallback = useCallback(() => dispatch(getAuth()), []);
    const getChatCallback = useCallback(() => dispatch(getChat(connectionId)), [connectionId]);
    const postNotifyChatCallback = useCallback((id: string, relatedValues: {}) => { dispatch(notifyChat(id, relatedValues)); }, []);
    const getDelegateChatCallback = useCallback(
        () =>
          dispatch(
            getDelegateChat(
              connectionId,
              nominationDelegate && nominationDelegate.length > 0
                ? nominationDelegate[0].eventId
                : nominationArchivedDelegate && nominationArchivedDelegate.length > 0
                ? nominationArchivedDelegate[0].eventId
                : '',
            ),
          ),
        [connectionId],
      );

    //Component did mount
    useEffect(() => {
        if (!authIsLoaded) {
            getAuthCallback();
        }
        if (!chatIsLoaded && connectionId && connectionId !== '') {
            if(!isDelegateChat) getChatCallback();
            else getDelegateChatCallback();
        }
    }, []);

    const connectionIdRef = useRef(connectionId);
    const companyType = auth.company != null && auth.company.category.chorusType.toLowerCase() === 'vendor' ? 'supplier' : 'shipowner';

    //--START OF MESSAGES----------------------
    const [message, setMessage] = React.useState('');
    const [messages, setMessages] = React.useState<ChatMessage[]>([]);
    const [operationsPhase, setOperationsPhaseAction] = React.useState(false);
    const operationsPhaseRef = useRef(operationsPhase);
    const [initialOperationsPhase, setInitialOperationsPhaseAction] = React.useState(false);
    const [isChatDisabled, setIsChatDisabled] = React.useState(false);

    useEffect(() => {
        if (chatIsLoaded) {
            setMessages(
                chat.sort((a, b) => {
                    if (a.timeStamp && b.timeStamp) return compareDate(a.timeStamp, b.timeStamp);
                    else return NaN;
                }),
            );
            //Logg(`Chat messages loaded, ${chat.length} messages.`);
            if (type === 'nomination' && nomination && nomination[0]) {
                setNominationPhase();
            }
        }
    }, [chat]);

    const postNotifyChatMethod = (id: string, relatedValues: {}) => {
        postNotifyChatCallback(id, relatedValues);
    };

    //--START OF SIGNALR----------------------
    const [hubConnection, setHubConnection] = React.useState(
        new signalR.HubConnectionBuilder().withUrl('/signalrchat', { transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling }).configureLogging(signalR.LogLevel.Error).build(),
    );

    //Method for starting connection
    const startChatConnection = () => {
        //Logg(`Starting chat hub connection with connection id ${connectionId}`);
        hubConnection
            .start()
            .then(() => console.log('Connection started to chathub'))
            .catch((err) => {
                Logg(`Error while establishing chathub connection${err}`);
                //Logg(`Changing transport type`);
                setHubConnection(new signalR.HubConnectionBuilder().withUrl('/signalrchat', { transport: signalR.HttpTransportType.LongPolling }).configureLogging(signalR.LogLevel.Error).build());
            });
    };


    const compareDate = (a: Date, b: Date) => {
        if (a < b) return -1;
        else if (a === b) return 0;
        else if (a > b) return 1;
        else return NaN;
    };

    const addMessage = (receivedMessage: ChatMessage) => {
        setMessages((messages) =>
            [...messages, receivedMessage].sort((a, b) => {
                if (a.timeStamp && b.timeStamp) return compareDate(a.timeStamp, b.timeStamp);
                else return NaN;
            }),
        );
    };

    //Method for setting Nomination Phase
    const setNominationPhase = () => {
        if (!initialOperationsPhase) {
            //set default OperationsPhase depending on bunker start time (6 hours before and bts + (allowed bunker time + 6 hours) time after)
            if (type === 'nomination' && nomination && nomination[0]) {
                var allowedBunkeringTime = nomination[0].allowedBunkeringTime || 0;
                var _bst = nomination[0].bst;
                var bst = new Date(_bst.toString());
                var now = new Date();

                var mMultiplier = 60 * 60 * 1000;
                var defaultBufferHours = 6;
                var defaultBufferMilliSeconds = defaultBufferHours * mMultiplier;

                var btsBeforeBuffer = defaultBufferMilliSeconds; // in milliseconds
                var btsAfterBuffer = (allowedBunkeringTime * mMultiplier) + defaultBufferMilliSeconds; // in milliseconds
               
                if (((bst.getTime() - btsBeforeBuffer) < now.getTime()) && (now.getTime() < (bst.getTime() + btsAfterBuffer))) {
                    setOperationsPhaseAction(true);
                }
                else {
                    setOperationsPhaseAction(false);
                }
                setInitialOperationsPhaseAction(true);
            }
        }
        else {
            //console.log("Bunker Phase already set!");
        }

    };

    //Method for sending message
    const sendMessage = () => {
        if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
            startChatConnection();
        }
        else {
            if (message.trim() !== '') {
                /* special case for delegate company chat */
                if (type === 'nomination' && nominationDelegate && isDelegateChat) {
                    hubConnection.invoke('SendMessageToGroupsAsync', nominationDelegate[0].attributes.receivingCustomerId , nominationDelegate[0].companyId, connectionId, message, type, delegateSupplierId).catch((err: any) => console.log(err));
                }
                if (type === 'nomination' && nomination && !isDelegateChat) {
                    const delegateId = delegateSupplierId ? delegateSupplierId : '';
                    hubConnection.invoke('SendMessageToGroupsAsync', nomination[0].vendorCompanyId, nomination[0].companyId, connectionId, message, type, delegateId).catch((err: any) => console.log(err));
                }
                else { // spot
                    if (companyType === 'supplier' && spotVendor) {
                        hubConnection.invoke('SendMessageToGroupsAsync', spotVendor[0].vendorCompanyId, spotVendor[0].companyId, connectionId, message, type, '').catch((err: any) => console.log(err));
                    }
                    else {
                        if (spotShipOwner) {
                            Object.keys(spotShipOwner.eventsByVendor && spotShipOwner.eventsByVendor).map(function (keyName, keyIndex) {
                                if (spotShipOwner.eventsByVendor[keyName] === connectionId) {
                                    hubConnection.invoke('SendMessageToGroupsAsync', keyName, spotShipOwner.companyId, connectionId, message, type, '').catch((err: any) => console.log(err));
                                }
                            });
                        }
                    }
                }
                setMessage('');
            }
        }
    };

    //Send message on enter press
    const keyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') sendMessage();
    };

    //--END OF MESSAGE----------------------

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on('ReceivedMessage', (receivedMessage: ChatMessage) => {
                if (connectionIdRef.current === receivedMessage.connectionId) {
                    addMessage(receivedMessage);

                    // send notification call
                    if (receivedMessage.user.veracityId === auth.veracityId && receivedMessage.chatId != null) {
                        var relatedValues: { [id: string]: string; } = {};
                        if (receivedMessage.user.company.chorusId) {
                            relatedValues["senderCompanyId"] = receivedMessage.user.company.chorusId;
                        }
                        if (type === 'nomination') {
                            // OperationsPhase setter
                            relatedValues["operationsPhase"] = (operationsPhaseRef.current) ? "true" : "false";
                            if(!isDelegateChat) postNotifyChatMethod(receivedMessage.chatId.toString(), relatedValues);
                            else if (isDelegateChat && nominationDelegate){
                                relatedValues["deligateNominationEventId"] = nominationDelegate[0].eventId;
                                postNotifyChatMethod(receivedMessage.chatId.toString(), relatedValues);
                            }
                            //Logg('nomination notification');
                        }
                        else { // spot
                            if (companyType === 'supplier') {
                                postNotifyChatMethod(receivedMessage.chatId.toString(), relatedValues);
                                //Logg('spot notification - supplier');
                            }
                            else {
                                postNotifyChatMethod(receivedMessage.chatId.toString(), relatedValues);
                                //Logg('spot notification - shipowner');
                            }
                        }
                    }

                }
            });
        }
    }, [hubConnection]);

    //For closing the chat and opening new when changing nomination
    //Triggers when connectionId state is set
    const prevConnectionId = usePrevious(connectionId);
    useEffect(() => {
        if (connectionId && connectionId !== '') {
            //Logg(`Fetching chat messages for id ${connectionId}`);
            !isDelegateChat? getChatCallback() : getDelegateChatCallback();
        }
        if (hubConnection && connectionId && connectionId !== '') {
            if (prevConnectionId && prevConnectionId !== '') {
                hubConnection.stop().then(function () {
                    //Logg('Stopped connection');
                    if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
                        startChatConnection();
                        //Logg('Started connection after connectionId changed');
                    }
                });
            } else {
                if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
                    startChatConnection();
                    //Logg('Started connection');
                }
            }
        }
        if (type === 'nomination') {
            setInitialOperationsPhaseAction(false);
        }
        connectionIdRef.current = connectionId;
    }, [connectionId]);
    //--END OF SIGNALR----------------------

    //disable chat if nomination or spot archived
    useEffect(() => {
        if (type === 'nomination' && nomination && !isDelegateChat) {
            setIsChatDisabled(nomination.length === 0);
        }
        if(type === 'nomination' && isDelegateChat){
            setIsChatDisabled(nominationDelegate?.length === 0);
        }
    }, [connectionId, nomination]);

    useEffect(() => {
        if (type === 'spot') {
            if (companyType === 'supplier' && spotVendor){
                setIsChatDisabled(spotVendor.length ===0);
            }else{
                if (spotShipOwner) {
                    setIsChatDisabled(Object.keys(spotShipOwner).length === 0);
                }
            }
        }
    }, [connectionId, spotShipOwner, spotVendor]);

    
    //Component did mount
    useEffect(() => {
        if (type === 'nomination') {
            operationsPhaseRef.current = operationsPhase;
        }
    }, [operationsPhase]);


    //Render logic
    if ((chatError && chatErrorDetails) || (authError && authErrorDetails)) {
        return (
            <>
                {chatError && chatErrorDetails && <Error error={chatErrorDetails} />}
                {authError && authErrorDetails && <Error error={authErrorDetails} />}
            </>
        );
    } else if (!connectionId || connectionId === '') {
        return null;
    } else if (authLoading || chatLoading) {
        const loadingLabels: string[] = [...(authLoading ? ['user data'] : [])];
        if (chatLoading) loadingLabels.push('chat');

        return <Loading type={arrayListCombiner(loadingLabels)} />;
    } else {
        return (
            <Chat
                type={type}
                connectionId={connectionId}
                message={message}
                setMessage={setMessage}
                messages={messages}
                keyPressed={keyPressed}
                sendMessage={sendMessage}
                loggedInUser={auth}
                className={className}
                isChatDisabled={isChatDisabled}
                operationsPhase={operationsPhase}
                setOperationsPhaseAction={setOperationsPhaseAction}
            />
        );
    }
};

export default ChatContainer;
