import React, { useEffect } from 'react';
import NominationFilterButton from '../NominationFilterButton/NominationFilterButton';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { Category } from '../../models/Category';
import { FuelbossStatus } from '../../store/Nomination/models';

interface OwnProps {
  setFilteredStatuses: (statuses: FuelbossStatus[]) => void;
  filteredStates: FuelbossStatus[];
  displayNormalStatuses: boolean;
  displayArchivedStatuses: boolean;
  className?: string;
  isShipOwner: boolean | undefined;
}

const StatusFilter: React.FC<OwnProps> = ({
  setFilteredStatuses,
  filteredStates,
  displayNormalStatuses,
  displayArchivedStatuses,
  className,
  isShipOwner,
}) => {
  const { auth, authIsLoaded } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,
  }));

  const isUserSupplier = () => {
    return auth.company && auth.company.category.categoryName == Category.Supplier;
  };

  const statusButtonPressed = (status: FuelbossStatus) => {
    const statusIndex = filteredStates.indexOf(status);
    const localFilteredStates = [...filteredStates];

    if (statusIndex < 0) {
      localFilteredStates.push(status);
    } else {
      localFilteredStates.splice(statusIndex, 1);
    }
    setFilteredStatuses(localFilteredStates);
  };

  useEffect(() => {
    if (filteredStates.length === 0) {
      // @ts-ignore
      setFilteredStatuses(['finalised', 'cancelled', 'action-required', 'pending', 'confirmed', 'accepted', 'needs-contract']);
    }
  }, []);

  return (
    <div className={className}>
      {displayArchivedStatuses && (
        <>
          <NominationFilterButton
            status={isShipOwner ? 'finalised' : 'finalised'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
          <NominationFilterButton
            status={'cancelled'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
        </>
      )}
      {displayNormalStatuses && (
        <>
          <NominationFilterButton
            status={'action-required'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
          <NominationFilterButton 
            status={'pending'} 
            filteredStatuses={filteredStates} 
            onClick={statusButtonPressed} 
          />
          {isUserSupplier() && (
            <NominationFilterButton
              // @ts-ignore
              status={'accepted'}
              filteredStatuses={filteredStates}
              onClick={statusButtonPressed}
            />
          )}
          <NominationFilterButton
            status={'needs-contract'}
            filteredStatuses={filteredStates}
            onClick={statusButtonPressed}
          />
        </>
      )}
    </div>
  );
};

export default StatusFilter;
