import React from 'react';
import { BdnConfiguration as BdnConfig } from '@teqplay/chorus-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import FuelBossTableContainer from '../../../../../components/FuelBossTable';
import { TableOptions, Column, Accessor, UseTableOptions } from 'react-table/index';
import DeleteConfiguration from './DeleteConfiguration/DeleteConfiguration';
import { StyledTableWrapper } from '../../../../../components/AdminComponents/StyledComponents/StyledTableWrapper';
import editButtonStyles from '../../../../../components/EditById/EditById.module.scss';
import { EditBDNConfiguration } from '../../../../../components/EditBDNConfiguration/EditBDNConfiguration';
import { Auth } from '../../../../../store/Auth/models';
import { AuthenticationServiceLayer } from '@teqplay/chorus-components';

const editIcon = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const addIcon = findIconDefinition({ prefix: 'fal', iconName: 'plus' });

interface OwnProps {
  bdnConfigurations: BdnConfig[];
  deleteConfiguration: (configuration: BdnConfig) => Promise<void>;
  createOrUpdateBdnConfiguration: (bdnConfig: BdnConfig) => void;
  refectchBDNCongfigurations: () => void;
  navAdminType: string;
  auth: Auth;
  serviceLayer: AuthenticationServiceLayer;
}

const BDNConfigurationTable: React.FC<OwnProps> = ({
  bdnConfigurations,
  deleteConfiguration,
  createOrUpdateBdnConfiguration,
  refectchBDNCongfigurations,
  navAdminType,
  auth,
  serviceLayer,
}) => {
  const onCreateNew = (x: any) => {
    setCreatingNewConfig(true);
  };

  const [selectedConfiguration, displayEditConfigurationFor] = React.useState<BdnConfig>({} as BdnConfig);
  const [creatingNewConfig, setCreatingNewConfig] = React.useState(false);

  const headers: Array<Column<BdnConfig>> = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      /*  {
        Header: 'Date created',
        accessor: (row) => {
          return row.date;
        },
      }, */
      {
        Header: 'Delete',
        Cell: (props: any) => {
          const data = props.cell.row.original;
          return (
            <div className="delete-cell">
              <DeleteConfiguration
                config={data ? data : ''}
                //@ts-ignore
                deleteMethod={deleteConfiguration}
                header="Delete template"
                delBtnIcon={deleteIcon}
              />
            </div>
          );
        },
      },
      {
        Header: 'Edit',
        Cell: (props: any) => {
          const configuration = props.cell.row.original;
          return (
            <div className="edit-cell">
              <Button
                className={editButtonStyles['btn-edit']}
                onClick={() => {
                  displayEditConfigurationFor(configuration);
                }}
              >
                <FontAwesomeIcon icon={editIcon} />
              </Button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const history = useHistory();
  if (creatingNewConfig) {
    return (
      <EditBDNConfiguration
        auth={auth}
        selectedConfigurationId={''}
        goBack={() => {
          setCreatingNewConfig(false);
          refectchBDNCongfigurations();
          displayEditConfigurationFor({} as BdnConfig);
        }}
        isNew={true}
        serviceLayer={serviceLayer}
      />
    );
  } else if (selectedConfiguration._id) {
    return (
      <EditBDNConfiguration
        auth={auth}
        selectedConfigurationId={selectedConfiguration._id}
        goBack={() => {
          refectchBDNCongfigurations();
          displayEditConfigurationFor({} as BdnConfig);
        }}
        serviceLayer={serviceLayer}
      />
    );
  } else {
    return (
      <div>
        <StyledTableWrapper style={{ marginTop: '50px' }}>
          <Button color="primary" size="lg" onClick={onCreateNew}>
            <FontAwesomeIcon icon={addIcon} /> Add new template
          </Button>
          {bdnConfigurations && bdnConfigurations.length ? (
            <FuelBossTableContainer
              headers={headers}
              tableRows={bdnConfigurations}
              showPagination={true}
              showFilterSearch={false}
            />
          ) : null}
        </StyledTableWrapper>
      </div>
    );
  }
};

export default React.memo(BDNConfigurationTable);
