import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { CHORUSCOMPANY_LOADING, CHORUSCOMPANY_FETCH_ERROR, GET_CHORUSCOMPANY, ChorusCompanyActions } from './types';
import { ChorusCompanyResponseModel } from './models';

export const getChorusCompanies = () => (dispatch: Dispatch<ChorusCompanyActions>) => {
  dispatch({
    type: CHORUSCOMPANY_LOADING,
  });

  new api<ChorusCompanyResponseModel>({
    dispatch,
    errorType: CHORUSCOMPANY_FETCH_ERROR,
  })
    .get(`api/chorus/GetCompanies/`)
    .then((response) => {
      dispatch({
        type: GET_CHORUSCOMPANY,
        payload: response,
      });
    });
};
