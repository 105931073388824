import { Dispatch } from 'redux';
import {
  IntegrationAssetPermissionsActions,
  INTEGRATION_ASSET_PERMISSIONS_CLEAR_TOAST,
  INTEGRATION_ASSET_PERMISSIONS_LOADING,
  GET_INTEGRATION_ASSET_PERMISSIONS,
  INTEGRATION_ASSET_PERMISSIONS_FETCH_ERROR,
  ADD_INTEGRATION_ASSET_PERMISSIONS,
  DELETE_INTEGRATION_ASSET_PERMISSIONS,
  EDIT_INTEGRATION_ASSET_PERMISSIONS,
  INTEGRATION_ASSET_PERMISSIONS_EDIT_ERROR,
  INTEGRATION_ASSET_PERMISSIONS_ADD_ERROR,
  INTEGRATION_ASSET_PERMISSIONS_DELETE_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { IntegrationAssetPermissionsResponseModel, IntegrationAssetPermissions } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating integration asset permissions...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Integration asset permissions updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating integration asset permissions...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Integration asset permissions created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;


export const getIntegrationAssetPermissions = () => (dispatch: Dispatch<IntegrationAssetPermissionsActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_PERMISSIONS_LOADING,
  });

  new api<IntegrationAssetPermissionsResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_PERMISSIONS_FETCH_ERROR,
  })
    .get(`/api/Integration/AssetPermission`)
    .then((response) => {
      dispatch({
        type: GET_INTEGRATION_ASSET_PERMISSIONS,
        payload: response,
      });
    });
};

export const addIntegrationAssetPermissions = (integrationAssetPermissions: IntegrationAssetPermissions, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationAssetPermissionsActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_PERMISSIONS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<IntegrationAssetPermissionsResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_PERMISSIONS_ADD_ERROR,
  })
    .post(`/api/Integration/AssetPermission`, integrationAssetPermissions)
    .then((response) => {
      dispatch({
        type: ADD_INTEGRATION_ASSET_PERMISSIONS,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteIntegrationAssetPermissions = (id: string) => (dispatch: Dispatch<IntegrationAssetPermissionsActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_PERMISSIONS_LOADING,
  });

  const url = `/api/Integration/AssetPermission/${id}`;
  new api<IntegrationAssetPermissionsResponseModel>({
    dispatch,
    errorType: INTEGRATION_ASSET_PERMISSIONS_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_INTEGRATION_ASSET_PERMISSIONS,
        payload: response,
      });
    }).catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const editIntegrationAssetPermissions = (integrationAssetPermissions: IntegrationAssetPermissions, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationAssetPermissionsActions>,
) => {
  dispatch({
    type: INTEGRATION_ASSET_PERMISSIONS_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (integrationAssetPermissions.id != undefined) {
    const url = `/api/Integration/AssetPermission/${integrationAssetPermissions.id}`;

    new api<IntegrationAssetPermissionsResponseModel>({
      dispatch,
      errorType: INTEGRATION_ASSET_PERMISSIONS_EDIT_ERROR,
    })
      .put(url, integrationAssetPermissions)
      .then((response) => {
        dispatch({
          type: EDIT_INTEGRATION_ASSET_PERMISSIONS,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const integrationAssetPermissionsClearToast = () => (dispatch: Dispatch<IntegrationAssetPermissionsActions>) => {
  dispatch({
    type: INTEGRATION_ASSET_PERMISSIONS_CLEAR_TOAST,
  });
};
