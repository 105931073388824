import React from 'react';
import { ErrorActionPayload } from '../../../../models/ErrorAction';
import { ReactComponent as PageNotFoundIcon } from '../svgs/question-square.svg';
import { ReactComponent as ErrorIcon } from '../svgs/error-generic-graphic.svg';
import styles from '../../Error.module.scss';

interface OwnProps {
  error?: ErrorActionPayload;
}

export const PageNotFoundError: React.FC<OwnProps> = React.memo(({ error }) => (
  <div className={styles.errorComponent}>
    <div className={styles['icon-wrapper']}>
      <ErrorIcon className={styles.image} />
      <div className={styles['status-code']}>{error ? error.statusCode : 404}</div>
    </div>
    <h2>Page not found</h2>
    <h5 className={styles.header}>We are sorry, but this page does not exist.</h5>
    <div className={styles['error-description']}>
      <p>If you typed the address yourself, are you sure you typed it correctly?</p>
      <p>If we sent you here: We are sorry. Please let us know about the broken link.</p>
    </div>
    <a className={`btn btn-primary ${styles['continue-button']}`} href="/">
      Click here to continue
    </a>
    {error && error.message && <div>{error.message}</div>}
  </div>
));
