import { StandardWrapper } from '../../models/Responses';
import { Company } from '../Company/models';
import { FuelBossLocation } from '../FuelbossLocations/models';

export type BDNLocationWrapperModel = StandardWrapper<BDNLocation[], {}>;
export type BDNLocationResponseModel = BDNLocation[];

export interface BDNLocation {
  supplierId: string;
  company?: Company;
  id: string;
  deliveryMode: string;
  location: FuelBossLocation;
  locationId: string;
  active: boolean;
  generatedBy: LocationGeneratedBy;
  numberOfOperations: number;
}

export enum LocationGeneratedBy {
  System = 0,
  User,
}
