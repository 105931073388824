import { BDNLocationWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const BDN_LOCATIONS_LOADING = 'bdnLocations/loading';
export type BDN_LOCATIONS_LOADING = typeof BDN_LOCATIONS_LOADING;

export const GET_BDN_LOCATIONS = 'bdnLocations/get';
export type GET_BDN_LOCATIONS = typeof GET_BDN_LOCATIONS;

export const BDN_LOCATIONS_FETCH_ERROR = 'bdnLocations/fetchError';
export type BDN_LOCATIONS_FETCH_ERROR = typeof BDN_LOCATIONS_FETCH_ERROR;

export const UPDATE_BDN_LOCATIONS = 'bdnLocations/update';
export type UPDATE_BDN_LOCATIONS = typeof UPDATE_BDN_LOCATIONS;

export const UPDATE_BDN_LOCATIONS_ERROR = 'bdnLocations/updateError';
export type UPDATE_BDN_LOCATIONS_ERROR = typeof UPDATE_BDN_LOCATIONS_ERROR;

export const BDN_LOCATIONS_CLEAR_TOAST = 'bdnLocations/clearToast';
export type BDN_LOCATIONS_CLEAR_TOAST = typeof BDN_LOCATIONS_CLEAR_TOAST;

export const DELETE_BDN_LOCATIONS = 'bdnlocations/delete';
export type DELETE_BDN_LOCATIONS = typeof DELETE_BDN_LOCATIONS;

export const DELETE_BDN_LOCATIONS_ERROR = 'bdnlocations/deleteError';
export type DELETE_BDN_LOCATIONS_ERROR = typeof DELETE_BDN_LOCATIONS_ERROR;

export const ADD_BDN_LOCATIONS = 'bdnlocations/add';
export type ADD_BDN_LOCATIONS = typeof ADD_BDN_LOCATIONS;

export const ADD_BDN_LOCATIONS_ERROR = 'bdnlocations/addError';
export type ADD_BDN_LOCATIONS_ERROR = typeof ADD_BDN_LOCATIONS_ERROR;

export type BDNLocationsActions =
  | {
      type: GET_BDN_LOCATIONS;
      payload: BDNLocationWrapperModel['data'];
    }
  | {
      type: BDN_LOCATIONS_LOADING;
    }
  | {
      type: BDN_LOCATIONS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: UPDATE_BDN_LOCATIONS;
      payload: BDNLocationWrapperModel['data'];
    }
  | {
      type: UPDATE_BDN_LOCATIONS_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: BDN_LOCATIONS_CLEAR_TOAST;
    }
  |{
      type: DELETE_BDN_LOCATIONS;
      payload: BDNLocationWrapperModel['data'];
    }
  | {
      type: DELETE_BDN_LOCATIONS_ERROR;
      payload: ErrorActionPayload;
    }
    | {
      type: ADD_BDN_LOCATIONS;
      payload: BDNLocationWrapperModel['data'];
    }
  | {
      type: ADD_BDN_LOCATIONS_ERROR;
      payload: ErrorActionPayload;
    };
  
