import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';

const validationMessage = {message: 'Not valid', exculdeEmptyString: true};

export default Yup.object().shape({
    topic: Yup.string().matches(integrationAssetConfigs.topic.regex, validationMessage).required('Integration asset topic required'),
    dataEventType: Yup.string().required('Event type required'),
    dataStructure: Yup.string().required('Data structure required'),
    timeStamp: Yup.date().optional(),
})