import React from 'react';
import { SpotEnquiry } from '../../../../store/Spot/models';
import { INominationEnquiry } from '@teqplay/chorus-components';
import { Nomination } from '../../../../store/Nomination/models';
import CountDown from '../CountDown/CountDown';
import styles from './SpotList.module.scss';
import Moment from 'react-moment';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Category } from '../../../../models/Category';
import { getSpotEventIds } from './../../../../helpers/spotEnquiryHelper';

const archiveIcon = findIconDefinition({ prefix: 'fal', iconName: 'archive' });
const finalisedIcon = findIconDefinition({ prefix: 'fal', iconName: 'clipboard-check' });
const banIcon = findIconDefinition({ prefix: 'fal', iconName: 'ban' });

interface OwnProps {
  spots: SpotEnquiry[];
  chooseSpot: (spotId: string[], spotObject: SpotEnquiry) => void;
  activeSpotId: string;
  displayingArchivedSpots: boolean;
}

const SpotList: React.FC<OwnProps> = ({ spots, chooseSpot, activeSpotId, displayingArchivedSpots }) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));
  const isUserShipOwner = () => {
    return auth.company && (auth.company.category.categoryName == Category.Shipowner || auth.company.category.categoryName == Category.Charterer);
  };

  return (
    <div className={`${styles['nomination-cards']}`}>
      {spots.map((spot) => {
        if (spot)
          return (
            <button
              key={spot._id}
              className={`${styles['nomination-button']} ${spot.attributes ? styles[spot.attributes.fbStatus] : ' '} 
            ${activeSpotId === spot._id ? styles['active'] : ''}`}
              onClick={() => {
                if (isUserShipOwner()) {
                  chooseSpot(getSpotEventIds(spot), spot);
                } else {
                  const spotenquiry = spot as Nomination;
                  chooseSpot([spotenquiry.eventId], spot);
                }
              }}
            >
              <div className={styles['eta-wrapper']}>
                {displayingArchivedSpots && spot.attributes && spot.attributes.fbStatus === 'cancelled' && (
                  <FontAwesomeIcon className={styles['cancelled-icon']} icon={banIcon} />
                )}
                {displayingArchivedSpots && spot.attributes && spot.attributes.fbStatus === 'finalised' && (
                  <FontAwesomeIcon className={styles['finalised-icon']} icon={finalisedIcon} />
                )}
                {!displayingArchivedSpots && <CountDown date={new Date(spot.bst)} />}
              </div>
              <div className={styles['right']}>
                <div className={styles['header']}>{spot.attributes?.receivingShipName}</div>
                <div className={styles['description']}>
                  <Moment date={new Date(spot.bst)} format="DD MMM YYYY" />,{' ' + spot.attributes?.port}
                </div>
              </div>
            </button>
          );
        else return null;
      })}
    </div>
  );
};

export default SpotList;
