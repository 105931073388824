import { OutboundIntegrationApiTrackerWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const OUTBOUNDINTEGRATIONAPITRACKER_LOADING = 'outboundIntegrationApiTracker/loading';
export type OUTBOUNDINTEGRATIONAPITRACKER_LOADING = typeof OUTBOUNDINTEGRATIONAPITRACKER_LOADING;

export const GET_OUTBOUNDINTEGRATIONAPITRACKER = 'outboundIntegrationApiTracker/get';
export type GET_OUTBOUNDINTEGRATIONAPITRACKER = typeof GET_OUTBOUNDINTEGRATIONAPITRACKER;

export const OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR = 'outboundIntegrationApiTracker/fetchError';
export type OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR = typeof OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR;

export const ADD_OUTBOUNDINTEGRATIONAPITRACKER = 'outboundIntegrationApiTracker/add';
export type ADD_OUTBOUNDINTEGRATIONAPITRACKER = typeof ADD_OUTBOUNDINTEGRATIONAPITRACKER;

export const OUTBOUNDINTEGRATIONAPITRACKER_ADD_ERROR = 'outboundIntegrationApiTracker/addError';
export type OUTBOUNDINTEGRATIONAPITRACKER_ADD_ERROR = typeof OUTBOUNDINTEGRATIONAPITRACKER_ADD_ERROR;

export const ADD_OUTBOUNDINTEGRATIONASSET = 'outboundIntegrationAsset/add';
export type ADD_OUTBOUNDINTEGRATIONASSET = typeof ADD_OUTBOUNDINTEGRATIONASSET;

export const OUTBOUNDINTEGRATIONASSET_ADD_ERROR = 'outboundIntegrationAsset/addError';
export type OUTBOUNDINTEGRATIONASSET_ADD_ERROR = typeof OUTBOUNDINTEGRATIONASSET_ADD_ERROR;

export const OUTBOUNDINTEGRATIONAPITRACKER_DELETE_ERROR = 'outboundIntegrationApiTracker/deleteError';
export type OUTBOUNDINTEGRATIONAPITRACKER_DELETE_ERROR = typeof OUTBOUNDINTEGRATIONAPITRACKER_DELETE_ERROR;

export const OUTBOUNDINTEGRATIONAPITRACKER_EDIT_ERROR = 'outboundIntegrationApiTracker/editError';
export type OUTBOUNDINTEGRATIONAPITRACKER_EDIT_ERROR = typeof OUTBOUNDINTEGRATIONAPITRACKER_EDIT_ERROR;

export const DELETE_OUTBOUNDINTEGRATIONAPITRACKER = 'outboundIntegrationApiTracker/delete';
export type DELETE_OUTBOUNDINTEGRATIONAPITRACKER = typeof DELETE_OUTBOUNDINTEGRATIONAPITRACKER;

export const EDIT_OUTBOUNDINTEGRATIONAPITRACKER = 'outboundIntegrationApiTracker/edit';
export type EDIT_OUTBOUNDINTEGRATIONAPITRACKER = typeof EDIT_OUTBOUNDINTEGRATIONAPITRACKER;

export const OUTBOUNDINTEGRATIONAPITRACKER_CLEAR_TOAST = 'outboundIntegrationApiTracker/ClearToast';
export type OUTBOUNDINTEGRATIONAPITRACKER_CLEAR_TOAST = typeof OUTBOUNDINTEGRATIONAPITRACKER_CLEAR_TOAST;


export type OutboundIntegrationApiTrackerActions = 
    | {
        type: GET_OUTBOUNDINTEGRATIONAPITRACKER;
        payload: OutboundIntegrationApiTrackerWrapperModel['data'];
    }
    | {
        type: OUTBOUNDINTEGRATIONAPITRACKER_LOADING;
    }
    | {
        type: OUTBOUNDINTEGRATIONAPITRACKER_FETCH_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: OUTBOUNDINTEGRATIONAPITRACKER_ADD_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: OUTBOUNDINTEGRATIONAPITRACKER_DELETE_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: OUTBOUNDINTEGRATIONAPITRACKER_EDIT_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: OUTBOUNDINTEGRATIONASSET_ADD_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: ADD_OUTBOUNDINTEGRATIONAPITRACKER;
        payload: OutboundIntegrationApiTrackerWrapperModel['data'];
    }
    | {
        type: DELETE_OUTBOUNDINTEGRATIONAPITRACKER;
        payload: OutboundIntegrationApiTrackerWrapperModel['data'];
    }
    | {
        type: EDIT_OUTBOUNDINTEGRATIONAPITRACKER;
        payload: OutboundIntegrationApiTrackerWrapperModel['data'];
    }
    | {
        type: ADD_OUTBOUNDINTEGRATIONASSET;
    }
    | {
        type: OUTBOUNDINTEGRATIONAPITRACKER_CLEAR_TOAST;
    };
