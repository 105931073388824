import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUpload from './FileUpload';
import styles from './FilesUpload.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

const FilesUpload = ({
  files,
  fileType,
  onLoadFile,
  onDeleteFile,
  acceptMimeTypes = ['application/pdf'],
  maxSize = 27000000, //27MB
  maxNumberFiles = 1,
}) => {
  const [exceedNumberOfFiles, setExceedNumberOfFiles] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loadedFile, setLoadedFile] = useState(undefined);

  const fileExtensions = {
    'application/pdf': '.pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'application/msword': '.doc',
    'image/jpeg': '.jpeg',
    'image/png': '.png',
  };

  const fileExtensionsString = acceptMimeTypes
  .map((mimeType) => fileExtensions[mimeType])
  .join(', ');

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (files == null || acceptedFiles == null || fileType == "image" || files.length + acceptedFiles.length <= maxNumberFiles) {
        setExceedNumberOfFiles(false);
      } else {
        setExceedNumberOfFiles(true);
        return;
      }

      let index = 0;
      acceptedFiles.forEach(async (file) => {
        const reader = new FileReader();

        reader.onabort = () => { };

        reader.onerror = () => { };

        reader.onload = async () => {
          await setProcessing(true);
          await onLoadFile(file, file.type, reader);
          setLoadedFile(reader.result.split(',')[1]);
          if (index == acceptedFiles.length - 1) await setProcessing(false);

          index++;
        };

        reader.readAsDataURL(file);
      });
    },
    [files],
  );

  const allFilesLoaded = () => {
    return files != null && files.length === maxNumberFiles && fileType != 'image';
  };

  const { isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles, fileRejections } = useDropzone({
    onDrop,
    accept: acceptMimeTypes.join(),
    minSize: 0,
    maxSize: maxSize,
    multiple: true,
    disabled: allFilesLoaded() || processing,
  });

  const isAnyFileTooLarge =
    rejectedFiles != null && rejectedFiles.length > 0 && rejectedFiles.some((f) => f.size > maxSize);

  const handleDelete = async (file) => {
    setProcessing(true);

    await onDeleteFile(file, fileType);
    setLoadedFile(undefined);

    const remainingFiles = files.filter((f) => f.id !== file.id);
    files = remainingFiles;

    setProcessing(false);
  };

  React.useEffect(() => {
    //console.log('got change in files',files);
    if (files && files.length == 1) {
      setLoadedFile(files[0].fileData);
    }
  }, []);

  const renderInfo = () => {
    return (
      <React.Fragment>
        {areAllFilesLoaded && <span>All required files uploaded</span>}
        {!areAllFilesLoaded &&
          (isDragActive ? (
            <a>Upload file</a>
          ) : (
            <div className={styles['drag-files-message']}>
              <span>
                <FontAwesomeIcon style={{ color: '#fff', marginBottom: '5px' }} size={'lg'} icon={faCloudUploadAlt} />
              </span>
              <span className={styles['drag-files-message-span']}>Drag files here</span>
              <a className={styles['drag-files-message-link']}>Or chose from your computer...</a>
            </div>
          ))}
      </React.Fragment>
    );
  };

  const areAllFilesLoaded = allFilesLoaded();

  return (
    <div className={styles['FilesUpload']}>
      <div className={styles['files-container']}>
        {(!loadedFile || (files && files.length <= maxNumberFiles) || fileType == 'image') && (
          <div className={styles['container']} {...getRootProps()}>
            <input {...getInputProps()} />
            {processing ? <div className={styles['spinner-s']} /> : renderInfo()}
          </div>
        )}
        {loadedFile && fileType == 'image' && (
          <div className={styles['container']}>
            <img className={styles['loaded-image-file']} src={`${'data:image/png;base64,'}${loadedFile}`}></img>
          </div>
        )}
      </div>
      <div className={styles['files-list-container']}>
        {files != null &&
          files
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((file) => <FileUpload key={file.id} file={file} onDelete={() => handleDelete(file)} url={file.url} />)}
      </div>
      {exceedNumberOfFiles && (
        <div className={styles['text-warning']}>Exceed the maximum of {maxNumberFiles} file(s)!</div>
      )}
      {isDragReject&& (
        <div className={styles['text-warning']}>File type not accepted (only: {fileExtensionsString})</div>
      )}
      {fileRejections.find((x)=> x.errors) && (
        <div className={styles['text-warning']}>File type not accepted (only: {fileExtensionsString})</div>
      )}
      {isAnyFileTooLarge && <div className={styles['text-warning']}>File is larger than 25MB!</div>}
    </div>
  );
};

export default FilesUpload;
