import * as TYPE from './types';
import { ApplicationState } from '..';

export const pipelineReducer = (
  state: ApplicationState['pipelines'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.PipelineActions,
): ApplicationState['pipelines'] => {
  switch (action.type) {
    case TYPE.PIPELINE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_PIPELINE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorDetails: undefined,
        dataIsLoaded: true,
      };
    case TYPE.PIPELINE_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_PIPELINE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
        toastData: {
          content: 'Pipeline successfully added',
          type: 'success',
        },
      };
    case TYPE.PIPELINE_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : action.payload.statusCode == 409
            ? `We could not create the new pipeline. Pipeline with this name already exists in FuelBoss.`
            : `We could not create the new pipeline. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.DELETE_PIPELINE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
        toastData: {
          content: 'Pipeline deleted',
          type: 'info',
        },
      };
    case TYPE.PIPELINE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not delete Pipeline as Users might be linked with it. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.EDIT_PIPELINE:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.PIPELINE_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
  }
  return { ...state };
};
