import { SpotEnquiry } from '../store/Spot/models';
import { INominationEnquiry } from '@teqplay/chorus-components';
import { Contract } from '../store/Contract/models';

export const getSpotEventIds = (spotenquiry: SpotEnquiry) => {
  const spot = spotenquiry as INominationEnquiry;
  const eventsByVendor = spot.eventsByVendor;
  let eventIds: string[] = [];
  for (var key in eventsByVendor) {
    if (eventsByVendor.hasOwnProperty(key)) {
      eventIds.push(eventsByVendor[key]);
    }
  }
  return eventIds;
};

export const getSpotEventStatuses = (spotEnquiryObject: SpotEnquiry) => {
  const spotObj = spotEnquiryObject as INominationEnquiry;
  const statusesByVendor = spotObj.attributes?.fbStatusByVendor;
  let statuses: string[] = [];
  for (var key in statusesByVendor) {
    if (statusesByVendor.hasOwnProperty(key)) {
      statuses.push(statusesByVendor[key]);
    }
  }
  return statuses;
};

export const getSpotCompanyIds = (spotEnquiryObject: SpotEnquiry) => {
  const spotObj = spotEnquiryObject as INominationEnquiry;
  const eventsByVendor = spotObj.eventsByVendor;
  let companyIds: string[] = [];
  for (var key in eventsByVendor) {
    if (eventsByVendor.hasOwnProperty(key)) {
      companyIds.push(key);
    }
  }
  return companyIds;
};

export const contractHasExpired = (contract: Contract) => {
  const todayDate = new Date();
  const endDate = new Date(contract?.endDate!);

  if (contract.status == null) {
    return false;
  }
  if (contract?.endDateOption != null) {
    const endDateOption = new Date(contract?.endDateOption!);
    if (endDateOption > todayDate) {
      return false;
    }
  } else {
    if (endDate > todayDate) {
      return false;
    }
  }
  return true;
};
