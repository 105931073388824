import * as React from 'react';
import * as reactstrap from 'reactstrap';
import styles from '../../Table.module.scss';

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const chevronLeft = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-left',
});
const chevronRight = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-right',
});
const chevronDoubleLeft = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-double-left',
});
const chevronDoubleRight = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-double-right',
});

interface OwnProps {
  currentPage: number;
  setCurrentPage: (num: number) => void;
  maxPage: number;
}

const Pagination: React.FC<OwnProps> = ({ currentPage, setCurrentPage, maxPage }) => {
  const decreaseCurrentPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const increaseCurrentPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const RenderPaginationButtons = () => {
    let numbers;
    if (currentPage === 1 && currentPage + 2 <= maxPage) numbers = [currentPage, currentPage + 1, currentPage + 2];
    else if (currentPage === maxPage) numbers = [currentPage - 2, currentPage - 1, currentPage];
    else numbers = [currentPage - 1, currentPage, currentPage + 1];

    const buttons: JSX.Element[] = [];

    numbers.forEach((number) => {
      if (number > 0 && number <= maxPage) {
        buttons.push(
          <reactstrap.Button
            key={number}
            onClick={() => setCurrentPage(number)}
            color="primary"
            outline={currentPage !== number}
          >
            {number}
          </reactstrap.Button>,
        );
      }
    });

    return buttons;
  };

  return (
    <reactstrap.ButtonGroup className={styles['buttons']}>
      <reactstrap.Button onClick={() => setCurrentPage(1)} outline color="primary" disabled={currentPage === 1}>
        <FontAwesomeIcon icon={chevronDoubleLeft} />
      </reactstrap.Button>
      <reactstrap.Button onClick={decreaseCurrentPage} outline color="primary" disabled={currentPage === 1}>
        <FontAwesomeIcon icon={chevronLeft} />
      </reactstrap.Button>

      {RenderPaginationButtons()}

      <reactstrap.Button onClick={increaseCurrentPage} outline color="primary" disabled={currentPage + 1 > maxPage}>
        <FontAwesomeIcon icon={chevronRight} />
      </reactstrap.Button>
      <reactstrap.Button
        onClick={() => setCurrentPage(maxPage)}
        outline
        color="primary"
        disabled={currentPage === maxPage}
      >
        <FontAwesomeIcon icon={chevronDoubleRight} />
      </reactstrap.Button>
    </reactstrap.ButtonGroup>
  );
};

export default Pagination;
