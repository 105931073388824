import React from 'react';
import FormikWrapper from '../Formik/FormikWrapper';

import { Company } from '../../../../../store/Company/models';
import { User } from '../../../../../store/Users/models';
import { Role } from '../../../../../store/Roles/models';
import { Loading } from '../../../../Loading';

interface OwnProps {
  editUser: (user: User, roles: Role[]) => void;
  userId: string;
  company?: Company;
  users: User[];
  navAdminType: string;
  companyAdmin?: boolean;
  isLoading?: boolean;
}

const EditUser: React.FC<OwnProps> = ({ editUser, userId, companyAdmin, navAdminType, isLoading, users, company }) => {
  const submitUser = (user: User, roles: Role[]) => {
    editUser(user, roles);
  };

  const user: User | undefined = users.find((it) => it.id === userId);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <FormikWrapper
      isInaddUser={false}
      addUser={submitUser}
      companyAdmin={companyAdmin}
      user={user}
      header="Edit team member"
      company={company}
      navAdminType={navAdminType}
    />
  );
};

export default React.memo(EditUser);
