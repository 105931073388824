import * as Yup from 'yup';
import companyConfigs from './CompanyForm/companyConfigs';

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

const categoryValidationSchema = Yup.object().shape({
  id: Yup.string().required('Category required'),
  categoryName: Yup.string().optional(''),
  chorusType: Yup.string().optional(''),
});

export default Yup.object().shape({
  name: Yup.string()
    .matches(companyConfigs.name.regex, validationMessage)
    .required('Name required')
    .test('no-double-space', 'Double spaces are not allowed', (value) => {
      // Return true if the value does not contain two consecutive spaces, false otherwise
      return !/\s\s/.test(value);
    }),
  category: categoryValidationSchema.required('Category required'),
  country: Yup.string().required('Country required'),
  address: Yup.string().matches(companyConfigs.address.regex, validationMessage).required('Address required'),
  orgNumber: Yup.string()
    .matches(companyConfigs.orgNumber.regex, validationMessage)
    .required('Organisation number required'),
  companyInBrief: Yup.string().optional(''),
  areaOfInterest: Yup.string().optional(''),
});
