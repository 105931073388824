import { Dispatch } from 'redux';
import { api } from '../../helpers/api';
import { VolumeStatsResponseModel } from './models';
import { GET_VOLUMESTATS, VolumeStatsActions, VOLUMESTATS_FETCH_ERROR, VOLUMESTATS_LOADING } from './types';

export const getVolumeStats = () => (dispatch: Dispatch<VolumeStatsActions>) => {
  dispatch({
    type: VOLUMESTATS_LOADING,
  });
  const url = `api/chorus/GetVolumeStats/2020-01-01T00:00:00/2040-01-01T00:00:00`;
  new api<VolumeStatsResponseModel>({
    dispatch,
    errorType: VOLUMESTATS_FETCH_ERROR,
  })
    .get(url)
    .then((response) => {
      dispatch({
        type: GET_VOLUMESTATS,
        payload: response,
      });
    });
};
