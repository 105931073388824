import { Dispatch } from 'redux';

interface DispatchObject {
  dispatch: Dispatch;
  errorType: string;
}

interface ApiParameters {
  url: string;
  dispatchObject?: DispatchObject;
  data?: any;
  method?: string;
  token?: string;
}

const header = new Headers();
header.append('content-type', 'application/json');

async function apiMethod<T>({ url, method = 'GET', data, dispatchObject, token }: ApiParameters): Promise<T> {
  if (token && !header.get('Authorization')) {
    header.append('Authorization', token);
  }
  const fetchParams: RequestInit = {
    cache: 'no-cache',
    method,
    mode: token ? 'cors' : 'same-origin',
    headers: header,
  };

  const dispatch = dispatchObject && dispatchObject.dispatch;
  const errorType = dispatchObject && dispatchObject.errorType;

  if (data !== undefined) {
    fetchParams.body = JSON.stringify(data);
  }

  const response = await fetch(url, fetchParams);
  let responseJsonPromise;

  if (response.status >= 500) {
    dispatch &&
      dispatch({
        type: errorType,
        payload: {
          clientFault: false,
          serverFault: true,
          statusCode: response.status,
        },
      });

    throw errorType;
  } else if (response.status > 401) {
    dispatch &&
      dispatch({
        type: errorType,
        payload: {
          clientFault: true,
          serverFault: false,
          statusCode: response.status,
        },
      });

    throw errorType;
  } else if (response.status === 401) {
    // Set the responseJsonPromise
    responseJsonPromise = await response.json();

    throw errorType;
  } else if (response.url.includes("SignInMFA") && response.redirected) {
    // case to force signin System Admins for MFA
    dispatch &&
      dispatch({
        type: errorType,
        payload: {
          clientFault: false,
          serverFault: true,
          statusCode: response.status,
        },
      });
      window.location.replace(response.url);
  } else if (response.status >= 300) {
    dispatch &&
      dispatch({
        type: errorType,
        payload: {
          clientFault: false,
          serverFault: true,
          statusCode: response.status,
        },
      });
  }

  // Return Json Promise
  if (responseJsonPromise !== undefined) {
    return responseJsonPromise;
  } else {
    return await response.json();
  }
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
export const api = class<T> {
  dispatchObject?: DispatchObject;

  constructor(props?: DispatchObject) {
    this.dispatchObject = props;
  }

  get = (url: string, token?: string) => apiMethod<T>({ url, dispatchObject: this.dispatchObject, token });
  post = (url: string, data: any) =>
    apiMethod<T>({
      url,
      dispatchObject: this.dispatchObject,
      method: 'POST',
      data,
    });
  put = (url: string, data: any) =>
    apiMethod<T>({
      url,
      dispatchObject: this.dispatchObject,
      method: 'PUT',
      data,
    });
  delete = (url: string, data: any) =>
    apiMethod<T>({
      url,
      dispatchObject: this.dispatchObject,
      method: 'DELETE',
      data,
    });
};
