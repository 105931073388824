import * as TYPE from './types';

import { ApplicationState } from '..';
import { NominationSchedule } from './models';
const getNominationScheduleData = (data: NominationSchedule[]) => {
  //append Z Zulu indicator at the end of dates to consider them UTC date times at the frontend
  const nominationSchedules = data.map((el) =>
    true
      ? {
          ...el,
          eta: el.eta.slice(-1) == 'Z' ? el.eta : el.eta + 'Z',
          etd: el.etd.slice(-1) == 'Z' ? el.etd : el.etd + 'Z',
          bst: el.bst.slice(-1) == 'Z' ? el.bst : el.bst + 'Z',
          autoCreateDatetime:
            el.autoCreateDatetime.slice(-1) == 'Z' ? el.autoCreateDatetime : el.autoCreateDatetime + 'Z',
          timeStamp: el.timeStamp.slice(-1) == 'Z' ? el.timeStamp : el.timeStamp + 'Z',
        }
      : el,
  );
  return nominationSchedules;
};

export const nomination_scheduleReducer = (
  state: ApplicationState['nomination_schedule'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: undefined,
  },
  action: TYPE.NominationScheduleActions,
): ApplicationState['nomination_schedule'] => {
  switch (action.type) {
    case TYPE.NOMINATIONSCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.NOMINATIONSCHEDULE_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.NOMINATIONSCHEDULE_DELETE_ERROR:
      return {
        ...state,
        error: true,
        errorDetails: action.payload,
        toastData: {
          content: 'The nomination schedule could not be cancled. Please contact FuelBoss support for more details',
          type: 'error',
        },
      };
    case TYPE.DELETE_NOMINATIONSCHEDULE:
      if (action.payload.metaData) {
        return {
          ...state,
          data: getNominationScheduleData(action.payload.data),
          metadata: action.payload.metaData,
          loading: false,
          error: false,
          toastData: {
            content: 'Nomination schedule cancled',
            type: 'info',
          },
        };
      }
      return {
        ...state,
        data: getNominationScheduleData(action.payload.data),
        loading: false,
        error: false,
        toastData: {
          content: 'Nomination schedule cancled',
          type: 'info',
        },
      };
    case TYPE.UPDATE_NOMINATIONSCHEDULE:
      if (action.payload.metaData) {
        return {
          ...state,
          data: getNominationScheduleData(action.payload.data),
          metadata: action.payload.metaData,
          loading: false,
          error: false,
          toastData: {
            content: 'Nomination schedule updated',
            type: 'info',
          },
        };
      }
      return {
        ...state,
        data: getNominationScheduleData(action.payload.data),
        loading: false,
        toastData: {
          content: 'Nomination schedule updated',
          type: 'info',
        },
      };
    case TYPE.UPDATE_NOMINATIONSCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not update the nomination schedule. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };

    case TYPE.CREATENOMINATION_NOMINATIONSCHEDULE_ERROR:
      return {
        ...state,
        error: true,
        errorDetails: action.payload,
        toastData: {
          content:
            'The nomination schedule could not be pushed to nomination. Please contact FuelBoss support for more details',
          type: 'error',
        },
      };
    case TYPE.CREATENOMINATION_NOMINATIONSCHEDULE:
      if (action.payload.metaData) {
        return {
          ...state,
          data: getNominationScheduleData(action.payload.data),
          metadata: action.payload.metaData,
          loading: false,
          error: false,
          toastData: {
            content: 'Nomination schedule pushed to nomination successfully and supplier notified',
            type: 'info',
          },
          dataIsLoaded: true,
        };
      }
      return {
        ...state,
        data: getNominationScheduleData(action.payload.data),
        loading: false,
        error: false,
        toastData: {
          content: 'Nomination schedule pushed to nomination successfully and supplier notified',
          type: 'info',
        },
      };
    case TYPE.GET_NOMINATIONSCHEDULE:
      if (action.payload.metaData) {
        return {
          ...state,
          data: getNominationScheduleData(action.payload.data),
          metadata: action.payload.metaData,
          loading: false,
          error: false,
          dataIsLoaded: true,
        };
      }
      return {
        ...state,
        data: getNominationScheduleData(action.payload.data),
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.NOMINATIONSCHEDULE_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    default:
      return { ...state };
  }
};
