import { Dispatch } from 'redux';
import {
  MapActions,
  SANITY_CONFIG_FETCH_ERROR,
  SANITY_CONFIG_LOADING,
  GET_SANITY_CONFIG,
} from './types';
import { api } from '../../helpers/api';
import {
  SanityConfigResponseModel,
} from './models';


export const getSanityConfiguration = () => (dispatch: Dispatch<MapActions>) => {
  dispatch({
    type: SANITY_CONFIG_LOADING,
  });
  new api<SanityConfigResponseModel>({
    dispatch,
    errorType: SANITY_CONFIG_FETCH_ERROR,
  })
    .get(`/sanity/GetSanityConfiguration`)
    .then((response) => {
      dispatch({
        type: GET_SANITY_CONFIG,
        payload: response,
      });
    });
};
