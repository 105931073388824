import { ConnectivityWrapperModel } from './models';
import { ConnectivityCheckResultWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const FUELBOSS_CONNECTIVITY_CHECK_LOADING = 'connectivityFuelboss/loading';
export type FUELBOSS_CONNECTIVITY_CHECK_LOADING = typeof FUELBOSS_CONNECTIVITY_CHECK_LOADING;

export const FUELBOSS_CONNECTIVITY_CHECK_ERROR = 'connectivityFuelboss/error';
export type FUELBOSS_CONNECTIVITY_CHECK_ERROR = typeof FUELBOSS_CONNECTIVITY_CHECK_ERROR;

export const GET_FUELBOSS_CONNECTIVITY_CHECKS = 'connectivityFuelboss/get';
export type GET_FUELBOSS_CONNECTIVITY_CHECKS = typeof GET_FUELBOSS_CONNECTIVITY_CHECKS;

export const CHORUS_CONNECTIVITY_CHECK_LOADING = 'connectivityChorus/loading';
export type CHORUS_CONNECTIVITY_CHECK_LOADING = typeof CHORUS_CONNECTIVITY_CHECK_LOADING;

export const CHORUS_CONNECTIVITY_CHECK_ERROR = 'connectivityChorus/error';
export type CHORUS_CONNECTIVITY_CHECK_ERROR = typeof CHORUS_CONNECTIVITY_CHECK_ERROR;

export const GET_CHORUS_CONNECTIVITY_CHECKS = 'connectivityChorus/get';
export type GET_CHORUS_CONNECTIVITY_CHECKS = typeof GET_CHORUS_CONNECTIVITY_CHECKS;

export const MAPBOX_CONNECTIVITY_CHECK_LOADING = 'connectivityMapbox/loading';
export type MAPBOX_CONNECTIVITY_CHECK_LOADING = typeof MAPBOX_CONNECTIVITY_CHECK_LOADING;

export const MAPBOX_CONNECTIVITY_CHECK_ERROR = 'connectivityMapbox/error';
export type MAPBOX_CONNECTIVITY_CHECK_ERROR = typeof MAPBOX_CONNECTIVITY_CHECK_ERROR;

export const GET_MAPBOX_CONNECTIVITY_CHECKS = 'connectivityMapbox/get';
export type GET_MAPBOX_CONNECTIVITY_CHECKS = typeof GET_MAPBOX_CONNECTIVITY_CHECKS;

export const SIGNALR_CONNECTIVITY_CHECK_LOADING = 'connectivitySignalR/loading';
export type SIGNALR_CONNECTIVITY_CHECK_LOADING = typeof SIGNALR_CONNECTIVITY_CHECK_LOADING;

export const SIGNALR_CONNECTIVITY_CHECK_ERROR = 'connectivitySignalR/error';
export type SIGNALR_CONNECTIVITY_CHECK_ERROR = typeof SIGNALR_CONNECTIVITY_CHECK_ERROR;

export const GET_SIGNALR_CONNECTIVITY_CHECKS = 'connectivitySignalR/get';
export type GET_SIGNALR_CONNECTIVITY_CHECKS = typeof GET_SIGNALR_CONNECTIVITY_CHECKS;

export const TQPLAYICONS_CONNECTIVITY_CHECK_LOADING = 'connectivityTqpayIcons/loading';
export type TQPLAYICONS_CONNECTIVITY_CHECK_LOADING = typeof TQPLAYICONS_CONNECTIVITY_CHECK_LOADING;

export const TQPLAYICONS_CONNECTIVITY_CHECK_ERROR = 'connectivityTqpayIcons/error';
export type TQPLAYICONS_CONNECTIVITY_CHECK_ERROR = typeof TQPLAYICONS_CONNECTIVITY_CHECK_ERROR;

export const GET_TQPLAYICONS_CONNECTIVITY_CHECKS = 'connectivityTqpayIcons/get';
export type GET_TQPLAYICONS_CONNECTIVITY_CHECKS = typeof GET_TQPLAYICONS_CONNECTIVITY_CHECKS;

export const VERACITY_CONNECTIVITY_CHECK_LOADING = 'connectivityVeracity/loading';
export type VERACITY_CONNECTIVITY_CHECK_LOADING = typeof VERACITY_CONNECTIVITY_CHECK_LOADING;

export const VERACITY_CONNECTIVITY_CHECK_ERROR = 'connectivityVeracity/error';
export type VERACITY_CONNECTIVITY_CHECK_ERROR = typeof VERACITY_CONNECTIVITY_CHECK_ERROR;

export const GET_VERACITY_CONNECTIVITY_CHECKS = 'connectivityVeracity/get';
export type GET_VERACITY_CONNECTIVITY_CHECKS = typeof GET_VERACITY_CONNECTIVITY_CHECKS;

export const CLEAR_CONNECTIVITY_CHECKS = 'connectivity/ClearToast';
export type CLEAR_CONNECTIVITY_CHECKS = typeof CLEAR_CONNECTIVITY_CHECKS;

export const ADD_CONNECTIVITYCHECK_RESULT = 'connectivityResult/add';
export type ADD_CONNECTIVITYCHECK_RESULT = typeof ADD_CONNECTIVITYCHECK_RESULT;

export const ADD_CONNECTIVITYCHECK_RESULT_ERROR = 'connectivityResult/updateError';
export type ADD_CONNECTIVITYCHECK_RESULT_ERROR = typeof ADD_CONNECTIVITYCHECK_RESULT_ERROR;

export type ConnectivityCheckActions =
  | {
      type: GET_FUELBOSS_CONNECTIVITY_CHECKS;
      payload: ConnectivityWrapperModel['data'];
    }
  | {
      type: FUELBOSS_CONNECTIVITY_CHECK_LOADING;
    }
  | {
      type: FUELBOSS_CONNECTIVITY_CHECK_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_CHORUS_CONNECTIVITY_CHECKS;
      payload: ConnectivityWrapperModel['data'];
    }
  | {
      type: CHORUS_CONNECTIVITY_CHECK_LOADING;
    }
  | {
      type: CHORUS_CONNECTIVITY_CHECK_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_MAPBOX_CONNECTIVITY_CHECKS;
      payload: ConnectivityWrapperModel['data'];
    }
  | {
      type: MAPBOX_CONNECTIVITY_CHECK_LOADING;
    }
  | {
      type: MAPBOX_CONNECTIVITY_CHECK_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_SIGNALR_CONNECTIVITY_CHECKS;
      payload: ConnectivityWrapperModel['data'];
    }
  | {
      type: SIGNALR_CONNECTIVITY_CHECK_LOADING;
    }
  | {
      type: SIGNALR_CONNECTIVITY_CHECK_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_TQPLAYICONS_CONNECTIVITY_CHECKS;
      payload: ConnectivityWrapperModel['data'];
    }
  | {
      type: TQPLAYICONS_CONNECTIVITY_CHECK_LOADING;
    }
  | {
      type: TQPLAYICONS_CONNECTIVITY_CHECK_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_VERACITY_CONNECTIVITY_CHECKS;
      payload: ConnectivityWrapperModel['data'];
    }
  | {
      type: VERACITY_CONNECTIVITY_CHECK_LOADING;
    }
  | {
      type: VERACITY_CONNECTIVITY_CHECK_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: CLEAR_CONNECTIVITY_CHECKS;
    }
  | {
      type: ADD_CONNECTIVITYCHECK_RESULT;
      payload: ConnectivityCheckResultWrapperModel['data'];
    }
  | {
      type: ADD_CONNECTIVITYCHECK_RESULT_ERROR;
      payload: ErrorActionPayload;
    };
