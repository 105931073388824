import { IntegrationAssetCompanyWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_COMPANY_CLEAR_TOAST = 'integrationAssetCompany/ClearToast';
export type INTEGRATION_ASSET_COMPANY_CLEAR_TOAST = typeof INTEGRATION_ASSET_COMPANY_CLEAR_TOAST;

export const INTEGRATION_ASSET_COMPANY_LOADING = 'integrationAssetCompany/loading';
export type INTEGRATION_ASSET_COMPANY_LOADING = typeof INTEGRATION_ASSET_COMPANY_LOADING;

export const GET_INTEGRATION_ASSET_COMPANY = 'integrationAssetCompany/get';
export type GET_INTEGRATION_ASSET_COMPANY = typeof GET_INTEGRATION_ASSET_COMPANY;

export const INTEGRATION_ASSET_COMPANY_FETCH_ERROR = 'integrationAssetCompany/fetchError';
export type INTEGRATION_ASSET_COMPANY_FETCH_ERROR = typeof INTEGRATION_ASSET_COMPANY_FETCH_ERROR;

export const ADD_INTEGRATION_ASSET_COMPANY = 'integrationAssetCompany/add';
export type ADD_INTEGRATION_ASSET_COMPANY = typeof ADD_INTEGRATION_ASSET_COMPANY;

export const EDIT_INTEGRATION_ASSET_COMPANY = 'integrationAssetCompany/edit';
export type EDIT_INTEGRATION_ASSET_COMPANY = typeof EDIT_INTEGRATION_ASSET_COMPANY;

export const DELETE_INTEGRATION_ASSET_COMPANY = 'integrationAssetCompany/delete';
export type DELETE_INTEGRATION_ASSET_COMPANY = typeof DELETE_INTEGRATION_ASSET_COMPANY;

export const INTEGRATION_ASSET_COMPANY_ADD_ERROR = 'integrationAssetCompany/addError';
export type INTEGRATION_ASSET_COMPANY_ADD_ERROR = typeof INTEGRATION_ASSET_COMPANY_ADD_ERROR;

export const INTEGRATION_ASSET_COMPANY_EDIT_ERROR = 'integrationAssetCompany/editError';
export type INTEGRATION_ASSET_COMPANY_EDIT_ERROR = typeof INTEGRATION_ASSET_COMPANY_EDIT_ERROR;

export const INTEGRATION_ASSET_COMPANY_DELETE_ERROR = 'integrationAssetCompany/deleteError';
export type INTEGRATION_ASSET_COMPANY_DELETE_ERROR = typeof INTEGRATION_ASSET_COMPANY_DELETE_ERROR;

export type IntegrationAssetCompanyActions =
  | {
      type: INTEGRATION_ASSET_COMPANY_LOADING;
    }
  | {
      type: GET_INTEGRATION_ASSET_COMPANY;
      payload: IntegrationAssetCompanyWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_ASSET_COMPANY;
      payload: IntegrationAssetCompanyWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_ASSET_COMPANY;
      payload: IntegrationAssetCompanyWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_COMPANY;
      payload: IntegrationAssetCompanyWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_COMPANY_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_COMPANY_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_COMPANY_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_COMPANY_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_COMPANY_CLEAR_TOAST;
    };
