import { PaginationMeta } from '../../helpers/PaginationMeta';
import { ErrorActionPayload } from '../../models/ErrorAction';
import { Nomination } from '../Nomination/models';

export const ARCHIVED_NOMINATION_LOADING = 'nomination/archived/loading';
export type ARCHIVED_NOMINATION_LOADING = typeof ARCHIVED_NOMINATION_LOADING;

export const GET_ARCHIVED_NOMINATION = 'nomination/archived/get';
export type GET_ARCHIVED_NOMINATION = typeof GET_ARCHIVED_NOMINATION;

export const ARCHIVED_NOMINATION_FETCH_ERROR = 'nomination/archived/fetchError';
export type ARCHIVED_NOMINATION_FETCH_ERROR = typeof ARCHIVED_NOMINATION_FETCH_ERROR;

export const GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_APPEND = 'nomination/archived/nominationScrollAppend/get';
export type GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_APPEND = typeof GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_APPEND;

export const GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH = 'nomination/archived/nominationScrollRefresh/get';
export type GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH = typeof GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH;

export const CLEAR_ARCHIVED_NOMINATION = 'nomination/archived/clear';
export type CLEAR_ARCHIVED_NOMINATION = typeof CLEAR_ARCHIVED_NOMINATION;

export type ArchivedNominationActions =
  | {
      type: GET_ARCHIVED_NOMINATION;
      payload: { data: Nomination[]; metadata?: PaginationMeta };
    }
  | {
      type: ARCHIVED_NOMINATION_LOADING;
    }
  | {
      type: ARCHIVED_NOMINATION_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_APPEND;
      payload: { data: Nomination[] };
    }
  | {
      type: GET_ARCHIVED_NOMINATION_INFINITE_SCROLL_REFRESH;
      payload: { data: Nomination[] };
    }
  | {
      type: CLEAR_ARCHIVED_NOMINATION;
    };
