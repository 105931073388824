import React from 'react';
import { Nomination } from '../../../../store/Nomination/models';
import { ILocation, IShipWithId, LocationService } from '@teqplay/chorus-components';
import CountDown from '../CountDown/CountDown';
import styles from './RecurringNominationList.module.scss';
import Moment from 'react-moment';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecurringNomination } from '../../../../store/RecurringNomination/models';
const archiveIcon = findIconDefinition({ prefix: 'fal', iconName: 'archive' });
const banIcon = findIconDefinition({ prefix: 'fal', iconName: 'ban' });

interface OwnProps {
  nominations: RecurringNomination[];
  chooseNomination: (nominationId: string) => void;
  activeNominationId: string;
  locationService: LocationService;
}

const RecurringNominationList: React.FC<OwnProps> = ({
  nominations,
  chooseNomination,
  activeNominationId,
  locationService,
}) => {
  const getLocationText = async (choursLocationId: string) => {
    const selectedLocation: ILocation | undefined = await locationService.findLocationById(choursLocationId);
    if (selectedLocation) {
      return `${selectedLocation.port}, ${selectedLocation.country}`;
    } else {
      return choursLocationId;
    }
  };

  const Location = (locationId: string) => {
    const [locationName, setlocationName] = React.useState('');

    const getlocationName = async (id: string) => {
      const location_name = await getLocationText(id);
      setlocationName(location_name);
    };

    React.useEffect(() => {
      getlocationName(locationId);
    }, []);

    return locationName;
  };

  return (
    <div className={`${styles['nomination-cards']}`}>
      {nominations.map((nom) => {
        if (nom) {
          const locationName = Location(nom.locationId);
          return (
            <button
              key={nom.id}
              className={`${styles['nomination-button']} ${styles[nom.fbStatus]} ${
                activeNominationId === nom.id ? styles['active'] : ''
              }`}
              onClick={() => {
                chooseNomination(nom.id);
              }}
            >
              {/* <div className={styles['eta-wrapper']}>{<CountDown date={new Date(nom.bst)} />}</div> */}
              <div className={styles['right']}>
                <div className={styles['header']}>{nom.attributes?.receivingShipName}</div>
                <div className={styles['description']}>
                  <Moment date={nom.bst} format="DD MMM YYYY" />, {locationName}
                  {' -' + nom.numberOfEvents + ' upcoming events'}
                </div>
              </div>
            </button>
          );
        } else return null;
      })}
    </div>
  );
};

export default RecurringNominationList;
