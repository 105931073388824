import React from 'react';
import { ClipLoader } from 'react-spinners';
import styles from './Loading.module.scss';

interface OwnProps {
  type?: string;
  small?: boolean;
  className?: string;
}

export const Loading: React.FC<OwnProps> = React.memo(({ type, small, className }) => (
  <div className={`${small ? styles['loading-small'] : styles['loading']} ${className}`}>
    <div className={styles['loading__icon']}>
      <ClipLoader color={'var(--sidebar-color)'} />
    </div>
    Loading{type && ` ${type}`}...
  </div>
));
