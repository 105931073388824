import React from 'react';
import { ErrorActionPayload } from '../../models/ErrorAction';
import { PageNotFoundError } from './components/PageNotFoundError';
import { ClientError } from './components/ClientError';
import { ServerError } from './components/ServerError';
import { AuthenticationError } from './components/AuthenticationError/index';
import { ForbiddenError } from './components/ForbiddenError/index';

interface OwnProps {
  error: ErrorActionPayload;
}

export const Error: React.FC<OwnProps> = React.memo(({ error }) => {
  if (error.statusCode === 404) {
    return <PageNotFoundError error={error} />;
  } else if (error.statusCode === 401) {
    return <AuthenticationError error={error} />;
  } else if (error.statusCode === 403) {
    return <ForbiddenError error={error} />;
  } else if (error.clientFault) {
    return <ClientError error={error} />;
  } else if (error.serverFault) {
    return <ServerError error={error} />;
  }
  return <div>error {error.statusCode}</div>;
});
