import styled from 'styled-components';

export default styled.ul`
  flex: 1;
  padding: 0;
  margin-right: 2em;
  li {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
  }

  li input[type='radio'] {
    position: absolute;
    top: 20px;
    left: 9px;
    z-index: -1000;
  }

  li label {
    display: block;
    position: relative;
    padding: 20px 20px 20px 40px;
    height: 100%;
    z-index: 9;
    cursor: pointer;
    transition: all 0.15s linear;
  }

  li .check {
    display: block;
    position: absolute;
    border: 5px solid #aaaaaa;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    top: 15px;
    z-index: 5;
    cursor: pointer;
    transition: border 0.15s linear;
  }

  li:hover .check {
    border: 5px solid #99d6f0;
  }

  li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 14px;
    width: 14px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.15s linear;
  }

  input[type='radio']:checked ~ .check {
    border: ${({ theme: { colors } }) => `5px solid ${colors.cyanLight}`};
  }

  input[type='radio']:checked ~ .check::before {
    background: ${({ theme: { colors } }) => colors.cyanLight};
  }
`;
