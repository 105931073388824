import * as TYPE from './types';
import { ApplicationState } from '..';
export const BIcompanyFleetReducer = (
  state: ApplicationState['bicompanyfleet'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.BIcompanyFleetActions,
): ApplicationState['bicompanyfleet'] => {
  switch (action.type) {
    case TYPE.BI_COMPANY_FLEET_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_BI_COMPANY_FLEET:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.BI_COMPANY_FLEET_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_BI_COMPANY_FLEET:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'Vessel succesfully added!',
          type: 'success',
        },
      };
    case TYPE.ADD_BI_COMPANY_FLEET_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content:
            'Something went wrong! We could not add the vessel. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.DELETE_BI_COMPANY_FLEET:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Vessel successfully removed.',
          type: 'info',
        },
      };
    case TYPE.DELETE_BI_COMPANY_FLEET_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        toastData: {
          content: "Sorry, can't delete vessel.",
          type: 'error',
        },
      };
    case TYPE.BI_COMPANY_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.EDIT_BI_COMPANY_FLEET:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
  }
  return { ...state };
};
