export interface DeliveryMode {
  id: string;
  mode: DeliveryModes;
}
export enum DeliveryModes {
  Ship = 'SHIP',
  Truck = 'TRUCK',
  Container = 'CONTAINER',
  Pipe = 'PIPE',
}
