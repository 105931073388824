import React, { useCallback } from 'react';
import styles from './VesselCard.module.scss';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import DeleteById from '../../../../../../../../components/DeleteById/DeleteById';
import { MyFleetVessel } from '../../../../../../../../store/MyFleets/models';
import classnames from 'classnames';
import Eye from '../Eye/Eye';
import { useDispatch } from 'react-redux';
import { setFleetItemVisible } from '../../../../../../../../store/MyFleets/actions';
const deleteIcon = findIconDefinition({ prefix: 'fal', iconName: 'times' });

interface OwnProps {
  deleteVessel: (id: string) => void;
  myFleetItem: MyFleetVessel;
  edit: boolean;
}

const VesselCard: React.FC<OwnProps> = ({ edit, myFleetItem, deleteVessel }) => {
  const dispatch = useDispatch();

  const setItemVisibilityCallback = useCallback(
    (fleetItemId: string) => dispatch(setFleetItemVisible(fleetItemId)),
    [],
  );

  const toggleVisible = () => {
    setItemVisibilityCallback(myFleetItem.id);
  };

  return (
    <div
      role="button"
      className={classnames(styles['grid'], myFleetItem.visible ? '' : styles['non-visible'])}
      onKeyDown={undefined}
      onClick={() => toggleVisible()}
    >
      <ul className={styles['text-content']}>
        <li>
          <b> {myFleetItem.vessel.vesselName} </b>
        </li>
        <li>
          {myFleetItem.vessel.imo} - {myFleetItem.vessel.shipType1}
        </li>
      </ul>
      <div className={styles['buttonCol']}>
        {edit ? (
          <DeleteById
            className={styles['delButton']}
            id={myFleetItem.id}
            deleteMethod={deleteVessel}
            header="Delete vessel"
            delBtnIcon={deleteIcon}
          />
        ) : (
          <Eye visible={myFleetItem.visible} />
        )}
      </div>
    </div>
  );
};

export default React.memo(VesselCard);
