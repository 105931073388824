import React from 'react';
import { Formik } from 'formik';
import ContractForm from '../ContractForm/ContractForm';
import contractsFormValidation from '../contractsFormValidation';
import { initialValues } from '../contractsFormInitialValues';

import { Contract } from '../../../../../store/Contract/models';
import { ICompany } from '@teqplay/chorus-components';
import { Company } from '../../../../../store/Company/models';

interface OwnProps {
  addContract: (contract: Contract) => void;
  systemAdmin?: boolean;
  header: string;
  contract?: Contract;
  customers: ICompany[];
  suppliers: ICompany[];
  company?: Company;
  navAdminType: string;
}

const FormikWrapper: React.FC<OwnProps> = ({
  addContract,
  header,
  contract,
  systemAdmin,
  customers,
  suppliers,
  company,
  navAdminType,
}) => {
  const submitContract = (contract: Contract) => {
    addContract(contract);
  };

  return (
    <Formik
      initialValues={contract ? contract : initialValues}
      validationSchema={contractsFormValidation}
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <ContractForm
          systemAdmin={systemAdmin}
          header={header}
          saveMethod={submitContract}
          contract={contract}
          customers={customers}
          suppliers={suppliers}
          company={company}
          formikProps={formikProps}
          navAdminType={navAdminType}
        />
      )}
    </Formik>
  );
};

export default React.memo(FormikWrapper);
