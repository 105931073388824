import * as React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { LowCarbonFuels } from '../../../../../../models/LowCarbonFuel';
import LowCarbonOption from './LowCarbonOption/LowCarbonOption';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './LowCarbonOptionsButtons.module.scss';
import classnames from 'classnames';
import { ReactComponent as TruckIcon } from '../../../../../../svgs/truck-to-ship.svg';
import { ReactComponent as PipeIcon } from '../../../../../../svgs/pipe-to-ship-blue.svg';
import { ReactComponent as ShipIcon } from '../../../../../../svgs/ship-to-ship.svg';
import { ReactComponent as FuelIcon } from '../../../../../../svgs/icon-LBG.svg';
import { ReactComponent as ContainerIcon } from '../../../../../../svgs/container-to-ship.svg';
import { pipeline } from 'stream';

interface OwnProps {
  toggle: (fuel: LowCarbonFuels) => void;
  initialLowCarbonFuels: string[];
  //addAsset: (event: any, deliveryMode: DeliveryModes) => void;
}

const LowCarbonOptionsButtons: React.FC<OwnProps> = ({ toggle, initialLowCarbonFuels }) => {
  const [LBG, setLBG] = useState(initialLowCarbonFuels.includes(LowCarbonFuels.LBG));
  const [LSM, setLSM] = useState(initialLowCarbonFuels.includes(LowCarbonFuels.LSM));

  return (
    <div className={styles['low-carbon-option-buttons']}>
      <LowCarbonOption initToggled={LBG} onClick={() => toggle(LowCarbonFuels.LBG)}>
        <div className={classnames(styles.icondiv)}>
          <FuelIcon className={classnames(styles.icon, styles.LBG)} />
        </div>
        <div className={classnames(styles.text)}>LBG</div>
        <div className={classnames(styles.desc)}>Liquid Bio Gas</div>
      </LowCarbonOption>
      {/* <LowCarbonOption initToggled={LSM} onClick={() => toggle(LowCarbonFuels.LSM)}>
        <div className={classnames(styles.icondiv)}>
          <FuelIcon className={classnames(styles.icon, styles.LSM)} />
        </div>

        <div className={classnames(styles.text)}>LSM</div>
        <div className={classnames(styles.desc)}>Liquefied Synthetic Methane</div>
      </LowCarbonOption> */}
    </div>
  );
};

export default LowCarbonOptionsButtons;
