import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import * as TYPE from './types';
import { api } from '../../helpers/api';
import { CompanyLogoResponseModel } from './models';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating company logo...</ModalResponse>;
const updateSuccessModal: ReactElement = (
  <ModalResponse status="success">Company logo updated successfully</ModalResponse>
);

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating company logo...</ModalResponse>;
const createSuccessModal: ReactElement = (
  <ModalResponse status="success">Company logo created successfully</ModalResponse>
);

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

const errorAddLogo = <ModalResponse status="error">There has been an error updating the company logo</ModalResponse>;

export const getCompanyLogo = (companyId: string) => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_LOGO_LOADING,
  });

  new api<CompanyLogoResponseModel>({
    dispatch,
    errorType: TYPE.COMPANY_LOGO_FETCH_ERROR,
  })
    .get(`/api/CompanyModels/getCompanyLogo/${companyId}`)
    .then((response) => {
      dispatch({
        type: TYPE.GET_COMPANY_LOGO,
        payload: response,
      });
    });
};

export const deleteCompanyLogo = (companyId: string) => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_LOGO_LOADING,
  });

  const url = `/api/CompanyModels/deleteCompanyLogo/${companyId}`;

  new api<CompanyLogoResponseModel>({
    dispatch,
    errorType: TYPE.DELETE_COMPANY_LOGO_ERROR,
  })
    .delete(url, companyId)
    .then((response) => {
      dispatch({
        type: TYPE.DELETE_COMPANY_LOGO,
        payload: response,
      });
    });
};

export const addCompanyLogo = (companyId: string, logo: string, mimeType: string, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.CompanyActions>,
) => {
  dispatch({
    type: TYPE.COMPANY_LOGO_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/CompanyModels/addCompanyLogo/${companyId}`;
  const fbLogo = {
    file: logo,
    mimeType: mimeType,
  };

  new api<CompanyLogoResponseModel>({
    dispatch,
    errorType: TYPE.ADD_COMPANY_LOGO_ERROR,
  })
    .put(url, fbLogo)
    .then((response) => {
      dispatch({
        type: TYPE.ADD_COMPANY_LOGO,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorAddLogo });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 500);
    });
};
