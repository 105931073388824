import { Company } from '../../../../store/Company/models';
import { Category } from '../../../../store/Category/models';

const initialCompanyFormCategory: Category = {
  id: '',
  categoryName: '',
  chorusType: '',
};

export const initialValues: Company = {
  name: '',
  category: initialCompanyFormCategory,
  country: '',
  address: '',
  orgNumber: '',
  areaOfInterest: '',
  companyInBrief: '',
  active: true,
  hidden: false,
  invoiceAddress: '',
  invoiceEmailAddress: '',
};
