import React, { useCallback, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Company } from '../../../../../store/Company/models';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { Form, FormikProps } from 'formik';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  renderCheckbox,
  renderComplexDropdown,
  renderSimpleDropdown,
  renderTextAreaInput,
  renderTextInput,
  renderLabel,
} from '../../../../Form/FormInputs';
import { getCategories } from '../../../../../store/Category/actions';
import { countries } from 'countries-list';
import { FormHeader } from '../../../../Form/FormHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import editButtonStyles from '../../../../../components/EditById/EditById.module.scss';
import integrationAssetApiTrackerconfigs from './integrationAssetApiTrackerConfigs';
import { IntegrationAssetApiTracker } from '../../../../../store/IntegrationAssetApiTracker/models';
import { Loading } from '../../../../Loading';
import { getIntegrationAssets } from '../../../../../store/IntegrationAsset/actions';
import { getIntegrationAssetTopic } from '../../../../../store/IntegrationAssetTopic/actions';
import { IntegrationAssetTopic } from '../../../../../store/IntegrationAssetTopic/models';
import { IntegrationAsset } from '../../../../../store/IntegrationAsset/models';
import { getPipeline } from '../../../../../store/Pipelines/actions';
import { getFleetVessels } from '../../../../../store/CompanyFleet/actions';
import { checkSupplier } from '../../../../../helpers/roleChecks';
import { fbAssetTypeOptions } from '../../../../IntegrationTypes/IntegrationTypes';

const cross = findIconDefinition({ prefix: 'fal', iconName: 'times' });
const save = findIconDefinition({ prefix: 'fal', iconName: 'save' });
const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });
const PlusCircle = findIconDefinition({ prefix: 'fal', iconName: 'plus-circle' });

const StyledInnerForm = styled(Form)`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: flex;
  flex-wrap: wrap;
`;

const StyledForm = styled.div<{ fullWidth: boolean }>`
  .formHeaderWrapper {
    display: flex;
    align-items: center;
    padding: 0.5em 0;
    margin-bottom: 1em;
    border-bottom: 1px solid #ccc;
    .iconWrapper {
      padding: 5px 11px;
      border-radius: 5px;
      background-color: rgb(204, 236, 248);
      margin: 0 10px 0 0;
      transition: background-color 300ms ease-in-out;
      width: 40px;
      height: 35px;
      &:hover {
        background-color: rgb(153, 217, 240);
      }
    }
  }
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
  }
  .buttonContainer button {
    justify-self: flex-end;
    margin-left: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    width: 399px;
    flex: 0 35%;
    margin-right: 5em;
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
`;

const StyledIconsContainer = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-bottom: 0px;
  position: relative;
  right: -18px;
  top: -40px;
  button {
    width: 43px;
    height: 39px;
    padding: 0.1rem;
  }
  .btn-primary > .svg-inline--fa.fa-w-14 {
    width: 0.75em;
  }
  .btn-secondary > .svg-inline--fa.fa-w-10 {
    width: 0.55em;
    color: #24365c;
  }
`;

const StyledCollumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledAssetInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  margin-bottom: 1em;
  font-size: 16px;
  padding: 10px 15px;
`;

interface OwnProps {
  saveMethod: (
    integrationAssetTopic: IntegrationAssetTopic,
    integrationAsset: IntegrationAsset,
    fbAssetType: string,
    fbAssetId: string,
  ) => void;
  integrationAssetApiTracker?: IntegrationAssetApiTracker;
  header: string;
  systemAdmin?: boolean;
  navAdminType: string;
  formikProps: FormikProps<IntegrationAssetApiTracker>;
  companyId: string | undefined;
}

const IntegrationAssetApiTrackerForm: React.FC<OwnProps> = ({
  integrationAssetApiTracker,
  header,
  saveMethod,
  systemAdmin = false,
  formikProps,
  navAdminType,
  companyId,
}) => {
  const dispatch = useDispatch();
  const {
    integrationAssetTopicLoading,
    integrationAssetTopicError,
    integrationAssetTopicErrorDetails,
    integrationAssetTopics,
    integrationAssetTopicsIsLoaded,
    toastData,

    integrationAssetsLoading,
    integrationAssetsDetailsError,
    integrationAssetsErrorDetails,
    integrationAssets,
    integrationAssetsIsLoaded,
    toastDataIntegrationAsset,

    auth,
  } = useSelector((state: ApplicationState) => ({
    integrationAssetTopicLoading: state.integration_assets_topic.loading,
    integrationAssetTopicError: state.integration_assets_topic.error,
    integrationAssetTopicErrorDetails: state.integration_assets_topic.errorDetails,
    integrationAssetTopics: state.integration_assets_topic.data,
    integrationAssetTopicsIsLoaded: state.integration_assets_topic.dataIsLoaded,
    toastData: state.integration_assets_topic.toastData,

    integrationAssetsLoading: state.integration_assets.loading,
    integrationAssetsDetailsError: state.integration_assets.error,
    integrationAssetsErrorDetails: state.integration_assets.errorDetails,
    integrationAssets: state.integration_assets.data.filter((x) => x.ownerCompanyId == companyId),
    integrationAssetsIsLoaded: state.integration_assets.dataIsLoaded,
    toastDataIntegrationAsset: state.contracts.toastData,
    auth: state.auth.data,
  }));

  const {
    companyFleetLoading,
    companyFleetError,
    companyFleetErrorDetails,
    companyFleet,
    companyFleetIsLoaded,
    companyPipelineLoading,
    companyPipelineError,
    companyPipelineErrorDetails,
    companyPipelines,
    companyPipelineIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    companyFleetLoading: state.companyFleet.loading,
    companyFleetError: state.companyFleet.error,
    companyFleetErrorDetails: state.companyFleet.errorDetails,
    companyFleet: state.companyFleet.data,
    companyFleetIsLoaded: state.companyFleet.dataIsLoaded,
    companyPipelineLoading: state.pipelines.loading,
    companyPipelineError: state.pipelines.error,
    companyPipelineErrorDetails: state.pipelines.errorDetails,
    companyPipelines: state.pipelines.data,
    companyPipelineIsLoaded: state.pipelines.dataIsLoaded,
  }));

  const history = useHistory();

  useEffect(() => {
    formikProps.validateForm();
    return () => {
      if (!integrationAssetApiTracker) {
        formikProps.resetForm();
      }
    };
  }, []);

  useEffect(() => {
    if (!integrationAssetsIsLoaded) {
      getCompanyIntegrationAssetsCallback();
    }
    if (!companyFleetIsLoaded) {
      getCompanyFleetCallback();
    }
    if (!companyPipelineIsLoaded && checkSupplier(auth)) {
      getCompanyPipelineCallback();
    }
  }, []);

  const getCompanyIntegrationAssetsCallback = useCallback(() => dispatch(getIntegrationAssets()), []);

  const getIntegrationAssetTopicCallback = React.useCallback((assetId: string | undefined) => {
    dispatch(getIntegrationAssetTopic(assetId));
  }, []);

  const getCompanyPipelineCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyPipelineIsLoaded) {
      dispatch(getPipeline(auth.company.companyId));
    }
  }, []);

  const getCompanyFleetCallback = React.useCallback(() => {
    if (auth.company && auth.company.companyId && !companyFleetIsLoaded) {
      dispatch(getFleetVessels(auth.company.companyId));
    }
  }, []);

  const onFormSave = () => {
    formikProps.submitForm();
    /* const integrationAssetToSave: IntegrationAssetApiTracker = {
      ...formikProps.values,
    };
    if (integrationAssetApiTracker) {
      integrationAssetToSave.id = integrationAssetApiTracker.id;
    } */
    const integrationAsset = formikProps.values.integrationAsset;
    const integrationAssetTopic = formikProps.values.integrationAssetTopic;
    const fbAssetType = formikProps.values.fbAssetType;
    const fbAssetId = formikProps.values.fbAssetId;

    if (formikProps.isValid && integrationAssetTopic && integrationAsset) {
      saveMethod(integrationAssetTopic, integrationAsset, fbAssetType, fbAssetId);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetApiTrackerconfigs, errors, touched, setFieldValue, setFieldTouched };


  useEffect(() => {
    if (formikProps.values.integrationAsset) {
      const assetId = formikProps.values.integrationAsset.id;
      const integrationAssetType = formikProps.values.integrationAsset.assetType;
      getIntegrationAssetTopicCallback(assetId);

      formikProps.setFieldValue('assetName', formikProps.values.integrationAsset.assetName);
      formikProps.setFieldValue('assetExternalName', formikProps.values.integrationAsset.assetExternalName);
      formikProps.setFieldValue('assetDescription', formikProps.values.integrationAsset.assetDescription);
      formikProps.setFieldValue('assetLocation', formikProps.values.integrationAsset.assetLocation);
      formikProps.setFieldValue('assetType', formikProps.values.integrationAsset.assetType);
      formikProps.setFieldValue('veracityAssetId', formikProps.values.integrationAsset.veracityAssetId);

      //set fbasset type from integration asset type
      if (integrationAssetType == 'Terminal') {
        const fbasset = fbAssetTypeOptions.find((x) => x.name == 'Pipeline')?.name;
        formikProps.setFieldValue('fbAssetType', fbasset);
      }
      else if (integrationAssetType == 'Bunker Vessel' || integrationAssetType == 'Generic Vessel') {
        const fbasset = fbAssetTypeOptions.find((x) => x.name == 'Bunkership')?.name;
        formikProps.setFieldValue('fbAssetType', fbasset);
      }
      else if (integrationAssetType == 'Receiving Vessel') {
        const fbasset = fbAssetTypeOptions.find((x) => x.name == 'Receivingship')?.name;
        formikProps.setFieldValue('fbAssetType', fbasset);
      }
      else if (integrationAssetType == 'Truck') {
        const fbasset = fbAssetTypeOptions.find((x) => x.name == 'Truck')?.name;
        formikProps.setFieldValue('fbAssetType', fbasset);
      }
      else if (integrationAssetType == 'Container') {
        const fbasset = fbAssetTypeOptions.find((x) => x.name == 'Container')?.name;
        formikProps.setFieldValue('fbAssetType', fbasset);
      }
      else if (integrationAssetType == 'Outbound') {
        const fbasset = fbAssetTypeOptions.find((x) => x.name == 'Outbound')?.name;
        formikProps.setFieldValue('fbAssetType', fbasset);
      }

    } else {
      formikProps.setFieldValue('assetName', '');
      formikProps.setFieldValue('assetExternalName', '');
      formikProps.setFieldValue('assetDescription', '');
      formikProps.setFieldValue('assetLocation', '');
      formikProps.setFieldValue('assetType', '');
      formikProps.setFieldValue('veracityAssetId', '');
    }
  }, [formikProps.values.integrationAsset]);

  useEffect(() => {
    if (formikProps.values.fbAssetPipeline) {
      const fbAssetId = formikProps.values.fbAssetPipeline.chorusId;
      formikProps.setFieldValue('fbAssetId', fbAssetId);
    }
  }, [formikProps.values.fbAssetPipeline]);

  useEffect(() => {
    if (formikProps.values.fbAssetBunkerShip) {
      const fbAssetId = formikProps.values.fbAssetBunkerShip.chorusId;
      formikProps.setFieldValue('fbAssetId', fbAssetId);
    }
  }, [formikProps.values.fbAssetBunkerShip]);

  useEffect(() => {
    if (formikProps.values.fbAssetReceivingship) {
      const fbAssetId = formikProps.values.fbAssetReceivingship.chorusId;
      formikProps.setFieldValue('fbAssetId', fbAssetId);
    }
  }, [formikProps.values.fbAssetReceivingship]);

  useEffect(() => {
    formikProps.setFieldValue('fbAssetId', '');
  }, [formikProps.values.fbAssetType]);

  const [disableBunkerShipSelect, setDisableBunkerShipSelect] = React.useState(false);
  const [disableReceivingShipSelect, setDisableReceivingShipSelect] = React.useState(false);


  useEffect(() => {
    //
    if (formikProps.values.fbAssetType == 'Bunkership') {
      //if bunkership auto select bunker ship based on imo
      const veracityAssetId = formikProps.values.veracityAssetId;
      const re = /(\d{7})/g; //  /\d{7}+/g; 
      //extract imo from asset id
      const imo = re.exec(veracityAssetId);
      if (imo) {
        const selectedImo = Number(imo[0]);
        const selectedVessel = companyFleet.find(x => x.vessel.imo == selectedImo);
        if (selectedVessel) {
          formikProps.setFieldValue('fbAssetBunkerShip', selectedVessel);
          setDisableBunkerShipSelect(true);
        } else {
          formikProps.setFieldValue('fbAssetBunkerShip', undefined);
          setDisableBunkerShipSelect(false);
        }
      }
      else {
        formikProps.setFieldValue('fbAssetBunkerShip', undefined);
        setDisableBunkerShipSelect(false);
      }
    } else if (formikProps.values.fbAssetType == 'Receivingship') {
      const veracityAssetId = formikProps.values.veracityAssetId;
      const re = /(\d{7})/g;
      //extract imo from asset id
      const imo = re.exec(veracityAssetId);
      if (imo) {
        const selectedImo = Number(imo[0]);
        const selectedVessel = companyFleet.find(x => x.vessel.imo == selectedImo);
        if (selectedVessel) {
          formikProps.setFieldValue('fbAssetReceivingship', selectedVessel);
          setDisableReceivingShipSelect(true);
        } else {
          formikProps.setFieldValue('fbAssetReceivingship', undefined);
          setDisableReceivingShipSelect(false);
        }
      }
      else {
        formikProps.setFieldValue('fbAssetReceivingship', undefined);
        setDisableReceivingShipSelect(false);
      }
    } else if (formikProps.values.fbAssetType == 'Pipeline') {

    }
  }, [formikProps.values.veracityAssetId]);

  const isIntegrationAssetPage = navAdminType === 'company';
  const FormWrapper = isIntegrationAssetPage ? Form : StyledInnerForm;

  const onCancelButtonClick = () => {
    history.push(`/companyadmin/companyintegrations/inboundconnection`);
  };

  if (integrationAssetsLoading) {
    return <Loading />;
  } else
    return (
      <StyledForm {...{ fullWidth: isIntegrationAssetPage }}>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save data connection"
          cancelButtonText="Cancel"
          formUrl="companyintegrations/inboundconnection"
          onCancelButtonClick={onCancelButtonClick}
        />
        <FormWrapper>
          <StyledRowWrapper>
            <StyledCollumnWrapper>
              {renderComplexDropdown(
                'integrationAsset',
                integrationAssets,
                integrationAssetsLoading,
                'assetName',
                props,
              )}
              {formikProps.values.integrationAsset && <StyledAssetInfoWrapper>
                <div><b>Name:</b> {
                  //@ts-ignore
                  formikProps.values.assetName}</div>
                <div><b>External Name:</b> {
                  //@ts-ignore
                  formikProps.values.assetExternalName}</div>
                <div><b>Description:</b> {
                  //@ts-ignore
                  formikProps.values.assetDescription}</div>
                <div><b>Location:</b> {
                  //@ts-ignore
                  formikProps.values.assetLocation}</div>
                <div><b>Integration Asset Type:</b> {formikProps.values.assetType}</div>
                <div><b>FuelBoss Asset Type:</b> {formikProps.values.fbAssetType}</div>
                <div><b>Veracity AssetId:</b> {formikProps.values.veracityAssetId}</div>
              </StyledAssetInfoWrapper>}

              {/* {formikProps.values.integrationAsset && renderTextInput('assetName', true, props)}
              {formikProps.values.integrationAsset && renderTextInput('assetExternalName', true, props)}
              {formikProps.values.integrationAsset && renderTextInput('assetDescription', true, props)}
              {formikProps.values.integrationAsset && renderTextInput('assetLocation', true, props)}
              {formikProps.values.integrationAsset && renderTextInput('assetType', true, props)}
              {formikProps.values.integrationAsset && renderTextInput('veracityAssetId', true, props)} */}
              {renderComplexDropdown(
                'integrationAssetTopic',
                integrationAssetTopics,
                formikProps.values.integrationAsset == undefined,
                'topic',
                props,
              )}
              {/* {renderSimpleDropdown('fbAssetType', fbAssetTypeOptions, formikProps.values.integrationAsset == undefined, 'name', props)} */}
              {formikProps.values.fbAssetType == 'Bunkership' &&
                renderComplexDropdown(
                  'fbAssetBunkerShip',
                  companyFleet.map((item) => {
                    //@ts-ignore
                    item.vesselName = item.vessel.vesselName;
                    return item;
                  }),
                  disableBunkerShipSelect,
                  'vesselName',
                  props,
                )}
              {formikProps.values.fbAssetType == 'Receivingship' &&
                renderComplexDropdown(
                  'fbAssetReceivingship',
                  companyFleet.map((item) => {
                    //@ts-ignore
                    item.vesselName = item.vessel.vesselName;
                    return item;
                  }),
                  disableReceivingShipSelect,
                  'vesselName',
                  props,
                )}
              {formikProps.values.fbAssetType == 'Pipeline' &&
                renderComplexDropdown('fbAssetPipeline', companyPipelines, false, 'name', props)}

            </StyledCollumnWrapper>
          </StyledRowWrapper>
        </FormWrapper>
      </StyledForm>
    );
};

export default React.memo(IntegrationAssetApiTrackerForm);
