import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { initialValues } from '../userFormInitialValues';
import userConfigs from '../UserForm/userConfigs';

import UserForm from '../UserForm/UserForm';
import { Company } from '../../../../../store/Company/models';
import { User } from '../../../../../store/Users/models';
import { Role } from '../../../../../store/Roles/models';

interface OwnProps {
  addUser: (user: User, roles: Role[]) => void;
  companyAdmin?: boolean;
  header: string;
  user?: User;
  company?: Company;
  navAdminType: string;
  isInaddUser: boolean;
}

const validationMessage = { message: 'Not valid', excludeEmptyString: true };

const FormikWrapper: React.FC<OwnProps> = ({ addUser, header, user, companyAdmin, navAdminType, company,isInaddUser }) => {

  const [isRoleCaptain, setIsRoleCaptain] = useState(false);
  const [isRoleTerminalOperator, setIsRoleTerminalOperator] = useState(false);
  const [isInAddMember, setIsInAddMember] = useState(false);

  const submitUser = (user: User, roles: Role[]) => {
    addUser(user, roles);
  };
  React.useEffect(() => {
    setIsInAddMember(isInaddUser)
    
  }, [isInaddUser]);


  const fleetVesselValidationSchema = Yup.object().shape({
    id: Yup.string().required('Vessel required'),
  });

  const fleetPipelineValidationSchema = Yup.object().shape({
    id: Yup.string().required('Terminal required'),
  });

  const rolesValidationSchema = Yup.object().shape({
    id: Yup.string().required('Role required'),
    roleType: Yup.string().optional(),
    chorusRole: Yup.string().optional(),
  });

  const companyValidationSchema = Yup.object().shape({
    companyId: Yup.string().required('Company required'),
    roleType: Yup.string().optional(),
    chorusRole: Yup.string().optional(),
  });

  const validationShape = {
    firstName: Yup.string().matches(userConfigs.firstName.regex, validationMessage).required('First name required'),
    lastName: Yup.string().matches(userConfigs.lastName.regex, validationMessage).required('Last name required'),
    email: Yup.string().matches(userConfigs.email.regex, validationMessage).required('Email required'),
    company: companyValidationSchema.required('Company required'),
    role: rolesValidationSchema.required('Role required'),
  };

  if (isRoleCaptain) {
    // @ts-ignore
    validationShape.fleetVessel = fleetVesselValidationSchema.required('Vessel required').nullable();
  }

  if (isRoleTerminalOperator) {
    // @ts-ignore
    validationShape.fleetPipeline = fleetPipelineValidationSchema.required('Terminal required').nullable();
  }

  const userFormValidation = Yup.object().shape(validationShape);

  return (
    <Formik
      initialValues={user ? user : initialValues}
      validationSchema={userFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <UserForm
          addTeamMember={isInAddMember}
          user={user}
          header={header}
          saveMethod={submitUser}
          systemAdminPage={true}
          companyAdminForm={companyAdmin}
          company={company}
          formikProps={formikProps}
          navAdminType={navAdminType}
          setIsRoleCaptain={setIsRoleCaptain}
          setIsRoleTerminalOperator={setIsRoleTerminalOperator}
        />
      )}
    </Formik>
  );
};

export default React.memo(FormikWrapper);
