import React, { Component } from 'react';
import classnames from 'classnames';
import StyledBasicErrorMessage from './StyledBasicErrorMessage';

class CheckboxGroup extends Component {
  handleChange = (event) => {
    const target = event.currentTarget;
    const valueArray = [...this.props.value] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    // this is going to call setFieldValue and manually update values[id]
    this.props.onChange(this.props.id, valueArray);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched[id]
    this.props.onBlur(this.props.id, true);
  };

  render() {
    const { name, value, error, touched, className, children } = this.props;

    const classes = classnames(
      'input-field',
      {
        'is-success': value || (!error && touched),
        'is-error': !!error && touched,
      },
      className,
    );

    return (
      <div className={classes}>
        <fieldset>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              field: {
                name: child.props.name,
                value: value.includes(child.props.id),
                onChange: this.handleChange,
                onBlur: this.handleBlur,
              },
            }),
          )}
          {touched && <StyledBasicErrorMessage component="div" name={name} />}
        </fieldset>
      </div>
    );
  }
}

export default CheckboxGroup;
