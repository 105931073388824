import styled from 'styled-components';
const names = ['module', 'system'];

export default styled.div`
  display: block;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => (names.includes(props.name) || props.type === 'textarea' ? 'auto' : '49px')};
  margin-bottom: 1em;
  border: 1px solid #ccc;
  background-color: ${(props) => props.backgroundColor || 'inherit'};
  line-height: unset;
  padding: ${(props) => (props.type === 'text' || props.type === 'number' || props.type === 'textarea' ? '12px' : '0')};
  font-weight: 600;
  &:hover {
    border: 1px solid gray;
    transition: .3s all;
  }
  &:focus {
    border: 1px solid gray;
  }

  &:read-only {
    background-color: #eee;
  }

  &::placeholder {
    margin-left: 10px;
    font-size: 16px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(11, 24, 56, 0.54);
  }
`;
