import styled from 'styled-components';

export default styled.label`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  max-width: 100%;
  margin-bottom: 8px;
  font-size: 1em;
  //color: ${({ theme: { colors } }) => colors.neutral80};
  color: #000;
  line-height: 22px;
  svg {
    margin-right: 0.5em;
    color: #4a90e2;
    width: 25px;
    height: 25px;
  }
  .unit {
    color: #999999;
    font-size: 16px;
    margin-left: 0.2em;
  }
  .required {
    color: #c4262e;
  }
`;
