import { IntegrationAssetTypeWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';

export const INTEGRATION_ASSET_TYPE_CLEAR_TOAST = 'integrationAssetType/ClearToast';
export type INTEGRATION_ASSET_TYPE_CLEAR_TOAST = typeof INTEGRATION_ASSET_TYPE_CLEAR_TOAST;

export const INTEGRATION_ASSET_TYPE_LOADING = 'integrationAssetType/loading';
export type INTEGRATION_ASSET_TYPE_LOADING = typeof INTEGRATION_ASSET_TYPE_LOADING;

export const GET_INTEGRATION_ASSET_TYPE = 'integrationAssetType/get';
export type GET_INTEGRATION_ASSET_TYPE = typeof GET_INTEGRATION_ASSET_TYPE;

export const INTEGRATION_ASSET_TYPE_FETCH_ERROR = 'integrationAssetType/fetchError';
export type INTEGRATION_ASSET_TYPE_FETCH_ERROR = typeof INTEGRATION_ASSET_TYPE_FETCH_ERROR;

export const ADD_INTEGRATION_ASSET_TYPE = 'integrationAssetType/add';
export type ADD_INTEGRATION_ASSET_TYPE = typeof ADD_INTEGRATION_ASSET_TYPE;

export const EDIT_INTEGRATION_ASSET_TYPE = 'integrationAssetType/edit';
export type EDIT_INTEGRATION_ASSET_TYPE = typeof EDIT_INTEGRATION_ASSET_TYPE;

export const DELETE_INTEGRATION_ASSET_TYPE = 'integrationAssetType/delete';
export type DELETE_INTEGRATION_ASSET_TYPE = typeof DELETE_INTEGRATION_ASSET_TYPE;

export const INTEGRATION_ASSET_TYPE_ADD_ERROR = 'integrationAssetType/addError';
export type INTEGRATION_ASSET_TYPE_ADD_ERROR = typeof INTEGRATION_ASSET_TYPE_ADD_ERROR;

export const INTEGRATION_ASSET_TYPE_EDIT_ERROR = 'integrationAssetType/editError';
export type INTEGRATION_ASSET_TYPE_EDIT_ERROR = typeof INTEGRATION_ASSET_TYPE_EDIT_ERROR;

export const INTEGRATION_ASSET_TYPE_DELETE_ERROR = 'integrationAssetType/deleteError';
export type INTEGRATION_ASSET_TYPE_DELETE_ERROR = typeof INTEGRATION_ASSET_TYPE_DELETE_ERROR;

export type IntegrationAssetTypeActions =
  | {
      type: INTEGRATION_ASSET_TYPE_LOADING;
    }
  | {
      type: GET_INTEGRATION_ASSET_TYPE;
      payload: IntegrationAssetTypeWrapperModel['data'];
    }
  | {
      type: ADD_INTEGRATION_ASSET_TYPE;
      payload: IntegrationAssetTypeWrapperModel['data'];
    }
  | {
      type: EDIT_INTEGRATION_ASSET_TYPE;
      payload: IntegrationAssetTypeWrapperModel['data'];
    }
  | {
      type: DELETE_INTEGRATION_ASSET_TYPE;
      payload: IntegrationAssetTypeWrapperModel['data'];
    }
  | {
      type: INTEGRATION_ASSET_TYPE_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TYPE_ADD_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TYPE_EDIT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TYPE_DELETE_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: INTEGRATION_ASSET_TYPE_CLEAR_TOAST;
    };
