import { DeliveryModes } from '../../../../../models/DeliveryMode';
import {
  Completion,
  DocumentPart,
  DocumentStatusString,
  DocumentStatusValue,
  SafetyChecklistStatus,
  Signing,
} from '../../../../../store/Nomination/models';

export const isInProgress = (completion: Completion | undefined) => {
  if (!completion) {
    return false;
  }
  if (completion && completion.status == 'IN_PROGRESS') {
    return true;
  }
  return false;
};

export const isCompleted = (completion: Completion | undefined) => {
  if (!completion) {
    return false;
  }
  if (completion && completion.status == 'COMPLETED') {
    return true;
  }
  return false;
};

export const isSigned = (signing: Signing | undefined) => {
  if (!signing) {
    return false;
  }
  if (signing && signing.status == 'SIGNED') {
    return true;
  }
  return false;
};

export const getCompletion = (completion: Completion[], deliveryMode: DeliveryModes) => {
  if (deliveryMode == DeliveryModes.Ship) return completion.find((x) => x.actor == 'LBV');
  if (deliveryMode == DeliveryModes.Pipe || deliveryMode == DeliveryModes.Truck)
    return completion.find((x) => x.actor == 'TO');
  //todo add container completion later
  else if (deliveryMode == DeliveryModes.Container) return undefined;
  else return undefined;
};

export const getSigning = (signing: Signing[] | null, deliveryMode: DeliveryModes) => {
  if (signing) {
    if (deliveryMode == DeliveryModes.Ship) return signing.find((x) => x.actor == 'LBV');
    else if (deliveryMode == DeliveryModes.Pipe || deliveryMode == DeliveryModes.Truck)
      return signing.find((x) => x.actor == 'TO');
    //todo add container completion later
    else if (deliveryMode == DeliveryModes.Container) return undefined;
  } else {
    return undefined;
  }
};

export const ebdnSofDocumumentStatus = (
  documentStatus: DocumentStatusString,
  completion: Completion[],
  signing: Signing[] | null,
  deliveryMode: DeliveryModes,
) => {
  if (documentStatus === 'SIGNED') return 'COMPLETED & SIGNED';
  else if (documentStatus === 'NOT_STARTED') return 'NOT STARTED';
  else if (documentStatus === 'COMPLETED') return 'LBV TO INITIATE SIGNING';
  else if (documentStatus === 'IN_PROGRESS') {
    //look at the completion object depending on the delivery mode
    const LBVCompletion = getCompletion(completion, deliveryMode);
    //no RV completion checks as documents not visible to RV
    if (LBVCompletion) {
      if (LBVCompletion.status == 'COMPLETED') return 'LBV TO INITIATE SIGNING';
      return 'IN PROGRESS';
    } else {
      return 'IN PROGRESS';
    }
  }
  if (documentStatus === 'PENDING_SIGNATURES') {
    //look at the sigining object
    const LBVSigning = getSigning(signing, deliveryMode);
    const RVSigning = signing ? signing.find((x) => x.actor == 'RV') : undefined;
    if (!LBVSigning && !RVSigning) {
      return 'PENDING SIGNATURES';
    }
    //LBV has not signed and not RV
    else if (!isSigned(LBVSigning) && !isSigned(RVSigning)) {
      return 'PENDING SIGNATURES';
    }
    //LBV has signed but not RV
    else if (isSigned(LBVSigning) && !isSigned(RVSigning)) {
      return 'PENDING SIGNATURES RV';
    }
    //RV has signed but not LBV
    else if (isSigned(RVSigning) && !isSigned(LBVSigning)) {
      return 'PENDING SIGNATURES LBV';
    }
    //Both have signed
    else if (isSigned(RVSigning) && isSigned(LBVSigning)) {
      return 'COMPLTED & SIGNED';
    } else {
      return 'PENDING SIGNATURES';
    }
  }
};

export const checkListDocumumentStatus = (
  documentStatus: DocumentStatusString,
  completion: Completion[],
  signing: Signing[] | null,
  checkListType: SafetyChecklistStatus['type'],
  isSupplier: boolean,
  deliveryMode: DeliveryModes,
  checkListPart?: DocumentPart,
) => {
  if (documentStatus === 'SIGNED') return 'COMPLETED & SIGNED';
  else if (documentStatus === 'NOT_STARTED') return 'NOT STARTED';
  else if (documentStatus === 'COMPLETED' && checkListPart === 'A' && checkListType === 'IAPH_V2_A') return 'COMPLETED'; // IAPH V2 A
  else if (documentStatus === 'COMPLETED') return 'LBV TO INITIATE SIGNING';
  else if (documentStatus === 'IN_PROGRESS') {
    //look at the completion object
    const LBVCompletion = getCompletion(completion, deliveryMode);
    const RVCompletion = completion.find((x) => x.actor == 'RV');
    if (!LBVCompletion && !RVCompletion) {
      return 'NOT IN PROGRESS';
    }
    //LBV has started but not RV
    else if (
      LBVCompletion &&
      LBVCompletion.status == 'IN_PROGRESS' &&
      (RVCompletion == null || RVCompletion.status == 'NOT_STARTED')
    ) {
      return 'LBV IN PROGRESS';
    }
    //RV has started but not LBV
    else if (
      RVCompletion &&
      RVCompletion.status == 'IN_PROGRESS' &&
      (LBVCompletion == null || LBVCompletion.status == 'NOT_STARTED')
    ) {
      return 'RV IN PROGRESS';
    }
    // both have started but none completed
    else if (isInProgress(RVCompletion) && isInProgress(LBVCompletion)) {
      return 'BOTH IN PROGRESS';
    }
    //RV has completed but not LBV
    else if (!isCompleted(LBVCompletion) && isCompleted(RVCompletion)) {
      return 'PENDING LBV';
    }
    //LBV has completed but not RV
    else if (!isCompleted(RVCompletion) && isCompleted(LBVCompletion)) {
      return 'PENDING RV';
    }
    //Both completed
    else if (isCompleted(RVCompletion) && isCompleted(LBVCompletion)) {
      return 'LBV TO INITIATE SIGNING';
    } else {
      return 'IN PROGRESS';
    }
  }
  if (documentStatus === 'PENDING_SIGNATURES') {
    //look at the sigining object
    const LBVSigning = getSigning(signing, deliveryMode);
    const RVSigning = signing ? signing.find((x) => x.actor == 'RV') : undefined;
    const ExternalSigning = signing ? signing.find((x) => x.actor == 'EXTERNAL') : undefined;
    if (checkListType == 'IAPH') {
      // IAPH SIGNING
      if (!isSigned(RVSigning) && !isSigned(LBVSigning)) {
        return 'PENDING SIGNATURES';
      }
      //LBV has signed but not RV
      else if (isSigned(LBVSigning) && !isSigned(RVSigning)) {
        return 'PENDING SIGNATURES RV';
      }
      //RV has signed but not LBV
      else if (isSigned(RVSigning) && !isSigned(LBVSigning)) {
        return 'PENDING SIGNATURES LBV';
      } else if (isSigned(RVSigning) && isSigned(LBVSigning)) {
        return 'COMPLTED & SIGNED';
      } else {
        //handle case not defined??
        return 'PENDING SIGNATURES';
      }
    }
    if (
      checkListType == 'GOTHENBURG' ||
      checkListType == 'TR56' ||
      checkListType == 'IAPH_V2_A' ||
      checkListType == 'IAPH_V2_B'
    ) {
      // GOTHENBURG SIGNING
      //RV 0, EX 0, LBV 0
      if (!isSigned(RVSigning) && !isSigned(ExternalSigning) && !isSigned(LBVSigning)) {
        return 'PENDING SIGNATURES';
      }
      if (isSupplier) {
        //RV 1, EX 1, LBV 0
        if (isSigned(RVSigning) && isSigned(ExternalSigning) && !isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE LBV';
        }
        //RV 0, EX 1, LBV 1
        else if (!isSigned(RVSigning) && isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE COUNTERPART';
        }
        //RV 1, EX 0, LBV 1
        else if (isSigned(RVSigning) && !isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE COUNTERPART';
        }
        //RV 0, EX 0, LBV 1
        else if (!isSigned(RVSigning) && !isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE COUNTERPART';
        }
        //RV 1, EX 0, LBV 0
        else if (isSigned(RVSigning) && !isSigned(ExternalSigning) && !isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE LBV';
        }
        //RV 0, EX 1, LBV 0
        else if (!isSigned(RVSigning) && isSigned(ExternalSigning) && !isSigned(LBVSigning)) {
          return 'PENDING SIGNATURES';
        }
        //RV 1, EX 1, LBV 1
        else if (isSigned(RVSigning) && isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'COMPLTED & SIGNED';
        }
        //handle case not defined??
        else {
          return 'PENDING SIGNATURES';
        }
      }
      if (!isSupplier) {
        //not all cases covered????
        //RV 1, EX 1, LBV 0
        if (isSigned(RVSigning) && isSigned(ExternalSigning) && !isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE COUNTERPART';
        }
        //RV 0, EX 1, LBV 1
        else if (!isSigned(RVSigning) && isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE RV';
        }
        //RV 1, EX 0, LBV 1
        else if (isSigned(RVSigning) && !isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE COUNTERPART';
        }
        //RV 0, EX 0, LBV 1
        else if (!isSigned(RVSigning) && !isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE RV';
        }
        //RV 1, EX 0, LBV 0
        else if (isSigned(RVSigning) && !isSigned(ExternalSigning) && !isSigned(LBVSigning)) {
          return 'PENDING SIGNATURE COUNTERPART';
        }
        //RV 0, EX 1, LBV 0
        else if (!isSigned(RVSigning) && isSigned(ExternalSigning) && !isSigned(LBVSigning)) {
          return 'PENDING SIGNATURES';
        }
        //RV 1, EX 1, LBV 1
        else if (isSigned(RVSigning) && isSigned(ExternalSigning) && isSigned(LBVSigning)) {
          return 'COMPLTED & SIGNED';
        }
        //handle case not defined??
        else {
          return 'PENDING SIGNATURES';
        }
      }
    }
  }
};

export const isQuantityInt = (n: any) => {
  return Number(n) === n && n % 1 === 0;
};
