import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as FuelBossNominationSigned } from './img/Nomination_signed_Illustration.svg';
import { ReactComponent as FuelBossNominationNotSigned } from './img/Nomination_not_signed_Illustration.svg';
import { ReactComponent as FuelBossNominationExpired } from './img/Nomination_linkexpired.svg';

import { useEffect, useState } from 'react';
import { LoadingIndicator, SignFlowState, SignFlowType, useSignFlow } from '@teqplay/chorus-components';
import styles from './SignFlow.module.scss';
import logo from '../Layout/Components/NavMenu/FuelBoss_icon.png';
import { Button } from 'reactstrap';

interface OwnProps {
  signFlowType: SignFlowType;
  signFlowToken: string;
  chorusBaseURL: string;
  loggedIn: boolean;
}

export interface ISignFlowMessage {
  message: string;
}

export const SignFlow: React.FC<OwnProps> = ({ signFlowType, signFlowToken, chorusBaseURL, loggedIn }) => {
  const { state, url, error } = useSignFlow(signFlowToken, chorusBaseURL, signFlowType);

  const SignFlowMessage: React.FC<ISignFlowMessage> = (props) => {
    return (
      <div className="signflow-message-container">
        <div className="message">{props.message}</div>
        {props.children}
      </div>
    );
  };

  const getSignFlowGoStatus = () => {
    if (state === SignFlowState.LOADING) {
      return <LoadingIndicator loading={true} />;
    }
    if (state === SignFlowState.ERROR)
      return (
        <SignFlowMessage message="Something went wrong">{error && <p>a {error} error accured</p>}</SignFlowMessage>
      );
    if (state === SignFlowState.SIGNED) {
      return <SignFlowMessage message="Document is already signed" />;
    }
    if (state === SignFlowState.ALREADY_SIGNED) {
      return <SignFlowMessage message="Document is already signed" />;
    }
    if (state === SignFlowState.EXPIRED) {
      return <SignFlowMessage message="Document link has expired" />;
    }
    if (state === SignFlowState.REDIRECTING) {
      return (
        <SignFlowMessage message="Redirecting to the signing page">
          <Button className={styles['nomination-button']} onClick={() => window.location.replace(url!)}>
            Go to signing
          </Button>
        </SignFlowMessage>
      );
    } else return <SignFlowMessage message="Something went wrong" />;
  };

  const getSignFlowReturnStatus = () => {
    if (state === SignFlowState.LOADING) {
      return <LoadingIndicator loading={true} />;
    }
    if (state === SignFlowState.ERROR)
      return (
        <SignFlowMessage message="Something went wrong">{error && <p>a {error} error accured</p>}</SignFlowMessage>
      );
    if (state === SignFlowState.SIGNED) {
      return <SignFlowMessage message="Thank you for signing" />;
    }
    if (state === SignFlowState.ALREADY_SIGNED) {
      return <SignFlowMessage message="Thank you for signing" />;
    }
    if (state === SignFlowState.EXPIRED) {
      return <SignFlowMessage message="Document link has expired" />;
    }
    if (loggedIn) return <SignFlowMessage message="Thank you for signing!" />;
    return <SignFlowMessage message="Thank you for signing!" />;
  };

  const getDocumentSigningStatusLogo = () => {
    //check signed logo or other
    if (state == SignFlowState.SIGNED || state === SignFlowState.ALREADY_SIGNED)
      return <FuelBossNominationSigned className={styles['status-logo']} />;
    else if (state == SignFlowState.EXPIRED)
      return <FuelBossNominationExpired className={styles['status-logo']} />;
    else return <FuelBossNominationNotSigned className={styles['status-logo']} />;
  };

  return (
    <div className={loggedIn ? styles['sign-wrapper-loggedin'] : styles['sign-wrapper']}>
      <div className={styles['circle']}></div>
      <div className={styles['content']}>
        <div className={styles['content-left']}>{getDocumentSigningStatusLogo()}</div>
        <div className={styles['content-right']}>
          <div className={styles['fb-logo']}>
            <div className={styles['fb-logo-img']}>
              <img src={logo} alt="Logo" className={styles.logo} />
            </div>
            <div className={styles['fb-logo-text']}>FuelBoss</div>
          </div>
          {signFlowType == SignFlowType.PRE_SIGNING && (
            <div className={styles['signflow-div']}>{getSignFlowGoStatus()}</div>
          )}
          {signFlowType == SignFlowType.POST_SIGNING && (
            <div className={styles['signflow-div']}>{getSignFlowReturnStatus()}</div>
          )}
          {loggedIn && url && state !== SignFlowState.REDIRECTING && (
            <div className={styles['nom-button']}>
              <Button className={styles['nomination-button']} onClick={() => window.location.replace(url!)}>
                Go to nomination
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
