import React from 'react';
import styles from './OutboundIntegrationSettings.module.scss';
import { Contract } from '../../../../../store/Contract/models';
import { Button, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Field, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

const PlusCircle = findIconDefinition({prefix: 'fal',iconName: 'plus-circle'});

interface OwnProps {
  toggle: () => void;
  onClick: () => void;
  showModal: boolean;
}

const ConfirmModal: React.FC<OwnProps> = ({ toggle, showModal, onClick }) => {

  return (
    <Modal isOpen={showModal} toggle={toggle} className={styles['position']}>
        <ModalBody>
          <ModalHeader toggle={toggle} className={styles['modal-header']}>Request for outbound integration</ModalHeader>
            <h4 className={`${styles['content']} ${styles['header-text']}`}>Are you sure you want to create request for outbound integration?</h4>

                <div style={{    paddingLeft: '1rem'}}>
                  <Button className={styles['confirm-button']} color="primary" onClick={() => onClick()}>
                    Confirm
                  </Button>
                  <Button color="primary" className={styles['cancel-button']} onClick={toggle}>
                    Cancel
                  </Button>
                </div>    
        </ModalBody>
    </Modal>
  );
};

export default React.memo(ConfirmModal);
