import { Dispatch } from 'redux';
import {
  UserActions,
  USER_LOADING,
  USERS_FETCH_ERROR,
  GET_USERS,
  ADD_USER,
  USERS_ADD_ERROR,
  DELETE_USER,
  EDIT_USER,
  USERS_DELETE_ERROR,
  USERS_EDIT_ERROR,
  USERS_CLEAR_TOAST,
} from './types';
import { api } from '../../helpers/api';
import { UserResponseModel, User } from './models';
import { Role } from '../Roles/models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating user...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">User updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating user...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">User created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

export const getUsers = () => (dispatch: Dispatch<UserActions>) => {
  dispatch({
    type: USER_LOADING,
  });

  new api<UserResponseModel>({
    dispatch,
    errorType: USERS_FETCH_ERROR,
  })
    .get(`/api/UserAsset`)
    .then((response) => {
      dispatch({
        type: GET_USERS,
        payload: response,
      });
    });
};

export const addUser = (user: User, roles: Role[], onSuccess: () => void) => (dispatch: Dispatch<UserActions>) => {
  dispatch({
    type: USER_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  const input = {
    user: user,
    roles: roles,
  };

  new api<UserResponseModel>({
    dispatch,
    errorType: USERS_ADD_ERROR,
  })
    .post(`/api/UserAsset`, input)
    .then((response) => {
      dispatch({
        type: ADD_USER,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      /*dispatch({
        type: USERS_ADD_ERROR,
        payload: {
          clientFault: false,
          serverFault: true,
          statusCode: 405,
          message: 'User with the current email already exist! Please contact FuelBoss support for more details',
        },
      });*/
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteUser = (id: string) => (dispatch: Dispatch<UserActions>) => {
  dispatch({
    type: USER_LOADING,
  });

  const url = `/api/UserAsset/${id}`;
  new api<UserResponseModel>({
    dispatch,
    errorType: USERS_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_USER,
        payload: response,
      });
    });
};

export const editUser = (user: User, roles: Role[], onSuccess: () => void) => (dispatch: Dispatch<UserActions>) => {
  dispatch({
    type: USER_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (user.id != undefined) {
    const url = `/api/UserAsset/${user.id}`;

    const input = {
      user: user,
      roles: roles,
    };

    new api<UserResponseModel>({
      dispatch,
      errorType: USERS_EDIT_ERROR,
    })
      .put(url, input)
      .then((response) => {
        dispatch({
          type: EDIT_USER,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const usersClearToast = () => (dispatch: Dispatch<UserActions>) => {
  dispatch({
    type: USERS_CLEAR_TOAST,
  });
};
