import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import styles from './AnalyticsInsights.module.scss';
import { ApplicationState } from '../../store';
import { getAuth } from '../../store/Auth/actions';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import BIaddVessels from '../../components/BIaddVessels/BIaddVessels';
import BackNavigation from '../../components/BackNavigation/BackNavigation';
import EmbeddedReport from '../../components/VAP/EmbeddedReport/EmbeddedReport';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { getReports } from '../../store/Reports/actions';
import { getReportTypes } from '../../store/ReportTypes/actions';
import { Console } from 'console';
import { Report } from '../../store/Reports/models';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import ShipsLogo from '../../svgs/add-ship.svg';
import { ReactComponent as ArrowLeft } from '../../svgs/arrow-left.svg';
import { useParams, withRouter, RouterProps } from 'react-router';
import ReactTooltip from 'react-tooltip';

const StyledAnalytics = styled.div`
  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    .analyticsHeader {
      font-weight: bold;
      font-size: 28px;
    }
  }

  .bodyWrapper {
    font-size: 18px;
    .label {
      font-size: 18px;
    }
    .value {
      color: #24365c;
      font-weight: bold;
    }
    hr {
      height: 3px;
      background: #000000;
    }
  }

  .analyticsSection {
    font-weight: 600;
    font-family: Roboto;
    font-size: 22px;
    color: #000000;
  }
  button {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    border-radius: 5px;
    background: #f0f0f0;
    stroke-width: 2;
    margin-bottom: 10px;
    div {
      font-family: Roboto;
      font-size: 18px;
      color: #0c202b;
    }
    span {
      font-family: Roboto;
      font-size: 14px;
      color: #4d4d4d;
    }
  }
  button:hover {
    background: white;
    border: 1px solid #6495ed;
  }
  button:focus {
    background: white;
    border: 2px solid #24365c;
  }
`;

const StyledReportButton = styled.button`
  display: flex;
  flex-direction: collumn;
  width: 100%;
  border-radius: 5px;
  fill: #ffffff;
  stroke-width: 2;
`;
interface OwnProps {}

const AnalyticsInsights: React.FunctionComponent<OwnProps> = ({}) => {
  const {
    auth,
    authIsLoaded,
    reportsLoading,
    reportsDetailsError,
    reportsErrorDetails,
    reports,
    reportsIsLoaded,
    //reportTypeLoading,
    //reportTypeError,
    //reportTypeErrorDetails,
    //reportType,
    //reportTypeIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

    reportsLoading: state.reports.loading,
    reportsDetailsError: state.reports.error,
    reportsErrorDetails: state.reports.errorDetails,
    reports: state.reports.data,
    reportsIsLoaded: state.reports.dataIsLoaded,

    //reportTypeLoading: state.report_types.loading,
    //reportTypeError: state.report_types.error,
    //reportTypeErrorDetails: state.report_types.errorDetails,
    //reportType: state.report_types.data,
    //reportTypeIsLoaded: state.report_types.dataIsLoaded,
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const { defaultId } = useParams();

  const getReportsCallback = React.useCallback(() => dispatch(getReports()), []);

  //const getReportTypesCallback = React.useCallback(() => dispatch(getReportTypes()), []);

  const getAuthCallback = React.useCallback(() => dispatch(getAuth()), []);

  const syncVAP = async () => {
    setSyncVAPLoading(true);
    const syncVAPResponse = await fetch('/api/VAPReport/SyncVap/');
    console.log('Syncing VAP');
    setSyncVAPLoading(false);
    if (!syncVAPResponse.ok) {
      const errorMessage = `Failed to sync VAP. Status: ${syncVAPResponse.status} ${syncVAPResponse.statusText}`;
      console.error(errorMessage);
      return;
    }
    console.log('VAP Synced');
  };

  React.useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
    if (!reportsIsLoaded) {
      getReportsCallback();
      syncVAP();
    }
    //if (!reportTypeIsLoaded) {
    //  getReportTypesCallback();
    //}
    if (defaultId) {
      setActiveReportId(defaultId);
    }
  }, []);

  React.useEffect(() => {
    /// calculate report types to display e.g Bunker dashboard
    const reportTypesList = reports.map((item) => item.type);
    //console.log('report types', reportTypesList);
    const sortedReportTypes = reportTypesList.sort((a, b) => (a.subset > b.subset ? 1 : -1));
    //console.log('sorted report types', sortedReportTypes);
    const reportTypes = sortedReportTypes.map((it) => it.name);
    //console.log('name report types', reportTypes);
    const uniqueReportTypes = reportTypes.filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });
    setVisibleReportTypes(uniqueReportTypes);

    if (reports.length > 0 && defaultId) {
      //open the default report in new tab
      const defaultReport = reports.filter((x) => x.id == defaultId)[0];
      if (!defaultReport) {
        setActiveReportId('');
        history.push(`/analytics`);
      } else if (shouldOpenReportInNewTab(defaultReport)) {
        setActiveReportId('');
        window.open(defaultReport.url, '_blank');
        history.push(`/analytics`);
      }
    }
  }, [reports]);

  const [analyticsSliderMinified, setAnalyticsSliderMinified] = React.useState(false);
  const [selectedURL, setSelectedURL] = React.useState('');
  const [activeReportId, setActiveReportId] = React.useState('');
  const [visibleReportTypes, setVisibleReportTypes] = React.useState(['']);
  const [openInNewTab, setOpenInNewTab] = React.useState(false);
  const [openNonLNGfueledVessels, setOpenNonLNGfueledVessels] = React.useState(false);
  const [syncVAPLoading, setSyncVAPLoading] = React.useState(false);

  const routes = history.location.pathname.split('/');

  useEffect(() => {
    if (routes.length > 2) {
      return;
    }
  }, []);

  const [, , baseRoute] = routes;
  const isRootRoute = (routes || []).length <= 3;

  const shouldOpenReportInNewTab = (report: Report) => {
    if (report.openInNewTab || report.url) {
      return true;
    } else {
      return false;
    }
  };

  const onClickAddFleetVessels = () => {
    //redirect to Han's add vessel page
    //history.push(`/addVessels`);
    setOpenNonLNGfueledVessels(true);
    setActiveReportId('');
  };

  const onClick = (report: Report) => {
    history.push(`/analytics/${report.id}`);
    setOpenNonLNGfueledVessels(false);
    setActiveReportId(report.id ? report.id : '');
    if (!shouldOpenReportInNewTab(report)) {
      setSelectedURL(report.url);
    } else {
      window.open(report.url, '_blank');
      setActiveReportId('');
      setSelectedURL('');
    }
  };

  const goBack = () => {
    setOpenNonLNGfueledVessels(false);
    setSelectedURL('');
    history.push(`/analytics`);
  };

  const DisplayReportsButton = (report: Report) => (
    <Button
      className={`${styles['report-button']} ${activeReportId === report.id ? styles['active'] : ''}`}
      onClick={() => {
        onClick(report);
      }}
    >
      <div>{report.name}</div>
      <span>{report.description}</span>
    </Button>
  );

  const DisplayReportsSection = (reportType: string) => {
    const reportofType = reports.filter((item) => item.type.name == reportType);
    const currentReportType = reports.map((item) => item.type).filter((x) => x.name == reportType)[0];
    //console.log('displaying report of type', reportType, reportofType, currentReportType);
    return (
      <>
        <div className={styles['analyticsSection']}>
          {reportType}
          {reportType && currentReportType.showAddVessel && (
            <>
              <Button
                color="secondary"
                outline
                className={styles['addVesselButton']}
                onClick={onClickAddFleetVessels}
                data-tip={'Hi hello'}
              >
                <img src={ShipsLogo} width="27px" height="27px" />
              </Button>
              <ReactTooltip place="top" type="light" effect="solid" className={styles['tooltipClass']}>
                Add non-LNG-fuelled ships to the AIS dashboard for analysis
              </ReactTooltip>
            </>
          )}
        </div>
        <hr />
        {reportofType.map((report) => DisplayReportsButton(report))}
      </>
    );
  };

  if (reportsLoading || syncVAPLoading) {
    return <Loading type={'VAP Reports'} />;
  } else if (reportsDetailsError && reportsErrorDetails) {
    return <Error error={reportsErrorDetails} />;
  } else {
    return (
      <div className={styles['analytics']}>
        <div
          className={`${styles['analytics-wrapper']} ${
            analyticsSliderMinified ? styles['isMinified'] : styles['isNotMinified']
          }`}
        >
          {' '}
          <div className={styles['analytics-sidebar']}>
            {!analyticsSliderMinified ? (
              <>
                <StyledAnalytics>
                  <div className="headerWrapper">
                    <div className="analyticsHeader">Analytics & insights</div>
                  </div>

                  <div className="bodyWrapper">
                    {visibleReportTypes.map((section) => DisplayReportsSection(section))}
                  </div>
                </StyledAnalytics>
              </>
            ) : (
              <div>
                <p className={styles['minified-analytics']}>Analytics & insights</p>
              </div>
            )}
          </div>
          <CollapseButton
            direction={analyticsSliderMinified ? 'right' : 'left'}
            onClick={() => {
              setAnalyticsSliderMinified(!analyticsSliderMinified);
            }}
            className={styles['analytics-collapse']}
          />
        </div>
        {activeReportId == '' && !openNonLNGfueledVessels ? (
          <div className={styles['analytics-initial']}>
            <div className={styles['analytics-initial-text']}>
              Select a report in the menu on the left to view analytics.
            </div>
            <ArrowLeft className={styles['analytics-initial-img']} />
          </div>
        ) : (
          <div className={`${styles['analytics-content']} scroller-wrapper`}>
            {!openNonLNGfueledVessels && activeReportId && (
              <div className={styles['analytics-bi-wrapper']}>
                <EmbeddedReport reportId={activeReportId}></EmbeddedReport>
              </div>
            )}
            {openNonLNGfueledVessels && (
              <div className={styles['analytics-addvessel-wrapper']}>
                {goBack && <BackNavigation onClick={goBack} />}
                <BIaddVessels></BIaddVessels>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default AnalyticsInsights;
