import React from 'react';
import { User } from '../../../../../store/Users/models';
import { Role } from '../../../../../store/Roles/models';
import { Company } from '../../../../../store/Company/models';
import FormikWrapper from '../Formik/FormikWrapper';

interface OwnProps {
  addNewUser: (user: User, roles: Role[]) => void;
  companyAdmin?: boolean;
  company?: Company;
  navAdminType: string;
}

const AddUser: React.FunctionComponent<OwnProps> = ({ addNewUser, companyAdmin, navAdminType, company }) => {
  const submitUser = (user: User, roles: Role[]) => {
    addNewUser(user, roles);
  };

  return (
    <FormikWrapper
      isInaddUser={true}
      companyAdmin={companyAdmin}
      addUser={submitUser}
      header="Add team member"
      company={company}
      navAdminType={navAdminType}
    />
  );
};

export default React.memo(AddUser);
