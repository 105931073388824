import React, { useState, useCallback, useEffect } from 'react';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { getVessels } from '../../store/Vessels/actions';
import { FormGroup, Input, Button } from 'reactstrap';
import { Vessel } from '../../store/Vessels/models';
import SearchVesselResult from './Components/SearchVesselResult/SearchVesselResult';
import styles from './SearchVessel.module.scss';
import ShipsLogo from '../../svgs/add-ship.svg';

interface OwnProps {
  chooseVesselMethod: (vessel: Vessel) => void;
  hidden?: boolean;
  mapMyFleet?: boolean;
  buttonStyle?: string;
  setDisplaySearchVessel?: (display: boolean) => void;
  isSearchActive?: boolean;
  setIsSearchActive?: (isActive: boolean) => void;
}

const SearchVesselCustomized: React.FC<OwnProps> = ({
  chooseVesselMethod,
  hidden = false,
  mapMyFleet = false,
  buttonStyle,
  setDisplaySearchVessel,
  isSearchActive = true,
  setIsSearchActive,
}) => {
  const { vessels, vesselsIsLoaded } = useSelector((state: ApplicationState) => ({
    vessels: state.vessels.data,
    vesselsIsLoaded: state.vessels.dataIsLoaded,
  }));
  const ref = React.useRef(null);
  const dispatch = useDispatch();
  const getVesselsCallback = useCallback(() => dispatch(getVessels()), []);

  const [suggestions, setSuggestions] = useState<Vessel[]>([]);
  const [activeVessel, setActiveVessel] = useState(0);
  const [chosenVessel, setChosenVessel] = useState('');
  const [vesselObj, setVesselObj] = useState<Vessel>();
  const [isCancelHovered, setIsCancelHovered] = useState(false);

  let StyledButton: React.CSSProperties = {
    marginLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties;

  let StyledButtonCancel: React.CSSProperties = {
    marginLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F6F6F6',
    border: '1px solid #ccc',
    color: ' #24365c',
  } as React.CSSProperties;

  let StyledButtonCancelHover: React.CSSProperties = {
    marginLeft: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#CCE0EB',
    border: '1px solid #ccc',
    color: ' #24365c',
  } as React.CSSProperties;

  let StyledInput: React.CSSProperties = {
    height: '46px',
    borderRadius: '0px',
    boxShadow: 'none !important',
  } as React.CSSProperties;

  let StyledInputParent: React.CSSProperties = {
    flex: '1',
    display: 'flex',
    height: '46px',
    whiteSpace: 'nowrap',
  } as React.CSSProperties;

  let StyledFormGroupActive: React.CSSProperties = {
    width: '620px',
    backgroundColor: '#e5f5fb',
    padding: '25px',
    border: '1px solid #CCCCCC',
    borderRadius: '7px',
    boxShadow: '0px 5px 15px 0px rgba(204,204,204,1)',
    WebkitBoxShadow: '0px 5px 15px 0px rgba(204,204,204,1)',
    position: 'relative',
  } as React.CSSProperties;

  let StyledFormGroupInActive: React.CSSProperties = {
    width: '620px',
    backgroundColor: '#f5f5f5',
    padding: '25px',
    border: '1px solid #CCCCCC',
    borderRadius: '7px',
  } as React.CSSProperties;

  useEffect(() => {
    console.log('inuse effect cusotmized vessel search');
    if (!vesselsIsLoaded) {
      getVesselsCallback();
    }
  }, []);

  if (mapMyFleet) {
    StyledInput = { height: '38px', borderRadius: 'unset', marginRight: '2px' };
    StyledButton = { display: 'flex', alignItems: 'center' };
    StyledInputParent = { flex: '1', display: 'flex', height: '46px', whiteSpace: 'nowrap', marginTop: '10px' };
    StyledFormGroupActive = { margin: 0 };
  }

  const onChange = (val: string) => {
    setActiveVessel(0);
    setChosenVessel(val);
    setSuggestions(vessels.filter((vessel) => checkMatch(vessel, val)));
  };

  const onFocus = (val: string) => {
    if (setIsSearchActive) setIsSearchActive(true);
  };
  const onBlur = (val: string) => {
    if (val == '' || val == undefined) {
      if (setIsSearchActive) setIsSearchActive(false);
    }
  };

  const checkMatch = (vessel: Vessel, val: string) => {
    if (val.length < 1) return false;
    let match = false;

    //Compares input value with imo
    if (vessel.imo !== null && vessel.imo !== undefined) {
      if (vessel.imo.toString().indexOf(val) > -1) {
        match = true;
      }
    }

    //Compares input value with vessel name
    if (vessel.vesselName !== undefined && vessel.vesselName !== null) {
      if (vessel.vesselName.toLowerCase().indexOf(val.toLowerCase()) > -1) {
        match = true;
      }
    }

    //Place for more cases if needed in the future
    return match;
  };

  const chooseVessel = (vessel: Vessel, index: number) => {
    setChosenVessel(vessel.vesselName);
    setVesselObj(vessel);
    setSuggestions([]);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      //Enter key
      chooseVessel(suggestions[activeVessel], activeVessel);
    } else if (e.keyCode === 38) {
      //Up key
      if (activeVessel > 0) {
        setActiveVessel(activeVessel - 1);
      }
    } else if (e.keyCode === 40) {
      //Down key
      if (activeVessel + 1 < suggestions.length) {
        setActiveVessel(activeVessel + 1);
      }
    }
  };

  const addVesselClick = () => {
    console.log('in add vessel click');
    if (vesselObj !== undefined) {
      chooseVesselMethod(vesselObj);
      setChosenVessel('');
      //setIsActive(false);
    }
  };

  const cancelClick = () => {
    if (setDisplaySearchVessel) setDisplaySearchVessel(false);
  };

  const cancelMouseEnter = () => {
    setIsCancelHovered(true);
  };

  const cancelMouseLeave = () => {
    setIsCancelHovered(false);
  };

  const listStyles = () => {
    if (mapMyFleet) {
      return styles.mapsuggestions;
    } else {
      return styles.suggestions;
    }
  };
  return (
    // @ts-ignore
    <FormGroup
      hidden={hidden}
      // @ts-ignore
      style={isSearchActive ? StyledFormGroupActive : StyledFormGroupInActive}
      autoComplete="off"
    >
      <div style={StyledInputParent}>
        <Input
          // @ts-ignore
          style={StyledInput}
          type="text"
          name="vessel"
          id="vessel"
          onChange={(e) => onChange(e.target.value)}
          onFocus={(e) => onFocus(e.target.value)}
          onBlur={(e) => onBlur(e.target.value)}
          value={chosenVessel}
          onKeyDown={onKeyDown}
          autoComplete="off"
          placeholder="Type vessel name ..."
        />
        <Button
          className={buttonStyle}
          color="primary"
          size="lg"
          disabled={!chosenVessel.length}
          onClick={addVesselClick}
          // @ts-ignore
          style={StyledButton}
        >
          Add vessel
        </Button>

        <Button
          className={buttonStyle}
          size="lg"
          disabled={false}
          onClick={cancelClick}
          onMouseEnter={cancelMouseEnter}
          onMouseLeave={cancelMouseLeave}
          // @ts-ignore
          style={isCancelHovered ? StyledButtonCancelHover : StyledButtonCancel}
        >
          Cancel
        </Button>
      </div>

      <ul className={listStyles() as string}>
        {suggestions.map((vessel: any, index: any) => (
          <SearchVesselResult
            vessel={vessel}
            chooseVessel={chooseVessel}
            index={index}
            key={index}
            active={activeVessel === index}
          />
        ))}
      </ul>
    </FormGroup>
  );
};

export default React.memo(SearchVesselCustomized);
