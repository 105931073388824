import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import * as TYPE from './types';
import { api } from '../../helpers/api';
import { CompanyResponseModel, Company } from './models';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';
import { getAuth } from '../Auth/actions';
import { DeliveryModes } from '../../models/DeliveryMode';
import { LowCarbonFuels } from '../../models/LowCarbonFuel';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating company...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Company updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating company...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Company created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;

const errorToggleDeliveryModeModal = (
  <ModalResponse status="error">There has been an error updating the delivery mode</ModalResponse>
);

const errorToggleLowCarbonFuelModal = (
  <ModalResponse status="error">There has been an error updating the low carbon option</ModalResponse>
);

export const getCompanies = () => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_LOADING,
  });

  new api<CompanyResponseModel>({
    dispatch,
    errorType: TYPE.COMPANIES_FETCH_ERROR,
  })
    .get(`/api/CompanyModels`)
    .then((response) => {
      dispatch({
        type: TYPE.GET_COMPANIES,
        payload: response,
      });
    });
};

export const deleteCompany = (id: string) => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_LOADING,
  });

  const url = `/api/CompanyModels/${id}`;

  new api<CompanyResponseModel>({
    dispatch,
    errorType: TYPE.DELETE_COMPANY_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: TYPE.DELETE_COMPANY,
        payload: response,
      });
    });
};

export const updateCompany = (company: Company, navAdminType: string, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.CompanyActions>,
) => {
  dispatch({
    type: TYPE.COMPANY_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/CompanyModels/${company.companyId}`;

  new api<CompanyResponseModel>({
    dispatch,
    errorType: TYPE.UPDATE_COMPANY_ERROR,
  })
    .put(url, company)
    .then((response) => {
      dispatch({
        type: TYPE.UPDATE_COMPANY,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
      if (navAdminType === 'company') {
        // @ts-ignore
        getAuth()(dispatch);
      }
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const addCompany = (company: Company, onSuccess: () => void) => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<CompanyResponseModel>({
    dispatch,
    errorType: TYPE.ADD_COMPANY_ERROR,
  })
    .post(`/api/CompanyModels`, company)
    .then((response) => {
      dispatch({
        type: TYPE.ADD_COMPANY,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      /*dispatch({
        type: TYPE.ADD_COMPANY_ERROR,
        payload: {
          clientFault: false,
          serverFault: true,
          statusCode: 405,
          message: 'Company with the current name already exist! Please contact FuelBoss support for more details.',
        },
      });*/
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const setCompanyFormEditMode = (isEditMode: boolean) => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_EDIT_MODE,
    payload: isEditMode,
  });
};

export const companyClearToast = () => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_CLEAR_TOAST,
  });
};

export const companyClearDeliverModeError = () => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_CLEAR_DELIVERY_MODE_ERROR,
  });
};

export const companyClearLowCarbonFuelError = () => (dispatch: Dispatch<TYPE.CompanyActions>) => {
  dispatch({
    type: TYPE.COMPANY_CLEAR_LOWCARBON_FUEL_ERROR,
  });
};

export const toggleDeliveryMode = (companyId: string, deliveryMode: DeliveryModes, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.CompanyActions>,
) => {
  dispatch({
    type: TYPE.COMPANY_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/CompanyModels/${companyId}/${deliveryMode}`;

  new api<CompanyResponseModel>({
    dispatch,
    errorType: TYPE.COMPANY_DELIVERY_MODE_ERROR,
  })
    .put(url, null)
    .then((response) => {
      dispatch({
        type: TYPE.COMPANY_DELIVERY_MODE,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorToggleDeliveryModeModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 500);
    });
};

export const toggleLowCarbonOption = (companyId: string, fuel: LowCarbonFuels, onSuccess: () => void) => (
  dispatch: Dispatch<TYPE.CompanyActions>,
) => {
  dispatch({
    type: TYPE.COMPANY_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  const url = `/api/CompanyModels/toggleLowCarbonFuel/${companyId}/${fuel}`;

  new api<CompanyResponseModel>({
    dispatch,
    errorType: TYPE.COMPANY_LOWCARBON_FUEL_ERROR,
  })
    .put(url, null)
    .then((response) => {
      dispatch({
        type: TYPE.COMPANY_LOWCARBON_FUEL,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: updateSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorToggleLowCarbonFuelModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 500);
    });
};
