import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderModal } from '../../store/Global/actions';
import './Modal.scss';

class Modal extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden'; // disable scrolling
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto'; // enable scrolling
  }

  render() {
    const { renderModal, content } = this.props;

    return (
      <div className="Modal">
        <div className="modal-dimmer" onClick={() => content.props.isDismissable && renderModal(null)} />
        <div className="modal-box">{content}</div>
      </div>
    );
  }
}

export default connect(null, { renderModal })(Modal);
