import React, { useState } from 'react';
import { Route } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import AddContract from './Components/AddContract/AddContract';
import Contracts from './Components/Contracts/Contracts';
import { ApplicationState } from '../../../store';
import { getContracts, addContract, deleteContract, updateContract } from '../../../store/Contract/actions';
import { Contract } from '../../../store/Contract/models';
import { AddToast } from '../../../helpers/toastService';
import { getChorusCompanies } from '../../../store/ChorusCompany/actions';
import { ICompany } from '@teqplay/chorus-components';
import { contractsClearToast } from '../../../store/Contract/actions';
import EditContract from './Components/EditContract/EditContract';
import RejectModal from './Components/RejectModal/RejectModal';
import { getContractDocuments, deleteContractDocuments } from '../../../store/ContractDocuments/actions';

interface OwnProps {
  systemAdmin?: boolean;
  navAdminType: string;
}

const ContractsContainer: React.FC<OwnProps> = ({ systemAdmin = false, navAdminType }) => {
  const {
    contractsLoading,
    contractsDetailsError,
    contractsErrorDetails,
    contracts,
    contractsIsLoaded,
    toastData,
    chorusCompanyLoading,
    chorusCompanyError,
    chorusCompany,
    chorusCompanyIsLoaded,
    auth,
  } = useSelector((state: ApplicationState) => ({
    contractsLoading: state.contracts.loading,
    contractsDetailsError: state.contracts.error,
    contractsErrorDetails: state.contracts.errorDetails,
    contracts: state.contracts.data,
    contractsIsLoaded: state.contracts.dataIsLoaded,
    toastData: state.contracts.toastData,
    chorusCompanyLoading: state.choruscompany.loading,
    chorusCompanyError: state.choruscompany.error,
    chorusCompany: state.choruscompany.data,
    chorusCompanyIsLoaded: state.choruscompany.dataIsLoaded,
    auth: state.auth.data,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const getAllContractsCallback = useCallback(() => dispatch(getContracts()), []);

  const addContractCallback = useCallback(
    (contract: Contract) => dispatch(addContract(contract, () => history.push(`/${navAdminType}admin/contracts`))),
    [],
  );

  const deleteContractCallback = useCallback((id: string) => dispatch(deleteContract(id)), []);

  const editContractCallback = useCallback(
    (contract: Contract) => dispatch(updateContract(contract, () => history.push(`/${navAdminType}admin/contracts`))),
    [],
  );

  const getChorusCompaniesCallback = React.useCallback(() => dispatch(getChorusCompanies()), []);

  const clearToastCallback = useCallback(() => dispatch(contractsClearToast()), []);

  const getContractDocumentsCallback = React.useCallback((contractId: string) => {
    dispatch(getContractDocuments(contractId));
  }, []);

  useEffect(() => {
    if (toastData != null || toastData != undefined) {
      AddToast(toastData);
      clearToastCallback();
    }
  }, [toastData]);

  useEffect(() => {
    if (!contractsIsLoaded) {
      getAllContractsCallback();
    }
    if (!chorusCompanyIsLoaded) {
      getChorusCompaniesCallback();
    }
  }, []);

  const [suppliers, setSuppliers] = React.useState<ICompany[]>();
  const [customers, setCustomers] = React.useState<ICompany[]>();
  const [rejectContract, setRejectContract] = React.useState<Contract>();
  const [writePermission, setWritePermission] = React.useState(systemAdmin);
  const [updateRequired, setUpdateRequired] = React.useState<boolean>(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModalsetContract = (contract: Contract) => {
    setRejectContract(contract);
    setUpdateRequired(true);
    toggleModal();
  };

  const contractEdited = (contract: Contract) => {
    setUpdateRequired(true);
    editContractCallback(contract);
  };

  const contractAdded = (contract: Contract) => {
    setUpdateRequired(true);
    addContractCallback(contract);
  };

  useEffect(() => {
    const sup: ICompany[] = [];
    const cust: ICompany[] = [];

    if (chorusCompanyIsLoaded && chorusCompany.length > 0) {
      chorusCompany.forEach((e: ICompany) => {
        if (e.companyType.toUpperCase() === 'VENDOR') {
          sup.push(e);
          if (auth.company && e._id === auth.company.chorusId) {
            setWritePermission(true);
          }
        } else {
          cust.push(e);
        }
      });
      setSuppliers(sup.sort((a, b) => (a.name < b.name ? -1 : 1)));
      setCustomers(cust.sort((a, b) => (a.name < b.name ? -1 : 1)));
    }
  }, [chorusCompany]);

  const [selectedPage, setDefaultSelectedPage] = React.useState(0);
  function handleDefaultChange(newValue:number) {
    setDefaultSelectedPage(newValue);
  }

  if (auth && auth.company && customers && suppliers) {
    return (
      <>
        <RejectModal
          showModal={modal}
          updateContract={contractEdited}
          toggle={toggleModal}
          contract={rejectContract!}
        />
        <Switch>
          <Route
            exact
            path={`/${navAdminType}admin/contracts`}
            render={(props) => (
              <Contracts
                {...props}
                toggleRejectModal={toggleModalsetContract}
                contracts={contracts}
                updateRequired={updateRequired}
                requireUpdate={setUpdateRequired}
                isLoading={chorusCompanyLoading}
                hasError={contractsDetailsError || chorusCompanyError}
                errorDetails={contractsErrorDetails}
                deleteContract={deleteContractCallback}
                updateSingleContract={editContractCallback}
                getContracts={getAllContractsCallback}
                systemAdmin={systemAdmin}
                customers={customers}
                suppliers={suppliers}
                writePermission={writePermission}
                company={auth.company}
                navAdminType={navAdminType}
                defaultSelectedPage={selectedPage}
                setDefaultSelectedPage={handleDefaultChange}
                getContractDocuments={getContractDocumentsCallback}
              />
            )}
          />
          <Route
            exact
            path={`/${navAdminType}admin/contracts/add`}
            render={(props) => (
              <AddContract
                {...props}
                addNewContract={contractAdded}
                systemAdmin={systemAdmin}
                customers={customers}
                suppliers={suppliers}
                company={auth.company}
                navAdminType={navAdminType}
              />
            )}
          />
          <Route
            exact
            path={`/${navAdminType}admin/contracts/:contractId/edit`}
            render={(props) => (
              <EditContract
                {...props}
                isLoading={contractsLoading}
                getContracts={getAllContractsCallback}
                editContract={contractEdited}
                systemAdmin={systemAdmin}
                customers={customers}
                suppliers={suppliers}
                contracts={contracts}
                contractId={props.match.params.contractId}
                company={auth.company}
                navAdminType={navAdminType}
              />
            )}
          />
        </Switch>
      </>
    );
  } else {
    return null;
  }
};

export default ContractsContainer;
