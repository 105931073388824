import { Dispatch } from 'redux';
import {
  PipelineActions,
  PIPELINE_LOADING,
  PIPELINE_FETCH_ERROR,
  GET_PIPELINE,
  DELETE_PIPELINE,
  PIPELINE_DELETE_ERROR,
  ADD_PIPELINE,
  PIPELINE_ADD_ERROR,
  PIPELINE_CLEAR_TOAST,
} from './types';
import { api } from '../../helpers/api';
import { PipelineResponseModel, Pipeline } from './models';

export const getPipeline = (id: string) => (dispatch: Dispatch<PipelineActions>) => {
  dispatch({
    type: PIPELINE_LOADING,
  });

  new api<PipelineResponseModel>({
    dispatch,
    errorType: PIPELINE_FETCH_ERROR,
  })
    .get(`/api/Pipelines/${id}`)
    .then((response) => {
      dispatch({
        type: GET_PIPELINE,
        payload: response,
      });
    });
};

export const addPipeline = (id: string, pipeline: Pipeline) => (dispatch: Dispatch<PipelineActions>) => {
  dispatch({
    type: PIPELINE_LOADING,
  });

  new api<PipelineResponseModel>({
    dispatch,
    errorType: PIPELINE_ADD_ERROR,
  })
    .post(`/api/Pipelines/${id}`, pipeline)
    .then((response) => {
      dispatch({
        type: GET_PIPELINE,
        payload: response,
      });
      dispatch({
        type: ADD_PIPELINE,
        payload: response,
      });
    });
};

export const deletePipeline = (id: string, companyId: string) => (dispatch: Dispatch<PipelineActions>) => {
  dispatch({
    type: PIPELINE_LOADING,
  });

  const url = `/api/Pipelines/${id}`;
  new api<PipelineResponseModel>({
    dispatch,
    errorType: PIPELINE_FETCH_ERROR,
  })
    .delete(url, companyId)
    .then((response) => {
      dispatch({
        type: DELETE_PIPELINE,
        payload: response,
      });
    });
};

export const pipelineClearToast = () => (dispatch: Dispatch<PipelineActions>) => {
  dispatch({
    type: PIPELINE_CLEAR_TOAST,
  });
};
