import React, { useCallback } from 'react';
import { Company } from '../../../../store/Company/models';
import { updateCompany } from '../../../../store/Company/actions';
import { addCompanyLogo } from '../../../../store/CompanyLogo/actions';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import EditCompany from '../../../../components/AdminComponents/Companies/Components/EditCompany/EditCompany';
import { setCompanyFormEditMode } from '../../../../store/Company/actions';
import { Auth } from '../../../../store/Auth/models';
import styles from './ThirdPartyConnections.module.scss';
import styled from 'styled-components';
import { AuthenticationServiceLayer, ThirdPartyContactsTable } from '@teqplay/chorus-components';
import Pagination from '../../../../components/FuelBossTable/Pagination/Pagination';

interface OwnProps {
  auth: Auth;
  serviceLayer: AuthenticationServiceLayer;
  navAdminType: string;
}

const ThirdPartyConnections: React.FC<OwnProps> = ({ auth, serviceLayer, navAdminType }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles['third-party-connections-wrapper']}>
      <ThirdPartyContactsTable
        authenticationService={serviceLayer}
        user={auth.chorusUserProfile}
        userCompanyId={auth.chorusUserProfile.companyId || ''}
      >
        {(paginationProps) => {
          return <Pagination {...paginationProps} />;
        }}
      </ThirdPartyContactsTable>
    </div>
  );
};

export default ThirdPartyConnections;
