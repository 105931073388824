import sanityClient from '@sanity/client';

export default {
  getSanityClient(projectId: string, dataset: string, token: string) {
    const clientSanity = sanityClient({
      projectId: projectId,
      dataset: dataset,
      apiVersion: '2021-08-31', // use current UTC date - see "specifying API version"!
      token: token, // or leave blank for unauthenticated usage
      useCdn: false, // `false` if you want to ensure fresh data
    });
    return clientSanity;
  },
};
