import { ErrorActionPayload } from '../../models/ErrorAction';
import { ChorusDocumentWrapperModel } from './models';

export const CHORUSDOCUMENTS_LOADING = 'chorusdocuments/loading';
export type CHORUSDOCUMENTS_LOADING = typeof CHORUSDOCUMENTS_LOADING;

export const GET_CHORUSDOCUMENTS = 'chorusdocuments/get';
export type GET_CHORUSDOCUMENTS = typeof GET_CHORUSDOCUMENTS;

export const CHORUSDOCUMENTS_FETCH_ERROR = 'chorusdocuments/fetchError';
export type CHORUSDOCUMENTS_FETCH_ERROR = typeof CHORUSDOCUMENTS_FETCH_ERROR;


export const CHORUSDOCUMENTSDOWNLOADPREVIEW_FETCH_ERROR = 'chorusdocumentdownloadpreview/fetchError';
export type CHORUSDOCUMENTSDOWNLOADPREVIEW_FETCH_ERROR = typeof CHORUSDOCUMENTSDOWNLOADPREVIEW_FETCH_ERROR;

export const GET_CHORUSDOCUMENTSDOWNLOADPREVIEW = 'chorusdocumentdownloadpreview/get';
export type GET_CHORUSDOCUMENTSDOWNLOADPREVIEW = typeof GET_CHORUSDOCUMENTSDOWNLOADPREVIEW;

export const CHORUSDOCUMENTSDOWNLOADPREVIEW_LOADING = 'chorusdocumentdownloadpreview/download';
export type CHORUSDOCUMENTSDOWNLOADPREVIEW_LOADING = typeof CHORUSDOCUMENTSDOWNLOADPREVIEW_LOADING;

export const CHORUSDOCUMENTSDOWNLOADPREVIEW_CLEAR_TOAST = 'chorusdocumentdownloadpreview/clearToast';
export type CHORUSDOCUMENTSDOWNLOADPREVIEW_CLEAR_TOAST = typeof CHORUSDOCUMENTSDOWNLOADPREVIEW_CLEAR_TOAST;


export const GET_CHORUSDOCUMENTSDOWNLOADLIST = 'chorusdocumentdownloadlist/get';
export type GET_CHORUSDOCUMENTSDOWNLOADLIST = typeof GET_CHORUSDOCUMENTSDOWNLOADLIST;

export const CHORUSDOCUMENTSDOWNLOADLIST_LOADING = 'chorusdocumentdownloadlist/download';
export type CHORUSDOCUMENTSDOWNLOADLIST_LOADING = typeof CHORUSDOCUMENTSDOWNLOADLIST_LOADING;

export const CHORUSDOCUMENTSDOWNLOADLIST_FETCH_ERROR = 'chorusdocumentdownloadlist/fetchError';
export type CHORUSDOCUMENTSDOWNLOADLIST_FETCH_ERROR = typeof CHORUSDOCUMENTSDOWNLOADLIST_FETCH_ERROR;


export type ChorusDocumentActions =
  | {
      type: GET_CHORUSDOCUMENTS;
      payload: ChorusDocumentWrapperModel['data'];
    }
  | {
      type: CHORUSDOCUMENTS_LOADING;
    }
  | {
      type: CHORUSDOCUMENTS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
    | {
      type: GET_CHORUSDOCUMENTSDOWNLOADPREVIEW;
      payload: ChorusDocumentWrapperModel['data'];
      
    }
    | {
      type: CHORUSDOCUMENTSDOWNLOADPREVIEW_LOADING;
      
    }
    | {
      type: CHORUSDOCUMENTSDOWNLOADPREVIEW_CLEAR_TOAST;
      
    }
    | {
      type: CHORUSDOCUMENTSDOWNLOADPREVIEW_FETCH_ERROR;
      payload: ErrorActionPayload;
      
    }
    | {
      type: GET_CHORUSDOCUMENTSDOWNLOADLIST;
      payload: ChorusDocumentWrapperModel['data'];
    }
    | {
      type: CHORUSDOCUMENTSDOWNLOADLIST_LOADING;
      
    }
    | {
      type: CHORUSDOCUMENTSDOWNLOADLIST_FETCH_ERROR;
      payload: ErrorActionPayload;
      
    };