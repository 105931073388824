import * as TYPE from './types';
import { ApplicationState } from '..';

export const fuelbossLocationsReducer = (
  state: ApplicationState['fuelbosslocations'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.FuelbossLocationActions,
): ApplicationState['fuelbosslocations'] => {
  switch (action.type) {
    case TYPE.FUELBOSSLOCATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.GET_FUELBOSSLOCATIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.FUELBOSSLOCATION_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_FUELBOSSLOCATION:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Location succesfully added!',
          type: 'success',
        },
      };

    case TYPE.ADD_FUELBOSSLOCATION_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not add the location. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.DELETE_FUELBOSSLOCATION:
      return {
        ...state,
        data: action.payload,
        loading: false,
        toastData: {
          content: 'Location succesfully deleted!',
          type: 'success',
        },
      };
    case TYPE.DELETE_FUELBOSSLOCATION_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not delete the location. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.EDIT_FUELBOSSLOCATION:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
        toastData: {
          content: 'Location edited',
          type: 'info',
        },
      };
    case TYPE.EDIT_FUELBOSSLOCATION_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : 'Something went wrong! We could not edit the location. Please contact FuelBoss support for more details.',
          type: 'error',
        },
      };
    case TYPE.FUELBOSSLOCATION_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    default:
      return { ...state };
  }
};
