import { Dispatch } from 'redux';
import {
  IntegrationEventTypeActions,
  INTEGRATION_EVENT_TYPE_CLEAR_TOAST,
  INTEGRATION_EVENT_TYPE_LOADING,
  GET_INTEGRATION_EVENT_TYPE,
  INTEGRATION_EVENT_TYPE_FETCH_ERROR,
  ADD_INTEGRATION_EVENT_TYPE,
  DELETE_INTEGRATION_EVENT_TYPE,
  EDIT_INTEGRATION_EVENT_TYPE,
  INTEGRATION_EVENT_TYPE_EDIT_ERROR,
  INTEGRATION_EVENT_TYPE_ADD_ERROR,
  INTEGRATION_EVENT_TYPE_DELETE_ERROR,
} from './types';
import { api } from '../../helpers/api';
import { IntegrationEventTypeResponseModel, IntegrationEventType } from './models';
import React, { ReactElement } from 'react';
import ModalResponse from '../../components/Modal/ModalResponse';
import { SET_MODAL } from '../Global/types';

const updateLoaderModal: ReactElement = <ModalResponse status="loading">Updating integration event type...</ModalResponse>;
const updateSuccessModal: ReactElement = <ModalResponse status="success">Integration event type updated successfully</ModalResponse>;

const createLoaderModal: ReactElement = <ModalResponse status="loading">Creating integration event type...</ModalResponse>;
const createSuccessModal: ReactElement = <ModalResponse status="success">Integration event type created successfully</ModalResponse>;

const errorModal = <ModalResponse status="error">There has been an error</ModalResponse>;


export const getIntegrationEventTypes = () => (dispatch: Dispatch<IntegrationEventTypeActions>) => {
  dispatch({
    type: INTEGRATION_EVENT_TYPE_LOADING,
  });

  new api<IntegrationEventTypeResponseModel>({
    dispatch,
    errorType: INTEGRATION_EVENT_TYPE_FETCH_ERROR,
  })
    .get(`/api/Integration/EventTypes`)
    .then((response) => {
      dispatch({
        type: GET_INTEGRATION_EVENT_TYPE,
        payload: response,
      });
    });
};

export const addIntegrationEventType = (integrationEventType: IntegrationEventType, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationEventTypeActions>,
) => {
  dispatch({
    type: INTEGRATION_EVENT_TYPE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: createLoaderModal });

  new api<IntegrationEventTypeResponseModel>({
    dispatch,
    errorType: INTEGRATION_EVENT_TYPE_ADD_ERROR,
  })
    .post(`/api/Integration/EventTypes`, integrationEventType)
    .then((response) => {
      dispatch({
        type: ADD_INTEGRATION_EVENT_TYPE,
        payload: response,
      });
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: createSuccessModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
      onSuccess();
    })
    .catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const deleteIntegrationEventType = (id: string) => (dispatch: Dispatch<IntegrationEventTypeActions>) => {
  dispatch({
    type: INTEGRATION_EVENT_TYPE_LOADING,
  });

  const url = `/api/Integration/EventTypes/${id}`;
  new api<IntegrationEventTypeResponseModel>({
    dispatch,
    errorType: INTEGRATION_EVENT_TYPE_DELETE_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: DELETE_INTEGRATION_EVENT_TYPE,
        payload: response,
      });
    }).catch(() => {
      // @ts-ignore
      dispatch({ type: SET_MODAL, payload: errorModal });
      setTimeout(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: null });
      }, 1000);
    });
};

export const editIntegrationEventType = (integrationEventType: IntegrationEventType, onSuccess: () => void) => (
  dispatch: Dispatch<IntegrationEventTypeActions>,
) => {
  dispatch({
    type: INTEGRATION_EVENT_TYPE_LOADING,
  });
  // @ts-ignore
  dispatch({ type: SET_MODAL, payload: updateLoaderModal });

  if (integrationEventType.id != undefined) {
    const url = `/api/Integration/EventTypes/${integrationEventType.id}`;

    new api<IntegrationEventTypeResponseModel>({
      dispatch,
      errorType: INTEGRATION_EVENT_TYPE_EDIT_ERROR,
    })
      .put(url, integrationEventType)
      .then((response) => {
        dispatch({
          type: EDIT_INTEGRATION_EVENT_TYPE,
          payload: response,
        });
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: updateSuccessModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
        onSuccess();
      })
      .catch(() => {
        // @ts-ignore
        dispatch({ type: SET_MODAL, payload: errorModal });
        setTimeout(() => {
          // @ts-ignore
          dispatch({ type: SET_MODAL, payload: null });
        }, 1000);
      });
  }
};

export const integrationEventTypesClearToast = () => (dispatch: Dispatch<IntegrationEventTypeActions>) => {
  dispatch({
    type: INTEGRATION_EVENT_TYPE_CLEAR_TOAST,
  });
};
