import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Form, FormikProps } from 'formik';
import { ApplicationState } from '../../../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getAuth } from '../../../../../store/Auth/actions';
import { Loading } from '../../../../Loading';
import { Error } from '../../../../Error';
import { renderTextInput, renderComplexDropdown, renderSimpleDropdown, renderTextAreaInput, renderCheckbox } from '../../../../Form/FormInputs';
import integrationAssetConfigs from './integrationAssetConfigs';
import styled from 'styled-components';
import { FormHeader } from '../../../../Form/FormHeader';
import {IntegrationAssetPermissions} from '../../../../../store/IntegrationAssetPermissions/models';
import { getIntegrationAssetCompanies } from '../../../../../store/IntegrationAssetCompanies/actions';
import { getCompanies } from '../../../../../store/Company/actions';

const StyledInnerForm = styled.div`
  width: 50%;
  @media (max-width: 1920px) {
    width: 70%;
  }
  display: block;
  flex-wrap: wrap;
`;

const StyledForm = styled.div`
margin-top: 1em;
.formHeader {
  flex: 3 1 0%;
  font-weight: 600;
  font-size: 1.75em;
  padding-left: 5px;
}
.buttonContainer {
  display: flex;
  flex: 0 1 0%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: right;
}
.buttonContainer button {
  justify-self: flex-end;
  margin-left: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 18px;
  font-size: 18px;
}
div[class^='input-'] {
  flex: 0 1 40%;
  margin-right: 35px;
  width: 35%;
}
button {
  justify-self: flex-end;
  margin-right: 0.5em;
}
.form-check {
  margin-right: 5px;
}
`;

interface OwnProps {
  integrationAssetPermissions?: IntegrationAssetPermissions;
  header: string;
  saveMethod: (integrationAssetPermissions: IntegrationAssetPermissions) => void;
  systemAdminPage?: boolean;
  formikProps: FormikProps<IntegrationAssetPermissions>;
  navAdminType: string;
  integrationAssetId: string;
}

const IntegrationAssetPermissionsForm: React.FC<OwnProps> = ({
  header,
  saveMethod,
  integrationAssetPermissions,
  systemAdminPage = false,
  navAdminType,
  formikProps,
  integrationAssetId,
}) => {
  const {
    integrationAssetCompanies,
    integrationAssetCompaniesLoading,
    integrationAssetCompaniesDetailsError,
    integrationAssetCompaniesErrorDetails,
    integrationAssetCompaniesIsLoaded,
    toastDataIntegrationAssetCompanies,

    companies,
    companiesDetailsError,
    companiesErrorDetails,
    companiesIsLoaded,
    companiesLoading,
    toastDataCompanies,

    auth,
    authError,
    authIsLoaded, 
    authErrorDetails,
    authLoading,
  } = useSelector((state: ApplicationState) => ({
    integrationAssetCompanies: state.integration_assets_companies.data,
    integrationAssetCompaniesLoading: state.integration_assets_companies.loading,
    integrationAssetCompaniesDetailsError: state.integration_assets_companies.error,
    integrationAssetCompaniesErrorDetails: state.integration_assets_companies.errorDetails,
    integrationAssetCompaniesIsLoaded: state.integration_assets_companies.dataIsLoaded,
    toastDataIntegrationAssetCompanies: state.integration_assets_companies.toastData,

    companies: state.companies.data,
    companiesLoading: state.companies.loading,
    companiesDetailsError: state.companies.error,
    companiesErrorDetails: state.companies.errorDetails,
    companiesIsLoaded: state.companies.dataIsLoaded,
    toastDataCompanies: state.companies.toastData,

    authLoading: state.auth.loading,
    authError: state.auth.error,
    authErrorDetails: state.auth.errorDetails,
    auth: state.auth.data,
    authIsLoaded: state.auth.dataIsLoaded,

  }));
  const dispatch = useDispatch();
  const getAuthCallback = useCallback(() => dispatch(getAuth()), []);
  const getAllIntegrationAssetCompanies = useCallback(() => dispatch(getIntegrationAssetCompanies()), []);

  const getAllCompanies = useCallback(() => dispatch(getCompanies()), []);

  const [assetId, setAssetId] = useState<string>();

  useEffect(() => {
    if (!authIsLoaded) {
      getAuthCallback();
    }
  }, []);

  useEffect(()=>{
    if(integrationAssetCompanies){
      integrationAssetCompanies.filter((x) => {
        if(x.companyId === formikProps.values.ownerCompanyId){
          setAssetId(x.companyId);
          formikProps.setFieldValue("ownerCompanyId",x.companyName);
        }
      })
    }
  }, []);
  
  useEffect(() => {
    if (!integrationAssetCompaniesIsLoaded) {
      getAllIntegrationAssetCompanies();
    }
  }, [integrationAssetCompaniesIsLoaded]);

  useEffect(() => {
    if (!companiesIsLoaded) {
      getAllCompanies();
    }
  }, [companiesIsLoaded]);

  const onFormSave = () => {
    formikProps.submitForm();
    //const roleArray = getRolesArray();

    const integrationAssetPermissionsToSave: IntegrationAssetPermissions = {
      ...formikProps.values,
      //  active: true,
    };
    if(formikProps.values.ownerCompany)
    integrationAssetPermissionsToSave.ownerCompanyId = formikProps.values.ownerCompany?.companyId;

    if(formikProps.values.externalCompany?.chorusId)
    integrationAssetPermissionsToSave.externalAccessCompanyId = formikProps.values.externalCompany?.chorusId;

    if(integrationAssetCompanies){
      integrationAssetCompanies.filter((x) => {
        if(x.companyId === assetId){
          integrationAssetPermissionsToSave.ownerCompanyId = x.companyId;
        }
      })
    }

    if (integrationAssetPermissions) {
      integrationAssetPermissionsToSave.ownerCompanyId = integrationAssetPermissions.ownerCompanyId;
    }
    if (formikProps.isValid) {
      saveMethod(integrationAssetPermissionsToSave);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: integrationAssetConfigs, errors, touched, setFieldValue, setFieldTouched };
  const url = `integrationassets/${integrationAssetId}/edit`

  if (authError && authErrorDetails) {
    return <Error error={authErrorDetails} />;
  } else {
    return (
      <StyledForm>
        <FormHeader
          navAdminType={navAdminType}
          header={header}
          onFormSave={onFormSave}
          confirmButtonText="Save integration permission"
          cancelButtonText="Cancel"
          formUrl={url}
        />
        <StyledInnerForm as={Form}>
         {renderTextInput('ownerCompanyId', true, props)}
         {/*renderTextInput('externalAccessCompanyId', false, props)*/}
          {/*renderComplexDropdown('ownerCompany', integrationAssetCompanies, integrationAssetCompaniesLoading, 'companyName', props)*/}
          {renderComplexDropdown('externalCompany', companies, companiesLoading, 'name', props)}
         {renderCheckbox('read', false, props)}
         {renderCheckbox('write', false, props)}
        </StyledInnerForm>
      </StyledForm>
    );
  }
};

export default React.memo(IntegrationAssetPermissionsForm);
