// Colour handler
export const getLightDarkColor = (color: string) => {
  function pad(num: string, totalChars: number) {
    num = `${num}`;
    while (num.length < totalChars) {
      num = `0${num}`;
    }
    return num;
  }

  // Ratio is between 0 and 1
  function changeColor(selectedColor: string, ratio: number, darker: boolean) {
    // Trim trailing/leading whitespace
    selectedColor = selectedColor.replace(/^\s*|\s*$/, '');

    // Expand three-digit hex
    selectedColor = selectedColor.replace(/^#?([a-f0-9])([a-f0-9])([a-f0-9])$/i, '#$1$1$2$2$3$3');

    // Calculate ratio
    const difference = Math.round(ratio * 256) * (darker ? -1 : 1);

    // Determine if input is RGB(A)
    const fetchedRgb = selectedColor.match(
      new RegExp(
        '^rgba?\\(\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '\\s*,\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '\\s*,\\s*' +
          '(\\d|[1-9]\\d|1\\d{2}|2[0-4][0-9]|25[0-5])' +
          '(?:\\s*,\\s*' +
          '(0|1|0?\\.\\d+))?' +
          '\\s*\\)$',
        'i',
      ),
    );

    const alpha = !!fetchedRgb && fetchedRgb[4] != null ? fetchedRgb[4] : null;

    // Convert hex to decimal
    const decimal = fetchedRgb
      ? [fetchedRgb[1], fetchedRgb[2], fetchedRgb[3]]
      : selectedColor
          .replace(
            /^#?([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])([a-f0-9][a-f0-9])/i,
            (...args: any[]) => `${parseInt(args[1], 16)},${parseInt(args[2], 16)},${parseInt(args[3], 16)}`,
          )
          .split(/,/);

    // Return RGB(A)
    return fetchedRgb
      ? `rgb${alpha !== null ? 'a' : ''}(${Math[darker ? 'max' : 'min'](
          parseInt(decimal[0], 10) + difference,
          darker ? 0 : 255,
        )}, ${Math[darker ? 'max' : 'min'](parseInt(decimal[1], 10) + difference, darker ? 0 : 255)}, ${Math[
          darker ? 'max' : 'min'
        ](parseInt(decimal[2], 10) + difference, darker ? 0 : 255)}${alpha !== null ? `, ${alpha}` : ''})`
      : // Return hex
        [
          '#',
          pad(Math[darker ? 'max' : 'min'](parseInt(decimal[0], 10) + difference, darker ? 0 : 255).toString(16), 2),
          pad(Math[darker ? 'max' : 'min'](parseInt(decimal[1], 10) + difference, darker ? 0 : 255).toString(16), 2),
          pad(Math[darker ? 'max' : 'min'](parseInt(decimal[2], 10) + difference, darker ? 0 : 255).toString(16), 2),
        ].join('');
  }

  const subbedColor = color.substring(1); // strip #
  const rgb = parseInt(subbedColor, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // ITU-R BT.709

  if (luma < 180) {
    return changeColor(subbedColor, 0.8, false);
  } else {
    return changeColor(subbedColor, 0.8, true);
  }
};
