import * as TYPE from './types';

import { ApplicationState } from '..';

export const mapbox_tokenReducer = (
  state: ApplicationState['mapbox_token'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.MapActions,
): ApplicationState['mapbox_token'] => {
  switch (action.type) {
    case TYPE.MAPBOX_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.MAPBOX_TOKEN_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_MAPBOX_TOKEN:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};

export const latest_positionsReducer = (
  state: ApplicationState['latest_positions'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.MapActions,
): ApplicationState['latest_positions'] => {
  switch (action.type) {
    case TYPE.LATEST_POSITIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.LATEST_POSITIONS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_LATEST_POSITIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};

export const historic_positionsReducer = (
  state: ApplicationState['historic_positions'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.MapActions,
): ApplicationState['historic_positions'] => {
  switch (action.type) {
    case TYPE.HISTORIC_POSITIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.LATEST_POSITIONS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.GET_HISTORIC_POSITIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};

export const myfleet_imosReducer = (
  state: ApplicationState['myfleet_imos'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.MapActions,
): ApplicationState['myfleet_imos'] => {
  switch (action.type) {
    case TYPE.MYFLEET_IMOS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.MYFLEET_IMOS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.MYFLEET_IMOS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    default:
      return { ...state };
  }
};
