import { faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  getNominationStatusIcon,
  Icon,
  NegotiationState
} from '@teqplay/chorus-components'
import Button from '@teqplay/teqplay-ui'
import React, { useState } from 'react'
import { IFuelbossNegotiationPermissions } from './NegotiationActions'

interface OnBehalfDialogContentProps {
  permissions: IFuelbossNegotiationPermissions
  error?: string
  customerCompanyName: string
  activateEditMode: (type: 'EDIT' | 'EDIT_ON_BEHALF') => void
  changeStateWhenValid: (state: NegotiationState, onBehalf: boolean, reason?: string) => void
  setActiveDialog: (
    activeDialog: 'ACCEPT' | 'REJECT' | 'CANCEL' | 'COMPLETE' | 'FINALISE' | 'ON_BEHALF' | undefined
  ) => void
}

const pencilIcon = findIconDefinition({ prefix: 'fal', iconName: 'pencil' });

const OnBehalfDialogContent: React.FunctionComponent<OnBehalfDialogContentProps> = (props) => {
  const { permissions } = props
  const [cancelOnBehalfActive, setCancelOnBehalfActive] = useState(false)

  return (
    <div className="onbehalf-dialog-content">
      {cancelOnBehalfActive && (
        <>
          <p>Are you sure you want to cancel the nomination? This action can not be undone.</p>

          <div className="onbehalf-actions-wrapper">
            <Button
              danger
              preventDoubleClick
              outline
              onClick={() => {
                props.changeStateWhenValid('CANCELLED', true)
              }}
            >
              {getNominationStatusIcon('CANCELLED')}
              Cancel Nomination
            </Button>
          </div>
        </>
      )}

      {!cancelOnBehalfActive && (
        <>
          <p>
            This function permits you to make changes to the Buyer's ({props.customerCompanyName}) nomination request.{' '}
            <span className="bold-underline">It does not</span> create an agency relationship between you and your
            customer, and <span className="bold-underline">does not</span> bind your customer to these changes unless
            separately agreed by your customer.
          </p>

          <span>
            <b>For your customer you can:</b>
          </span>

          <div className="onbehalf-actions-wrapper">
            <Button
              primary
              preventDoubleClick
              outline
              className="edit-onbehalf-button"
              onClick={() => {
                props.activateEditMode('EDIT_ON_BEHALF')
              }}
            >
              <Icon>
                <FontAwesomeIcon icon={pencilIcon} />
              </Icon>
              Edit nomination
            </Button>

            {permissions.canAcceptOnBehalf && (
              <Button
                primary
                preventDoubleClick
                className="accept-onbehalf-button"
                outline
                success
                onClick={() => {
                  props.changeStateWhenValid('ACCEPTED', true)
                }}
              >
                {getNominationStatusIcon('ACCEPTED')}
                Accept nomination
              </Button>
            )}

            {permissions.canRejectOnBehalf && (
              <Button
                danger
                preventDoubleClick
                className="reject-onbehalf-button"
                outline
                onClick={() => {
                  props.changeStateWhenValid('REJECTED', true)
                }}
              >
                {getNominationStatusIcon('REJECTED')}
                Reject nomination
              </Button>
            )}

            {permissions.canCancelOnBehalf && (
              <Button
                danger
                preventDoubleClick
                outline
                onClick={() => {
                  setCancelOnBehalfActive(true)
                }}
              >
                {getNominationStatusIcon('CANCELLED')}
                Cancel nomination
              </Button>
            )}
          </div>

          {props.error && <p className="status error">{props.error}</p>}
        </>
      )}

      <div
        className="exit-onbehalf"
        onClick={() => {
          props.setActiveDialog(undefined)
        }}
      >
        <p>Exit and Return to prior view</p>
      </div>
    </div>
  )
}

export default OnBehalfDialogContent
