import React from 'react';
import { FuelBossLocation } from '../../../../../store/FuelbossLocations/models';
import FormikWrapper from '../Formik/FormikWrapper';

interface OwnProps {
  addNewLocation: (location: FuelBossLocation) => void;
}

const AddLocation: React.FC<OwnProps> = ({ addNewLocation }) => {
  const submitLocation = (location: FuelBossLocation) => {
    addNewLocation(location);
  };

  return <FormikWrapper header="Add location" addLocation={submitLocation} />;
};

export default React.memo(AddLocation);
