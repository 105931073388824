import {
  getCompanyDetails,
  getCompanyName,
  isOneOf,
  IPromptNomination,
  ICompany,
  INominationEnquiryEvent,
} from '@teqplay/chorus-components';
import { formatDateTime } from '@teqplay/teqplay-ui';
import { capitalize } from 'lodash';
import * as React from 'react';
import { RecurringNomination } from '../../../../../store/RecurringNomination/models';
import TitleBox from '../TitleBox/TitleBox';
interface IProps {
  selectedNomination?: IPromptNomination | INominationEnquiryEvent | RecurringNomination;
  companies: ICompany[];
}
const ScheduleInformation: React.FunctionComponent<IProps> = (props) => {
  const { selectedNomination, companies } = props;
  const customerCompanyId = getCustomerCompanyId();
  const customerCompanyName = customerCompanyId
    ? getCompanyDetails(props.companies, customerCompanyId).companyName
    : 'customer';
  const recipientCompanyId =
    selectedNomination && selectedNomination.recipient ? selectedNomination.recipient.companyId : undefined;
  const recipientCompanyName =
    recipientCompanyId && props.companies.find((c) => c._id === recipientCompanyId)
      ? getCompanyDetails(props.companies, recipientCompanyId).companyName
      : undefined;
  const madeByText = `${selectedNomination?.author?.name}(${getCompanyName(
    //@ts-ignore
    selectedNomination ? selectedNomination : null,
    companies,
  )})${
    //@ts-ignore
    selectedNomination && selectedNomination.onBehalf ? ` for customer ${customerCompanyName}` : ''
  }`;
  const recipientText = `${
    selectedNomination?.recipient
      ? `${selectedNomination.recipient.name} ${recipientCompanyName ? `(${recipientCompanyName})` : ''}`
      : ''
  }`;
  const actionByText = `${
    selectedNomination?.recipient
      ? `${selectedNomination.recipient.name} ${recipientCompanyName ? `(${recipientCompanyName})` : ''}`
      : ''
    //@ts-ignore
  }${selectedNomination?.reactionOnBehalf ? ` for customer ${customerCompanyName}` : ''}`;
  return (
    <>
      {
        <div className="proposal-info-wrapper">
          {selectedNomination && selectedNomination.author && selectedNomination.author.name && (
            <TitleBox
              titleText={'Submitted by'}
              valueText={`${madeByText}, ${selectedNomination ? formatDateTime(selectedNomination.created) : ''}`}
            />
          )}
          {selectedNomination &&
            selectedNomination.state &&
            isOneOf(selectedNomination.state, ['RECURRING EVENT']) &&
            selectedNomination.recipient &&
            selectedNomination.recipient.name && (
              <TitleBox
                titleText={'Recieved by'}
                valueText={`${recipientText}, ${
                  selectedNomination ? formatDateTime(selectedNomination.created) : ''
                }`}
              />
            )}
          {selectedNomination &&
            selectedNomination.state &&
            !isOneOf(selectedNomination.state, ['COUNTERED', 'PROPOSED', 'PENDING', 'RECURRING EVENT']) && (
              <TitleBox
                titleText={`${capitalize(selectedNomination.state)} by`}
                valueText={`${actionByText}, ${
                  selectedNomination ? formatDateTime((selectedNomination as IPromptNomination).reactionTime) : ''
                }`}
              />
            )}
        </div>
      }
    </>
  );
  function getCustomerCompanyId() {
    const latestProposal = props.selectedNomination;
    return latestProposal ? latestProposal.companyId : undefined;
  }
};
export default ScheduleInformation;
