import { ErrorActionPayload } from '../../models/ErrorAction';
import { ContractWrapperModel, ContractNotificationWrapperModel } from './models';

export const CONTRACT_LOADING = 'contract/loading';
export type CONTRACT_LOADING = typeof CONTRACT_LOADING;

export const GET_CONTRACTS = 'contract/get';
export type GET_CONTRACTS = typeof GET_CONTRACTS;

export const CONTRACTS_FETCH_ERROR = 'contract/fetchError';
export type CONTRACTS_FETCH_ERROR = typeof CONTRACTS_FETCH_ERROR;

export const DELETE_CONTRACT = 'contract/delete';
export type DELETE_CONTRACT = typeof DELETE_CONTRACT;

export const DELETE_CONTRACT_ERROR = 'contract/deleteError';
export type DELETE_CONTRACT_ERROR = typeof DELETE_CONTRACT_ERROR;

export const UPDATE_CONTRACT = 'contract/update';
export type UPDATE_CONTRACT = typeof UPDATE_CONTRACT;

export const UPDATE_CONTRACT_ERROR = 'contract/updateError';
export type UPDATE_CONTRACT_ERROR = typeof UPDATE_CONTRACT_ERROR;

export const ADD_CONTRACT = 'contract/add';
export type ADD_CONTRACT = typeof ADD_CONTRACT;

export const ADD_CONTRACT_ERROR = 'contract/addError';
export type ADD_CONTRACT_ERROR = typeof ADD_CONTRACT_ERROR;

export const CONTRACT_CLEAR_TOAST = 'Contract/ClearToast';
export type CONTRACT_CLEAR_TOAST = typeof CONTRACT_CLEAR_TOAST;

export const NOTIFY_CONTRACT = 'contract/notify';
export type NOTIFY_CONTRACT = typeof NOTIFY_CONTRACT;

export const NOTIFY_CONTRACT_ERROR = 'contract/notifyError';
export type NOTIFY_CONTRACT_ERROR = typeof NOTIFY_CONTRACT_ERROR;

export type ContractActions =
  | {
      type: GET_CONTRACTS;
      payload: ContractWrapperModel['data'];
    }
  | {
      type: CONTRACT_LOADING;
    }
  | {
      type: CONTRACTS_FETCH_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: NOTIFY_CONTRACT;
      payload: ContractNotificationWrapperModel['data'];
    }
  | {
      type: NOTIFY_CONTRACT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: DELETE_CONTRACT;
      payload: ContractWrapperModel['data'];
    }
  | {
      type: DELETE_CONTRACT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: UPDATE_CONTRACT;
      payload: ContractWrapperModel['data'];
    }
  | {
      type: UPDATE_CONTRACT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: ADD_CONTRACT;
      payload: ContractWrapperModel['data'];
    }
  | {
      type: ADD_CONTRACT_ERROR;
      payload: ErrorActionPayload;
    }
  | {
      type: CONTRACT_CLEAR_TOAST;
    };
