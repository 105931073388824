import * as TYPE from './types';

import { ApplicationState } from '..';

export const documentFilterBookmarkReducer = (
  state: ApplicationState['document_filterbookmarks'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: [],
    metadata: {},
  },
  action: TYPE.DocumentFilterBookmarkActions,
): ApplicationState['document_filterbookmarks'] => {
  switch (action.type) {
    case TYPE.DOCUMENTFILTERBOOKMARKS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case TYPE.GET_DOCUMENTFILTERBOOKMARKS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.DOCUMENTFILTERBOOKMARKS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
        toastData: {
          content: 'Something went wrong! We could not fetch your filter bookmarks.',
          type: 'error',
        },
      };
    case TYPE.ADD_DOCUMENTFILTERBOOKMARK:
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        error: false,
        toastData: {
          content: 'Your filter bookmark was saved.',
          type: 'info',
        },
      };

    case TYPE.DOCUMENTFILTERBOOKMARK_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: 'Something went wrong! We could not save your filter bookmark.',
          type: 'error',
        },
      };
    case TYPE.EDIT_DOCUMENTFILTERBOOKMARK:
      return {
        ...state,
        data: state.data.map((v) => (v.id === action.payload.id ? action.payload : v)),
        loading: false,
        error: false,
        toastData: {
          content: 'Your filter bookmark was saved.',
          type: 'info',
        },
      };
    case TYPE.DOCUMENTFILTERBOOKMARK_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: 'Something went wrong! We could not save your filter bookmark.',
          type: 'error',
        },
      };

    case TYPE.DELETE_DOCUMENTFILTERBOOKMARK:
      return {
        ...state,
        data: state.data.filter((v) => v.id !== action.payload),
        loading: false,
        error: false,
        toastData: {
          content: 'Your filter bookmark was deleted.',
          type: 'info',
        },
      };
    case TYPE.DOCUMENTFILTERBOOKMARK_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: 'Something went wrong! We could not delete your filter bookmark.',
          type: 'error',
        },
      };

    case TYPE.DOCUMENTFILTERBOOKMARK_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    default:
      return { ...state };
  }
};
