import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './EditById.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button } from 'reactstrap';

const edit = findIconDefinition({ prefix: 'fal', iconName: 'pen' });

interface OwnProps {
  redirectUrl: string;
}

const EditById: React.FC<OwnProps> = ({ redirectUrl }) => {
  const history = useHistory();

  return (
    <>
      <Button className={styles['btn-edit']} onClick={() => history.push(redirectUrl)}>
        <FontAwesomeIcon icon={edit} />
      </Button>
    </>
  );
};

export default React.memo(EditById);
