import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Form, FormikProps } from 'formik';
import { Contract, ContractBunkerLocation } from '../../../../../store/Contract/models';
import { ICompany } from '@teqplay/chorus-components';
import { Company } from '../../../../../store/Company/models';
import contractConfigs from './contractConfigs';
import {
  renderCheckbox,
  renderDatepicker,
  renderTextInput,
  renderNumericInput,
  renderTextAreaInput,
  renderSimpleDropdown,
  renderLabel,
} from '../../../../Form/FormInputs';
import { FormHeader } from '../../../../Form/FormHeader';
import { ApplicationState } from '../../../../../store';
import { getFuelbossLocations } from '../../../../../store/FuelbossLocations/actions';
import { getLocations } from '../../../../../store/ChorusLocations/actions';
import { ILocation } from '@teqplay/chorus-components';
import { Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';

import { FieldHookConfig, useField } from 'formik';
import SelectReact, { ReactSelectProps } from 'react-select';
import { useHistory } from 'react-router-dom';
import { number } from 'prop-types';
import FilesUpload from '../../../../../components/FilesUpload/FilesUpload';
import {
  getContractDocuments,
  deleteContractDocuments,
  addContractDocuments,
} from '../../../../../store/ContractDocuments/actions';
import { ContractDocument } from '../../../../../store/ContractDocuments/models';
import { checkCompanyAdmin, checkShipOwner, checkSupplier, checkSystemAdmin } from '../../../../../helpers/roleChecks';

const MultiSelect: React.FC<OwnProps & Omit<ReactSelectProps, 'options'> & FieldHookConfig<any>> = (props) => {
  const [field, _, helper] = useField(props);
  //@ts-ignore
  const { options, multi, onInputChange, onChange } = props;
  return (
    <SelectReact
      {...field}
      {...props}
      options={
        options
          ? options.map((v: any) => {
              return { label: v.label, value: v.value };
            })
          : []
      }
      onInputChange={onInputChange}
      onChange={(value) => {
        if (multi) helper.setValue(value ? value : []);
        else helper.setValue(value);
        if (onChange) onChange(value);
      }}
      value={field.value}
    />
  );
};
const trash = findIconDefinition({ prefix: 'fal', iconName: 'trash' });

const StyledInnerForm = styled.div`
  flex: 4;
  width: 60%;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;

  @media (max-width: 1920px) {
    width: 80%;
  }
  .contractFormEntry {
    //display: flex;
    //flex-wrap: wrap;
    //margin-bottom: 1em;
    //padding-bottom: 1em;
  }
  .locationsMultiselect {
    width: 100%;
    padding-right: 35px;
    height: 49px;
    margin-bottom: 1rem;
    div[class*='-control'] {
      height: 49px;
      border-radius: 0px;
    }
    div[class*='-ValueContainer'] {
      height: 100%;
      overflow-y: scroll;
    }
    div[class*='-multiValue'] > div:first-child {
      border-radius: 2px;
      color: #333;
      font-size: 0.9rem;
      overflow: hidden;
      padding: 1px 1px 1px 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }
    div[class*='-multiValue'] > div:last-child {
      //width: 16px;
      height: 16px !important;
      background-color: #d6f0f9;
      align-items: center;
      border-radius: 50%;
      box-sizing: border-box;
      text-align: center;
      margin: auto 3px;
      transition: 0.3s all;
    }
    div[class*='-multiValue'] > div:last-child:hover {
      background-color: #fff;
      color: #24365c;
      cursor: pointer;
    }
    div[class*='-multiValue'] > div:last-child > svg {
      display: block;
    }
  }
  .locationSection {
    margin-bottom: 20px;
    width: 100%;
    padding-right: 3em;
  }
  .locationName {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    margin-bottom: 10px;
  }
  .locationDetails {
    display: flex;
  }
  .locationSectionContent {
    padding: 15px 10px 0px;
    background-color: rgb(245, 245, 245);
    position: relative;
  }
  .locationSectionContentActive {
    padding: 15px 10px 0px;
    background-color: rgb(229, 245, 251);
    position: relative;
  }
  .locationSectionContent input {
    display: block;
    width: 100%;
    height: 49px;
    margin-bottom: 1em;
    border: 1px solid #ccc;
    line-height: unset;
    padding-left: 5px;
    border-radius: 0px;
  }
  .priceForPort {
    width: 40%;
    margin-right: 20px;
  }
  .allowedBunkeringTime {
    width: 45%;
    margin-right: 20px;
  }
  .deleteLocationButton {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #fff;
    stroke-width: 1;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    position: absolute;
    top: 10px;
    right: 0px;
  }
`;

const StyledFormSectionHeader = styled.div`
  color: rgb(0, 0, 0);
  line-height: 24px;
  font-weight: bold;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: left;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  font-size: 24px;
  margin: 15px 0px 10px;
  :before {
    content: '';
    border-top: 1px solid rgb(204, 204, 204);
    margin: 0px 10px 0px 0px;
    flex: 0 0 43px;
  }
  :after {
    content: '';
    border-top: 1px solid rgb(204, 204, 204);
    margin: 0px 20px 0px 10px;
    flex: 1 0 20px;
  }
`;

const StyledFormSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div {
    flex: 1 !important;
  }
`;

const StyledFormSectionCollumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 56px;
  width: 720px;
`;

const StyledFormSectionLocation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px !important;
`;

const StyledDocumentsContainer = styled.div`
  flex: 2 1 0%;
  height: fit-content;
  margin-top: 27px;
  background-color: rgb(229, 245, 251);
  padding: 15px 30px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  .documentsHeader {
    font-family: Roboto;
    font-size: 1.4em;
    font-weight: 600;
    text-decoration: none solid rgb(0, 0, 0);
    color: rgb(0, 0, 0) !important;
    margin-bottom: 10px;
  }
  div[class^='FilesUpload_files-container'] {
    background-color: #ffffff !important;
    height: 150px;
  }
  div[class^='FilesUpload_files-container']:hover {
    background: rgb(249, 249, 249) !important;
  }
  div[class*='FilesUpload_files-container'] svg {
    color: #24365c !important;
  }
  div[class^='FilesUpload_drag-files-message'] span {
    color: #24365c !important;
  }
  div[class*='FilesUpload_drag-files-message'] a {
    color: #24365c !important;
  }
  div[class*='FilesUpload_delete-icon-container'] {
    color: #24365c !important;
  }
  div[class*='FilesUpload_delete-icon-container'] svg {
    color: #24365c !important;
  }
  div[class*='FilesUpload_file-upload'] a {
    color: #24365c !important;
  }
`;

const StyledFormContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledForm = styled.div`
  margin-top: 1em;
  .formHeader {
    flex: 3 1 0%;
    font-weight: 600;
    font-size: 1.75em;
    padding-left: 5px;
  }
  .buttonContainer {
    display: flex;
    flex: 0 1 0%;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: right;
    padding: 10px 56px;
  }
  .buttonContainer button {
    justify-self: flex-end;
    font-weight: 600;
    border-radius: 5px;
    padding: 8px 18px;
    font-size: 18px;
  }
  div[class^='input-'] {
    flex: 0 1 40%;
    margin-right: 35px;
    &#datePicker {
      flex: 0 22.3%;
      margin-right: 0;
    }
  }
  button {
    justify-self: flex-end;
    margin-right: 0.5em;
  }
  .react-datepicker-wrapper input {
    border-radius: 0;
    margin-left: 0;
    height: 36px;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  button.btn-primary,
  button.btn-secondary {
    display: flex !important;
    -webkit-box-pack: end !important;
    justify-content: right !important;
    width: 100px !important;
    float: none !important;
  }
`;

interface OwnProps {
  contract?: Contract;
  header: string;
  saveMethod: (contract: Contract) => void;
  systemAdmin?: boolean;
  customers: ICompany[];
  suppliers: ICompany[];
  company?: Company;
  formikProps: FormikProps<Contract>;
  navAdminType: string;
}

const ContractForm: React.FC<OwnProps> = ({
  contract,
  header,
  saveMethod,
  suppliers,
  customers,
  company,
  formikProps,
  navAdminType,
}) => {
  const {
    auth,
    locationLoading,
    location,
    locationIsLoaded,
    documentsLoading,
    documents,
    documentsIsLoaded,
  } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
    locationLoading: state.location.loading,
    locationError: state.location.error,
    locationErrorDetails: state.location.errorDetails,
    location: state.location.data,
    locationIsLoaded: state.location.dataIsLoaded,
    documentsLoading: state.contract_documents.loading,
    documentsError: state.contract_documents.error,
    documentsErrorDetails: state.contract_documents.errorDetails,
    documents: state.contract_documents.data,
    documentsIsLoaded: state.contract_documents.dataIsLoaded,
  }));

  const isSupplier = () => (((company || {}).category || {}).chorusType || '').toUpperCase() === 'VENDOR';
  const dispatch = useDispatch();
  const history = useHistory();
  const getLocationCallback = useCallback(() => dispatch(getFuelbossLocations()), []);
  const getChorusLocationsCallback = React.useCallback(() => dispatch(getLocations()), []);

  const getContractDocumentsCallback = React.useCallback((contractId: string) => {
    dispatch(getContractDocuments(contractId));
  }, []);

  const addContractDocumentsCallback = useCallback(
    (contractId: string, document: ContractDocument) =>
      dispatch(addContractDocuments(contractId, document, () => console.log('document added'))),
    [],
  );

  const deleteContractDocumentsCallback = useCallback(
    (contractId: string, fileName: string) => dispatch(deleteContractDocuments(contractId, fileName)),
    [],
  );

  useEffect(() => {
    if (company && company.chorusId) {
      //only for add contract
      if (header.toLowerCase().includes('add')) {
        if (isSupplier()) {
          formikProps.setFieldValue('supplierId', company.chorusId);
        } else {
          formikProps.setFieldValue('customerId', company.chorusId);
        }
      }
    }
  }, [company]);

  useEffect(() => {
    if (!locationIsLoaded) {
      getChorusLocationsCallback();
    }
    if (contract && contract.contractId) {
      //load documents if not a new contract
      getContractDocumentsCallback(contract.contractId);
    }
    formikProps.validateForm();
    return () => {
      if (!contract) {
        formikProps.resetForm();
      }
    };
  }, []);

  useEffect(() => {
    if (
      contract &&
      contract.contractId &&
      contract.contractId !== '00000000-0000-0000-0000-000000000000' &&
      !documentsLoading
    ) {
      //load documents if not a new contract
      getContractDocumentsCallback(contract.contractId);
    }
  }, [contract]);

  useEffect(() => {
    if (contract && contract.contractBunkerLocations && locationIsLoaded && !bunkerLocationsLoaded) {
      //reload contract locations
      setContractBunkerLocations(contract.contractBunkerLocations);
      const loadLocationsList = location.filter((x) =>
        contract.contractBunkerLocations.map((t) => t.chorusLocationId).includes(x._id),
      );
      formikProps.setFieldValue('contractBunkerLocations', addNameValuesForBunkerLocations(loadLocationsList));
      setBunkerLocationsLoaded(true);
    }
  }, [contract, locationIsLoaded]);

  const onFormSave = () => {
    formikProps.submitForm();

    const contractToSave: Contract = {
      ...formikProps.values,
      contractBunkerLocations: contractBunkerLocations,
    };
    if (contract) {
      contractToSave.contractId = contract.contractId;
    }
    if (formikProps.isValid) {
      saveMethod(contractToSave);
    }
  };

  const { errors, touched, setFieldValue, setFieldTouched } = formikProps;
  const props = { config: contractConfigs, errors, touched, setFieldValue, setFieldTouched };

  const [locationsArray, setLocationsArray] = React.useState<ILocation[]>();
  const currencyOptions: object[] = (contractConfigs.currency || {}).selections;
  const unitOptions: object[] = (contractConfigs.unit || {}).selections;
  const calorificBasisOptions: object[] = (contractConfigs.calorificBasis || {}).selections;
  const [contractBunkerLocations, setContractBunkerLocations] = React.useState<ContractBunkerLocation[]>([]);
  const [bunkerLocationsLoaded, setBunkerLocationsLoaded] = React.useState<boolean>(false);
  const [activeBunkerLocationId, setActiveBunkerLocationId] = React.useState('');

  const bunkerLocationExists = (chorusLocationId: string) => {
    const locations = contractBunkerLocations.filter((x) => x.chorusLocationId == chorusLocationId);
    if (locations && locations.length == 1) {
      return true;
    }
    return false;
  };

  const addContractBunkerLocations = (chorusLocationId: string) => {
    let locations = contractBunkerLocations;
    let bunkerLoc: ContractBunkerLocation = {
      id: '00000000-0000-0000-0000-000000000000',
      chorusLocationId: chorusLocationId,
      contractId: contract && contract.contractId ? contract.contractId : '00000000-0000-0000-0000-000000000000',
      allowedBunkeringTime: 0,
      price: '',
    };

    locations.push(bunkerLoc);
    setContractBunkerLocations(locations);
  };

  const removeContractBunkerLocations = (chorusLocationId: string) => {
    let locations = contractBunkerLocations;
    locations = locations.filter((x) => x.chorusLocationId != chorusLocationId);
    setContractBunkerLocations(locations);
  };
  const onDeleteContractLoccationClick = (bunkerLocation: ContractBunkerLocation) => {
    const previousLocations = formikProps.values.contractBunkerLocations;
    formikProps.setFieldValue(
      'contractBunkerLocations',
      //@ts-ignore
      previousLocations.filter((x) => x.value != bunkerLocation.chorusLocationId),
    );

    removeContractBunkerLocations(bunkerLocation.chorusLocationId);
  };

  const onChangePortPrice = (bunkerLocation: ContractBunkerLocation, value: string) => {
    let bunkerLocations = contractBunkerLocations;
    //find and update object in bunker locations
    let objIndex = bunkerLocations.findIndex((obj) => obj.chorusLocationId == bunkerLocation.chorusLocationId);
    if (objIndex != -1) {
      bunkerLocations[objIndex].price = value;
    }
    setContractBunkerLocations(bunkerLocations);
  };

  const onChangePortBunkerTime = (bunkerLocation: ContractBunkerLocation, value: string) => {
    let bunkerLocations = contractBunkerLocations;
    //find and update object in bunker locations
    let objIndex = bunkerLocations.findIndex((obj) => obj.chorusLocationId == bunkerLocation.chorusLocationId);
    if (objIndex != -1) {
      bunkerLocations[objIndex].allowedBunkeringTime = Number(value);
    }
    setContractBunkerLocations(bunkerLocations);
  };

  const onFocus = (bunkerLocation: ContractBunkerLocation, value: string) => {
    setActiveBunkerLocationId(bunkerLocation.chorusLocationId);
  };

  const addNameValuesForBunkerLocations = (data: ILocation[]) => {
    const isChildValid = data && data instanceof Array && data.some((it) => it && (Object.keys(it) || []).length);
    if (!isChildValid) {
      return [];
    }
    const formatedData = (data || []).map((obj) => {
      let rObj = { label: '', value: '' };
      rObj.value = obj._id;
      rObj.label = obj.port + ', ' + obj.country;
      return rObj;
    });
    return formatedData;
  };

  useEffect(() => {
    //remove unselected location section
    if (formikProps.values.contractBunkerLocations) {
      const selectedLocations = [...formikProps.values.contractBunkerLocations];
      const toBeRomoved = contractBunkerLocations.filter(
        //@ts-ignore
        (x) => !selectedLocations.map((y) => y.value).includes(x.chorusLocationId),
      );
      if (selectedLocations.length) {
        toBeRomoved.forEach((element) => {
          removeContractBunkerLocations(element.chorusLocationId);
        });
      } else {
        setContractBunkerLocations([]);
      }
    } else {
      setContractBunkerLocations([]);
    }
  }, [formikProps.values.contractBunkerLocations]);

  const DisplayBunkerLocationOption = (bunkerLocation: ContractBunkerLocation) => {
    const selectedLocation = location.filter((x) => x._id == bunkerLocation.chorusLocationId)[0];
    return (
      <div className={'locationSection'} key={selectedLocation._id}>
        <div
          className={`${['locationSectionContent']} ${
            activeBunkerLocationId === selectedLocation._id ? ['locationSectionContentActive'] : ''
          }`}
        >
          <StyledFormSectionLocation>
            <div className={'locationName'}>{selectedLocation.port + ', ' + selectedLocation.country}</div>
            {isSupplier() && (
              <Button
                outline
                color="secondary"
                className="deleteLocationButton"
                onClick={() => onDeleteContractLoccationClick(bunkerLocation)}
              >
                <FontAwesomeIcon icon={trash} color="#000000" />
              </Button>
            )}
            <div className={'locationDetails'}>
              <div className={'priceForPort'}>
                {renderLabel('priceForPort', props.config)}
                <Input
                  type="text"
                  name="portPrice"
                  onFocus={(e) => onFocus(bunkerLocation, e.target.value)}
                  onChange={(e) => onChangePortPrice(bunkerLocation, e.target.value)}
                  defaultValue={bunkerLocation.price}
                  disabled={!isSupplier()}
                />
              </div>
              <div className={'allowedBunkeringTime'}>
                {renderLabel('allowedBunkeringTime', props.config)}
                <Input
                  type="number"
                  min="0"
                  name="portBunkeringTime"
                  id="name"
                  onFocus={(e) => onFocus(bunkerLocation, e.target.value)}
                  onChange={(e) => onChangePortBunkerTime(bunkerLocation, e.target.value)}
                  defaultValue={bunkerLocation.allowedBunkeringTime}
                  disabled={!isSupplier()}
                />
              </div>
            </div>
          </StyledFormSectionLocation>
        </div>
      </div>
    );
  };

  //@ts-ignore
  const handleLoadFile = async (file, fileType, fileReader) => {
    if (file == null || fileType == null || fileReader == null) {
      return;
    }
    const loadedFileContent = fileReader.result.split(',')[1];
    const contractDocument: ContractDocument = {
      file: loadedFileContent,
      mimeType: fileType,
      fileName: file.name,
      chorusId: '',
      contractId: '',
    };
    if (contract && contract?.contractId) addContractDocumentsCallback(contract?.contractId, contractDocument);
  };

  //@ts-ignore
  const handleDeleteFile = async (file, fileType) => {
    const fileName = file.name;
    if (contract && contract?.contractId) deleteContractDocumentsCallback(contract?.contractId, fileName);
  };

  const getDocs = (contractsDocuments: ContractDocument[]) => {
    const docs = contractsDocuments.map((doc, index) => {
      return {
        id: index,
        name: doc.fileName,
        url: `/api/Contract/getContractPdf/${contract?.contractId}/${doc.fileName}`,
        fileData: doc.file,
      };
    });
    return docs;
  };
  const disableSupplierId = checkSupplier(auth) && checkCompanyAdmin(auth) && !checkSystemAdmin(auth);
  const disableCustomerId = checkShipOwner(auth) && checkCompanyAdmin(auth) && !checkSystemAdmin(auth);

  const contractDocs = documents.filter((x) => x.chorusId == contract?.chorusId);
  return (
    <StyledForm>
      <FormHeader
        navAdminType={navAdminType}
        header={header}
        onFormSave={onFormSave}
        confirmButtonText="Save contract"
        cancelButtonText="Cancel"
        formUrl="contracts"
        showSaveAndCancelButtons={isSupplier() ? true : false}
      />
      <StyledFormContent>
        <StyledInnerForm as={Form}>
          <StyledFormSectionHeader>Contract partners</StyledFormSectionHeader>
          <StyledFormSectionCollumn className="contractFormEntry">
            {renderSimpleDropdown('supplierId', suppliers, !!contract || disableSupplierId, '_id', props)}
            {renderSimpleDropdown('customerId', customers, !!contract || disableCustomerId, '_id', props)}
            {renderTextInput('contractRef', !isSupplier(), props)}
            {renderTextInput('name', !isSupplier(), props)}
          </StyledFormSectionCollumn>
          <StyledFormSectionHeader>Contract period</StyledFormSectionHeader>
          <StyledFormSectionCollumn>
            <StyledFormSectionRow>
              {renderDatepicker(
                'startDate',
                contract?.status == 'Confirmed' || contract?.hasBeenAccepted || !isSupplier(),
                contractConfigs,
              )}
              {renderDatepicker(
                'endDate',
                contract?.status == 'Confirmed' || contract?.hasBeenAccepted || !isSupplier(),
                contractConfigs,
              )}
            </StyledFormSectionRow>
            <StyledFormSectionRow>
              {renderDatepicker(
                'endDateOption',
                contract?.status == 'Confirmed' || contract?.hasBeenAccepted || !isSupplier(),
                contractConfigs,
              )}
              {renderDatepicker('signedDate', !isSupplier(), contractConfigs)}
            </StyledFormSectionRow>
          </StyledFormSectionCollumn>
          <StyledFormSectionHeader>Price & quantities</StyledFormSectionHeader>
          <StyledFormSectionCollumn>
            {renderTextInput('pricePerUnit', !isSupplier(), props)}
            <StyledFormSectionRow>
              {renderSimpleDropdown('unit', unitOptions, !isSupplier(), '_id', props)}
              {renderSimpleDropdown('currency', currencyOptions, !isSupplier(), '_id', props)}
            </StyledFormSectionRow>
            <StyledFormSectionRow>
              {renderNumericInput('quantityMinimum', !isSupplier(), props)}
              {renderNumericInput('quantityOption', !isSupplier(), props)}
            </StyledFormSectionRow>
            {renderSimpleDropdown('calorificBasis', calorificBasisOptions, !isSupplier(), '_id', props)}
            {renderTextAreaInput('notes', !isSupplier(), props)}
          </StyledFormSectionCollumn>
          <StyledFormSectionHeader>Location</StyledFormSectionHeader>
          <StyledFormSectionCollumn>
            {props.config && renderLabel('contractBunkerLocations', props.config)}
            {isSupplier() && (
              <MultiSelect
                className="locationsMultiselect"
                id="contractBunkerLocations"
                name="contractBunkerLocations"
                placeholder="Search location"
                //@ts-ignore
                isMulti
                options={locationsArray}
                onChange={(e: any) => {
                  setLocationsArray([]);
                  //add newly selected location
                  if (e) {
                    e.forEach((element: any) => {
                      if (!bunkerLocationExists(element.value)) {
                        addContractBunkerLocations(element.value);
                      }
                    });
                  }
                }}
                onInputChange={(val: string) => {
                  if (val.length >= 3) {
                    const optionLocations = location.filter(
                      (x) =>
                        (x.port && x.port?.toLocaleLowerCase().includes(val)) ||
                        (x.country && x.country?.toLocaleLowerCase().includes(val)),
                    );
                    const options = addNameValuesForBunkerLocations(optionLocations);
                    //@ts-ignore
                    setLocationsArray(options);
                  }
                }}
              />
            )}
            {contractBunkerLocations &&
              contractBunkerLocations.map((item) => {
                return DisplayBunkerLocationOption(item);
              })}
            {renderTextInput('additionalFees', !isSupplier(), props)}
          </StyledFormSectionCollumn>
          <StyledFormSectionHeader>Invoice information</StyledFormSectionHeader>
          <StyledFormSectionCollumn>
            {renderTextAreaInput('invoiceAddress', !isSupplier(), props)}
            {renderTextInput('invoiceEmailAddress', !isSupplier(), props)}
            {renderTextInput('customerNumber', !isSupplier(), props)}
            {renderCheckbox('active', !isSupplier(), props)}
          </StyledFormSectionCollumn>
          {/* {renderCheckbox('negotiateOnPrice', props)} */}
          {isSupplier() && (
            <div className="buttonContainer">
              <Button color="primary" onClick={onFormSave}>
                Save contract
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  history.push(`/${navAdminType}admin/contracts`);
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </StyledInnerForm>
        {contract && contract.contractId !== '00000000-0000-0000-0000-000000000000' && (
          <StyledDocumentsContainer>
            <div className="documentsHeader">Documents</div>
            <FilesUpload
              files={contractDocs ? getDocs(contractDocs) : undefined}
              fileType={{
                id: 1,
                name: 'Contract Documents',
              }}
              acceptMimeTypes={[
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
                'application/vnd.ms-excel', // xls
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //.xlsx
                'application/msword', // doc
              ]}
              onLoadFile={handleLoadFile}
              onDeleteFile={handleDeleteFile}
              maxNumberFiles={5}
            />
          </StyledDocumentsContainer>
        )}
      </StyledFormContent>
    </StyledForm>
  );
};

export default React.memo(ContractForm);
