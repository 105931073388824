import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const value = typeof field.value === 'string' ? new Date(field.value) : field.value;

  return (
    <DatePicker
      {...field}
      {...props}
      selected={value}
      placeholderText="Please choose date"
      value={value}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

export default DatePickerField;
