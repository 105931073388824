import React from 'react';
import { Company } from '../../../../../store/Company/models';
import FormikWrapper from '../Formik/FormikWrapper';
import { Loading } from '../../../../Loading';

interface OwnProps {
  editCompany: (company: Company) => void;
  companies: Company[];
  companyId?: string;
  navAdminType: string;
  systemAdmin?: boolean;
  company?: Company;
  isLoading?: boolean;
  saveLogoMethod: (companyId: string, logo: string, mimeType: string) => void;
  companyLogo?: Uint8Array[];
}

const EditCompany: React.FC<OwnProps> = ({
  editCompany,
  isLoading,
  companyId,
  navAdminType,
  company,
  systemAdmin,
  companies,
  saveLogoMethod,
  companyLogo,
}) => {
  const submitCompany = (company: Company) => {
    editCompany(company);
  };

  if (!company && isLoading) {
    return <Loading />;
  }

  return (
    <FormikWrapper
      header="Edit company"
      company={company ? company : companies.find((it) => it.companyId === companyId)}
      addCompany={submitCompany}
      navAdminType={navAdminType}
      systemAdmin={systemAdmin}
      saveLogoMethod={saveLogoMethod}
      companyLogo={companyLogo}
    />
  );
};

export default React.memo(EditCompany);
