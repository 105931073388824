import React from 'react';
interface TitleBoxProps {
  titleText: string;
  valueText: string;
  className?: string;
}
const TitleBox: React.FunctionComponent<TitleBoxProps> = (props) => {
  return (
    <div className={`title-box ${props.className ? props.className : ''}`}>
      <span className="title-box-title">{props.titleText}</span>
      {props.valueText}
    </div>
  );
};
export default TitleBox;
