import React from 'react';
import {IntegrationAsset} from '../../../../../store/IntegrationAsset/models';
import {IntegrationAssetIssuer} from '../../../../../store/IntegrationAssetIssuer/models';

import * as Yup from 'yup';
import integrationAssetConfigs from '../IntegrationAssetForm/integrationAssetConfigs';
import { Formik } from 'formik';
import IntegrationAssetIssuersForm from '../IntegrationAssetForm/IntegrationAssetIssuersForm'; 
import integrationAssetIssuersFormValidation from '../FormValidations/integrationAssetIssuersFormValidation';


interface OwnProps {
  veracityAssetId: string;
  integrationAssetId: string;
  addNewIntegrationAssetIssuers: (integrationAssetIssuer: IntegrationAssetIssuer) => void;
  navAdminType: string;
  integrationAssetIssuers?: IntegrationAssetIssuer;
}

const AddIntegrationAssetIssuers: React.FunctionComponent<OwnProps> = ({ addNewIntegrationAssetIssuers, navAdminType, integrationAssetIssuers, veracityAssetId, integrationAssetId }) => {
  const submitIntegrationAssetIssuers = (integrationAssetIssuers: IntegrationAssetIssuer) => {
    addNewIntegrationAssetIssuers(integrationAssetIssuers);
  };

  const initialValues: IntegrationAssetIssuer = {
    integrationAssetId: integrationAssetId,
    veracityAssetId: veracityAssetId,
    assetIdIssuer: '',
  };

  return (
    <Formik
      initialValues={ integrationAssetIssuers? integrationAssetIssuers : initialValues}
      validationSchema={integrationAssetIssuersFormValidation}
      validateOnMount
      onSubmit={(values, actions: any) => {
        actions.validateForm();
        actions.setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <IntegrationAssetIssuersForm
          integrationAssetIssuers={integrationAssetIssuers}
          header={'Add integration asset issuers'}
          saveMethod={submitIntegrationAssetIssuers}
          systemAdminPage={true}
          formikProps={formikProps}
          navAdminType={navAdminType}
          integrationAssetId={integrationAssetId}
        />
      )}
    </Formik>
  );
};

export default React.memo(AddIntegrationAssetIssuers);
