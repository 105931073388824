import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AuthenticationServiceLayer,
  ICompany,
  ILocation,
  IShipWithId,
  IPromptNomination,
  IPipeline,
  IContract,
  IShipWithCompanyId,
  CopyFromPreviousNomination,
} from '@teqplay/chorus-components';
import { ErrorWrapper } from './../../../../models/ErrorAction';
import {
  useNominationService,
  CreateNomination,
  useLocationService,
  CachedLocationService,
} from '@teqplay/chorus-components';

import { useState } from 'react';
import { ApplicationState } from '../../../../store';
import { Loading } from '../../../../components/Loading/index';
import { Error } from './../../../../components/Error/index';
import './ChorusStyleOverrides.scss';
import { getNominationsInfiniteScroll } from '../../../../store/Nomination/actions';
import { canCreateNominationOnBehalf } from '../../../../helpers/roleChecks';
import { getVolumeStats } from '../../../../store/VolumeStats/actions';

interface OwnProps {
  chooseNomination: (nominationId: string) => void;
  bunkerShips: IShipWithCompanyId[];
  bunkerPipelines: IPipeline[];
  companies: ICompany[];
  locationService: CachedLocationService;
  contracts: IContract[];
  creatingNewNominationBool?: boolean;
  creatingNominationOnBehalfBool?: boolean;
  className?: string;
  yearMonth: string;
  vendorCompanies?: ICompany[];
  serviceLayer: AuthenticationServiceLayer;
}

const NewNomination: React.FC<OwnProps> = ({
  chooseNomination,
  bunkerShips,
  bunkerPipelines,
  companies,
  locationService,
  contracts,
  creatingNewNominationBool = false,
  creatingNominationOnBehalfBool = false,
  className,
  yearMonth,
  vendorCompanies,
  serviceLayer,
}) => {
  const { auth } = useSelector((state: ApplicationState) => ({
    auth: state.auth.data,
  }));
  const dispatch = useDispatch();
  const errorWrapperObject: ErrorWrapper = {};

  const getNominationCallback = React.useCallback(() => dispatch(getNominationsInfiniteScroll(0)), []);

  const successCallback = (
    action: 'AMEND' | 'COUNTER' | 'COMPLETE' | 'CREATE',
    updatedNomination: IPromptNomination,
  ) => {
    getNominationCallback();
    dispatch(getVolumeStats());
    if (action === 'CREATE') {
      chooseNomination(`${updatedNomination.eventId}`);
    }
  };
  const [loading, setLoading] = useState(false);

  const {
    latestNomination,
    nominationHistory,
    nominationSelection,
    updatePromptNomination,
    updatePromptNominationState,
    updateSandboxPromptNomination,
  } = useNominationService({
    nominationId: undefined,
    authenticationService: serviceLayer,
    userProfile: auth.chorusUserProfile,
    setLoading: setLoading,
    onNewerVersionAvailable: () => {
      console.log('there is a newer version of the nomination available.');
    },
    sandboxId: undefined,
    vendorCompanyId: auth.chorusUserProfile.companyId ? auth.chorusUserProfile.companyId : '',
    successCallback: successCallback,
  });

  const [copyFromNomination, setCopyFromNomination] = useState<IPromptNomination | undefined>();

  React.useEffect(() => {
    if (latestNomination && latestNomination.eventId) chooseNomination(latestNomination.eventId);
  }, [latestNomination]);

  if (errorWrapperObject.payload) {
    return <Error error={errorWrapperObject.payload} />;
  } else if (creatingNewNominationBool || creatingNominationOnBehalfBool) {
    return (
      <div className={className}>
        <div className={`sticky-top prompt-negotiation-wrapper`}>
          <div className="prompt-form">
            <div className="form-section create-nomination-header">
              <h2 className="form-header">
                Create new nomination{creatingNominationOnBehalfBool ? ' for customer' : ''}
              </h2>
              <CopyFromPreviousNomination
                callback={setCopyFromNomination}
                authenticationService={serviceLayer}
                userProfile={auth.chorusUserProfile}
              />
            </div>
          </div>
        </div>
        <CreateNomination
          userProfile={auth.chorusUserProfile}
          creatingNominationOnBehalf={creatingNominationOnBehalfBool}
          updatePromptNomination={updatePromptNomination}
          authenticationService={serviceLayer}
          companies={companies}
          //using location service instead of locationlist
          locationService={locationService}
          bunkerShips={bunkerShips}
          pipelines={bunkerPipelines}
          //vendorCompanies={vendorCompanies}
          showFuelbossStates={true}
          showDeliveryModeSelection={true}
          contractsList={contracts}
          enableAlternativeFuelSelector={true}
          copyFromNomination={copyFromNomination}
        />
      </div>
    );
  } else if (loading) {
    return <Loading type="Chorus API" />;
  } else {
    return null;
  }
};

export default NewNomination;
