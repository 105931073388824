import { StandardWrapper } from '../../models/Responses';
import { IntegrationEventType } from '../IntegrationEventTypes/models';

export type IntegrationAssetTopicWrapperModel = StandardWrapper<IntegrationAssetTopic[], {}>;
export type IntegrationAssetTopicResponseModel = IntegrationAssetTopic[];

export interface IntegrationAssetTopic {
  id?: string;  
  veracityAssetId: string; // fb_t_risavika
  dataStructure: string; // json object string
  timeStamp?: Date;
  topic: string; // fb_t_risavika_mass
  eventTypeId: string;
  dataEventType?: IntegrationEventType;
  integrationAssetId: string;
  dataType: TopicDataType;
}

export enum TopicDataType {
  QualityInbound = 0,
  CompositionInbound = 1,
  BunkerDeliveryNoteInbound = 2,
  BunkerDeliveryNoteOutbound = 3,
  BunkerDeliveryNoteDocumentOutbound = 4,
  NominationOutbound = 5,
  SupplierInternalOutbound = 6
  
}
