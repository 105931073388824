import { Dispatch } from 'redux';
import { MyFleetsActions, MYFLEETS_LOADING, MYFLEETS_FETCH_ERROR, GET_MYFLEETS } from './types';
import { api } from '../../helpers/api';
import { MyFleetsResponseModel } from './models';
import { Vessel } from '../Vessels/models';
export const getMyFleets = () => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .get(`/api/MyFleets/`)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};
export const addMyFleetItem = (vessel: Vessel, fleetid: string) => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .post(`/api/MyFleets/${fleetid}`, vessel)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};
export const deleteMyFleetItem = (id: string) => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  const url = `/api/MyFleets/${id}`;
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};

export const deleteMyFleet = (id: string) => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  const url = `/api/MyFleets/DeleteFleet/${id}`;
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .delete(url, id)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};

export const addMyFleet = (name: string) => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .post(`/api/MyFleets/Addfleet/${name}`, name)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};

export const setFleetVisibility = (id: string, visible: boolean) => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .put(`/api/MyFleets/EditFleetVisibility/${id}/${visible}`, id)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};

export const setFleetItemVisible = (id: string) => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .put(`/api/MyFleets/EditFleetItemVisibility/${id}`, id)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};

export const getMyFleetItems = () => (dispatch: Dispatch<MyFleetsActions>) => {
  dispatch({
    type: MYFLEETS_LOADING,
  });
  new api<MyFleetsResponseModel>({
    dispatch,
    errorType: MYFLEETS_FETCH_ERROR,
  })
    .get(`/api/MyFleets/GetMyFleetItems`)
    .then((response) => {
      dispatch({
        type: GET_MYFLEETS,
        payload: response,
      });
    });
};

// export const editMyFleetName = (name:string) => (dispatch: Dispatch<MyFleetsActions>) => {
//     dispatch({
//         type: MYFLEETS_LOADING,
//     });
//     new api<MyFleetsResponseModel>({
//         dispatch,
//         errorType: MYFLEETS_FETCH_ERROR,
//     })
//         .post(`/api/MyFleets/Addfleet/${name}`, name)
//         .then((response) => {
//             dispatch({
//                 type: GET_MYFLEETS,
//                 payload: response,
//             });
//         });
// };
