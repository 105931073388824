import React from 'react'

interface PermissionWarningProps {
  className?: string
}

const PermissionsWarning: React.FunctionComponent<PermissionWarningProps> = (props) => {
  return <div className={`permission-warning ${props.className || ''}`}>{props.children}</div>
}

export default PermissionsWarning
