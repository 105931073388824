import React from 'react';
import styles from './MinifiedNominationList.module.scss';
import { Nomination } from './../../../../../../store/Nomination/models';

interface OwnProps {
  nominations: Nomination[];
  activeNominationId: string;
}

const MinifiedNominationList: React.FC<OwnProps> = ({ nominations, activeNominationId }) => (
  <div className={`${styles['minified-nomination']}`}>
    <div className={styles['header']}>Bunker events</div>
    {nominations.map((nom) => {
      if (nom)
        return (
          <div
            className={`${styles['wrapper']} ${activeNominationId === nom.eventId ? styles['active'] : ''}`}
            key={nom._id}
          >
            <div className={`${styles['nomination-indicator']} ${styles[nom.attributes.fbStatus]}`}></div>
          </div>
        );
      else return null;
    })}
  </div>
);

export default MinifiedNominationList;
