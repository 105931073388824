import * as React from 'react';
import styles from './ErrorLayout.module.scss';
import { ReactComponent as DnvglLogo } from '../../svgs/dnvgl-logo.svg';

export default (props: { children?: React.ReactNode }) => (
  <div className={styles['error-layout']}>
    <div className={styles['header']}>
      <h3>FuelBoss</h3>
      <span className={styles.dnvgl}>
        <p>Powered By</p>
        <DnvglLogo className={styles['dnvgl-logo']} />
      </span>
    </div>
    <div className={styles['content']}>{props.children}</div>
  </div>
);
