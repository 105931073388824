import * as React from 'react';
import * as reactstrap from 'reactstrap';
import styles from '../Table.module.scss';
import { Input } from 'reactstrap';

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleDropdown from '../../../components/Form/SimpleDropdown';

const chevronLeft = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-left',
});
const chevronRight = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-right',
});
const chevronDoubleLeft = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-double-left',
});
const chevronDoubleRight = findIconDefinition({
  prefix: 'fal',
  iconName: 'chevron-double-right',
});

interface OwnProps {
  pageIndex: number;
  pageCount: number;
  gotoPage: (num: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  maxPage: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageSize: number;
  setPageSize: (num: number) => void;
  totalRecords: number;
}

const Pagination: React.FC<OwnProps> = ({
  pageIndex,
  pageCount,
  gotoPage,
  previousPage,
  nextPage,
  maxPage,
  canNextPage,
  canPreviousPage,
  pageSize,
  setPageSize,
  totalRecords,
}) => {
  const RenderPaginationButtons = (pageIndex: number, maxPage: number, gotoPage: any) => {
    let numbers;
    if (pageIndex === 1 && pageIndex + 2 <= maxPage) numbers = [pageIndex, pageIndex + 1, pageIndex + 2];
    else if (pageIndex === maxPage) numbers = [pageIndex - 2, pageIndex - 1, pageIndex];
    else numbers = [pageIndex - 1, pageIndex, pageIndex + 1];

    const buttons: JSX.Element[] = [];

    numbers.forEach((number) => {
      if (number > 0 && number <= maxPage) {
        buttons.push(
          <reactstrap.Button
            className={styles['pagination-button']}
            key={number}
            onClick={() => gotoPage(number - 1)}
            color="primary"
            outline={pageIndex !== number}
          >
            {number}
          </reactstrap.Button>,
        );
      }
    });

    return buttons;
  };

  return (
    <div>
      <span>
        <strong>
          Showing results: {totalRecords == 0 ? 0 : pageIndex * pageSize + 1} to{' '}
          {pageIndex * pageSize + pageSize < totalRecords ? pageIndex * pageSize + pageSize : totalRecords} of{' '}
          {totalRecords}
        </strong>{' '}
        {/* <strong>
            Page:{' '} {pageIndex + 1} {'/'} {maxPage}
          </strong>{' '} */}
      </span>
      <div className={styles['pagination-container']}>
        <div className={styles['pagination-div']}>
          Show:{' '}
          <Input
            type="select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className={styles['pagination-select']}
          >
            {[5, 10, 15, 20, 25, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </div>
        <reactstrap.ButtonGroup className={styles['buttons']}>
          <reactstrap.Button
            className={styles['pagination-button']}
            onClick={() => gotoPage(0)}
            outline
            color="primary"
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon icon={chevronDoubleLeft} />
          </reactstrap.Button>
          <reactstrap.Button
            className={styles['pagination-button']}
            onClick={() => previousPage()}
            outline
            color="primary"
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon icon={chevronLeft} />
          </reactstrap.Button>

          {RenderPaginationButtons(pageIndex + 1, maxPage, gotoPage)}
          <reactstrap.Button
            className={styles['pagination-button']}
            onClick={() => nextPage()}
            outline
            color="primary"
            disabled={!canNextPage}
          >
            <FontAwesomeIcon icon={chevronRight} />
          </reactstrap.Button>
          <reactstrap.Button
            className={styles['pagination-button']}
            onClick={() => gotoPage(pageCount - 1)}
            outline
            color="primary"
            disabled={!canNextPage}
          >
            <FontAwesomeIcon icon={chevronDoubleRight} />
          </reactstrap.Button>
        </reactstrap.ButtonGroup>

        <div className={styles['pagination-div']}>
          Page: {'  '}
          <Input
            type="number"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              if (page < 1) {
                gotoPage(0);
                e.target.value = '1';
              } else if (page == maxPage) {
                gotoPage(maxPage - 1);
                e.target.value = maxPage.toString();
              } else gotoPage(page);
            }}
            style={{ width: '50px' }}
          />
          {'of'} {maxPage}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
