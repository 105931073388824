import * as React from 'react';
import styles from './NavLink.module.scss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const NavLink = (props: { title: string; isExternalLink?: boolean; children: React.ReactNode; route: string }) => {
  const location = useLocation();
  const pageName = location.pathname.split('/')[1].toLowerCase().replace(' ', '');
  const titleName = props.title.toLowerCase().replace(' ', '');
  const active = titleName === pageName;

  if (props.isExternalLink) {
      return (
          <a className={`${styles['NavLink']}`} href={props.route} target="_blank">
              <span className={styles['body']}>
                  {props.children}
                  <span className={`${active ? styles.textActive : styles.text}`}>{props.title}</span>
              </span>
          </a>
      );
  }

  return (
    <Link className={`${styles['NavLink']} ${active ? styles.active : ''}`} to={props.route}>
      <span className={styles['body']}>
        {props.children}
        <span className={`${active ? styles.textActive : styles.text}`}>{props.title}</span>
      </span>
    </Link>
  );
};
