import * as TYPE from './types';

import { ApplicationState } from '..';
import { EmailSettings } from './models';

export const emailSettingsReducer = (
  state: ApplicationState['emailsettings'] = {
    loading: true,
    error: false,
    dataIsLoaded: false,
    data: {} as EmailSettings,
    metadata: {},
  },
  action: TYPE.EmailSettingsActions,
): ApplicationState['emailsettings'] => {
  switch (action.type) {
    case TYPE.EMAILSETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPE.EMAILSETTINGS_CLEAR_TOAST:
      return {
        ...state,
        toastData: undefined,
      };
    case TYPE.GET_EMAILSETTINGS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        dataIsLoaded: true,
      };
    case TYPE.EMAILSETTINGS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload,
      };
    case TYPE.ADD_EMAILSETTINGS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'EmailSettings successfully created!',
          type: 'success',
        },
      };

    case TYPE.EMAILSETTINGS_ADD_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not create the EmailSettings. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    case TYPE.EDIT_EMAILSETTINGS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'EmailSettings edited',
          type: 'info',
        },
      };
    case TYPE.EMAILSETTINGS_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not edit the EmailSettings. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };

    case TYPE.DELETE_EMAILSETTINGS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        toastData: {
          content: 'EmailSettings successfully deleted!',
          type: 'success',
        },
      };
    case TYPE.EMAILSETTINGS_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        toastData: {
          content: action.payload.message
            ? action.payload.message
            : `We could not delete the EmailSettings. Please contact FuelBoss support for more details`,
          type: 'error',
        },
      };
    

    default:
      return { ...state };
  }
};
