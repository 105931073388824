import { MyFleetsWrapperModel } from './models';
import { ErrorActionPayload } from '../../models/ErrorAction';
export const MYFLEETS_LOADING = 'myFleets/loading';
export type MYFLEETS_LOADING = typeof MYFLEETS_LOADING;
export const GET_MYFLEETS = 'myFleets/get';
export type GET_MYFLEETS = typeof GET_MYFLEETS;
export const MYFLEETS_FETCH_ERROR = 'myFleets/fetchError';
export type MYFLEETS_FETCH_ERROR = typeof MYFLEETS_FETCH_ERROR;
export const ADD_MYFLEETS = 'myFleets/add';
export type ADD_MYFLEETS = typeof ADD_MYFLEETS;
export const MYFLEETS_FETCH_SUCCESS = 'myFleets/fetchSuccess';
export type MYFLEETS_FETCH_SUCCESS = typeof MYFLEETS_FETCH_SUCCESS;
export const DELETE_MYFLEETS = 'myFleets/delete';
export type DELETE_MYFLEETS = typeof DELETE_MYFLEETS;
export const EDIT_MYFLEETS = 'myFleets/edit';
export type EDIT_MYFLEETS = typeof EDIT_MYFLEETS;
export type MyFleetsActions =
    | {
        type: GET_MYFLEETS;
        payload: MyFleetsWrapperModel['data'];
    }
    | {
        type: MYFLEETS_LOADING;
    }
    |
    {
        type: MYFLEETS_FETCH_ERROR;
        payload: ErrorActionPayload;
    }
    | {
        type: ADD_MYFLEETS;
        payload: MyFleetsWrapperModel['data'];
    }
    | {
        type: MYFLEETS_FETCH_SUCCESS;
    }
    | {
        type: DELETE_MYFLEETS;
        payload: MyFleetsWrapperModel['data'];
    }
    | {
        type: EDIT_MYFLEETS;
        payload: MyFleetsWrapperModel['data'];
    };
